import React, { useState, useEffect } from "react";
import PlanDetails from "./components/PlanDetails";
import BillingDetails from "./components/BillingDetails";
import Invoice from "./components/Invoice";
import styled from "styled-components";
import UseDesignHook from "../designCloud/components/UseDesignHook";
import { getAuthAndData, postAuthAndData } from "../../api/ApiHelper";
import "./Billing.css";
import { CREATE_CUSTOMER, GET_CUSTOMER, GET_PLANS, GET_SUBSCRIPTION_STATUS, IDTY_PROD_HOST_URI } from "../../api/apiConstants";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";
import { useDispatch } from "react-redux";

const Billing = () => {
  // const currentPlanDetails = {
  //   plan_id: 1,
  //   plan_name: "Basic Plan",
  //   amount: 20,
  //   currency: "inr",
  //   geography: "India",
  //   interval: "month",
  //   quantity: 20,
  //   card_last_digits: "2400",
  //   card_expiry_month: 12,
  //   card_expiry_year: 2025,
  //   current_billing_end: "24-11-2023",
  //   stripe_product_id: "prod_P1STzyU8Apt94B",
  //   stripe_price_id: "price_1ODPYSHWhtdSGqgJaXczDhP8",
  // };
//  const currentPlanDetails = {
//     plan_id: 1,
//     user_id: 105,
//     customer_id: "cus_P5wT0pQ0iuPd3T",
//     tenant_id: 145153,
//     subscription_id: "sub_1OHkjmHWhtdSGqgJwtJSrIwt",
//     price_id: "price_1ODPYSHWhtdSGqgJaXczDhP8",
//     invoice_id: "in_1OHkjmHWhtdSGqgJ3YUDLi3B",
//     subscription_status: "active",
//     payment_status: "paid",
//     billing_amount: 0.0,
//     billing_currency: "inr",
//     current_billing_start: "2023-11-29T10:11:22+05:30",
//     current_billing_end: "2023-12-29T10:11:22+05:30",
//     subscription_start_date: "2023-11-29T10:11:22+05:30",
//     subscription_item: "si_P5wdMLmHEhutMi",
//     latest_transaction_date: "2023-11-29T10:11:22+05:30",
//     quantity: 1,
//     product_name: "Basic Plan",
//     card_last_digits: "2400",
//     card_expiry_month: 12,
//     card_expiry_year: 2025,
//     interval: "month"
//   }

  const [tab, setTab] = useState("1");
  const [customerId, setCustomerId] = useState(null);
  const [activeplan, setActiveplan] = useState(false);
  const [currentPlanDetails, setCurrentPlanDetails] = useState(null);
  const [plans, setPlans] = useState(null);

  const { updateThemeToDark, updateThemeToLight } = UseDesignHook();
  const dispatch = useDispatch();
  const handleLoader = (showBool) => {
    dispatch(dispatchLoaderData(showBool));
  };

  const GetCustomer = async() => {
    handleLoader(true);

    await getAuthAndData(
      `${IDTY_PROD_HOST_URI}${GET_CUSTOMER}`
    ).then((res) => {
      if(res.code === 1) {
        setCustomerId(res.data.customerId);
        handleLoader(false);
      } else {
        console.log(res.data);
        CreateCustomer();
      }
    })
    .catch((err) => {
      handleLoader(false);
      console.log(err);
    });
  };
  
  const CreateCustomer = async() => {
    const body = {};
    handleLoader(true);

    await postAuthAndData(
      `${IDTY_PROD_HOST_URI}${CREATE_CUSTOMER}`, body
      ).then((res) => {
      if(res.code === 1) {
        setCustomerId(res.data.customerId);
      } else {
        console.log(res.data);
      }
      handleLoader(false);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const GetPlans = async() => {
    handleLoader(true);

    await getAuthAndData(
      `${IDTY_PROD_HOST_URI}${GET_PLANS}`
    ).then((res) => {
      if(res.code === 1) {
        setPlans(res.data.plans);
        GetSubscriptionStatus(res.data.plans);
      } else {
        console.log(res.data);
      }
      handleLoader(false);
    })
    .catch((err) => {
      handleLoader(false);
      console.log(err);
    });
  };

  const GetSubscriptionStatus = async(plansDetails) => {
    handleLoader(true);

    await getAuthAndData(
      `${IDTY_PROD_HOST_URI}${GET_SUBSCRIPTION_STATUS}`
    ).then((res) => {
      if(res.code === 1) {
        let plan = plansDetails.filter((plan) => plan.stripe_price_id === res.data.price_id);
        if(!!plan)
        {
          res.data.plan_id = plan[0].plan_id;
          res.data.plan_name = res.data.product_name;
          res.data.amount = plan[0].amount;
          res.data.currency = plan[0].currency;
          res.data.interval = plan[0].interval;
        }

        setCurrentPlanDetails(res.data);
        if(res.data.subscription_status === "active") {
          setActiveplan(true);
        }
      } else {
        console.log(res.data);
      }
      handleLoader(false);
    })
    .catch((err) => {
      handleLoader(false);
      console.log(err);
    });
  };

  useEffect(() => {
    updateThemeToDark();
    document.body.style.background = "#22222A";
    return () => {
      updateThemeToLight();
      document.body.style.background = "white";
    };
  },[]);

  useEffect(() => {
    (async() => {
      await GetCustomer();
      await GetPlans();
    })();
  },[]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if(!!urlParams && urlParams.size > 0) {
      if(urlParams.get("redirect_status") === "succeeded") {
        window.location.replace(window.location.origin + "/billing");
      } else {
        console.log("payment failed");
        window.location.replace(window.location.origin + "/billing");
      }
    }
  },[]);

  return (
    <div className="billing-container">
      <div className="tab-header-container">
        <a
          href="#"
          style={{ color: tab === "1" ? "" : "#54b699" }}
          onClick={() => setTab("1")}
        >
          {" "}
          Plan Details
        </a>{" "}
        |{" "}
        <a
          href="#"
          style={{ color: tab === "2" ? "" : "#54b699" }}
          onClick={() => setTab("2")}
        >
          Billing
        </a>{" "}
        |{" "}
        <a
          href="#"
          style={{ color: tab === "3" ? "" : "#54b699" }}
          onClick={() => setTab("3")}
        >
          Invoice
        </a>
        {/* <StyledTabButton className={`tab-button ${tab === "1" && "tab-active"}`} onClick={() => setTab("1")}>
          Plan Details
        </StyledTabButton>
        <StyledTabButton className={`tab-button ${tab === "2" && "tab-active"}`} onClick={() => setTab("2")}>
          Billing
        </StyledTabButton>
        <StyledTabButton className={`tab-button ${tab === "3" && "tab-active"}`} onClick={() => setTab("3")}>
          Invoice
        </StyledTabButton> */}
      </div>
      {tab === "1" && <PlanDetails plans={plans} customerId={customerId} activeplan={activeplan} currentPlanDetails={currentPlanDetails} handleLoader={handleLoader} />}
      {tab === "2" && <BillingDetails activeplan={activeplan} currentPlanDetails={currentPlanDetails} />}
      {tab === "3" && <Invoice activeplan={activeplan} currentPlanDetails={currentPlanDetails} />}
    </div>
  );
};

export default Billing;

// export const StyledTabButton = styled.button`
//   padding: 3px 20px;
//   border: 3px solid black;
//   box-shadow: 2px 3px 0px black;
//   background-color: rgba(0,0,0,0);
//   margin: 0px;
//   font-weight: 500;
// `;

export const PrimayButton = styled.button`
  padding: 3px 20px;
  border-radius: 5px;
  border: 0px;
  background-color: rgb(1, 126, 250);
  color: white;
  margin: 0px;
  font-weight: 500;

  :hover {
    background-color: #0066c9;
  }
  :disabled {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;