import React, { useState, useEffect } from "react";
import PerformanceCard from "./PerformanceCard";
import "./story.Style.css";
import { INFLUENCERS_LIST, ENGT_PROD_BASE_URI } from "../../api/apiConstants";
import { getAuthAndData, postAuthAndData } from "../../api/ApiHelper";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";

function VideoPerformanceComponent() {
  const [feeds, setFeeds] = useState([]);
  //console.log("influencerDtat####", influencerData);
  const [active, setActive] = useState("Video");
  const History = useHistory();
  const Dispatch = useDispatch();

  useEffect(() => {
    console.log("data123----");

    fetchFeedsData(["2"]);
  }, []);

  function tabClick(val) {
    setActive(val);
    let ContentType = val === "Story" ? ["1"] : ["2"];
    fetchFeedsData(ContentType);
  }

  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
    //    props.routeActionHandler.dispatchLoaderData(showBool);
  };

  const fetchFeedsData = async (ContentType) => {
    handleLoader(true);
    const body = {
      FollowedInfluencers: [2, 2, 3, 1, 7],
      Tags: [],
      Interests: [4],
      Index: 1,
      ContentType: ContentType,
    };
    try {
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${INFLUENCERS_LIST}`,
        body,
        History
      );
      setFeeds(data?.data?.Feeds);
    } catch (err) {}
    handleLoader(false);
  };

  return (
    <div>
      <div>
        <div
          onClick={() => tabClick("Video")}
          className={`e-s-tab ${active === "Video" ? `e-s-tab-active` : ``}`}
        >
          Video
        </div>
        <div
          onClick={() => tabClick("Story")}
          className={`e-s-tab ${active === "Story" ? `e-s-tab-active` : ``}`}
        >
          Story
        </div>
      </div>
      <div style={{ height: "30px" }}></div>
      <div className="storyColection">
        <div className="story">
          {feeds?.map((profile, index) => (
            <PerformanceCard
              key={index}
              thumbnailURL={profile.Thumbnail_URL}
              thumbnailTitle={profile.Thumbnail_Title}
              postEngagementDetails={profile.PostEngagementDetails}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default VideoPerformanceComponent;
