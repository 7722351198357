import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Box } from "@material-ui/core";
import "./prediction.style.css";
import { Pie } from "react-chartjs-2";
import Table from "../../common/reactTable/table";
import { getAuthAndData, postAuthAndData } from "../../../api/ApiHelper";
import {
  GET_PREDICTION_DETAILS,
  SOMETHING_WENT_WRONG,
  UPDATE_PREDICTION_RESULTS,
  ACTIVE_PREDICTIONS,
} from "../../../api/apiConstants";
import {
  TextField,
  Radio,
  Button,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Tooltip,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../../actions/route/routeActionHandler";
import createNotification from "../../common/reactNotification";
import { NotificationContainer } from "react-notifications";
//import Radio from "@material-ui/core/Radio";
//import RadioGroup from "@material-ui/core/RadioGroup";
//import Checkbox from "@material-ui/core/Checkbox";

function PredictionDetail() {
  const history = useHistory();
  const Dispatch = useDispatch();

  const activeEngagement = useSelector(
    (state) => state.EngagementsSmartReducer.campaignsData
  );
  const [predictAndWinEng, setPredictAndWinEng] = useState(
    activeEngagement?.filter((e) => e.Engagement_type == 3)
  );
  const [predictAndWinName, setPredictAndWinName] = useState([]);
  const [selectedType, setSelectedType] = useState(predictAndWinName[0]);
  const [optionList, setOptionList] = useState([]);
  const [buttonFlag, setButtonFlag] = useState(false);
  const [checked, setChecked] = useState(false);
  const [rowDetail, setrowDetail] = useState({});
  const [rowNumber, setrowNumber] = useState();
  const [answerExist, setAnswerExist] = useState(false);
  const [summery, setSummery] = useState({});
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([
    {
      data: [],
      backgroundColor: ["#ff6361", "#ffa600", "#84d717", "#1737d7"],
    },
  ]);
  const optionsPie = {
    legend: {
      display: true,
      position: "right",
    },
  };

  console.log("activeEngagement", activeEngagement);
  // const labels = ["A", "B", "C", "D"];
  // const datasets = [
  //   {
  //     data: [],
  //     backgroundColor: ["#ff6361", "#ffa600", "#84d717", "#1737d7"],
  //   },
  // ];

  //   const journeyColumns = [
  //     {
  //       name: "Journey Name",
  //       selector: "JourneyName",
  //       width: "40%",
  //     },
  //     {
  //       name: "Created On",
  //       selector: "CreatedOn",
  //       width: "10%",
  //     },
  //     {
  //       name: " ",
  //       width: "10%",
  //       cell: (rowObj) => (
  //         <RewardContextMenu onAction={(e) => onActionClick(e, rowObj)} />
  //       ),
  //     },
  //   ];

  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
    //    props.routeActionHandler.dispatchLoaderData(showBool);
  };

  const handleChange = (event, row) => {
    console.log("event.target.checked", event.target.checked);
    console.log("row===========", row);
    // const answerExist = optionList?.find((e) => e.is_winner == true);
    // if (answerExist) {
    //   createNotification("warning", "Answer already selected");
    // } else {
    optionList?.forEach((e) => {
      if (
        e.enagement_prediction_details_id ==
        row?.enagement_prediction_details_id
      ) {
        e.is_winner = event.target.checked;
      } else {
        e.is_winner = false;
      }
    });
    // setChecked(event.target.checked);
    setrowDetail({
      radioButton: event.target.checked,
      rowInfo: row,
    });
  };

  const handleResponseCode = (resp) => {
    if (!resp || resp.code === -1) {
      createNotification(
        "error",
        SOMETHING_WENT_WRONG + " in Smart Engagements"
      );
      return false;
    } else {
      return true;
    }
  };

  const onOptionChange = (target) => {
    setSelectedType(target);
    predictionOption(target.value);
    summaryData(target.value);
  };

  const predictAndwinList = () => {
    var nameOptions = [];
    predictAndWinEng?.forEach((rew) => {
      let obj = {
        value: rew.EngagementID,
        label: rew.DisplayName,
      };
      nameOptions.push(obj);
    });
    setPredictAndWinName(nameOptions);
  };

  const predictionOption = (engagementId) => {
    handleLoader(true);
    getAuthAndData(`${GET_PREDICTION_DETAILS}${engagementId}`, history).then(
      (res) => {
        if (handleResponseCode(res)) {
          setAnswerExist(res?.data?.find((e) => e.is_winner == true));
          setOptionList(res.data);
          console.log("prirnt the option======", res);
        } else {
          createNotification("info", "There are No Campaigns");
        }
        handleLoader(false);
      }
    );
  };

  const pieChartMapping = (data) => {
    console.log("data====2222==", data);
    const percentages = [];
    data[0]?.PredictionParticipantVoteCounts?.map((e) => {
      // datasets[0]?.data.push(25);
      percentages.push(e.PercentageShare);
    });
    setDatasets([
      {
        data: percentages,
        backgroundColor: ["#ff6361", "#ffa600", "#84d717", "#1737d7"],
      },
    ]);
    console.log("datasets[0]?.data.???????", datasets[0]?.data);
  };

  const bindLableData = (arr) => {
    console.log("arr Data===", arr);
    const lableArr = [];
    arr?.map((e) => {
      lableArr.push(e?.ParticipantName);
    });
    setLabels(lableArr);
  };

  const conformAnswer = () => {
    // const answerExist = optionList?.find((e)=>  e.is_winner == true)
    if (answerExist) {
      createNotification("warning", "Answe already selected");
    } else {
      //  if (rowDetail?.rowInfo?.is_winner == false) {
      if (rowDetail?.radioButton) {
        const body = {
          enagement_prediction_details_id:
            rowDetail?.rowInfo?.enagement_prediction_details_id,
          isWinner: rowDetail?.radioButton,
          engagemengt_id: rowDetail?.rowInfo?.engagement_master_id,
        };
        handleLoader(true);

        postAuthAndData(UPDATE_PREDICTION_RESULTS, body, history).then(
          (res) => {
            if (handleResponseCode(res)) {
              createNotification("success", "Answer successfully Save");
              console.log("prirnt the option======", res);
            } else {
              createNotification("warning", "Some issue in Answer Save");
            }
            handleLoader(false);
          }
        );
      } else {
        createNotification("warning", "Select answer please");
      }
    }
    // } else {
    //   createNotification("warning", "Answe already selected");
    // }
  };

  const summaryData = (EngagementID) => {
    const body = {
      EngagementIds: [EngagementID],
      CustomerId: 0,
    };
    postAuthAndData(ACTIVE_PREDICTIONS, body, history).then((res) => {
      if (handleResponseCode(res)) {
        setSummery(res?.data);
        pieChartMapping(res?.data);
        bindLableData(res?.data[0]?.PredictionParticipantVoteCounts);
        console.log("ACTIVE_PREDICTIONS===$$$===", res);
      } else {
        createNotification("info", "There are No Campaigns");
      }
      handleLoader(false);
    });
  };

  useEffect(() => {
    //New Functionm
    predictAndwinList();
  }, []);

  console.log("summery===##", summery);
  console.log("labels=====", labels);
  return (
    <div>
      <NotificationContainer />
      <div className="manage-journey-block">
        <div className="manage-journey">Active Prediction</div>
      </div>
      <div className="predictionDropDown">
        {/* <p>Select Active Prediction</p> */}
        <div>
          <div className="manage-journey-text">Select Active Prediction</div>
          <div
            className="ml-1 row"
            style={{ marginRight: "-33px", width: "33vw" }}
          >
            <div className="w-80">
              <Select
                options={predictAndWinName}
                // isDisabled={obj.rewardType?.value!==2}
                name="predictionList"
                onChange={(e) => onOptionChange(e)}
                value={selectedType}
                style={{ lineHeight: "28px" }}
                maxMenuHeight={150}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="manage-journey-text">Summary</div>
          <Box
            component="div"
            style={{
              p: 2,
              border: "1px solid lightGrey",
              width: "40vw",
              borderRadius: "2%",
            }}
          >
            <div className="chartAndData">
              <div className="summeryCount">
                <span className="l-v-s-o-box-t-c f-40">
                  {summery[0]?.TotalParticipantFormatted}
                </span>
                <span className="l-v-s-o-box-t-c f-12 ml-2">Predicted</span>
              </div>
              <div
                style={{ diaplay: "block", width: "300px", height: "175px" }}
              >
                <Pie
                  style={{
                    diaplay: "block",
                    width: "300px",
                    height: "175px",
                  }}
                  options={optionsPie}
                  data={{
                    labels: labels,
                    datasets: datasets,
                  }}
                />
              </div>
            </div>
          </Box>
        </div>
      </div>
      <div style={{ opacity: answerExist ? ".5" : "1" }}>
        <div className="headerStyle" style={{ marginTop: "30px" }}>
          <div className="headerTextStyleName">Prediction Option</div>
          <div className="headerTextStyle">Winner Declared On</div>
          <div className="headerTextStyle">Modified By</div>
          <div className="headerTextStyle">Modified On</div>
          <div className="headerTextStyle">Is winneer</div>
        </div>
        {optionList?.map((row) => (
          <div style={{ marginBottom: "2px" }}>
            <div className="rowStyle">
              <div className="rowTextStyleName">{row?.participant_name}</div>
              <div className="rowTextStyle">
                {new Date(row?.winner_declared_on).toLocaleDateString()}
              </div>
              <div className="rowTextStyle">{row?.modified_by}</div>
              <div className="rowTextStyle">
                {new Date(row?.modified_on).toLocaleDateString()}
              </div>
              <div style={{ paddingRight: "8vw", zIndex: "999" }}>
                {/* <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  defaultValue={row?.is_winner}
                   value={checked}
                  // checked={row?.is_winner}
                  onChange={(e) => handleChange(e, row)}
                /> */}
                <FormControlLabel
                  control={<Radio disabled={answerExist} />}
                  //label="Tournament"
                  onChange={(e) => handleChange(e, row)}
                  value="2"
                  checked={row?.is_winner}
                  // disabled={answerExist}
                  //checked={checked}
                />
                {/* <Checkbox
            checked={checked}
            onChange={handleChange}
            // inputProps={{ 'aria-label': 'controlled' }}
            /> */}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        //disabled={answerExist?.length > 0}

        style={{
          // width: "14%",
          marginLeft: "33vw",
          marginTop: "40px",
          opacity: answerExist ? ".5" : "1",
        }}
      >
        {/* <div
          className="btn-create-ab-row "

          //disabled={answerExist}
        > */}
        <Button
          variant="contained"
          onClick={conformAnswer}
          style={{ backgroundColor: "#6b9cf3", color: "white" }}
          disabled={answerExist}
        >
          Approve Winner
        </Button>
        {/* <span
            className="btn-ab-text"
            disabled={answerExist}
            onClick={conformAnswer}
          >
            Approve Winner
          </span> */}
        {/* </div> */}
      </div>
    </div>
  );
}

export default PredictionDetail;
