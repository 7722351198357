import React, { useState } from "react";
import "./leftsidebar.css";
import template from "../../Image/Icon/Template.svg";
import Modal from "../Modal/index";
import Galary from "../../Image/Icon/galary.svg";
import folderOpen from "../../Image/Icon/new-folder-open.svg";
import texts from "../../Image/Icon/text.svg";
import box from "../../Image/Icon/box.svg";
import Mediaimage from "../../Image/Icon/media.svg";
import crown from "../../Image/Icon/crown.svg";
import magicPen from "../../Image/Icon/magicpen.svg";
import Assets from "../Design/components/Assets/Assets";
import Template from "../Design/components/Template/Template";
import Text from "../Design/components/Text/Text";
import Product from "../Design/components/Product/Product";
import DesignApps from "../Design/components/DesignApps/DesignApps";
import Media from "../Design/components/Media/Media";
import Stickers from "../Design/components/Stickers/Stickers";
import AIImage from "../Design/components/AIImage/AIImage";
import { SocialMediaPost } from "../SocialMediaPost";
import NetworkCard from "../AccountCard";

const LeftSidebar = (props) => {
  const { socialMediaPopup = false, addaccount = false } = props;

  const [activeComponent, setActiveComponent] = useState(null);
  console.log("activeComponent", activeComponent);

  const data = [
    { id: 1, image: folderOpen, text: "My Assets", component: "Assets" },
    { id: 2, image: template, text: "Template", component: "Template" },
    { id: 3, image: texts, text: "Text", component: "Text" },
    { id: 4, image: box, text: "Products", component: "Product" },
    { id: 5, image: Galary, text: "Apps", component: "DesignApps" },
    { id: 6, image: Mediaimage, text: "Media", component: "Media" },
    { id: 7, image: crown, text: "Sticker", component: "Stickers" },
    { id: 8, image: magicPen, text: "AI Image", component: "AIImage" },
    { id: 9, image: template, text: "Shortcut" },
  ];

  const handleMenuItemClick = (component) => {
    setActiveComponent((prevComponent) =>
      prevComponent === component ? null : component
    );
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "Assets":
        return <Assets />;
      case "Template":
        return <Template type={props?.type} />;
      case "Text":
        return <Text />;
      case "Product":
        return <Product />;
      case "DesignApps":
        return <DesignApps type={props.type} />;
      case "Media":
        return <Media />;
      case "Stickers":
        return <Stickers />;
      case "AIImage":
        return <AIImage />;
      default:
        return null;
    }
  };

  return (
    <div className="d-flex flex-row bd-highlight">
      <div className="bd-highlight">
        <div className="flex-sidebar">
          {data.map((item, index) => (
            <div
              className={`${
                activeComponent === item.component ? `onebox_active` : `onebox`
              }`}
              key={item.id}
              onClick={() => handleMenuItemClick(item.component)}
            >
              <img
                className="img_1"
                src={item.image}
                alt={`Image ${index + 1}`}
              />
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div>{renderComponent(activeComponent)}</div>
      <div className="box-container">
        {socialMediaPopup ? (
          <div className="box-first_new post-files-container">
            <SocialMediaPost />
            <div className="social-media-popup-modal">
              <Modal />
            </div>
          </div>
        ) : addaccount ? (
          <div className="box-first_new post-files-container">
            <NetworkCard />
          </div>
        ) : (
          <div className="box-first_new">
            <Modal />
          </div>
        )}

        {/* {addaccount ? (
          <div className="box-first_new post-files-container">
            <NetworkCard />
          </div>
        ) : (
          <div className="box-first_new">
            <Modal />
          </div>
        )} */}
        {/* {activeComponent ? (
          <div className="box-first-bottom_2"></div>
        ) : (
          <div className="box-first-bottom_1"></div>
        )} */}
      </div>
    </div>
  );
};

export default LeftSidebar;
