import React, { useState, useEffect } from "react";
import ChatAndProduct from "./ChatAndProduct";
import LiveMoniteringCard from "./LiveMoniteringCard";
import LiveMoniteringDetail from "./LiveMoniteringDetail";
import { makeStyles } from "@material-ui/core/styles";
import { postAuthAndData, getAuthAndData } from "../../api/ApiHelper";
import { useSelector, useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";
import {
  INFLUENCERS_LIST,
  ENGT_PROD_BASE_URI,
  GET_LIVE_DETAIL_BY_SCHEDULE_ID,
  GET_BROADCAST_URL_BY_CODE,
  ENGT_PROD_HOST_URI,
  GET_POST_PRODUCT,
} from "../../api/apiConstants";
import { NotificationContainer } from "react-notifications";
import createNotification from "../common/reactNotification";
import "react-notifications/lib/notifications.css";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import FavoriteIcon from "@material-ui/icons/Favorite";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import {
  IvsClient,
  GetStreamCommand,
  PutMetadataCommand,
} from "@aws-sdk/client-ivs";

const useStyles = makeStyles({
  scroll: {
    display: "flex",
    flexDirection: "row",
    width: "vw",
    // justifyContent: window.innerWidth > 400 ? "center" : "start",
    overflowX: "scroll",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    "&::-webkit-scrollbar": {
      height: "5px",
      width: "30px",
      // display: "none",
    },
  },
});

function LiveMonitoring() {
  const [feeds, setFeeds] = useState([]);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [videoEnd, setVideoEnd] = useState(false);
  const [chatData, setChatData] = useState();
  const [selectedFeed, setSelectedFeed] = useState();
  const [selectedId, setSelectedId] = useState(undefined);
  const [streamStop, setStreamStop] = useState(false);
  const { scroll } = useStyles();
  const [title, setTitle] = useState("");
  const [titleImage, setTitleImage] = useState("")
  //let selectedFeed;
  const [isFeedLoaded, setIsFeedLoaded] = useState(false);

  const [isLive, setIsLive] = useState(false);

  const [chatChannel, setChatChannel] = useState("")
  const [liveEnded, setLiveEnded]= useState(false)
  const Dispatch = useDispatch();
  const History = useHistory();
  // const [metaData, setMetaData] = useState([]);
  const [sendMsg, setSendMsg] = useState(false);
  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
    //    props.routeActionHandler.dispatchLoaderData(showBool);
  };

  const fetchFeedsData = async () => {
    handleLoader(true);
    const body = {
      FollowedInfluencers: [1],
      Tags: ["SPORTS", "CLOTH"],
      Interests: [1, 2, 3, 4],
      Index: 1,
      ContentType: ["5"],
      ProductCategory: [],
    };
    try {
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${INFLUENCERS_LIST}`,
        body,
        History
      );
      setFeeds(data?.data?.Feeds);
      console.log("scheduledLiveList===", data?.data?.Feeds);
      setTitle(data?.data?.Feeds[0]?.Thumbnail_Title);
      setTitleImage(data?.data?.Feeds[0]?.Thumbnail_URL);
      setSelectedId(data?.data?.Feeds[0]?.EngagementPostId)
       console.log("title", title)
      // setSelectedFeed(data?.data?.Feeds[0]);
      setIsFeedLoaded(true);
      // scriptAdd();
      // getUrlByCode();
    } catch (err) {}
    handleLoader(false);
  };

  const MINUTE_MS = 5000;

  let counter = 0;

  useEffect(() => {
    if (isLive) {
      return;
    } else {
      const interval = setInterval(() => {
        counter = counter + 1;
        getUrlByCode();
        //console.log("Logs every minute");
        console.log(counter, "inside counter");
      }, MINUTE_MS);

      return () => clearInterval(interval);
    }
  }, [!isLive, selectedId]);

  const getUrlByCode = async () => {
    const body = {
      // ScheduleCode: ,
      // Engagement_id: selectedId,
      Engagement_id: selectedId,
    };

    try {
      const data = await postAuthAndData(
        `${ENGT_PROD_HOST_URI}${GET_BROADCAST_URL_BY_CODE}`,
        body,
        History
      );

      // console.log("DATAAAA", data)
      // const primary_channel = data.filter(function (data) {
      //   return data?.IsPrimary === true;
      // });

      // console.log(primary_channel, "pri,art channnel")

      {
        data?.data?.map(data => {
          if(data?.IsPrimary === true && data?.ChannelArn != null){
            setChatChannel(data?.ChannelArn)
          }
        })
      }
      
      
        const primary_channel = data?.data?.filter(function (data) {
          return data?.IsPrimary === true;
        });
      

        console.log(primary_channel, "pri,art channnel");

        let p_playbackURL = "";
        let p_online = false;

        if (primary_channel.length > 0) {
          p_playbackURL = primary_channel[0].PlayBackURL;
          p_online = primary_channel[0].IsLive;
        }

        console.log("playback url", p_playbackURL);


        if(p_online === true){
        mediaLoad(p_playbackURL);
        setIsLive(true);
       setVideoPlaying(true);
        }
      
    } catch (error) {}
  };

  const mediaLoad = (primaryplackbackURL) => {
    console.log("media load called");
    const mediaPlayerScript = document.createElement("script");
    mediaPlayerScript.src =
      "https://player.live-video.net/1.14.0/amazon-ivs-player.min.js";
    mediaPlayerScript.async = true;
    mediaPlayerScript.onload = () =>
      mediaPlayerScriptLoaded(primaryplackbackURL);
    document.body.appendChild(mediaPlayerScript);
  };

  const mediaPlayerScriptLoaded = (primaryplackbackURL) => {
    // debugger;
    // setVideoPlaying(true);
    // This shows how to include the Amazon IVS Player with a script tag from our CDN
    // If self hosting, you may not be able to use the create() method since it requires
    // that file names do not change and are all hosted from the same directory.
    console.log(
      "mediaPlayerScriptLoaded loaded.............................",
      primaryplackbackURL
    );
    const MediaPlayerPackage = window.IVSPlayer;

    // First, check if the browser supports the Amazon IVS player.
    if (!MediaPlayerPackage.isPlayerSupported) {
      console.warn(
        "The current browser does not support the Amazon IVS player."
      );
      return;
    }

    const PlayerState = MediaPlayerPackage.PlayerState;
    const PlayerEventType = MediaPlayerPackage.PlayerEventType;

    // Initialize player
    const player = MediaPlayerPackage.create();
    player.attachHTMLVideoElement(document.getElementById("video-player"));

    console.log("create");
    // Attach event listeners
    player.addEventListener(PlayerState.PLAYING, () => {
      try {
        //console.log("LIVE STARTED..............................");
        ////console.log("Player State - PLAYING");
        setVideoPlaying(true);
        setLiveEnded(false);
        // setVideoStartTime(new Date().getTime());
        //saveCustomerEvent();
      } catch {}
    });
    player.addEventListener(PlayerState.ENDED, () => {
      //1. Hide Video Player
      ////console.log("Player State - ENDED");
      try {
        setVideoPlaying(false);
        setLiveEnded(true);
        // setLoadershowing(true);
        // setVideoEnd(true);
      } catch {}
    });
    player.addEventListener(PlayerState.READY, () => {
      //////console.log("Player State - READY");
      try {
        setVideoPlaying(true);
        ////console.log("Player State - READY");
      } catch {}
    });
    player.addEventListener(PlayerEventType.ERROR, (err) => {
      //1.
      ////console.warn("Player Event - ERROR:", err);
    });
    //debugger;
    player.addEventListener(PlayerEventType.TEXT_METADATA_CUE, (cue) => {
      console.log("Timed_metadata: ", cue.text);
      try {
        const metadataText = JSON.parse(cue.text);
        console.log("metadataText======$$", metadataText);
        // const productId = metadataText["productId"];
        // props.setMetadataId(productId);
        //const metadataTime = player.getPosition().toFixed(2);
        setChatData(metadataText);
      } catch {}
    });

    // Setup stream and play
    player.setAutoplay(true);
    // player.load(playBackURL);
    player.load(primaryplackbackURL);

    // secondry_player.setAutoplay(true);
    // player.load(playBackURL);
    //console.log("secondry_player URL", secondryPlaybackURL);

    // player.load(
    //   "https://blsh-social.s3.amazonaws.com/v/ASDFS/Jerry+_+TomWB+Kids.mp4"
    // );
    player.setVolume(0.5);
  };

  const LiveFeedData = async () => {
    handleLoader(true);
    try {
      const data = await getAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_LIVE_DETAIL_BY_SCHEDULE_ID}${selectedId}`,
        History
      );
      //setFeeds(data?.data?.Feeds);
      const products = await getAuthAndData(`${ENGT_PROD_BASE_URI}${GET_POST_PRODUCT}${selectedId}`, History);
      data.data.AssociatedProductList = products?.data;
      data.data.IsDataLoaded = true;

      console.log("Selectedfeeddddd", data?.data);
      setSelectedFeed(data?.data);
      setIsFeedLoaded(true);
      // scriptAdd();
    } catch (err) {}
    handleLoader(false);
  };

  useEffect(() => {
    fetchFeedsData();
  }, []);
  useEffect(() => {
    if(!!selectedId)
    {
      LiveFeedData();
    }
  }, [selectedId]);

  // useEffect(() => {
  //   streamStop && createNotification("success", "Stream Stoped Succesfully");
  // }, [streamStop == true]);

  // useEffect(() => {
  //   scriptAdd();
  // }, [selectedFeed]);

  // console.log("reset the metadata in parent component");
  // console.log("selectedFeed123====", selectedFeed);
  // console.log("isFeedLoaded===", isFeedLoaded);
  return (
    <div>
      <NotificationContainer />
      <div className={scroll}>
        {feeds?.map((feed, index) => (
          <LiveMoniteringCard
            key={index}
            feed={feed}
            setSelectedId={setSelectedId}
            //  selectedFeed={selectedFeed}
          />
        ))}
        {/* <LiveMoniteringCard />
        <LiveMoniteringCard />
        <LiveMoniteringCard /> */}
      </div>
      {isFeedLoaded && (
        <div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
          <div
            style={{
              width: "50%",
              backgroundColor: "#fff",
              boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
              borderRadius: "16px",
              display: "flex",
              gap: "15px",
              padding: "10px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              
            </div>



{videoPlaying ?
            <div>
              <div
                style={{
                  height: "600px",
                  position: "relative",
                  backgroundColor: "black",
                  borderRadius: "16px",
                }}
              >
                <div style={{ height: "100%" }}>
                    <video
                      id="video-player"
                      playsInline
                      style={{
                        // width: storycardwidthfn(1),
                        height: "100%",
                        // position: "fixed",
                        // bottom: "0",

                        backgroundColor: "black",

                        borderRadius: "16px",
                        aspectRatio: "9/16",
                      }}
                    ></video>    
                      <div
                        // className={viweCount}
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          position: "absolute",
                          top: "9px",
                          right: 0,
                          background:
                            "linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%)",
                          left: 0,
                          padding: "5px",
                          // margin: "0 16px",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={titleImage}
                            style={{
                              borderRadius: "50%",
                              height: "32px",
                              width: "32px",
                              marginRight: "16px",
                            }}
                          />
                          <div style={{ color: "white", fontSize: "16px" }}>
                           {title}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              color: "white",
                              background: "red",
                              padding: "2px 8px",
                              borderRadius: "4px",
                              fontSize: "13px",
                            }}
                          >
                            LIVE
                            <span
                              style={{
                                height: "16px",
                                width: "16px",
                                // border: "1px solid white",
                                // borderRadius: "50%",
                              }}
                            ></span>
                          </div>
                          <div
                            style={{
                              color: "white",
                              zIndex: "2",
                              background: "rgba(0, 0, 0, 0.6)",
                              borderRadius: "3px",
                              fontSize: "12px",
                              padding: "0px 8px ",
                              textTransform: "uppercase",
                            }}
                          >
                            2k
                          </div>
                        </div>
                        {/* <img
        alt="1"
        src={
          "https://blaash-story-live.s3.ap-south-1.amazonaws.com/story_cta/liveImg.png"
        }
        style={{
          height: "30px",
          width: "50px",
          marginRight: "5px",
          zIndex: "2",
        }}
      /> */}
                      </div>
                      {/* <div
                        style={{
                          display: "flex",
                          position: "absolute",
                          bottom: "20px",
                          left: 0,
                          right: 0,
                          margin: "0 auto",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-around",
                          marginTop: "-60px",
                        }}
                      >
                        <div
                          className=" text-center "
                          style={{
                            background: "red",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            fontSize: "14px",
                            textTransform: "uppercase",
                            color: "white",
                          }}
                        >
                          <span>Stop Live</span>
                        </div>
                      </div> */}
                   
                </div>
              </div>
            </div>
:
            <div
                style={{
                  height: "600px",
                  position: "relative",
                  backgroundColor: "black",
                  borderRadius: "16px",
                }}
              >
                 <div style={{ height: "100%" }}>
                    <video
                      // id="video-player"
                      playsInline
                      style={{
                        // width: storycardwidthfn(1),
                        height: "100%",
                        // position: "fixed",
                        // bottom: "0",

                        backgroundColor: "black",

                        borderRadius: "16px",
                        aspectRatio: "9/16",
                      }}
                    ></video>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'290px', right:'106px'}}>
                    {liveEnded ?
                    <p style={{ color:"white"}}>Live has Ended</p>  
                         :
                    <p style={{ color:"white"}}>Live not Started</p>  
                    }
                    </div> 
                    </div>
              </div>
      }

            
          </div>


          
          {/* <LiveMoniteringDetail
            selectedFeed={selectedFeed}
            setStreamStop={setStreamStop}
            streamStop={streamStop}
            // inputMsg={inputMsg}
            // setInputMsg={setInputMsg}
            //  msgDAta={msgDAta}
            // sendMsg={sendMsg}
            // metaData={metaData}
            // setMetaData={setMetaData}
            // updateMsgCollection={updateMsgCollection}
            // getingIvsData={getingIvsData}
          /> */}
          <ChatAndProduct
            selectedFeed={selectedFeed}
            //inputMsg={inputMsg}
            //setInputMsg={setInputMsg}
            // setSendMsg={setSendMsg}
            // sendMsg={sendMsg}
            //  metaData={metaData}
            // takeMsgdata={takeMsgdata}
            chatChannel={chatChannel}
            chatData={chatData}
          />
        </div>
      )}
    </div>
  );
}

export default LiveMonitoring;
