import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./sideMenu.css";
import { containerHeightCalcFn } from "../global";
import overview_src from "../../../assets/img/overview.svg";
import engagements_src from "../../../assets/img/Engagements.svg";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import liveview_src from "../../../assets/img/Liveview.svg";
import analytics_src from "../../../assets/img/Analytics.svg";
import manage_src from "../../../assets/img/Manage.svg";
import segment_src from "../../../assets/img/Segment.svg";
import logo_src from "../../../assets/img/blaash-logo.png";
import logo_white from "../../../assets/img/logo-white-2.png"
import AirplayIcon from "@material-ui/icons/Airplay";
import CastIcon from "@material-ui/icons/Cast";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
// import AdsClickIcon from '@mui/icons-material/AdsClick';
import { useSelector } from "react-redux";
import MovieCreationIcon from '@material-ui/icons/MovieCreation';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import { PAGE_PERMISSION } from "../../../api/apiConstants";
import { NavigateBefore } from "@material-ui/icons";
import ViewCarouselOutlinedIcon from "@material-ui/icons/ViewCarouselOutlined";
import DashboardIcon from '@material-ui/icons/Dashboard';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import AddToQueueOutlinedIcon from '@material-ui/icons/AddToQueueOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import TransformOutlinedIcon from '@material-ui/icons/TransformOutlined';
import UseIgHook from "../../igUploads/igHook/UseIgHook";

export default function SideMenu(props) {
  let history = useHistory();
  const [selectedSM, setSelectedSM] = useState(1);

  const [engagementSM, setEngagementSM] = useState(1);
  const [analyticsSM, setAnalyticsSM] = useState(1);
  const [manageSM, setManageSM] = useState(1);
  const userPermission = useSelector(
    (select) => select.userReducer.userData?.UserPermission
  );
  console.log('userPermission ===> ',userPermission);

  // const {
  //   postState,
  //   setPostState,
  //   updatePostState,
  // } = UseIgHook();

  useEffect(() => {
    if (history && history.location) {
      if (history.location.pathname === "/") {
        setSelectedSM(8);
        setEngagementSM(0);
      } else if (history.location.pathname === "/engagements/smart") {
        setSelectedSM(2);
        setEngagementSM(1);
      } else if (
        hasPermisson2(PAGE_PERMISSION.manage_journey) &&
        history.location.pathname === "/engagements/journey"
      ) {
        setSelectedSM(2);
        setEngagementSM(2);
      } else if (
        hasPermisson2(PAGE_PERMISSION.manage_ab_testing) &&
        history.location.pathname === "/abTest"
      ) {
        setSelectedSM(2);
        setEngagementSM(3);
      } else if (
        hasPermisson2(PAGE_PERMISSION.manage_prediction) &&
        history.location.pathname === "/predictionDetails"
      ) {
        setSelectedSM(2);
        setEngagementSM(4);
      } else if (history.location.pathname === "/liveview") {
        setSelectedSM(3);
      } else if (history.location.pathname === "/analytics/report") {
        setSelectedSM(4);
        setAnalyticsSM(1);
      } else if (history.location.pathname === "/analytics/trends") {
        setSelectedSM(4);
        setAnalyticsSM(2);
      } else if (history.location.pathname === "/analytics/gamePerformance") {
        setSelectedSM(4);
        setAnalyticsSM(2);
      } else if (
        hasPermisson2(PAGE_PERMISSION.manage_customer_segment) &&
        history.location.pathname === "/segments"
      ) {
        setSelectedSM(5);
      } else if (
        //hasPermisson2(PAGE_PERMISSION.manage_rewards) &&
        history.location.pathname === "/manage/rewards"
      ) {
        setSelectedSM(6);
        setManageSM(1);
      } else if (
        hasPermisson2(PAGE_PERMISSION.manage_reward_partner) &&
        history.location.pathname === "/manage/RewardPartner"
      ) {
        setSelectedSM(6);
        setManageSM(2);
      } else if (history.location.pathname === "/admin") {
        setSelectedSM(7);
      } else if (
        // hasPermisson2(PAGE_PERMISSION.manage_hire_influencers) &&
        history.location.pathname === "/shoppablevideo/dashboard"
      ) {
        setSelectedSM(9);
        setEngagementSM(0);
      } else if (
        // hasPermisson2(PAGE_PERMISSION.manage_studio) &&
        history.location.pathname === "/hireinfluncer/videoperformance"
      ) {
        setSelectedSM(8);
        setEngagementSM(2);
      } else if (
        // hasPermisson2(PAGE_PERMISSION.manage_studio) &&
        history.location.pathname === "/shoppablevideo/uploadshoppablevideos"
      ) {
        setSelectedSM(9);
        setEngagementSM(1);
      } else if (
        // hasPermisson2(PAGE_PERMISSION.manage_studio) &&
        //! history.location.pathname === "/story/StoryStudio"
        history.location.pathname === "/story/UploadStories"

      ) {
        setSelectedSM(13);
        setEngagementSM(2);
      } else if (
        // hasPermisson2(PAGE_PERMISSION.manage_studio) &&
        history.location.pathname === "/live/schedulelive"
      ) {
        setSelectedSM(14);
        setEngagementSM(3);
      } else if (
        // hasPermisson2(PAGE_PERMISSION.manage_studio) &&
        history.location.pathname === "/shoppablevideo/videoslibrary"
      ) {
        setSelectedSM(9);
        setEngagementSM(5);
      } else if (
        // hasPermisson2(PAGE_PERMISSION.manage_studio) &&
        history.location.pathname === "/story/storylibrary"
      ) {
        setSelectedSM(13);
        setEngagementSM(6);
      } else if (
        // hasPermisson2(PAGE_PERMISSION.manage_studio) &&
        history.location.pathname === "/playlistmanager"
      ) {
        setSelectedSM(17);///////
        // setEngagementSM(4);
      } else if (
        // hasPermisson2(PAGE_PERMISSION.manage_video_performance) &&
        history.location.pathname === "/hireinfluncer"
      ) {
        setSelectedSM(10);
      } else if (
        // hasPermisson2(PAGE_PERMISSION.manage_video_performance) &&
        history.location.pathname === "/live/livemonitoring"
      ) {
        setSelectedSM(14);
        setEngagementSM(11);
      } else if(history.location.pathname === "/story/storyDashboard") {
        setSelectedSM(13);
        setEngagementSM(0);
        // history.push("/");
        //setSelectedSM(1);
      }else if(history.location.pathname === "/shoppablevideo/transformvideo"){
        setSelectedSM(9);
        setEngagementSM(18);
      }else if(history.location.pathname === "/designCloud"){
        setSelectedSM(100);
      }else if(history.location.pathname === "/addaccount"){
        setSelectedSM(100);
        setEngagementSM(2);
      }else if(history.location.pathname === "/oneclickpost"){
        setSelectedSM(101);
      }
    }
  });

  const setRouteAndMenu = () => {
    if (userPermission.manage_customer_segment) {
    }
  };

  function overviewClick() {
    setSelectedSM(1);
    setEngagementSM(0);
    history.push("/liveView");
  }
  function storyDashboard() {
    setSelectedSM(12);
    // setEngagementSM(0);
    history.push("/storyDashboard");
  }

  function livemonitoring() {
    setSelectedSM(11);
    //setEngagementSM(0);
    history.push("/livemonitoring");
  }

  function engagementClick(idx, subIdx, val) {
    setSelectedSM(idx);
    setEngagementSM(subIdx);
    history.push("/engagements/" + val);
  }
  function abTestClick(idx, subIdx, val) {
    setSelectedSM(idx);
    setEngagementSM(subIdx);
    history.push("/" + val);
  }

  function liveviewClick() {
    setSelectedSM(3);
    history.push("/liveview");
  }

  function designstudio(idx, subIdx, val) { //video
    setSelectedSM(idx);
    console.log({ subIdx, idx });
    setEngagementSM(subIdx);

    history.push("/shoppablevideo/" + val);
  }

  function storyClick(idx, subIdx, val) { //story
    setSelectedSM(idx);
    setEngagementSM(subIdx);
    history.push("/story/" + val);
  }

  function liveClick(idx, subIdx, val) { //live
    setSelectedSM(idx);
    setEngagementSM(subIdx);
    history.push("/live/" + val);
  }

  function hireInfluencer() {
    setSelectedSM(10);
    history.push("/hireinfluncer");
  }

  function oneclickpost() {
    setSelectedSM(102);
    history.push("/oneclickpost");
    console.log(' function oneclickpost()');
    // updatePostState(1)

  }
  function designCloud() {
    setSelectedSM(100);
    history.push("/designCloud");
    console.log(' function designCloud()');
    // updatePostState(1)

  }

  function addAccount() {
    setSelectedSM(100);
    setEngagementSM(2);
    history.push("/addaccount");
    console.log(' function addAccount()');
    // updatePostState(1)

  }

  function playlistManager() {
    setSelectedSM(17);
    history.push("/playlistmanager");
  }

  function studioClick(idx, subIdx, val) {
    setSelectedSM(idx);
    setEngagementSM(subIdx);
    history.push("/" + val);
  }

  function analyticsClick(idx, subIdx, val) {
    setSelectedSM(idx);
    setAnalyticsSM(subIdx);
    history.push("/analytics/" + val);
  }
  function segmentClick() {
    setSelectedSM(5);
    history.push("/segments");
  }
  function manageClick(idx, subIdx, val) {
    setSelectedSM(idx);
    setManageSM(subIdx);
    history.push("/manage/" + val);
  }
  function adminClick() {
    setSelectedSM(7);
    history.push("/admin");
  }
  function sideLabelFlag() {
    return (
      selectedSM === 1 ||
      selectedSM === 3 ||
      selectedSM === 5 ||
      selectedSM === 7 ||
      selectedSM === 10 ||
      selectedSM === 11
      // selectedSM === 9
    );
  }

  const hasPermisson2 = (id) =>
    userPermission?.find((e) => e.permissionId == id);
  function redirectFn(param) {
    history.push(param);
  }
  //console("userPermission====%%%", userPermission);


  let theme = useSelector((select) => select.themeReducer.Rtheme);
  // console.log('ajay 03 theme user menu',theme);



  return (
    <div
      id="side-menu-container"
      className="float-left clearfix"
      style={{
        height: containerHeightCalcFn(),
        backgroundColor: theme === "dark" ? "rgb(34, 34, 42)" : "white",
        // opacity: theme === "dark" ? 0.8 : 0.8
        color: theme === "dark" ? "white" : "black",
      }}
    >
      {theme === "dark" ? ( <>
      <div className="h-logo-wrapper">
        <img
          src={logo_white}
          alt="Divanor"
          className="h-logo"
          // onClick={() => redirectFn("/")}
          onClick={() => redirectFn("/")}
          
        />
      </div></>) : (
        <>
      <div className="h-logo-wrapper">
        <img
          src={logo_src}
          alt="Divanor"
          className="h-logo"
          // onClick={() => redirectFn("/")}
          onClick={() => redirectFn("/")}
          
        />
      </div></>)
}
      <div
        className={`s-m-major float-left clearfix ${
          sideLabelFlag() ? `w-100` : `w-100`
        }`}
      >
        <div
          className={`s-m-item ${selectedSM === 8 ? `active` : ``}`}
          onClick={() => studioClick(8, 1, "")}
        >
          <DashboardIcon
            className="s-m-item-img"
            alt="Revenue"
            style={
              selectedSM === 8
                ? { color: "#017EFA", fontSize: "19px" }
                : { color: "grey", fontSize: "19px" }
            }
          />

          <span className={`s-m-item-lbl ${selectedSM === 8 ? `active` : ``}`}
          style={{color: theme === "dark" ? "white" : "black",}}
          >
          Revenue
          </span>
        </div>
                  {/*  video =============v*/}
        <div className={`s-m-item ${selectedSM === 9 ? `active` : ``}`}>
          <MovieCreationIcon
            className="s-m-item-img"
            alt="Design Studio"
            style={
              selectedSM === 9
                ? { color: "#017EFA", fontSize: "19px" }
                : { color: "grey", fontSize: "19px" }
            }
          />

          <span
            className={`s-m-item-lbl ${selectedSM === 9 ? `active` : ``}`}
            onClick={() => designstudio(9, 0, "dashboard")}
          style={{color: theme === "dark" ? "white" : "black",}}

          >
            Shoppable Video
          </span>
          {selectedSM === 9 ? (
            <ExpandLessIcon
              alt="expand less"
              style={{ color: "#017EFA", fontSize: "20px", float: "right" }}              
            />
          ) : (
            <ExpandMoreIcon
              alt="expand more"
              style={{ color: "grey", fontSize: "20px", float: "right" }}
              onClick={() => designstudio(9, 0, "dashboard")}
            />
          )}
          {selectedSM === 9 ? (
            <div style={{ marginTop: "15px" }}>
              <div
                className={`s-m-sub-tab ${engagementSM === 1 ? `active` : ``}`}
                onClick={() => designstudio(9, 1, "uploadshoppablevideos")}
              >
                  <BackupOutlinedIcon
            className="s-m-item-img"
            style={
              engagementSM === 1
                ? { color: "#017EFA", fontSize: "19px" }
                : { color: "grey", fontSize: "19px" }
            }
          />
                <span style={{
                      marginBottom: "10px",
                      letterSpacing: 0,
                      lineHeight: "21px",
                      paddingLeft: "10px",
                }}>Upload Shoppable Video</span>
              </div>

              <div
                className={`s-m-sub-tab ${engagementSM === 18 ? `active` : ``}`}
                onClick={() => designstudio(9, 18, "transformvideo")}
              >
                 <TransformOutlinedIcon
            className="s-m-item-img"
            style={
              engagementSM === 18
                ? { color: "#017EFA", fontSize: "19px" }
                : { color: "grey", fontSize: "19px" }
            }
          />
                <span style={{
                      marginBottom: "10px",
                      letterSpacing: 0,
                      lineHeight: "21px",
                      paddingLeft: "10px",
                }}>Transform Video</span>
              </div>

              <div
                className={`s-m-sub-tab ${engagementSM === 5 ? `active` : ``}`}
                onClick={() => designstudio(9, 5, "videoslibrary")}
              >
                 <PhotoLibraryOutlinedIcon
            className="s-m-item-img"
            style={
              engagementSM === 5
                ? { color: "#017EFA", fontSize: "19px" }
                : { color: "grey", fontSize: "19px" }
            }
          />
                <span style={{
                      marginBottom: "10px",
                      letterSpacing: 0,
                      lineHeight: "21px",
                      paddingLeft: "10px",
                }}>Video Library</span>
              </div>
            </div>
          ) : null}
        </div>

        {/* story ================= */}

        <div className={`s-m-item ${selectedSM === 13 ? `active` : ``}`}>
          <InsertPhotoIcon
            className="s-m-item-img"
            alt="Design Studio"
            style={
              selectedSM === 13
                ? { color: "#017EFA", fontSize: "19px" }
                : { color: "grey", fontSize: "19px" }
            }
          />

          <span
            className={`s-m-item-lbl ${selectedSM === 13 ? `active` : ``}`}
            onClick={() => storyClick(13, 0, "storyDashboard")}
          style={{color: theme === "dark" ? "white" : "black",}}

          >
            Story
          </span>
          {selectedSM === 13 ? (
            <ExpandLessIcon
              alt="expand less"
              style={{ color: "#017EFA", fontSize: "20px", float: "right" }}
            />
          ) : (
            <ExpandMoreIcon
              alt="expand more"
              style={{ color: "grey", fontSize: "20px", float: "right" }}
              onClick={() => storyClick(13, 0, "storyDashboard")}
            />
          )}
          {selectedSM === 13 ? (
            <div style={{ marginTop: "15px" }}>
              <div
                className={`s-m-sub-tab ${engagementSM === 2 ? `active` : ``}`}
                //! onClick={() => storyClick(13, 2, "StoryStudio")}
                onClick={() => storyClick(13, 2, "UploadStories")}

              >
                 <BackupOutlinedIcon
            className="s-m-item-img"
            style={
              engagementSM === 2
                ? { color: "#017EFA", fontSize: "19px" }
                : { color: "grey", fontSize: "19px" }
            }
          />
                <span style={{
                      marginBottom: "10px",
                      letterSpacing: 0,
                      lineHeight: "21px",
                      paddingLeft: "10px",
                }}>Upload Stories</span>
              </div>

              <div
                className={`s-m-sub-tab ${engagementSM === 6 ? `active` : ``}`}
                onClick={() => storyClick(13, 6, "storylibrary")}
              >
                 <PhotoLibraryOutlinedIcon
            className="s-m-item-img"
            style={
              engagementSM === 6
                ? { color: "#017EFA", fontSize: "19px" }
                : { color: "grey", fontSize: "19px" }
            }
          />
                <span style={{
                      marginBottom: "10px",
                      letterSpacing: 0,
                      lineHeight: "21px",
                      paddingLeft: "10px",
                }}>Story Library</span>
              </div>
            </div>
          ) : null}
        </div>

        {/* live=============== */}

        <div className={`s-m-item ${selectedSM === 14 ? `active` : ``}`}
          
        >
          <div onClick={() => liveClick(14, 3, "schedulelive")}>
            <AirplayIcon
              className="s-m-item-img"
              alt="Design Studio"
              style={
                selectedSM === 14
                  ? { color: "#017EFA", fontSize: "19px" }
                  : { color: "grey", fontSize: "19px" }
              }
            />

            <span
              className={`s-m-item-lbl ${selectedSM === 14 ? `active` : ``}`}
              
            style={{color: theme === "dark" ? "white" : "black",}}

            >
              Live Commerce
            </span>
            {/* <span
              className={`s-m-item-lbl ${selectedSM === 100 ? `active` : ``}`}
              style={{color: theme === "dark" ? "white" : "black", marginLeft: "3px", fontSize: "10px"}}
            >
              Comming soon
            </span> */}
            {selectedSM === 14 ? (
              <ExpandLessIcon
                alt="expand less"
                style={{ color: "#017EFA", fontSize: "20px", float: "right" }}
              />
            ) : (
              <ExpandMoreIcon
                alt="expand more"
                style={{ color: "grey", fontSize: "20px", float: "right" }}
              />
            )}
          </div>
          {selectedSM === 14 ? (
            <div style={{ marginTop: "15px" }}>
              <div
                className={`s-m-sub-tab ${engagementSM === 3 ? `active` : ``}`}
                onClick={() => liveClick(14, 3, "schedulelive")}
              >
                 <ScheduleOutlinedIcon
            className="s-m-item-img"
            style={
              engagementSM === 3
                ? { color: "#017EFA", fontSize: "19px" }
                : { color: "grey", fontSize: "19px" }
            }
          />
                <span style={{
                      marginBottom: "10px",
                      letterSpacing: 0,
                      lineHeight: "21px",
                      paddingLeft: "10px",
                }}>Schedule Live</span>
              </div>
              <div
                className={`s-m-sub-tab ${engagementSM === 11 ? `active` : ``}`}
                onClick={() => liveClick(14, 11, "livemonitoring")}
              >
                 <AddToQueueOutlinedIcon
            className="s-m-item-img"
            style={
              engagementSM === 11
                ? { color: "#017EFA", fontSize: "19px" }
                : { color: "grey", fontSize: "19px" }
            }
          />
                <span style={{
                      marginBottom: "10px",
                      letterSpacing: 0,
                      lineHeight: "21px",
                      paddingLeft: "10px",
                }}>
                  Live Monitoring
                </span>
              </div>
            </div>
          ) : null}
        </div>
 {/* live=============== */}

        <div
          className={`s-m-item ${selectedSM === 17 ? `active` : ``}`}
          onClick={() => playlistManager()}
        >
          <PlaylistPlayIcon
            className="s-m-item-img"
            alt="Video Analytics"
            style={
              selectedSM === 17
                ? { color: "#017EFA", fontSize: "19px" }
                : { color: "grey", fontSize: "19px" }
            }
          />
          <span className={`s-m-item-lbl ${selectedSM === 17 ? `active` : ``}`}
          style={{color: theme === "dark" ? "white" : "black",}}
          
          >Playlist Manager</span>
        </div>

        {/* <div
        // .s-m-item-lbl.active css class
        
          className={`s-m-item ${selectedSM === 12 ? `active` : ``}`}
          onClick={() => storyDashboard()}
        >
          <ViewCarouselOutlinedIcon
            className="s-m-item-img"
            alt="Video Analytics"
            style={
              selectedSM === 12
                ? { color: "#017EFA", fontSize: "19px" }
                : { color: "grey", fontSize: "19px" }
            }
          />

          <span className={`s-m-item-lbl ${selectedSM === 12 ? `active` : ``}`}>
            One Click
          </span>
        </div> */}
        
        <div
          className={`s-m-item ${selectedSM === 10 ? `active` : ``}`}
          // onClick={() => hireInfluencer()}
          // onClick={() => hireInfluencer()}
        >
          <RecordVoiceOverIcon
            className="s-m-item-img"
            alt="Hire Influncer"
            style={
              selectedSM === 10
                ? { color: "#017EFA", fontSize: "19px" }
                : { color: "grey", fontSize: "19px" }
            }
          />

          <span className={`s-m-item-lbl ${selectedSM === 10 ? `active` : ``}`}
          style={{color: theme === "dark" ? "white" : "black",}}
          >
            Hire Influncer
          </span>
          <span
            className={`s-m-item-lbl ${selectedSM === 100 ? `active` : ``}`}
            style={{color: theme === "dark" ? "white" : "black", marginLeft: "3px", fontSize: "10px"}}
          >
            Comming soon
          </span>
        </div>
        <div className={`s-m-item ${selectedSM === 100 ? `active` : ``}`}
        >
          <div onClick={() => designCloud()}>
            <RecordVoiceOverIcon
              className="s-m-item-img"
              alt="One Click Icon"
              style={
                selectedSM === 100
                  ? { color: "#017EFA", fontSize: "19px" }
                  : { color: "grey", fontSize: "19px" }
              }
            />

            <span
              className={`s-m-item-lbl ${selectedSM === 100 ? `active` : ``}`}
              style={{color: theme === "dark" ? "white" : "black",}}
            >
              Design Cloud
            </span>
            {/* <span
              className={`s-m-item-lbl ${selectedSM === 100 ? `active` : ``}`}
              style={{color: theme === "dark" ? "white" : "black", marginLeft: "3px", fontSize: "10px"}}
            >
              Comming soon
            </span> */}
            {selectedSM === 100 ? (
              <ExpandLessIcon
                alt="expand less"
                style={{ color: "#017EFA", fontSize: "20px", float: "right" }}
              />
            ) : (
              <ExpandMoreIcon
                alt="expand more"
                style={{ color: "grey", fontSize: "20px", float: "right" }}
              />
            )}
          </div>
          {selectedSM === 100 ? (
            <div style={{ marginTop: "15px" }}>
              <div
                className={`s-m-sub-tab ${engagementSM === 2 ? `active` : ``}`}
                onClick={() => addAccount()}
              >
                 <RecordVoiceOverIcon
                  className="s-m-item-img"
                  alt="One Click Icon"
                  style={
                    engagementSM === 2
                      ? { color: "#017EFA", fontSize: "19px" }
                      : { color: "grey", fontSize: "19px" }
                  }
                />
                <span style={{
                      marginBottom: "10px",
                      letterSpacing: 0,
                      lineHeight: "21px",
                      paddingLeft: "10px",
                }}>Add Account</span>
              </div>
            </div>
          ) : null}
        </div>
        {/* <div
          className={`s-m-item ${selectedSM === 100 ? `active` : ``}`}
          onClick={() => designCloud()}
        >
          <RecordVoiceOverIcon
            className="s-m-item-img"
            alt="One Click Icon"
            style={
              selectedSM === 100
                ? { color: "#017EFA", fontSize: "19px" }
                : { color: "grey", fontSize: "19px" }
            }
          />

          <span className={`s-m-item-lbl ${selectedSM === 10 ? `active` : ``}`}
          style={{color: theme === "dark" ? "white" : "black",}}
          >
            Design Cloud
          </span>
        </div>  */}
{/* //! edit the selectedSM value */}
         <div
          className={`s-m-item ${selectedSM === 101 ? `active` : ``}`}
          onClick={() => oneclickpost()}
        >
          <RecordVoiceOverIcon
            className="s-m-item-img"
            alt="One Click Icon"
            style={
              selectedSM === 101
                ? { color: "#017EFA", fontSize: "19px" }
                : { color: "grey", fontSize: "19px" }
            }
          />

          <span className={`s-m-item-lbl ${selectedSM === 101 ? `active` : ``}`}
          style={{color: theme === "dark" ? "white" : "black",}}
          >
            One Click Post
          </span>
        </div>  

        {/* <div
                className={`s-m-sub-tab ${engagementSM === 2 ? `active` : ``}`}
                onClick={() => designstudio(9, 2, "uploadstories")}
              >
                <span>Upload Stories</span>
              </div> */}
        {/* <div
                className={`s-m-sub-tab ${engagementSM === 3 ? `active` : ``}`}
                onClick={() => designstudio(9, 3, "schedulelive")}
              >
                <span>Schedule Live</span>
              </div> */}
        {/* {hasPermisson2(PAGE_PERMISSION.manage_studio) && ( */}
        {/* <div
                className={`s-m-sub-tab ${engagementSM === 4 ? `active` : ``}`}
                onClick={() => designstudio(9, 4, "playlistmanager")}
              >
                <span>Playlist Manager</span>
              </div> */}
        {/* <div
                className={`s-m-sub-tab ${engagementSM === 3 ? `active` : ``}`}
                onClick={() => designstudio(9, 3, "embededplayer")}
              >
                <span>Embed Playlist</span>
              </div> */}

        {/* <div
                className={`s-m-sub-tab ${engagementSM === 6 ? `active` : ``}`}
                onClick={() => designstudio(9, 6, "storylibrary")}
              >
                <span>Story Library</span>
              </div> */}
        {/* )} */}
        {/* <div className={`s-m-sub-tab ${engagementSM === 2 ? `active` : ``}`} onClick={() => engagementClick(2, 2, 'journey')}>
                                <span></span>
                            </div> */}

        {/* {hasPermisson2(PAGE_PERMISSION.manage_studio) && ( */}
        {/* <div
                className={`s-m-sub-tab ${engagementSM === 4 ? `active` : ``}`}
                onClick={() => designstudio(9, 4, "playlistmanager")}
              >
                <span>Playlist Manager</span>
              </div> */}
        {/* <div
                className={`s-m-sub-tab ${engagementSM === 3 ? `active` : ``}`}
                onClick={() => designstudio(9, 3, "embededplayer")}
              >
                <span>Embed Playlist</span>
              </div> */}

        {/* )} */}
        {/* <div className={`s-m-sub-tab ${engagementSM === 2 ? `active` : ``}`} onClick={() => engagementClick(2, 2, 'journey')}>
                                <span></span>
                            </div> */}

        {/* <div
          className={`s-m-item ${selectedSM === 1 ? `active` : ``}`}
          onClick={() => overviewClick()}
        >
          <img
            src={liveview_src}
            className="s-m-item-img"
            alt="Overview"
            style={selectedSM === 11 ? { color: "#017EFA" } : { color: "grey" }}
          />

          <span className={`s-m-item-lbl ${selectedSM === 1 ? `active` : ``}`}>
            Live View
          </span>
        </div> */}

        {/* <div
          className={`s-m-item ${selectedSM === 11 ? `active` : ``}`}
          onClick={() => livemonitoring()}
        >
          <LiveTvIcon
            className="s-m-item-img"
            alt="Video Analytics"
            style={
              selectedSM === 11
                ? { color: "#017EFA", fontSize: "19px" }
                : { color: "grey", fontSize: "19px" }
            }
          />

          <span className={`s-m-item-lbl ${selectedSM === 11 ? `active` : ``}`}>
            Live Monitoring
          </span>
        </div> */}

        {/* <div className={`s-m-item ${selectedSM === 2 ? `active` : ``}`}>
          <img
            src={engagements_src}
            className="s-m-item-img"
            alt="Engagements"
          />
          <span
            className={`s-m-item-lbl ${selectedSM === 2 ? `active` : ``}`}
            onClick={() => engagementClick(2, 1, "smart")}
          >
            Game Engagements
          </span>
          {selectedSM === 2 ? (
            <ExpandLessIcon
              alt="expand less"
              style={{ color: "#017EFA", fontSize: "20px", float: "right" }}
            />
          ) : (
            <ExpandMoreIcon
              alt="expand more"
              style={{ color: "grey", fontSize: "20px", float: "right" }}
            />
          )}
          {selectedSM === 2 ? (
            <div>
              <div
                className={`s-m-sub-tab ${engagementSM === 1 ? `active` : ``}`}
                onClick={() => engagementClick(2, 1, "smart")}
              >
                <span>Smart Engagements</span>
              </div>
              <div
                className={`s-m-sub-tab ${engagementSM === 2 ? `active` : ``}`}
                onClick={() => engagementClick(2, 2, "journey")}
              >
                <span>Journey</span>
              </div>
              {hasPermisson2(PAGE_PERMISSION.manage_ab_testing) && (
                <div
                  className={`s-m-sub-tab ${
                    engagementSM === 3 ? `active` : ``
                  }`}
                  onClick={() => abTestClick(2, 3, "abTest")}
                >
                  <span>AB Testing</span>
                </div>
              )}
              {hasPermisson2(PAGE_PERMISSION.manage_prediction) && (
                <div
                  className={`s-m-sub-tab ${
                    engagementSM === 4 ? `active` : ``
                  }`}
                  onClick={() => abTestClick(2, 4, "predictionDetails")}
                >
                  <span>Prediction</span>
                </div>
              )}
            </div>
          ) : null}
        </div> */}
        {/* <div
          className={`s-m-item ${selectedSM === 3 ? `active` : ``}`}
          onClick={() => liveviewClick()}
        >
          <img src={overview_src} className="s-m-item-img" alt="Liveview" />
          {sideLabelFlag() ? (
            <span className={`s-m-item-lbl ${selectedSM === 8 ? `active` : ``}`}>
              Live View &nbsp;
              <span className="pill">Coming Soon</span>
              
            </span>
          ) : null}
        </div> */}

        {/* {hasPermisson2(PAGE_PERMISSION.manage_dashboard) && (
          <div
            className={`s-m-item ${selectedSM === 4 ? `active` : ``}`}
            onClick={() => analyticsClick(4, 1, "report")}
          >
            <img src={analytics_src} className="s-m-item-img" alt="Analytics" />

            <span
              className={`s-m-item-lbl ${selectedSM === 4 ? `active` : ``}`}
            >
              Analytics
            </span>

            {selectedSM === 4 ? (
              <Fragment>
                <div
                  className={`s-m-sub-tab ${analyticsSM === 1 ? `active` : ``}`}
                  onClick={() => analyticsClick(4, 1, "report")}
                >
                  <span>Dashboard</span>
                </div>
              </Fragment>
            ) : null}
          </div>
        )} */}

        {/* {hasPermisson2(PAGE_PERMISSION.manage_customer_segment) && (
          <div
            className={`s-m-item ${selectedSM === 5 ? `active` : ``}`}
            onClick={() => segmentClick()}
          >
            <img
              src={segment_src}
              className="s-m-item-img"
              alt="CustomerSegment"
            />

            <span
              className={`s-m-item-lbl ${selectedSM === 5 ? `active` : ``}`}
            >
              Customer Segment
            </span>
          </div>
        )} */}

        {/* <div
          className={`s-m-item ${selectedSM === 6 ? `active` : ``}`}
          onClick={() => manageClick(6, 1, "rewards")}
        >
          <img src={manage_src} className="s-m-item-img" alt="Manage" />

          <span className={`s-m-item-lbl ${selectedSM === 6 ? `active` : ``}`}>
            Manage
          </span>

          {selectedSM === 6 ? (
            <Fragment>
              <div
                className={`s-m-sub-tab ${manageSM === 1 ? `active` : ``}`}
                onClick={() => manageClick(6, 1, "rewards")}
              >
                <span>Rewards</span>
              </div>

              {hasPermisson2(PAGE_PERMISSION.manage_reward_partner) && (
                <div
                  className={`s-m-sub-tab ${manageSM === 2 ? `active` : ``}`}
                  onClick={() => manageClick(6, 2, "RewardPartner")}
                >
                  <span>Reward Partner</span>
                </div>
              )}
            </Fragment>
          ) : null}
        </div> */}

        {/* <div className={`s-m-item ${selectedSM === 7 ? `active` : ``}`} onClick={() => adminClick()}>
                    <img src={admin_src} className="s-m-item-img" alt="Admin" />
                    {sideLabelFlag() ? (<span className={`s-m-item-lbl ${selectedSM === 8 ? `active` : ``}`}>Admin</span>) : null}
                </div> */}
      </div>
    </div>
    // <div className="sideMenueMain">

    //</div>
  );
}