import React, { useState, Suspense, lazy, useEffect } from "react";
import "./template.css";
import {
  getAuthAndData,
  postAuthAndData,
} from "../../../../../../api/ApiHelper";
import {
  ENGT_PROD_BASE_URI,
  ENGT_PROD_HOST_URI,
  GET_ALL_TEMPLATES,
  GET_TEMPLATES_BY_NAME,
} from "../../../../../../api/apiConstants";
import SearchIcon from "@material-ui/icons/Search";
import { TabButton } from "../../index.js";
import { useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../../../../../actions/route/routeActionHandler";

const TemplateGroup = lazy(() => import("./TemplateGroup.js"));

const Template = ({ type }) => {
  // const customStyles = {
  //   control: (provided) => ({
  //     ...provided,
  //     color: "#fff",
  //     backgroundColor: "#27272f", // Custom background color
  //     borderColor: "#484851", // Custom border color
  //     boxShadow: "none", // Remove default box-shadow
  //     "&:hover": {
  //       borderColor: "#555", // Custom border color on hover
  //     },
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isSelected ? "#555" : "transparent", // Highlight selected option
  //     color: state.isSelected ? "#48485" : "#000", // Custom text color
  //     "&:hover": {
  //       backgroundColor: state.isSelected ? "#555" : "#f1f1f1", // Highlight selected option on hover
  //     },
  //     singleValue: (provided, state) => ({
  //       ...provided,
  //       color: "#fff", // Custom text color for the selected option
  //       fontWeight: "bold", // Make the selected option bold
  //     }),
  //   }),
  //   // Customize other components as needed (e.g., singleValue, menu, etc.)
  // };

  const dispatch = useDispatch();
  const handleLoader = (showBool) => {
    dispatch(dispatchLoaderData(showBool));
  };

  const [searchTemplate, setSearchTemplate] = useState("");
  const [searchImageTemplate, setSearchImageTemplate] = useState([]);
  const [searchVideoTemplate, setSearchVideoTemplate] = useState([]);
  const [searchAudioTemplate, setSearchAudioTemplate] = useState([]);
  const [contenttype, setContentType] = useState(type === 1 ? 1 : 2);
  const [imagePlaylist, setImagePlaylist] = useState([]);
  const [videoPlaylist, setVideoPlaylist] = useState([]);
  const [audioPlaylist, setAudioPlaylist] = useState([]);
  const [searchType, setSearchType] = useState(null);

  const GetTemplatebyName = async () => {
    handleLoader(true);
    setSearchType(contenttype);
    const body = {
      template_search_type: 1,
      content_type: contenttype,
      search_text: searchTemplate,
      display_count: 10,
      page_index: 0,
    };

    await postAuthAndData(`${ENGT_PROD_HOST_URI}${GET_TEMPLATES_BY_NAME}`, body)
      .then((response) => {
        if (response.code === 1) {
          if (contenttype === 1) {
            setSearchImageTemplate([...response.data]);
          } else if (contenttype === 2) {
            setSearchVideoTemplate([...response.data]);
          } else if (contenttype === 3) {
            setSearchAudioTemplate([...response.data]);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    handleLoader(false);
  };

  const GetAllTemplatesByType = async (contenttype) => {
    handleLoader(true);
    await getAuthAndData(
      `${ENGT_PROD_BASE_URI}${GET_ALL_TEMPLATES}?content_type=${type}`
    )
      .then((response) => {
        if (response.code === 1) {
          if (contenttype === 1) {
            setImagePlaylist((prevList) => [...prevList, ...response.data]);
          } else if (contenttype === 2) {
            setVideoPlaylist((prevList) => [...prevList, ...response.data]);
          } else if (contenttype === 3) {
            setAudioPlaylist((prevList) => [...prevList, ...response.data]);
          }
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
    handleLoader(false);
  };

  useEffect(() => {
    (() => {
      if (type === 1) {
        GetAllTemplatesByType(1);
      } else {
        GetAllTemplatesByType(2);
        GetAllTemplatesByType(3);
      }
    })();
  }, []);

  useEffect(() => {
    if (
      !!searchTemplate &&
      searchTemplate !== "" &&
      searchTemplate.length >= 3
    ) {
      const getTemplate = setTimeout(() => {
        GetTemplatebyName();
      }, [2000]);

      return () => clearTimeout(getTemplate);
    }
  }, [searchTemplate, contenttype]);

  return (
    <div className="boxtemplate">
      <div
        style={{
          width: "300px",
          height: "610px",
          padding: "10px 10px 10px",
          background: "#27272F",
        }}
      >
        <TabButton
          className="tab-button"
          onClick={() => setContentType(type === 1 ? 1 : 2)}
        >
          Discover
        </TabButton>
        {type !== 1 && (
          <TabButton className="tab-button" onClick={() => setContentType(3)}>
            Audio
          </TabButton>
        )}
        <div className="searchbox">
          <SearchIcon />
          <input
            id="outlined-basic"
            type="text"
            className="search-input"
            placeholder="Search all templates"
            onChange={(event) => {
              if (event.target.value === "") {
                setSearchType(null);
              }
              setSearchTemplate(event.target.value);
            }}
          />
        </div>
        {!!searchTemplate &&
        searchTemplate.length >= 3 &&
        searchType === contenttype ? (
          <>
            {type === 1 && (
              <>
                {searchImageTemplate.length > 0 ? (
                  <Suspense fallback={<div>Loading</div>}>
                    <TemplateGroup
                      playlist={searchImageTemplate}
                      type={contenttype}
                    />
                  </Suspense>
                ) : (
                  <div>No Template Available</div>
                )}
              </>
            )}
            {type !== 1 && contenttype === 2 && (
              <>
                {searchVideoTemplate.length > 0 ? (
                  <Suspense fallback={<div>Loading</div>}>
                    <TemplateGroup
                      playlist={searchVideoTemplate}
                      type={contenttype}
                    />
                  </Suspense>
                ) : (
                  <div>No Template Available</div>
                )}
              </>
            )}
            {type !== 1 && contenttype === 3 && (
              <>
                {searchAudioTemplate.length > 0 ? (
                  <Suspense fallback={<div>Loading</div>}>
                    <TemplateGroup
                      playlist={searchAudioTemplate}
                      type={contenttype}
                    />
                  </Suspense>
                ) : (
                  <div>No Template Available</div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {type === 1 && (
              <Suspense fallback={<div>Loading</div>}>
                <TemplateGroup playlist={imagePlaylist} type={contenttype} />
              </Suspense>
            )}
            {type !== 1 && contenttype === 2 && (
              <Suspense fallback={<div>Loading</div>}>
                <TemplateGroup playlist={videoPlaylist} type={contenttype} />
              </Suspense>
            )}
            {type !== 1 && contenttype === 3 && (
              <Suspense fallback={<div>Loading</div>}>
                <TemplateGroup playlist={audioPlaylist} type={contenttype} />
              </Suspense>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Template;
