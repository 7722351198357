import {
  Delete_Youtube_Array,
  Selected_Youtube_Array,
} from "../../constants/actionTypes";

const INITIAL_STATE = {
  videoObj: {}, // Default value
};

const selectedYoutubeArrayReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Selected_Youtube_Array:
      return {
        //...state,
        videoObj: action?.payload,
      };

    case Delete_Youtube_Array:
      return {
        // ...state,
        videoObj: {},
      };

    default:
      return state;
  }
};

export default selectedYoutubeArrayReducer;
