import React, { useEffect, useRef, useState } from "react";
import {
  Group,
  Image as KonvaImage,
  Label,
  Tag,
  Text,
  Transformer,
} from "react-konva";
import { useHoverDirty, useLongPress } from "react-use";
import useImage from "use-image";

import CloseIcon from "../../../assets/img/close.svg";

export function StickyNote({
  textProps,
  onSelect,
  isSelected,
  onChange,
  onDelete,
}) {
  const shapeRef = useRef(null);
  const transformerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (isSelected && transformerRef.current !== null) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const isHovered = useHoverDirty(shapeRef);
  const [deleteImage] = useImage(CloseIcon, "Anonymous");
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const onLongPress = () => {
    setShowDeleteButton(true);
  };

  useEffect(() => {
    if (isHovered) {
      setShowDeleteButton(true);
    } else {
      setTimeout(() => {
        setShowDeleteButton(false);
      }, 2000);
    }
  }, [isHovered]);
  const transformer = isSelected ? (
    <Transformer
      ref={transformerRef}
      rotateEnabled={true}
      flipEnabled={true}
      enabledAnchors={[
        "middle-left",
        "middle-right",
        "top-center",
        "bottom-center",
        "top-left",
        "top-right",
        "bottom-left",
        "bottom-right",
      ]}
      boundBoxFunc={(oldBox, newBox) => {
        newBox.width = Math.max(30, newBox.width);
        return newBox;
      }}
    />
  ) : null;
  return (
    <>
      {
        textProps.text !== "" && (
          <Label
            x={textProps.x}
            y={textProps.y}
            onClick={onSelect}
            onTap={onSelect}
            ref={shapeRef}
            width={textProps.width}
            height={textProps.height}
            offsetX={5}
            draggable={true}
            onDragStart={() => setIsDragging(true)}
            onDragEnd={(e) => {
              setIsDragging(false);
              onChange({
                ...textProps,
                x: e.target.x(),
                y: e.target.y(),
              });
            }}
            onTransformEnd={(e) => {
              const node = shapeRef.current;
              const scaleX = node.scaleX();
              const scaleY = node.scaleY();

              // we will reset it back
              node.scaleX(1);
              node.scaleY(1);
              onChange({
                ...textProps,
                x: node.x(),
                y: node.y(),
                // set minimal value
                width: Math.max(5, node.width() * scaleX),
                height: Math.max(node.height() * scaleY),
              });
            }}
          >
            <Tag fill={textProps.backgroundColor} cornerRadius={5} />
            <Text
              text={textProps.text}
              fill={textProps.textColor}
              fontFamily={textProps.fontFamily}
              fontSize={textProps.fontSize}
              padding={5}
            />
            {showDeleteButton && !isDragging && (
              <KonvaImage
                onTouchStart={onDelete}
                onClick={onDelete}
                image={deleteImage}
                width={20}
                height={20}
                offsetX={textProps.width + 20}
              />
            )}
          </Label>
        )
        // {/* {transformer} */}
      }
    </>
  );
}
