import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import "react-notifications/lib/notifications.css";
import { useSelector } from "react-redux";
import {
  ENGT_PROD_BASE_URI,
  GET_PRODUCT_DETAILS,
  EVNT_PROD_HOST_URI,
  PREE_SIGNED_URL,
  SOMETHING_WENT_WRONG,
  SAVE_POST_DATA,
} from "../../api/apiConstants";
import { postAuthAndData } from "../../api/ApiHelper";
import { useHistory } from "react-router";
import createNotification from "../common/reactNotification";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";
import { useDispatch } from "react-redux";
let presignedUploadUrlArr = [];
let preAssignthumbnailArr = [];
let isUploadToAWSSuccess = false;
let productIds = [];

function Usestoryhook(steps) {
  const [storyFlage, setStoryFlage] = useState(false);
  const [liveSchedule, setLiveSchedule] = useState(false);
  const [pageType, setPageType] = useState(true);
  const [activeStep, setActiveStep] = useState(1);
  const [activeProductFilter, setActiveProductFilter] = useState("name");
  const [activeDetailsFilter, setActiveDetailsFilter] = useState("Stickers");
  const [inputMsg, setInputMsg] = useState("");
  const [skipped, setSkipped] = useState(new Set());
  const [fileObj, setFileObj] = useState([]);
  const [thumbnailUrl, setThumbnailUrl] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [PostContent, setPostContent] = useState([]);
  const [productDetail, setProductDetail] = useState([]);
  const [thumbnailContent, setThumbnailContent] = useState([]);
  const [liveCode, setLiveCode] = useState(null);

  const initialDiscription = {
    tag: "",
    discription: "",
    targetCatogery: "",
    caption: "",
    fontFamily: "",
    ColorPicker: "",
    buttonType: 0,
    contentType: 0,
    productId: "",
    thumbnailTitle: "",
    buttonCta: "",
    liveDateTime: new Date().getTime(),
    videoDuration: 0,
    isMulti: "",
    cta_URL: "",
    disabled_product: true,
  };

  const [discription, setDiscription] = useState(initialDiscription);

  const userData = useSelector((select) => select.userReducer.userData);

  ////console("userData==1=", userData);

  const [flag, setFlag] = useState([]);
  const [flag2, setFlag2] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log("ondrop===");
      isUploadToAWSSuccess = true;
      // setPostContent([]);
      // setThumbnailContent([]);
      setFileObj([]);
      setThumbnailUrl([]);
      const filterObjArr = [];
      acceptedFiles?.forEach((file, index) => {
        //console("file_extention===", file.name.split(".").pop());
        let extensionType = file.name.split(".").pop();
        if (
          extensionType == "jpeg" ||
          extensionType == "jpg" ||
          extensionType == "mp4" ||
          //! added
          extensionType == "mov" ||
          extensionType == "png" ||
          extensionType == "webp"
        ) {
          if (
            (file.name.split(".").pop() == "mp4" && file.size < 10000000) || //Video Size max is 5 MB
            file.size < 5000000
          ) {
            const obj = {
              extension: file.name.split(".").pop(),
              contentType: file.name.split(".").pop() == "mp4" ? "2" : "1",
              file: file,
              FileId: ` ${Date.now()}_${file.name}`,
            };
            filterObjArr.push(obj);
            console.log("File Object====", obj);
          } else {
            createNotification(
              "warning",
              "Image/Video size should be less than 10 MB."
            );
          }
        } else {
          createNotification(
            "warning",
            "ajay Only jpeg, jpg, png, mp4  webp file type is allowed"
          );
        }
      });
      if (activeStep === 1) {
        setFileObj(filterObjArr);
        console.log("file Obj", filterObjArr);
        fatchPreUrlData(filterObjArr);
      } else if (activeStep === 2) {
        setThumbnailUrl(filterObjArr);
        console.log("thumbnail url", filterObjArr);
        fetchPreUrlDataThumblail(filterObjArr);
      }
    },
    [activeStep]
  );

  const handleResponseCode = (resp) => {
    console.log(resp.data, "resp");
    if (!resp || resp.code === -1) {
      // history.push("/login");
      createNotification(
        "error",
        SOMETHING_WENT_WRONG + ", Please try again later."
      );
      // history.go("/login");
      handleLoader(false);
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    handleLoader(false);
  }, []);

  // useEffect(() => {
  //   if (userData?.UserID && fileObj?.length > 0) {
  //     fatchPreUrlData();
  //   } else {
  //     fileObj?.length > 0 &&
  //       createNotification(
  //         "warning",
  //         "Something went wrong, please try again."
  //       );
  //   }
  // }, [fileObj]);

  // useEffect(() => {
  //   if (thumbnailUrl?.length > 0) {
  //     fetchPreUrlDataThumblail();
  //   } else {
  //     thumbnailUrl?.length > 0 &&
  //       createNotification(
  //         "warning",
  //         "Something went wrong, please try again."
  //       );
  //   }
  // }, [thumbnailUrl]);

  const handleLoader = (showBool) => {
    dispatch(dispatchLoaderData(showBool));
  };

  //   const copyGeneratedCode = (Playlist)=>{
  //     //console.log("======Playlist", Playlist)
  //    setgeneratedCodeObj({
  //     open: true,
  //     title: "Code Copied!!",
  //     text1: "Live Activation Code",
  //     text2: Playlist,
  //     handleClose: (bool) => {
  //       setgeneratedCodeObj({...generatedCodeObj, open: false, handleClose: () => {}
  //     })
  //   }

  // })
  // navigator.clipboard.writeText(Playlist)
  // }

  const fatchPreUrlData = async (filterObjArr) => {
    try {
      presignedUploadUrlArr = [];
      setPostContent([]);
      handleLoader(true);
      const body = {
        userId: userData?.UserID,
        files: filterObjArr,
      };

      const res = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${PREE_SIGNED_URL}`,
        body,
        history
      );

      if (handleResponseCode(res)) {
        handleLoader(false);
        res?.data.forEach((e) => presignedUploadUrlArr.push(e));
        if (discription.contentType == 2) {
          getDuration(filterObjArr[0].file);
          setPostContent(
            res?.data.map((e) => ({
              fileName: e.FileKey,
              fileType: e.FileExtenssion.toLowerCase() == "mp4" ? 2 : 1,
              fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
            }))
          );
        } else {
          getDuration(filterObjArr[0].file);
          setPostContent(
            res?.data.map((e) => ({
              fileName: e.FileKey,
              fileType: e.FileExtenssion == "mp4" ? 2 : 1,
              fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
            }))
          );
        }
      }
    } catch (error) {
      // Handle error here
      console.error("Error fetching data:", error);
      handleLoader(false);
    }
  };

  const [prodIds, setProdIds] = useState([]);
  const updateProductIds = (productId) => {
    console.log("updateProductIdsproductId", productId);
    setProdIds(productId);
  };

  console.log("PostContent==>", PostContent);

  function getDuration(file) {
    var video = document.createElement("video");
    video.src = URL.createObjectURL(file);
    video.preload = "metadata";
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      setDiscription({ ...discription, videoDuration: video.duration });
      console.log("Video Duration==>", video.duration);
      return video.duration;
      // setVideoDuration(video.duration);
    };
    // video.src  = file
  }

  const fetchPreUrlDataThumblail = async (filterObjArr) => {
    preAssignthumbnailArr = [];
    setThumbnailContent([]);
    handleLoader(true);
    const body = {
      userId: 80,
      files: filterObjArr,
    };

    await postAuthAndData(
      `${ENGT_PROD_BASE_URI}${PREE_SIGNED_URL}`,
      body,
      history
    ).then((res) => {
      if (handleResponseCode(res)) {
        handleLoader(false);
        res?.data.forEach((e) => preAssignthumbnailArr.push(e));
        setThumbnailContent(
          res?.data.map((e) => ({
            fileName: e.FileKey,
            fileType: e.FileExtenssion == "mp4" ? 2 : 1,
            fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
          }))
        );
      }
    });
    handleLoader(false);
  };

  console.log("tvhumbnailContent==>", thumbnailContent);

  const addContent = (fobj) => {
    console.log("addContent ffob======", fobj);
    console.log("addContent ffobExtenssion======", fobj);
    let contentFile;
    if (fobj.contentType == 1) {
      contentFile = `image/${fobj?.extension}`;
    } else {
      contentFile = `video/${fobj?.extension}`;
    }
    return contentFile;
  };

  const uploadDataOnAwsLive = () => {
    handleLoader(true);
    //local variable isUploadSuccess = true
    try {
      console.log("uploadDataOnAws=====$$$");
      if (discription.contentType != 5) {
        if (presignedUploadUrlArr?.length == 0) isUploadToAWSSuccess = false;
      }
      presignedUploadUrlArr?.forEach((presignedUploadUrl) => {
        //if()
        let fobj = fileObj.find((e) => e.FileId == presignedUploadUrl.FileId);
        console.log("fileObj=====$$$", fobj);
        let conetntType = addContent(fobj);
        console.log("conetntType=====$$$", conetntType);
        const requestOptions = {
          method: "PUT",
          headers: {
            // "Content-Type": fileObj.find(
            //   (e) => e.FileId == presignedUploadUrl.FileId
            // ).file.type,

            "Content-Type": conetntType,
            "Access-Control-Allow-Origin": "*",
          },
          body: fileObj.find((e) => e.FileId == presignedUploadUrl.FileId).file,
        };
        console.log("requestOptions====$$", requestOptions);
        fetch(
          new Request(presignedUploadUrl.PreSignedUrl, requestOptions)
        ).then((response) => {
          //console("uploadDataOnAws Flow1", response);
          if (response.status == 200) {
            //setFlag(true);
            console.log("NEW CHNAGE==");
            // createPost();
            createPostLive();

            // createEngagementPost(isUploadToAWSSuccess);
          } else {
            isUploadToAWSSuccess = false;
            createNotification(
              "error",
              SOMETHING_WENT_WRONG +
                "Error uploading image to cloud, please try again."
            );
            //setFlag(false);
          }
        });
        handleLoader(false);
      });

      // if(isUploadSuccess)
      // {
      //   //postdata
      // }
      //  responsehandler(flag);
      //Calling Thumbnail Upload
    } catch {
      isUploadToAWSSuccess = false;
      createNotification(
        "error",
        SOMETHING_WENT_WRONG +
          "Error uploading image to cloud, please try again."
      );
    }
    handleLoader(false);
  };

  const uploadDataOnAws = () => {
    handleLoader(true);
    //local variable isUploadSuccess = true
    try {
      console.log("uploadDataOnAws=====$$$");
      if (discription.contentType != 5) {
        if (presignedUploadUrlArr?.length == 0) isUploadToAWSSuccess = false;
      }
      presignedUploadUrlArr?.forEach((presignedUploadUrl) => {
        //if()
        let fobj = fileObj.find((e) => e.FileId == presignedUploadUrl.FileId);
        console.log("fileObj=====$$$", fobj);
        let conetntType = addContent(fobj);
        console.log("conetntType=====$$$", conetntType);
        const requestOptions = {
          method: "PUT",
          headers: {
            // "Content-Type": fileObj.find(
            //   (e) => e.FileId == presignedUploadUrl.FileId
            // ).file.type,

            "Content-Type": conetntType,
            "Access-Control-Allow-Origin": "*",
          },
          body: fileObj.find((e) => e.FileId == presignedUploadUrl.FileId).file,
        };
        console.log("requestOptions====$$", requestOptions);
        fetch(
          new Request(presignedUploadUrl.PreSignedUrl, requestOptions)
        ).then((response) => {
          //console("uploadDataOnAws Flow1", response);
          if (response.status == 200) {
            //responsehandler(true);
            //isUploadSuccess = false;
            //setFlag(true);
            // uploadDataOnAws2();
          } else {
            //setFlag(false);
            // responsehandler(false);
            //If any file is failing to upload - We shall not create the Post - Please try again
            isUploadToAWSSuccess = false;
            createNotification(
              "error",
              SOMETHING_WENT_WRONG +
                "Error uploading image to cloud, please try again."
            );
          }
        });
        handleLoader(false);
      });

      // if(isUploadSuccess)
      // {
      //   //postdata
      // }
      //  responsehandler(flag);
      //Calling Thumbnail Upload
    } catch {
      isUploadToAWSSuccess = false;
      createNotification(
        "error",
        SOMETHING_WENT_WRONG +
          "Error uploading image to cloud, please try again."
      );
    }
    handleLoader(false);
  };

  const uploadDataOnAws2 = () => {
    try {
      if (preAssignthumbnailArr?.length == 0) isUploadToAWSSuccess = false;

      preAssignthumbnailArr?.forEach((presignedUploadUrl) => {
        let thumbnailFileObj = thumbnailUrl.find(
          (e) => e.FileId == presignedUploadUrl.FileId
        );

        console.log("thumbnailFileObj=====$$$", thumbnailFileObj);
        let conetntType = addContent(thumbnailFileObj);
        console.log("thumbnail conetntType =====$$$", conetntType);
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": conetntType,
            "Access-Control-Allow-Origin": "*",
          },
          body: thumbnailUrl.find((e) => e.FileId == presignedUploadUrl.FileId)
            .file,
        };
        ////console("before fatch data in thumbnailContent");
        fetch(
          new Request(presignedUploadUrl.PreSignedUrl, requestOptions)
        ).then((response) => {
          ////console("uploadDataOnAws Flow2", response.status);
          if (response.status == 200) {
            //setFlag(true);
            console.log("NEW CHNAGE==");
            // createEngagementPost(isUploadToAWSSuccess);
          } else {
            isUploadToAWSSuccess = false;
            createNotification(
              "error",
              SOMETHING_WENT_WRONG +
                "Error uploading image to cloud, please try again."
            );
            //setFlag(false);
          }
        });
      });
      //Once Thumb nail is added - Save Post Data
    } catch {
      isUploadToAWSSuccess = false;
      createNotification(
        "error",
        SOMETHING_WENT_WRONG +
          "Error uploading image to cloud, please try again."
      );
    }
    handleLoader(false);

    //After the 2nd Upload Initiate the Post
  };

  const createEngagementPost = (isAWSSuccess) => {
    console.log("Success Flow ===", isAWSSuccess);
    createPost();

    // if (isAWSSuccess) {
    //   //Send Data to Server
    //   // createPost();
    // } else {
    //   createNotification(
    //     "error",
    //     SOMETHING_WENT_WRONG +
    //     "Error uploading image to cloud, please try again."
    //   );
    // }
  };

  const fatchProductDetail = async (id) => {
    handleLoader(true);
    const body = {
      Sku: activeProductFilter == "sku" ? inputMsg : "",
      PortalProductId: activeProductFilter === "id" ? inputMsg : "",
      ProductName: activeProductFilter == "name" ? inputMsg : "",
      ProductId: activeProductFilter == "id" ? inputMsg : "",
    };
    try {
      postAuthAndData(
        `${EVNT_PROD_HOST_URI}${GET_PRODUCT_DETAILS}`,
        body,
        history
      ).then((res) => {
        console.log("Result", res);
        if (handleResponseCode(res)) {
          handleLoader(false);
          setProductDetail(res.data);
          setDiscription({
            ...discription,
            productId: res?.data[0]?.productId,
          });
        }
      });
      // const data = await postAuthAndData(
      //   `${EVNT_PROD_HOST_URI}${GET_PRODUCT_DETAILS}`,
      //   body,
      //   history
      // );
      // setProductDetail(data.data);
      // props.setDiscription({
      //   ...props.discription,
      //   productId: data?.data[0]?.productId,
      // });
      // console.log("data@@@@", data.data);
    } catch (err) {}
    handleLoader(false);
  };

  const checkManadatoryFields = () => {
    let hasMandatoryFieldmissing = true;
    console.log("ajay03 productIds.length", prodIds.length);

    if (prodIds.length < 1) {
      hasMandatoryFieldmissing = false;
      createNotification("warning", "Please add atleast one product.");
      return hasMandatoryFieldmissing;
    }
    if (
      !(discription?.thumbnailTitle?.length > 0) &&
      discription.contentType != 5 &&
      !(discription?.buttonCta?.length > 0)
    ) {
      hasMandatoryFieldmissing = false;
      createNotification("warning", "Enter banner title.");
      hasMandatoryFieldmissing = false;
    } else if (
      !(discription?.discription?.length > 0) &&
      discription.contentType != 5
    ) {
      hasMandatoryFieldmissing = false;
      createNotification("warning", "Enter description.");
    } else if (
      discription.contentType == 5 &&
      new Date(discription?.liveDateTime).getTime() < new Date().getTime()
    ) {
      hasMandatoryFieldmissing = false;
      handleLoader(false);
      createNotification(
        "warning",
        "Enter  DateTime value grater then currunt dateTime."
      );
    }
    ////console("hasMandatoryFieldmissing", hasMandatoryFieldmissing);

    return hasMandatoryFieldmissing;
  };

  const createPost = (isAWSSuccess) => {
    //Check for Product List

    // debugger;
    handleLoader(true);
    let datacorret = checkManadatoryFields();

    ////console("datacorret=====post", datacorret);
    console.log("00productIds", productIds);
    console.log("00prodIds", prodIds);
    console.log("ajay03 datacorret", datacorret);
    console.log("thumbnailContent", thumbnailContent);
    if (datacorret) {
      const body = {
        IsMultiHost: discription.isMulti || false,
        userId: userData?.UserID,
        description: discription.discription,
        tags: discription.tag,
        contentType: discription.contentType,
        postText: "",
        textfontFamily: discription.fontFamily,
        captionFont: discription.fontFamily,
        captionColor: discription.ColorPicker,
        ctaImageId: discription.buttonType,
        buttonType: discription.buttonType,
        caption: discription.caption,
        buttonHeader: discription.caption,
        cTAUrl: discription.targetCatogery,
        postContents: PostContent,
        // productId: productIds,
        ProductIdList: [...prodIds],
        Thumbnail_URL: thumbnailContent[0]?.fileUrl,
        Thumbnail_Title: discription?.thumbnailTitle,
        Button_Cta: discription?.buttonCta,
        // liveDateTime: 1674680400000,
        Video_duration:
          discription.contentType === 2 ? discription.videoDuration : 0,
        liveDateTime: discription?.liveDateTime,
        // liveDateTime: discription.contentType === 2 ? discription?.livedate : 1674680400000,
      };
      console.log("body", body);

      postAuthAndData(`${ENGT_PROD_BASE_URI}${SAVE_POST_DATA}`, body, history)
        .then((res) => {
          console.log(res, "res ======================");

          if (!!res?.data?.enagement_post_id) {
            createNotification("success", "Post created successfully.");
            // if(discription.contentType == 5)
            // copyGeneratedCode(res?.data.schedule_code)
            setLiveCode(res.data.schedule_code.split("_")[0]);
            handleReset();
            setDiscription({
              tag: "",
              discription: "",
              targetCatogery: "",
              caption: "",
              fontFamily: "",
              ColorPicker: "",
              buttonType: 0,
              contentType: 0,
              productId: "",
              thumbnailTitle: "",
              buttonCta: "",
              liveDateTime: "",
            });
            setPostContent([]);
            setThumbnailContent([]);
            setDiscription(initialDiscription);
            setProductDetail([]);
            productIds.length = 0;
            setProdIds([]);
            handleLoader(false);
          } else {
            if (res.code === 400) {
              createNotification("error", res?.data?.error);
            } else {
              createNotification(
                "warning",
                "You have exceeded the Allocated Channels for Live Session."
              );
            }
            handleLoader(false);
          }
          handleLoader(false);
        })
        .catch((err) => console.log(err));
      handleLoader(false);
    } else {
      handleLoader(false);

      // createNotification(
      //   "warning",
      //   discription?.discription
      //     ? "Please attach a product for Shoppable Video,"
      //     : "Enter description | Enter banner image"
      // );
    }
  };

  const createPostLive = () => {
    // debugger;
    // handleLoader(true);
    // let datacorret = checkManadatoryFields();
    ////console("datacorret=====post", datacorret);
    // if (datacorret) {
    const body = {
      IsMultiHost: discription.isMulti || false,
      // thumbnailTitle: discription.thumbnailTitle,
      userId: userData?.UserID,
      description: discription.discription,
      tags: discription.tag,
      contentType: discription.contentType,
      postText: "",
      textfontFamily: discription.fontFamily,
      captionFont: discription.fontFamily,
      captionColor: discription.ColorPicker,
      ctaImageId: discription.buttonType,
      buttonType: discription.buttonType,
      caption: discription.caption,
      buttonHeader: discription.caption,
      cTAUrl: discription.targetCatogery,
      postContents: PostContent,
      productId: productIds[0],
      Thumbnail_URL: thumbnailContent[0]?.fileUrl,
      Thumbnail_Title: discription?.thumbnailTitle,
      Button_Cta: discription?.buttonCta,
      // liveDateTime: 1674680400000,
      Video_duration:
        discription.contentType === 2 ? discription.videoDuration : 0,
      liveDateTime: discription?.liveDateTime,
    };

    postAuthAndData(`${ENGT_PROD_BASE_URI}${SAVE_POST_DATA}`, body, history)
      .then((res) => {
        console.log(res, "res Live ======================");
        if (handleResponseCode(res)) {
          createNotification("success", "Post created successfully.");
          handleReset();
          setDiscription({
            isMulti: "",
            tag: "",
            discription: "",
            targetCatogery: "",
            caption: "",
            fontFamily: "",
            ColorPicker: "",
            buttonType: 0,
            contentType: 0,
            productId: "",
            thumbnailTitle: "",
            buttonCta: "",
            liveDateTime: "",
          });
        }
      })
      .catch((err) => console.log(err));
    // } else {
    // createNotification(
    //   "warning",
    //   discription?.discription
    //     ? "Please attach a product for Shoppable Video,"
    //     : "Enter description | Enter banner image"
    // );
    // }
    handleLoader(false);
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  console.log("activeStep", activeStep);
  const handleNext = (contentType) => {
    // setSecondPage(true);
    if (fileObj.length > 0 && contentType != 0) {
      setPageType(false);
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      // if(activeStep === steps.length - 1)

      setSkipped(newSkipped);
      console.log("steps", steps.length - 1);
      if (activeStep === 1) {
        ////console();

        // let datacorret = checkManadatoryFields();
        // if (datacorret) {
        //   console.log("datacorret=====aws", datacorret);
        uploadDataOnAws();
        //uploadDataOnAws2();
        //createEngagementPost(isUploadToAWSSuccess);
        setFileObj([]);
        setThumbnailUrl([]);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if ((thumbnailUrl.length > 0 && contentType != 0) || liveSchedule) {
      setPageType(false);
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      // if(activeStep === steps.length - 1)

      setSkipped(newSkipped);
      console.log("steps", steps.length - 1);
      if (activeStep === 2) {
        ////console();

        // let datacorret = checkManadatoryFields();
        // if (datacorret) {
        //   console.log("datacorret=====aws", datacorret);
        // uploadDataOnAws();
        uploadDataOnAws2();
        //createEngagementPost(isUploadToAWSSuccess);
        setFileObj([]);
        setThumbnailUrl([]);

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 3) {
      setPageType(false);
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      // if(activeStep === steps.length - 1)

      setSkipped(newSkipped);
      console.log("steps ==>", steps.length - 1);
      if (activeStep === steps.length) {
        createEngagementPost(isUploadToAWSSuccess);
      }
    } else {
      createNotification(
        "warning",
        "Add an image/video to proceed or contentType"
      );
    }
  };

  const handleLiveNext = (contentType) => {
    if (thumbnailUrl.length > 0 && contentType != 0) {
      setPageType(false);
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      // if(activeStep === steps.length - 1)

      setSkipped(newSkipped);
      console.log("steps", steps.length - 1);
      if (activeStep === 1) {
        ////console();

        // let datacorret = checkManadatoryFields();
        // if (datacorret) {
        //   console.log("datacorret=====aws", datacorret);
        // uploadDataOnAws();

        uploadDataOnAws2();
        //createEngagementPost(isUploadToAWSSuccess);
        setFileObj([]);
        setThumbnailUrl([]);

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 2) {
      setPageType(false);
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      setSkipped(newSkipped);
      console.log("steps ==>", steps.length - 1);
      if (activeStep === steps.length) {
        createEngagementPost(isUploadToAWSSuccess);
      }
    } else {
      createNotification(
        "warning",
        "Add an image/video to proceed or contentType"
      );
    }
  };

  const handleNextLive = (e) => {
    // setSecondPage(true);
    if (fileObj.length > 0) {
      setPageType(false);
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      // if(activeStep === steps.length - 1)

      setSkipped(newSkipped);
      console.log("activeStep", activeStep);
      console.log("steps", steps.length - 1);
      // if (activeStep === steps.length) {
      //   ////console();

      //   let datacorret = checkManadatoryFieldsLive();
      //   if (datacorret) {
      //     console.log("datacorret=====aws", datacorret);
      //     uploadDataOnAws();
      //     //uploadDataOnAws2();
      //     //createEngagementPost(isUploadToAWSSuccess);
      //     setFileObj([]);
      //     setThumbnailUrl([]);
      //   }
      // } else {
      //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
      // }
    } else {
      createNotification(
        "warning",
        "Add an image/video to proceed or contentType"
      );
    }
  };

  const handleBack = (e) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setPageType(true);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setPageType(true);
    setActiveStep(1);
    setDiscription({
      tag: "",
      discription: "",
      targetCatogery: "",
      caption: "",
      fontFamily: "",
      ColorPicker: "",
      buttonType: 0,
      contentType: 0,
      productId: "",
      thumbnailTitle: "",
      buttonCta: "",
      liveDateTime: "",
      isMulti: "",
    });
  };

  const contentType = (event) => {
    //setFileObj([]);
    if (event.target.value === "Story") {
      setStoryFlage(true);
      setDiscription({ ...discription, contentType: 1 });
    } else {
      setStoryFlage(false);
    }
    if (event.target.value === "Post")
      setDiscription({ ...discription, contentType: 2 });

    if (event.target.value === "LiveSchedule") {
      setLiveSchedule(true);
      setDiscription({ ...discription, contentType: 5 });
      setPageType(false);
      if (activeStep !== steps.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setLiveSchedule(false);
    }
  };

  const removeAll = () => {
    setFileObj([]);
  };

  const removeAll2 = () => {
    setThumbnailUrl([]);
  };

  const newFileUploadThumbnail = (acceptedFiles) => {
    setThumbnailUrl([]);
    const filterObjArr = [];
    if (acceptedFiles?.length == 1) {
      acceptedFiles?.forEach((file) => {
        let extensionType = file.name.split(".").pop();
        if (
          extensionType == "jpeg" ||
          extensionType == "jpg" ||
          extensionType == "mp4" ||
          extensionType == "png" ||
          extensionType == "webp"
        ) {
          if (file.size < 3000000) {
            const obj = {
              extension: file.name.split(".").pop(),
              contentType: file.name.split(".").pop() == "mp4" ? "2" : "1",
              file: file,
              FileId: ` ${Date.now()}_${file.name}`,
            };
            filterObjArr.push(obj);
          } else {
            createNotification(
              "warning",
              "Image size should be less than 3 MB."
            );
          }
        } else {
          createNotification(
            "warning",
            "Only jpeg, jpg, png, webp file type is allowed."
          );
        }
      });
    } else {
      createNotification("warning", "Only one image allowed");
    }

    setThumbnailUrl(filterObjArr);
    console.log("thumbnail url", thumbnailUrl);
    console.log("filter Obj", filterObjArr);
    fetchPreUrlDataThumblail(filterObjArr);
  };

  const changeTitle = (event) => {
    //////console("changeTage", event.target.value);
    setDiscription({
      ...discription,
      thumbnailTitle: event.target.value,
    });
  };
  const changeButtonCta = (event) => {
    setDiscription({
      ...discription,
      buttonCta: event.target.value,
    });
  };

  return {
    activeStep,
    pageType,
    discription,
    fileObj,
    thumbnailUrl,
    thumbnailContent,
    inputMsg,
    liveSchedule,
    productIds,
    storyFlage,
    productDetail,
    PostContent,
    activeDetailsFilter,
    activeProductFilter,
    initialDiscription,
    onDrop,
    setProductDetail,
    fatchProductDetail,
    setLiveSchedule,
    setInputMsg,
    setActiveDetailsFilter,
    setActiveProductFilter,
    setActiveStep,
    setPageType,
    setDiscription,
    isStepOptional,
    isStepSkipped,
    handleNext,
    handleNextLive,
    uploadDataOnAwsLive,
    handleBack,
    handleSkip,
    handleReset,
    contentType,
    removeAll,
    removeAll2,
    newFileUploadThumbnail,
    changeTitle,
    changeButtonCta,
    handleLiveNext,
    updateProductIds,
    liveCode,
    setLiveCode,
    prodIds,
    setProdIds,
  };
}

export default Usestoryhook;
