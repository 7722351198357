import React, { Fragment, useEffect, useState } from "react";
import _ from "lodash";
import Select from "react-select";
import "./prerequisiteRules.css";
import p_rule_src from "../../../../assets/img/Setting_option.svg";
import { BUDGET_CURRENCY } from "../../../../constants/globalConstants";
import createNotification from "../../../common/reactNotification";
import { TextField } from "@material-ui/core";
import { getAuthAndData } from "../../../../api/ApiHelper";
import {
  PREDICTION_MASTER_DATA,
  ENGT_PROD_BASE_URI,
} from "../../../../api/apiConstants";
import { useHistory } from "react-router";
import {
  Checkbox,
  Input,
  ListItemText,
  MenuItem,
  Select as MaterialUiSelect,
} from "@material-ui/core";

const rule1options = [{ value: "Has Purchased", label: "Has Purchased" }];
const rule2options = [
  { value: "Greater than or Equal To", label: "Greater than or Equal To" },
  //{ value: 'Less Than Or Equal To', label: 'Less Than Or Equal To' }
];
const rule4options = [{ value: "In Last", label: "In Last" }];
const DaysTypeOptions = [
  { value: "Days", label: "Days" },
  { value: "Week", label: "Week" },
  { value: "Month", label: "Month" },
];

export default function PreRequisiteRules(props) {
  const goal = props?.props?.setGoals;
  const definePurchaseRule = props?.definePurchaseRule;
  console.log("*AAA**", props);
  const preRules = props.props?.preRules;
  const preSelectedId =
    preRules?.EngagementPredictionDetails?.PredictionGroupMasterId;
  const predictionPareticipantsIds =
    preRules?.EngagementPredictionDetails?.PredictionPareticipantsIds;
  const [costEnable, setCostEnable] = useState(
    preRules?.costToPlay ? true : false
  );
  const [purchaseRuleEnable, setPurchaseRuleEnable] = useState(
    preRules?.purchaseValue || preRules?.purchaseRuleId ? true : false
  );
  const [purchaseValue, setPurchaseValue] = useState(preRules?.purchaseValue);
  const [durationNum, setDurationNum] = useState(preRules?.durationNum || "7");
  const [rule1, setRule1] = useState(rule1options[0]);
  const [rule2, setRule2] = useState(rule2options[0]);
  const [rule4, setRule4] = useState(rule4options[0]);
  const [masterData, setMasterData] = useState([]);
  const [groupInfo, setGroupInfo] = useState([]);
  const [pickedParticipent, setPickedParticipent] = useState([]);
  const [participantInfo, setParticipantInfo] = useState([]);
  const [groupElementId, setGroupElementId] = useState({});
  const [predictionOption, setPredictionOption] = useState();
  const [daysType, setDaysType] = useState(
    preRules
      ? { value: preRules?.daysType, label: preRules?.daysType }
      : DaysTypeOptions[0]
  );
  const [costToPlay, setCostToPlay] = useState(preRules?.costToPlay);
  const history = useHistory();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [error, setError] = useState({});
  const [predictionData, setPredictionData] = useState({});

  const rule1Change = (event) => {
    console.log("event", event);
    setRule1(event);
  };
  const changeGroupInfo = (event) => {
    // setParticipantInfo([])
    setGroupElementId(event);
    console.log("changeGroupInfo===", event);
    const filterData = masterData.find(
      (e) => e.Predictiongroup_Id == event.value
    );
    console.log("filterData====", filterData);
    filterData?.PredictionParticipants?.map((e) => {
      const participantObj = {
        value: e.prediction_participant_id,
        name: e.participant_name,
        groupId: e.group_id,
      };
      participantInfo.push(participantObj);
      // setParticipantInfo([...participantInfo,participantObj])
    });
  };

  const changeParticipant = (event) => {
    setSelectedCategories(event.target.value);
    const participantIds = [];
    setError({ ...error, selectedCategories: "" });
    console.log("you select some thing other", event);
    event.target.value.forEach((cname) => {
      let found = participantInfo?.find((ct) => ct.name == cname);
      if (found) {
        participantIds.push(found.value);
      }
    });
    // const participantIds = event.target.value.map((e)=>(e.target.value))
    // console.log("participantId======", participantIds)
    const EngagementPredictionDetails = {
      PredictionGroupMasterId: groupElementId.value,
      PredictionPareticipantsIds: participantIds,
    };
    // if(preRules?.EngagementPredictionDetails != null || props.abTestFlage){
    //     console.log("im if statement=====")
    //   props.setPredictAndWinREquest( preRules?.EngagementPredictionDetails)
    // }else{
    props.setPredictAndWinREquest(EngagementPredictionDetails);
    //predictionData.push(EngagementPredictionDetails)
  };

  const rule2Change = (event) => {
    setRule2(event);
  };
  const rule3Change = (event) => {
    let val = event.target.value;
    if (!val || val?.length <= 7) {
      setPurchaseValue(val ? parseInt(val.replace(",", "")) : "");
      props.setDefinePurchaseRule({ enable: true, value: val });
    }
  };
  const rule4Change = (event) => {
    setRule4(event);
  };
  const rule5Change = (event) => {
    let val = event.target.value;
    if (val && (val > 99 || val < 1)) {
      return false;
    }
    setDurationNum(val);
  };
  const rule6Change = (event) => {
    setDaysType(event);
  };

  const onCostChange = (e) => {
    if (e.target.value <= 999) {
      setCostToPlay(e.target.value);
      props.setDefineCost({ enable: true, value: e.target.value });
    }
  };

  const onPurchaseRuleCheck = (e) => {
    setPurchaseRuleEnable(e.target.checked);
    let checked = e.target.checked;
    props.setDefinePurchaseRule({ enable: checked, value: "" });
    if (!checked) {
      setPurchaseValue();
      setDurationNum();
    }
  };
  const onCostCheck = (e) => {
    setCostEnable(e.target.checked);
    let checked = e.target.checked;
    props.setDefineCost({ enable: checked, value: "" });
  };

  useEffect(() => {
    return () => {
      let preRulesData = {
        purchaseRuleId: preRules?.purchaseRuleId || 0,
        purchaseValue: purchaseValue,
        durationNum: durationNum,
        daysType: daysType.value,
        costToPlay: costToPlay,
        // EngagementPredictionDetails:definePurchaseRule,
      };
      props.props.engagementsSmartActionHandler.dispatchPreRules(preRulesData);
    };
  }, [costToPlay, purchaseValue, durationNum, daysType, purchaseRuleEnable]);

  useEffect(() => {
    if (costEnable && purchaseRuleEnable) {
      createNotification(
        "warning",
        "Adding Too Many Pre-requisites may reduce the participation"
      );
    }
    props.setDefinePurchaseRule({
      enable: purchaseRuleEnable,
      value: purchaseValue,
    });
    props.setDefineCost({ enable: costEnable, value: costToPlay });
  }, [costEnable, purchaseRuleEnable]);

  const fatchPredictionMasterData = async () => {
    try {
      const data = await getAuthAndData(
        `${ENGT_PROD_BASE_URI}${PREDICTION_MASTER_DATA}`,
        history
      );
      console.log("fatchPredictionMasterData", data?.data);
      setMasterData(data?.data);
      data?.data?.map((e) => {
        const groupObj = {
          value: e.Predictiongroup_Id,
          label: e.Group_Name,
        };
        groupInfo.push(groupObj);
        // setGroupElementId(groupInfo[0])
      });
    } catch {}
  };

  useEffect(() => {
    fatchPredictionMasterData();
    if (preRules?.EngagementPredictionDetails != null) {
      let participantNames = [];
      const preSelectedGroupobj = groupInfo?.find(
        (e) => e.value == preSelectedId
      );
      console.log("preSelectedGroupobj===", preSelectedGroupobj);
      setGroupElementId(preSelectedGroupobj);
      //groupElementId.push(preSelectedGroupobj)
      predictionPareticipantsIds?.forEach((cid) => {
        let found = participantInfo?.find((ct) => ct.value == cid);
        console.log("found====", found);
        if (found) {
          participantNames.push(found.name);
        }
      });
      console.log("participantNames=====", participantNames);
      // selectedCategories.push(participantNames)
      setSelectedCategories(participantNames);
      // participantNames = []
    }
  }, []);
  console.log("groupInfo====", groupInfo);
  console.log("participantInfo===", participantInfo);

  console.log("anand singh");
  return (
    <div
      id="define-journey-container"
      className="c-e-journey-sec w-100 float-left clearfix"
    >
      <div className="c-e-journey-h">Choose Prerequisite Rules</div>
      {goal.engagementType != 3 ? (
        <div>
          <div>
            <span style={{ fontSize: "14px" }}>Cost to Play</span>
            <span>
              <input
                type="checkbox"
                checked={costEnable}
                onChange={onCostCheck}
                style={{
                  margin: "8px",
                  opacity: props.abTestFlage
                    ? ""
                    : props?.updateEngagement
                    ? "0.4"
                    : "",
                }}
                disabled={props.abTestFlage ? "" : props?.updateEngagement}
              />
            </span>
            <span className={`${costEnable ? "" : "disable-purchase-rule"}`}>
              <input
                type="number"
                value={costToPlay}
                onChange={onCostChange}
                className="cost-to-play"
                placeholder="Enter Cost"
              />
            </span>
          </div>
          <div className="c-e-journey-boxes w-100 float-left clearfix">
            <div className="w-100 float-left clearfix c-e-target-p-rule pb-4">
              <img src={p_rule_src} alt="Purchase Rule" className="mr-1" />
              Purchase Rule&nbsp;&nbsp;&nbsp;
              <input
                type="checkbox"
                checked={purchaseRuleEnable}
                onChange={onPurchaseRuleCheck}
                // disabled={props.abTestFlage ? "" : props?.updateEngagement}
                disabled={props?.updateEngagement}
                style={{
                  opacity: props.abTestFlage
                    ? ""
                    : props?.updateEngagement
                    ? "0.4"
                    : "",
                }}
              />
            </div>
            <div
              className={`w-100 float-left clearfix c-e-target-p-rule-opt ${
                purchaseRuleEnable ? "" : "disable-purchase-rule"
              }`}
            >
              <Select
                options={rule1options}
                value={rule1}
                onChange={rule1Change}
                className="w-20 p-r-10 float-left clearfix"
              />
              <Select
                options={rule2options}
                value={rule2}
                onChange={rule2Change}
                className="w-22 p-r-10 float-left clearfix"
              />
              <div className="w-15 m-r-10 float-left clearfix">
                <div className="w-34 float-left clearfix p-rule-value-left">
                  <span className="w-100 p-rule-value-txt">
                    {BUDGET_CURRENCY}
                  </span>
                </div>
                <div className="w-60 float-left clearfix p-rule-value-right">
                  <input
                    type="text"
                    value={
                      purchaseValue
                        ? parseInt(purchaseValue).toLocaleString()
                        : ""
                    }
                    onChange={rule3Change}
                    className="p-rule-input"
                    placeholder="Value"
                    maxLength={6}
                  />
                </div>
              </div>
              <Select
                options={rule4options}
                value={rule4}
                className="w-10 p-r-10 float-left clearfix "
              />
              <div className="w-5 m-r-10 float-left clearfix t-a-r-5 p-rule-duration">
                <input
                  type="number"
                  id="t-a-r-5"
                  value={durationNum}
                  onChange={rule5Change}
                  max={90}
                  min={1}
                  className="p-rule-input"
                />
              </div>
              <Select
                options={DaysTypeOptions}
                value={daysType}
                onChange={rule6Change}
                className="w-10 p-r-10 float-left clearfix"
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          // disabled={props.abTestFlage ? "" : props?.updateEngagement}
          style={{
            opacity: props?.abTestFlage
              ? ""
              : props?.updateEngagement
              ? "0.4"
              : "",
            pointerEvents: props.updateEngagement ? "none" : "",
          }}
        >
          <Select
            options={groupInfo}
            value={groupElementId}
            onChange={changeGroupInfo}
            className="w-20 p-r-10 float-left clearfix"
          />
          <div
            className="w-100 float-left clearfix"
            style={{ paddingTop: "18px" }}
          >
            <div className="target-category-text">Target Category</div>
            <MaterialUiSelect
              variant="outlined"
              label="Select Category"
              labelId="Select Categories"
              multiple
              value={selectedCategories}
              onChange={changeParticipant}
              input={<Input />}
              renderValue={(selected) => selected.join(", ")}
              style={{ width: "600px" }}
            >
              {participantInfo.map((c) => (
                <MenuItem
                  key={c.name}
                  value={c.name}
                  style={{ height: "34px" }}
                >
                  <Checkbox checked={selectedCategories.indexOf(c.name) > -1} />
                  <ListItemText primary={c.name} />
                </MenuItem>
              ))}
            </MaterialUiSelect>
            {error.selectedCategories && (
              <p
                style={{ fontSize: "10px", fontFamily: "Roboto", color: "red" }}
              >
                {error.selectedCategories}
              </p>
            )}
          </div>
          {/* <Select closeMenuOnSelect={false} 
              isMulti options={participantInfo} 
              defaultValue={[1,2]} 
              onChange={changeParticipant} 
              className="w-22 p-r-10 float-left clearfix" 
          /> */}
        </div>
      )}
    </div>
  );
}
