import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStateWithHistory } from "react-use";
import { getAuthAndData, postAuthAndData } from "../../../api/ApiHelper";
import {
  CREATE_PLAY_LIST,
  ENGT_PROD_BASE_URI,
  ENGT_PROD_HOST_URI,
  EVNT_PROD_HOST_URI,
  GET_ALL_PLAYLIST,
  GET_ALL_PRODUCT,
  GET_YOUTUBE_DATA,
  PREE_SIGNED_URL,
  PROCESS_AND_SAVE_BASEURL_TO_AWS,
  SAVE_POST_DATA,
  SAVE_TO_AWS,
  SAVE_TO_CLOUDINARY,
} from "../../../api/apiConstants";
import { Dark_Mode, Light_Mode } from "../igHandler/igHandler";
import { useHistory } from "react-router";
import { dispatchLoaderData } from "../../../actions/route/routeActionHandler";
import createNotification from "../../common/reactNotification";
import axios from "axios";
import {
  Delete_Product_Array,
  Selected_Youtube_Array,
} from "../../../constants/actionTypes";
import { json } from "d3";
import { Auth } from "aws-amplify";
import { useAppContext } from "../../../contexts/appContext";
let presignedUploadUrlArr = [];
// let preAssignthumbnailArr = [];

let igArrays = [];
let ytArrays = [];
let videoduration = 0;

const UseIgHook = () => {
  const [productArray, setProductArray] = useState([]);
  let userData = useSelector((select) => select.userReducer.userData);

  const [igFileObj, setIgFileObj] = useState([]);

  const [igSelectedData, setIgSelectedData] = useState([]);
  const [ytSelectedData, setYtSelectedData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const [array1, setArray1] = useState([]);
  const [array2, setArray2] = useState([]);
  const [array3, setArray3] = useState([]);
  const [ytContentUrl, setYtContentUrl] = useState("");

  const { isInstaSelected, isYoutubeSelected } = useAppContext();

  useEffect(() => {
    igArrays = igSelectedData;
    console.log("igArrayse", igArrays);
  }, [igSelectedData]);

  // useEffect(() => {
  //   console.log("useEffectytSelectedData", ytArrays);
  //   updateSelectedArray(ytSelectedData);
  // }, [ytSelectedData]);

  const dispatchResponse = useDispatch();

  const updateSelectedArray = (ytdata) => {
    console.log("response yt", ytdata);
    dispatchResponse({ type: Selected_Youtube_Array, payload: ytdata });
  };

  useEffect(() => {
    console.log("ytSelectedData", ytSelectedData);
    ytArrays = ytSelectedData;
    console.log("ytArrays", ytArrays);
  }, [ytSelectedData]);

  console.log("igArrays", igArrays);
  console.log("igSelectedData", igSelectedData);
  console.log("productArray", productArray);

  let arr = useSelector((select) => select.selectedProductArrayReducer.arr);
  console.log("selected arr", arr);

  const getInstagramVideoData = async (videoData) => {
    handleLoader(true);
    console.log("videoData", videoData);
    const body = {
      file_name: videoData.caption,
      filePath: videoData.media_url,
    };
    await postAuthAndData(`${ENGT_PROD_HOST_URI}${SAVE_TO_AWS}`, body, history)
      .then(async (res) => {
        setPostContent([
          {
            fileName: body.file_name,
            fileType: res.data.includes("mp4") ? 2 : 1,
            fileUrl: res.data,
          },
        ]);

        // setThumbnailUrl(res.data);
        handleLoader(false);
      })
      .catch((error) => {
        console.error("error from authorizeCode", error);
        createNotification("error", "Error occured during fething media");
        handleLoader(false);
      });
  };

  const getYoutubeVideoData = async (videoId) => {
    handleLoader(true);
    console.log("videoID", videoId);
    await getAuthAndData(
      `${ENGT_PROD_HOST_URI}${GET_YOUTUBE_DATA}?videoid=${videoId}`
    )
      .then((response) => {
        console.log("postState2 youtube response", response);
        handleLoader(false);
        updateSelectedArray(response);
        setYtContentUrl(response.data);
        setPostContent([
          {
            fileName: videoId,
            fileType: response.data.includes(".mp4") ? 2 : 1,
            fileUrl: response.data,
          },
        ]);
        // if (response.code == 500) {
        //   console.log("response.data.code", response);
        //   handleLoader(false);
        //   console.log("stop");
        //   handleOpenAlert1();
        // } else {
        //   if (handleResponseCode(response)) {
        //     console.log("responseYtdata", response.data);
        //     if (response !== null || undefined) {
        //       // getAllPlayList();
        //       getAllProduct();
        //       setYtData(response.data);
        //       console.log("response", response.data);
        //       handleLoader(false);
        //       console.log("end");
        //     } else {
        //       setPostState(1);
        //       handleLoader(false);
        //       console.log("end");
        //       return;
        //     }
        //   }
        // }
      })
      .catch((error) => {
        console.error("error from authorizeCode", error);
        createNotification("error", "Error occured during fething media");
        handleLoader(false);
      });
  };

  const handleIgNext = () => {
    console.log("handleNavSubmitphotovideoClick", photovideoClick);
    console.log("ytArrays inside submit", ytArrays);
    if (photovideoClick == "VIDEO") {
      console.log("1");
      if (igArrays.length == 0 || igArrays.length > 1) {
        if (igArrays.length == 0) {
          console.log("igArrays", igArrays);
          console.log("igArrays.length", igArrays.length);
          createNotification("warning", "Please select at least one post");
          return;
        }
        if (igArrays.length > 1) {
          console.log("igArrays", igArrays);
          console.log("igArrays.length", igArrays.length);
          createNotification("warning", "Please select only one post");
          return;
        }
      } else {
        setPostState(3);
        getInstagramVideoData(igArrays[0]);
        console.log("selected arr", arr);
      }
    }
    if (photovideoClick == "IMAGE")
      if (igArrays.length > 0) {
        setPostState(3);
        console.log("selected arr", arr);
      } else {
        console.log("igArrays", igArrays);
        console.log("igArrayslength", igArrays.length);
        createNotification("warning", "Please select at least one post");
        return;
      }
  };
  const handleYtNext = () => {
    console.log("handleNavSubmitphotovideoClick", photovideoClick);
    console.log("ytArrays inside submit", ytArrays);
    if (photovideoClick == "VIDEO") {
      console.log("1");
      console.log(ytArrays);
      getYoutubeVideoData(ytArrays[0].videoId);
      if (ytArrays.length == 0 || ytArrays.length > 1) {
        console.log("2");
        if (ytArrays.length == 0) {
          console.log("3");
          console.log("igArrays", ytArrays);
          console.log("igArrays.length", ytArrays.length);
          createNotification("warning", "Please select at least one post");
          return;
        }
        if (ytArrays.length > 1) {
          console.log("igArrays", ytArrays);
          console.log("igArrays.length", ytArrays.length);
          createNotification("warning", "Please select only one post");
          return;
        }
      } else {
        setPostState(3);
        console.log("selected arr", arr);
      }
    }
    // if (photovideoClick == "IMAGE")
    //   if (igArrays.length > 0) {
    //     setPostState(3);
    //     console.log("selected arr", arr);
    //   } else {
    //     console.log("igArrays", igArrays);
    //     console.log("igArrayslength", igArrays.length);
    //     createNotification("warning", "Please select at least one post");
    //     return;
    //   }
  };

  const [discription, setDiscription] = useState({
    tag: "",
    discription: "",
    targetCatogery: "",
    caption: "",
    fontFamily: "",
    ColorPicker: "",
    buttonType: 0,
    contentType: 0,
    productId: "",
    thumbnailTitle: "",
    buttonCta: "",
    liveDateTime: new Date().getTime(),
    videoDuration: 0,
    isMulti: "",
  });
  const initialDiscription = {
    tag: "",
    discription: "",
    targetCatogery: "",
    caption: "",
    fontFamily: "",
    ColorPicker: "",
    buttonType: 0,
    contentType: 0,
    productId: "",
    thumbnailTitle: "",
    buttonCta: "",
    liveDateTime: new Date().getTime(),
    videoDuration: 0,
    isMulti: "",
  };
  var contentTypee = 0;

  const handleLoader = (showBool) => {
    dispatch(dispatchLoaderData(showBool));
  };

  const handleResponseCode = (resp) => {
    console.log(resp.data, "resp");
    if (!resp || resp.code === -1) {
      // history.push("/login");
      createNotification("error", " Please try again later.");
      // history.go("/login");
      // handleLoader();
      return false;
    } else {
      return true;
    }
  };

  const [postState, setPostState] = useState(1);
  const [thumbnailTitle, setThumbnailTitle] = useState("");
  const [photovideoClick, setPhotovideoClick] = useState("VIDEO");

  // useEffect(async () => {
  //   if (postState == 3) {
  //     handleInstaFileUpload(); //! to test
  //     await getAllPlayList();
  //     console.log("ig00", igSelectedData);
  //   }
  //   console.log("postState", postState);
  // }, [postState]);

  useEffect(async () => {
    if (postState == 3 && isInstaSelected) {
      // handleInstaFileUpload(); //! to test
      await getAllPlayList();
    }
    console.log("postState", postState);
  }, [postState]);

  useEffect(async () => {
    if (postState == 3 && isYoutubeSelected) {
      // handleYoutubeUpload(); //! to test
      await getAllPlayList();
    }
    console.log("postState", postState);
  }, [postState]);

  useEffect(() => {
    console.log("useEffectdiscription", discription);
    console.log("videoduration", videoduration);
  }, [discription]);

  const GetThumbnailUrl = async () => {
    if (PostContent) {
      handleLoader(true);
      console.log(PostContent);
      const { fileName, fileUrl } = PostContent[0];

      const encodedParams = new URLSearchParams();
      encodedParams.set("source_language", "en");
      encodedParams.set("target_language", "id");

      const data = {
        file_name: fileName,
        filePath: fileUrl,
      };
      await postAuthAndData(
        `${ENGT_PROD_HOST_URI}${SAVE_TO_CLOUDINARY}`,
        data,
        history
      )
        .then(async (response) => {
          console.log("postState2 instagram response", response);
          data.file_name = fileName + "_thumbnail";
          data.filePath = response.data;
          await postAuthAndData(
            `${ENGT_PROD_HOST_URI}${SAVE_TO_AWS}`,
            data,
            history
          )
            .then(async (res) => {
              if (res.code === 1) {
                setThumbnailContent([
                  {
                    fileName: fileName,
                    fileType: res.data.includes("mp4") ? 2 : 1,
                    fileUrl: res.data,
                  },
                ]);
                setThumbnailUrl(res.data);
              } else {
                createNotification(
                  "error",
                  "Error occured during creating thumbnail"
                );
              }
              handleLoader(false);
            })
            .catch((error) => {
              console.error("error from authorizeCode", error);
              createNotification(
                "error",
                "Error occured during creating thumbnail"
              );
              handleLoader(false);
            });
        })
        .catch(function (error) {
          handleLoader(false);
          console.log("Error in request: ", error);
        });
    }
  };

  const handleIgSubmit = async (userData) => {
    console.log("playlist", playlist);
    console.log("thumbnailContent", thumbnailContent);
    if (
      discription.productId.length == 0 ||
      discription.thumbnailTitle.length == 0 ||
      (!isChecked && thumbnailContent.length == 0) ||
      (!isChecked && playlist.length == 0)
    ) {
      if (discription.productId.length == 0) {
        createNotification("warning", "Please select Product.");
        return;
      }
      if (discription.thumbnailTitle.length == 0) {
        createNotification("warning", "Please give the thumbnail title.");
        return;
      }
      if (thumbnailContent.length == 0) {
        createNotification("warning", "Please provide the thumbnail.");
        return;
      }
      if (playlist.length == 0) {
        createNotification("warning", "Please select the playlist.");
        return;
      }
    } else {
      handleLoader(true);
      console.log("start");
      console.log("000001");
      await uploadDataOnAws(array1); //! thiss
      console.log("000002");
      await uploadDataOnAws2(array2, array3); //! thiss
      console.log("videoduration", videoduration);
      console.log("handleSubmit");
      console.log("igArrays", igArrays);
      console.log("000003");
      setThumbnailUrl(array1[0]);
      // await updatePlaylist();
      console.log("000004");
      console.log("check productArr from getAllProduct api", productArr);
      console.log("check getAllPlayList from getAllPlayList api", PlaylistArr);
      console.log("stop");
      handleLoader(false);
      await createpost({
        userData,
        resData: thumbnailUrl,
        postContent: PostContent,
      });
      handleLoader(false);
    }

    resetAll();
    console.log("Reset all data");
    console.log("presignedUploadUrlArr", presignedUploadUrlArr);
    console.log("igArrays", igArrays);
    console.log("videoduration", videoduration);
    console.log("igFileObj", igFileObj);
    console.log("igSelectedData", igSelectedData);
    console.log("postState", postState);
    console.log("discription", discription);
    console.log("contentTypee", contentTypee);
    console.log("thumbnailTitle", thumbnailTitle);
    console.log("photovideoClick", photovideoClick);
    console.log("array1", array1);
    console.log("array2", array2);
    console.log("array3", array3);
    console.log("thumbnailUrl", thumbnailUrl);
    console.log("PostContent", PostContent);
    console.log("thumbnailContent", thumbnailContent);
    console.log("productArray", productArray);
  };

  const handleYtSubmit = async (userData) => {
    if (
      discription.productId.length == 0 ||
      discription.thumbnailTitle.length == 0 ||
      (!isChecked && thumbnailContent.length == 0) ||
      playlist.length == 0
    ) {
      if (discription.productId.length == 0) {
        createNotification("warning", "Please select Product.");
        return;
      }
      if (discription.thumbnailTitle.length == 0) {
        createNotification("warning", "Please give the thumbnail title.");
        return;
      }
      if (playlist.length == 0) {
        createNotification("warning", "Please select the playlist.");
        return;
      }
      if (thumbnailContent.length == 0) {
        createNotification("warning", "Please provide the thumbnail.");
        return;
      }
    } else {
      handleLoader(true);

      const postContent = {
        fileName: `${ytArrays[0]?.title.replace(/ /g, "_")}.mp4`,
        fileType: 2,
        fileUrl: ytContentUrl,
      };
      //setPostContent(postContent);

      if (isChecked) {
        const thumbnailData = {
          file_name: `${ytArrays[0]?.title.replace(/ /g, "_")}.mp4`,
          filePath: ytContentUrl,
        };
        //const {fileUrl} = PostContent[0];
        await postAuthAndData(
          `${ENGT_PROD_BASE_URI}${PROCESS_AND_SAVE_BASEURL_TO_AWS}`,
          thumbnailData
        )
          .then((response) => {
            console.log("yt_thumbnail url", response.data);
            //setThumbnailUrl(response.data);
            createpost({
              userData,
              resData: response.data,
              postContent: [postContent],
            });
            handleLoader(false);
          })
          .catch((error) => {
            handleLoader(false);
            console.error("error from authorizeCode", error);
            createNotification("error", "Error occured during fething media");
            // handleLoader(false);
          });

        // await createpost(userData);

        // resetAll();
        handleLoader(false);
      } else {
        createpost({ userData, postContent: [postContent] });
        // resetAll();
        handleLoader(false);
      }
    }
    console.log("thumbnailUrl for YT", thumbnailUrl);
    await uploadDataOnAws2(array2, array3);
    console.log("last discription", discription);
    console.log("last PostContent", PostContent);
    console.log("last thumbnailContent", thumbnailContent);
  };
  const handleChangeThumbnail = (e) => {
    setThumbnailTitle(e.target.value);
    setDiscription({
      ...discription,
      thumbnailTitle: e.target.value,
    });
  };

  const handleContentType = (contentType) => {
    console.log("handleContentType", contentType);
    setDiscription({
      ...discription,
      contentType: contentType,
    });
  };

  const handleVideoDuration = (duration) => {
    console.log("handleVideoDuration", duration);
    // setDiscription({
    //   ...discription,
    //   videoDuration: duration
    // })
    videoduration = duration;
  };

  const handleAddPlaylist = (e) => {
    console.log("Playlist Added");
  };
  const handlephotoclick = () => {
    console.log("photo clicked");
    setPhotovideoClick("IMAGE");
  };
  const handlevideoclick = () => {
    console.log("video clicked");
    setPhotovideoClick("VIDEO");
  };

  //! Theme
  let theme = useSelector((select) => select.themeReducer.Rtheme);
  // console.log('ajay 03 theme',theme);
  const dispatch = useDispatch();

  const updateThemeToDark = () => {
    dispatch({ type: Dark_Mode, payload: "dark" });
  };
  const updateThemeToLight = () => {
    dispatch({ type: Light_Mode, payload: "light" });
  };

  //! Dropdown
  const [playlistCollection, setPlaylistCollection] = useState([
    {
      value: -1,
      label: "All Playlist",
      playlistCode: "",
    },
  ]);
  const [selectedPlaylist, setSelectedPlaylist] = useState({
    value: -1,
    label: "All Playlist",
    playlistCode: "",
  });

  const [PlaylistArr, setPlaylistArr] = useState([]);
  const getAllPlayList = async () => {
    const playlistArr = [];
    try {
      const body = {
        Content_Type: photovideoClick == "VIDEO" ? 2 : 1,
      };
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_ALL_PLAYLIST}`,
        body,
        History
      );
      //debugger;
      console.log("getAllPlayList========", data?.data);
      // console.log("getAllPlayList",Array.isArray(data?.data));
      setPlaylistArr(data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const [productArr, setProductArr] = useState([]);
  // getAuthAndData
  const getAllProduct = async () => {
    const allproductArr = [];
    try {
      const data = await getAuthAndData(
        `${EVNT_PROD_HOST_URI}${GET_ALL_PRODUCT}`,
        History
      );
      console.log("getAllProduct========", data?.data);
      // console.log("getAllProduct",Array.isArray(data?.data));

      setProductArr(data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  // const dataOptions = {
  //   method: "POST",
  //   url: "https://5yiek6g5g0.execute-api.ap-south-1.amazonaws.com/Prod/api/engt/ProcessAndSaveBaseUrlToAWS",
  //   headers: {
  //     "x-api-key": "MXqO3cDcr492OTPGZZAot7akPvLmfKbA4bKt5Ryr",
  //     "x-tenant-key": "DIVANOR123",
  //   },
  //   data: '[{"file_name":"WhatCarCanYouGetForAGrand.mp4", "filePath":"https://blsh-social.s3.amazonaws.com/v/ASDFS/14a63b51-13fb-4ea3-ab46-dfdf968dcfda..mp4"}]',
  // };

  const handleInstaFileUpload = () => {
    handleLoader(true);
    console.log("start");
    console.log("handleInstaFileUpload");
    console.log("igSelectedData", igSelectedData);
    // setIgFileObj([]);
    const filterObjArr = [];

    try {
      igArrays.map(async (igArray, index) => {
        {
          console.log("inside ig map map");
          console.log("igArray", igArray);
          const { caption, media_url } = igArray;
          // const caption = igArray[index].caption;
          // const media_url = igArray[index].media_url;
          console.log("caption", caption);
          console.log("media_url", media_url);

          const response = await fetch(media_url);
          const blob = await response.blob();

          let type = "";
          let extension = "";
          console.log("media_url", media_url);
          if (media_url.includes("jpg")) {
            type = "image/jpg";
            extension = ".jpg";
            setDiscription({ ...discription, contentType: 1 });
            // console.log('instagram array have contentType',discription?.contentType);
            contentTypee = 1;
          }
          if (media_url.includes("mp4")) {
            type = "video/mp4";
            extension = ".mp4";
            setDiscription({ ...discription, contentType: 2 });
            // console.log('instagram array have contentType',discription?.contentType);
            contentTypee = 2;
            handleContentType(contentTypee);
          }
          if (media_url.includes("jpeg")) {
            type = "image/jpeg";
            extension = ".jpeg";
            setDiscription({ ...discription, contentType: 1 });
            // console.log('instagram array have contentType',discription?.contentType);
            contentTypee = 1;
            handleContentType(contentTypee);
          }
          const file = new File([blob], `${caption}${extension}`, { type });
          console.log("0000 file object to make obj", file); // The file object is ready for upload or further processing
          // return file;
          const obj = {
            extension: extension,
            contentType: extension == ".mp4" || extension == "mp4" ? 2 : 1,
            file: file,
            FileId: ` ${Date.now()}_${file.name}`,
          };
          console.log("0000 obj", obj);
          filterObjArr.push(obj);
          console.log("0000 this is obj---> from instagramArray", obj); //!

          setIgFileObj(filterObjArr);
          fetchPreUrlDataPost(filterObjArr);
        }
      });

      console.log("1 filterObjArr", filterObjArr);
      // setIgFileObj(filterObjArr)

      // fetchPreUrlDataPost(filterObjArr);
    } catch (error) {
      console.error("error XXXXXXXXXXXXXXXXXXX", error);
    }

    setArray1(filterObjArr);
    // setTimeout(() => {
    //   uploadDataOnAws(filterObjArr);//! thiss
    // }, 15000);
  };

  const history = useHistory();
  // const filterObjArr = [];

  const [anchorEl, setAnchorEl] = useState(null);

  function redirectFn(arg) {
    history.push(arg);
  }
  const logOutFn = () => {
    setAnchorEl(null);
    Auth.signOut()
      .then((res) => redirectFn("/login"))
      .catch((err) => console.error(err));
  };

  console.log("userData", userData);
  if (!userData?.FirstName) {
    logOutFn();
    createNotification("error", "Session Expired Please login again");
  }
  console.log("presignedUploadUrlArr", presignedUploadUrlArr);
  // console.log('preAssignthumbnailArr',preAssignthumbnailArr);

  const uploadDataOnAws = async (filterObjArr) => {
    console.log("ajay 04 0000 114 uploadDataOnAws started");

    try {
      console.log("presignedUploadUrlArr 238", presignedUploadUrlArr);
      console.log("presignedUploadUrlArr 238", presignedUploadUrlArr.length);
      for (const presignedUploadUrl of presignedUploadUrlArr) {
        console.log("filterObjArr", filterObjArr); //! chg HERE SHOULD BE igfileobj
        let fobj = filterObjArr.find(
          (e) => e.FileId == presignedUploadUrl.FileId
        );
        console.log(
          "ajay 04 115 0000 uploadDataOnAws fileObj=fobj====$$$",
          fobj
        );
        let conetntType = addContent(fobj);
        console.log(
          "ajay 04 116 0000 uploadDataOnAws conetntType=====$$$",
          conetntType
        );
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": conetntType,
            "Access-Control-Allow-Origin": "*",
          },
          data: filterObjArr.find((e) => e.FileId == presignedUploadUrl.FileId)
            .file,
        };
        console.log(
          "ajay 04 117 0000 uploadDataOnAws requestOptions====$$",
          requestOptions
        );

        try {
          const response = await axios.put(
            presignedUploadUrl.PreSignedUrl,
            fobj.file,
            requestOptions
          );
          console.log("ajay 04 118 response uploadDataOnAws Flow1", response);
          if (response.status == 200) {
            console.log(
              "ajay 04 119 uploadDataOnAws ---->response status = 200"
            );
          } else {
            createNotification(
              "error",
              "Error uploading image to cloud, please try again."
            );
            console.log("stop");
            handleLoader(false);
          }
        } catch (error) {
          console.log("errorXXXXXXXXXXXX", error);
          //   createNotification("error", "Error uploading image to cloud, please try again.");
          //   handleLoader(false);
        }
      }
    } catch (error) {
      console.log("errorXXXXXXXXXXXX", error);
      createNotification(
        "error",
        "Error uploading image to cloud, please try again."
      );
      console.log("stop");
      handleLoader(false);
    }
  };

  const addContent = (fobj) => {
    console.log("addContent ffob======", fobj);
    console.log("addContent ffobExtenssion======", fobj);
    let contentFile;
    if (fobj.contentType == 1) {
      contentFile = `image/${fobj?.extension}`;
    } else {
      contentFile = `video/${fobj?.extension}`;
    }
    return contentFile;
  };

  const [fileObj, setFileObj] = useState([]);
  const [thumbnailUrl, setThumbnailUrl] = useState([]);

  const handleInstaDrop = useCallback((acceptedFiles) => {
    setThumbnailUrl([]);
    const filterObjArr2 = [];

    acceptedFiles?.forEach((file, index) => {
      console.log("ajay 04 98 file uploaded thumbnail file", file);
      //console("file_extention===", file.name.split(".").pop());
      let extensionType = file.name.split(".").pop();
      if (
        extensionType === "jpeg" ||
        extensionType === "jpg" ||
        extensionType === "mp4" ||
        //! added
        extensionType === "mov" ||
        extensionType === "png" ||
        extensionType === "webp"
      ) {
        if (
          (file.name.split(".").pop() === "mp4" && file.size < 10000000) || //Video Size max is 5 MB
          file.size < 5000000
        ) {
          const obj = {
            extension: file.name.split(".").pop(),
            contentType: file.name.split(".").pop() === "mp4" ? "2" : "1",
            file: file,
            FileId: ` ${Date.now()}_${file.name}`,
          };
          filterObjArr2.push(obj);
          console.log("ajay 04 filterObjArr2 101", filterObjArr2);
          console.log("ajay 04 obj 101.1 ====", obj);
        } else {
          createNotification(
            "warning",
            "Image/Video size should be less than 10 MB."
          );
        }
      } else {
        createNotification(
          "warning",
          "Only jpeg, jpg, png, mp4  webp file type is allowed"
        );
      }
    });
    setFileObj(filterObjArr2);
    setThumbnailUrl(filterObjArr2);
    fetchPreUrlDataThumbnail(filterObjArr2); //
    // setArray2(filterObjArr2) // doing in fetchPreUrlDataThumbnail
  }, []);

  const handleYoutubeDrop = useCallback((acceptedFiles) => {
    console.log("yt_drop");
    setThumbnailUrl([]);
    const filterObjArr2 = [];

    acceptedFiles?.forEach((file, index) => {
      console.log("ajay 04 98 file uploaded thumbnail file", file);
      //console("file_extention===", file.name.split(".").pop());
      let extensionType = file.name.split(".").pop();
      console.log("yt_extension", extensionType);
      if (extensionType === "mp4") {
        if (
          (file.name.split(".").pop() === "mp4" && file.size < 10000000) || //Video Size max is 5 MB
          file.size < 5000000
        ) {
          const obj = {
            extension: file.name.split(".").pop(),
            contentType: "2",
            file: file,
            FileId: ` ${Date.now()}_${file.name}`,
          };

          console.log("ytobj", obj);
          filterObjArr2.push(obj);
          console.log("ajay 04 filterObjArr2 101", filterObjArr2);
          console.log("ajay 04 obj 101.1 ====", obj);
        } else {
          createNotification(
            "warning",
            "Video size should be less than 10 MB."
          );
        }
      } else {
        createNotification("warning", "Only mp4 file type is allowed");
      }
    });
    setFileObj(filterObjArr2);
    setThumbnailUrl(filterObjArr2);
    //fetchPreUrlDataThumbnail(filterObjArr2); //
    fetchPreUrlDataThumbnailYt(filterObjArr2); //
    // setArray2(filterObjArr2) // doing in fetchPreUrlDataThumbnail
  }, []);

  console.log("isYoutubeSelected", isYoutubeSelected);
  console.log("array1_yt", array1);
  console.log("array2_yt", array2);
  console.log("array3_yt", array3);

  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log("dropped", acceptedFiles);
      console.log("isYoutubeSelected", isYoutubeSelected);
      isInstaSelected && handleInstaDrop(acceptedFiles);
      isYoutubeSelected && handleYoutubeDrop(acceptedFiles);
    },
    [isYoutubeSelected, isInstaSelected, handleInstaDrop, handleYoutubeDrop]
  );

  console.log("101.2 thumbnailUrl", thumbnailUrl); //!this is working

  const [PostContent, setPostContent] = useState([]);
  const [thumbnailContent, setThumbnailContent] = useState([]);

  function getDuration(file) {
    var video = document.createElement("video");
    video.src = URL.createObjectURL(file);
    video.preload = "metadata";
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      // setDiscription({ ...discription, videoDuration: video.duration });
      handleVideoDuration(video.duration);
      console.log("discription in ");
      console.log("Video Duration==>", video.duration);
      return video.duration;
      // setVideoDuration(video.duration);
    };
    // video.src  = file
  }

  const fetchPreUrlDataPost = async (filterObjArr) => {
    console.log("ondrop fetchPreUrlDataPost");
    console.log("ajay 04 0000 fetchPreUrlDataPost");
    console.log(
      "ajay 04 0000 100.1 fetchPreUrlDataPost  filterObjArr",
      filterObjArr
    );
    // presignedUploadUrlArr = [];
    setPostContent([]);
    const body = {
      userId: userData?.UserID,
      files: filterObjArr,
    };

    console.log("body", body);
    try {
      await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${PREE_SIGNED_URL}`,
        body,
        history
      ).then((res) => {
        console.log(
          "ajay 04 103 0000 fetchPreUrlDataPost first response by userId and filterObjArr ======== ",
          res
        );
        console.log("stop");
        handleLoader(false);
        if (handleResponseCode(res)) {
          console.log("0000 inside handleResponseCode");
          console.log("0000 discription.contentType", discription.contentType);
          res?.data.forEach((e) => presignedUploadUrlArr.push(e));
          console.log(
            "ajay 04 104.1 0000 presignedUploadUrlArr",
            presignedUploadUrlArr
          );
          if (contentTypee == 2) {
            console.log("ondrop contentTypee2");
            getDuration(filterObjArr[0].file);
            setPostContent(
              res?.data.map((e) => ({
                fileName: e.FileKey,
                fileType:
                  e.FileExtenssion.toLowerCase() === ".mp4" ||
                  e.FileExtenssion.toLowerCase() === "mp4"
                    ? 2
                    : 1,
                fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
              }))
            );

            console.log(
              "ajay 01 103.1 0000 see the difference res.data",
              res?.data
            );
          } else {
            setThumbnailUrl(res?.data[0].PreSignedUrl);
            console.log("thumbnailUrllllll", thumbnailUrl);
            // getDuration(filterObjArr[0].file)//! when this is not video we don't need this
            setPostContent(
              res?.data.map((e) => ({
                fileName: e.FileKey,
                fileType:
                  e.FileExtenssion.toLowerCase() === ".mp4" ||
                  e.FileExtenssion.toLowerCase() === "mp4"
                    ? 2
                    : 1,
                fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
              }))
            );
            setThumbnailUrl(res?.data[0].PreSignedUrl);
            console.log(
              "ajay 01 103.1 0000 see the difference res.data",
              res?.data
            );
          }

          console.log("ajay 04 105.1 0000 PostContent", PostContent);

          // setArray1(filterObjArr)
          // uploadDataOnAws(filterObjArr);
        }
      });
    } catch (error) {
      handleLoader(false);
      console.log("stop");
      console.log("error", error);
      createNotification("error", "Error in uploading");
    }
  };

  useEffect(() => {
    console.log("ondrop postcontent", PostContent);
  }, [PostContent]);

  useEffect(() => {
    console.log("ondrop thumbnailUrll", thumbnailUrl);
  }, [thumbnailUrl]);

  console.log("0000 198 discription", discription);
  console.log("0000 197 PostContent", PostContent);
  console.log("0000 197 thumbnailContent", thumbnailContent);

  const fetchPreUrlDataThumbnail = async (filterObjArr2) => {
    const preAssignthumbnailArr = [];
    setThumbnailContent([]);

    const body = {
      userId: 80, //! why not user id as in previous
      files: filterObjArr2,
    };
    try {
      await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${PREE_SIGNED_URL}`,
        body,
        history
      ).then((res) => {
        if (handleResponseCode(res)) {
          console.log(
            "ajay 04 107 fetchPreUrlDataThumbnail first response by userId 80 and filterObjArr2 ======== ",
            res
          );
          res?.data.forEach((e) => preAssignthumbnailArr.push(e));
          console.log(
            "ajay 04 108 preAssignthumbnailArr",
            preAssignthumbnailArr
          );
          setThumbnailContent(
            res?.data.map((e) => ({
              fileName: e.FileKey,
              fileType:
                e.FileExtenssion === ".mp4" ||
                e.FileExtenssion.toLowerCase() === "mp4"
                  ? 2
                  : 1,
              fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
            }))
          );
        }
        console.log(
          "ajay 04 109 thumbnailContent from fetchPreUrlDataThumbnail",
          thumbnailContent
        );
      });
    } catch (error) {
      console.log("error", error);
      handleLoader(false);
      console.log("stop");
    }
    if (thumbnailContent !== null) {
      console.log("preAssignthumbnailArr 00000", preAssignthumbnailArr);
      // uploadDataOnAws2(filterObjArr2,preAssignthumbnailArr)
      //! thiss
      setArray2(filterObjArr2);
      setArray3(preAssignthumbnailArr);
    }
  };
  const fetchPreUrlDataThumbnailYt = async (filterObjArr2) => {
    const preAssignthumbnailArr = [];
    setThumbnailContent([]);

    const body = {
      userId: 80, //! why not user id as in previous
      files: filterObjArr2,
    };
    try {
      await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${PREE_SIGNED_URL}`,
        body,
        history
      ).then((res) => {
        if (handleResponseCode(res)) {
          console.log(
            " 04 107 fetchPreUrlDataThumbnail first response by userId 80 and filterObjArr2 ======== ",
            res
          );
          res?.data.forEach((e) => preAssignthumbnailArr.push(e));
          console.log(" 04 108 preAssignthumbnailArr", preAssignthumbnailArr);
          setThumbnailContent(
            res?.data.map((e) => ({
              fileName: e.FileKey,
              fileType: 2,
              fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
            }))
          );
        }
        console.log(
          " 04 109 thumbnailContent from fetchPreUrlDataThumbnail",
          thumbnailContent
        );
      });
    } catch (error) {
      console.log("error", error);
      handleLoader(false);
      console.log("stop");
    }
    if (thumbnailContent !== null) {
      console.log("preAssignthumbnailArr 00000", preAssignthumbnailArr);
      // uploadDataOnAws2(filterObjArr2,preAssignthumbnailArr)
      //! thiss
      setArray2(filterObjArr2);
      setArray3(preAssignthumbnailArr);
    }
  };

  console.log("ajay 04 110 tvhumbnailContent==>", thumbnailContent);

  const uploadDataOnAws2 = async (filterObjArr2, preAssignthumbnailArr1) => {
    console.log("ajay 04 120 uploadDataOnAws2");
    console.log("120 filterObjArr2", filterObjArr2);
    try {
      console.log("preAssignthumbnailArr 0000", preAssignthumbnailArr1[0]);
      for (const presignedUploadUrl of preAssignthumbnailArr1) {
        let thumbnailFileObj = filterObjArr2.find(
          (e) => e.FileId === presignedUploadUrl.FileId
        );
        console.log("ajay 04 121 thumbnailFileObj=====$$$", thumbnailFileObj);

        let contentType = addContent(thumbnailFileObj);
        console.log("thumbnail contentType =====$$$", contentType);
        const requestOptions = {
          headers: {
            "Content-Type": contentType,
            "Access-Control-Allow-Origin": "*",
          },
        };
        console.log("ajay 04 123 requestOptions", requestOptions);
        const response = await axios.put(
          presignedUploadUrl.PreSignedUrl,
          thumbnailFileObj.file,
          requestOptions
        );
        console.log("ajay 04 124 uploadDataOnAws Flow2", response);
        console.log("NEW CHANGE response", response);
        console.log("NEW CHANGE url", response.config.url);

        if (response.status === 200) {
          console.log(" 04 125 uploadDataOnAws2 ---->response status = 200"); //! chk url
          // setFlag(true);
          const regex = /(.*\.mp4)/; // Regular expression to match the URL ending with .mp4
          const match = response.config.url.match(regex);

          console.log("NEW CHANGE match", match[1]);
          setThumbnailUrl(match[1]);
          // createEngagementPost(isUploadToAWSSuccess);
        } else {
          createNotification(
            "error",
            "Error uploading image to cloud, please try again."
          );
          handleLoader(false);
          console.log("stop");

          // setFlag(false);
        }
      }
    } catch (error) {
      console.log("XXXXXXXXXXXXX");
      console.log("stop");
      handleLoader(false);
      createNotification(
        "error",
        "Error uploading image to cloud, please try again."
      );
    }
  };

  const deleteProductArray = () => {
    dispatch({ type: Delete_Product_Array, payload: [] });
    console.log("deleteProductArray");
  };

  const resetAll = () => {
    setDiscription(initialDiscription);
    setPostContent([]);
    setThumbnailContent([]);
    setThumbnailUrl([]);
    setIsChecked(false);
    // filterObjArr = [];
    igArrays = [];
    videoduration = 0;
    presignedUploadUrlArr = [];
    setIgFileObj([]);
    setArray1([]);
    setArray2([]);
    setArray3([]);
    //setPhotovideoClick("IMAGE");
    setThumbnailTitle("");
    setProductArray([]);
    deleteProductArray();
    setProductArray([]);
    setPlaylistDetails([]);
    setPostState(1);
  };

  const createpost = async ({ userData, resData, postContent }) => {
    handleInstaFileUpload();
    console.log("PostContent", resData);
    console.log("last createpost");
    console.log("last discription", discription);
    console.log("last userData?.UserID", userData?.UserID);
    console.log("last PostContent", PostContent);
    console.log("last thumbnailContent", thumbnailContent);

    // setDiscription({ ...discription, contentType: contentTypee });

    const body = {
      //! IsMultiHost: discription.isMulti,
      IsMultiHost: false,
      userId: userData?.UserID,
      description: discription.discription,
      tags: discription.tag,
      contentType: discription.contentType,
      postText: "",
      textfontFamily: discription.fontFamily,
      captionFont: discription.fontFamily,
      captionColor: discription.ColorPicker,
      ctaImageId: discription.buttonType,
      buttonType: discription.buttonType,
      caption: discription.caption,
      buttonHeader: discription.caption,
      cTAUrl: discription.targetCatogery,
      postContents: postContent ? postContent : PostContent,
      ProductIdList: discription.productId,
      Thumbnail_URL: resData ? resData : thumbnailContent[0]?.fileUrl,
      Thumbnail_Title: discription?.thumbnailTitle,
      Button_Cta: discription?.buttonCta,
      //Button_Cta: "Button_Cta",
      // Video_duration: discription.contentType === 2 ? discription.videoDuration : 0, //! work here
      Video_duration: videoduration,
      liveDateTime: discription?.liveDateTime,
    };
    try {
      await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${SAVE_POST_DATA}`,
        body,
        history
      ).then(async (res) => {
        await updatePlaylist(res);

        console.log(res, "last res ======================");
        createNotification("success", "Post uploaded successfully");
        console.log("stop");
        handleLoader(false);
        console.log("Post uploaded successfully");
      });
    } catch (error) {
      createNotification("error", "Error while uploading");
      console.log("stop");
      handleLoader(false);
    }

    resetAll();
    console.log("Reset all data");
  };

  const [playlist, setPlaylist] = useState([]);
  const [prevPost, setPrevPost] = useState([]);
  const [playlistDetails, setPlaylistDetails] = useState([]);

  const selectedPlaylistt = (value) => {
    console.log("valueplaylist", value);
    setPlaylist(value.PlayListId);
    setPrevPost(value.Post_Ids);
    console.log("PlayListId", value.PlayListId);

    setPlaylistDetails(value);
  };
  const updatePlaylist = async (response) => {
    const postIdArray = [];
    postIdArray.push(response.data.enagement_post_id, ...prevPost);

    console.log("playlistDetailss ", playlistDetails);
    const body = {
      Content_Type: photovideoClick == "VIDEO" ? 2 : 1,
      Description: playlistDetails?.Description,
      IsTagged: playlistDetails?.IsTagged,
      Name: playlistDetails?.Name,
      PlayListId: playlist,
      Post_Ids: postIdArray,
      URL: playlistDetails?.URL,
    };
    console.log("updatePlaylistbody", body);

    try {
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${CREATE_PLAY_LIST}`,
        body,
        History
      );
      console.log(data, "playlist data................");
    } catch (error) {
      createNotification("error", "playlist is not attached to the post");
      handleLoader(false);
    }
  };

  return {
    // selectedProductIdArray,
    // setSelectedProductIdArray,
    // selectedProductArray,
    // setSelectedProductArray,

    postState,
    setPostState,
    thumbnailTitle,
    setThumbnailTitle,
    photovideoClick,
    setPhotovideoClick,
    // updatePhotovideoClick,
    handlephotoclick,
    handlevideoclick,
    history,
    handleIgSubmit,
    handleYtSubmit,
    handleChangeThumbnail,
    handleAddPlaylist,
    theme,
    updateThemeToDark,
    updateThemeToLight,
    playlistCollection,
    setPlaylistCollection,
    selectedPlaylist,
    setSelectedPlaylist,
    PlaylistArr,
    setPlaylistArr,
    getAllPlayList,
    productArr,
    setProductArr,
    getAllProduct,
    handleInstaFileUpload,
    onDrop,
    discription,
    setDiscription,
    handleLoader,
    // igData,
    // setIgData,
    // updateIgData,
    handleIgNext,
    handleYtNext,
    productArray,
    setProductArray,
    igArrays,
    handleResponseCode,
    playlistDetails,
    PostContent,
    fileObj,
    thumbnailContent,
    thumbnailUrl,
    GetThumbnailUrl,
    getInstagramVideoData,
    igSelectedData,
    setIgSelectedData,
    ytSelectedData,
    setYtSelectedData,
    selectedPlaylistt,
    isChecked,
    setIsChecked,
  };
};

export default UseIgHook;
