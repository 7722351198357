import React, { useEffect, useState } from "react";
import "./SubscriptionPlans.css";
import { PrimayButton } from "../Billing";
import Button from "@material-ui/core/Button";

const SubscriptionPlans = ({plans, activeplan, currentPlanDetails, selectedplan, setSelectedplan, setSlidemenu}) => {
  // const planDetails = [{
  //   plan_id: 1,
  //   plan_name: "Basic Plan",
  //   amount: 20,
  //   currency: "inr",
  //   geography: "India",
  //   interval: "month",
  //   stripe_product_id: "prod_P1STzyU8Apt94B",
  //   stripe_price_id: "price_1ODPYSHWhtdSGqgJaXczDhP8",
  //   additional_info: "<h4>Additional Info</h4>"
  // }];
  const [planDetails, setPlanDetails] = useState([]);
  const [interval, setInterval] = useState("month");

  useEffect(() => {
    if(plans != null) {
      let planFilter = plans.filter((plan) => plan.interval === interval)
      setPlanDetails(planFilter);
    }
  },[plans, interval]);

  return (
    <>
      <div className="plans-maincontainer">
        <div className="plans-titlecontainer">
          <h4 className="title-subscription">Manage Subscription</h4>
        </div>
        <div className="plans-content">
          <div className="plans-headcontainer">
            <div>
              <h3>Upgrade Your Plan</h3>
            </div>
            <div className="interval-details">
              <Button style={{color: "white"}} className={`tab-button ${interval === "year" && "tab-active"} interval-button`} onClick={() => setInterval("year")}>
                Annual Billing
              </Button>
              <Button style={{color: "white"}} className={`tab-button ${interval === "month" && "tab-active"} interval-button`} onClick={() => setInterval("month")}>
                Monthly Billing
              </Button>
            </div>
          </div>
          <div className="plans-bodycontainer">
            <div className="plans-listcontainer">
              {planDetails.map((plan, index) => {
                return(
                <div key={index} className="planwrapper">
                  <div style={{display: "flex", flexDirection: "column", flex:"1", alignItems:"center"}}>
                    <div>
                      <p className="plan-info">{plan.plan_name}</p>
                    </div>
                    <div>
                      <p className="plan-info">{plan.amount} {plan.currency}</p>
                    </div>
                    <div>
                      <p className="plan-addinfo">Additional Plan Info</p>
                    </div>
                    <div style={{textAlign: "center"}} dangerouslySetInnerHTML={{__html: plan.plan_information}} />
                  </div>
                  <PrimayButton style={{flex: "none"}} onClick={() => {
                      setSelectedplan(plan);
                    }} disabled={!!currentPlanDetails && activeplan ? plan.plan_id === currentPlanDetails.plan_id : !!selectedplan}>
                    Select Plan
                  </PrimayButton>
                </div>);
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPlans;
