import React, { useState, useEffect, Fragment } from "react";
import { Button, Box, OutlinedInput } from "@material-ui/core";
import "./playlistManager.css";
import Select from "react-select";
import Table from "../common/reactTable/table";
import createNotification from "../common/reactNotification";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import { useHistory } from "react-router-dom";
import store from "../../store/store";
import {
  ENGT_PROD_BASE_URI,
  GET_ALL_PLAYLIST,
  SAVE_EMBEDDED_PLAYYER_DETAILS,
  GET_ALL_EMBEDDED_PLAYER_DETAILS
} from "../../api/apiConstants";
import { getAuthAndData, postAuthAndData } from "../../api/ApiHelper";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";
let feedsGlobalData = [];
let playlistId = 0;
let domainNameString

function EmbededPlayer() {
  const [selectedContent, setSelectedContent] = useState([]);
  const [playlistCollection, setPlaylistCollection] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState()
  const [embededPlayerCollection, setEmbededPlayerCollection] = useState([])
  const [playlistData, setPlaylistData] = useState("");
  const History = useHistory();
  const Dispatch = useDispatch();
  const [urlValidation, setUrlValidation] = useState(false) 
  const  DomainName = store.getState()?.LoginReducer?.userData?.DomainName;

  
 

 

  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
  };
  const handleUrl = (event) => {
    setPlaylistData(event?.target?.value)
    let isTrueVal = urlPatternValidation(event?.target?.value);
    setUrlValidation(isTrueVal)
  };

 

 const  urlPatternValidation = (URL) => {
  domainNameString =   getHostname(URL)
  console.log("domainNameString=====", domainNameString)
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
    return regex.test(URL);
  };

  const getHostname = (url) => {
    // use URL constructor and return hostname
    return new URL(url).hostname;
  }
  


  // changeUrl = event => {
  //   const { value } = event.target;
  //   const isTrueVal = !value || this.urlPatternValidation(value);
  //   this.setState({
  //     URL: value,
  //     isTrueVal
  //   });
  // };



  const getAllPlayList = async () => {
    handleLoader(true);
    const playlistArr = []
    try {
      const data = await getAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_ALL_PLAYLIST}`,
        History
      );
      // debugger;
      console.log("getAllPlayList========", data?.data);
      data?.data?.forEach((player) => {
        let obj = {
          value: player?.PlayListId,
          label: player?.Name,
          playlistCode: player?.PlayListCode
        };
        playlistArr.push(obj);
      });
      setPlaylistCollection(playlistArr);
      setSelectedPlaylist(playlistArr[0]);
      //onPlaylistChange(playlistArr[0])
    } catch (err) {}
    handleLoader(false);
  };


  const embededPlayerDetails = async () => {
    handleLoader(true);
    const playlistArr = []
    try {
      const data = await getAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_ALL_EMBEDDED_PLAYER_DETAILS}`,
        History
      );
      // debugger;
      console.log("embededPlayerDetails========", data);
      data?.data?.forEach((player) => {
        let obj = {
          urlName: player?.target_url,
          playListName: player?.play_list_name,
          embdedPlayerCode:  `<div id ="${player?.player_code}"></div>`
        };
        playlistArr.push(obj);
      });
      setEmbededPlayerCollection(playlistArr)
    } catch (err) {}
    handleLoader(false);
  };
 

  
  const checkManadatoryFields = () => {
     let domainFlg = (DomainName?.toUpperCase() === domainNameString?.toUpperCase()) ? true: false
     let urlflag = embededPlayerCollection?.find((e)=>e.urlName == playlistData)
    let hasMandatoryField = true;
    if (playlistData?.length <= 0) {
      hasMandatoryField = false;
      createNotification("warning", "Enter Target Url.");
    } else if (!urlValidation) {
      hasMandatoryField = false;
      createNotification("warning", "Envalid Url.");
    } else if (!domainFlg) {
      hasMandatoryField = false;
      createNotification("warning", "Envalid Domain.");
    } else if (urlflag) {
      hasMandatoryField = false;
      createNotification("warning", "Url Name Allready Exist.");
    }
    return hasMandatoryField;
  };


  const playlistColumn = [
    {
      name: "Url Name",
      selector: "urlName",
      width: "30%",
    },
    {
        name: "Play List Name",
        selector: "playListName",
        width: "30%",
      },
      
      {
        name: "Embded Player Code",
        selector: "embdedPlayerCode",
        width: "30%",
      },
      {
        name: "Copy root",
        width: "10%",
        cell: (rowObj) => (
          <FileCopyIcon style = {{cursor:"pointer"}} onClick={() => navigator.clipboard.writeText(rowObj.embdedPlayerCode)} />
        ),
        // cell: rowObj => <DeleteIcon onClick={e => removeRow(rowObj?.id)} />
        
      },
  ];
 
  const onPlaylistChange = (target) => {
    console.log("target=====", target)
    setSelectedPlaylist(target);
  };

  const saveEmbeddedPlayerdetails = async () => {
    const checkFields = checkManadatoryFields()
    if(!checkFields) 
    return;
    console.log("saveEmbeddedPlayerdetails======", saveEmbeddedPlayerdetails)
    handleLoader(true);
    const body = {
      play_list_id : selectedPlaylist?.value,
      play_list_name : selectedPlaylist?.label,
      play_list_code : selectedPlaylist?.playlistCode,
      target_url : playlistData
    };
    try {
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${SAVE_EMBEDDED_PLAYYER_DETAILS}`,
        body,
        History
      );
      createNotification("success", "Playlist Created succesfully.");
      embededPlayerDetails()
    } catch (err) {}
    handleLoader(false);
  };

 

 
  useEffect(() => {
    getAllPlayList()
    embededPlayerDetails()
  }, []);

  console.log("userData=========%%", DomainName);

  return (
    <div>
      <NotificationContainer />
      <div style = {{display:"flex", flexDirection:"column"}}>
          <Fragment>
            <div className="PlaylistSubheader">
                   {/* <div className="ml-1 row" style={{ marginRight: "-33px" }}> */}
                      <div className="w-30">
                        <Select
                          options={playlistCollection}
                          // isDisabled={obj.rewardType?.value!==2}
                          name="rewardName"
                          onChange={(e) => onPlaylistChange(e)}
                         value={selectedPlaylist}
                          //defaultValue= {selectedPlaylist}
                          style={{ lineHeight: "28px" }}
                          maxMenuHeight={150}
                        />
                      </div>
                
              <OutlinedInput
                id="myInput"
                value={playlistData?.playlistName}
                color="primery"
                placeholder="Enter Target Url*"
                inputProps={{ maxLength: 300 }}
                style={{
                  // borderRadius: "30px 30px 30px 30px",
                  minWidth: "30%",
                  height: "35px",
                  color: "#6c757d",
                  backgroundColor: "white",
                  //opacity: editNameFlag ? "1" : ".4",
                  //borderColor: "white",
                }}
                onChange={(event) => handleUrl(event)}
                // inputProps={{ style: { color: "white" } }}
              />
              

              <div
                style={{ marginRight: "15px" }}
                className="btn-create-journey float-right text-center pt-2"
                onClick={saveEmbeddedPlayerdetails}
              >
                <span className="btn-c-j-text">
                  Generate player code
                </span>
              </div>
            </div>
          </Fragment>
          <div>
                  <Table
                    columns={playlistColumn}
                    data={embededPlayerCollection}
                    pagination={true}
                    //length = {embededPlayerCollection?.length}
                    // subHeader={true}
                  />
            </div>
      </div>
    </div>
  );
}

export default EmbededPlayer;
