import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import InstaIcon from "../icon/InstaIcon";
import TiktokIcon from "../icon/TiktokIcon";
import YouTubeIcon from "../icon/YouTubeIcon";
import PininterestIcon from "../icon/PininterestIcon";
import InstaTray from "../icon/InstaTray";
import FacebookTray from "../icon/FacebookTray";
import YoutubeTray from "../icon/YoutubeTray";
import PinintrestTray from "../icon/PinintrestTray";
import { DeveloperBoard } from "@material-ui/icons";
import { utcYear } from "d3";

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

export default function IgAlertBox1({
  alertInstObj,
  handleInstaAuthorize,
  handleYoutubeAuthorize,
}) {
  const handleInstaIconClick = () => {
    console.log("InstaIconclicked");
    handleInstaAuthorize();
  };

  const handleYoutubeIconClick = () => {
    console.log("youtube clicked");
    handleYoutubeAuthorize();
  };

  return (
    <div>
      <Dialog
        fullWidth="100px"
        maxWidth={"sm"}
        elementType={"Fade"}
        open={alertInstObj.open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => alertInstObj.handleClose(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        // style={{
        //     backgroundColor:"black"
        // }}
        PaperProps={{
          style: {
            background: "#161414",
            //   background: "black",
            color: "white",
          },
        }}
      >
        <div
          onClick={handleInstaIconClick}
          style={{
            width: "360px",
            height: "80px",
            // border:"2px solid grey",
            position: "absolute",
            top: "25px",
            left: "13px",
            cursor: "pointer",
            borderRadius: "20px",
          }}
        >
          <InstaTray />
          {/* <InstaIcon
                    />Connect your Instagram Buisness account to publish posts */}
        </div>
        <div
          // onClick={handleInstaIconClick}
          style={{
            width: "360px",
            height: "80px",
            // border:"2px solid grey",
            position: "absolute",
            top: "115px",
            left: "24px",
            borderRadius: "20px",
            // cursor: "pointer",
          }}
        >
          <FacebookTray />
          {/* <TiktokIcon
                    />Connect your TikTok account to publish posts */}
        </div>
        <div
          onClick={handleYoutubeIconClick}
          style={{
            width: "360px",
            height: "80px",
            // border:"2px solid grey",
            position: "absolute",
            top: "205px",
            left: "24px",
            borderRadius: "20px",
            // cursor: "pointer",
          }}
        >
          <YoutubeTray />
          {/* <YouTubeIcon
                    />Connect your YouTube channel to publish posts */}
        </div>
        <div
          // onClick={handleInstaIconClick}
          style={{
            width: "360px",
            height: "80px",
            // border:"2px solid grey",
            position: "absolute",
            top: "295px",
            left: "24px",
            borderRadius: "20px",
            // cursor: "pointer",
          }}
        >
          <PinintrestTray />
          {/* <PininterestIcon
                    />Connect your Pininterest Board to publish pins */}
        </div>
        <DialogTitle id="alert-dialog-title">
          {/* {generatedCodeObj.title} */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {/* {generatedCodeObj.text1} */}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: "blue", fontSize: "0.8rem" }}
          >
            {/* {generatedCodeObj.text2} */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button 
                    onClick={()=>handleInstaAuthorize()} color="primary" style={{borderColor:'#3390FF',border:'1px', 
                }}
                    >
                        Ok
                    </Button> */}
          <div
            style={{
              position: "absolute",
              bottom: "20px",
              right: "20px",
            }}
          >
            <Button
              onClick={() => alertInstObj.handleClose(false)}
              style={{ backgroundColor: "#3390FF", color: "#FFFFFF" }}
              autoFocus
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
