import React, { useState } from "react";
import { PrimayButton } from "../Billing";

const BillingDetails = ({activeplan, currentPlanDetails}) => {
  return(
    <div className="tab-content-container">
      {
        activeplan &&
        <div>
          <div className="subscription-details">
            <div>
              <h4 className="current-subscription">
                Currenty Subscribed to
                <span>
                  {currentPlanDetails.plan_name}
                </span>
              </h4>
              <h4 className="current-renewal">
                Next Credit cycle renewal:
                <span>
                  {currentPlanDetails.current_billing_end}
                </span>
              </h4>
            </div>
          </div>
        </div>
      }
      <div className="billingdetails-container">
        <div className="current-billing-details">
          <h4 className="current-subscription">
            Billing Information
          </h4>
          <PrimayButton style={{flex: "none"}} onClick={() => { }}>
            Update Billing Details
          </PrimayButton>
        </div>
        <div style={{display: "flex", justifyContent: "space-between", marginTop: "30px"}}>
          <div className="billingdetails-card">
            <h4 style={{borderBottom: "3px solid #22222A", padding: "0px 0px 15px 15px"}} className="current-subscription">
              Card Information
            </h4>
            <div style={{padding: "15px 15px"}}>
              <h6>Card Number</h6>
              <p>{currentPlanDetails.card_last_digits}</p>
              <h6>Expiration Date</h6>
              <p>{currentPlanDetails.card_expiry_month} / {currentPlanDetails.card_expiry_year}</p>
            </div>
          </div>
          <div className="billingdetails-card">
            <h4 style={{borderBottom: "3px solid #22222A", padding: "0px 0px 15px 15px"}} className="current-subscription">
              Billing Address
            </h4>
            <div style={{padding: "15px 15px"}}>
              <p>Billing Addrress Info</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingDetails;
