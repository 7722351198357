import React from 'react'
import "./manageVideo.style.css"
import {Typography} from "@material-ui/core"
import Select from "react-select";
import MoreVertIcon from '@material-ui/icons/MoreVert';

function ManageVideoComponent() {
  return (
    <div>
    <div className="subHeaderMv12">
      <div className="videoLibrarylable">Video Library</div>
      
      <div className="w-20">
              <Select
                //options={predictAndWinName}
                // isDisabled={obj.rewardType?.value!==2}
                name="predictionList"
               // onChange={(e) => onOptionChange(e)}
               // value={selectedType}
                style={{ lineHeight: "28px" }}
                maxMenuHeight={150}
              />
            </div>
            </div>
            <div className='contentroot'>
            <div className='videocollectiondiv'>
              <div className='videoCard'>
                <div className='cardLayout'>
                  <div className='videoPlayerDiv'>
                  <div className='optionmenudiv'>
                  <MoreVertIcon fontSize = "small" style={{color:"white"}} />
                  </div>
                 <div className='videodurationdiv'>
                  <div className='videoDurationtypo'>12:45</div>
                 </div>
                  </div>
                  <div>
                  <div className='videoTytle34'>
                    anand_kumar_singh
                  </div>
                  </div>
                  </div>
                  
   
              </div>
            </div>
            <div className='previeoDiv'>

            </div>
            </div>

    </div>
  )
}

export default ManageVideoComponent