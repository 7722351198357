import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import FavoriteIcon from "@material-ui/icons/Favorite";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import {
  dispatchLoaderData,
  dispatchAlertDialogData,
} from "../../actions/route/routeActionHandler";
import { postAuthAndData } from "../../api/ApiHelper";
import { useSelector, useDispatch } from "react-redux";
import { STOP_STREAM, ENGT_PROD_BASE_URI } from "../../api/apiConstants";
import { useHistory } from "react-router-dom";

function LiveMoniteringDetail({ selectedFeed, setStreamStop, streamStop }) {
  const Dispatch = useDispatch();
  const History = useHistory();
  console.log("selectedFeed====%%%%", selectedFeed);
  const channelArn = selectedFeed?.ChannelPublishSettings?.ChannelArn;
  const engagementPostId = selectedFeed?.EngagementPostId;
  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
  };
  const handleAlertDialog = (obj) => {
    Dispatch(dispatchAlertDialogData(obj));
  };

  const StopStream = async () => {
    handleLoader(true);
    const body = {
      UserId: 79,
      EngagementPostId: engagementPostId,
      ChannelArn: channelArn,
    };
    try {
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${STOP_STREAM}`,
        body,
        History
      );
      setStreamStop(true);
    } catch (err) {}
    handleLoader(false);
  };

  const onDeleteClick = () => {
    handleAlertDialog({
      open: true,
      title: "Stop Stream",
      text: "Do you want to Stop Stream?",
      handleClose: (bool) => {
        handleAlertDialog({
          open: false,
          title: "",
          text: "",
          handleClose: () => {},
        });
        if (bool) {
          StopStream();
        } else {
        }
      },
    });
  };

  return (
    <div
      style={{
        width: "50%",
        backgroundColor: "#fff",
        boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
        borderRadius: "16px",
        display: "flex",
        gap: "15px",
        padding: "10px",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <div
          style={{
            padding: "10px 20px",

            backgroundColor: "#E6F3FF",
            display: "flex",
            borderRadius: "10px",
            flexDirection: "column",
            alignItems: "center",
            // marginRight: "1%",
          }}
        >
          <PeopleIcon style={{ marginTop: "10px", color: "#007bff" }} />
          <Typography
            style={{
              margin: "10px 0px",
              fontSize: "20px",
              fontWeight: "500",
              color: "black",
            }}
          >
            234
          </Typography>
          <Typography style={{ color: "#6c757d", fontSize: "14px" }}>
            Viewers
          </Typography>
        </div>
        <div
          style={{
            padding: "10px 20px",

            backgroundColor: "#E6F3FF",
            display: "flex",
            borderRadius: "10px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FavoriteIcon style={{ marginTop: "10px", color: "red" }} />
          <Typography
            style={{
              margin: "10px 0px",
              fontSize: "20px",
              fontWeight: "500",
              color: "black",
            }}
          >
            234
          </Typography>
          <Typography style={{ color: "#6c757d", fontSize: "14px" }}>
            Emotions
          </Typography>
        </div>
        <div
          style={{
            padding: "10px 20px",

            backgroundColor: "#E6F3FF",
            display: "flex",
            borderRadius: "10px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <VisibilityIcon style={{ marginTop: "10px", color: "#ffc107" }} />
          <Typography
            style={{
              margin: "10px 0px",
              fontSize: "20px",
              fontWeight: "500",
              color: "black",
            }}
          >
            234
          </Typography>
          <Typography style={{ color: "#6c757d", fontSize: "14px" }}>
            Product Views
          </Typography>
        </div>
        <div
          style={{
            padding: "10px 20px",
            backgroundColor: "#E6F3FF",
            display: "flex",
            borderRadius: "10px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <LocalMallIcon style={{ marginTop: "10px", color: "#28a745" }} />
          <Typography
            style={{
              margin: "10px 0px",
              fontSize: "20px",
              fontWeight: "500",
              color: "black",
            }}
          >
            234
          </Typography>
          <Typography style={{ color: "#6c757d", fontSize: "14px" }}>
            Orders
          </Typography>
        </div>
      </div>
      {/* {true && renderMetaData()} */}
      <div>
        <div
          style={{
            height: "600px",
            position: "relative",
            backgroundColor: "black",
            borderRadius: "16px",
          }}
        >
          {/* <video
          id="video-player"
          playsInline
          style={{
            width: "35vw",
            height: "600px",
            backgroundColor: "black",
          }}
        ></video> */}
          {streamStop ? (
            <div
              style={{
                // width: "35vw",
                // height: "600px",
                backgroundColor: "black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  color: "white",
                  fontSize: "14px",
                  alignItems: "center",
                }}
              >
                Stream Stoped Succesfully.
              </Typography>
            </div>
          ) : (
            <div style={{ height: "100%" }}>
              <video
                id="video-player"
                playsInline
                style={{
                  // width: storycardwidthfn(1),
                  height: "100%",
                  // position: "fixed",
                  // bottom: "0",

                  backgroundColor: "gray",

                  borderRadius: "16px",
                  aspectRatio: "9/16",
                }}
              ></video>
              <div
                // className={viweCount}
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  position: "absolute",
                  top: "9px",
                  right: 0,
                  background:
                    "linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%)",
                  left: 0,
                  padding: "5px",
                  // margin: "0 16px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={""}
                    style={{
                      borderRadius: "50%",
                      height: "32px",
                      width: "32px",
                      marginRight: "16px",
                    }}
                  />
                  <div style={{ color: "white", fontSize: "16px" }}>
                    Live Steaming with paypal
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "white",
                      background: "red",
                      padding: "2px 8px",
                      borderRadius: "4px",
                      fontSize: "13px",
                    }}
                  >
                    LIVE
                    <span
                      style={{
                        height: "16px",
                        width: "16px",
                        // border: "1px solid white",
                        // borderRadius: "50%",
                      }}
                    ></span>
                  </div>
                  <div
                    style={{
                      color: "white",
                      zIndex: "2",
                      background: "rgba(0, 0, 0, 0.6)",
                      borderRadius: "3px",
                      fontSize: "12px",
                      padding: "0px 8px ",
                      textTransform: "uppercase",
                    }}
                  >
                    2k
                  </div>
                </div>
                {/* <img
                    alt="1"
                    src={
                      "https://blaash-story-live.s3.ap-south-1.amazonaws.com/story_cta/liveImg.png"
                    }
                    style={{
                      height: "30px",
                      width: "50px",
                      marginRight: "5px",
                      zIndex: "2",
                    }}
                  /> */}
              </div>
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  bottom: "20px",
                  left: 0,
                  right: 0,
                  margin: "0 auto",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                  marginTop: "-60px",
                }}
              >
                <div
                  className=" text-center "
                  style={{
                    background: "red",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    fontSize: "14px",
                    textTransform: "uppercase",
                    color: "white",
                  }}
                  onClick={onDeleteClick}
                >
                  <span>Stop Live</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LiveMoniteringDetail;
