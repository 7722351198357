//! FIRST PAGE IMAGE SLIDER
//! arrow for more than one story
//! second PAGE IMAGE SLIDER

import React, { useState, useEffect, createRef, useRef } from "react";
import Button from "@material-ui/core/Button";

import "./story.Style.css";
import "react-notifications/lib/notifications.css";
import SearchIcon from "@material-ui/icons//Search";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Stories, { WithSeeMore } from "react-insta-stories";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";

import {
  ENGT_PROD_BASE_URI,
  PREE_SIGNED_URL,
  SOMETHING_WENT_WRONG,
  SAVE_POST_DATA,
} from "../../api/apiConstants";
import { postAuthAndData } from "../../api/ApiHelper";
import { useHistory } from "react-router";
import createNotification from "../common/reactNotification";
import { NotificationContainer } from "react-notifications";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";
import { useDispatch } from "react-redux";
import {
  Textarea,
  InputBase,
  InputLabel,
  TextareaAutosize,
  IconButton,
  ButtonBase,
} from "@material-ui/core";
import AddSticker from "./StoryEditor/AddSticker";
import TextEditor from "./StoryEditor/TextEditor";

// import groupSticker from "../../assets/img/stickers/_Group_.png";

import stickersData from "../../constants/stickersData";
import VideoLibrary_Description from "./VideoLibrary_Description";
let isUploadToAWSSuccess = true;
let productIds = [];
const steps = [1, 2, 3, 4];

function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
function generateRandomString() {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

function getFileNameFromDataURL(dataURL) {
  let arr = dataURL.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    extension = mime.split("/")[1];
  let randomString = generateRandomString();

  return randomString + "image." + extension;
}

function UploadStories() {
  // let isUploadToAWSSuccess = true;
  const [activeStep, setActiveStep] = useState(1);
  const [activeProductFilter, setActiveProductFilter] = useState("name");
  const [activeDetailsFilter, setActiveDetailsFilter] = useState("Stickers");
  const [activeAttachment, setActiveAttachment] = useState("product");
  const steps = [1, 2];
  const [skipped, setSkipped] = useState(new Set());
  const [fileObj, setFileObj] = useState([]);
  const [thumbnailUrl, setThumbnailUrl] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [postContent, setPostContent] = useState([]);
  const [thumbnailContent, setThumbnailContent] = useState([]);
  const [presignedUploadUrlArr, setPresignedUploadUrlArr] = useState([]);
  const [preAssignthumbnailArr, setPreAssignthumbnailArr] = useState([]);
  const [productDetail, setProductDetail] = useState([]);
  const [discription, setDiscription] = useState({
    tag: "",
    discription: "",
    targetCatogery: "",
    caption: "",
    fontFamily: "",
    ColorPicker: "",
    buttonType: 0,
    contentType: 0,
    productId: "",
    thumbnailTitle: "",
    liveDateTime: "",
  });
  const initialStoryInput = {
    selectedCoverFile: null,
    thumbnailTitle: "",
    category: "",
    tag: "",
    ctaText: "",
    ctaUrl: "",
    storySelectedFiles: [],
    coverFileUrl: "",
  };
  const [storyInputs, setStoryInputs] = useState(initialStoryInput);
  const [textEditorInput, setTextEditorInput] = useState({
    id: 0,
    storyIndex: 0,
    text: "",
    fontSize: 18,
    fontStyle: "Roboto",
    fontWeight: 400,
    fontColor: "#000000",
    fontBackgroundColor: "#ffffff",
  });
  const [texts, setTexts] = useState([
    {
      id: 0,
      storyIndex: 0,
      x: 100,
      y: 100,
      width: null,
      height: null,
      text: "Hello World",
      textColor: "#000000",
      backgroundColor: "#ffffff",
      fontFamily: "Roboto",
      fontSize: 18,
    },
  ]);
  const [images, setImages] = useState([]);
  const userData = useSelector((select) => select.userReducer.userData);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const coverInputRef = useRef(null);
  const storyImagesInputRef = useRef(null);
  const stagesRef = useRef([]);
  const beforeStagesRef = useRef(false);
  const [designSaved, setDesignSaved] = useState(false);
  const [imgInitialDimensions, setImgInitialDimensions] = useState([]);
  const [toBeSavedFiles, setToBeSavedFiles] = useState([]);

  useEffect(() => {
    let textExists = false;
    let newTexts = texts.map((text) => {
      if (
        text.storyIndex == currentStoryIndex &&
        text.id == textEditorInput.id
      ) {
        textExists = true;
        return {
          ...text,
          storyIndex: currentStoryIndex,
          text: textEditorInput.text,
          textColor: textEditorInput.fontColor,
          backgroundColor: textEditorInput.fontBackgroundColor,
          fontFamily: textEditorInput.fontStyle,
          fontSize: textEditorInput.fontSize,
        };
      }
      return text;
    });

    if (!textExists) {
      newTexts.push({
        id: textEditorInput.id,
        x: 100,
        y: 100,
        width: null,
        height: null,
        storyIndex: currentStoryIndex,
        text: textEditorInput.text,
        textColor: textEditorInput.fontColor,
        backgroundColor: textEditorInput.fontBackgroundColor,
        fontFamily: textEditorInput.fontStyle,
        fontSize: textEditorInput.fontSize,
      });
    }
    setTexts(newTexts);
  }, [textEditorInput]);

  const handleCoverFileChange = (e) => {
    const file = e.target.files[0];
    setStoryInputs({
      ...storyInputs,
      selectedCoverFile: file,
      coverFileUrl: URL.createObjectURL(file),
    });
    var reader = new FileReader();

    reader.onload = function () {
      // //console.log(reader.result);
    };

    reader.readAsDataURL(file);
    newFileUploadThumbnail([file]);
  };

  const handleStoryImagesChange = (e) => {
    const files = e.target.files;

    const storySelectedFiles = [];
    for (let i = 0; i < files.length; i++) {
      storySelectedFiles.push(files[i]);
    }
    setStoryInputs({
      ...storyInputs,
      storySelectedFiles: [
        ...storyInputs.storySelectedFiles,
        ...storySelectedFiles,
      ],
    });
  };

  useEffect(() => {
    stagesRef.current = stagesRef.current.slice(
      0,
      storyInputs.storySelectedFiles.length
    );
    storyInputs.storySelectedFiles.forEach((file, index) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (ev) => {
        const image = new window.Image();
        image.src = ev.target.result;
        image.onload = () => {
          setImgInitialDimensions((prev) => [
            ...prev,
            {
              width: image.width,
              height: image.height,
              // width: 379,
              // height: 674,
            },
          ]);
        };
      };
    });
  }, [storyInputs.storySelectedFiles]);

  const handleSubmit = () => {
    console.log("handle submit clicked");
    ////handleLoader(true);
    // const files = storyInputs.storySelectedFiles.map((file, index) => {
    //   setCurrentStoryIndex(index);
    //   const stage = stagesRef.current[index].getStage();
    //   //console.log("stageeee kkk", stage);
    //   //console.log("imgInitialDimensions kkk", imgInitialDimensions);
    //   // const scaleX = imgInitialDimensions[index].width / stage.width();
    //   // const scaleY = imgInitialDimensions[index].height / stage.height();
    //   // stage.scaleX(scaleX);
    //   // stage.scaleY(scaleY);
    //   let uri = stage.toDataURL({
    //     width: imgInitialDimensions[index].width,
    //     height: imgInitialDimensions[index].height,
    //     // width: 379,
    //     // height: 674,
    //   });
    //   //console.log("uri kk", uri);
    //   setToBeSavedFiles((prev) => [...prev, uri]);
    //   const filename = getFileNameFromDataURL(uri);
    //   const blob = dataURLtoFile(uri, filename);
    //   let formData = new FormData();
    //   formData.append("file", blob);
    //   return formData.get("file");
    // });
    // //console.log("files", files);
    onDrop(storyInputs.storySelectedFiles);
  };

  const onDrop = (acceptedFiles) => {
    console.log("on drop clicked");
    isUploadToAWSSuccess = true;
    console.log("isUploadToAWSSuccess ondrop", isUploadToAWSSuccess);
    const filterObjArr = [];
    acceptedFiles?.forEach((file, index) => {
      let extensionType = file.name.split(".").pop();
      if (
        extensionType == "jpeg" ||
        extensionType == "jpg" ||
        extensionType == "mp4" ||
        extensionType == "png" ||
        extensionType == "webp"
      ) {
        if (
          (file.name.split(".").pop() == "mp4" && file.size < 10000000) || //Video Size max is 5 MB
          file.size < 5000000
        ) {
          const obj = {
            extension: file.name.split(".").pop(),
            contentType: file.name.split(".").pop() == "mp4" ? "2" : "1",
            file: file,
            FileId: ` ${Date.now()}_${file.name}`,
          };
          filterObjArr.push(obj);
          //console.log("File Object====", obj);
        } else {
          createNotification(
            "warning",
            "Image/Video size should be less than 10 MB."
          );
        }
      } else {
        createNotification(
          "warning",
          "Only jpeg, jpg, png, mp4  webp file type is allowed"
        );
      }
    });

    setFileObj(filterObjArr);
    fatchPreUrlData(filterObjArr);
    // beforeStagesRef.current = true;
  };

  const uploadDataOnAws = () => {
    ////handleLoader(true);
    //local variable isUploadSuccess = true
    // if (!beforeStagesRef.current) return;
    try {
      console.log("uploadDataOnAws kkk clicked");
      handleLoader(true);
      console.log("presignedUploadUrlArr", presignedUploadUrlArr);
      //console.log("uploadDataOnAws=====$$$");
      // if (discription.contentType != 5) {
      //console.log("uploadDataOnAws=====$$$", presignedUploadUrlArr);
      if (presignedUploadUrlArr?.length == 0) isUploadToAWSSuccess = false;
      console.log("isUploadToAWSSuccess", isUploadToAWSSuccess);
      // }
      presignedUploadUrlArr?.forEach((presignedUploadUrl) => {
        console.log("inside presignedUploadUrlArr");
        //if()
        let fobj = fileObj.find((e) => e.FileId == presignedUploadUrl.FileId);
        //console.log("fileObj=====$$$", fobj);
        let conetntType = addContent(fobj);
        //console.log("conetntType=====$$$", conetntType);
        const requestOptions = {
          method: "PUT",
          headers: {
            // "Content-Type": fileObj.find(
            //   (e) => e.FileId == presignedUploadUrl.FileId
            // ).file.type,

            "Content-Type": conetntType,
            "Access-Control-Allow-Origin": "*",
          },
          body: fileObj.find((e) => e.FileId == presignedUploadUrl.FileId).file,
        };
        ////handleLoader(false);
        //console.log("requestOptions====$$", requestOptions);
        //console.log("presignedUrl url", presignedUploadUrl.PreSignedUrl);
        fetch(new Request(presignedUploadUrl.PreSignedUrl, requestOptions))
          .then((response) => {
            //console("uploadDataOnAws Flow1", response);

            if (response.status == 200) {
              console.log("response ok");
              //responsehandler(true);
              //isUploadSuccess = false;
              //setFlag(true);
              uploadDataOnAws2();
            } else {
              console.log("response not ok");
              //setFlag(false);
              // responsehandler(false);
              //If any file is failing to upload - We shall not create the Post - Please try again
              isUploadToAWSSuccess = false;
              console.log("isUploadToAWSSuccess else ", isUploadToAWSSuccess);
              createNotification(
                "error",
                SOMETHING_WENT_WRONG +
                  "Error uploading image to cloud, please try again."
              );
            }
          })
          .catch((err) => console.log({ err }));
      });
      if (presignedUploadUrlArr.length > 0) {
        console.log("presignedUploadUrlArr", isUploadToAWSSuccess);
        createEngagementPost(isUploadToAWSSuccess);
      }
      // if(isUploadSuccess)
      // {
      //   //postdata
      // }
      //  responsehandler(flag);
      //Calling Thumbnail Upload
    } catch (error) {
      //console.log("uploadDataOnAws error", error);
      isUploadToAWSSuccess = false;
      console.log("isUploadToAWSSuccess catch", isUploadToAWSSuccess);
      createNotification(
        "error",
        SOMETHING_WENT_WRONG +
          "Error uploading image to cloud, please try again."
      );
      ////handleLoader(false);
    }
  };

  const newFileUploadThumbnail = (acceptedFiles) => {
    const filterObjArr = [];
    if (acceptedFiles?.length == 1) {
      //console.log("File====", acceptedFiles[0].name);
      acceptedFiles?.forEach((file) => {
        let extensionType = file.name.split(".").pop();
        if (
          extensionType == "jpeg" ||
          extensionType == "jpg" ||
          extensionType == "mp4" ||
          extensionType == "png" ||
          extensionType == "webp"
        ) {
          if (file.size < 3000000) {
            const obj = {
              extension: file.name.split(".").pop(),
              contentType: file.name.split(".").pop() == "mp4" ? "2" : "1",
              file: file,
              FileId: ` ${Date.now()}_${file.name}`,
            };
            filterObjArr.push(obj);
          } else {
            createNotification(
              "warning",
              "Image size should be less than 3 MB."
            );
          }
        } else {
          createNotification(
            "warning",
            "Only jpeg, jpg, png, webp file type is allowed."
          );
        }
      });
    } else {
      createNotification("warning", "Only one image allowed");
    }
    setThumbnailUrl(filterObjArr);
    fetchPreUrlDataThumblail(filterObjArr);
  };

  const handleResponseCode = (resp) => {
    if (!resp || resp.code === -1) {
      createNotification(
        "error",
        SOMETHING_WENT_WRONG + ", Please try again later."
      );
      ////handleLoader(false);
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    // //console.log(userData,"userdata")
    // if(!userData){
    //   Auth.signOut();
    //   history.push("/login");
    // }
    ////handleLoader(false);
    // //console.log("track this---uploadstories--productDetail,productIds------>",productDetail,productIds);
  }, [productDetail, productIds]);

  const handleLoader = (showBool) => {
    dispatch(dispatchLoaderData(showBool));
  };

  const fatchPreUrlData = (filterObjArr) => {
    console.log("fatchPreUrlData before response clicked");
    if (!userData) {
      Auth.signOut();
      history.push("/login");
    }
    //handleLoader(true);
    const body = {
      userId: userData.UserID,
      files: filterObjArr,
    };
    postAuthAndData(
      `${ENGT_PROD_BASE_URI}${PREE_SIGNED_URL}`,
      body,
      history
    ).then((res) => {
      if (handleResponseCode(res)) {
        console.log("fatchPreUrlData after response clicked");
        //handleLoader(false);
        let newPresignedUploadUrlArr = [];
        res?.data.forEach((e) => newPresignedUploadUrlArr.push(e));

        setPresignedUploadUrlArr([
          ...presignedUploadUrlArr,
          ...newPresignedUploadUrlArr,
        ]);
        //console.log(" res?.data====", res?.data);
        let newPostContent = res?.data.map((e) => ({
          fileName: e.FileKey,
          fileType: e.FileExtenssion == "mp4" ? 2 : 1,
          fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
        }));

        setPostContent(newPostContent);
        //setDesignSaved(true);
        //console.log("newPostContent====", newPostContent);
      }
    });
  };

  useEffect(() => {
    if (presignedUploadUrlArr.length > 0 && postContent.length > 0) {
      uploadDataOnAws();
    }
  }, [presignedUploadUrlArr, postContent]);

  const fetchPreUrlDataThumblail = (filterObjArr) => {
    if (!userData) {
      Auth.signOut();
      history.push("/login");
    }
    //handleLoader(true);
    const body = {
      userId: userData.UserID,
      files: filterObjArr,
    };
    postAuthAndData(
      `${ENGT_PROD_BASE_URI}${PREE_SIGNED_URL}`,
      body,
      history
    ).then((res) => {
      if (handleResponseCode(res)) {
        // //handleLoader(false);
        let newPreAssignthumbnailArr = [];
        res?.data.forEach((e) => newPreAssignthumbnailArr.push(e));
        setPreAssignthumbnailArr([
          ...preAssignthumbnailArr,
          ...newPreAssignthumbnailArr,
        ]);
        setThumbnailContent(
          res?.data.map((e) => ({
            fileName: e.FileKey,
            fileType: e.FileExtenssion == "mp4" ? 2 : 1,
            fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
          }))
        );
      }
    });
  };

  const addContent = (fobj) => {
    //console.log("addContent ffob======", fobj);
    //console.log("addContent ffobExtenssion======", fobj);
    let contentFile;
    if (fobj.contentType == 1) {
      contentFile = `image/${fobj?.extension}`;
    } else {
      contentFile = `video/${fobj?.extension}`;
    }
    return contentFile;
  };

  const uploadDataOnAws2 = () => {
    // //handleLoader(true);
    try {
      if (preAssignthumbnailArr?.length == 0) isUploadToAWSSuccess = false;
      //console.log("uploadDataOnAws2=====$$$", preAssignthumbnailArr);
      //console.log("uploadDataOnAws2Thumbnail=====$$$", thumbnailUrl);
      preAssignthumbnailArr?.forEach((presignedUploadUrl) => {
        let thumbnailFileObj = thumbnailUrl.find(
          (e) => e.FileId == presignedUploadUrl.FileId
        );

        //console.log("thumbnailFileObj=====$$$", thumbnailFileObj);
        let conetntType = addContent(thumbnailFileObj);
        //console.log("thumbnail conetntType =====$$$", conetntType);
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": conetntType,
            "Access-Control-Allow-Origin": "*",
          },
          body: thumbnailUrl.find((e) => e.FileId == presignedUploadUrl.FileId)
            .file,
        };
        ////console("before fatch data in thumbnailContent");
        fetch(
          new Request(presignedUploadUrl.PreSignedUrl, requestOptions)
        ).then((response) => {
          ////console("uploadDataOnAws Flow2", response.status);
          if (response.status == 200) {
            //setFlag(true);
            //handleLoader(false);
            //console.log("NEW CHNAGE==");
          } else {
            ////handleLoader(false);
            isUploadToAWSSuccess = false;
            createNotification(
              "error",
              SOMETHING_WENT_WRONG +
                "Error uploading image to cloud, please try again."
            );
            //setFlag(false);
          }
        });
      });
      //Once Thumb nail is added - Save Post Data
    } catch {
      isUploadToAWSSuccess = false;
      ////handleLoader(false);

      createNotification(
        "error",
        SOMETHING_WENT_WRONG +
          "Error uploading image to cloud, please try again."
      );
    }

    //After the 2nd Upload Initiate the Post
  };

  const createEngagementPost = (isAWSSuccess) => {
    //console.log("Success Flow ===", isAWSSuccess);
    if (isAWSSuccess) {
      //Send Data to Server
      createPost();
    } else {
      createNotification(
        "error",
        SOMETHING_WENT_WRONG +
          "Error uploading image to cloud, please try again."
      );
    }
  };

  const checkManadatoryFields = () => {
    let hasMandatoryFieldmissing = true;
    if (!(thumbnailUrl?.length > 0)) {
      hasMandatoryFieldmissing = false;
      createNotification("warning", "Add banner image.");
    } else if (
      !(storyInputs.thumbnailTitle?.length > 0) &&
      discription.contentType != 5
    ) {
      hasMandatoryFieldmissing = false;
      createNotification("warning", "Enter banner title.");
      hasMandatoryFieldmissing = false;
    } else if (!(storyInputs.thumbnailTitle.length > 0)) {
      hasMandatoryFieldmissing = false;
      createNotification("warning", "Enter description.");
    }
    ////console("hasMandatoryFieldmissing", hasMandatoryFieldmissing);
    return hasMandatoryFieldmissing;
  };

  const createPost = () => {
    // debugger;\
    //console.log("userdata", userData);
    if (!userData) {
      Auth.signOut();
      history.push("/login");
    }
    ////handleLoader(true);
    checkManadatoryFields();
    const body = {
      userId: userData?.UserID,
      description: storyInputs?.thumbnailTitle,
      tags: "",
      contentType: 1,
      postText: "",
      textfontFamily: "",
      captionFont: "",
      Video_duration: 0,
      captionColor: "",
      captionFont: "",
      ctaImageId: 0,
      buttonType: 0,
      buttonHeader: "",
      caption: "",
      buttonHeader: "",
      cTAUrl: "",
      postContents: postContent,
      ProductIdList: productIds,
      Thumbnail_URL: thumbnailContent[0]?.fileUrl,
      Thumbnail_Title: storyInputs?.thumbnailTitle,
      liveDateTime: 1674680400000,
      Button_CTA: storyInputs?.ctaText,
      Button_CTA_URL: storyInputs?.ctaUrl,
    };
    //console.log("body", body);

    postAuthAndData(`${ENGT_PROD_BASE_URI}${SAVE_POST_DATA}`, body, history)
      .then((res) => {
        console.log("SAVE_POST_DATAaaaaaaaaaaa");
        if (handleResponseCode(res)) {
          createNotification("success", "Post created successfully.");

          setFileObj([]);
          setThumbnailUrl([]);
          setThumbnailContent([]);
          // setThumbnailUrl([]);
          setProductDetail([]);
          productIds = [];
          setToBeSavedFiles([]);
          setDesignSaved(false);
          // setPostContent([]);
          setTexts([]);
          setPreAssignthumbnailArr([]);
          setPresignedUploadUrlArr([]);
          setImgInitialDimensions([]);
          setImages([]);
          // storyImagesInputRef.current.value = "";
          // coverInputRef.current.value = "";
          // stagesRef.current.map((stage, index) => {
          //   stagesRef.current[index] = null;
          // });
          setStoryInputs(initialStoryInput);
          setActiveStep(1);
          ////handleLoader(false);
          //console.log("end");
          handleLoader(false);
        }
      })
      .catch((err) => {
        createNotification("error", "Something went wrong.");
      });
  };
  const addStickerToPanel = ({ src, width, x, y }) => {
    // //console.log("images",images);
    setImages((currentImages) => [
      ...currentImages,
      {
        storyIndex: currentStoryIndex,
        width,
        x,
        y,
        src,
        resetButtonRef: createRef(),
      },
    ]);
  };
  const handleAddText = () => {
    setTextEditorInput({
      id: texts.length + 1,
      text: "Add Text",
      fontSize: 18,
      fontStyle: "Roboto",
      fontWeight: 400,
      fontColor: "#000000",
      fontBackgroundColor: "#84FFF8",
    });
  };
  const [error, setError] = useState("");

  const handleNext = () => {
    //console.log("handleNext");
    if (storyInputs.thumbnailTitle == "") {
      setError("Thumbnail Title is required");
    } else if (productIds.length == 0) {
      createNotification("warning", "Please select atleast one product.");
    } else {
      // setCurrentStoryIndex(0);
      setActiveStep(2);
    }
  };
  const generateRandomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const updateProductid = (product_id) => {
    productIds = [...product_id];
    // //console.log('ajay 01 updateProductid is running');
  };

  return (
    <div>
      <NotificationContainer />

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "15px 0",
          width: "70%",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          {/* {steps.map((step, idx) => (
            <>
              {idx !== 0 && (
                <hr
                  style={{
                    display: "block",
                    // height: "4px",
                    width: "40px",
                    border: 0,
                    // borderWidth: "8px",
                    borderRadius: "0 20px 20px 0",
                    borderTop:
                      idx + 1 <= activeStep
                        ? "6px solid #017EFA"
                        : "6px solid #EFF0F7",
                    // margin: "1em 0",
                    padding: 0,
                  }}
                />
              )}
              <div
                style={{
                  width: "35px",
                  height: "35px",
                  color: idx + 1 <= activeStep ? "white" : "black",
                  margin: " 0 5px",
                  background: idx + 1 <= activeStep ? "#017EFA" : "#EFF0F7",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "10px",
                }}
              >
                {step}
              </div>
              {idx < steps.length - 1 && (
                <hr
                  style={{
                    display: "block",
                    // height: "19px",
                    width: "40px",
                    border: 0,
                    borderTop:
                      idx + 1 <= activeStep
                        ? "6px solid #017EFA"
                        : "6px solid #EFF0F7",
                    borderRadius: "20px 0px 0px 20px",

                    // margin: "1em 0",
                    padding: 0,
                  }}
                />
              )}
            </>
          ))} */}
        </div>
      </div>

      {activeStep === 1 && (
        <>
          <div style={{ display: "flex", gap: "30px", height: "79vh" }}>
            <div
              style={{
                width: "70%",
                background: "#FFFFFF",
                boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
                borderRadius: "16px",
              }}
            >
              <div
                style={{
                  padding: "20px 10px",
                  position: "relative",
                }}
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  <div
                    style={{
                      width: "55%",
                      border: "1px solid rgba(207, 207, 207, 0.5)",
                      borderRadius: "20px",
                      padding: "20px 10px",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <InputLabel style={{ fontSize: "14px" }}>
                        Cover Image
                      </InputLabel>
                      <div
                        style={{
                          display: "flex",
                          gap: "40px",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "58px", height: "58px" }}>
                          {storyInputs.selectedCoverFile !== null && (
                            <img
                              style={{
                                objectFit: "cover",
                                borderRadius: "50%",
                                width: "58px",
                                height: "58px",
                              }}
                              src={URL.createObjectURL(
                                storyInputs.selectedCoverFile
                              )}
                              alt={storyInputs.selectedCoverFile.name}
                            />
                          )}
                        </div>

                        <Button
                          style={{
                            color: "#017EFA",
                            background: "#F2F9FF",
                            padding: "5px 15px",
                            border: "1px solid #57B2E0",
                            height: "30px",
                            borderRadius: "9px",
                            fontSize: "12px",
                            marginRight: "50px",
                          }}
                          onClick={() => coverInputRef.current.click()}
                        >
                          <input
                            type="file"
                            ref={coverInputRef}
                            style={{ visibility: "hidden", width: "0px" }}
                            onChange={handleCoverFileChange}
                          />
                          UPLOAD
                        </Button>
                      </div>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                      <InputLabel style={{ fontSize: "14px" }}>
                        Thumbnail Title
                      </InputLabel>
                      {error && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {error}
                        </div>
                      )}
                      <InputBase
                        placeholder="Ex. blaash"
                        style={{
                          border:
                            error !== ""
                              ? "1px solid red"
                              : "1px solid #F0F0F0",
                          padding: "0 10px",
                          width: "100%",
                          borderRadius: "10px",
                        }}
                        value={storyInputs.thumbnailTitle}
                        onChange={(e) =>
                          setStoryInputs({
                            ...storyInputs,
                            thumbnailTitle: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                      <InputLabel style={{ fontSize: "14px", fontWeight: 400 }}>
                        CTA Text
                      </InputLabel>

                      <InputBase
                        placeholder="Ex. blaash"
                        style={{
                          border: "1px solid #F0F0F0",
                          padding: "0 10px",
                          width: "100%",
                          borderRadius: "10px",
                        }}
                        value={storyInputs.ctaText}
                        onChange={(e) =>
                          setStoryInputs({
                            ...storyInputs,
                            ctaText: e.target.value,
                          })
                        }
                      />
                      <div className="form-group" style={{ marginTop: "20px" }}>
                        <label>
                          <input
                            type="checkbox"
                            //checked={disableProductCard}
                            //onChange={handleProductCardCheckboxChange}
                          />
                          &nbsp;
                          <span style={{ fontSize: "14px", fontWeight: 400 }}>
                            Disable Product Card
                          </span>
                        </label>
                      </div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <InputLabel style={{ fontSize: "14px", fontWeight: 400 }}>
                        To add CTA URL
                      </InputLabel>

                      <InputBase
                        placeholder="Ex. blaash"
                        style={{
                          border: "1px solid #F0F0F0",
                          padding: "0 10px",
                          width: "100%",
                          borderRadius: "10px",
                        }}
                        value={storyInputs.ctaUrl}
                        onChange={(e) =>
                          setStoryInputs({
                            ...storyInputs,
                            ctaUrl: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  {/* //! FIRST PAGE IMAGE SLIDER */}
                  <div
                    style={{
                      width: "45%",
                      border: "1px solid rgba(207, 207, 207, 0.5)",
                      borderRadius: "20px",
                      display: "flex",
                      justifyContent: "center",
                      padding: "20px 10px",
                    }}
                  >
                    <div
                      style={{
                        width: "280px",
                        height: "480px",
                        backgroundColor: "#f5f5f5",
                        borderRadius: "10px",
                        position: "relative",
                        overflow: "hidden",
                        zIndex: 2002020020,
                        // border: "2px solid red",
                      }}
                    >
                      {/* //! arrow for more than one story  */}
                      {storyInputs.storySelectedFiles.length > 1 && (
                        <div
                          style={{
                            height: "28px",
                            width: "28px",
                            backgroundColor: " rgba(255, 255, 255, 0.5)",
                            backdropFilter: "blur(9px)",
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.22)",
                            borderRadius: "10px",
                            transform: "matrix(0.71, 0.71, 0.71, -0.71, 0, 0)",
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            margin: "auto auto auto -5px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: "9999999999",
                          }}
                          onClick={() =>
                            setCurrentStoryIndex(currentStoryIndex - 1)
                          }
                        >
                          <ArrowBackIosIcon
                            style={{
                              transform:
                                "matrix(0.71, 0.71, 0.71, -0.71, 0, 0)",
                              color: "black",
                              fontSize: "18px",
                            }}
                          />
                        </div>
                      )}
                      {/* //! for more than one story  */}
                      {storyInputs.storySelectedFiles.length > 0 && (
                        <Stories
                          stories={storyInputs.storySelectedFiles.map(
                            (file) => {
                              return {
                                url: URL.createObjectURL(file),
                                type: "image",
                              };
                            }
                          )}
                          width={"100%"}
                          isPaused={true}
                          height={"100%"}
                          preventDefault={true}
                          currentIndex={currentStoryIndex}
                        />
                      )}
                      {currentStoryIndex + 1 <
                        storyInputs.storySelectedFiles.length && (
                        <div
                          style={{
                            height: "28px",
                            width: "28px",
                            backgroundColor: " rgba(255, 255, 255, 0.5)",
                            backdropFilter: "blur(9px)",
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.22)",
                            borderRadius: "10px",
                            transform: "matrix(0.71, 0.71, 0.71, -0.71, 0, 0)",
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            cursor: "pointer",
                            margin: "auto 10px auto auto",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: "1999",
                          }}
                          onClick={() =>
                            setCurrentStoryIndex(currentStoryIndex + 1)
                          }
                        >
                          <ArrowForwardIosIcon
                            style={{
                              color: "black",
                              fontSize: "18px",
                              transform:
                                "matrix(0.71, 0.71, 0.71, -0.71, 0, 0)",
                            }}
                            // onClick={nextContent}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        marginBottom: "-2rem",
                      }}
                    >
                      <Button
                        style={{
                          color: "#017EFA",
                          background: "#F2F9FF",
                          padding: "5px 15px",
                          border: "1px solid #57B2E0",
                          borderRadius: "9px",
                          fontSize: "12px",
                        }}
                        onClick={() => storyImagesInputRef.current.click()}
                      >
                        <input
                          type="file"
                          ref={storyImagesInputRef}
                          multiple
                          accept="image/*,video/*"
                          style={{ visibility: "hidden", width: "0px" }}
                          onChange={handleStoryImagesChange}
                        />
                        UPLOAD
                      </Button>
                      {/* <Button
                        style={{
                          color: "#017EFA",
                          background: "#F2F9FF",
                          padding: "5px 15px",
                          border: "1px solid #57B2E0",
                          borderRadius: "9px",
                          fontSize: "12px",
                        }}
                      >
                        REORDER
                      </Button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                background: "#FFFFFF",
                boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
                borderRadius: "16px",
                width: "30%",
                padding: "10px",
              }}
            >
              <VideoLibrary_Description
                productDetail={productDetail}
                setProductDetail={setProductDetail}
                productIds={productIds}
                updateProductid={updateProductid}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 0",
            }}
          >
            <div></div>

            {/* <Button
              style={{
                color: "white",
                background: "#017EFA",
                padding: "5px 15px",
                borderRadius: "9px",
                fontSize: "12px",
              }}
              disabled={storyInputs.storySelectedFiles.length == []}
              onClick={handleNext}
            >
              NEXT STEP
            </Button> */}
            <Button
              style={{
                color: "white",
                background: "#017EFA",
                padding: "5px 15px",
                borderRadius: "9px",
                fontSize: "12px",
              }}
              onClick={() => handleSubmit()}
            >
              Finish
            </Button>
            <div
              style={{
                position: "absolute",
                opacity: "0",
                pointerEvents: "none",
              }}
            >
              {storyInputs.storySelectedFiles.map((file, index) => (
                //! second PAGE IMAGE SLIDER

                <AddSticker
                  ref={(stag) => (stagesRef.current[index] = stag)}
                  background={file}
                  width={399}
                  height={550}
                  scaleX={1}
                  scaleY={1}
                  key={() => generateRandomString(5)}
                  images={images}
                  setImages={setImages}
                  currentStoryIndex={index}
                  texts={texts}
                  setTexts={setTexts}
                />
              ))}
            </div>
          </div>
        </>
      )}
      {activeStep === 55 && (
        <div
          style={{
            display: "flex",
            gap: "30px",
            height: "80vh",
            position: "relative",
            // border: "2px solid red"
          }}
        >
          <div
            style={{
              width: "70%",
              background: "#FFFFFF",
              boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
              borderRadius: "16px",
              // border: "2px solid green",
            }}
          >
            <div
              style={{
                padding: "20px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3 style={{ fontSize: "16px", marginBottom: "30px" }}>
                  Add Details
                </h3>
                <div
                  style={{
                    display: "flex",
                    // justifyContent: "space-around",
                    alignItems: "center",
                    gap: "10px ",
                    fontSize: "13px",
                  }}
                >
                  <div
                    style={{
                      background:
                        activeDetailsFilter === "Stickers"
                          ? "#1F2020"
                          : "#F2F2F2",
                      borderRadius: "7px",
                      padding: "5px 8px",
                      cursor: "pointer",
                      color:
                        activeDetailsFilter === "Stickers"
                          ? "#ffffff"
                          : "#212222",
                    }}
                    onClick={() => setActiveDetailsFilter("Stickers")}
                  >
                    Stickers
                  </div>
                  <div
                    style={{
                      background:
                        activeDetailsFilter === "Text Font"
                          ? "#1F2020"
                          : "#F2F2F2",
                      borderRadius: "7px",
                      cursor: "pointer",
                      padding: "5px 8px",
                      color:
                        activeDetailsFilter === "Text Font"
                          ? "#FFFFFF"
                          : "#212222",
                    }}
                    onClick={() => setActiveDetailsFilter("Text Font")}
                  >
                    Text Font
                  </div>
                </div>
              </div>
              <div style={{}}></div>

              {activeDetailsFilter == "Stickers" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "10px",
                      border: "1px dashed #017efa",
                      borderRadius: "8px",
                      overflow: "hidden",
                      padding: "10px",
                      flexWrap: "wrap",
                      margin: "0px",
                      height: "45vh",
                      fontSize: "14px",
                      position: "relative",
                    }}
                  >
                    {stickersData.map((sticker) => {
                      return (
                        //! STICKERS (buttons)
                        <button
                          style={{
                            height: "80px",
                            width: "80px",
                            background: "none",
                            border: "none",
                            margin: 0,
                            padding: 0,
                          }}
                          onMouseDown={() => {
                            //console.log("STICKERS CLICKED");
                            addStickerToPanel({
                              src: sticker.url,
                              width: sticker.width,
                              x: 100,
                              y: 100,
                            });
                          }}
                        >
                          <img
                            alt={sticker.alt}
                            src={sticker.url}
                            width={sticker.width}
                            // height={"80px"}
                            style={{ borderRadius: "10px" }}
                          />
                        </button>
                      );
                    })}
                    {designSaved && (
                      <div
                        style={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          bottom: 0,
                          left: 0,
                          background: "rgba(0,0,0,0.15)",
                        }}
                        onClick={() => {}}
                      ></div>
                    )}
                  </div>
                </>
              )}
              {activeDetailsFilter == "Text Font" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    borderRadius: "8px",
                  }}
                >
                  <TextEditor
                    textEditorInput={textEditorInput}
                    setTextEditorInput={setTextEditorInput}
                    handleAddText={handleAddText}
                  />
                  {designSaved && (
                    <div
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        bottom: 0,
                        left: 0,
                        background: "rgba(0,0,0,0.15)",
                      }}
                      onClick={() => {}}
                    ></div>
                  )}
                </div>
              )}
              {/* <h5 style={{ fontSize: "16px" }}>File added</h5> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 0",
                }}
              >
                <Button
                  style={{
                    color: "#017EFA",
                    background: "#F2F9FF",
                    padding: "5px 15px",
                    border: "1px solid #57B2E0",
                    borderRadius: "9px",
                    fontSize: "12px",
                  }}
                  onClick={() => setActiveStep(1)}
                >
                  PREVIOUS
                </Button>
                {!designSaved ? (
                  <Button
                    style={{
                      color: "white",
                      background: "#017EFA",
                      padding: "5px 15px",
                      borderRadius: "9px",
                      fontSize: "12px",
                    }}
                    onClick={handleSubmit}
                  >
                    Save Design
                  </Button>
                ) : (
                  <Button
                    style={{
                      color: "white",
                      background: "#017EFA",
                      padding: "5px 15px",
                      borderRadius: "9px",
                      fontSize: "12px",
                    }}
                    onClick={() => handleSubmit()}
                  >
                    Finish
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              background: "#FFFFFF",
              boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
              borderRadius: "16px",
              width: "35%",
              padding: "10px",
              display: "flex",
              alignItems: "end",
            }}
          >
            <div
              style={{
                height: "100%",
                border:
                  storyInputs.storySelectedFiles.length > 0
                    ? ""
                    : "1px dashed #017efa",
                // margin: "auto",
                borderRadius: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                position: "relative",
              }}
            >
              {currentStoryIndex > 0 && (
                <div
                  style={{
                    height: "25px",
                    width: "25px",
                    backgroundColor: " rgba(255, 255, 255, 0.3)",
                    backdropFilter: "blur(5px)",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.22)",
                    borderRadius: "6px",
                    transform: "matrix(0.71, 0.71, 0.71, -0.71, 0, 0)",
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    margin: "auto auto auto -5px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: "9999999999",
                  }}
                  onClick={() => setCurrentStoryIndex(currentStoryIndex - 1)}
                >
                  <ArrowBackIosIcon
                    style={{
                      transform: "matrix(0.71, 0.71, 0.71, -0.71, 0, 0)",
                      color: "black",
                      fontSize: "17px",
                    }}
                  />
                </div>
              )}
              {storyInputs.storySelectedFiles.map((file, index) => (
                //! second PAGE IMAGE SLIDER
                <div
                  style={{
                    display: currentStoryIndex !== index ? "none" : "block",
                    position: "relative",
                    // border: "2px solid green",
                  }}
                >
                  {!designSaved ? (
                    <AddSticker
                      ref={(stag) => (stagesRef.current[index] = stag)}
                      background={file}
                      width={399}
                      height={550}
                      scaleX={1}
                      scaleY={1}
                      key={() => generateRandomString(5)}
                      images={images}
                      setImages={setImages}
                      currentStoryIndex={index}
                      texts={texts}
                      setTexts={setTexts}
                    />
                  ) : (
                    <img
                      src={toBeSavedFiles[index]}
                      // src={URL.createObjectURL(
                      //   storyInputs.storySelectedFiles )}

                      style={{ width: "380px", height: "550px" }}
                    />
                  )}
                </div>
              ))}

              {currentStoryIndex + 1 <
                storyInputs.storySelectedFiles.length && (
                <div
                  style={{
                    height: "25px",
                    width: "25px",
                    backgroundColor: " rgba(255, 255, 255, 0.3)",
                    backdropFilter: "blur(5px)",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.22)",
                    borderRadius: "6px",
                    transform: "matrix(0.71, 0.71, 0.71, -0.71, 0, 0)",
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    cursor: "pointer",
                    margin: "auto -5px auto auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: "1999",
                  }}
                  onClick={() => setCurrentStoryIndex(currentStoryIndex + 1)}
                >
                  <ArrowForwardIosIcon
                    style={{
                      color: "black",
                      fontSize: "17px",
                      transform: "matrix(0.71, 0.71, 0.71, -0.71, 0, 0)",
                    }}
                    // onClick={nextContent}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UploadStories;
