import { LOG_IN_STATE, LOG_OUT_STATE } from "../../constants/actionTypes";


const INITIAL_STATE = {
    logInState: "LOG_IN_STATE",
  };

  const loginStateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

      case LOG_IN_STATE:
        return {
          ...state,
          logInState: action?.payload,
        };

        case LOG_OUT_STATE:
        return {
          ...state,
          logInState: action?.payload,
        };
        
      default:
        return state;
    }
  };

  export default loginStateReducer;