import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import createNotification from "../common/reactNotification";
import { NotificationContainer } from "react-notifications";

import Popover from "@material-ui/core/Popover";
import {
  Typography,
  OutlinedInput,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { BsChevronDown, BsX, BsFillBellFill, BsSearch } from "react-icons/bs";
import "./header.css";

import notificationBell from "../../assets/img/header/notification.svg";
import dropdownImg from "../../assets/img/header/Vector.svg";
import searchNormal from "../../assets/img/header/searchmormal.svg";
import default_user_src from "../../assets/img/default_user.png";
import { ReactComponent as ProductTourIcon } from "../../assets/img/product_tour.svg";
import support_src from "../../assets/img/support.svg";
import settings_src from "../../assets/img/Property_Settings.svg";
import help_src from "../../assets/img/User Guide.svg";
import logout_src from "../../assets/img/logout.svg";
import { Auth } from "aws-amplify";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from '@material-ui/icons/Close';
import loginStateReducer from "../../reducers/loginState/loginStateReducer";
import { LOG_IN_STATE, LOG_OUT_STATE } from "../../constants/actionTypes";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));
// {userData?.FirstName} {userData?.LastName}

let count=1


export default function Header(props) {
  let userData = useSelector((select) => select.userReducer.userData);
  const location = useLocation()
  let history = useHistory();
  const classes = useStyles();
  const [searchOpen, setSearchOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [company, setCompany] = useState("");
  const [title, settitle] = useState("");
  const [viewEmail, setViewEmail] = useState("")
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // for notification poper
  const [notifyEl, setNotifyEl] = useState(null);
  const notifyOpen = Boolean(notifyEl);
  const notifyId = notifyOpen ? "notification-popover" : undefined;
  
  let logInState = useSelector((select) => select.loginStateReducer.logInState);
  const dispatch = useDispatch();

  const updateToLoggedIn = () => {
    dispatch({ type: LOG_IN_STATE, payload: 'LOG_IN_STATE' });
};
const updateToLoggedOut = () => {
    dispatch({ type: LOG_OUT_STATE, payload: 'LOG_OUT_STATE' });
};

console.log('header');
console.log('logInState',logInState);

  // useEffect(()=>{
  //   if(userData && userData.FirstName){
  //     console.log("emailid is--->",userData.UserName);
  //     console.log("emailid is--->",userData.FirstName);
  //   }else{
  //     if(logInState=="LOG_IN_STATE"){
  //     logOutFn()
  //     updateToLoggedOut();
  //     createNotification('error',' Session Time Out, Please login again');
  //     console.log('logged out from header');
  //   }}},[])
  
  useEffect(() => {
    if(location.pathname === "/"){
      settitle("Revenue")
    }else if(location.pathname.includes("/shoppablevideo/dashboard")){
      settitle("Shoppable Video")      
    }else if(location.pathname.includes("/shoppablevideo/uploadshoppablevideos")){
      settitle("Upload Shoppable Video")      
    }
    else if(location.pathname.includes("/shoppablevideo/transformvideo")){
      settitle("Transform Video")      
    }
    else if(location.pathname.includes("/shoppablevideo/videoslibrary")){
      settitle("Video Library")      
    }
    else if(location.pathname.includes("/story/storyDashboard")){
      settitle("Story")
    }
    else if(location.pathname.includes("/story/uploadstories")){
      settitle("Upload Stories")      
    }
    else if(location.pathname.includes("/story/storylibrary")){
      settitle("Story Library")      
    }
    else if(location.pathname.includes("/live/schedulelive")){
      settitle("Schedule Live")
    }else if(location.pathname.includes("/live/livemonitoring")){
      settitle("Live Monitoring")
    }
    else if(location.pathname.includes("/playlistmanager")){
      settitle("Playlist Manager")
    }else if(location.pathname.includes("/hireinfluncer")){
      settitle("Hire Influncer")
    }
    else if(location.pathname.includes("/billing")){
      settitle("Account Billing")
    }
  }, [location])
  
  const notifyClick = (event) => {
    setNotifyEl(event.currentTarget);
  };
  const notifyClose = () => {
    setNotifyEl(null);
  };

  const handleChange = (event) => {
    setCompany(event.target.value);
  };
  function redirectFn(param) {
    history.push(param);
  }
  const logOutFn = () => {
    setAnchorEl(null);
    Auth.signOut()
      .then((res) => redirectFn("/login"))
      .catch((err) => console.error(err));
  };
  function settingsRedirectFn() {
    setAnchorEl(null);
    redirectFn("/settings");
  }

  const [handleOpenModalPopUp, sethandleOpenModalPopUp] = useState(false);

  const openModalPopup = () => {
    sethandleOpenModalPopUp(true);
  };

  const handleCloseModalPopup = () => {
    sethandleOpenModalPopUp(false);
    console.log("popupclosed")
  };

  //! to test redux logout issue
  // console.log('ajay   timeout started');
  // setTimeout(() => {
  //   userData.FirstName = null;
  //   console.log('ajay FirstName is null now--------');
  // }, 150000);

  let theme = useSelector((select) => select.themeReducer.Rtheme);
  // console.log('ajay 03 theme header',theme);

  return (
    //     <div id="header-container2">
    //          <div className="sectionName">
    //          Design Studio
    //          </div>
    //          <div className="headerfuncrions">
    //          <div className="supportRequest">
    //          <img
    //                  // onClick={() => liveChat()}
    //                   src={notificationBell}
    //                   alt="Girl in a jacket"
    //                   width="15.5px"
    //                   height="15.5px"

    //                 ></img>
    //                 <div className="textClass">Support Request</div>
    //          </div>
    //          <div className="productTour">
    //          <img
    //                  // onClick={() => liveChat()}
    //                   src={notificationBell}
    //                   alt="Girl in a jacket"
    //                   width="15.5px"
    //                   height="15.5px"

    //                 ></img>
    //              <div className="textClass">product tour</div>
    //          </div>
    //          <div className="searchProject">
    //          <OutlinedInput
    //                   id="myInput"
    //                   colorSecondary
    //                  // value={inputMsg}
    //                   placeholder="Search...."
    //                   style={{
    //                     borderRadius: "5px 5px 5px 5px",
    //                     minWidth: "12vw",
    //                     height: "30px",
    //                     margin: ".3vw",
    //                     //borderColor: "white",
    //                   }}
    //                   //onChange={(event) => handleResponce(event)}
    //                   inputProps={{ style: { color: "rgb(72 56 56)"} }}
    //                 />
    //          <img
    //                  // onClick={() => liveChat()}
    //                   src={notificationBell}
    //                   alt="Girl in a jacket"
    //                   width="15.5px"
    //                   height="15.5px"
    //                   style={{position: "absolute",
    //                     right: "10px",
    //                     top: "11px",
    //                     }}

    //                 ></img>
    //          </div>
    //          <div className="notificationhead">
    //          <img
    //                  // onClick={() => liveChat()}
    //                   src={notificationBell}
    //                   alt="Girl in a jacket"
    //                   width="20px"
    //                   height="20px"
    //                   style={{
    //                     position: "absolute",

    // right: "6px",
    // top: "6px"

    //                   }}

    //                 ></img>
    //          </div>
    //          <div className="userProfile">
    //            <div className="userProfileIner">
    //            <Avatar
    //                           alt="Remy Sharp"
    //                           //src={}
    //                           style={{
    //                             width: 32,
    //                             height: 32,
    //                            // border: "2.2px solid gray",
    //                             //backgroundColor: "white",
    //                           }} />
    //                           <div className="nameandroll">
    //                           <Typography  style={{fontFamily: 'Poppins',
    // fontStyle: "normal",
    // fontWeight: "600",
    // fontSize: "14px",
    // lineHeight: "21px",
    // /* identical to box height */

    // /* dark */

    // color: "#1E2029",

    // opacity: "0.6"}}

    //               >
    //                uctdn isp
    //               </Typography>
    //               <Typography
    //               style={{fontFamily: 'Poppins',
    // fontStyle: "normal",
    // fontWeight: "500",
    // fontSize: "11px",
    // lineHeight: "17px",
    // /* identical to box height */

    // /* dark */

    // color: "#1E2029",

    // opacity: "0.6"}}
    //                 className="rollName"
    //               >
    //                 selecte dProd
    //               </Typography>
    //               </div>
    //               <img
    //                  // onClick={() => liveChat()}
    //                   src={dropdownImg}
    //                   alt="Girl in a jacket"
    //                   width="18.63px"
    //                   height="12.13px"
    //                 ></img>
    //            </div>
    //          </div>
    //          </div>
    //     </div>

    <div 
    id="header-container"
    style={{
      backgroundColor: theme === "dark" ? "#22252e" : "white", //black
        // opacity: theme === "dark" ? 0.8 : 0.8
          color: theme === "dark" ? "white" : "black", 
          // padding : theme === "dark" ? "0px" : "10px",
    }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0 10px",

          width: "100%",
          justifyContent: "space-between",

          
        }}
      >
        <h3 style={{ fontSize: "16px", fontWeight: 700 }}>{title}</h3>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography className={classes.typography}>
              <div className="h-logger-user-options p-0">
                {/* <div onClick={() => settingsRedirectFn("/settings")}>
                <img src={settings_src} alt="Settings" />
                <span className="pl-2 pt-2">Settings</span>
              </div> */}
                {/* <div>
                  <img src={help_src} alt="Help" />
                  <span className="pl-2 pt-2">Help</span>
                </div> */}
                <div style={{ borderBottom: "1px solid #DBDDDE" }}>
                  <img src={logout_src} alt="logout" />
                  <span className="pl-2 pt-2" onClick={logOutFn}>
                    Logout
                  </span>
                </div>
                <div></div>
              </div>
            </Typography>
          </Popover>

          <div className="h-links" onClick={openModalPopup}>
            {/* <img src={product_tour_src} className="mb-1" /> */}
            <ProductTourIcon className="mb-1" color="#017EFA" />
            <span className="ml-2"
            style={{
              color: theme === 'dark' ? 'white' : 'grey'
            }}
            >Product Tour</span>
          </div>
          <div className="h-links">
            <a
              href="mailto:support@blaashio.zohodesk.in"
              style={{ textDecoration: "none", color: "#757575" }}
            >
              <img src={support_src} className="mb-1" />
              <span 
              style={{
                color: theme === 'dark' ? 'white' : 'grey'
              }}
              className="ml-2">Support Request</span>
            </a>
          </div>
          <Dialog
            open={handleOpenModalPopUp}
            onClose={handleCloseModalPopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ width: "100%" }}
          >
            <DialogTitle id="alert-dialog-title">{"Blaash.Io"}</DialogTitle>
            {/* <CloseIcon onclick={()=>sethandleOpenModalPopUp(false)}/> */}
             
            <DialogContent>
              <iframe
                width="100%"
                height="87%"
                src="https://www.youtube.com/embed/5I4i19UgrU0"
                title="Blaash io - Amplify your Engagement, Retention and Conversion"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </DialogContent>
          </Dialog>
          <Popover
            id={notifyId}
            open={notifyOpen}
            anchorEl={notifyEl}
            onClose={notifyClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Typography className={classes.typography}>
              <div className="h-notifications">
                <div className="h-n-h">
                  <span className="h-n-h-lbl">Notifications</span>
                  <span className="h-n-h-lbl-read float-right pt-2">
                    Mark as read
                  </span>
                </div>
              </div>
            </Typography>
          </Popover>
          {/* {searchOpen === false ? ( */}
          {/* <div className="h-s-sec ">
            <input type="text" className="h-s-sec-input" placeholder="Search" />
           
            <div className="h-s-sec-img icon-container">
              <BsSearch
                className="h-icons"
                style={{ color: "#017EFA", fontSize: "15px" }}
              ></BsSearch>
            </div>
          </div> */}
          {/* ) : (
          <BsSearch
            className="h-icons "
            onClick={() => setSearchOpen(true)}
            style={{ marginLeft: "40px" }}
          ></BsSearch>
        )} */}
          {/* <div className="icon-container" style={{ margin: "0 15px" }}>
            <BsFillBellFill
              className="h-icons "
              onClick={notifyClick}
              style={{ color: "#017EFA", fontSize: "18px" }}
            ></BsFillBellFill>
          </div> */}
          <div className="h-logged-user-sec " onClick={handleClick}>
            <div className="icon-container">
              <img
                src={default_user_src}
                alt="logged user"
                className="h-logger-user"
              />
            </div>
            <div className="h-logged-user-d ">
              <p className="h-logged-user-d2 ">WELCOME</p>
              <p className="h-logged-user-d1 ">{userData?.FirstName}</p>
            </div>
            <BsChevronDown style={{ color: "#017EFA" }} />
          </div>
        </div>
      </div>
    </div>
  );
}
