import React, { lazy, Suspense, useEffect, useState } from "react";
import "./aiImage.css";
import CircularProgressBar from "../../../cloudHeader/Progressbar";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { getAuthAndData } from "../../../../../../api/ApiHelper";
import {
  ENGT_PROD_BASE_URI,
  GET_IMAGE_OPENAI,
  GET_OLD_IMAGE_OPENAI,
} from "../../../../../../api/apiConstants";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { ExtendTabButton } from "../../index.js";
import { useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../../../../../actions/route/routeActionHandler";
const TemplateCategory = lazy(() => import("../Template/TemplateCategory.js"));

const AIImage = () => {
  // const customStyles = {
  //   control: (provided) => ({
  //     ...provided,
  //     color: "#fff",
  //     backgroundColor: "#27272f", // Custom background color
  //     borderColor: "#484851", // Custom border color
  //     boxShadow: "none", // Remove default box-shadow
  //     "&:hover": {
  //       borderColor: "#555", // Custom border color on hover
  //     },
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isSelected ? "#555" : "transparent", // Highlight selected option
  //     color: state.isSelected ? "#48485" : "#000", // Custom text color
  //     "&:hover": {
  //       backgroundColor: state.isSelected ? "#555" : "#f1f1f1", // Highlight selected option on hover
  //     },
  //     singleValue: (provided, state) => ({
  //       ...provided,
  //       color: state.isSelected ? "white" : "white",
  //       //color: "#fff", // Custom text color for the selected option
  //       fontWeight: "bold", // Make the selected option bold
  //     }),
  //   }),
  //   // Customize other components as needed (e.g., singleValue, menu, etc.)
  // };

  const dispatch = useDispatch();
  const handleLoader = (showBool) => {
    dispatch(dispatchLoaderData(showBool));
  };

  const GetAIImage = async () => {
    handleLoader(true);
    setDisable(true);

    await getAuthAndData(
      `${ENGT_PROD_BASE_URI}${GET_IMAGE_OPENAI}?search=${generateText}`
    )
      .then((response) => {
        if (response.code === 1) {
          setAIImageCollection((prevList) => [...prevList, response.data]);
        }
        setDisable(false);
      })
      .catch((error) => {
        setDisable(false);
        console.log("Error:", error);
      });

    setGenerateText("");
    setDisable(true);
    setSearched(true);
    handleLoader(false);
  };

  const GetOldAIGeneratedImages = async () => {
    handleLoader(true);

    await getAuthAndData(`${ENGT_PROD_BASE_URI}${GET_OLD_IMAGE_OPENAI}`)
      .then((response) => {
        if (response.code === 1) {
          setOldImageCollection((prevList) => [...prevList, ...response.data]);
        }
        setDisable(false);
      })
      .catch((error) => {
        setDisable(false);
        console.log("Error:", error);
      });
    handleLoader(false);
  };

  const [disable, setDisable] = useState(true);
  const [searched, setSearched] = useState(false);
  const [collectionType, setCollectionType] = useState(1);
  const [generateText, setGenerateText] = useState("");
  const [aiImageCollection, setAIImageCollection] = useState([]);
  const [oldImageCollection, setOldImageCollection] = useState([]);
  console.log("setAIImageCollection", aiImageCollection);

  useEffect(() => {
    (() => {
      GetOldAIGeneratedImages();
    })();
  }, []);

  return (
    <div className="boxtemplate">
      <div
        style={{
          width: "300px",
          height: "610px",
          padding: "10px 10px 10px",
          background: "#27272F",
        }}
      >
        {searched ? (
          <div className="mt-2">
            <div className="d-flex flex-row bd-highlight justify-content-between">
              <div style={{ color: "#FFF", fontSize: "14px" }}>AI Images</div>
              <div
                className="view-more"
                onClick={() => {
                  setSearched(false);
                }}
              >
                <ArrowBackIcon style={{ width: " 20px", height: "20px" }} />
              </div>
            </div>
            <div>
              <ExtendTabButton
                className="tab-button"
                onClick={() => setCollectionType(1)}
              >
                Current Creation
              </ExtendTabButton>
              <ExtendTabButton
                className="tab-button"
                onClick={() => setCollectionType(2)}
              >
                Past Creation
              </ExtendTabButton>
              {collectionType === 1 && (
                <>
                  {aiImageCollection.length > 0 ? (
                    <Suspense fallback={<div>Loading</div>}>
                      <TemplateCategory
                        playlistgroup={aiImageCollection}
                        type={11}
                      />
                    </Suspense>
                  ) : (
                    <div>No Generate Image Available</div>
                  )}
                </>
              )}
              {collectionType === 2 && (
                <>
                  {oldImageCollection.length > 0 ? (
                    <Suspense fallback={<div>Loading</div>}>
                      <TemplateCategory
                        playlistgroup={oldImageCollection}
                        type={12}
                      />
                    </Suspense>
                  ) : (
                    <div>No Generate Image Available</div>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="mt-2">
              <div className="d-flex flex-row bd-highlight justify-content-between">
                <div style={{ color: "#FFF", fontSize: "14px" }}>
                  Text To Image
                </div>
                <div>
                  <CircularProgressBar progress={50} progressText={50} />
                </div>
              </div>
            </div>
            {/* <div className="mt-2" style={{ display: "flex" }}>
            <div className="w-100 ">
              <div style={{ color: "#FFF", fontSize: "14px" }}>
                What are you creating today ?
              </div>
              <Select
                options={playlistCollection}
                // isDisabled={obj.rewardType?.value!==2}
                name="rewardName"
                onChange={(e) => onPlaylistChange(e)}
                value={selectedPlaylist}
                //defaultValue= {selectedPlaylist}
                style={{ lineHeight: "28px" }}
                maxMenuHeight={150}
                styles={customStyles}
                placeholder="Select your all assets"
              />
            </div>
          </div>
          <div className="mt-2" style={{ display: "flex" }}>
            <div className="w-100 ">
              <div style={{ color: "#FFF", fontSize: "14px" }}>
                Add more specific details ?
              </div>
              <Select
                options={playlistCollection}
                // isDisabled={obj.rewardType?.value!==2}
                name="rewardName"
                onChange={(e) => onPlaylistChange(e)}
                value={selectedPlaylist}
                //defaultValue= {selectedPlaylist}
                style={{ lineHeight: "28px" }}
                maxMenuHeight={150}
                styles={customStyles}
                placeholder="Select your all assets"
              />
            </div>
          </div> */}
            <div className="mt-2" style={{ display: "flex" }}>
              <div className="w-100 ">
                <div style={{ color: "#FFF", fontSize: "14px" }}>
                  Write anything
                </div>
                <TextareaAutosize
                  aria-label="textarea"
                  placeholder="Type your text here..."
                  onChange={(event) => {
                    setGenerateText(event.target.value.trim());
                    if (event.target.value.trim().length >= 3) {
                      setDisable(false);
                    } else {
                      setDisable(true);
                    }
                  }}
                  multiline
                  minRows={10} // Adjust the minimum number of rows you want to display
                  style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "rgb(39, 39, 47)",
                    color: "#ffffff",
                    padding: "10px",
                    resize: "none", // To prevent manual resizing of the textarea
                    overflowY: "hidden",
                    borderRadius: "10px",
                    border: "1px solid #484851",
                    marginTop: "5px",
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-row bd-highlight justify-content-center gap-2">
              <div
                className="generate_ai_button  my-2"
                style={{
                  backgroundColor: `${
                    disable ? "rgb(112, 116, 121)" : "rgb(1, 126, 250)"
                  }`,
                }}
                onClick={(event) => {
                  event.preventDefault();
                  if (generateText.length >= 3 && !disable) {
                    GetAIImage();
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M16.2505 6.75007L15.0088 7.99173L12.5088 5.49173L13.7505 4.25007C14.1005 3.90007 14.5505 3.7334 15.0005 3.7334C15.4505 3.7334 15.9005 3.90007 16.2505 4.25007C16.9421 4.94173 16.9421 6.0584 16.2505 6.75007Z"
                    fill="white"
                  />
                  <path
                    d="M14.4245 8.58325L5.41621 17.5833C4.72454 18.2749 3.60788 18.2749 2.91621 17.5833C2.22454 16.8916 2.22454 15.7749 2.91621 15.0833L11.9245 6.08325L14.4245 8.58325Z"
                    fill="white"
                  />
                  <path
                    d="M8.29176 3.41668L8.63343 2.25835C8.66676 2.15001 8.63343 2.03335 8.55843 1.95001C8.48343 1.86668 8.3501 1.83335 8.24176 1.86668L7.08343 2.20835L5.9251 1.86668C5.81676 1.83335 5.7001 1.86668 5.61676 1.94168C5.53343 2.02501 5.50843 2.14168 5.54176 2.25001L5.8751 3.41668L5.53343 4.57501C5.5001 4.68335 5.53343 4.80001 5.60843 4.88335C5.69176 4.96668 5.80843 4.99168 5.91676 4.95835L7.08343 4.62501L8.24176 4.96668C8.2751 4.97501 8.3001 4.98335 8.33343 4.98335C8.41676 4.98335 8.49176 4.95001 8.55843 4.89168C8.64176 4.80835 8.66676 4.69168 8.63343 4.58335L8.29176 3.41668Z"
                    fill="white"
                  />
                  <path
                    d="M4.95875 8.41668L5.30042 7.25835C5.33375 7.15001 5.30042 7.03335 5.22542 6.95001C5.14209 6.86668 5.02542 6.84168 4.91709 6.87501L3.75042 7.20835L2.59209 6.86668C2.48375 6.83335 2.36709 6.86668 2.28375 6.94168C2.20042 7.02501 2.17542 7.14168 2.20875 7.25001L2.54209 8.41668L2.20042 9.57501C2.16709 9.68335 2.20042 9.80001 2.27542 9.88335C2.35875 9.96668 2.47542 9.99168 2.58375 9.95835L3.74209 9.61668L4.90042 9.95835C4.92542 9.96668 4.95875 9.96668 4.99209 9.96668C5.07542 9.96668 5.15042 9.93335 5.21709 9.87501C5.30042 9.79168 5.32542 9.67501 5.29209 9.56668L4.95875 8.41668Z"
                    fill="white"
                  />
                  <path
                    d="M17.4581 12.5833L17.7998 11.425C17.8331 11.3166 17.7998 11.2 17.7248 11.1166C17.6414 11.0333 17.5248 11.0083 17.4164 11.0416L16.2581 11.3833L15.0998 11.0416C14.9914 11.0083 14.8748 11.0416 14.7914 11.1166C14.7081 11.2 14.6831 11.3166 14.7164 11.425L15.0581 12.5833L14.7164 13.7416C14.6831 13.85 14.7164 13.9666 14.7914 14.05C14.8748 14.1333 14.9914 14.1583 15.0998 14.125L16.2581 13.7833L17.4164 14.125C17.4414 14.1333 17.4748 14.1333 17.5081 14.1333C17.5914 14.1333 17.6664 14.1 17.7331 14.0416C17.8164 13.9583 17.8414 13.8416 17.8081 13.7333L17.4581 12.5833Z"
                    fill="white"
                  />
                </svg>
                <span
                  className="m-2"
                  style={
                    {
                      //color: theme === "dark" ? "white" : "grey",
                    }
                  }
                >
                  Generate
                </span>
              </div>
            </div>
            <div style={{ color: "#FFF", fontSize: "14px" }}>Past Creation</div>
            <div>
              {oldImageCollection.length > 0 ? (
                <Suspense fallback={<div>Loading</div>}>
                  <TemplateCategory
                    playlistgroup={oldImageCollection}
                    type={12}
                  />
                </Suspense>
              ) : (
                <div>No Old AI Images Available</div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AIImage;
