import React from 'react'

const FirstPolygon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 0L33.5885 9V27L18 36L2.41154 27V9L18 0Z" fill="white"/>
<path d="M18 3L30.9904 10.5V25.5L18 33L5.00962 25.5V10.5L18 3Z" fill="#222229"/>
<path d="M15.9242 14.864V12.32H20.3562V24H17.5082V14.864H15.9242Z" fill="white"/>
</svg>
  )
}

export default FirstPolygon