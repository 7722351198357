import React from "react";
import Card from "@material-ui/core/Card";
//import live from "./image/inf1c.jpg";
import { Box, Typography, CardMedia } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { postAuthAndData } from "../../api/ApiHelper";

const useStyles = makeStyles({
  media1: {
    height: "180px",
    cursor: "pointer",
    borderRadius: "9px 9px 9px 9px",
  },
});

function LiveMoniteringCard({ setSelectedId, feed }) {
  const { media1 } = useStyles();
  const liveDate = feed?.LiveSchedule?.LiveStartDateFormat;
  const name = feed?.Thumbnail_Title;
  const imgUrl = feed?.Thumbnail_URL;

  const feedSelection = () => {
    // console.log("FeedsData 1234", feed);
    setSelectedId(feed?.EngagementPostId);
     console.log("FeedsData=====&&&", feed);
  };

  return (
    <div
      onClick={feedSelection}
      style={{ position: "relative", cursor: "pointer", marginBottom: "15px" }}
    >
      <Card
        style={{
          width: "297px",
          height: "180px",
          margin: "0px 4px 0px 4px",
          borderRadius: "9px",
          // display: flag == "IMG" ? "none" : "",
          backgroundColor: "white",
          // padding: "3px",
        }}
      >
        {/* <img src={Contents} width: alt="Italian Trulli" /> */}
        <CardMedia className={media1} image={imgUrl} />
      </Card>
      <div
        style={{
          position: "absolute",
          //right: "0",
          top: "0",
          zIndex: "999",
          width: "300px",
          height: "180px",
          paddingLeft: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontSize: ".8rem",
              whiteSpace: "initial",
              backgroundColor: "#344767",
              color: "white",
              fontWeight: "500",
              justifySelf: "end",
              padding: "2px 10px",
              margin: "5px 5px 0 auto",
              textAlign: "left",
              width: "max-content",
              borderRadius: "9px ",
            }}
          >
            {/* {item?.productdisplayName?.length > 25 */}
            {liveDate}
          </div>

          {/* <Typography
            style={{
              fontSize: ".7rem",
              whiteSpace: "initial",
              backgroundColor: "#0000006e",
              color: "white",
              padding: "2px 3px",
              marginTop: "118px",
              borderRadius: "0px 0px 0px 0px",
            }}
            variant="subtitle1"
            color="textSecondary"
            component="div"
          >
            {/* {Description.length > 35
              ? Description.slice(0, 34) + ".."
              : Description} 
            Description
          </Typography> */}

          <div
            style={{
              fontSize: ".7rem",
              // whiteSpace: "initial",
              backgroundColor: "#676DFF",
              color: "white",
              padding: "8px 10px",
              margin: "137px auto 1rem auto",
              position: "absolute",
              left: "10px",
              right: "10px",

              bottom: "-29px",
              zIndex: 1489,
              // width: "max-content",
              borderRadius: "9px",
              // marginTop: "140px",
            }}
          >
            {/* {item?.productdisplayName?.length > 25 */}
            {name}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveMoniteringCard;
