import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";

//import Stack from '@mui/material/Stack';
// import TextField from '@material-ui/core/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { BsChevronLeft } from "react-icons/bs";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
//import img from "./imgData/inf2c.jpg"
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { CustomeDatePickerENGT } from "../common/global";
import PlayerComponent from "./PlayerComponent";
import CardComponent from "./CardComponent";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
//coments
import {
  SIMILAR_INFLUENCERS,
  INFLUENCER_DETAIL,
  BOOK_INFLUENCER,
} from "../../api/apiConstants";
import { getAuthAndData, postAuthAndData } from "../../api/ApiHelper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";
import createNotification from "../common/reactNotification";
import NotificationContainer from "react-notifications/lib/NotificationContainer";

// import inf1a from "./imgData/inf1a.webp"
// import inf1b from "./imgData/inf1b.webp"
// import inf2c from "./imgData/inf2c.jpg"
// import inf3a from "./imgData/inf3b.jpg"

const useStyles = makeStyles({
  tag: {
    marginLeft: "5px",
    color: "#6b9cf3",
    fontStyle: "oblique",
  },
  price: {
    fontWeight: "bold",
    //fontStyle: "italic"
    // marginLeft:"10px"
  },
  starts: {
    // fontStyle: "italic",
    color: "#8c969b",
    marginRight: "4px",
  },
  btn: {
    backgroundColor: "#6b9cf3",
    marginTop: "10px",
    paddingLeft: "40px",
    paddingRight: "40px",
    marginLeft: "440px",
  },
  followers: {
    display: "flex",
    alignItems: "baseline",
    marginBottom: "20px",
  },
  msgDetail: {
    color: "#abb3b7",
    cursor: "pointer",
  },
});

// const data = [
//   {
//       Name:"Rajeev Rana",
//       Id:1,
//       pImg:inf1a,
//        tag:`#royalenfield
//        #timelessclassic
//       `
//   },
//   {
//       Name:"Shruti Wahi",
//       Id:2,
//       pImg:inf2c,
//       tag:`#bespokegrub #shrutiwahi `
//   },
//   {
//       Name:"Rohini James",
//       Id:3,
//       pImg:inf3a,
//       tag:`#sponsored #MinistryOfNuts #NutriFix`
//   },
//   {
//       Name:"Rajeev Rana",
//       Id:4,
//       pImg:inf1b,
//       tag:`#menwithstyleindia
//       #indianinfluencer`
//   },

//   ]

// const onDateSelect=(key,date)=>{
//   setEngagement({...engagement,[key]:date});
//   engagement[key]=date;
//   props.getSetGoalsData(engagement);
// }

function StoryDetailComponent() {
  const { tag, price, btn, followers, starts, msgDetail } = useStyles();
  const [description, setDescription] = useState();
  const History = useHistory();
  const Dispatch = useDispatch();
  const [influencerDetails, setInfluencerDetails] = useState();
  const [similarInfluencers, setSimilarInfluencers] = useState();
  const [bookingDate, setBookingDate] = useState(new Date());
  const [detailedMsg, setDetailedMsg] = useState(false);
  //   const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));

  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };
  const Id = useSelector((select) => select?.story?.storyId);
  console.log("$%$%$%", Id);
  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
  };

  const fatchSimilarById = async (Id) => {
    handleLoader(true);
    try {
      const data = await getAuthAndData(`${SIMILAR_INFLUENCERS}${Id}`, History);
      setSimilarInfluencers(data.data);
      console.log("similar influencers data%%%", data);
    } catch (error) {
      console.log(error.message);
    }
    handleLoader(false);
  };
  const fatchInfluencerById = async (Id) => {
    try {
      const data = await getAuthAndData(`${INFLUENCER_DETAIL}${Id}`);
      setInfluencerDetails(data.data);
      console.log("influencer detail data&&&&", data);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fatchInfluencerById(Id);
    fatchSimilarById(1);
  }, []);

  const hireInfluencer = async () => {
    handleLoader(true);
    const Body = {
      InfluencerId: 1,
      TenantId: 3,
      RequestInfo: description,
      RequestDate: bookingDate,
    };
    try {
      const data = await postAuthAndData(BOOK_INFLUENCER, Body, History);
      console.log("booking data****", data);

      {
        data.data
          ? createNotification(
              "success",
              "Booking request received, will share more booking details shortly."
            )
          : createNotification(
              "warning",
              "Please provide intruction for booking."
            );
      }
    } catch (error) {
      console.log(error.message);
    }
    handleLoader(false);
  };

  const goBack = () => {
    History.push("/hireinfluncer");
  };

  //console.log("influencerDetails", influencerDetails)
  //console.log("similarInfluencers", similarInfluencers)
  return (
    <div>
      <NotificationContainer />
      <div id="c-s-breadcrum" style={{ marginBottom: "10px" }}>
        <div className="c-s-breadcrum-back" onClick={() => goBack()}>
          <BsChevronLeft></BsChevronLeft>Back
        </div>
        <div className="c-s-breadcrum-title">
          <span className="pl-1 c-pointer" onClick={() => goBack()}>
            Stories /{" "}
          </span>
          <span className="text-bold">Stories Details</span>
        </div>
      </div>
      <div
        style={{ marginRight: "25px", display: "flex", marginBottom: "20px" }}
      >
        <Card
          style={{
            minWidth: 360,
            maxWidth: 360,
            marginRight: "30px",
            maxHeight: "420",
          }}
        >
          <CardMedia
            component="img"
            height="420"
            image={influencerDetails?.ProfileImageUrl}
            alt="green iguana"
          />
        </Card>
        <div>
          <div>
            <h2>{influencerDetails?.Name}</h2>
            <h7 className={tag}>{influencerDetails?.HashTags}</h7>
            <div className="description">
              {influencerDetails?.Description.length < 100 ? (
                <span> {influencerDetails?.Description}</span>
              ) : (
                <>
                  {!detailedMsg ? (
                    <>
                      <span>
                        {" "}
                        {influencerDetails?.Description?.slice(0, 100)}
                      </span>
                      <span className={msgDetail} onClick={setDetailedMsg}>
                        ...more
                      </span>
                    </>
                  ) : (
                    <span> {influencerDetails?.Description}</span>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={followers}>
            <StarOutlineIcon style={{ marginRight: "3px" }} />
            <Typography variant="body" component="div">
              {influencerDetails?.HighlightedText}
            </Typography>
          </div>
          <div className="date">
            <div>
              <div className={followers}>
                {/* <span>Price Rang </span> */}
                <Typography variant="body" component="div" className={starts}>
                  Price Range:-
                </Typography>
                <Typography variant="body" component="div" className={price}>
                  Starts from INR {influencerDetails?.Price}
                </Typography>
              </div>
              <Box
                component="div"
                style={{
                  p: 2,
                  border: "1px solid lightGrey",
                  width: "580px",
                  borderRadius: "2%",
                }}
              >
                <div className=" dflex  ">
                  <div style={{ marginRight: "8px" }}>Compaign Date</div>
                  <DatePicker
                    minDate={new Date()}
                    selected={bookingDate}
                    customInput={<CustomeDatePickerENGT />}
                    onChange={(date) => setBookingDate(date)}
                  />
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                      
                     <DesktopDatePicker
                      label="Date desktop"
                       inputFormat="MM/dd/yyyy"
                       value={value}
                       onChange={handleChange}
                       renderInput={(params) => <TextField {...params} />}
                                                                           />

                      </LocalizationProvider> */}
                </div>
                <div className="role-description ">
                  <div>Additional Instruction</div>
                  <textarea
                    className="role-description-box"
                    placeholder="Enter More Information about Campaign - Static Image, Video Or Story , Product URL etc."
                    maxLength={200}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  ></textarea>
                </div>
              </Box>
              <Button
                className={btn}
                color="primary"
                variant="contained"
                onClick={hireInfluencer}
              >
                Hire Me
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h5>Review My Work</h5>
        <div className="videoContainer">
          <PlayerComponent Video={influencerDetails?.SampleVideo1Url} />
          <PlayerComponent Video={influencerDetails?.SampleVideo2Ur1} />
          <PlayerComponent Video={influencerDetails?.SampleVideo3Ur1} />
        </div>
      </div>
      <div>
        <h5>How It Works</h5>
        <div className="videoContainer">
          <div className="workSteps">
            <h2 className="step">1</h2>
            <span>
              Shruti Wahi is the queen of the beauty and make-up YouTube world
              in India. The honey toned beauty has
            </span>
          </div>

          <div className="workSteps">
            <h2 className="step">2</h2>
            <span>
              Shruti Wahi is the queen of the beauty and make-up YouTube world
              in India. The honey toned beauty has
            </span>
          </div>

          <div className="workSteps">
            <h2 className="step">3</h2>
            <span>
              Shruti Wahi is the queen of the beauty and make-up YouTube world
              in India. The honey toned beauty has
            </span>
          </div>
        </div>
      </div>
      <div>
        <h5>Similar Trending Influencers</h5>
        <div style={{ height: "30px" }}></div>
        <div className="storyColection">
          <div className="story">
            {similarInfluencers?.map((profile) => (
              <CardComponent
                key={profile.InfluencerId}
                pImg={profile.ProfileImageUrl}
                Name={profile.Name}
                tag={profile.HashTags}
                price={profile.Price}
                id={profile.InfluencerId}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoryDetailComponent;
