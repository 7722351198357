import React from "react";

import Card from "@material-ui/core/Card";
//import img from "https://blsh-social.s3.amazonaws.com/i/manya/m1.png";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import GroupIcon from "@material-ui/icons/Group";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import MouseIcon from "@material-ui/icons/Mouse";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  name: {
    marginLeft: "5px",
    fontWeight: "bold",
    marginTop: "8px",
  },
  tagp: {
    marginLeft: "5px",
    color: "black",
    fontSize: "13px",
    fontWeight: "600",
  },
  tagp2: {
    margin: "15px 0px 5px 5px",
    color: "#5f6165",
    fontSize: "34px",
    fontWeight: "600",
  },
  tagp1: {
    marginLeft: "5px",
    color: "#5f6165",
    fontSize: "13px",
    fontWeight: "500",
  },
  card: {
    position: "relative",
  },
  cardHeader: {
    display: "flex",
    margin: "10px",
    width: "100%",
    justifyContent: "start",
  },
  bottomText: {
    margin: "0px 5px 5px 5px",
    color: "#5f6165",
    fontSize: "10px",
  },
});

function AnalysisCard({
  iconType,
  headerA,
  headerB,
  headerC,
  headerD,
  dataPercentage,
  data,
}) {
  const { name, tagp, card, tagp1, tagp2, cardHeader, bottomText } =
    useStyles();
  const checkPercentageData = () => {
    let parms = dataPercentage != "" ? dataPercentage : "0";
    let value = parseInt(parms);
    return value;
  };
  return (
    <div style={{ marginRight: "20px", marginBottom: "20px" }}>
      <Card
        className={card}
        style={{ minWidth: "18vw", maxWidth: "18vw", height: "290px" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className={cardHeader}>
            <Typography
              className={tagp}
              gutterBottom
              variant="body2"
              component="div"
            >
              {headerA}
            </Typography>
            <Typography
              className={tagp}
              gutterBottom
              variant="body2"
              component="div"
            >
              {headerB}
            </Typography>
          </div>
          {iconType == 1 && <VisibilityIcon style={{ color: "#7652e5" }} />}
          {iconType == 2 && <AccessTimeIcon style={{ color: "#17a2b8" }} />}
          {iconType == 3 && <PlayArrowIcon style={{ color: "#1741b8" }} />}
          {iconType == 4 && <GroupIcon style={{ color: "#d9c116" }} />}
          {iconType == 5 && <PersonOutlineIcon style={{ color: "#70db2d" }} />}
          {iconType == 6 && <TrackChangesIcon style={{ color: "#db7d2d" }} />}
          {iconType == 7 && <MouseIcon style={{ color: "#26813a" }} />}
          {iconType == 8 && (
            <AssignmentReturnIcon style={{ color: "#c97103" }} />
          )}
          <Typography
            className={tagp2}
            gutterBottom
            variant="body2"
            component="div"
          >
            {data}
          </Typography>
          <Typography
            className={tagp1}
            gutterBottom
            variant="body2"
            component="div"
          >
            {headerC}
          </Typography>
          <div
            style={{
              display: "flex",
              margin: "10px",
              opacity: dataPercentage != "" ? "1" : "0",
            }}
          >
            <Typography
              className={tagp1}
              gutterBottom
              variant="body2"
              component="div"
            >
              {dataPercentage}
            </Typography>
            {checkPercentageData() >= 0 && (
              <ArrowDropUpIcon style={{ color: "green" }} />
            )}
            {checkPercentageData() < 0 && (
              <ArrowDropDownIcon style={{ color: "red" }} />
            )}
          </div>
          <hr
            style={{
              //marginTop: "5px",
              // border: "0.5px solid black",
              //borderBottom: "1px solid #343131",
              borderWidth: "0.5px 0.5px 1px",
              borderStyle: "solid",
              width: "90%",
              color: "#ccc",
              borderImage: "initial",
            }}
          />
          <div style={{ width: "100%" }}>
            <Typography
              className={bottomText}
              gutterBottom
              variant="body2"
              component="div"
            >
              {headerD}
            </Typography>
          </div>
        </div>
        {/*

        <Typography
          className={name}
          gutterBottom
          variant="body2"
          component="div"
        >
          {thumbnailTitle.slice(0, 35)}..
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "5px 8px 5px 3px",
          }}
        >
          {" "}
          <div style={{ display: "flex" }}>
            <Typography
              className={tagp}
              gutterBottom
              variant="body2"
              component="div"
            >
              Number of Views
            </Typography>
            <Typography
              className={tagp1}
              gutterBottom
              variant="body2"
              component="div"
            >
              {numberofViews}
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <Typography
              className={tagp}
              gutterBottom
              variant="body2"
              component="div"
            >
              Number of Likes
            </Typography>
            <Typography
              className={tagp1}
              gutterBottom
              variant="body2"
              component="div"
            >
              {numberOfLikes}
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "5px 8px 5px 3px",
          }}
        >
          <div style={{ display: "flex" }}>
            <Typography
              className={tagp}
              gutterBottom
              variant="body2"
              component="div"
            >
              CTA Count :
            </Typography>
            <Typography
              className={tagp1}
              gutterBottom
              variant="body2"
              component="div"
            >
              {numberOfClicks}
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <Typography
              className={tagp}
              gutterBottom
              variant="body2"
              component="div"
            >
              Avg watch duration:
            </Typography>
            <Typography
              className={tagp1}
              gutterBottom
              variant="body2"
              component="div"
            >
              {avgDurationInSeconds}(sec)
            </Typography>
          </div>
        </div> */}
      </Card>
    </div>
  );
}

export default AnalysisCard;
