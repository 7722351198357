/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import '../../igUploads/OneClickPost.css';


 const ComboBoxDropdown =({PlaylistArr, selectedPlaylistt, playlistDetails})=> {




  const handleSelect = (event, value) => {
    console.log('Selected Playlist:', value ? value : null);
    console.log('Selected Playlist:', value ? value.Name : null);
    selectedPlaylistt(value)

  };

  return (
    <Autocomplete
      id="combo-box-demo"
      options={PlaylistArr}
      getOptionLabel={(option) => option.Name}
      value={playlistDetails}

      
      renderInput={(params) => 
      <TextField 
      {...params} 
      label="" 
      color="white"
      variant="outlined"
      // inputProps={{
      //   style: { color: "white"},
      // }}  
      />}
      classes={{
        paper: 'custom-autocomplete-paper',
        inputRoot: 'custom-autocomplete-inputRoot',
        input: 'custom-autocomplete-input',
      
      }} 
      style={{ color: "white", width: 315, height: 37, position:"relative", bottom: -390 }}
      onChange={handleSelect} // Handle selection changes      
    />
  );
}


// const top100Films = [
//   { title: 'The Shawshank Redemption', year: 1994 },
//   { title: 'Monty Python and the Holy Grail', year: 1975 },
// ];


export default ComboBoxDropdown;
