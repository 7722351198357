import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

function StodioCategory(props) {
  const contentType = props?.discription?.contentType;
  return (
    <FormControl>
      <FormLabel
        id="demo-row-radio-buttons-group-label"
        style={{ fontSize: "1.1rem" }}
      >
        What are you creating today
      </FormLabel>
      <RadioGroup
        style={{ backgroundColor: "white" }}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={(e) => props.contentType(e)}
      >
        {/* <FormControlLabel
          value="Story"
          control={<Radio checked={contentType == 1} />}
          label="Story"
        /> */}
        <FormControlLabel
          value="Post"
          control={<Radio checked={contentType == 2} />}
          label="Shoppable Video"
        />
        {/* <FormControlLabel
          value="LiveSchedule"
          control={<Radio checked={contentType == 5} />}
          label="Live Schedule"
        /> */}
        {/* <FormControlLabel value="Content" control={<Radio />} label="Content" /> */}
      </RadioGroup>
    </FormControl>
  );
}

export default StodioCategory;
