import React, {useCallback,useState, useEffect} from 'react'

import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ReactPlayer from "react-player";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import createNotification from "../common/reactNotification";
import "react-notifications/lib/notifications.css";
import { postAuthAndData, getAuthAndData } from "../../api/ApiHelper";
import { useHistory } from "react-router";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";
import Dropzone from "react-dropzone";
import { NotificationContainer } from "react-notifications";
import Typography from "@material-ui/core/Typography";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import "./transformvideo.css"
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import {updateUserDataObj} from "../../actions/login/updateUserDataObj"
import {
    ENGT_PROD_BASE_URI,
    PREE_SIGNED_URL,
    SOMETHING_WENT_WRONG,
    TRANSFORM_FILES,
    IDTY_PROD_HOST_URI,
    USER_DATA_GROUP_PERMISSIONS
  } from "../../api/apiConstants";
  let presignedUploadUrlArr = [];
let userData;
let isUploadToAWSSuccess = true;
let filterObjArr = [];


function TransformVideoComponent() {
    const [fileObj, setFileObj] = useState([]);
    const [PostContent, setPostContent] = useState([]);
    const [contentType, setContentType] = useState(1)
    const [urlObject, setUrlobject] = useState()
    const history = useHistory();
    const dispatch = useDispatch();
    userData = useSelector((select) => select.userReducer.userData);

    const onDrop = useCallback((acceptedFiles) => {
      if(acceptedFiles?.length > 1){
        createNotification(
          "warning",
          "Only one file is acceptable"
        );
        return;
      }
        isUploadToAWSSuccess = true;
        setPostContent([]);
        setFileObj([]);
        
        filterObjArr = [];
        acceptedFiles?.forEach((file, index) => {
          let extensionType = file.name.split(".").pop().toLowerCase();
          if (extensionType == "mp4" )
           {
            if (file.size < 25000000)  //Video Size max is 25 MB
              
             {
              const obj = {
                extension: file.name.split(".").pop(),
                contentType: extensionType == "mp4" ? "2" : "1",
                file: file,
                FileId: ` ${Date.now()}_${file.name}`,
                fileName: file.name.split(".")[0],
                fileNameWithExt:file.name,
              };
              filterObjArr.push(obj);
            } else {
              createNotification(
                "warning",
                "Image/Video size should be less than 25 MB."
              );
            }
          } else {
            createNotification(
              "warning",
              "Only jpeg, jpg, png, mp4  webp file type is allowed"
            );
          }
        });
    
        setFileObj(filterObjArr);
        fatchPreUrlData(filterObjArr);
      }, []);
      const { getRootProps, getInputProps, isDragActive, acceptedFiles, inputRef } =
      useDropzone({ onDrop });  

      const removeAll = () => {
        setFileObj([]);
        setUrlobject({})
      };

      const handleLoader = (showBool) => {
        dispatch(dispatchLoaderData(showBool));
      };

      const fileNameFinderFun = (i)=> {
      const fileName =   filterObjArr[0]?.fileName
      return fileName;
      }

      const addContent = (fobj)=>{
        //console.log("addContent ffob======", fobj)
        //console.log("addContent ffobExtenssion======", fobj)
        let contentFile; 
       if(fobj.contentType == 1){
        contentFile = `image/${fobj?.extension}`;
       }else{
        contentFile = `video/${fobj?.extension}`;
       }
       return contentFile;
      }

      const updateAndReturnUserDAta=()=>{
    
        //console.log("userData%%%%========",userData)
        if(!userData){
            //console.log("userData%%%%========111",userData)
            Auth.currentSession()
            .then(session=>{
                var payLoad=session.idToken.payload;
                //console.log("payLoadpayLoad234=======",payLoad)
                getAuthAndData(`${IDTY_PROD_HOST_URI}${USER_DATA_GROUP_PERMISSIONS}${payLoad.email}`)
                    .then(res => {
                        //console.log("Response Came",res);
                        if (handleResponseCode(res)) {
                            //console.log("handleResponseCode Inside Came",res);
                             dispatch(updateUserDataObj(res?.data))             
                            //console.log("userData%%%%========222",res?.data)
                            userData =  res?.data
                           
                        } else {
                            //console.log("handleResponseCode Outside Came",res);
                            //update redux store
                            
                        }
                    });
            }).catch(error=>{
                //update redux store
                
            })
        } else{
           //update redux store
        }
    }

      const uploadDataOnAws = () => {
        handleLoader(true);
        //local variable isUploadSuccess = true
        try {
          if (presignedUploadUrlArr?.length == 0) isUploadToAWSSuccess = false;
          presignedUploadUrlArr?.forEach((presignedUploadUrl) => {
            //if()
            let fobj = fileObj.find(
              (e) => e.FileId == presignedUploadUrl.FileId
            );
            //console.log("fileObj=====$$$", fobj)
            let conetntType = addContent(fobj);
            const requestOptions = {
              method: "PUT",
              headers: {
                "Content-Type": conetntType,
                "Access-Control-Allow-Origin": "*",
              },
              body: fileObj.find((e) => e.FileId == presignedUploadUrl.FileId).file,
            };
            //console.log("requestOptions====@@@@", requestOptions)
            fetch(
              new Request(presignedUploadUrl.PreSignedUrl, requestOptions)
            ).then((response) => {
              ////console.log("uploadDataOnAws Flow1", response.status);
              if (response.status == 200) {
                //responsehandler(true);
                //isUploadSuccess = false;
                //setFlag(true);
              } else {
                //setFlag(false);
                // responsehandler(false);
                //If any file is failing to upload - We shall not create the Post - Please try again
                isUploadToAWSSuccess = false;
              }
            });
            handleLoader(false);
          });
    
          // if(isUploadSuccess)
          // {
          //   //postdata
          // }
          //  responsehandler(flag);
        } catch {
          isUploadToAWSSuccess = false;
        }
        handleLoader(false);
      };
      const fatchPreUrlData = (filterObjArr) => {
        presignedUploadUrlArr = [];
    
        setPostContent([]);
        handleLoader(true);
        const body = {
          userId: userData?.UserID,
          files: filterObjArr,
        };
        //debugger;
        postAuthAndData(
          `${ENGT_PROD_BASE_URI}${PREE_SIGNED_URL}`,
          body,
          history
        ).then((res) => {
          ////console.log("res========", res);
          if (handleResponseCode(res)) {
            handleLoader(false);
            res?.data.forEach((e) => presignedUploadUrlArr.push(e));
            setPostContent(
              res?.data.map((e) => ({
                fileName: fileNameFinderFun(e.FileKey),
                fileType: e.FileExtenssion.toLowerCase() == "mp4" ? 2 : 1,
                fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
              }))
            );
          }
        });

        
      };

      const onButtonClick = () => {
        
        downloadFile(urlObject?.thumbnail_file_url, (filterObjArr[0]?.fileName + '_thumbnail.' + filterObjArr[0]?.extension.toLowerCase()))
    }

    function downloadFile(url, fileName){
      //console.log("url===>",url);
      //console.log("fileName===>",fileName);
      try{
      fetch(url)
      //fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
        .then(res => res.blob())
        .then(blobdata => {
          const aElement = document.createElement('a');
          aElement.setAttribute('download', fileName);
          const href = URL.createObjectURL(blobdata);
          aElement.href = href;
          //aElement.setAttribute('target', '_blank');
          aElement.click();
         // URL.revokeObjectURL(href);
        });
      }catch{
        
      }
    };

    const onButtonClick2 = () => {
      downloadFile(urlObject?.base_file_url, (filterObjArr[0]?.fileName + '_base.' + filterObjArr[0]?.extension.toLowerCase())) 
  }


      // const downloadFile = () => {
      //  // fileDownload(contentType == 1 ? urlObject?.base_file_url : urlObject?.thumbnail_file_url, "filename")
      //  // window.location.href = contentType == 1 ? urlObject?.base_file_url : urlObject?.thumbnail_file_url
       
      // }


      const createEngagementPost = (isAWSSuccess) => {
        if (isAWSSuccess) {
          //Send Data to Server
          createPost();
        } else {
          createNotification(
            "error",
            SOMETHING_WENT_WRONG +
              "Error uploading image to cloud, please try again."
          );
        }
      };


      const createPost = () => {
        // debugger;
        handleLoader(true);
        ////console.log("datacorret=====post", datacorret);
       
          const body = {
            file_name: PostContent[0]?.fileName,
            filePath: PostContent[0]?.fileUrl
          };
    
          postAuthAndData(
            `${ENGT_PROD_BASE_URI}${TRANSFORM_FILES}`,
            body,
            history
          ).then((res) => {
            if (handleResponseCode(res)) {
                //console.log("res=========$$$", res)
                setUrlobject(res?.data)
                if(res?.data?.base_file_url){
              createNotification("success", "Post created successfully.");
              handleLoader(false);
                }else{
                  createNotification("warning", "Post not created. Click on upload again.");
                  handleLoader(false);
                }
            }
          });
        
       // handleLoader(false);
      };

      const handleResponseCode = (resp) => {
        if (!resp || resp.code === -1) {
          createNotification(
            "error",
            SOMETHING_WENT_WRONG + ", Please try again later."
          );
          return false;
        } else {
          return true;
        }
      };

      const uPloadVideo =()=>{
          if (PostContent[0]?.fileType == 2) {
        uploadDataOnAws();
        createEngagementPost(isUploadToAWSSuccess);
        //setFileObj([]);
        }
      }

      useEffect(() => {
        handleLoader(false);
        updateAndReturnUserDAta()
        
      }, []);

  return (
    <div>
        <NotificationContainer />
        <div className='stepHeader' >
        <div className="manage-journey-block">
                <div className="manage-journey ">STEP 1</div>
              </div>
              <div className="manage-journey-block">
                <div className="manage-journey ">STEP 2</div>
              </div>
              <div className="manage-journey-block">
                <div className="manage-journey ">STEP 3</div>
              </div>
          
        </div>
        <div className='stepHeader' >
        <div className="manage-journey-block">
                <div className="subheaderFont">Upload File</div>
              </div>
              <div className="manage-journey-block">
                <div className="subheaderFont">Video Preview</div>
              </div>
              <div className="manage-journey-block">
                <div className="subheaderFont">Download</div>
              </div>
          
        </div>
        <div className='stepHeader'>
            <div className='sectionDiv'>
            <div className="videoSizeText">Video size {"<"} 25mb</div>
            <div
            className="buttomAlign"
           
          >
            <div {...getRootProps()} className="uplodeDiv5">
              <input {...getInputProps()} />
              <CloudUploadIcon
                className="uplodeimg"
                style={{ fontSize: "5rem" }}
              />
              {fileObj?.length > 0 && (
                <p>{fileObj?.length} file is selected for upload</p>
              )}

              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Drag 'n' drop some files here, or click to select files</p>
              )}
            </div>
            <div className='buttonAlign'>
            <Button
              style={{
                backgroundColor: "var(--buttonBg)",
                color: "white",
                margin: "20px 0px",
                padding: "5px 20px",
              }}
              onClick={() => removeAll()}
            >
              Reset
            </Button>
            <button
              style={{
                backgroundColor: "var(--buttonBg)",
                color: "white",
                margin: "20px 0px",
                padding: "5px 20px",
              }}
              onClick={() => uPloadVideo()}
            >
              Transform
            </button>
            </div>
          </div>
            </div>
            <div className='sectionDiv'>
     <FormControl>
      <RadioGroup
        style={{ backgroundColor: "white" }}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={(e) =>setContentType(e.target.value === "Video" ?1 :2)}
      >
        <FormControlLabel
          value="Video"
          control={<Radio checked={contentType == 1} />}
          label="Video"
        />
        <FormControlLabel
          value="Thumbnail"
          control={<Radio checked={contentType == 2} />}
          label="Thumbnail"
        />
      </RadioGroup>
    </FormControl>
    
    <ReactPlayer
          width={"100%"}
          height={"100%"}
          className="video3"
          controls={false}
          playing={true}
          volume={0}
          url={contentType == 1 ? urlObject?.base_file_url : urlObject?.thumbnail_file_url}
          //light={pImg}
          loop={true}
         
        />
        
            </div>
            <div className='sectionDiv'>
              <div className='thumbnailBurron'>
              <div className="manage-journey-block">
                <div className="subheaderFont">Thumbnail</div>
              </div>
              <Button
              disabled = {urlObject?.thumbnail_file_url ? false : true}
              style={{
                backgroundColor: "var(--buttonBg)",
                color: "white",
                margin: "20px 0px",
                padding: "5px 40px",
              }}
              onClick={() => onButtonClick()}
            >
              Download
            </Button>
              </div>
              <div className='thumbnailBurron'>
              <div className="manage-journey-block">
                <div className="subheaderFont">Video</div>
              </div>
              <Button
              disabled = {urlObject?.base_file_url ? false : true}
              style={{
                backgroundColor: "var(--buttonBg)",
                color: "white",
                margin: "20px 0px",
                padding: "5px 40px",
              }}
              onClick={() => onButtonClick2()}
            >
              Download
            </Button>
              </div>
            </div>
        </div>
        
    </div>
  )
}

export default TransformVideoComponent