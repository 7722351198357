import { ResponsiveLine } from "@nivo/line";
import { ResponsiveFunnel } from "@nivo/funnel";
import React, { useState, useEffect } from "react";
// import { ResponsiveLine } from "@nivo/line";
// import { ResponsiveFunnel } from "@nivo/funnel";
// import React, { useState, useEffect } from "react";
import {
  ResponsiveFunnelData,
  ResponsiveLineChartData,
} from "../../constants/globalMockdata";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  GET_STORY_DASHBOARD,
  ENGT_PROD_BASE_URI,
  SOMETHING_WENT_WRONG,
  GET_ALL_PLAYLIST,
  GET_SOCIAL_DASHBOARD,
} from "../../api/apiConstants";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";
import { getAuthAndData, postAuthAndData } from "../../api/ApiHelper";
import createNotification from "../common/reactNotification";
import { NotificationContainer } from "react-notifications";
import moment from "moment";
// import { MenuItem, Select } from "@material-ui/core";
import Select from "react-select";
let storydata = {};

function StoryDashboard() {
  const [numberOfViewers, setNumberOfViewers] = useState({});
  const [numberOfActiveViews, setNumberOfActiveViews] = useState({});
  const [userInteractions, setUserInteractions] = useState({});
  const [lineChart, setLineChart] = useState(ResponsiveLineChartData);
  const [funnelchart, setfunnelchart] = useState(ResponsiveFunnelData);
  const [playlistCollection, setPlaylistCollection] = useState([
    {
      value: -1,
      label: "All Playlist",
      playlistCode: "",
    },
  ]);
  const [selectedPlaylist, setSelectedPlaylist] = useState({
    value: -1,
    label: "All Playlist",
    playlistCode: "",
  });
  const [days, setdays] = useState(7);
  // const [storydata, setstorydata] = useState({});
  const [showtext, setshowtext] = useState(false);
  const [showfunneltext, setshowfunneltext] = useState(false);
  const [Play_list, setPlay_list] = useState("");
  const GetPlayList = (event) => {
    setPlay_list(event.target.value);
  };

  const History = useHistory();
  const Dispatch = useDispatch();

  const handleResponseCode = (resp) => {
    if (!resp || resp.code === -1) {
      createNotification("error", SOMETHING_WENT_WRONG + "in Target Audience");
      return false;
    } else {
      return true;
    }
  };

  const fetchStroyDashboard = async () => {
    handleLoader(true);
    const body = {
      Playlist_id: selectedPlaylist?.value,
      LastXDays: days,
    };
    try {
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_STORY_DASHBOARD}`,
        body,
        History
      );
      storydata = data?.data;
      console.log("storydata====", data);
      if (
        data.data.Sales_OverViews[0].TotalViews !== 0 ||
        data.data.Sales_OverViews[0].TotalSales !== 0
      ) {
        customLinestorydata();
        setshowtext(false);
      } else {
        setshowtext(true);
      }
      if (
        data.data.Views_Summery?.TotalViews !== 0 ||
        data.data.Views_Summery?.TotalActiveViews !== 0 ||
        data.data.Views_Summery?.TotalCTAs !== 0
      ) {
        customFunnelstorydata();
        setshowfunneltext(false);
      } else {
        setshowfunneltext(true);
      }
    } catch (err) {}
    handleLoader(false);
  };
  const customLinestorydata = () => {
    const ResponsiveLineChartData = [
      {
        id: "Total Views",
        color: "hsl(32, 70%, 50%)",
        data: storydata?.Sales_OverViews?.map((item) => {
          return {
            x: moment(item.ReportingDate).format("MMM Do YY"),
            y: item.TotalViews,
          };
        }),
      },
      {
        id: "Total Sales",
        color: "hsl(350, 70%, 50%)",
        data: storydata?.Sales_OverViews?.map((item) => {
          return {
            x: moment(item.ReportingDate).format("MMM Do YY"),
            y: item.TotalSales,
          };
        }),
      },
    ];
    setLineChart(ResponsiveLineChartData);
  };

  const customFunnelstorydata = () => {
    const ResponsiveFunnelData = [
      {
        id: "TotalViews",
        value: storydata?.Views_Summery?.TotalViews,
        label: "Total Views",
      },
      {
        id: "TotalActiveViews",
        value: storydata?.Views_Summery?.TotalActiveViews,
        label: "Total Active Views",
      },
      {
        id: "TotalCTAs",
        value: storydata?.Views_Summery?.TotalCTAs,
        label: "Total CTAs",
      },
    ];
    setfunnelchart(ResponsiveFunnelData);
  };

  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
  };

  const getAllPlayList = async () => {
    handleLoader(true);
    const playlistArr = [];
    try {
      const body = {
        Content_Type: 1,
      };
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_ALL_PLAYLIST}`,
        body,
        History
      );
      // debugger;
      console.log("getAllPlayList========", data?.data);
      data?.data?.forEach((player) => {
        let obj = {
          value: player?.PlayListId,
          label: player?.Name,
          playlistCode: player?.PlayListCode,
        };
        playlistArr.push(obj);
      });
      console.log(playlistArr);
      setPlaylistCollection([playlistCollection[0], ...playlistArr]);
      setSelectedPlaylist(playlistCollection[0]);
      //onPlaylistChange(playlistArr[0])
    } catch (err) {}
    handleLoader(false);
  };

  // const fetchSocialDashboardData = async (playListId) => {
  //   handleLoader(true);
  //   var body = {
  //     Playlist_id: playListId,
  //   };
  //   try {
  //     const data = await postAuthAndData(
  //       `${ENGT_PROD_BASE_URI}${GET_SOCIAL_DASHBOARD}`,
  //       body,
  //       History
  //     );
  //     setSocialDashboard(data.data);
  //   } catch (err) {}
  //   handleLoader(false);
  // };

  const onPlaylistChange = (target) => {
    console.log("target=====", target);
    setSelectedPlaylist(target);
  };

  useEffect(() => {
    getAllPlayList();
    // fetchSocialDashboardData(-1);
  }, []);
  useEffect(() => {
    fetchStroyDashboard();
  }, [selectedPlaylist, days]);

  const MyResponsiveLine = () => (
    <div style={{ height: "400px" }}>
      <ResponsiveLine
        data={lineChart}
        width={800}
        height={400}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Date",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        colors={{ scheme: "dark2" }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        enableSlices="x"
        sliceTooltip={({ slice }) => {
          return (
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
              }}
            >
              {/* <div>x: {slice.id}</div> */}
              {slice.points.map((point) => (
                <div
                  key={point.id}
                  style={{
                    color: point.serieColor,
                    padding: "3px 0",
                  }}
                >
                  <strong>{point.serieId}</strong>: {point.data.yFormatted}
                </div>
              ))}
            </div>
          );
        }}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );

  const MyResponsiveFunnel = () => (
    <div style={{ height: "400px" }}>
      <ResponsiveFunnel
        data={funnelchart}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        valueFormat=">-.4s"
        colors={{ scheme: "spectral" }}
        borderWidth={20}
        labelColor={{
          from: "color",
          modifiers: [["darker", 3]],
        }}
        beforeSeparatorLength={100}
        beforeSeparatorOffset={20}
        afterSeparatorLength={100}
        afterSeparatorOffset={20}
        currentPartSizeExtension={10}
        currentBorderWidth={40}
        motionConfig="wobbly"
      />
    </div>
  );

  return (
    <div>
      <div id="liveview-container">
        <div className="subHeaderMv12">
          <div className="w-50 float-left clearfix mb-2">
            <div className="overview-heading float-left clearfix mt-2">
              Summary for the{" "}
              <a
                href="#"
                style={{ color: days == 7 ? "" : "#54b699" }}
                onClick={() => setdays(7)}
              >
                {" "}
                Last 7 Days
              </a>{" "}
              |{" "}
              <a
                href="#"
                style={{ color: days == 15 ? "" : "#54b699" }}
                onClick={() => setdays(15)}
              >
                Last 15 Days
              </a>{" "}
              |{" "}
              <a
                href="#"
                style={{ color: days == 30 ? "" : "#54b699" }}
                onClick={() => setdays(30)}
              >
                Last 30 Days
              </a>
            </div>
          </div>

          <div className="w-30" style={{ display: "flex" }}>
            <div className="w-33">
              <div className="overview-heading2 float-left clearfix mt-2">
                Filter By playlist
              </div>
            </div>
            <div className="w-67">
              <Select
                options={playlistCollection}
                // isDisabled={obj.rewardType?.value!==2}
                name="rewardName"
                onChange={(e) => onPlaylistChange(e)}
                value={selectedPlaylist}
                //defaultValue= {selectedPlaylist}
                style={{ lineHeight: "28px" }}
                maxMenuHeight={150}
              />
            </div>
          </div>
        </div>
        <div
          className="w-100 float-left clearfix"
          style={{ margin: "20px 0 0" }}
        >
          <div className="w-50 float-left clearfix mb-2">
            <div className="overview-heading float-left clearfix mt-2">
              Recent Activities
            </div>
          </div>
        </div>

        <div className="w-100 float-left clearfix l-v-s-o">
          <div className="w-25 float-left clearfix l-v-s-o-box-outer">
            <div
              className="l-v-s-o-box"
              style={{
                boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                background: "white",
                height: "200px",
              }}
            >
              <div className="l-v-s-o-box-t pt-3">
                <div
                  className="l-v-s-o-box-t-h"
                  style={{ fontSize: "14px", textAlign: "center" }}
                >
                  Total Views
                </div>
                <div style={{ textAlign: "center" }}>
                  <span
                    className="l-v-s-o-box-t-c"
                    style={{ lineHeight: "70px" }}
                  >
                    {storydata?.Views_Summery?.TotalViews === 0
                      ? 0
                      : storydata?.Views_Summery?.TotalViews}
                  </span>
                  <span className="l-v-s-o-box-t-c-lbl  ml-2">Views</span>
                </div>
                <div>
                  <div
                    className="d-flex"
                    style={{ textAlign: "center", paddingLeft: "100px" }}
                  >
                    <div
                      className="l-v-s-o-box-t-c-per"
                      style={{ lineHeight: "22px", color: "#5f6165" }}
                    >
                      {storydata?.Views_Summery?.PCTotalViews}%
                    </div>
                    <div className="l-v-s-o-box-t-c-per-lbl">
                      {parseInt(storydata?.Views_Summery?.PCTotalViews) > 0 ? (
                        <ArrowDropUpIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowDropDownIcon style={{ color: "red" }} />
                      )}
                    </div>
                  </div>
                </div> 
              </div>
              <div className="l-v-s-o-box-b">
                <hr />
                <p
                  style={{
                    fontSize: "10px",
                    color: "#5f6165",
                    padding: "0px 7px",
                  }}
                >
                  Total number of Customers who viewed Stories.
                </p>
              </div>
            </div>
          </div>
          <div className="w-25 float-left clearfix l-v-s-o-box-outer">
            <div
              className="l-v-s-o-box"
              style={{
                height: "200px",
                boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                background: "white",
              }}
            >
              <div className="l-v-s-o-box-t  pt-3">
                <div
                  className="l-v-s-o-box-t-h"
                  style={{ fontSize: "14px", textAlign: "center" }}
                >
                  Active Views
                </div>
                <div style={{ textAlign: "center" }}>
                  <span
                    className="l-v-s-o-box-t-c"
                    style={{ lineHeight: "70px" }}
                  >
                    {storydata?.Views_Summery?.TotalActiveViews === 0
                      ? 0
                      : storydata?.Views_Summery?.TotalActiveViews}
                  </span>
                  <span className="l-v-s-o-box-t-c-lbl  ml-2">Views</span>
                </div>
                <div
                  className="d-flex"
                  style={{ textAlign: "center", paddingLeft: "100px" }}
                >
                  <div
                    className="l-v-s-o-box-t-c-per"
                    style={{ lineHeight: "22px", color: "#5f6165" }}
                  >
                    {storydata?.Views_Summery?.PCTotalActiveViews}%
                  </div>
                  <div className="l-v-s-o-box-t-c-per-lbl">
                    {parseInt(storydata?.Views_Summery?.PCTotalActiveViews) > 0 ? (
                      <ArrowDropUpIcon style={{ color: "green" }} />
                    ) : (
                      <ArrowDropDownIcon style={{ color: "red" }} />
                    )}
                  </div>
                </div>
              </div>
              <div className="l-v-s-o-box-b">
                <hr />
                <p
                  style={{
                    fontSize: "10px",
                    color: "#5f6165",
                    padding: "0px 7px",
                  }}
                >
                  Active engagement from your viewers where they at least 1
                  card.
                </p>
              </div>
            </div>
          </div>
          <div className="w-25 float-left clearfix l-v-s-o-box-outer">
            <div
              className="l-v-s-o-box"
              style={{
                height: "200px",
                boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                background: "white",
              }}
            >
              <div className="l-v-s-o-box-t pt-3">
                <div
                  className="l-v-s-o-box-t-h"
                  style={{ fontSize: "14px", textAlign: "center" }}
                >
                  User Interaction
                </div>
                <div style={{ textAlign: "center" }}>
                  <span
                    className="l-v-s-o-box-t-c"
                    style={{ lineHeight: "70px" }}
                  >
                    {storydata?.Views_Summery?.TotalCTAsFormatted ? storydata?.Views_Summery?.TotalCTAsFormatted : 0}
                  </span>
                  <span className="l-v-s-o-box-t-c-lbl  ml-2">Clicks</span>
                </div>
                <div
                  className="d-flex"
                  style={{ textAlign: "center", paddingLeft: "100px" }}
                >
                  <div
                    className="l-v-s-o-box-t-c-per"
                    style={{ lineHeight: "22px", color: "#5f6165" }}
                  >
                    {storydata?.Views_Summery?.PCTotalCTAs}%
                  </div>
                  <div className="l-v-s-o-box-t-c-per-lbl">
                    {parseInt(storydata?.Views_Summery?.PCTotalCTAs) > 0 ? (
                      <ArrowDropUpIcon style={{ color: "green" }} />
                    ) : (
                      <ArrowDropDownIcon style={{ color: "red" }} />
                    )}
                  </div>
                </div>
              </div>
              <div className="l-v-s-o-box-b">
                <hr />
                <p
                  style={{
                    fontSize: "10px",
                    color: "#5f6165",
                    padding: "0px 7px",
                  }}
                >
                  Product Clicks Customers who clicked on Product Link.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 float-left clearfix">
          <div className="w-50 float-left clearfix mb-2">
            <div className="overview-heading float-left clearfix mt-2">
              Sales Vs Story Engagement
            </div>
          </div>
          {/* <DateFilter onFilterClick={(days)=>getSalesOnDateFilterClick(days)} selected={salesRevenueFilter}/> */}
        </div>
        <div>
          <div
            className="w-100 float-left clearfix mb-4 chart-container"
            style={{
              border: "none",
              background: "white",
            }}
          >
            {showtext ? (
              <>
                <p
                  style={{
                    textAlign: "center",
                    margin: "10px",
                    color: "#4f4f4f",
                    fontSize: "13px",
                  }}
                >
                  NO DATA
                </p>
              </>
            ) : (
              <MyResponsiveLine />
            )}
          </div>
        </div>
        <div className="w-100 float-left clearfix">
          <div className="w-50 float-left clearfix mb-2">
            <div className="overview-heading float-left clearfix mt-2">
              User Journey
            </div>
          </div>
          {/* <DateFilter onFilterClick={(days)=>getSalesOnDateFilterClick(days)} selected={salesRevenueFilter}/> */}
        </div>
        <div>
          <div
            className="w-100 float-left clearfix mb-4 chart-container"
            style={{
              border: "none",
              background: "white",
            }}
          >
            {showfunneltext ? (
              <>
                <p
                  style={{
                    textAlign: "center",
                    margin: "10px",
                    color: "#4f4f4f",
                    fontSize: "13px",
                  }}
                >
                  NO DATA
                </p>
              </>
            ) : (
              <MyResponsiveFunnel />
            )}
          </div>
        </div>
        {/* <div>
          <div
            className="overview-heading float-left clearfix mt-2 "
            style={{ margin: "30px 0 10px" }}
          >
            Top 5 Stories
          </div>
          <div
            style={{ display: "flex", gap: "20px" }}
            className="float-left clearfix"
          >
            {storydata?.Top_Stories?.map((TopStory) => (
              <div>
                <img
                  src={TopStory.Url}
                  alt=""
                  width={200}
                  style={{ borderRadius: "15px" }}
                  height={156}
                />
                <div
                  style={{
                    background: "#676DFF",
                    borderRadius: "10px",
                    padding: "9px",
                    width: "90%",
                    margin: "0px auto 0",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    fontSize: "12px",
                    color: "#fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>View Count</div>
                    <div>{TopStory.TotalCTAsFormatted}</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>Number of clicks</div>
                    <div>{TopStory.TotalViewsFormatted} </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default StoryDashboard;
