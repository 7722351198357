import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import LineChart from "../common/utils/lineChart";
import BarChart from "../common/utils/barChart";
import DoughnutChart from "../common/utils/doughnutChart";
import CampaignBox from "../common/campaignBox/campaignBox";
import LineCanvasGraph from "../common/graphs/lineCanvasGraph";
import { getAuthAndData, postAuthAndData } from "../../api/ApiHelper";
import createNotification from "../common/reactNotification";
import { NotificationContainer } from "react-notifications";
import { ResponsiveStream } from "@nivo/stream";

import {
  Checkbox,
  Input,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  lineChartData,
  barChartData,
  LiveViewCampaignMockData,
  doughnutChartData,
  smallBarChartData,
  lineChartSingleBlueData,
  lineChartSingleGreenData,
  lineChartSinglePurpleData,
  lineChartSingleOrangeData,
  slimBarChartData,
} from "../../constants/globalMockdata";
import h_dots_src from "../../assets/img/dots-icon_horizontal.svg";
import calender_src from "../../assets/img/calender.svg";
import down_arrow_src from "../../assets/img/down_arrow.svg";
import "./liveView.css";
import { useEffect } from "react";
import {
  REPT_PROD_HOST_URI,
  ENGAGEMENTS_BY_FILTERS,
  EVENT_LIST_FOR_LAST_X_HOURS,
  ACTIVE_CUSTOMER_COUNT_FOR_LAST_X_HOURS,
  SOMETHING_WENT_WRONG,
  TOP_ENGAGEMENTS_CUSTOMER_COUNT,
  CART_GROSS_VALUE,
  STAT_CHANGE,
  VIDEO_DURATION_GROUP_BY_DAY,
  ENGT_PROD_BASE_URI,
  GET_SOCIAL_DASHBOARD,
} from "../../api/apiConstants";
import { getData } from "../../api/ApiHelper";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function LiveView(props) {
  //console.log("LiveView***", props);
  let history = useHistory();
  const classes = useStyles();
  const [lvFilterVal, setLVFilterVal] = useState("Current Week");
  const [campaigndata, setCampaignData] = useState(LiveViewCampaignMockData);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCategoriesName, setSelectedCategoriesName] = useState([]);
  const [topFiveEng, setTopFiveEng] = useState([]);
  const [activePlayersTotalCount, setActivePlayersTotalCount] = useState({});
  const [gamePlayTotalCount, setGamePlayTotalCount] = useState({});
  const [purchasesTotalCount, setPurchasesTotalCount] = useState({});
  const [WinnersTotalCount, setWinnersTotalCount] = useState({});
  const [socialDashboard, setSocialDashboard] = useState({});
  const [lvfEl, setlvfEl] = useState(null);
  const [filterEvent, setFilterEvent] = useState(
    props?.eventListCountForLastXHoure
  );
  const [lastHourCount, setLastHourCount] = useState(0);
  const [eventsList, setEventList] = useState([]);
  //const LastHourCount = props?.activeCustomerCounts[0]?.data?.slice(-1)[0]?.y;
  //const [activeCustomerCount, setActiveCustomerCount] = useState([]);
  const lvfOpen = Boolean(lvfEl);
  const lvfId = lvfOpen ? "lvf-filter" : undefined;
  const events = [
    "ProductReview",
    "Search",
    "CreateOrder",
    "ProductView",
    "AddToWishList",
    "AddToCart",
    "SocialShare",
    "SignUp",
  ];
  const handleClose = () => {
    setlvfEl(null);
  };
  const lvFilterOpenClick = (event) => {
    setlvfEl(event.currentTarget);
  };
  function setLVFilterClick(val) {
    setLVFilterVal(val);
    setlvfEl(null);
  }

  const handleLoader = (showBool) => {
    props.routeActionHandler.dispatchLoaderData(showBool);
  };

  const handleResponseCode = (resp) => {
    if (!resp || resp.code === -1) {
      createNotification("error", SOMETHING_WENT_WRONG + " in live view");
      return false;
    } else {
      return true;
    }
  };

  const formateDataForCustomerCount = (data) => {
    var lineCanvasData = [];
    var customerCountObj = {
      id: "Active customer count",
      color: "hsl(147, 100%, 30%)",
      data: [],
    };
    lineCanvasData.push(customerCountObj);
    Array.isArray(data) &&
      data?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        lineCanvasData[0].data.push(obj);
      });
    // //console.log("lineCanvasData===", lineCanvasData);
    //setActiveCustomerCount(lineCanvasData);
    props.liveViewActionHandler.dispatchActiveCustomerCount(lineCanvasData);
    lastHourCountCheck();
  };

  const formateDataForVideoWatched = (data) => {
    var lineCanvasData = [];
    var videoWatched = {
      id: "Video Duration In Sec",
      color: "hsl(177, 100%, 30%)",
      data: [],
    };
    lineCanvasData.push(videoWatched);
    Array.isArray(data) &&
      data?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityDay)?.toLocaleDateString(),
          y: p.Formatedcount,
        };
        lineCanvasData[0].data.push(obj);
      });
    // //console.log("lineCanvasData===", lineCanvasData);
    //setActiveCustomerCount(lineCanvasData);
    props.liveViewActionHandler.dispatchVideoWatched(lineCanvasData);
    lastHourCountCheck();
  };

  const lastHourCountCheck = () => {
    if (props?.activeCustomerCounts?.length > 0) {
      const count = props?.activeCustomerCounts[0]?.data?.slice(-1)[0]?.y;
      setLastHourCount(count);
    }
  };

  const formateDataForGrossValue = (data) => {
    var lineCanvasData = [];
    var customerCountObj = {
      id: "Active customer",
      color: "hsl(147, 100%, 30%)",
      data: [],
    };
    lineCanvasData.push(customerCountObj);
    Array.isArray(data) &&
      data?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.NewCustomerCountString,
        };
        lineCanvasData[0].data.push(obj);
      });

    var customerCartObj = {
      id: "Cart Threshold",
      color: "hsl(249, 100%, 30%)",
      data: [],
    };
    lineCanvasData.push(customerCartObj);
    Array.isArray(data) &&
      data?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.CustomerCountAboveCartGrossTotalString,
        };
        lineCanvasData[1].data.push(obj);
      });
    //console.log("lineCanvasData===%%%%", lineCanvasData);
    //setActiveCustomerCount(lineCanvasData);
    props.liveViewActionHandler.dispatchGrossValueData(lineCanvasData);
  };

  const activeCustomerCountForLastXHours = () => {
    handleLoader(true);
    getAuthAndData(
      `${REPT_PROD_HOST_URI}${ACTIVE_CUSTOMER_COUNT_FOR_LAST_X_HOURS}`,
      history
    ).then((res) => {
      if (handleResponseCode(res)) {
        // //console.log("activeCustomerCountForLastXHours===", res);
        formateDataForCustomerCount(res?.data);
      } else {
      }
      handleLoader(false);
    });
  };

  const videoWatchedInLastXDay = () => {
    handleLoader(true);
    getAuthAndData(
      `${REPT_PROD_HOST_URI}${VIDEO_DURATION_GROUP_BY_DAY}`,
      history
    ).then((res) => {
      if (handleResponseCode(res)) {
        // //console.log("activeCustomerCountForLastXHours===", res);
        formateDataForVideoWatched(res?.data);
      } else {
      }
      handleLoader(false);
    });
  };

  const CustomerCountForAverageCartGross = () => {
    handleLoader(true);
    getAuthAndData(`${REPT_PROD_HOST_URI}${CART_GROSS_VALUE}`, history).then(
      (res) => {
        if (handleResponseCode(res)) {
          //console.log("CustomerCountForAverageCartGross===", res);
          //formateDataForCustomerCount(res?.data);
          formateDataForGrossValue(res?.data);
        } else {
        }
        handleLoader(false);
      }
    );
  };

  const formateDataForCustomerEventActivity = (data) => {
    console.log("data====$$$$$", data);
    const list = [];
    data?.forEach((element) => {
      console.log("outer loop====");

      if (list?.length) {
        const p = list?.find((e) => e.value == element.EventName);
        if (!p) {
          const obj = {
            name: element?.Event_Description,
            value: element?.EventName,
          };
          list.push(obj);
        }
      } else {
        const obj = {
          name: element?.Event_Description,
          value: element?.EventName,
        };
        list.push(obj);
      }
    });
    setEventList(list);
    const ProductReview = data?.filter((e) => e.EventName == "ProductReview");
    const Search = data?.filter((e) => e.EventName == "Search");
    const CreateOrder = data?.filter((e) => e.EventName == "CreateOrder");
    const ProductView = data?.filter((e) => e.EventName == "ProductView");
    const AddToWishList = data?.filter((e) => e.EventName == "AddToWishList");
    const AddToCart = data?.filter((e) => e.EventName == "AddToCart");
    const SocialShare = data?.filter((e) => e.EventName == "SocialShare");
    const SignUp = data?.filter((e) => e.EventName == "SignUp");
    const PostCTAClicked = data?.filter((e) => e.EventName == "PostCTAClicked");
    const StoryView = data?.filter((e) => e.EventName == "StoryView");
    const StoryInteraction = data?.filter(
      (e) => e.EventName == "StoryInteraction"
    );
    const LiveLaunched = data?.filter((e) => e.EventName == "LiveLaunched");
    const LaunchedBlaashPannel = data?.filter(
      (e) => e.EventName == "LaunchedBlaashPannel"
    );
    const LikeORShare = data?.filter((e) => e.EventName == "LikeORShare");
    const VideoView = data?.filter((e) => e.EventName == "VideoView");
    const CustomerReffered = data?.filter(
      (e) => e.EventName == "CustomerReffered"
    );
    const UserSession = data?.filter((e) => e.EventName == "UserSession");
    const RemovedFromCart = data?.filter(
      (e) => e.EventName == "RemovedFromCart"
    );
    console.log("PostCTAClicked=====$$", PostCTAClicked);
    var lineCanvasData = [];

    var RemovedFromCartObj = {
      id: "RemovedFromCart",
      color: "hsl(147, 100%, 30%)",
      data: [],
    };

    Array.isArray(RemovedFromCart) &&
      RemovedFromCart?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        RemovedFromCartObj.data.push(obj);
      });
    if (RemovedFromCartObj.data?.length > 0)
      lineCanvasData.push(RemovedFromCartObj);

    var UserSessionObj = {
      id: "UserSession",
      color: "hsl(147, 100%, 30%)",
      data: [],
    };

    Array.isArray(UserSession) &&
      UserSession?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        UserSessionObj.data.push(obj);
      });
    if (UserSessionObj.data?.length > 0) lineCanvasData.push(UserSessionObj);

    var CustomerRefferedObj = {
      id: "CustomerReffered",
      color: "hsl(147, 100%, 30%)",
      data: [],
    };

    Array.isArray(CustomerReffered) &&
      CustomerReffered?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        CustomerRefferedObj.data.push(obj);
      });
    if (CustomerRefferedObj.data?.length > 0)
      lineCanvasData.push(CustomerRefferedObj);

    var VideoViewObj = {
      id: "VideoView",
      color: "hsl(147, 100%, 30%)",
      data: [],
    };

    Array.isArray(VideoView) &&
      VideoView?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        VideoViewObj.data.push(obj);
      });
    if (VideoViewObj.data?.length > 0) lineCanvasData.push(VideoViewObj);

    var LikeORShareObj = {
      id: "LikeORShare",
      color: "hsl(147, 100%, 30%)",
      data: [],
    };

    Array.isArray(LikeORShare) &&
      LikeORShare?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        LikeORShareObj.data.push(obj);
      });
    if (LikeORShareObj.data?.length > 0) lineCanvasData.push(LikeORShareObj);

    var LaunchedBlaashPannelObj = {
      id: "LaunchedBlaashPannel",
      color: "hsl(147, 100%, 30%)",
      data: [],
    };

    Array.isArray(LaunchedBlaashPannel) &&
      LaunchedBlaashPannel?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        LaunchedBlaashPannelObj.data.push(obj);
      });
    if (LaunchedBlaashPannelObj.data?.length > 0)
      lineCanvasData.push(LaunchedBlaashPannelObj);

    var LiveLaunchedObj = {
      id: "LiveLaunched",
      color: "hsl(147, 100%, 30%)",
      data: [],
    };

    Array.isArray(LiveLaunched) &&
      LiveLaunched?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        LiveLaunchedObj.data.push(obj);
      });
    if (LiveLaunchedObj.data?.length > 0) lineCanvasData.push(LiveLaunchedObj);

    var StoryInteractionObj = {
      id: "StoryInteraction",
      color: "hsl(147, 100%, 30%)",
      data: [],
    };

    Array.isArray(StoryInteraction) &&
      StoryInteraction?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        StoryInteractionObj.data.push(obj);
      });
    if (StoryInteractionObj.data?.length > 0)
      lineCanvasData.push(StoryInteractionObj);

    var StoryViewObj = {
      id: "StoryView",
      color: "hsl(147, 100%, 30%)",
      data: [],
    };

    Array.isArray(StoryView) &&
      StoryView?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        StoryViewObj.data.push(obj);
      });
    if (StoryViewObj.data?.length > 0) lineCanvasData.push(StoryViewObj);

    var PostCTAClickedObj = {
      id: "PostCTAClicked",
      color: "hsl(147, 100%, 30%)",
      data: [],
    };

    Array.isArray(PostCTAClicked) &&
      PostCTAClicked?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        PostCTAClickedObj.data.push(obj);
      });
    if (PostCTAClickedObj.data?.length > 0)
      lineCanvasData.push(PostCTAClickedObj);

    var ProductReviewObj = {
      id: "ProductReview",
      color: "hsl(147, 100%, 30%)",
      data: [],
    };

    Array.isArray(ProductReview) &&
      ProductReview?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        ProductReviewObj.data.push(obj);
      });
    if (ProductReviewObj.data?.length > 0)
      lineCanvasData.push(ProductReviewObj);
    var SearchObj = {
      id: "Search",
      color: "hsl(157, 100%, 30%)",
      data: [],
    };

    Array.isArray(Search) &&
      Search?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        SearchObj.data.push(obj);
      });
    if (SearchObj.data?.length > 0) lineCanvasData.push(SearchObj);

    var CreateOrderObj = {
      id: "CreateOrder",
      color: "hsl(167, 100%, 30%)",
      data: [],
    };

    Array.isArray(CreateOrder) &&
      CreateOrder?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        CreateOrderObj.data.push(obj);
      });
    if (CreateOrderObj.data?.length > 0) lineCanvasData.push(CreateOrderObj);

    var ProductViewObj = {
      id: "ProductView",
      color: "hsl(177, 100%, 30%)",
      data: [],
    };

    Array.isArray(ProductView) &&
      ProductView?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        ProductViewObj.data.push(obj);
      });
    if (ProductViewObj.data?.length > 0) lineCanvasData.push(ProductViewObj);

    var AddToWishListObj = {
      id: "AddToWishList",
      color: "hsl(187, 100%, 30%)",
      data: [],
    };

    Array.isArray(AddToWishList) &&
      AddToWishList?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        AddToWishListObj.data.push(obj);
      });
    if (AddToWishListObj.data?.length > 0)
      lineCanvasData.push(AddToWishListObj);

    var AddToCartObj = {
      id: "AddToCart",
      color: "hsl(197, 100%, 30%)",
      data: [],
    };

    Array.isArray(AddToCart) &&
      AddToCart?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        AddToCartObj.data.push(obj);
      });
    if (AddToCartObj.data?.length > 0) lineCanvasData.push(AddToCartObj);

    var SocialShareObj = {
      id: "SocialShare",
      color: "hsl(247, 100%, 30%)",
      data: [],
    };

    Array.isArray(SocialShare) &&
      SocialShare?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        SocialShareObj.data.push(obj);
      });
    if (SocialShareObj.data?.length > 0) lineCanvasData.push(SocialShareObj);

    var SignUpObj = {
      id: "SignUp",
      color: "hsl(347, 100%, 30%)",
      data: [],
    };

    Array.isArray(SignUp) &&
      SignUp?.forEach((p) => {
        let obj = {
          x: new Date(p?.ActivityHour)?.toLocaleTimeString(),
          y: p.Count,
        };
        SignUpObj.data.push(obj);
      });
    if (SignUpObj.data?.length > 0) lineCanvasData.push(SignUpObj);
    console.log("lineCanvasData======123", lineCanvasData);
    props.liveViewActionHandler.dispatchEventListForLastXHoures(lineCanvasData);
  };

  const eventListForLastXHours = () => {
    handleLoader(true);
    getAuthAndData(
      `${REPT_PROD_HOST_URI}${EVENT_LIST_FOR_LAST_X_HOURS}`,
      history
    ).then((res) => {
      if (handleResponseCode(res)) {
        console.log("eventListForLastXHours===", res);
        formateDataForCustomerEventActivity(res?.data);
      } else {
      }
      handleLoader(false);
    });
  };

  const topFiveEngagement = () => {
    handleLoader(true);
    getAuthAndData(
      `${REPT_PROD_HOST_URI}${TOP_ENGAGEMENTS_CUSTOMER_COUNT}`,
      history
    ).then((res) => {
      if (handleResponseCode(res)) {
        //console.log("topFiveEngagement===", res);
        setTopFiveEng(res?.data);
      } else {
      }
      handleLoader(false);
    });
  };

  const playerActivityCountRep = () => {
    handleLoader(true);
    getAuthAndData(`${REPT_PROD_HOST_URI}${STAT_CHANGE}`, history).then(
      (res) => {
        if (handleResponseCode(res)) {
          //console.log("topFiveEngagement===", res);
          // setTopFiveEng(res?.data);
          setActivePlayersTotalCount(res?.data?.ActivePlayersTotalCount);
          setGamePlayTotalCount(res?.data.GamePlayTotalCount);
          setPurchasesTotalCount(res?.data?.PurchasesTotalCount);
          setWinnersTotalCount(res?.data?.WinnersTotalCount);
        } else {
        }
        handleLoader(false);
      }
    );
  };

  const fatchSelectedNameList = (arr) => {
    const nameList = [];
    arr.forEach((element) => {
      eventsList?.find((e) => {
        if (e.value == element) nameList.push(e.name);
      });
    });
    setSelectedCategoriesName(nameList);
  };

  const filterEvents = (value) => {
    fatchSelectedNameList(value);
    setSelectedCategories(value);
    const filterdArr = [];
    value?.forEach((e) => {
      props?.eventListCountForLastXHoure?.forEach((element) => {
        if (element.id == e) filterdArr.push(element);
      });
    });
    if (filterdArr?.length > 0) {
      // props.liveViewActionHandler.dispatchEventListForLastXHoures(filterdArr);
      setFilterEvent(filterdArr);
    } else {
      setFilterEvent(props?.eventListCountForLastXHoure);
    }
    //console.log("filterdArr====", filterdArr);
  };
  // useEffect(() => {

  //   }
  // }, [input])

  const fetchSocialDashboardData = async () => {
    handleLoader(true);
    try {
      const data = await getAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_SOCIAL_DASHBOARD}`,
        History
      );
      setSocialDashboard(data.data);
    } catch (err) {}
    handleLoader(false);
  };

  useEffect(() => {
    fetchSocialDashboardData();
    activeCustomerCountForLastXHours();
    eventListForLastXHours();
    topFiveEngagement();
    CustomerCountForAverageCartGross();
    playerActivityCountRep();
    videoWatchedInLastXDay();
    // getData(ENGAGEMENTS_BY_FILTERS)
    //     .then(engagementsData => {
    //         if (engagementsData && Array.isArray(engagementsData)) {
    //             setCampaignData(engagementsData);
    //         }
    //     })
  }, []);
  //console.log("activeCustomerCount===$$$", props.eventListCountForLastXHoure);

  //console.log("selectedCategories", selectedCategories);
  console.log(
    "props?.eventListCountForLastXHoure====",
    props?.eventListCountForLastXHoure
  );

  return (
    <Fragment>
      <NotificationContainer />
      <div id="liveview-container">
        <div
          className="w-100 float-left clearfix"
          style={{ margin: "20px 0 0" }}
        >
          <div className="w-50 float-left clearfix mb-2">
            <div className="overview-heading float-left clearfix mt-2">
              Recent Activities
            </div>
          </div>
        </div>

        <div className="w-100 float-left clearfix l-v-s-o">
          <div className="w-25 float-left clearfix l-v-s-o-box-outer">
            <div
              className="l-v-s-o-box"
              style={{
                boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                background: "white",
              }}
            >
              <div className="l-v-s-o-box-t pt-2">
                <div className="l-v-s-o-box-t-h">Active Customer</div>
                <div>
                  <span className="l-v-s-o-box-t-c">
                    {activePlayersTotalCount?.CurrentCountString}
                  </span>
                  <span className="l-v-s-o-box-t-c-lbl  ml-2">Customers</span>
                  <div className="disp-inline-b float-right pr-3">
                    {activePlayersTotalCount?.Change > 0 ? (
                      <div className="l-v-s-o-box-t-c-per">
                        {activePlayersTotalCount?.ChangeString}%
                      </div>
                    ) : (
                      <div className="l-v-s-o-box-t-c-per c-orange">
                        {activePlayersTotalCount?.ChangeString}%
                      </div>
                    )}
                    <div className="l-v-s-o-box-t-c-per-lbl">this week</div>
                  </div>
                </div>
              </div>
              <div className="l-v-s-o-box-b">
                <LineChart
                  data={lineChartSingleBlueData}
                  hideAxis={true}
                  hideHeader={true}
                ></LineChart>
              </div>
            </div>
          </div>
          <div className="w-25 float-left clearfix l-v-s-o-box-outer">
            <div
              className="l-v-s-o-box"
              style={{
                boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                background: "white",
              }}
            >
              <div className="l-v-s-o-box-t pt-2">
                <div className="l-v-s-o-box-t-h">Engage Customers</div>
                <div>
                  <span className="l-v-s-o-box-t-c">
                    {socialDashboard?.TotalVideoViews}
                  </span>
                  <span className="l-v-s-o-box-t-c-lbl  ml-2">Customers</span>
                  <div className="disp-inline-b float-right pr-3">
                    {/* {gamePlayTotalCount?.Change > 0 ? ( */}
                    <div className="l-v-s-o-box-t-c-per">
                      {socialDashboard?.PCTotalWatchedDurationInLastWeek}%
                    </div>
                    {/* ) : (
                      <div className="l-v-s-o-box-t-c-per c-orange">
                        {gamePlayTotalCount?.ChangeString}%
                      </div>
                    )} */}
                    <div className="l-v-s-o-box-t-c-per-lbl">this week</div>
                  </div>
                </div>
              </div>
              <div className="l-v-s-o-box-b">
                <LineChart
                  data={lineChartSingleGreenData}
                  hideAxis={true}
                  hideHeader={true}
                ></LineChart>
              </div>
            </div>
          </div>
          <div className="w-25 float-left clearfix l-v-s-o-box-outer">
            <div
              className="l-v-s-o-box"
              style={{
                boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                background: "white",
              }}
            >
              <div className="l-v-s-o-box-t pt-2">
                <div className="l-v-s-o-box-t-h">Purchases Customers</div>
                <div>
                  <span className="l-v-s-o-box-t-c">
                    {purchasesTotalCount?.CurrentCountString}
                  </span>
                  <span className="l-v-s-o-box-t-c-lbl ml-2">Customers</span>
                  <div className="disp-inline-b float-right pr-3">
                    {purchasesTotalCount?.Change > 0 ? (
                      <div className="l-v-s-o-box-t-c-per">
                        {purchasesTotalCount?.ChangeString}%
                      </div>
                    ) : (
                      <div className="l-v-s-o-box-t-c-per c-orange">
                        {purchasesTotalCount?.ChangeString}%
                      </div>
                    )}
                    <div className="l-v-s-o-box-t-c-per-lbl">this week</div>
                  </div>
                </div>
              </div>
              <div className="l-v-s-o-box-b">
                <LineChart
                  data={lineChartSinglePurpleData}
                  hideAxis={true}
                  hideHeader={true}
                ></LineChart>
              </div>
            </div>
          </div>
          <div className="w-25 float-left clearfix l-v-s-o-box-outer">
            <div
              className="l-v-s-o-box"
              style={{
                boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                background: "white",
              }}
            >
              <div className="l-v-s-o-box-t pt-2">
                <div className="l-v-s-o-box-t-h">Winner Customers</div>
                <div>
                  <span className="l-v-s-o-box-t-c">
                    {WinnersTotalCount?.CurrentCountString}
                  </span>
                  <span className="l-v-s-o-box-t-c-lbl ml-2">Customers</span>
                  <div className="disp-inline-b float-right pr-3">
                    {WinnersTotalCount?.Change > 0 ? (
                      <div className="l-v-s-o-box-t-c-per">
                        {WinnersTotalCount?.ChangeString}%
                      </div>
                    ) : (
                      <div className="l-v-s-o-box-t-c-per c-orange">
                        {WinnersTotalCount?.ChangeString}%
                      </div>
                    )}
                    <div className="l-v-s-o-box-t-c-per-lbl">this week</div>
                  </div>
                </div>
              </div>
              <div className="l-v-s-o-box-b">
                <LineChart
                  data={lineChartSingleOrangeData}
                  hideAxis={true}
                  hideHeader={true}
                ></LineChart>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className="overview-heading float-left clearfix mt-2 "
            style={{ margin: "30px 0 10px" }}
          >
            Top 5 Products
          </div>
          <div
            style={{ display: "flex", gap: "20px" }}
            className="float-left clearfix"
          >
            {[...Array(5)].map(() => (
              <div>
                <img
                  src={require("../../assets/img/sample.jpg")}
                  alt=""
                  width={200}
                  style={{ borderRadius: "15px" }}
                  height={156}
                />
                <div
                  style={{
                    background: "#676DFF",
                    borderRadius: "10px",
                    padding: "9px",
                    width: "90%",
                    margin: "-15px auto 0",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    fontSize: "12px",
                    color: "#fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>View Count</div>
                    <div>200k</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>Number of clicks</div>
                    <div>190k</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>Number of Orders:</div>
                    <div>10k</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <div className="w-100 float-left clearfix">
          <div className="w-50 float-left clearfix mb-2">
            <div className="overview-heading float-left clearfix mt-2">
              Last 6 hours of event activity
            </div>
          </div>
          <DateFilter onFilterClick={(days)=>getSalesOnDateFilterClick(days)} selected={salesRevenueFilter}/>
        </div> */}
        <div
          className="w-100 float-left clearfix"
          style={{
            boxShadow:
              "0px 2.63px 25.154px rgba(0, 0, 0, 0.05), inset 0px 0px 0.931631px rgba(255, 255, 255, 0.9",
            background: "white",
            borderRadius: "16px",
            padding: "10px",
            margin: "20px 0",
          }}
        >
          <div className="w-100 float-left clearfix">
            <div className="w-50 float-left clearfix mb-2">
              <div className="overview-heading float-left clearfix mt-2">
                Last 6 hours active customer count
              </div>
            </div>
            {/* <DateFilter onFilterClick={(days)=>getSalesOnDateFilterClick(days)} selected={salesRevenueFilter}/> */}
          </div>
          <div>
            <div
              className="w-100 float-left clearfix p-4 mb-4 chart-container"
              style={{
                border: "none",
                background: "white",
              }}
            >
              <LineCanvasGraph
                data={props.activeCustomerCounts}
                pointsEnabled={true}
                enableArea={true}
                yName={"Active Customer Count"}
              ></LineCanvasGraph>
            </div>
            {/* <div className="w-30 float-left clearfix p-4 mb-4 chart-container recentOne">
            <div className="overview-heading float-left clearfix mt-2">
              last 1 hour
            </div>
            <span
              className="l-v-s-o-box-t-c fontStyle"
              //   style={{ margin: "113px 0px", fontSize: "70px" }}
            >
              {lastHourCount}
            </span>
            <span className="l-v-s-o-box-t-c-lbl">Customers</span>
          </div> */}
          </div>
        </div>
        <div
          className="w-100 float-left clearfix"
          style={{
            boxShadow:
              "0px 2.63px 25.154px rgba(0, 0, 0, 0.05), inset 0px 0px 0.931631px rgba(255, 255, 255, 0.9",
            background: "white",
            borderRadius: "16px",
            padding: "10px",
          }}
        >
          <div className="w-100 float-left clearfix">
            <div className="w-50 float-left clearfix mb-2">
              <div className="overview-heading float-left clearfix mt-2 mb-2">
                PlayList Performance
              </div>
            </div>
          </div>
          <div className="w-100 float-left clearfix m">
            {[...Array(5)].map((_, idx) => (
              <div
                style={{
                  display: "flex",
                  border: "1px solid #F0F0F0",
                  borderRadius: "10px",
                  marginBottom: "15px",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "20%",
                    gap: "5px",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  {[...Array(6)].map(() => (
                    <div style={{}}>
                      <img
                        src={require("../../assets/img/sample.jpg")}
                        alt=""
                        style={{
                          background: "gray",
                          height: "50px",
                          width: "60px",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div style={{ width: "35%", padding: "10px" }}>
                  <div>Playlist {idx + 1}</div>
                  <div style={{ color: "gray", fontSize: "14px" }}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Tempore dolore impedit consequatur eaque iste sint!
                    Inventore provident u
                  </div>
                </div>
                <div
                  style={{
                    // width: "40%",
                    display: "flex",
                    gap: "10px",
                    alignSelf: "center",
                    justifyContent: "space-between",
                    // alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "#F2F9FF",
                      borderRadius: "15px",
                      padding: "15px ",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "22px",
                        fontWeight: 600,
                        color: "#017EFA",
                      }}
                    >
                      584
                    </div>
                    <div style={{ fontSize: "14px" }}>Number of views</div>
                  </div>
                  <div
                    style={{
                      background: "#F2F9FF",
                      borderRadius: "15px",
                      padding: "15px 20px",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "22px",
                        fontWeight: 600,
                        color: "#017EFA",
                      }}
                    >
                      584
                    </div>
                    <div style={{ fontSize: "14px" }}>Avg. Spend Time</div>
                  </div>
                  <div
                    style={{
                      background: "#F2F9FF",
                      borderRadius: "15px",
                      padding: "15px 20px",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "22px",
                        fontWeight: 600,
                        color: "#017EFA",
                      }}
                    >
                      584
                    </div>
                    <div style={{ fontSize: "14px" }}>Total Time Watch</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* {topFiveEng?.map((e) => (
          <div className="w-20 float-left clearfix p-3 mb-4 chart-container recentOne2">
            <div
              className="overview-heading float-left clearfix"
              style={{ fontSize: "12px" }}
            >
              {e.EnagementDisplayName}
            </div>
            <>
              <span
                className="l-v-s-o-box-t-c f-40"
                style={{ margin: "10px 0px" }}
              >
                {e.FormatedCount}
              </span>
              <span className="l-v-s-o-box-t-c-lbl">Customers Engaged</span>
            </>
          </div>
        ))} */}

        {/* <div className="w-100 float-left clearfix">
          <div className="w-50 float-left clearfix mb-2">
            <div className="overview-heading float-left clearfix mt-2">
              Active Customers Vs Customer With Higher Cart Value
            </div>
          </div>
          {/* <DateFilter onFilterClick={(days)=>getSalesOnDateFilterClick(days)} selected={salesRevenueFilter}/>
        </div>

        <div className="w-100 float-left clearfix p-4 mb-4 chart-container">
          <LineCanvasGraph
            data={props.grossValueCustomerCount}
            pointsEnabled={true}
            enableArea={true}
            yName={"Active Customer Count"}
          ></LineCanvasGraph>
        </div> */}

        {/* <div className="w-100 float-left clearfix">
          <div className="w-50 float-left clearfix mb-2">
            <div className="overview-heading float-left clearfix mt-2">
              Videos Watch Trend
            </div>
          </div>
          <DateFilter onFilterClick={(days)=>getSalesOnDateFilterClick(days)} selected={salesRevenueFilter}/>
        </div>

        <div className="w-100 float-left clearfix p-4 mb-4 chart-container">
          <LineCanvasGraph
            data={props.videoWatchedDuration}
            pointsEnabled={true}
            enableArea={true}
            yName={"Duration"}
          ></LineCanvasGraph>
        </div> */}

        {/* <div className="w-100 float-left clearfix">
          <div className="w-50 float-left clearfix">
            <div className="overview-heading float-left clearfix mt-2 l-v-h">
              Sales Overview
            </div>
          </div>
          <div className="w-50 float-left clearfix">
            <div className="w-100 float-right clearfix mb-1">
              <div
                className="float-right clearfix mb-1 f-c-box"
                onClick={lvFilterOpenClick}
              >
                <img
                  src={calender_src}
                  alt="Calender"
                  className="mr-2"
                  style={{ width: "16px" }}
                />
                <span className="d-dp-lbl pr-1">{lvFilterVal}</span>
                <img src={down_arrow_src} alt="Down Arrow" />
              </div>
              <Popover
                id={lvfId}
                open={lvfOpen}
                anchorEl={lvfEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Typography className={classes.typography}>
                  <div className="s-o-f-options p-0">
                    <div
                      className="s-o-f-option"
                      onClick={() => setLVFilterClick("Last Week")}
                    >
                      Last Week
                    </div>
                    <div
                      className="s-o-f-option"
                      onClick={() => setLVFilterClick("Last 1 Month")}
                    >
                      Last 1 Month
                    </div>
                    <div
                      className="s-o-f-option"
                      onClick={() => setLVFilterClick("Last 1 Year")}
                    >
                      Last 1 Year
                    </div>
                    <div
                      className="s-o-f-option"
                      onClick={() => setLVFilterClick("Current Week")}
                    >
                      Current Week
                    </div>
                  </div>
                </Typography>
              </Popover>
            </div>
          </div>
        </div> */}
        {/* <div className="mb-3">
                <span className="l-v-h">Sales Overview</span>
                <span className="l-v-f float-right"></span>
            </div> */}

        {/* <div className="w-100 float-left clearfix l-v-charts">
          <div className="w-50 float-left clearfix l-v-chart-box-outer">
            <div className="l-v-chart-box">
              <LineChart
                data={lineChartData}
                chartTitle="Sales Chart"
                showAction={true}
              ></LineChart>
            </div>
          </div>
          <div className="w-50 float-left clearfix l-v-chart-box-outer">
            <div className="l-v-chart-box">
              <BarChart
                data={slimBarChartData}
                chartTitle="Sales Chart"
                showAction={true}
                showInfo={true}
                showRefresh={true}
              ></BarChart>
            </div>
          </div>
          <div className="w-50 float-left clearfix l-v-chart-box-outer">
            <div className="l-v-chart-box">
              <BarChart
                data={barChartData}
                showLines={false}
                chartTitle="Coupons Redeemed"
                showAction={true}
                noXLine={true}
              ></BarChart>
            </div>
          </div>
          <div className="w-50 float-left clearfix l-v-chart-box-outer">
            <div className="l-v-chart-box">
              <DoughnutChart
                data={doughnutChartData}
                chartTitle="Top performing Games"
                showAction={true}
                showInfo={true}
                showRefresh={true}
              ></DoughnutChart>
            </div>
          </div>
        </div>

        <div className="w-100 float-left clearfix">
          <div className="l-v-i-t-h">Instant Targeting</div>
        </div>
        <div className="w-100 float-left clearfix l-v-i-t">
          <div className="w-25 float-left clearfix l-v-i-t-box-outer">
            <div className="l-v-i-t-box">
              <div className="l-v-i-t-box-t">
                <div className="w-50 float-left clearfix h-100 c-center f-d-c">
                  <div className="text-center">
                    <span className="l-v-i-t-box-t-h">234</span>
                    <span className="l-v-i-t-box-t-h-c">+40%</span>
                  </div>
                  <div className="l-v-i-t-box-t-desc">Pending Cart</div>
                </div>
                <div className="w-50 float-left clearfix l-v-i-t-box-t-c">
                  <BarChart
                    data={smallBarChartData}
                    showLines={false}
                    hideAxis={true}
                    hideHeader={true}
                  ></BarChart>
                </div>
              </div>
              <div className="l-v-i-t-box-b">
                <div className="l-v-i-t-box-b-btn">Engage Now</div>
              </div>
            </div>
          </div>
          <div className="w-25 float-left clearfix l-v-i-t-box-outer">
            <div className="l-v-i-t-box">
              <div className="l-v-i-t-box-t">
                <div className="w-50 float-left clearfix h-100 c-center f-d-c">
                  <div className="text-center">
                    <span className="l-v-i-t-box-t-h">131</span>
                    <span className="l-v-i-t-box-t-h-c c-orange">-5%</span>
                  </div>
                  <div className="l-v-i-t-box-t-desc">Inactive Customers</div>
                </div>
                <div className="w-50 float-left clearfix l-v-i-t-box-t-c">
                  <BarChart
                    data={smallBarChartData}
                    showLines={false}
                    hideAxis={true}
                    hideHeader={true}
                  ></BarChart>
                </div>
              </div>
              <div className="l-v-i-t-box-b">
                <div className="l-v-i-t-box-b-btn">Engage Now</div>
              </div>
            </div>
          </div>
          <div className="w-25 float-left clearfix l-v-i-t-box-outer">
            <div className="l-v-i-t-box">
              <div className="l-v-i-t-box-t">
                <div className="w-50 float-left clearfix h-100 c-center f-d-c">
                  <div className="text-center">
                    <span className="l-v-i-t-box-t-h">1,254</span>
                    <span className="l-v-i-t-box-t-h-c">+54%</span>
                  </div>
                  <div className="l-v-i-t-box-t-desc">High Value Customers</div>
                </div>
                <div className="w-50 float-left clearfix l-v-i-t-box-t-c">
                  <BarChart
                    data={smallBarChartData}
                    showLines={false}
                    hideAxis={true}
                    hideHeader={true}
                  ></BarChart>
                </div>
              </div>
              <div className="l-v-i-t-box-b">
                <div className="l-v-i-t-box-b-btn">Engage Now</div>
              </div>
            </div>
          </div>
          <div className="w-25 float-left clearfix l-v-i-t-box-outer">
            <div className="l-v-i-t-box">
              <div className="l-v-i-t-box-t">
                <div className="w-50 float-left clearfix h-100 c-center f-d-c">
                  <div className="text-center">
                    <span className="l-v-i-t-box-t-h">423</span>
                    <span className="l-v-i-t-box-t-h-c">+23%</span>
                  </div>
                  <div className="l-v-i-t-box-t-desc">New Customers</div>
                </div>
                <div className="w-50 float-left clearfix l-v-i-t-box-t-c">
                  <BarChart
                    data={smallBarChartData}
                    showLines={false}
                    hideAxis={true}
                    hideHeader={true}
                  ></BarChart>
                </div>
              </div>
              <div className="l-v-i-t-box-b">
                <div className="l-v-i-t-box-b-btn">Engage Now</div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-100 float-left clearfix">
          <div className="l-v-a-c-h">Active Campaigns</div>
        </div>
        <div className="w-100 float-left clearfix l-v-a-c">
          {/* {campaigndata && campaigndata.length > 0 ? (
                    <CampaignBox campaigndata={campaigndata}></CampaignBox>
                ) : <div className="e-s-heading ml-4">No campaigns found!</div>} */}
        {/* </div> */}
      </div>
    </Fragment>
  );
}
