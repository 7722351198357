import React, { useEffect, useState } from "react";
import "./DesignCloud.css";
import web from "./Image/Icon/web-design.jpg";
import montage from "./Image/Icon/montage.png";
import copywriter from "./Image/Icon/copywriter.png";
import magicwand from "./Image/Icon/magic-wand.png";
import { useHistory } from "react-router-dom";
import UseDesignHook from "./components/UseDesignHook";
import { AddAccount } from "../AddAccount";
// import { SocialMediaPopup } from "./components/SocialMediaPost";
import axios from "axios";
import NetworkCard from "./components/AccountCard";

const data = [
  { id: 1, image: web, path: "/design", text: "Design" },
  { id: 2, image: montage, path: "/design", text: "Video" },
  { id: 3, image: copywriter, text: "AI Writing" },
  { id: 4, image: magicwand, text: "AI Quick Tools" },
  {
    id: 5,
    image: montage,
    path: "/socialmediapopup",
    text: "Social Media Launcher",
  },
  { id: 6, image: web, text: "Post To Platform" },
];

const DesignCloud = (path) => {
  const { updateThemeToDark, updateThemeToLight } = UseDesignHook();
  const [isAuthorised, setIsAuthorised] = useState(false);
  const history = useHistory();

  const handleClick = (item) => {
    console.log("Clicked on item with ID:", item.path);
    history.push({
      pathname: item.path,
      state: { type: item.id },
    });
  };

  useEffect(() => {
    updateThemeToDark();
    document.body.style.background = "#22222A";

    //validateToken();

    return () => {
      updateThemeToLight();
      document.body.style.background = "white";
    };
  }, []);
  return (
    <div className="">
      {/* <div className="frame">
        <div className="div">
          <div className="text-wrapper">No social accounts connected</div>
          <div
            style={{ color: "rgba(255, 255, 255, 0.50)" }}
            // onClick={closeModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M13.9997 25.6668C20.443 25.6668 25.6663 20.4435 25.6663 14.0002C25.6663 7.55684 20.443 2.3335 13.9997 2.3335C7.55635 2.3335 2.33301 7.55684 2.33301 14.0002C2.33301 20.4435 7.55635 25.6668 13.9997 25.6668Z"
                fill="#FF5F58"
              />
              <path
                d="M15.2366 13.9999L17.9199 11.3166C18.2583 10.9783 18.2583 10.4183 17.9199 10.0799C17.5816 9.74159 17.0216 9.74159 16.6833 10.0799L13.9999 12.7633L11.3166 10.0799C10.9783 9.74159 10.4183 9.74159 10.0799 10.0799C9.74159 10.4183 9.74159 10.9783 10.0799 11.3166L12.7633 13.9999L10.0799 16.6833C9.74159 17.0216 9.74159 17.5816 10.0799 17.9199C10.2549 18.0949 10.4766 18.1766 10.6983 18.1766C10.9199 18.1766 11.1416 18.0949 11.3166 17.9199L13.9999 15.2366L16.6833 17.9199C16.8583 18.0949 17.0799 18.1766 17.3016 18.1766C17.5233 18.1766 17.7449 18.0949 17.9199 17.9199C18.2583 17.5816 18.2583 17.0216 17.9199 16.6833L15.2366 13.9999Z"
                fill="#292D32"
              />
            </svg>
          </div>
        </div>
        <p className="p">
          You do not have a social accounts connected. Go to Calendar and
          connect at least one account first.
        </p>
        <div className="div-2">
          <div className="div-wrapper">
            <div className="text-wrapper-2">Cancel</div>
          </div>
          <div className="div-wrapper-2">
            <div className="text-wrapper-2">Connect Account</div>
          </div>
        </div>
      </div> */}
      {/* <AddAccount />
      <SocialMediaPopup /> */}
      {/* <label style={{ color: "#fff" }}>Facebook</label>
      <NetworkCard isAuthorised={isAuthorised} />
      <label style={{ color: "#fff" }}>YouTube</label>
      <NetworkCard isAuthorised={true} /> */}

      <div className="box-first">
        <div className="text_style">What do you want to do today ?</div>
        <div className="center-box">
          {data.map((item, index) => (
            <div
              className="flex-item"
              key={item.id}
              onClick={() => handleClick(item)}
            >
              <img
                src={item.image}
                alt={`Image ${index + 1}`}
                className="img_cloud"
              />
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DesignCloud;
