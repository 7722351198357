import React, { useState, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Table from "../../../common/reactTable/table";
import _ from "lodash";
import "./rewardsAndBudget.css";
import createNotification from "../../../common/reactNotification";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import plus_src from "../../../../assets/img/add_gray.svg";
import Resizer from "../../../common/resizer/resizer";
import { getAuthAndData } from "../../../../api/ApiHelper";
import {
  BUDGET_MIN,
  BUDGET_DEFAULT,
  BUDGET_MAX,
  BUDGET_DURATION_DEFAULT,
  BUDGET_DURATION_MIN,
  BUDGET_DURATION_MAX,
  BUDGET_CURRENCY,
} from "../../../../constants/globalConstants";
import {
  REWARD_TYPES,
  SOMETHING_WENT_WRONG,
  REWARDS_BY_REWARD_TYPE,
} from "../../../../api/apiConstants";
import RewardRow from "./RewardRow";
import DeleteIcon from "@material-ui/icons/Delete";

const arrayRewards = [
  {
    winnerPosition: 1,
    rewardType: { label: "Select", value: "" },
    id: "",
    rewardName: "",
    rewardValue: "",
    probability: "",
    numberOfWinners: "",
    displayName: "",
  },
];

export default function RewardsAndBudgets(props) {
  console.log("*prors in rewardsAndBudget======*", props);
  var history = useHistory();
  const goalData = props.props.setGoals;
  const rewardsAndBudgetData = props.props.rewardsAndBudget;
  // const [rewardRowsData, setRewardRowsData] = useState(rewardsAndBudgetData?.rewards?.length>0 ?rewardsAndBudgetData?.rewards: arrayRewards);
  const [rewardNames, setRewardNames] = useState([]);
  const [rewardMaster, setRewardMaster] = useState([]);
  const [rewardRow, setRewardRow] = useState(
    rewardsAndBudgetData?.rewards || []
  );
  //const [filteredArr, setFilteredArr] = useState([])
  //const [winnersPosition, setWinnersPosition] = useState()
  const [rewardType, setrewardType] = useState(rewardNames[0]);
  const [budget, setBudget] = useState(
    rewardsAndBudgetData?.budget || BUDGET_DEFAULT
  );
  const [budgetDuration, setBudgetDuration] = useState(
    rewardsAndBudgetData?.budgetDuration || BUDGET_DURATION_DEFAULT
  );
  const [rewardDataObj, setRewardDataObj] = useState({
    winnerPosition: 0,
    rewardType: "",
    id: "",
    rewardName: "",
    rewardValue: "",
    probability: "",
    numberOfWinners: "",
    displayName: "",
  });
  const journeyColumns = [
    {
      name: "Reward Name",
      selector: "displayName",
      width: "35%",
    },
    goalData?.engagementType != 2 && {
      name: "Probability",
      selector: "probability",
      width: "15%",
      // cell: rowObj =>
      //     <div style={{ paddingRight: '5px' }}>
      //         <div>{rowObj.JourneyTasks.length && rowObj.JourneyTasks[0].JourneyTaskName}</div>
      //         <CustomTooltip tooltipText={rowObj.JourneyTasks.length && rowObj.JourneyTasks.map(j => '-> ' + j.JourneyTaskName + ' ')} >
      //             <button type="button" className='table-row-btn'>
      //                 <div className='table-row-btn-text'>{rowObj.JourneyTasks.length}</div>
      //             </button>
      //         </CustomTooltip>
      //     </div>
    },
    goalData?.engagementType == 2 && {
      name: "Winner Position",
      selector: "winnerPosition",
      width: "10%",
    },
    goalData?.engagementType == 2 && {
      name: "Number Of Winners",
      selector: "numberOfWinners",
      width: "30%",
    },
    {
      name: "Delete",
      width: "10%",
      cell: (rowObj) => (
        <DeleteIcon onClick={(e) => onDeleteClick(e, rowObj)} />
      ),
      // cell: rowObj => <DeleteIcon onClick={e => removeRow(rowObj?.id)} />
    },
  ];

  const onDeleteClick = (e, rowObj) => {
    console.log("Deleted Row ==========", rowObj);
    // let action = e.target.textContent;
    removeRow(rowObj?.id);
  };

  const onRewardRowChange = (target) => {
    //console.log("onRewardRowChange======",target)
    setrewardType(target);
    setRewardDataObj({
      ...rewardDataObj,
      rewardType: target.label,
      id: target.value,
      rewardName: target.label,
      displayName: target.label,
    });
  };

  const winnerChange = (e) => {
    // console.log("winnerChange===",e.target.value)
    // setWinnersPosition(e.target.value)
    setRewardDataObj({ ...rewardDataObj, winnerPosition: e.target.value });
  };

  const winnerCount = (e) => {
    // console.log("winnercount ======", e.target.value)
    setRewardDataObj({ ...rewardDataObj, numberOfWinners: e.target.value });
  };

  // const nameChange=(e)=>{
  //    //console.log("onRewardNameChange======",e.target.value)
  //    setRewardDataObj({...rewardDataObj, displayName : e.target.value})

  // }

  const probabilityChang = (e) => {
    // console.log("probabilutyChange=======", e.target.value)
    setRewardDataObj({ ...rewardDataObj, probability: e.target.value });
  };

  const removeRow = (rewardMasterId) => {
    //const removedRow = rewardRow.filter((e)=>(e?.id==rewardMasterId))

    console.log("TO BE Deleted Reward ID ", rewardMasterId);

    const flteredAwards = rewardRow.filter((e) => e?.id !== rewardMasterId);

    console.log("Remaining Collection for Rewards", flteredAwards);

    setRewardRow(flteredAwards);
    DispatchData(flteredAwards);
  };

  const addRow = () => {
    // iF eMPTY - Show a Warning
    // If Duplicate - Show a Warning

    console.log("On Add Row ===", rewardDataObj);
    const DuplicateRewardCheck = rewardRow?.filter(
      (e) => e?.id == rewardDataObj?.id
    );
    const winnerpositionCheck = rewardRow?.filter(
      (e) => e?.winnerPosition == rewardDataObj?.winnerPosition
    );
    console.log("DuplicateRewardCheck=======", DuplicateRewardCheck);

    if (rewardDataObj?.id == 0) {
      createNotification("warning", "Please Add Appropriate data");
    } else if (
      winnerpositionCheck?.length != 0 &&
      goalData?.engagementType == 2
    ) {
      createNotification("warning", "Winner Position Already Exist");
    } else if (
      goalData?.engagementType == 2 &&
      rewardDataObj?.winnerPosition == 0
    ) {
      createNotification("warning", "Winner Position 0 is not valid");
    } else if (DuplicateRewardCheck?.length != 0) {
      createNotification("warning", "Reward Type Already Present");
    } else {
      rewardRow.push(rewardDataObj);

      console.log("Latest Collection ===", rewardDataObj);

      setRewardRow(rewardRow);
      DispatchData(rewardRow);
    }
  };

  const fetchAllCouponRewards = () => {
    const couponConst = 2;
    getAuthAndData(`${REWARDS_BY_REWARD_TYPE}${couponConst}`, history).then(
      (res) => {
        console.log("fetchAllCouponRewards========", res);
        if (handleResponseCode(res)) {
          setRewardMaster(res.data);
          var rewardNameOptions = [];
          res.data.forEach((rew) => {
            let obj = {
              value: rew.reward_master_id,
              label: rew.reward_name,
              reward_code: res.data[0].reward_code,
              description: res.data[0].description,
              expiry_date: res.data[0].expiry_date,
            };
            rewardNameOptions.push(obj);
          });
          setRewardNames(rewardNameOptions);
          setrewardType(rewardNames[0]);
        }
      }
    );
  };

  const DispatchData = (rewardCollection) => {
    let rewardsAndBudget = {
      rewards: rewardCollection,
      budget: budget,
      budgetDuration: budgetDuration,
    };
    console.log("New Reward Collection", rewardCollection);
    console.log("row reward data beford dispatch", rewardsAndBudget);
    props.props.engagementsSmartActionHandler.dispatchRewardsAndBudgetData(
      rewardsAndBudget
    );
  };

  useEffect(() => {
    fetchAllCouponRewards();
  }, []);
  // useEffect(() => {

  // }, [budget, budgetDuration, rewardRowsData])

  const handleResponseCode = (resp) => {
    if (!resp || resp.code === -1) {
      createNotification(
        "error",
        SOMETHING_WENT_WRONG + "in Rewards and Budget"
      );
      return false;
    } else {
      return true;
    }
  };

  const lableSet = (event) => {
    console.log("console the event =", event.target.checked);
    props.setEnableExternalReward(event.target.checked);
  };
  // const onBudgetChange = (budget)=>{
  //     setBudget(budget)
  //     DispatchData(rewardRow)
  // }
  // const onDurationChange = (duration)=>{
  //     setBudgetDuration(duration)
  //     DispatchData(rewardRow)
  // }
  useEffect(() => {
    DispatchData(rewardRow);
  }, [budgetDuration, budget]);

  const disableEditRewards =
    rewardsAndBudgetData?.rewards?.length > 0 &&
    rewardsAndBudgetData?.rewards.filter((r) => {
      if (r.engagementRewardId) return r;
    })?.length > 0;
  console.log("rewardDataObj===PPPPP++++", rewardRow);
  return (
    <Fragment>
      <div style={{ paddingLeft: "20px" }}>
        <FormControlLabel
          control={<Switch onChange={(event) => lableSet(event)} />}
          label="Allow 3rd Party rewards"
        />
      </div>
      <div id="rewards-budget-container">
        <Fragment>
          <div
            className="add-reward-sec w-100 float-left clearfix"
            style={{
              pointerEvents: props.abTestFlage
                ? ""
                : disableEditRewards
                ? "none"
                : "",
            }}
          >
            {/* {rewardRowsData.map((obj, i) => */}
            <div className="r-b-addreward-top ">
              <div className="rewardAndProb">
                <div
                  className={`${
                    goalData?.engagementType ? "w-32" : "w-32"
                  } float-left clearfix mr-1`}
                >
                  <div
                    className="w-100 float-left clearfix"
                    style={{ fontSize: "12px" }}
                  >
                    <div className="r-b-ar-i-h w-90">Reward Name</div>
                    <div className="ml-1 row" style={{ marginRight: "-33px" }}>
                      <div className="w-80">
                        <Select
                          options={rewardNames}
                          // isDisabled={obj.rewardType?.value!==2}
                          name="rewardName"
                          onChange={(e) => onRewardRowChange(e)}
                          value={rewardType}
                          style={{ lineHeight: "28px" }}
                          maxMenuHeight={150}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="w-32 float-left clearfix mr-1"
                  style={{
                    //opacity: goalData?.engagementType != 2 ? "1" : "0.2",
                    marginLeft: "25px",
                  }}
                >
                  <div
                    className="w-100 float-left clearfix"
                    style={{ fontSize: "12px" }}
                  >
                    <div className="r-b-ar-i-h">Probability(%)</div>
                    <input
                      type="number"
                      name="probability"
                      onChange={(e) => probabilityChang(e)}
                      // onBlur={onProbabilityBlur}
                      //value={obj.probability}
                      disabled={goalData?.engagementType == 2}
                      placeholder="0%"
                      className=" r-b-ar-i"
                      style={{ textAlign: "center" }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="rewardAndProb"
                style={{ display: goalData?.engagementType != 2 ? "none" : "" }}
              >
                <div className="w-30 float-left clearfix mr-5">
                  <div
                    className="w-100 float-left clearfix"
                    style={{ fontSize: "12px" }}
                  >
                    <div className="r-b-ar-i-h">Win Position</div>
                    <input
                      type="number"
                      name="winnerPosition"
                      onChange={(e) => winnerChange(e)}
                      // value={obj.winnerPosition}
                      placeholder="0"
                      className=" r-b-ar-i"
                      style={{ textAlign: "center" }}
                    />
                  </div>
                </div>
                <div className="w-32 float-left clearfix mr-1">
                  <div
                    className="w-100 float-left clearfix"
                    style={{ fontSize: "12px" }}
                  >
                    <div className="r-b-ar-i-h">Winners</div>
                    <input
                      type="number"
                      name="numberOfWinners"
                      onChange={(e) => winnerCount(e)}
                      // value={obj.numberOfWinners}
                      className=" r-b-ar-i"
                      style={{ textAlign: "center" }}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="w-30 float-left clearfix mr-1">
                                    <div className="w-100 float-left clearfix"  style={{fontSize:'12px'}}>
                                    <div className="r-b-ar-i-h">Display To Customer</div>
                                        <input name='displayName' 
                                        onChange={(e) => nameChange(e)} 
                                      // value="anand" 
                                        placeholder="Display Name" 
                                        className=" r-b-ar-i" />
                                    </div>
                                </div> */}
              <div className="addRewardButton">
                <div
                  className="r-b-addreward-btns float-left r-b-add-reward clearfix "
                  style={{ marginTop: "20px", backgroundColor: "#6b9cf3" }}
                  onClick={addRow}
                >
                  <img
                    src={plus_src}
                    alt="Plus"
                    className="r-b-add-reward-img"
                    style={{ color: "white" }}
                  />
                  <span
                    className="r-b-add-reward-text"
                    style={{ color: "white" }}
                  >
                    Add Reward
                  </span>
                </div>
              </div>
            </div>
            <div>
              {rewardRow &&
                rewardRow.length > 0 &&
                journeyColumns &&
                journeyColumns?.length > 0 && (
                  <Table
                    columns={journeyColumns}
                    data={rewardRow}
                    pagination={true}
                    // subHeader={true}
                  />
                )}
            </div>

            {/* {rewardRow?.map((obj)=>(<RewardRow key ={obj?.id}  obj = {obj} removeRow = {removeRow} goalData = {goalData}  rewardCollection = {rewardNames} /> )) } */}
          </div>
        </Fragment>
      </div>
      <div
        className="b-d-sec w-100 float-left clearfix"
        style={{ display: goalData?.engagementType == 2 ? "none" : "" }}
      >
        <div className="w-45 float-left clearfix">
          <div className="b-d-h w-100 float-left clearfix">
            Budget (In {BUDGET_CURRENCY})
          </div>
          <div className="b-d-content w-100 float-left clearfix">
            <div className="w-100 float-left clearfix">
              <Resizer
                minSize={BUDGET_MIN}
                maxSize={BUDGET_MAX}
                initialSize={budget}
                id="budgetResizer"
                valText=""
                updateBudget={(budget) => setBudget(budget)}
              />
            </div>
          </div>
        </div>
        <div className="w-10 float-left clearfix"></div>
        <div className="w-45 float-right clearfix">
          <div className="b-d-h">Duration (In Days)</div>
          <div className="b-d-content">
            <div className="w-100 float-left clearfix">
              <Resizer
                minSize={BUDGET_DURATION_MIN}
                maxSize={BUDGET_DURATION_MAX}
                initialSize={budgetDuration}
                id="daysResizer"
                valText="Days"
                updateBudgetDuration={(duration) => setBudgetDuration(duration)}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
