/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import UseIgHook from '../igHook/UseIgHook';
import '../../igUploads/OneClickPost.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));
const MultipleBoxDropDown = ({productArr, onProductArrayChange,setProductArray,productArray}) => {
  

  const classes = useStyles();

  const handleSelectionChange = (event, value) => {
    
    console.log("Selected products -->",value);

    onProductArrayChange(value);
    setProductArray(value)
    console.log('111autocompleteproductArr',productArr);
    
    
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={productArr}
        style={{
          boxSizing:"border-box",
          width: 324,
          height: 37,
          
          
        }}
        value={productArray}
        getOptionLabel={(option) => {
          console.log('111option',option);
          return option.display_name
        }}
        onChange={handleSelectionChange}
        defaultValue={[
          // top100Films[13]
        ]}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.display_name}
              {...getTagProps({ index })}
              // disabled={fixedOptions.indexOf(option) !== -1}
            />
          ))
        }
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label=""
            placeholder=""
          />
        )}
        classes={{
          // paper: 'custom-autocomplete-paper',
          inputRoot: 'custom-autocomplete-inputRoot',
          input: 'custom-autocomplete-input',
        }}
      />

    </div>
  );
}




export default MultipleBoxDropDown;



