import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgressBar = ({ progress, progressText }) => {
  return (
    <div style={{ width: "40px", height: "40px" }}>
      <CircularProgressbar value={progress} text={`${progressText}%`} />
    </div>
  );
};

export default CircularProgressBar;
