import { ResponsiveLine } from "@nivo/line";
import { ResponsiveFunnel } from "@nivo/funnel";
import React, { useState, useEffect } from "react";
// import { ResponsiveLine } from "@nivo/line";
// import { ResponsiveFunnel } from "@nivo/funnel";
// import React, { useState, useEffect } from "react";
import {
  dummyLineChart,
  getRevenueSalesOverview,
  responsiveBarChart,
  ResponsiveFunnelData,
  ResponsiveLineChartData,
} from "../../constants/globalMockdata";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  GET_STORY_DASHBOARD,
  ENGT_PROD_BASE_URI,
  SOMETHING_WENT_WRONG,
  GET_ALL_PLAYLIST,
  GET_REVENUE_OVERVIEW,
  GET_REVENUE_TREND_OVERVIEW,
  GET_REVENUE_SALES_OVERVIEW,
} from "../../api/apiConstants";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";
import { getAuthAndData, postAuthAndData } from "../../api/ApiHelper";
import createNotification from "../common/reactNotification";
import { NotificationContainer } from "react-notifications";
import moment from "moment";
// import { MenuItem, Select } from "@material-ui/core";
import Select from "react-select";
import { ResponsiveBar } from "@nivo/bar";
// let RevenueData = {};

let SalesData = {};

function Revenue() {
  const [RevenueData, setRevenueData] = useState({})
  const [numberOfViewers, setNumberOfViewers] = useState({});
  const [numberOfActiveViews, setNumberOfActiveViews] = useState({});
  const [userInteractions, setUserInteractions] = useState({});
  const [lineChart, setLineChart] = useState(getRevenueSalesOverview);
  const [BarChart, setBarChart] = useState(responsiveBarChart);
  const [TopSalesData, setTopSalesData] = useState([]);
  const [sales, setSales] = useState(0)

  const [playlistCollection, setPlaylistCollection] = useState([
    {
      value: -1,
      label: "Playlist",
      playlistCode: "",
    },
    {
      value: -1,
      label: "Story",
      playlistCode: "",
    },
    {
      value: -1,
      label: "Product",
      playlistCode: "",
    },
  ]);
  const [selectedPlaylist, setSelectedPlaylist] = useState({
    value: -1,
    label: "Playlist",
    playlistCode: "",
  });
  const [days, setdays] = useState(7);
  // const [RevenueData, setRevenueData] = useState({});
  const [showtext, setshowtext] = useState(false);
  const [showfunneltext, setshowfunneltext] = useState(false);
  const [Play_list, setPlay_list] = useState("");
  const GetPlayList = (event) => {
    setPlay_list(event.target.value);
  };

  const History = useHistory();
  const Dispatch = useDispatch();
  console.log("TopSalesData=====", TopSalesData)
  const handleResponseCode = (resp) => {
    if (!resp || resp.code === -1) {
      createNotification("error", SOMETHING_WENT_WRONG + "in Target Audience");
      return false;
    } else {
      return true;
    }
  };

  const fetchRevenueOverview = async () => {
    handleLoader(true);
    const body = {
      Playlist_id: selectedPlaylist?.value,
      LastXDays: days,
    };
    try {
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_REVENUE_OVERVIEW}`,
        body,
        History
      );
      setRevenueData(data?.data);
     console.log('%%%%%%%', RevenueData?.PCTotalAvgOrderPerDay)





      if (
        data.data.Sales_OverViews[0].TotalViews !== 0 ||
        data.data.Sales_OverViews[0].TotalSales !== 0
      ) {
        customLinestorydata();
        setshowtext(false);
      } else {
        setshowtext(true);
      }
      // if (
      //   data.data.Views_Summery?.TotalViews !== 0 ||
      //   data.data.Views_Summery?.TotalActiveViews !== 0 ||
      //   data.data.Views_Summery?.TotalCTAs !== 0
      // ) {
      //   customFunnelstorydata();
      //   setshowfunneltext(false);
      // } else {
      //   setshowfunneltext(true);
      // }
    } catch (err) { }
    handleLoader(false);
  };

  const fetchRevenueSalesOverview = async () => {
    handleLoader(true);
    const body = {
      Playlist_id: selectedPlaylist?.value,
      LastXDays: days,
    };
    try {
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_REVENUE_SALES_OVERVIEW}`,
        body,
        History
      );
      SalesData = data?.data;
      console.log("SalesData====", data);
      if (
        data.data.TotalSales !== 0 ||
        data.data.TotalCustomersEngaged !== 0
      ) {
        customLinestorydata();
        setshowtext(false);
      } else {
        setshowtext(true);
      }
      if (
        data.data.Views_Summery?.TotalViews !== 0 ||
        data.data.Views_Summery?.TotalActiveViews !== 0 ||
        data.data.Views_Summery?.TotalCTAs !== 0
      ) {
        customLinestorydata();
        setshowfunneltext(false);
      } else {
        setshowfunneltext(true);
      }
    } catch (err) { }
    handleLoader(false);
  };


  const customLinestorydata = () => {
    const ResponsiveLineChartData = [
      // {
      //   id: "Total Customers Engaged",
      //   color: "hsl(350, 70%, 50%)",
      //   data: SalesData?.map((item) => {
      //     return {
      //       x: moment(item.ReportingDate).format("MMM Do YY"),
      //       y: item.TotalCustomersEngaged,
      //     };
      //   }),
      // },
      {
        id: "Total Sales",
       color: "hsl(32, 70%, 50%)",
       // color: "hsl(350, 70%, 50%)",
        data: SalesData?.map((item) => {
          console.log("SalesData==", SalesData)
          return {
            x: moment(item.ReportingDate).format("MMM Do YY"),
            y: item.TotalSales,
          };
        }),
      },
    ];
    setLineChart(ResponsiveLineChartData);
  };

  // const customFunnelstorydata = () => {
  //   const ResponsiveFunnelData = [
  //     {
  //       id: "TotalViews",
  //       value: RevenueData?.Views_Summery?.TotalViews,
  //       label: "Total Views",
  //     },
  //     {
  //       id: "TotalActiveViews",
  //       value: RevenueData?.Views_Summery?.TotalActiveViews,
  //       label: "Total Active Views",
  //     },
  //     {
  //       id: "TotalCTAs",
  //       value: RevenueData?.Views_Summery?.TotalCTAs,
  //       label: "Total CTAs",
  //     },
  //   ];
  //   setfunnelchart(ResponsiveFunnelData);
  // };

  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
  };

  const getAllPlayList = async () => {
    handleLoader(true);
    const playlistArr = [];
    try {
      const body = {
        Content_Type: 1,
      };
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_ALL_PLAYLIST}`,
        body,
        History
      );
      // debugger;
      console.log("getAllPlayList========", data?.data);
      data?.data?.forEach((player) => {
        let obj = {
          value: player?.PlayListId,
          label: player?.Name,
          playlistCode: player?.PlayListCode,
        };
        playlistArr.push(obj);
      });
      console.log(playlistArr);
      setPlaylistCollection([playlistCollection[0], ...playlistArr]);
      setSelectedPlaylist(playlistCollection[0]);
      //onPlaylistChange(playlistArr[0])
    } catch (err) { }
    handleLoader(false);
  };

  const getRevenueTrendOverview = async () => {
    handleLoader(true);
    var body = {
      RevenueTrendType: "2",
      LastXDays: days,
    };
    try {
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_REVENUE_TREND_OVERVIEW}`,
        body,
        History
      );
      setTopSalesData(data?.data);
      console.log("TopSalesData====", data);
    } catch (err) {
      console.log("err", err);
    }
    handleLoader(false);
  };



  const onPlaylistChange = (target) => {
    console.log("target=====", target);
    setSelectedPlaylist(target);
  };

  // useEffect(() => {
  //   getAllPlayList();
  //   // fetchSocialDashboardData(-1);
  // }, []); 
  useEffect(() => {
    fetchRevenueOverview();
    fetchRevenueSalesOverview();
  }, [selectedPlaylist, days]);

  useEffect(() => {
    getRevenueTrendOverview();
  }, [])

  const MyResponsiveLine = () => (
    <div style={{ height: "400px" }}>
      <ResponsiveLine
        data={lineChart}
        width={800}
        height={400}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Date",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        colors={{ scheme: "dark2" }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        enableSlices="x"
        sliceTooltip={({ slice }) => {
          return (
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
              }}
            >
              {/* <div>x: {slice.id}</div> */}
              {slice.points.map((point) => (
                <div
                  key={point.id}
                  style={{
                    color: point.serieColor,
                    padding: "3px 0",
                  }}
                >
                  <strong>{point.serieId}</strong>: {point.data.yFormatted}
                </div>
              ))}
            </div>
          );
        }}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );

  const MyResponsiveBar = () => (
    <div style={{ height: "400px" }}>
      <ResponsiveBar
        data={BarChart}
        keys={[
          'Total Sales',
          'Trend Name',
        ]}
        indexBy={selectedPlaylist?.value}
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
          }
        ]}
        fill={[
          {
            match: {
              id: 'fries'
            },
            id: 'dots'
          },
          {
            match: {
              id: 'sandwich'
            },
            id: 'lines'
          }
        ]}
        borderColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6
            ]
          ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: selectedPlaylist?.value,
          legendPosition: 'middle',
          legendOffset: 32
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'food',
          legendPosition: 'middle',
          legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6
            ]
          ]
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " in country: " + e.indexValue }}
      />
    </div>
  );

  return (
    <div>
      <div id="liveview-container">
        <div className="subHeaderMv12">
          <div className="w-50 float-left clearfix mb-2">
            <div className="overview-heading float-left clearfix mt-2">
              Summary for the{" "}
              <a
                href="#"
                style={{ color: days == 7 ? "" : "#54b699" }}
                onClick={() => setdays(7)}
              >
                {" "}
                Last 7 Days
              </a>{" "}
              |{" "}
              <a
                href="#"
                style={{ color: days == 15 ? "" : "#54b699" }}
                onClick={() => setdays(15)}
              >
                Last 15 Days
              </a>{" "}
              |{" "}
              <a
                href="#"
                style={{ color: days == 30 ? "" : "#54b699" }}
                onClick={() => setdays(30)}
              >
                Last 30 Days
              </a>
            </div>
          </div>
        </div>
        <div
          className="w-100 float-left clearfix"
          style={{ margin: "20px 0 0" }}
        >
          <div className="w-50 float-left clearfix mb-2">
            <div className="overview-heading float-left clearfix mt-2" style={{ fontSize: "18px" }}>
              Sales Overview
            </div>
          </div>
        </div>

        <div className="w-100 float-left clearfix l-v-s-o">
          <div className="w-50 float-left clearfix l-v-s-o-box-outer">
            <div
              className="l-v-s-o-box"
              style={{
                boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                background: "white",
                height: "260px",
                // border:"3px solid #e3e3e3"
              }}
            >
              <div className="l-v-s-o-box-t pt-3">
                <div style={{ textAlign: "center" }}>
                  <span
                    className="l-v-s-o-box-t-c"
                    style={{ lineHeight: "110px", fontSize: "90px" }
                    }
                  >
                    {RevenueData?.TotalOrdersFormatted === "0"
                      ? 0
                      : RevenueData?.TotalOrdersFormatted}
                  </span>
                  {/* <span className="l-v-s-o-box-t-c-lbl  ml-2">Views</span> */}
                </div>
                <div>
                  {/* <div
                    className="d-flex"
                    style={{ textAlign: "center", paddingLeft: "90px" }}
                  > */}
                  <div
                    className="l-v-s-o-box-t-c-per"
                    style={{
                      lineHeight: "56px",
                      color: "#5f6165",
                      textAlign: "left",
                      paddingLeft: "306px",
                      fontSize: "1.5rem",
                    }}
                  >
                    Orders
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="w-50 float-left clearfix l-v-s-o-box-outer">
            <div
              className="l-v-s-o-box"
              style={{
                height: "260px",
                boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                background: "white",
                // border:"3px solid #e3e3e3"
              }}
            >
              <div className="l-v-s-o-box-t  pt-3">
                <div style={{ textAlign: "center" }}>
                  <span
                    className="l-v-s-o-box-t-c"
                    style={{ lineHeight: "110px", fontSize: "90px" }}
                  >
                    {RevenueData?.TotalSalesFormatted === "0"
                      ? 0
                      : RevenueData?.TotalSalesFormatted}
                  </span>
                  {/* <span className="l-v-s-o-box-t-c-lbl  ml-2">Views</span> */}
                </div>
                <div
                  className="l-v-s-o-box-t-c-per"
                  style={{
                    lineHeight: "56px",
                    color: "#5f6165",
                    textAlign: "left",
                    fontSize: "1.5rem",
                    paddingLeft: "306px",
                  }}
                >
                  Sales
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 float-left clearfix">
          <div className="w-50 float-left clearfix mb-2">
            <div className="overview-heading float-left clearfix mt-2" style={{ fontSize: "18px" }}>
              Sales Trends
            </div>
          </div>
          {/* <DateFilter onFilterClick={(days)=>getSalesOnDateFilterClick(days)} selected={salesRevenueFilter}/> */}
        </div>
        <div>
          <div
            className="w-100 float-left clearfix mb-4 chart-container"
            style={{
              border: "none",
              background: "white",
            }}
          >
            {/* {showtext ? (
              <>
                <p
                  style={{
                    textAlign: "center",
                    margin: "10px",
                    color: "#4f4f4f",
                    fontSize: "13px",
                  }}
                >
                  NO DATA
                </p>
              </>
            ) : ( */}
            <MyResponsiveLine />
            {/* )} */}
          </div>
        </div>
        <div className="w-100 float-left clearfix">
          <div className="w-50 float-left clearfix mb-2">
            <div className="overview-heading float-left clearfix mt-2" style={{ fontSize: "18px" }}>
              Engagement Overview
            </div>
          </div>
          <div className="w-100 float-left clearfix l-v-s-o">

            {/* <div className="w-25 float-left clearfix l-v-s-o-box-outer">
              <div
                className="l-v-s-o-box"
                style={{
                  height: "167px",
                  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                  background: "white",
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div className="l-v-s-o-box-t">

                  <div style={{ textAlign: "center" }}>
                    <span
                      className="l-v-s-o-box-t-c"
                      style={{ lineHeight: "85px" }}
                    >
                      {RevenueData?.TotalCustomerEngagedFormatted ? RevenueData?.TotalCustomerEngagedFormatted : 0}
                    </span>
                    <span className="l-v-s-o-box-t-c-lbl  ml-2">Customer Engaged</span>
                  </div>

                  <div
                    // className="d-flex"
                    style={{ textAlign: "center" }}
                  >
                    <div
                      className="l-v-s-o-box-t-c-per"
                      style={{ lineHeight: "22px", color: "#5f6165", textAlign: "center" }}
                    >
                      {RevenueData?.PCCustomerEngaged}%{parseInt(RevenueData?.PCCustomerEngaged) > 0 ? (
                        <ArrowDropUpIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowDropDownIcon style={{ color: "red" }} />
                      )}
                    </div>
                  </div>

                  <div
                    // className="d-flex"
                    style={{ textAlign: "center" }}
                  >
                    <div
                      className="l-v-s-o-box-t-c-per"
                      style={{ lineHeight: "22px", color: "#5f6165", textAlign: "center" }}
                    >
                      Customers Engaged
                    </div>
                  </div>
                </div>
              </div>
            </div> */}


            {/* <div className="w-25 float-left clearfix l-v-s-o-box-outer">
              <div
                className="l-v-s-o-box"
                style={{
                  height: "167px",
                  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                  background: "white",
                  display:'flex',
                  justifyContent:'center',
                  alignItems:'center'
                }}
              >
                <div className="l-v-s-o-box-t  pt-3">

                  <div style={{ textAlign: "center" }}>
                    <span
                      className="l-v-s-o-box-t-c"
                      style={{ lineHeight: "85px" }}
                    >
                      {RevenueData?.TotalViewsFormatted === "0"
                        ? 0
                        : RevenueData?.TotalViewsFormatted}
                    </span>
                    
                  </div>
                  <div
                    // className="d-flex"
                    style={{ textAlign: "center" }}
                  >
                    <div
                      className="l-v-s-o-box-t-c-per"
                      style={{ lineHeight: "22px", color: "#5f6165", textAlign: "center" }}
                    >
                      {RevenueData?.PCTotalViews}%{parseInt(RevenueData?.PCTotalViews) > 0 ? (
                        <ArrowDropUpIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowDropDownIcon style={{ color: "red" }} />
                      )}
                    </div>
                  </div>
                  <div
                    // className="d-flex"
                    style={{ textAlign: "center" }}
                  >
                    <div
                      className="l-v-s-o-box-t-c-per"
                      style={{ lineHeight: "22px", color: "#5f6165", textAlign: "center" }}
                    >
                      Total View
                    </div>
                  </div>
                </div>
              </div>
            </div> */}



            {/* <div className="w-25 float-left clearfix l-v-s-o-box-outer">
              <div
                className="l-v-s-o-box"
                style={{
                  height: "167px",
                  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                  background: "white",
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div className="l-v-s-o-box-t">

                  <div style={{ textAlign: "center" }}>
                    <span
                      className="l-v-s-o-box-t-c"
                      style={{ lineHeight: "85px" }}
                    >
                      {RevenueData?.TotalAvgOrderSizeFormatted ? RevenueData?.TotalAvgOrderSizeFormatted : 0}
                    </span>
                    <span className="l-v-s-o-box-t-c-lbl  ml-2">Views</span>
                  </div>

                  <div
                    // className="d-flex"
                    style={{ textAlign: "center" }}
                  >
                    <div
                      className="l-v-s-o-box-t-c-per"
                      style={{ lineHeight: "22px", color: "#5f6165", textAlign: "center" }}
                    >
                      {RevenueData?.PCTotalAvgOrderSize}%{parseInt(RevenueData?.PCTotalAvgOrderSize) > 0 ? (
                        <ArrowDropUpIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowDropDownIcon style={{ color: "red" }} />
                      )}
                    </div>
                  </div>

                  <div
                    // className="d-flex"
                    style={{ textAlign: "center" }}
                  >
                    <div
                      className="l-v-s-o-box-t-c-per"
                      style={{ lineHeight: "22px", color: "#5f6165", textAlign: "center" }}
                    >
                      Avg Order Size
                    </div>
                  </div>
                </div>
              </div>
            </div> */}


            {/* <div className="w-25 float-left clearfix l-v-s-o-box-outer">
              <div
                className="l-v-s-o-box"
                style={{
                  height: "167px",
                  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                  background: "white",
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div className="l-v-s-o-box-t">

                  <div style={{ textAlign: "center" }}>
                    <span
                      className="l-v-s-o-box-t-c"
                      style={{ lineHeight: "85px" }}
                    >
                      {RevenueData?.TotalAvgOrderPerDayFormatted ? RevenueData?.TotalAvgOrderPerDayFormatted : 0}
                    </span>
                    <span className="l-v-s-o-box-t-c-lbl  ml-2">Orders</span>
                  </div>

                  <div
                    // className="d-flex"
                    style={{ textAlign: "center" }}
                  >
                    <div
                      className="l-v-s-o-box-t-c-per"
                      style={{ lineHeight: "22px", color: "#5f6165", textAlign: "center" }}
                    >
                      {RevenueData?.PCTotalAvgOrderPerDay}%{parseInt(RevenueData?.PCTotalAvgOrderPerDay) > 0 ? (
                        <ArrowDropUpIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowDropDownIcon style={{ color: "red" }} />
                      )}
                    </div>
                  </div>

                  <div
                    // className="d-flex"
                    style={{ textAlign: "center" }}
                  >
                    <div
                      className="l-v-s-o-box-t-c-per"
                      style={{ lineHeight: "22px", color: "#5f6165", textAlign: "center" }}
                    >
                      Avg Order/day
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* ----------------------------- */}

            <div className="w-25 float-left clearfix l-v-s-o-box-outer">
              <div
                className="l-v-s-o-box"
                style={{
                  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                  background: "white",
                  height: "200px",
                }}
              >
                <div className="l-v-s-o-box-t pt-3">
                  <div
                    className="l-v-s-o-box-t-h"
                    style={{ fontSize: "14px", textAlign: "center" }}
                  >
                    Total Customer Engaged
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <span
                      className="l-v-s-o-box-t-c"
                      style={{ lineHeight: "70px" }}
                    >
                      {RevenueData?.TotalCustomerEngagedFormatted === 0
                        ? 0
                        : RevenueData?.TotalCustomerEngagedFormatted}
                    </span>
                    <span className="l-v-s-o-box-t-c-lbl  ml-2">Customers</span>
                  </div>
                  <div>
                    <div
                      className="d-flex"
                      style={{ textAlign: "center", paddingLeft: "100px" }}
                    >
                      <div
                        className="l-v-s-o-box-t-c-per"
                        style={{ lineHeight: "22px", color: "#5f6165" }}
                      >
                        {RevenueData?.PCCustomerEngaged}%
                      </div>
                      <div className="l-v-s-o-box-t-c-per-lbl">
                        {parseInt(RevenueData?.PCCustomerEngaged) > 0 ? (
                          <ArrowDropUpIcon style={{ color: "green" }} />
                        ) : (
                          <ArrowDropDownIcon style={{ color: "red" }} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="l-v-s-o-box-b">
                  <hr />
                  <p
                    style={{
                      fontSize: "10px",
                      color: "#5f6165",
                      padding: "0px 7px",
                    }}
                  >
                    Number of Customers who interacted with Blaash Content.
                  </p>
                </div>
              </div>
            </div>

            <div className="w-25 float-left clearfix l-v-s-o-box-outer">
              <div
                className="l-v-s-o-box"
                style={{
                  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                  background: "white",
                  height: "200px",
                }}
              >
                <div className="l-v-s-o-box-t pt-3">
                  <div
                    className="l-v-s-o-box-t-h"
                    style={{ fontSize: "14px", textAlign: "center" }}
                  >
                    Average Number Of Orders
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <span
                      className="l-v-s-o-box-t-c"
                      style={{ lineHeight: "70px" }}
                    >
                      {RevenueData?.TotalAvgOrderPerDayFormatted === 0
                        ? 0
                        : RevenueData?.TotalAvgOrderPerDayFormatted}
                    </span>
                    <span className="l-v-s-o-box-t-c-lbl  ml-2">Orders</span>
                  </div>
                  <div>
                    <div
                      className="d-flex"
                      style={{ textAlign: "center", paddingLeft: "100px" }}
                    >
                      <div
                        className="l-v-s-o-box-t-c-per"
                        style={{ lineHeight: "22px", color: "#5f6165" }}
                      >
                        {RevenueData?.PCTotalAvgOrderPerDay}%
                      </div>
                      <div className="l-v-s-o-box-t-c-per-lbl">
                        {parseInt(RevenueData?.PCTotalAvgOrderPerDay) > 0 ? (
                          <ArrowDropUpIcon style={{ color: "green" }} />
                        ) : (
                          <ArrowDropDownIcon style={{ color: "red" }} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="l-v-s-o-box-b">
                  <hr />
                  <p
                    style={{
                      fontSize: "10px",
                      color: "#5f6165",
                      padding: "0px 7px",
                    }}
                  >
                    Average number of Checkouts per day.
                  </p>
                </div>
              </div>
            </div>

            <div className="w-25 float-left clearfix l-v-s-o-box-outer">
              <div
                className="l-v-s-o-box"
                style={{
                  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                  background: "white",
                  height: "200px",
                }}
              >
                <div className="l-v-s-o-box-t pt-3">
                  <div
                    className="l-v-s-o-box-t-h"
                    style={{ fontSize: "14px", textAlign: "center" }}
                  >
                    Average Order Value
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <span
                      className="l-v-s-o-box-t-c"
                      style={{ lineHeight: "70px" }}
                    >
                      {RevenueData?.TotalAvgOrderSizeFormatted === 0
                        ? 0
                        : RevenueData?.TotalAvgOrderSizeFormatted}
                    </span>
                    {/* <span className="l-v-s-o-box-t-c-lbl  ml-2">Orders</span> */}
                  </div>
                  <div>
                    <div
                      className="d-flex"
                      style={{ textAlign: "center", paddingLeft: "100px" }}
                    >
                      <div
                        className="l-v-s-o-box-t-c-per"
                        style={{ lineHeight: "22px", color: "#5f6165" }}
                      >
                        {RevenueData?.PCTotalAvgOrderSize}%
                      </div>
                      <div className="l-v-s-o-box-t-c-per-lbl">
                        {parseInt(RevenueData?.PCTotalAvgOrderSize) > 0 ? (
                          <ArrowDropUpIcon style={{ color: "green" }} />
                        ) : (
                          <ArrowDropDownIcon style={{ color: "red" }} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="l-v-s-o-box-b">
                  <hr />
                  <p
                    style={{
                      fontSize: "10px",
                      color: "#5f6165",
                      padding: "0px 7px",
                    }}
                  >
                    Average order value per day.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* <div className="subHeaderMv12">
          <div className="w-50 float-left clearfix mb-2">
            <div className="overview-heading float-left clearfix mt-2" style={{ fontSize: "18px" }}>
              Trending
            </div>
          </div>

          <div className="w-30" style={{ display: "flex" }}>
            <div className="w-33">
              <div className="overview-heading2 float-left clearfix mt-2">
                Filter By
              </div>
            </div>
            <div className="w-67">
              <Select
                options={playlistCollection}
                // isDisabled={obj.rewardType?.value!==2}
                name="rewardName"
                onChange={(e) => onPlaylistChange(e)}
                value={selectedPlaylist}
                //defaultValue= {selectedPlaylist}
                style={{ lineHeight: "28px" }}
                maxMenuHeight={150}
              />
            </div>
          </div>
        </div> */}
        {/* <div>
          <div
            className="w-100 float-left clearfix mb-4 chart-container"
            style={{
              border: "none",
              background: "white",
            }}
          >
            <MyResponsiveBar />
          
          </div>
        </div> */}
        {/* <div>
          <div
            className="overview-heading float-left clearfix mt-2 "
            style={{ margin: "30px 0 10px", fontSize: "18px" }}
          >
            Top 5 Stories
          </div>
          <div
            style={{ display: "flex", gap: "20px" }}
            className="float-left clearfix"
          >
            {TopSalesData?.map((TopSales) => (
              <div>
                <img
                  src={TopSales?.TrendURL}
                  // src="/image6.jpg"
                  alt=""
                  width={200}
                  style={{
                    // borderRadius: "15px"
                    borderTopRightRadius: "15px",
                    borderTopLeftRadius: "15px"
                  }}
                  height={156}
                />
                <div
                  style={{
                    background: "#d3d3d3f0",
                    // borderRadius: "10px",
                    padding: "9px",
                    width: "100%",
                    margin: "0px auto 0",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    fontSize: "12px",
                    color: "black",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: '15px',
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Total Sales</div>
                    <div>{TopSales?.TotalSales}</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>Name</div>
                    <div>{TopSales?.TrendName} </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Revenue;
