import React, { useCallback, useState, useEffect, Fragment } from "react";
import Button from "@material-ui/core/Button";
import DescriptionIcon from "@material-ui/icons/Description";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Dropzone, { useDropzone } from "react-dropzone";
import "./story.Style.css";
import "react-notifications/lib/notifications.css";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SearchIcon from "@material-ui/icons//Search";
import Select from "react-select";
import { NotificationContainer } from "react-notifications";
import {
  Textarea,
  InputBase,
  InputLabel,
  TextareaAutosize,
  IconButton,
  ButtonBase,
  Typography,
} from "@material-ui/core";
import ReactPlayer from "react-player";
import DatePicker from "react-datepicker";
import Usestoryhook from "./Usestoryhook";
import VideoLibrary_Description from "./VideoLibrary_Description";
import { CustomeDatePickerENGT } from "../common/global";
import Modal from "react-responsive-modal";

let steps = [1, 2];

function ScheduleLive() {
  const {
    activeStep,
    pageType,
    discription,
    isMulti,
    fileObj,
    thumbnailUrl,
    thumbnailContent,
    setLiveSchedule,
    liveSchedule,
    storyFlage,
    inputMsg,
    productDetail,
    PostContent,
    activeDetailsFilter,
    activeProductFilter,
    onDrop,
    setProductDetail,
    setInputMsg,
    setActiveDetailsFilter,
    setActiveProductFilter,
    fatchProductDetail,
    setActiveStep,
    setPageType,
    setDiscription,
    isStepOptional,
    isStepSkipped,
    handleNext,
    handleBack,
    handleSkip,
    handleReset,
    contentType,
    removeAll,
    removeAll2,
    newFileUploadThumbnail,
    changeTitle,
    handleLiveNext,
    updateProductIds,
    liveCode,
    setLiveCode,
    prodIds
  } = Usestoryhook(steps);
  const [currentThumbNailUrl, setCurrentThumbNailUrl] = useState("");
  const [currentUrl, setCurrentUrl] = useState("");
  const [hour, setHour] = useState([]);
  const [minets, setMinets] = useState([]);
  const [bookingDate, setBookingDate] = useState(new Date());
  const [selectedHour, setSelectedHour] = useState({
    value: 0,
    label: 0,
  });
  const [selectedMin, setSelectedMin] = useState({
    value: 0,
    label: 0,
  });
  const formDateTimeString = (data, type) => {
    //console.log("eventData===", data);
    if (type == 1) setBookingDate(data);
    if (type == 2) setSelectedHour(data);
    if (type == 3) setSelectedMin(data);
    const selectedDate = type == 1 ? data : bookingDate;
    const selectedHours = type == 2 ? data?.value : selectedHour?.value;
    const selectedMins = type == 3 ? data?.value : selectedMin?.value;
    customTimeString(selectedDate, selectedHours, selectedMins);
  };

  const customTimeString = (date, h, min) => {
    let slectedDate = new Date(date);
    let selectedDateTime = new Date(
      slectedDate.getFullYear(),
      slectedDate.getMonth(),
      slectedDate.getDate(),
      h,
      min
    ).getTime();
    console.log("Converteddate =====&&&", selectedDateTime);
    setDiscription({
      ...discription,
      liveDateTime: selectedDateTime,
    });
  };

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, inputRef } =
    useDropzone({ onDrop });
  const handleNextStep = (contentType) => {
    setDiscription({ ...discription, contentType: contentType });
    setLiveSchedule(true);
    handleLiveNext();
  };
  useEffect(() => {
    if (thumbnailUrl.length > 0) {
      const file = thumbnailUrl[0].file;
      const reader = new FileReader();
      reader.onload = (event) => {
        const dataURL = event.target.result;
        setCurrentThumbNailUrl(dataURL);
      };
      reader.readAsDataURL(file);
    }
  }, [thumbnailUrl]);

  useEffect(() => {
    // getMasterCategories();
    timeInHour();
    timeInMin();
    customTimeString(bookingDate, selectedHour?.value, selectedMin?.value);
  }, []);


  const timeInHour = () => {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      let obj = {
        value: i,
        label: i,
      };
      hours.push(obj);
    }
    setHour(hours);
  };

  const timeInMin = () => {
    const min = [];
    for (let i = 0; i < 60; i++) {
      let obj = {
        value: i,
        label: i,
      };
      min.push(obj);
    }
    setMinets(min);
  };

  const GetFileSize = (totalBytes) => {
    if (totalBytes < 1000000) {
      var FileSize = Math.floor(totalBytes / 1000) + ' KB';
      console.log("FileSize", FileSize);
      return FileSize
    } else {
      var FileSize = Math.floor(totalBytes / 1000000) + ' MB';
      console.log("FileSize", FileSize);
      return FileSize
    }
  }
  return (
    <div>
      <NotificationContainer />
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "15px 0",
          width: "70%",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          {steps.map((step, idx) => (
            <>
              {idx !== 0 && (
                <hr
                  style={{
                    display: "block",
                    // height: "4px",
                    width: "40px",
                    border: 0,
                    // borderWidth: "8px",
                    borderRadius: "0 20px 20px 0",
                    borderTop:
                      idx + 1 <= activeStep
                        ? "6px solid #017EFA"
                        : "6px solid #EFF0F7",
                    // margin: "1em 0",
                    padding: 0,
                  }}
                />
              )}
              <div
                style={{
                  width: "35px",
                  height: "35px",
                  color: idx + 1 <= activeStep ? "white" : "black",
                  margin: " 0 5px",
                  background: idx + 1 <= activeStep ? "#017EFA" : "#EFF0F7",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "10px",
                }}
              >
                {step}
              </div>
              {idx < steps.length - 1 && (
                <hr
                  style={{
                    display: "block",
                    // height: "19px",
                    width: "40px",
                    border: 0,
                    borderTop:
                      idx + 1 <= activeStep
                        ? "6px solid #017EFA"
                        : "6px solid #EFF0F7",
                    borderRadius: "20px 0px 0px 20px",

                    // margin: "1em 0",
                    padding: 0,
                  }}
                />
              )}
            </>
          ))}
        </div>
      </div>
      {activeStep === 1 && (
        <div style={{ display: "flex", gap: "30px", height: "73vh" }}>
          <div
            style={{
              width: "70%",
              background: "#FFFFFF",
              boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
              borderRadius: "16px",
            }}
          >
            <div
              style={{
                padding: "20px",
              }}
            >
              <h3 style={{ fontSize: "16px", marginBottom: "30px" }}>
                Upload Thumbnail Image
              </h3>
              <Dropzone
                onDrop={(acceptedFiles) => newFileUploadThumbnail(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="uplodeDiv">
                    <input {...getInputProps()} />
                    <CloudUploadIcon
                      className="uplodeimg"
                      style={{ fontSize: "3rem" }}
                    />
                    {thumbnailUrl?.length > 0 && (
                      <p>{thumbnailUrl?.length} file is selected for upload</p>
                    )}

                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <>
                        <p style={{ margin: 0, fontSize: "14px" }}>
                          Select files or Drag 'n' drop some files here
                        </p>
                        <p
                          style={{
                            color: "rgba(0, 0, 0, 0.4)",
                            margin: "0 0 15px",
                            fontSize: "14px",
                          }}
                        >
                          Video file,file size no more than 10mb
                        </p>
                        {/* <button></button> */}
                        <Button
                          variant="contained"
                          // color="#F2F9FF"
                          style={{
                            background: "#F2F9FF",
                            border: "1px solid #57B2E0",
                            color: "#017EFA",
                            fontSize: "12px",
                            borderRadius: "9px",
                          }}
                        >
                          SELECT FILE
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </Dropzone>

              <p
                style={{
                  textAlign: "center",
                  margin: "30px 0",
                  fontSize: "14px",
                }}
              >
                What are you creating today.....
              </p>
              {thumbnailUrl?.length > 0 && (
                <div>
                  <h5 style={{ fontSize: "16px" }}>File added</h5>
                  <div
                    style={{
                      display: "flex",
                      margin: "3px 0px",
                      // width: "100%",
                      // justifyContent: "space-between",
                    }}
                  >
                    <DescriptionIcon
                      style={{ color: "#017EFA", fontSize: "35px" }}
                    />
                    <div
                      style={{
                        width: "100%",
                        borderBottom: "3px solid #017EFA",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "12px",
                          margin: "0",
                          color: "rgba(0, 0, 0, 0.4)",
                        }}
                      >
                        {thumbnailUrl[0]?.file?.name}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <DeleteOutlineIcon
                          style={{
                            color: "rgba(255, 78, 78, 1)",
                            fontSize: "18px",
                          }}
                        />
                        <p
                          style={{
                            fontSize: "12px",
                            margin: "0",
                            color: "rgba(0, 0, 0, 0.4)",
                          }}
                        >
                          {GetFileSize(thumbnailUrl[0]?.file?.size)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <Button
                    style={{
                      color: "white",
                      background: "#017EFA",
                      padding: "5px 15px",
                      borderRadius: "9px",
                      fontSize: "12px",
                      float: "right",
                    }}
                    onClick={() => handleNextStep(5)}
                  >
                    NEXT STEP

                  </Button>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              background: "#FFFFFF",
              boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
              borderRadius: "16px",
              width: "30%",
              padding: "10px",
              display: "flex",
              alignItems: "end",
            }}
          >
            {thumbnailUrl.length > 0 ? (
              // <ReactPlayer url={currentUrl} controls={true} height={"100%"} />
              <img
                src={currentThumbNailUrl}
                height={"100%"}
                width={"100%"}
                style={{ borderRadius: "10px", objectFit: "cover", }}
              />
            ) : (
              <>
                <h4
                  style={{
                    // textOrientation: "sideways",
                    writingMode: "vertical-rl	",
                    margin: "0 0 20px",
                    transform: "rotate(-180deg)",
                    color: "#4F4F4F",
                    // height: "90%",
                    fontSize: "40px",
                  }}
                >
                  Preview
                </h4>
                <div
                  style={{
                    height: "90%",
                    border: "1px dashed #017efa",
                    margin: "auto",
                    borderRadius: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "70%",
                  }}
                >
                  <p
                    style={{
                      padding: "10px",
                      color: "rgba(0, 0, 0, 0.4)",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    Uploaded <span style={{ color: "black" }}>Photo</span> will
                    be shown here
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {activeStep === 2 && (
        <div style={{ display: "flex", gap: "30px", height: "73vh" }}>
          <div
            style={{
              width: "70%",
              background: "#FFFFFF",
              boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
              borderRadius: "16px",
            }}
          >
            <div
              style={{
                padding: "20px",
              }}
            >
              <h3 style={{ fontSize: "16px", marginBottom: "30px" }}>
                Fill Details
              </h3>
              <div>
                <div style={{ marginBottom: "5px" }}>
                  <InputLabel style={{ fontSize: "14px" }}>
                    Live Stream Title
                  </InputLabel>
                  <InputBase
                    placeholder="Ex. blaash"
                    style={{
                      border: "1px solid #F0F0F0",
                      padding: "0 10px",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                    value={discription.thumbnailTitle}
                    onChange={(e) =>
                      setDiscription({
                        ...discription,
                        thumbnailTitle: e.target.value,
                      })
                    }
                  />
                </div>
                <div style={{ marginBottom: "5px" }}>
                  <InputLabel style={{ fontSize: "14px" }}>Summary</InputLabel>

                  <TextareaAutosize
                    minRows={2.5}
                    placeholder="Summary"
                    style={{
                      border: "1px solid #F0F0F0",
                      width: "100%",
                      padding: "5px 10px",
                      borderRadius: "10px",
                    }}
                    value={discription.discription}
                    onChange={(e) =>
                      setDiscription({
                        ...discription,
                        discription: e.target.value,
                      })
                    }
                  />
                </div>
                {/* <div style={{ marginBottom: "5px" }}>
                  <InputLabel style={{ fontSize: "14px" }}>
                    Category (maximum 20)
                  </InputLabel>

                  <TextareaAutosize
                    minRows={2.5}
                    placeholder="Separate by enter"
                    style={{
                      border: "1px solid #F0F0F0",
                      width: "100%",
                      padding: "5px 10px",
                      borderRadius: "10px",
                    }}
                  />
                </div> */}
                <div style={{ marginBottom: "5px" }}>
                  <InputLabel style={{ fontSize: "14px" }}>
                    Influencer
                  </InputLabel>

                  <TextareaAutosize
                    minRows={2.5}
                    placeholder="Separate by enter"
                    style={{
                      border: "1px solid #F0F0F0",
                      padding: "5px 10px",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div style={{display:'flex', flexDirection:'row'}}>
                    <input
                      type="checkbox"
                      checked={isMulti}
                      onChange={(e) => 
                        setDiscription({
                          ...discription,
                          isMulti: e.target.checked,
                        })
                      }
                    />
                    <p style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '14px', marginLeft:'5px' }}>Is Multi-Host</p>
                  </div>
                  <DatePicker
                    minDate={new Date()}
                    selected={bookingDate}
                    customInput={<CustomeDatePickerENGT />}
                    onChange={(date) => formDateTimeString(date, 1)}
                  />
                  <>
                    <Select
                      style={{ marginLeft: "30px", width: "25%" }}
                      options={hour}
                      value={selectedHour}
                      onChange={(date) => formDateTimeString(date, 2)}

                    />
                    <spam>HH</spam>
                    <Select
                      options={minets}
                      value={selectedMin}
                      onChange={(date) => formDateTimeString(date, 3)}
                      style={{ width: "25%" }}
                    />
                    <spam>MM</spam>
                  </>
                </div>
                {/* <div style={{ marginBottom: "5px" }}>
                  <InputLabel style={{ fontSize: "14px" }}>
                    Tags (maximum 20)
                  </InputLabel> 

                  <TextareaAutosize
                    minRows={2.5}
                    placeholder="Separate by enter"
                    style={{
                      border: "1px solid #F0F0F0",
                      padding: "5px 10px",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </div> */}
              </div>

              {/* <h5 style={{ fontSize: "16px" }}>File added</h5> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 0",
                }}
              >
                <Button
                  style={{
                    color: "#017EFA",
                    background: "#F2F9FF",
                    padding: "5px 15px",
                    border: "1px solid #57B2E0",
                    borderRadius: "9px",
                    fontSize: "12px",
                  }}
                  onClick={() => setActiveStep(1)}
                >
                  PREVIOUS
                </Button>
                <Button
                  style={{
                    color: "#017EFA",
                    background: "#FFF2F2",
                    border: "1px solid rgba(253, 0, 0, 0.5)",
                    padding: "5px 15px",
                    color: "#FA0101",
                    borderRadius: "9px",
                    fontSize: "12px",
                  }}
                >
                  RESET ALL
                </Button>
                <Button
                  style={{
                    color: "white",
                    background: "#017EFA",
                    padding: "5px 15px",
                    borderRadius: "9px",
                    fontSize: "12px",
                  }}
                  onClick={() => handleNextStep(5)}
                >
                  SUBMIT
                </Button>
              </div>
            </div>
          </div>
          <div
            style={{
              background: "#FFFFFF",
              boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
              borderRadius: "16px",
              width: "30%",
              padding: "10px",
              // display: "flex",

              // alignItems: "end",
            }}
          >
            <VideoLibrary_Description
              productDetail={productDetail}
              setProductDetail={setProductDetail}
              productIds={prodIds}
              updateProductIds={updateProductIds}
            />

          </div>
        </div>
      )}
      <>
        <Modal
          open={liveCode != null}
          onClose={() => setLiveCode(null)}
        >
          <div style={{padding: "30px 40px 20px"}}>
            <div>
              <Typography style={{fontWeight: "bold"}}>
                Host Live Code
              </Typography>
            </div>
            <div style={{textAlign:"center"}}>
              <Typography>
                {liveCode}
              </Typography>
            </div>
          </div>
        </Modal>
      </>
    </div>
  );
}

export default ScheduleLive;
