import React, { useState, useEffect, useRef } from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import SendIcon from "@material-ui/icons/Send";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import ProductList from "./ProductList";
import CouponCard from "./CouponCard";
import { IvsClient, PutMetadataCommand } from "@aws-sdk/client-ivs";
import { Input, InputBase } from "@material-ui/core";
import { Profanity, ProfanityOptions } from '@2toad/profanity';
// import { timeout } from "d3";

const useStyles = makeStyles({
  scroll: {
    display: "flex",
    flexDirection: "column",
    //height: "550",
    width: "100%",
    backgroundColor: "white",
    height: "550px",
    marginTop: "20px",
    // justifyContent: window.innerWidth > 400 ? "center" : "start",
    overflowY: "scroll",
    overflowX: "hidden",
    whiteSpace: "nowrap",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
  },
});

function ChatAndProduct({ selectedFeed, chatData, chatChannel }) {
  console.log("Feed Prop$$$$$$", chatData);
  const associatedProduct = selectedFeed?.AssociatedProductList;
  console.log(associatedProduct,"asssssociated product")
  const channelArn = selectedFeed?.ChannelPublishSettings?.ChannelArn;
  const { scroll } = useStyles();
  const [metaData, setMetaData] = useState([]);
  const [active, setActive] = useState("Chat");
  const [inputMsg, setInputMsg] = useState({ msg: "", admin: "false" });
  const { REACT_APP_SECRET_KEY_CHAT, REACT_APP_ACCESS_KEY_CHAT } = process.env;
  const [showEmotion, setShowEmotion] = useState(false);
  const [activeEmotionType, setActiveEmotionType] = useState("sticker");

  const options = new ProfanityOptions();
  options.grawlix = '*****';
  const profanity = new Profanity(options);

  function tabClick(val) {
    setActive(val);
  }

  const updateMetaData = () => {
    setMetaData([...metaData, chatData]);
  };

  const handleResponce = (event) => {
    const obj = {
      msg: profanity.censor(event?.target?.value),
      admin: "true",
    };
    setInputMsg(obj);
    // takeMsgdata(obj);
    // //console.log("event===$$$=", event.target.value);
    //setInputText(event.target.value);
  };

  let ownChatData = {
    customerId: "",
    fullName: "",
    message: "",
  };

  const liveChat = async () => {
    setInputMsg({ msg: "", admin: "false" });

    // ownChatData = {
    //   customerId: 79,
    //   fullName: "Moderator",
    //   message: inputMsg?.msg,
    //   admin: inputMsg?.admin,
    // };

    //console.log(ownChatData, "obj==========");
    // const parsedName = JSON.parse(ownChatData)

    //  setChatData(ownChatData);

    const creds = {
      accessKeyId: REACT_APP_ACCESS_KEY_CHAT,
      secretAccessKey: REACT_APP_SECRET_KEY_CHAT,
    };
    console.log("creds2222====", creds);

    const obj = {
      customerId: 79,
      fullName: "Moderator",
      message: inputMsg?.msg,
      admin: profanity.censor(inputMsg?.admin),
    };

    //const arrs = "arn:aws:ivs:ap-south-1:568375208006:channel/pkl9zZAHq1Xk";
    // const arrs = "arn:aws:ivs:ap-south-1:568375208006:channel/anf9fMa5eNiL";
    const arrs = chatChannel;
    console.log("creds===%%%%%@@@=", arrs);
    const myJSON = JSON.stringify(obj);
    //console.log("myJSON====@@", myJSON);
    const client = new IvsClient({ region: "ap-south-1", credentials: creds });
    const command = new PutMetadataCommand({
      channelArn: arrs,
      metadata: myJSON,
    });

    try {
      //console.log("Ivs COMMAND", command);
      const data = await client.send(command);
      console.log("Ivs data", data);
      // process data.
    } catch (error) {
      // error handling.
      //console.log("Ivs liveChat  error", error);
    } finally {
      // finally.
    }
  };

  useEffect(() => {
    if (chatData) updateMetaData();
  }, [chatData]);

  const messagesEndRef = useRef(null);

  let timeout;
  
  useEffect(()=>{
    clearTimeout(timeout);
    timeout = setTimeout(() =>{
      if(chatData) {
        messagesEndRef.current.scrollIntoView({behavior:"smooth"});
      }
  },1000)
  }, [chatData]);

  return (
    <div
      style={{
        // marginTop: "50px",
        width: "50%",
        background: "#FFFFFF",
        boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
        borderRadius: "16px",
      }}
    >
      <div
        style={{
          width: "100%",
          padding: "20px 10px 10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div
          onClick={() => tabClick("Chat")}
          className={`e-s-tab ${active === "Chat" ? `e-s-tab-active` : ``}`}
        >
          Chat
        </div>
        <div
          onClick={() => tabClick("Product")}
          className={`e-s-tab ${active === "Product" ? `e-s-tab-active` : ``}`}
        >
          Product
        </div>
        <div
          onClick={() => tabClick("Coupon")}
          className={`e-s-tab ${active === "Coupon" ? `e-s-tab-active` : ``}`}
        >
          Coupon
        </div>
      </div>
      {active === "Chat" && (
        <div style={{ position: "relative" }}>
          <div className={scroll}>
            {metaData?.map((data) =>
              !data?.admin ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "15px",
                  }}
                >
                  {/* <Avatar style={{ backgroundColor: deepOrange[500] }}>
                    G
                  </Avatar> */}
                  <Typography
                    style={{
                      color: "#ebb51b",
                      fontSize: "12px",
                      margin: "10px",
                    }}
                  >
                    {data?.fullName}
                  </Typography>
                  <Typography style={{ color: "#6c757d", fontSize: "12px" }}>
                    {data?.message}
                  </Typography>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "15px",
                    justifyContent: "end",
                  }}
                >
                  <Typography
                    style={{
                      color: "black",
                      fontSize: "12px",
                      margin: "10px",
                    }}
                  >
                    {data?.fullName}
                  </Typography>
                  <Typography
                    style={{
                      color: "#6c757d",
                      fontSize: "12px",
                      margin: "10px",
                    }}
                  >
                    {data?.message}
                  </Typography>
                  {/* <Avatar style={{ backgroundColor: deepPurple[500] }}>
                    Ad
                  </Avatar> */}
                </div>
              )
            )}
            <div ref={messagesEndRef}></div>
          </div>
          {showEmotion && (
            <div
              style={{
                display: "flex",
                gap: "17px",
                position: "absolute",
                width: "224px",
                height: "160px",
                background: "rgba(255, 255, 255, 0.6)",
                borderRadius: "10px",
                padding: "4px",
                boxShadow:
                  "0px 2.63px 25.154px rgba(0, 0, 0, 0.05), inset 0px 0px 0.931631px rgba(255, 255, 255, 0.9)",
                // backdropFilter: "blur(45.1567px)",
                left: 0,
                border: "1px solid #F2F2F2",
                bottom: "65px",
              }}
            >
              <ul
                style={{
                  display: "flex",
                  fontSize: "13px",
                  alignItems: "center",
                  justifyContent: "center",
                  listStyle: "none",
                  padding: "0px",
                  borderRadius: "4px",
                  margin: "0 auto",
                  background: "white",
                  height: "max-content",
                }}
              >
                <li
                  onClick={() => setActiveEmotionType("sticker")}
                  style={{
                    padding: "2px 6px",
                    backgroundColor:
                      activeEmotionType === "sticker" ? "black" : "white",
                    color: activeEmotionType === "sticker" ? "white" : "black",
                    borderRadius: "4px",
                  }}
                >
                  Sticker
                </li>
                <li
                  onClick={() => setActiveEmotionType("emoji")}
                  style={{
                    padding: "2px 6px",

                    backgroundColor:
                      activeEmotionType === "emoji" ? "black" : "white",
                    color: activeEmotionType === "emoji" ? "white" : "black",
                    borderRadius: "4px",
                  }}
                >
                  Emoji
                </li>
              </ul>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              // margin: "0px 5px 0px 8px",
              width: "100%",
              overflow: "hidden",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: " 0 0 16px 16px",
            }}
          >
            <IconButton onClick={() => setShowEmotion(!showEmotion)}>
              <TagFacesIcon
                style={{
                  fontSize: "25px",
                  color: "#6c757d",
                  padding: "0px",
                }}
              />
            </IconButton>
            <InputBase
              // id="myInput"
              value={inputMsg?.msg}
              // color="primery"
              placeholder="Please enter text"
              style={{
                borderRadius: "10px ",
                background: "#F2F2F2",
                minWidth: "75%",
                borderColor: "#F2F2F2",
                height: "35px",
                padding: "0 5px",
                border: "0",
                outline: "none",
              }}
              onChange={(event) => handleResponce(event)}
              // inputProps={{
              //   style: { color: "#6c757d", border: "none", outline: "none" },
              // }}
            />
            <IconButton onClick={() => liveChat()}>
              <SendIcon
                style={{
                  // color: "rgb(247 16 11)",
                  fontSize: "25px",
                  color: "#6c757d",
                  // transform: "rotate(-36deg)",
                  // marginBottom: "5px",
                }}
              />
            </IconButton>
          </div>
        </div>
      )}
      {active === "Product" && (
        <ProductList associatedProduct={associatedProduct} />
      )}
      {active === "Coupon" && <CouponCard selectedFeed={selectedFeed} />}
    </div>
  );
}

export default ChatAndProduct;
