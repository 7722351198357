// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import Segments from '../../components/segments/segments';
// import * as actionsHandler from '../../actions/segments/segmentsActionHandler';

// const mapStateToProps = state => ({
//     segmenstList: state.SegmentsReducer.segmenstList,
    
// });

// const mapDispatchToProps = dispatch => ({ 
//     segmentsActionHandler : bindActionCreators(actionsHandler, dispatch)
// });

// const SegmentsContatiner = connect(mapStateToProps, mapDispatchToProps)(Segments);
// export default SegmentsContatiner;


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Segment from '../../components/segments/Segment';
import * as actionsHandler from '../../actions/engagements/journey/journeyActionHandler';
import * as routeActionHandler from '../../actions/route/routeActionHandler';

const mapStateToProps = state => ({
    allJourneysData: state.EngagementsJourneyReducer.allJourneysData,
    allJourneyTasks: state.EngagementsJourneyReducer.allJourneyTasks
});

const mapDispatchToProps = dispatch => ({
    engagementsJourneyActionHandler: bindActionCreators(actionsHandler, dispatch),
    routeActionHandler: bindActionCreators(routeActionHandler, dispatch)
});

const EngagementsJourneyContatiner = connect(mapStateToProps, mapDispatchToProps)(Segment);
export default EngagementsJourneyContatiner;
