import {
  ACTIVE_CUSTOMER_COUNTS,
  EVENT_LIST_COUNT_FOR_LAST_X_HOURES,
  GROSS_VALUE_CUSTOMER_COUNT,
  VIDEO_WATCHED_DURATION,
} from "../../constants/actionTypes";

export function dispatchActiveCustomerCount(data) {
  return (dispatch) => {
    dispatch({
      type: ACTIVE_CUSTOMER_COUNTS,
      payload: data,
    });
  };
}
export function dispatchEventListForLastXHoures(data) {
  return (dispatch) => {
    dispatch({
      type: EVENT_LIST_COUNT_FOR_LAST_X_HOURES,
      payload: data,
    });
  };
}
export function dispatchGrossValueData(data) {
  return (dispatch) => {
    dispatch({
      type: GROSS_VALUE_CUSTOMER_COUNT,
      payload: data,
    });
  };
}

export function dispatchVideoWatched(data) {
  return (dispatch) => {
    dispatch({
      type: VIDEO_WATCHED_DURATION,
      payload: data,
    });
  };
}
