import React, {useCallback,useState, useEffect} from 'react'
import {useDropzone} from 'react-dropzone'
import "./story.Style.css"
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {useSelector} from 'react-redux'
import {ENGT_PROD_BASE_URI, PREE_SIGNED_URL,SOMETHING_WENT_WRONG} from "../../api/apiConstants"
import {postAuthAndData} from "../../api/ApiHelper"
import {useHistory} from 'react-router'
import createNotification from '../common/reactNotification'

function ContentUplode() {
  const [fileObj, setFileObj] = useState([])
  const history = useHistory()
  const userData = useSelector((select)=>select.userReducer.userData)
  const onDrop = useCallback(acceptedFiles => {
    console.log("onDrop===",acceptedFiles)
    acceptedFiles?.forEach((file, index)=>{
      const obj = {
        extension : file.name.split('.').pop(),
        contentType: file.name.split('.').pop() == "mp4" ?"2":"1",
        FileId :index+1
      }
      fileObj.push(obj)
    })
   console.log("onDrop==55=@@",acceptedFiles[0].name.split('.').pop())
   fatchPreUrlData()

  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
console.log("fileObj=======##23",fileObj)

const handleResponseCode=(resp)=>{
  if(!resp || resp.code===-1){
      createNotification('error',SOMETHING_WENT_WRONG+ 'in Target Audience');
      return false;
  }else{
      return true;
  }
}

const fatchPreUrlData = () =>{
  const body = {
    userId:48,
    files:fileObj
  }
  console.log("anansfd  flajflk")
  postAuthAndData(`${ENGT_PROD_BASE_URI}${PREE_SIGNED_URL}`,body,history)
  .then(res=>{
      if(handleResponseCode(res)){
       console.log("resres===" ,res)
      }
 
    })
}

  console.log("userData======", userData)

  return (
    <div {...getRootProps()} className ="uplodeDiv" >
      <input {...getInputProps()} />
       <CloudUploadIcon className = "uplodeimg" style= {{fontSize: "5rem"}}/>
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>
      }
    </div>
  )
    }

export default ContentUplode
