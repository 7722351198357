import React, { useCallback, useState, useEffect, Fragment } from "react";
import ContentUplode from "./ContentUplode";
import DiscriptionStudio from "./DiscriptionStudio";
import StodioCategory from "./StodioCategory";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useDropzone } from "react-dropzone";
import "./story.Style.css";
import "react-notifications/lib/notifications.css";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { updateUserDataObj } from "../../actions/login/updateUserDataObj";
import {
  ENGT_PROD_BASE_URI,
  PREE_SIGNED_URL,
  SOMETHING_WENT_WRONG,
  SAVE_POST_DATA,
  IDTY_PROD_HOST_URI,
  USER_DATA_GROUP_PERMISSIONS,
} from "../../api/apiConstants";
import { postAuthAndData, getAuthAndData } from "../../api/ApiHelper";
import { useHistory } from "react-router";
import createNotification from "../common/reactNotification";
import { NotificationContainer } from "react-notifications";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";
import { useDispatch } from "react-redux";
import store from "../../store/store";
import Dropzone from "react-dropzone";
import AlertDialogBox from "../common/alertBox/AlertDialogBox";
import { Auth } from "aws-amplify";
import { filter } from "lodash";
import StudioComponenets_Description from "./StudioComponenets_Description";
let presignedUploadUrlArr = [];
let preAssignthumbnailArr = [];
let isUploadToAWSSuccess = true;
let productIds = [];
const steps = ["Add images & videos", "Publish"];
let userData;

function UploadStudioComponents() {
  ////console("StodioComponent=============$$$");
  userData = useSelector((select) => select.userReducer.userData);
  //var userData1 = store.getState().LoginReducer.userData;
  //console.log("StodioComponent=============$$$", userData);
  // let isUploadToAWSSuccess = true;
  const [storyFlage, setStoryFlage] = useState(false);
  const [liveSchedule, setLiveSchedule] = useState(false);
  const [pageType, setPageType] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [fileObj, setFileObj] = useState([]);
  const [thumbnailUrl, setThumbnailUrl] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [PostContent, setPostContent] = useState([]);
  const [productDetail, setProductDetail] = useState([]);
  const [thumbnailContent, setThumbnailContent] = useState([]);
  const [discription, setDiscription] = useState({
    tag: "",
    discription: "",
    targetCatogery: "",
    caption: "",
    fontFamily: "",
    ColorPicker: "",
    buttonType: 0,
    contentType: 0,
    productId: "",
    thumbnailTitle: "",
    liveDateTime: "",
    videoDuration: 0,
  });
  const [generatedCodeObj, setgeneratedCodeObj] = useState({
    open: false,
    title: "",
    text1: "",
    text2: "",
    handleClose: () => {},
  });

  //////console("userData==1=", userData);

  const [flag, setFlag] = useState([]);
  const [flag2, setFlag2] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      isUploadToAWSSuccess = true;
      setPostContent([]);
      setThumbnailContent([]);
      setFileObj([]);
      setThumbnailUrl([]);
      const filterObjArr = [];
      acceptedFiles?.forEach((file, index) => {
        ////console("file_extention===", file.name.split(".").pop());
        let extensionType = file.name.split(".").pop().toLowerCase();
        if (
          extensionType == "jpeg" ||
          extensionType == "jpg" ||
          extensionType == "mp4" ||
          extensionType == "png" ||
          extensionType == "webp"
        ) {
          if (
            (extensionType == "mp4" && file.size < 10000000) || //Video Size max is 5 MB
            file.size < 5000000
          ) {
            const obj = {
              extension: file.name.split(".").pop(),
              contentType: extensionType == "mp4" ? "2" : "1",
              file: file,
              FileId: ` ${Date.now()}_${file.name}`,
            };
            filterObjArr.push(obj);
            ////console.log("File Object====",obj);
          } else {
            createNotification(
              "warning",
              "Image/Video size should be less than 10 MB."
            );
          }
        } else {
          createNotification(
            "warning",
            "Only jpeg, jpg, png, mp4  webp file type is allowed"
          );
        }
      });
      setFileObj(filterObjArr);
      fatchPreUrlData(filterObjArr);
    },
    [discription]
  );

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, inputRef } =
    useDropzone({ onDrop });

  const handleResponseCode = (resp) => {
    if (!resp || resp.code === -1) {
      createNotification(
        "error",
        SOMETHING_WENT_WRONG + ", Please try again later."
      );
      handleLoader(false);

      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    handleLoader(false);
    updateAndReturnUserDAta();
    // getuserId_local();
  }, []);

  const copyGeneratedCode = (Playlist) => {
    //console.log("======Playlist", Playlist)
    setgeneratedCodeObj({
      open: true,
      title: "Code Copied!!",
      text1: "Live Activation Code",
      text2: Playlist,
      handleClose: (bool) => {
        setgeneratedCodeObj({
          ...generatedCodeObj,
          open: false,
          handleClose: () => {},
        });
      },
    });
    navigator.clipboard.writeText(Playlist);
  };

  // useEffect(() => {
  //   if (userData?.UserID && fileObj?.length > 0) {
  //     fatchPreUrlData();
  //   } else {
  //     fileObj?.length > 0 &&
  //       createNotification(
  //         "warning",
  //         "Something went wrong, please try again."
  //       );
  //   }
  // }, [fileObj]);

  // useEffect(() => {
  //   if (userData?.UserID && thumbnailUrl?.length > 0) {
  //     fetchPreUrlDataThumblail();
  //   } else {
  //     thumbnailUrl?.length > 0 &&
  //       createNotification(
  //         "warning",
  //         "Something went wrong, please try again."
  //       );
  //   }
  // }, [thumbnailUrl]);

  const handleLoader = (showBool) => {
    dispatch(dispatchLoaderData(showBool));
  };

  const fatchPreUrlData = (filterObjArr) => {
    try {
      if (!userData?.UserID) {
        console.log("not found")
        Auth.signOut();
        history.push("/login");
      }
      presignedUploadUrlArr = [];

      setPostContent([]);
      handleLoader(true);
      const body = {
        userId: userData?.UserID,
        files: filterObjArr,
      };

      //debugger;
      postAuthAndData(
        `${ENGT_PROD_BASE_URI}${PREE_SIGNED_URL}`,
        body,
        history
      ).then((res) => {
        if (handleResponseCode(res)) {
          handleLoader(false);
          res?.data.forEach((e) => presignedUploadUrlArr.push(e));
          if (discription.contentType == 2) {
            getDuration(filterObjArr[0].file);
            setPostContent(
              res?.data.map((e) => ({
                fileName: e.FileKey,
                fileType: e.FileExtenssion.toLowerCase() == "mp4" ? 2 : 1,
                fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
              }))
            );
          } else {
            setPostContent(
              res?.data.map((e) => ({
                fileName: e.FileKey,
                fileType: e.FileExtenssion.toLowerCase() == "mp4" ? 2 : 1,
                fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
              }))
            );
          }
        }
      });
    } catch {}
  };

  function getDuration(file) {
    var video = document.createElement("video");
    video.src = URL.createObjectURL(file);
    video.preload = "metadata";
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      setDiscription({ ...discription, videoDuration: video.duration });
      console.log("Video Duration==>", video.duration);
      return video.duration;
      // setVideoDuration(video.duration);
    };
    // video.src  = file
  }

  const fetchPreUrlDataThumblail = (filterObjArr) => {
    try {
      if (!userData?.UserID) {
        Auth.signOut();
        history.push("/login");
      }

      preAssignthumbnailArr = [];
      handleLoader(true);
      const body = {
        userId: userData?.UserID,
        files: filterObjArr,
      };

      postAuthAndData(
        `${ENGT_PROD_BASE_URI}${PREE_SIGNED_URL}`,
        body,
        history
      ).then((res) => {
        if (handleResponseCode(res)) {
          handleLoader(false);
          res?.data.forEach((e) => preAssignthumbnailArr.push(e));

          setThumbnailContent(
            res?.data.map((e) => ({
              fileName: e.FileKey,
              fileType: e.FileExtenssion.toLowerCase() == "mp4" ? 2 : 1,
              fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
            }))
          );
        }
      });
    } catch {
      handleLoader(false);
    }
  };

  const updateAndReturnUserDAta = () => {
    //console.log("userData%%%%========",userData)
    if (!userData) {
      //console.log("userData%%%%========111",userData)
      Auth.currentSession()
        .then((session) => {
          var payLoad = session.idToken.payload;
          //console.log("payLoadpayLoad234=======",payLoad)
          getAuthAndData(
            `${IDTY_PROD_HOST_URI}${USER_DATA_GROUP_PERMISSIONS}${payLoad.email}`
          ).then((res) => {
            //console.log("Response Came",res);
            if (handleResponseCode(res)) {
              //console.log("handleResponseCode Inside Came",res);
              dispatch(updateUserDataObj(res?.data));
              //console.log("userData%%%%========222",res?.data)
              userData = res?.data;
            } else {
              //console.log("handleResponseCode Outside Came",res);
              //update redux store
            }
          });
        })
        .catch((error) => {
          //update redux store
        });
    } else {
      //update redux store
    }
  };

  const addContent = (fobj) => {
    ////console.log("addContent ffob======", fobj)
    ////console.log("addContent ffobExtenssion======", fobj)
    let contentFile;
    if (fobj.contentType == 1) {
      contentFile = `image/${fobj?.extension}`;
    } else {
      contentFile = `video/${fobj?.extension}`;
    }
    return contentFile;
  };

  const uploadDataOnAws = () => {
    // handleLoader(true);
    //let awsFlag = false
    //local variable isUploadSuccess = true
    try {
      ////console.log("uploadDataOnAws=====$$$")
      if (discription.contentType != 5) {
        if (presignedUploadUrlArr?.length == 0) isUploadToAWSSuccess = false;
      }
      let successFileUpload = 0;
      presignedUploadUrlArr?.forEach((presignedUploadUrl) => {
        //if()
        let fobj = fileObj.find((e) => e.FileId == presignedUploadUrl.FileId);
        ////console.log("fileObj=====$$$", fobj)
        let conetntType = addContent(fobj);
        ////console.log("conetntType=====$$$", conetntType)
        const requestOptions = {
          method: "PUT",
          headers: {
            // "Content-Type": fileObj.find(
            //   (e) => e.FileId == presignedUploadUrl.FileId
            // ).file.type,

            "Content-Type": conetntType,
            "Access-Control-Allow-Origin": "*",
          },
          body: fileObj.find((e) => e.FileId == presignedUploadUrl.FileId).file,
        };
        ////console.log("requestOptions====$$", requestOptions)
        fetch(
          new Request(presignedUploadUrl.PreSignedUrl, requestOptions)
        ).then((response) => {
          ////console.log("uploadDataOnAws Flow1", response);
          if (response.status == 200) {
            //responsehandler(true);
            //isUploadSuccess = false;
            //setFlag(true);
            // awsFlag = true
            successFileUpload = successFileUpload + 1;
            ////console.log("Added in to AWS ==",successFileUpload);
            if (successFileUpload == fileObj?.length) uploadDataOnAws2();
          } else {
            handleLoader(false);
            //setFlag(false);
            // responsehandler(false);
            //If any file is failing to upload - We shall not create the Post - Please try again
            isUploadToAWSSuccess = false;
            createNotification(
              "error",
              SOMETHING_WENT_WRONG +
                "Error uploading image to cloud, please try again."
            );
          }
        });
      });
      ////console.log("Loop exited");
    } catch {
      isUploadToAWSSuccess = false;
      handleLoader(false);
      createNotification(
        "error",
        SOMETHING_WENT_WRONG +
          "Error uploading image to cloud, please try again."
      );
    }
  };

  const uploadDataOnAws2 = () => {
    // handleLoader(true);
    try {
      if (preAssignthumbnailArr?.length == 0) isUploadToAWSSuccess = false;

      preAssignthumbnailArr?.forEach((presignedUploadUrl) => {
        let thumbnailFileObj = thumbnailUrl.find(
          (e) => e.FileId == presignedUploadUrl.FileId
        );

        ////console.log("thumbnailFileObj=====$$$", thumbnailFileObj)
        let conetntType = addContent(thumbnailFileObj);
        ////console.log("thumbnail conetntType =====$$$", conetntType)
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": conetntType,
            "Access-Control-Allow-Origin": "*",
          },
          body: thumbnailUrl.find((e) => e.FileId == presignedUploadUrl.FileId)
            .file,
        };
        //////console("before fatch data in thumbnailContent");
        fetch(
          new Request(presignedUploadUrl.PreSignedUrl, requestOptions)
        ).then((response) => {
          //////console("uploadDataOnAws Flow2", response.status);
          if (response.status == 200) {
            //setFlag(true);
            ////console.log("NEW CHNAGE==");
            createEngagementPost(isUploadToAWSSuccess);
          } else {
            handleLoader(false);
            isUploadToAWSSuccess = false;
            createNotification(
              "error",
              SOMETHING_WENT_WRONG +
                "Error uploading image to cloud, please try again."
            );
            //setFlag(false);
          }
        });
      });
      //Once Thumb nail is added - Save Post Data
    } catch {
      handleLoader(false);
      isUploadToAWSSuccess = false;
      createNotification(
        "error",
        SOMETHING_WENT_WRONG +
          "Error uploading image to cloud, please try again."
      );
    }
    // handleLoader(false);

    //After the 2nd Upload Initiate the Post
  };

  const createEngagementPost = (isAWSSuccess) => {
    ////console.log("Success Flow ===",isAWSSuccess);
    if (isAWSSuccess) {
      //Send Data to Server
      createPost();
    } else {
      createNotification(
        "error",
        SOMETHING_WENT_WRONG +
          "Error uploading image to cloud, please try again."
      );
    }
  };

  const checkManadatoryFields = () => {
    let hasMandatoryFieldmissing = true;
    if (!(thumbnailUrl?.length > 0)) {
      hasMandatoryFieldmissing = false;
      createNotification("warning", "Add banner image.");
    } else if (
      !(discription?.thumbnailTitle?.length > 0) &&
      discription.contentType != 5
    ) {
      hasMandatoryFieldmissing = false;
      createNotification("warning", "Enter banner title.");
      hasMandatoryFieldmissing = false;
    } else if (
      discription.contentType == 5 &&
      !(discription?.discription?.length > 0)
    ) {
      hasMandatoryFieldmissing = false;
      createNotification("warning", "Enter description.");
    } else if (
      !(discription.contentType == 2 ? discription?.productId : true)
    ) {
      hasMandatoryFieldmissing = false;
      createNotification(
        "warning",
        "Please attach a product for Shoppable Video."
      );
    } else if (
      discription.contentType == 5 &&
      new Date(discription?.liveDateTime).getTime() < new Date().getTime()
    ) {
      hasMandatoryFieldmissing = false;
      createNotification(
        "warning",
        "Enter  DateTime value grater then currunt dateTime."
      );
    }
    //console("hasMandatoryFieldmissing", hasMandatoryFieldmissing);
    return hasMandatoryFieldmissing;
  };

  const createPost = () => {
    // debugger;
    // handleLoader(true);
    try {
      let datacorret = checkManadatoryFields();
      //console("datacorret=====post", datacorret);
      if (datacorret) {
        const body = {
          userId: userData?.UserID,
          description: discription.discription,
          tags: discription.tag,
          contentType: discription.contentType,
          postText: "",
          textfontFamily: discription.fontFamily,
          captionFont: discription.fontFamily,
          captionColor: discription.ColorPicker,
          ctaImageId: discription.buttonType,
          buttonType: discription.buttonType,
          caption: discription.caption,
          buttonHeader: discription.caption,
          cTAUrl: discription.targetCatogery,
          postContents: PostContent,
          //productId: discription.productId,
          Thumbnail_URL: thumbnailContent[0]?.fileUrl,
          Thumbnail_Title: discription?.thumbnailTitle,
          liveDateTime: discription?.liveDateTime,
          ProductIdList: discription.productId,
          Video_duration: discription.videoDuration,
        };

        postAuthAndData(
          `${ENGT_PROD_BASE_URI}${SAVE_POST_DATA}`,
          body,
          history
        ).then((res) => {
          if (handleResponseCode(res)) {
            handleLoader(false);
            createNotification("success", "Post created successfully.");
            if (discription.contentType == 5)
              copyGeneratedCode(res?.data.schedule_code);
            handleReset();
            setDiscription({
              tag: "",
              discription: "",
              targetCatogery: "",
              caption: "",
              fontFamily: "",
              ColorPicker: "",
              buttonType: 0,
              contentType: 0,
              productId: "",
              thumbnailTitle: "",
              liveDateTime: "",
            });
            productIds = [];
            setProductDetail([]);
          }
        });
      } else {
        // createNotification(
        //   "warning",
        //   discription?.discription
        //     ? "Please attach a product for Shoppable Video,"
        //     : "Enter description | Enter banner image"
        // );
      }
    } catch {
      handleLoader(false);
    }
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (e) => {
    // setSecondPage(true);
    if ((fileObj.length && discription?.contentType != 0) || liveSchedule) {
      setPageType(false);
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      // if(activeStep === steps.length - 1)

      setSkipped(newSkipped);
      if (activeStep === steps.length - 1) {
        //////console();
        let datacorret = checkManadatoryFields();

        if (datacorret) {
          handleLoader(true);
          if (discription.contentType == 5) {
            uploadDataOnAws2();
          } else {
            uploadDataOnAws();
          }
          ////console.log("datacorret=====aws", datacorret);

          //uploadDataOnAws2();
          //createEngagementPost(isUploadToAWSSuccess);
          setFileObj([]);
          setThumbnailUrl([]);
        } else {
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      createNotification(
        "warning",
        "Add an image/video to proceed or contentType"
      );
    }
  };

  const handleBack = (e) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setPageType(true);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setPageType(true);
    setActiveStep(0);
  };

  const contentType = (event) => {
    //setFileObj([]);
    productIds = [];
    setProductDetail([]);
    if (event.target.value === "Story") {
      setStoryFlage(true);
      setDiscription({ ...discription, contentType: 1 });
    } else {
      setStoryFlage(false);
    }
    if (event.target.value === "Post") {
      //setStoryFlage(true);
      setDiscription({ ...discription, contentType: 2 });
    }
    if (event.target.value === "LiveSchedule") {
      //setStoryFlage(false);
      setLiveSchedule(true);
      setDiscription({ ...discription, contentType: 5 });
      setPageType(false);
      if (activeStep !== steps.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setLiveSchedule(false);
    }
  };

  const removeAll = () => {
    setFileObj([]);
  };

  const removeAll2 = () => {
    setThumbnailUrl([]);
  };

  const newFileUploadThumbnail = (acceptedFiles) => {
    setThumbnailUrl([]);
    const filterObjArr = [];
    if (acceptedFiles?.length == 1) {
      acceptedFiles?.forEach((file) => {
        let extensionType = file.name.split(".").pop().toLowerCase();
        if (
          extensionType == "jpeg" ||
          extensionType == "jpg" ||
          extensionType == "mp4" ||
          extensionType == "png" ||
          extensionType == "webp"
        ) {
          if (file.size < 3000000) {
            const obj = {
              extension: file.name.split(".").pop(),
              contentType: extensionType == "mp4" ? "2" : "1",
              file: file,
              FileId: ` ${Date.now()}_${file.name}`,
            };
            filterObjArr.push(obj);
          } else {
            createNotification(
              "warning",
              "Image size should be less than 3 MB."
            );
          }
        } else {
          createNotification(
            "warning",
            "Only jpeg, jpg, png, webp file type is allowed."
          );
        }
      });
    } else {
      createNotification("warning", "Only one image allowed");
    }

    setThumbnailUrl(filterObjArr);
    fetchPreUrlDataThumblail(filterObjArr);
  };

  const changeTitle = (event) => {
    ////////console("changeTage", event.target.value);
    setDiscription({
      ...discription,
      thumbnailTitle: event.target.value,
    });
  };
  //console.log("userData%%%%==final======",userData)
  return (
    <div className="studioRoot" style={{ margin: "0px 5px" }}>
      <NotificationContainer />
      <AlertDialogBox generatedCodeObj={generatedCodeObj} />
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <React.Fragment>
          <Typography style={{ marginTop: 2, marg: 1 }}>
            Step {activeStep + 1}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              value="anand"
              disabled={activeStep === 0}
              onClick={(e) => handleBack(e)}
              sx={{ mr: 1 }}
              style={{ backgroundColor: "var(--buttonBg)", color: "white" }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              onClick={(e) => handleNext(e)}
              accessKey="anand"
              style={{ backgroundColor: "var(--buttonBg)", color: "white" }}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      </Box>
      {pageType ? (
        <Fragment>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              //enable in mobile view
              //justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <StodioCategory
              contentType={contentType}
              discription={discription}
            />
          </div>

          <div
            className="buttomAlign"
            style={{ opacity: discription.contentType == 5 ? "0.2" : "1.0" }}
          >
            <div {...getRootProps()} className="uplodeDiv">
              <input {...getInputProps()} />
              <CloudUploadIcon
                className="uplodeimg"
                style={{ fontSize: "5rem" }}
              />
              {fileObj?.length > 0 && (
                <p>{fileObj?.length} file is selected for upload</p>
              )}

              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Drag 'n' drop some files here, or click to select files</p>
              )}
            </div>
            <Button
              style={{
                backgroundColor: "var(--buttonBg)",
                color: "white",
                margin: "20px 0px",
                padding: "5px 70px",
              }}
              onClick={() => removeAll()}
            >
              Reset
            </Button>
          </div>

          {/* <Dropzone
            onDrop={(acceptedFiles) => newFileUploadThumbnail(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <CloudUploadIcon
                    className="uploading"
                    style={{ fontSize: "5rem" }}
                  />
                  <p>Upload Thumbnail Image</p>
                </div>
              </section>
            )}
          </Dropzone> */}
        </Fragment>
      ) : (
        <div>
          <label
            style={{
              color: "rgba(0, 0, 0, 0.54)",
              marginTop: "30px",
              fontSize: "1.1rem",
            }}
          >
            Upload Thumbnail Image/Video
          </label>
          <div className="buttomAlign">
            <Dropzone
              onDrop={(acceptedFiles) => newFileUploadThumbnail(acceptedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()} className="uplodeDiv2">
                    <input {...getInputProps()} />
                    <CloudUploadIcon
                      className="uplodeimg2"
                      style={{ fontSize: "2rem" }}
                    />
                    {thumbnailUrl?.length > 0 && (
                      <p>{thumbnailUrl?.length} Banner Image</p>
                    )}

                    {isDragActive ? (
                      <p>Select an image or drag 'n' drop</p>
                    ) : (
                      <p style={{ fontSize: "12px" }}>
                        Less than 3 MB allowed.
                      </p>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
            <Button
              style={{
                backgroundColor: "var(--buttonBg)",
                color: "white",
                margin: "20px 0px",
                padding: "5px 70px",
              }}
              onClick={() => removeAll2()}
            >
              Reset
            </Button>
          </div>
          {!liveSchedule && (
            <div>
              <label
                style={{
                  color: "rgba(0, 0, 0, 0.54)",
                  marginTop: "30px",
                  fontSize: "1.1rem",
                }}
              >
                Add Title
              </label>
              <div className="disTag">
                <TextField
                  id="standard-basic"
                  label="Thumbnail Title"
                  variant="standard"
                  multiline={true}
                  onChange={changeTitle}
                  inputProps={{ maxLength: 35 }}
                  required={true}
                  // maxRows={1}
                  fullWidth={true}
                  style={{ marginBottom: "5px" }}
                />
              </div>
            </div>
          )}

          <StudioComponenets_Description
            storyFlage={storyFlage}
            liveSchedule={liveSchedule}
            discription={discription}
            setDiscription={setDiscription}
            productIds={productIds}
            productDetail={productDetail}
            setProductDetail={setProductDetail}
          />
        </div>
      )}
    </div>
  );
}

export default UploadStudioComponents;
