import React from "react";
import { useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { borderRadius } from "polished";
import { useDispatch } from "react-redux";
import { updateStoryId } from "../../actions/story/storyActionHendler";

const useStyles = makeStyles({
  name: {
    marginLeft: "5px",
    fontWeight: "bold",
  },
  tagp: {
    marginLeft: "5px",
    color: "#354e7c",
  },
  btn: {
    backgroundColor: "#6b9cf3",
  },
  priceStyl: {
    color: "white",
  },
  bg: {
    backgroundColor: "black",
    borderRadius: "30%",
    paddingRight: "5px",
    paddingLeft: "5px",
    marginBottom: "5px",
    marginLeft: "30px",
  },
  cardActions: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: "5px",
    paddingTop: "175px",
    opacity: 0,
    "&:hover": {
      opacity: 1,
    },
  },
  card: {
    position: "relative",
  },
});

function CardComponent({ pImg, Name, tag, id, price }) {
  const history = useHistory();
  const Dispatch = useDispatch();
  const { name, tagp, btn, priceStyl, card, bg, cardActions } = useStyles();
  console.log("card id ***", id);
  function handleClick() {
    Dispatch(updateStoryId(id));
    history.push("/hireinfluncer/details");
  }

  return (
    <div
      style={{ marginRight: "25px", marginBottom: "25px" }}
      onClick={handleClick}
    >
      <Card
        className={card}
        style={{ minWidth: 230, maxWidth: 230, height: 305 }}
      >
        <CardMedia
          component="img"
          height="240"
          image={pImg}
          alt="green iguana"
        />
        <Typography
          className={name}
          gutterBottom
          variant="body2"
          component="div"
        >
          {Name}
        </Typography>
        <Typography
          className={tagp}
          gutterBottom
          variant="body2"
          component="div"
        >
          {tag}
        </Typography>
        <CardActions className={cardActions}>
          <body className={bg}>
            <Typography variant="body2" component="div" className={priceStyl}>
              Starts from INR {price}{" "}
            </Typography>
          </body>
          <div style={{ marginLeft: "140px" }}>
            <Button
              className={btn}
              color="primary"
              variant="contained"
              size="small"
            >
              Hire Me
            </Button>
          </div>
          {/* <Button size="small">Learn More</Button> */}
        </CardActions>
      </Card>
    </div>
  );
}

export default CardComponent;
