import React, { useEffect, useState } from "react";

import "./styles.css";
import axios from "axios";
import FacebookIcon from "../../../igUploads/icon/FacebookIcon";
import InstaIcon from "../../../igUploads/icon/InstaIcon";
import YouTubeIcon from "../../../igUploads/icon/YouTubeIcon";
import { useAppContext } from "../../../../contexts/appContext";
import {
  CLIENT_URL_FOR_FB_IG,
  CLIENT_URL_FOR_YT,
  ENGT_PROD_HOST_URI,
  GENERATE_ACCESS_TOKEN,
  GENERATE_YOUTUBE_ACCESS_TOKEN,
  VALIDATE_TOKEN_API,
} from "../../../../api/apiConstants";
import { getAuthAndData, postAuthAndData } from "../../../../api/ApiHelper";
import createNotification from "../../../common/reactNotification";
import { useHistory } from "react-router-dom";
import { dispatchLoaderData } from "../../../../actions/route/routeActionHandler";
import { useDispatch } from "react-redux";

function NetworkCard() {
  const [urlCode, setUrlCode] = useState("");
  //   const [isFacebookClicked, setIsFacebookClicked] = useState(false);
  //   const [isInstagramClicked, setIsInstagramClicked] = useState(false);

  const {
    isAuthorisedFacebook,
    isAuthorisedInstagram,
    setIsAuthorisedFacebook,
    setIsAuthorisedInstagram,
    isAuthorisedYT,
    setIsAuthorisedYT,
    facebookData,
    setFacebookData,
    instagramData,
    setInstagramData,
    ytData,
    setYTData,
  } = useAppContext();

  const history = useHistory();
  const dispatch = useDispatch();

  const handleInstaResponseCode = (resp) => {
    if (resp.code === 1 && !resp.data.token_invalid) {
      setIsAuthorisedInstagram(true);
      history.push("/addaccount");
    } else {
      createNotification("error", "Error");
      history.push("/addaccount");
    }
  };

  const handleFacebookResponseCode = (resp) => {
    if (resp.code === 1 && !resp.data.token_invalid) {
      setIsAuthorisedFacebook(true);
      history.push("/addaccount");
    } else {
      createNotification("error", "Error");
      history.push("/addaccount");
    }
  };

  const handleYTResponseCode = (resp) => {
    if (!resp.data.token_invalid) {
      if (!!resp.data.error) {
        createNotification("error", "Error");
        history.push("/addaccount");
      } else {
        setIsAuthorisedYT(true);
        history.push("/addaccount");
      }
    } else {
      createNotification("error", "Error");
      history.push("/addaccount");
    }
  };

  const handleLoader = (showBool) => {
    dispatch(dispatchLoaderData(showBool));
  };

  const handleResponseCode = (res, platform) => {
    if (res.code === 1) {
      if (platform === 0) {
        setIsAuthorisedFacebook(true);
        setFacebookData(res.data);
      }
      if (platform === 1) {
        setIsAuthorisedInstagram(true);
        setInstagramData(res.data);
      }
      if (platform === 2) {
        setIsAuthorisedYT(true);
        setYTData(res.data);
      }
    } else {
      if (platform === 0) {
        setIsAuthorisedFacebook(false);
      }
      if (platform === 1) {
        setIsAuthorisedInstagram(false);
      }
      if (platform === 2) {
        setIsAuthorisedYT(false);
      }
    }
  };

  function validateToken(platform) {
    getAuthAndData(
      `${ENGT_PROD_HOST_URI}${VALIDATE_TOKEN_API}?sm_platform=${platform}`
    )
      .then((res) => {
        handleResponseCode(res, platform);
      })
      .catch((err) => {
        console.error("validateToken error", err);
      });
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    handleLoader(true);
    validateToken(0);
    validateToken(1);
    validateToken(2);

    setTimeout(() => handleLoader(false), 1500);

    if (code === null) return;

    const dataForFacebook = {
      code,
      socialMediaEnum: "0",
    };

    const dataForInstagram = {
      code,
      socialMediaEnum: "1",
    };

    const dataForYT = {
      code: code,
    };

    postAuthAndData(
      `${ENGT_PROD_HOST_URI}${GENERATE_ACCESS_TOKEN}`,
      dataForInstagram
    )
      .then((res) => {
        handleInstaResponseCode(res);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    postAuthAndData(
      `${ENGT_PROD_HOST_URI}${GENERATE_ACCESS_TOKEN}`,
      dataForFacebook
    )
      .then((res) => {
        handleFacebookResponseCode(res);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    postAuthAndData(
      `${ENGT_PROD_HOST_URI}${GENERATE_YOUTUBE_ACCESS_TOKEN}`,
      dataForYT
    )
      .then((res) => {
        handleYTResponseCode(res);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const reauthoriseInstaFacebook = () => {
    window.location.href = CLIENT_URL_FOR_FB_IG;
  };
  const reauthoriseYT = () => {
    window.location.href = CLIENT_URL_FOR_YT;
  };

  return (
    <div className="account_card_container">
      <label style={{ color: "#fff" }}>Facebook</label>
      <article className="settings__card" aria-label="Card">
        <section className="network__accounts">
          <div className="account__card" onClick={reauthoriseInstaFacebook}>
            <article className="network__account">
              <picture className="account__picture">
                {isAuthorisedFacebook && !!facebookData?.profile_picture ? (
                  <img
                    className="account__image"
                    src={facebookData?.profile_picture}
                    alt="Facebook profile pic"
                  />
                ) : (
                  <FacebookIcon />
                )}
              </picture>
              <div className="account__details u-width-100 u-text-overflow-ellipsis">
                <div className="account__name">
                  {isAuthorisedFacebook && !!facebookData?.user_name
                    ? facebookData.user_name
                    : "Facebook"}
                </div>
                <div
                  className={
                    isAuthorisedFacebook
                      ? "account__status is-active"
                      : "account__status not-active"
                  }
                >
                  {isAuthorisedFacebook ? "Connected" : "Need to Re-authorise"}
                </div>
              </div>
            </article>
          </div>
        </section>
      </article>
      <label style={{ color: "#fff" }}>Instagram</label>
      <article className="settings__card" aria-label="Card">
        <section className="network__accounts">
          <div className="account__card" onClick={reauthoriseInstaFacebook}>
            <article className="network__account">
              <picture className="account__picture">
                {isAuthorisedInstagram && !!instagramData?.profile_picture ? (
                  <img
                    className="account__image"
                    src={instagramData.profile_picture}
                    alt="Insta profile pic"
                  />
                ) : (
                  <InstaIcon />
                )}
              </picture>
              <div className="account__details u-width-100 u-text-overflow-ellipsis">
                <div className="account__name">
                  {isAuthorisedInstagram && !!instagramData?.user_name
                    ? instagramData.user_name
                    : "Instagram"}
                </div>
                <div
                  className={
                    isAuthorisedInstagram
                      ? "account__status is-active"
                      : "account__status not-active"
                  }
                >
                  {isAuthorisedInstagram ? "Connected" : "Need to Re-authorise"}
                </div>
              </div>
            </article>
          </div>
        </section>
      </article>
      <label style={{ color: "#fff" }}>Youtube</label>
      <article className="settings__card" aria-label="Card">
        <section className="network__accounts">
          <div className="account__card" onClick={reauthoriseYT}>
            <article className="network__account">
              <picture className="account__picture">
                {isAuthorisedYT && !!ytData?.profile_picture ? (
                  <img
                    className="account__image"
                    src={ytData.profile_picture}
                    alt="Youtube profile pic"
                  />
                ) : (
                  <YouTubeIcon />
                )}
              </picture>
              <div className="account__details u-width-100 u-text-overflow-ellipsis">
                <div className="account__name">
                  {isAuthorisedYT && !!ytData?.user_name
                    ? ytData.user_name
                    : "Youtube"}
                </div>
                <div
                  className={
                    isAuthorisedYT
                      ? "account__status is-active"
                      : "account__status not-active"
                  }
                >
                  {isAuthorisedYT ? "Connected" : "Need to Re-authorise"}
                </div>
              </div>
            </article>
          </div>
        </section>
      </article>
    </div>
  );
}

export default NetworkCard;

{
  /* <div className="group-2">
  <div className="more-wrapper">
    <img
      className="add-account-search"
      alt="search account icon"
      src={threeDotsHorz}
    />
  </div>
  <div className="eye-instance-wrapper">
    <img className="add-account-search" alt="search account icon" src={eye} />
  </div>
  <div className="social-media-logo-wrapper">
    <InstaIcon />
  </div>
  <div className="add-account-5">
    <img className="add-account-search" alt="search account icon" src={crown} />
    <div className="add-account-6">
      <div className="time">Blaash.io</div>
      <div className="time-2">Instagram Business Profile</div>
    </div>
  </div>
</div>; */
}
