import React, { useEffect, useState } from "react";
import "../Billing.css";
import SubscriptionPlans from "./SubscriptionPlans";
import Payment from "./Payment";
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import { postAuthAndData } from "../../../api/ApiHelper";
import { PrimayButton } from "../Billing";
import { CREATE_SUBSCRIPTION, IDTY_PROD_HOST_URI } from "../../../api/apiConstants";

const PlanDetails = ({plans, activeplan, customerId, currentPlanDetails, handleLoader}) => {
  const [slidemenu, setSlidemenu] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [selectedplan, setSelectedplan] = useState(currentPlanDetails);
  const [paymentSecret, setPaymentSecret] = useState(null);

  const CreateSubscription = async() => {
    handleLoader(true);
    const body = {
      customerId: customerId,
      quantity: quantity,
      priceId: selectedplan.price_id
    };
    await postAuthAndData(
      `${IDTY_PROD_HOST_URI}${CREATE_SUBSCRIPTION}`, body
      ).then((res) => {
      if(res.code === 1) {
        if(res.data.client_sceret != null)
        {
          setSubscriptionId(res.data.subscriptionId);
          setPaymentSecret(res.data.client_sceret);
          setSlidemenu(true);
          handleLoader(false);
        } else {
          CreateSubscription();
        }
      } else {
        console.log(res.data);
        handleLoader(false);
      }
    })
    .catch((err) => {
      handleLoader(false);
      console.log(err);
    });
  }

  useEffect(() => {
    if(activeplan) {
      setSelectedplan(currentPlanDetails);
    }
  },[currentPlanDetails]);
  
  return (
    <div className="tab-content-container">
      {
        slidemenu &&
        <Payment 
          quantity={quantity}
          subscriptionId={subscriptionId}
          selectedplan={selectedplan}
          slidemenu={slidemenu}
          setSlidemenu={setSlidemenu}
          handleLoader={handleLoader}
          paymentSecret={paymentSecret}
        />
      }
      <div>
        {
          activeplan &&
          <>
            <div className="subscription-details">
              <div>
                <h4 className="current-subscription">
                  Currenty Subscribed to
                  <span>
                    {currentPlanDetails.product_name}
                  </span>
                </h4>
                <h4 className="current-renewal">
                  Next Credit cycle renewal:
                  <span>
                    {currentPlanDetails.current_billing_end}
                  </span>
                </h4>
              </div>
              {/*<div>
                <Button
                  style={{
                    color: "#FFF",
                    background: "#373743",
                    padding: "5px 15px",
                    borderRadius: "10px",
                    fontSize: "12px",
                    width: "90px",
                    height: "37px",
                    marginBottom: "10px",
                    borderBottom: "1px solid var(--style, #017EFA)",
                  }}
                  onClick={() => setSlidemenu(true)}
                >
                  {activeplan ? "Manage" : "Buy Now"}
                </Button> 
              </div>*/}
            </div>
            <div className="current-plan-details">
              <div className="head-plan-details">
                <div>What's Included</div>
                <div>Price</div>
              </div>
              <div className="body-plan-details">
                <div>Users</div>
                <div>Professional Users x {currentPlanDetails.quantity}</div>
                <div>{currentPlanDetails.billing_amount} {currentPlanDetails.billing_currency}</div>
              </div>
              <div className="body-plan-details">
                <div>Monthly Total</div>
                <div>{currentPlanDetails.billing_amount} {currentPlanDetails.billing_currency}</div>
              </div>
            </div>
          </>
        }
      </div>
      <SubscriptionPlans plans={plans} activeplan={activeplan} currentPlanDetails={currentPlanDetails} selectedplan={selectedplan} setSelectedplan={setSelectedplan} setSlidemenu={setSlidemenu} />
      <div className="footer-plan-details">
        <h4 style={{margin:"0px"}} className="current-subscription">
          {selectedplan != null ? selectedplan.plan_name+ " x "+ quantity : "Choose a Plan"}
        </h4>
        <div style={{display:"flex", gap: "20px", alignItems: "center"}}> 
          <h4 style={{fontSize: "20px", margin:"0px"}}>
            Number of Users
          </h4>
          <div className="quantity-input">
            <input
              id="outlined-basic"
              type="number"
              className="search-input"
              aria-label="Number of Users"
              placeholder="Users"
              value={quantity}
              onChange={(event) => {
                if (event.target.value < 1) {
                  setQuantity(null);
                } else {
                  setQuantity(parseInt(event.target.value));
                }
              }}
            />
            <div className="input-suffix">
              <ArrowDropUpRoundedIcon onClick={() => setQuantity((prevQuantity) => prevQuantity + 1)} />
              <ArrowDropDownRoundedIcon onClick={() => setQuantity((prevQuantity) => prevQuantity > 1 ? prevQuantity - 1 : 1)} />
            </div>
          </div>
        </div>
        <div style={{display:"flex", gap: "20px", alignItems: "center"}}> 
          <h4 style={{fontSize: "20px", margin:"0px"}}>
            Total:
          </h4>
          <h4 style={{fontSize: "18px", margin:"0px"}}>
            {selectedplan != null ? quantity * selectedplan.amount+" "+selectedplan.currency+" / "+selectedplan.interval : ""}
          </h4>
        </div>
        <PrimayButton style={{flex: "none"}} onClick={() => {
            if(quantity >= 1) {
              CreateSubscription();
            }
          }} disabled={!!!selectedplan}>
          Checkout
        </PrimayButton>
      </div>
    </div>
  );
};

export default PlanDetails;
