import React, {Fragment} from 'react'
import trash_src from "../../../../assets/img/trash.png";
import Tooltip from '@material-ui/core/Tooltip';
import info from '../../../../assets/img/info.png';

function RewardRow(props) {
    const Tournament = props?.goalData?.isTournament
    const data = props.obj
    console.log("RewardRow===data====",data)
    const rewardCollection = props?.rewardCollection
    const selectedrow = rewardCollection?.filter((e)=>(e.value == data.id))
    console.log("propsData=========", selectedrow)
    const i = 0;
    
  return (
    <div className="add-reward-sec w-100 float-left clearfix" >
                            <div className="r-b-addreward-top w-100 float-left clearfix row">
                                <div className="w-8 float-left clearfix mr-1" style={{display:Tournament?'':'none'}}>
                                    <div className="w-100 float-left clearfix"   style={{fontSize:'12px'}}>
                                            <label className=" r-b-ar-i" style={{display: 'flex', textAlign: 'center',justifyContent:"center" }} >{data?.winnerPosition}</label>
                                    </div>
                                </div>
                                <div className={`${Tournament?'w-24':'w-30'} float-left clearfix mr-1`} style ={{display:"flex", flexDirection:"row"}}>
                                    <div className="w-100 float-left clearfix"  style={{fontSize:'12px'}}>
                                        <div className='ml-1 row'>
                                            <div className='w-80'>
                                                <label className="r-b-ar-i w-110" style={{lineHeight:'28px'}}>{data?.rewardType}</label>
                                                </div>
                                               
                                        </div>
                                    </div>
                                    <div className='w-10' >
                                                <Tooltip 
                                                    title={
                                                        <Fragment>
                                                                <p>{`Coupon code: ${selectedrow[0]?.reward_code || ''}`}</p>
                                                            <p>{`Expiry Date: ${new Date(selectedrow[0]?.expiry_date).toLocaleDateString()}`}</p>
                                                        </Fragment>
                                                    }
                                                >
                                                    <img src={info} className='mt-2' style={{ height: '20px', width: '20px' }} />
                                                </Tooltip>
                                            </div>
                                </div>
                               
                                <div className="w-8 float-left clearfix mr-1" style={{display:Tournament?'none':''}} >
                                    <div className="w-100 float-left clearfix"  style={{fontSize:'12px'}}>
                                        <label className=" r-b-ar-i"  style={{ textAlign: 'center' }} 
                                        >{data?.probability}</label>
                                    </div>
                                </div>
                                <div className="w-8 float-left clearfix mr-1" style={{display:Tournament?'':'none'}} >
                                    <div className="w-100 float-left clearfix"  style={{fontSize:'12px'}}>
                                        <label
                                            className=" r-b-ar-i" 
                                            style={{ textAlign: 'center' }} 
                                        >{data?.numberOfWinners}</label>
                                    </div>
                                </div>
                                {/* <div className="w-30 float-left clearfix mr-1">
                                    <div className="w-100 float-left clearfix"  style={{fontSize:'12px'}}>
                                    <label className="r-b-ar-i w-100" style={{lineHeight:'28px'}}>{data?.displayName}</label>
                                    </div>
                                </div> */}
                                <div className={`w-4 float-left clearfix ${i==0 ? 'mt-2':'mt-2'}`} onClick={() => props.removeRow(props?.data?.id)}>
                                    <img src={trash_src} alt='trash' style={{ height: '20px', width: '20px' }} />
                                </div>
                            </div>
                        
                    </div>
  )
}

export default RewardRow