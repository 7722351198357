import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LiveView from "../../components/liveView/liveView";
import * as actionsHandler from "../../actions/liveView/liveViewActionHandler";
import * as routeActionHandler from "../../actions/route/routeActionHandler";

const mapStateToProps = (state) => ({
  activeCustomerCounts: state.LiveViewReducer.activeCustomerCounts,
  eventListCountForLastXHoure:
    state.LiveViewReducer.eventListCountForLastXHoure,
  grossValueCustomerCount: state.LiveViewReducer.grossValueCustomerCount,
  videoWatchedDuration: state.LiveViewReducer.videoWatchedDuration,
});

const mapDispatchToProps = (dispatch) => ({
  liveViewActionHandler: bindActionCreators(actionsHandler, dispatch),
  routeActionHandler: bindActionCreators(routeActionHandler, dispatch),
});

const LiveViewContatiner = connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveView);
export default LiveViewContatiner;
