import React, { useState } from "react";
import {
  Card,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import ReactPlayer from "react-player";
function PlaylistCardComponent({
  feed,
  playlistData,
  setPlaylistData,
  handleClick,
  checkBox,
  contentType,
}) {

  const fileExtensionsRegex = /\.(mov|mp4|webm|mkv|flv)$/; 

  function validateFileExtension(url) {    
    return fileExtensionsRegex.test(url);  
    } 

    

  const url = feed?.url;


  const displayName = feed?.displayName;
  const isSelectde = feed?.isSelectde;
  const postId = feed?.postId;
  const [checked, setChecked] = useState(isSelectde);

  const changeOnClick = (event) => {
    setChecked(event.target.checked);
    handleClick(postId);
  };

  const handleChange = (event) => {
    setChecked(true);
  };
  return (
    <div style={{ position: "relative", margin: "15px 15px 0px 0px" }}>
      {checkBox && (
        <FormControlLabel
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            zIndex: "1",
          }}
          id={feed?.postId}
          onChange={changeOnClick}
          control={<Checkbox checked={checked} />}
        />
      )}
      {/* <input
        type="checkbox"
        id="vehicle1"
        name="vehicle1"
        value="true"
        style={{
          position: "absolute",
          top: "10px",
          left: "15px",
          zIndex: "1",
          height: "20px",
          width: "20px",
          // cursor: "pointer",
          onClick= "(event) => console.log(event)"
        }}
      /> */}
      <Card
        style={{
          width: "140px",
          height: "245px",
          margin: "5px 13px 5px 10px",
          //borderRadius: "15px",
          // display: flag == "IMG" ? "none" : "",
        }}
      >
        {contentType === 2 && validateFileExtension(url)
        ?
         <ReactPlayer
          width={"140px"}
          height={"100%"}
          className="video"
          controls={false}
          // playing={true}
          volume={0}
          url={url}
          //light={pImg}
          loop={true}
          onClick={() => setChecked(true)}
        /> :
         <img
                  src={url}
                  alt=""
                  width={"140px"}
                  height={"100%"}
                  
                />}
        <div
          style={{
            backgroundColor: "#0000009c",
            position: "absolute",
            bottom: "5px",
            zIndex: "1",
            width: "138px",
            height: "50px",
            //borderRadius: "0px 0px 15px 15px",
          }}
        >
          <Typography
            variant="subtitle1"
            color="textSecondary"
            component="div"
            style={{
              fontSize: "15px",
              fontWeight: "600",
              //position: "absolute",
              bottom: "20px",
              zIndex: "2",
              color: "white",
              whiteSpace: "initial",
              margin: "0px 10px",
            }}
          >
            {displayName}
          </Typography>
        </div>
      </Card>
    </div>
  );
}

export default PlaylistCardComponent;
