import {
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  ButtonBase,
  List,
  Box,
  ListItem,
  SwipeableDrawer,
  TextField,
  Typography,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useEffect, useState } from "react";
import sampleImg from "../../assets/img/sample.jpg";
import {
  GET_FEEDS_V1,
  ENGT_PROD_BASE_URI,
  SAVE_POST_DATA,
  SOMETHING_WENT_WRONG,
  GET_POST_CONTENT
} from "../../api/apiConstants";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";
import { getAuthAndData, postAuthAndData } from "../../api/ApiHelper";
import ReactPlayer from "react-player";
import VideoLibrary_Description from "./VideoLibrary_Description";
import createNotification from "../common/reactNotification";
import { NotificationContainer } from "react-notifications";
import StoryLibrary_Description from "./StoryLibrary_Description";

let feedsGlobalData = [];
let productIds = []
let hasAllSequenceAssigned = true;

function StoryLibrary() {
  const [storyfeeds, setstoryfeeds] = useState([]);
  const [selectedCard, setselectedCard] = useState({});
  const [storyStatus, setstoryStatus] = useState(
    selectedCard?.isSelectde ? "Active" : "Inactive"
  );
  const [productDetail, setProductDetail] = useState([]);
  const [story, setStory] = useState([]);
  const [storyList, setstoryList] = useState([]);
  const [selectedstory, setselectedstory] = useState([]);
  const [thumbnail_title, setThumbnail_title] = useState([]);
  const [discription, setDiscription] = useState({
    productId: "",
    thumbnailTitle: "",
    PostContentId:"",
    Sequence:"",
    StoryId:"",
  });
 

  const History = useHistory();
  const Dispatch = useDispatch();

  const handleResponseCode = (resp) => {
    if (!resp || resp.code === -1) {
      createNotification("error", SOMETHING_WENT_WRONG + "in Target Audience");
      return false;
    } else {
      return true;
    }
  };

  const emptyproductIDs = () => {
    productIds = [];
  };

  const fetchFeedsData = async (ContentType) => {
    handleLoader(true);
    const body = {
      Index: 1,
      ContentType: ContentType,
    };
    try {
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_FEEDS_V1}`,
        body,
        History
      );
      feedsGlobalData = data?.data?.Feeds;
      customFeeds();
    } catch (err) {}
    handleLoader(false);
  };

  const updateProductid = (product_id) => {
    productIds = [...product_id];
    // console.log('ajay 01 updateProductid is running');
  }
  
  const customFeeds = () => {
    const feedsArr = [];
    feedsGlobalData?.forEach((fees) => {
      const obj = {
        url: fees?.Thumbnail_URL,
        displayName: fees?.Thumbnail_Title,
        isSelectde: fees?.IsCancelled,
        postId: fees?.EngagementPostId,
        Video_duration: fees?.Video_duration,
      };
      feedsArr.push(obj);
    });
    setstoryfeeds(feedsArr);
  };
  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
  };
  // console.log("storyfeeds===>", storyfeeds);
  // console.log("selectedCard===>", selectedCard);
  // console.log("productIds===>", productIds);
  // console.log("productDetails===>", productDetail);
  // console.log("GET story===>",story)
 
  useEffect(() => {
    fetchFeedsData([1]);
  }, []);

  useEffect(() => {
    if (selectedCard?.postId) {
      fatchPostContent([1]);
    }
  }, [selectedCard]);

  const fatchPostContent = async () => {
    handleLoader(true);
    try {
      getAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_POST_CONTENT}${selectedCard?.postId}`,
        null,
        History
      ).then((res) => {
        if (handleResponseCode(res)) {
          // console.log("res===>", res);
          handleLoader(false);
          if (res?.data.length > 0) {
            setstoryList(res?.data);
          }
        }
      });
    } catch (err) {
      handleLoader(false);
    }
  };
  
  
  // const generatePostContentBody = () =>{    
  //   console.log("generatePostContentBody ==>",selectedstory)
  //   const contents = [];
  //     // if (selectedstory?.length < storyList?.length)
  //     // {
  //     //   hasAllSequenceAssigned = false;
  //     //   return;
  //     // }
  //     selectedstory?.forEach((story) => {
  //       if(selectedstory?.Sequence >= 0){
  //         const obj = {
  //           Sequence: story?.Sequence,
  //           PostContentId: story?.PostContentId,
  //         };
  //         contents.push(obj);
  //       }
  //       else{
  //         console.log("generatePostContentBody iF Inside ==>",selectedstory)
  //         hasAllSequenceAssigned = false;
  //       }        
  //     });
  //   return contents;
  // }
   
  
  const CheckMandatoryFeilds = () =>{
    var allOkay = true;
    if (thumbnail_title.length <=0) {
      console.log("thumbnail title lenght",thumbnail_title.length)
      createNotification(
        "warning",
        "Please add thumbnail titile."
      );
      allOkay =false;
    }else if(productDetail.length <= 0){
      createNotification(
        "warning",
        "Please select a product search criteria."
      );
      allOkay =false;
    }else if (selectedstory?.length < storyList?.length){
      createNotification(
        "warning",
        "Please Select all the Stories Images."
      );
      allOkay =false;
    }
    return   allOkay;
  
  }

// console.log("productDetail===>", productDetail.length, productDetail )
// console.log("productIds===>", productIds.length, productIds )


const createPost = () => {   
    try {
      if (!CheckMandatoryFeilds())
      {
        return;
      } else{
      const body = {
        Thumbnail_Title: thumbnail_title,
        contentType: 1,
        ProductIdList: productIds,
        IsActive: storyStatus === "Active" ? true : false,
        PostId: selectedCard?.postId,
        // postContents: postConents,
        postContents: selectedstory.map((storyContent)=>{
          return {
          Sequence: storyContent?.Sequence + 1,
          PostContentId: storyContent?.PostContentId,
          Url: storyContent.Url,
        }})
      };
      console.log("createPost===",body)
      handleLoader(true);
      postAuthAndData(
        `${ENGT_PROD_BASE_URI}${SAVE_POST_DATA}`,
        body
      ).then((res) => {
        if (handleResponseCode(res)) {
          createNotification("success", "Story updated successfully.");
          setDiscription({
            contentType: 0,
            productId: "",
            thumbnailTitle: "",           
          });
          productIds = [];
          setProductDetail([]);
          setselectedstory([]);
          setDrawers(false);
          handleLoader(false);
          window.location.reload();
        }
        else{
          createNotification("error", "Story updated failed, please try again later.");
        }
      });
    }
    }catch {
      handleLoader(false);
    }
  };

  
// this
  const selectstories = (item) => {
    let arr = [...selectedstory];
    if (arr.indexOf(item) === -1) {     
      item.Sequence = arr.length  
      arr.push(item);
      console.log("arrayLenght---->",arr)
    } else if (arr.indexOf(item) >= 0) {
      arr.map((element, index)=>{
        if(element.Sequence > arr.indexOf(item)){
          arr[index].Sequence = element.Sequence+1;            
        }
      } )     
      //Form the PostContent --
      arr.splice(arr.indexOf(item), 1);  
    }
    setselectedstory(arr);   
  }

  console.log("storyList===>",storyList)



  const [drawes, setDrawers] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event?.type === "keydown" &&
      (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }

    setDrawers({ ...drawes, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      style={{ width: "350px" }}
      role="presentation"
    >
      <List>
      <ListItem>
        <div style={{marginTop:"16px"}}>
        <InputLabel style={{ fontSize: "14px" }}>
           Stories
            </InputLabel>
            <div style={{ display: "flex", position: "relative" }}>
              {storyList.map((item) => (
                <div style={{ flex: 0.25 }} onClick={() => selectstories(item)}>
                  {selectedstory.indexOf(item) >= 0 && (
                    <div
                      style={{
                        position: "absolute",
                        marginLeft: "65px",
                        marginTop: "-5px",
                        padding: "2px 8px",
                        borderRadius: "10px",
                        color: "#ffffff",
                        background: "rgba(1, 126, 250, 0.4)",
                        backdropFilter: "blur(2.56436px)",
                      }}
                    >
                      <p style={{ margin: "0", fontSize: "12px" }}>
                        {selectedstory.indexOf(item) + 1}
                      </p>
                    </div>
                  )}
                  <img
                    src={item.Url}
                    alt=""
                    height={"75px"}
                    width={"75px"}
                    style={{ margin: "0.25rem" }}
                  />
                </div>
              ))}
            </div>
            </div>
        </ListItem>
        <ListItem>
          <div style={{ marginBottom: "5px" }}>
            <InputLabel style={{ fontSize: "14px" }}>
              Thumbnail Title
            </InputLabel>
            <InputBase
              // placeholder={selectedCard?.displayName}
              value={thumbnail_title}
              style={{
                border: "1px solid #F0F0F0",
                padding: "0 10px",
                width: "100%",
                borderRadius: "10px",
                marginBottom: "10px",
              }}
              onChange={(e) => setThumbnail_title(e.target.value)}
                //  stringLengthCheck(8, 30)}}
            />
          </div>
        </ListItem>
        <ListItem>
          <div style={{ marginBottom: "5px" }}>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                style={{ fontSize: "14px" }}
              >
                Story Status
              </FormLabel>
              <RadioGroup
                style={{ backgroundColor: "white" }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={storyStatus}
                onChange={(e) => setstoryStatus(e.target.value)}
              >
                <FormControlLabel
                  value="Active"
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  value="Inactive"
                  control={<Radio />}
                  label="In-active"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </ListItem>        
        <ListItem>
          <StoryLibrary_Description
            productDetail={productDetail}
            setProductDetail={setProductDetail}
            productIds={productIds}
            selectedCard={selectedCard}
            setStory={setStory}
            storyDetails={story}
            updateProductid={updateProductid}
          />
        </ListItem>
        <ListItem>
          <ButtonBase
            style={{
              color: "#017EFA",
              background: "#F2F9FF",
              padding: "5px 15px",
              border: "1px solid #57B2E0",
              borderRadius: "9px",
              fontSize: "12px",
            }}
            onClick={createPost}
          >
            Update
          </ButtonBase>
        </ListItem>
      </List>
    </Box>
  );

  return (    
    <div style={{ display: "flex" }}>
      <NotificationContainer/>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          {/* <h3 style={{ fontSize: "16px", color: "#4F4F4F" }}>Story Library</h3> */}
        </div>
        <div
          style={{
            display: "flex",
            gap: "20px",
            height: "70vh",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              rowGap: "25px",
            }}
          >
            {storyfeeds.map((item) => (
              <div onClick={toggleDrawer("right", true)}>
                <div
                  className="library-video"
                  style={{
                    boxShadow: "0px 2.56436px 15.3861px rgba(0, 0, 0, 0.08)",
                    borderRadius: "16px",
                    padding: "10px",
                    overflow: "hidden",
                    width: "230px",
                    
                  }}
                  onClick={() => {
                    setselectedCard(item);
                    emptyproductIDs();
                    setstoryStatus(!item?.isSelectde ? "Active" : "Inactive");
                    setThumbnail_title(item?.displayName);
                  }}
                >
                 <img
                  src={item?.url}
                  alt=""
                  // width="100%"
                  width={"210px"}
                  height={"210px"}
                  
                />
          <Typography
            variant="subtitle1"
            color="textSecondary"
            component="div"
            style={{
              fontSize: "15px",
              fontWeight: "600",
              //position: "absolute",
              bottom: "20px",
              zIndex: "2",
              color: "white",
              whiteSpace: "initial",
              // margin: "10px",
              backgroundColor:"#424141",
              padding:"10px !important",
              textAlign:"center",
              lineHeight:"2.75"
            }}
          >
            {item.displayName.length > 20 ? item.displayName.substring(0, 20) + "...": item.displayName}
          </Typography>
        
                  {/* <p style={{ margin: " 10px 0", fontSize: "14px" }}>
                    {item.displayName}
                  </p> */}
                </div>
              </div>
            ))}
          </div>
        </div>
        {selectedCard ? (
          <>
            <div>
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={drawes[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    {list(anchor)}
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default StoryLibrary;
