import {
  ACTIVE_CUSTOMER_COUNTS,
  EVENT_LIST_COUNT_FOR_LAST_X_HOURES,
  GROSS_VALUE_CUSTOMER_COUNT,
  VIDEO_WATCHED_DURATION,
} from "../../constants/actionTypes";

const initialState = {
  activeCustomerCounts: null,
  eventListCountForLastXHoure: null,
  grossValueCustomerCount: null,
  videoWatchedDuration: null,
};
const LiveViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_CUSTOMER_COUNTS: {
      const newState = { ...state };
      newState.activeCustomerCounts = action.payload ? action.payload : null;
      return newState;
    }
    case EVENT_LIST_COUNT_FOR_LAST_X_HOURES: {
      const newState = { ...state };
      newState.eventListCountForLastXHoure = action.payload
        ? action.payload
        : null;
      return newState;
    }
    case GROSS_VALUE_CUSTOMER_COUNT: {
      const newState = { ...state };
      newState.grossValueCustomerCount = action.payload ? action.payload : null;
      return newState;
    }
    case VIDEO_WATCHED_DURATION: {
      const newState = { ...state };
      newState.videoWatchedDuration = action.payload ? action.payload : null;
      return newState;
    }
    default: {
      return state;
    }
  }
};
export default LiveViewReducer;
