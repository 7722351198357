import { USER_DATA_ACTION_TYPE } from "../../constants/actionTypes";

const INITIAL_STATE = {
    userData :null,
};

const userReducer = (state = INITIAL_STATE, action) =>{
    switch(action.type){
          case USER_DATA_ACTION_TYPE:
              return { 
                  ...state,
                  userData : action.payload, 
              };
               
    default: {
        return state;
      }  
}
}
export default userReducer;