import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import plus_src from "../../../../assets/img/add_gray.svg";

// import { Trash } from "../../icons/Trash";
import "./index.css";
import { useAppContext } from "../../../../contexts/appContext";
import FacebookIcon from "../../../igUploads/icon/FacebookIcon";
import InstaIcon from "../../../igUploads/icon/InstaIcon";
import { Link } from "react-router-dom";
import axios from "axios";
import createNotification from "../../../common/reactNotification";
import {
  ENGT_PROD_HOST_URI,
  GENERATE_AI_TEXT,
  GET_INSTAGRAM_CONTAINER,
  SOMETHING_WENT_WRONG,
  UPLOAD_POST_TO_FACEBOOK,
  UPLOAD_REELS_TO_INSTAGRAM,
} from "../../../../api/apiConstants";
import { postAuthAndData } from "../../../../api/ApiHelper";
import { dispatchLoaderData } from "../../../../actions/route/routeActionHandler";

export const SocialMediaPost = () => {
  const [isAICaptionOpen, setIsAICaptionOpen] = useState(false);
  const [caption, setCaption] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [sourceImages, setSourceImages] = useState([]);
  const [destinationImages, setDestinationImages] = useState([]);
  const [error, setError] = useState("");
  const [isPostButtonDisabled, setIsPostButtonDisabled] = useState(false);
  const [aiGeneratedText, setAiGeneratedText] = useState("");
  const [textForAiCaption, setTextForAiCaption] = useState("");
  const dispatch = useDispatch();

  const {
    isAuthorisedFacebook,
    isAuthorisedInstagram,
    droppedVideos,
    droppedImages,
    handleVideoDrop,
    handleImageDrop,
    setDroppedVideos,
    setDroppedImages,
    setContentDropped,
  } = useAppContext();
  console.log(
    "isAuthorisedFacebook",
    isAuthorisedFacebook,
    isAuthorisedInstagram
  );

  const handleDrop = (e) => {
    e.preventDefault();
    const videoData = e.dataTransfer.getData("video");
    const imageData = e.dataTransfer.getData("image");
    if (videoData.length > 0) {
      handleVideoDrop(videoData);
    }
    if (imageData.length > 0) {
      handleImageDrop(imageData);
    }
  };

  const allowDrop = (e) => {
    e.preventDefault();
  };

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    setCaption(inputText);

    // Check if the input text is within the desired word count range
    const wordCount = inputText
      .split(" ")
      .filter((word) => word.trim() !== "").length;
    if (wordCount < 3 || wordCount > 50) {
      setError("Text must be between 3 and 50 words.");
    } else {
      setError("");
    }
  };

  const toggleAICaption = () => {
    setIsAICaptionOpen(!isAICaptionOpen);
  };

  const handlePostTypeChange = (event) => {
    setMediaType(event.target.value);
    console.log(mediaType);
  };

  const handleLoader = (showBool) => {
    dispatch(dispatchLoaderData(showBool));
  };

  const handleResponseCode = (resp) => {
    if (!resp || resp.code === -1) {
      createNotification(
        "error",
        SOMETHING_WENT_WRONG + ", Please try again later."
      );
      handleLoader(false);

      return false;
    } else {
      return true;
    }
  };

  const createContainerForInstagram = () => {
    setIsPostButtonDisabled(true);
    if (caption && mediaType && droppedVideos.length > 0 && !error) {
      createNotification(
        "info",
        "Uploading to Instagram, please wait. It may take some time"
      );
      handleLoader(true);
      const dataToSend = {
        file_url: droppedVideos[0],
        posttype: "0",
        caption: caption,
      };

      postAuthAndData(
        `${ENGT_PROD_HOST_URI}${GET_INSTAGRAM_CONTAINER}`,
        dataToSend
      )
        .then((res) => {
          console.log("IG container response", res.data.containerId);
          if (handleResponseCode(res)) {
            console.log("cont", res.data.containerId);
            const containerToSend = {
              containerId: res.data.containerId,
            };

            setTimeout(() => {
              postAuthAndData(
                `${ENGT_PROD_HOST_URI}${UPLOAD_REELS_TO_INSTAGRAM}`,
                containerToSend
              )
                .then((response) => {
                  console.log("IG container response", response);
                  if (handleResponseCode(response)) {
                    setIsPostButtonDisabled(false);
                    console.log("Second API request was successful");
                    console.log("Insta Post", response);

                    handleLoader(false);
                    createNotification("success", response.data.success);
                    setCaption("");
                    setMediaType("");
                    setDroppedVideos([]);
                    setDroppedImages([]);
                    setTextForAiCaption("");
                    setAiGeneratedText("");
                  } else {
                    setIsPostButtonDisabled(false);
                    handleLoader(false);
                    console.log("Second API request failed");
                  }
                })
                .catch((error) => {
                  setIsPostButtonDisabled(false);
                  handleLoader(false);
                  console.error("Error in second API request:", error);
                });
            }, 30000);
            //createPostToInstagram(res.data.data.containerId);
            // createNotification("success", secondApiResponse.data.data.success);
            // setCaption("");
            // setMediaType("");
          } else {
            console.log("first API request failed");
          }
        })
        .catch((error) => {
          setIsPostButtonDisabled(false);
          console.error("Error in first API request:", error);
        });
    } else if (!caption) {
      setIsPostButtonDisabled(false);
      createNotification("error", "Please enter the caption");
    } else if (droppedVideos.length < 1) {
      setIsPostButtonDisabled(false);
      createNotification("error", "Please drop the media");
    } else if (!mediaType) {
      setIsPostButtonDisabled(false);
      createNotification("error", "Please select the account");
    }
  };

  const createPostToFacebook = () => {
    if (caption && mediaType && droppedVideos.length > 0 && !error) {
      createNotification("info", "Uploading to Facebook, please wait..");
      handleLoader(true);
      setIsPostButtonDisabled(true);
      const dataToSend = {
        file_url: droppedVideos[0],
        posttype: "0",
        caption: caption,
      };

      postAuthAndData(
        `${ENGT_PROD_HOST_URI}${UPLOAD_POST_TO_FACEBOOK}`,
        dataToSend
      )
        .then((fbresponse) => {
          if (handleResponseCode(fbresponse)) {
            setIsPostButtonDisabled(false);
            console.log("FB API request was successful");
            console.log(fbresponse);
            handleLoader(false);
            createNotification("success", fbresponse.data.success);
            setCaption("");
            setMediaType("");
            setDroppedVideos([]);
            setDroppedImages([]);
            setTextForAiCaption("");
            setAiGeneratedText("");
          } else {
            console.log("FB API request failed");
          }
        })
        .catch((error) => {
          handleLoader(false);
          setIsPostButtonDisabled(false);
          console.error("Error in second API request:", error);
        });
    } else if (!caption) {
      setIsPostButtonDisabled(false);
      createNotification("error", "Please enter the caption");
    } else if (droppedVideos.length < 1) {
      setIsPostButtonDisabled(false);
      createNotification("error", "Please drop the media");
    } else if (!mediaType) {
      setIsPostButtonDisabled(false);
      createNotification("error", "Please select the account");
    }
  };

  // const AIGeneratedTextUrl =
  // ("https://5yiek6g5g0.execute-api.ap-south-1.amazonaws.com/Prod/api/engt/generateText");

  const createAIGeneratedText = () => {
    // setIsPostButtonDisabled(true);
    // if (caption && !error) {
    createNotification("info", "Generating AI text, please wait..");
    const dataToSend = {
      text: textForAiCaption,
      role: "Social Media Writer",
      textlen: 200,
      style: "Professional",
    };

    postAuthAndData(`${ENGT_PROD_HOST_URI}${GENERATE_AI_TEXT}`, dataToSend)
      .then((res) => {
        if (handleResponseCode(res)) {
          setIsPostButtonDisabled(false);
          console.log(" API request was successful");
          console.log(res.data.choices[0].message.content);
          setAiGeneratedText(res.data.choices[0].message.content);
          setCaption(res.data.choices[0].message.content);
          createNotification("success", "AI text is generated");
          //   setCaption("");
          //   setMediaType("");
          //   setDroppedVideos([]);
        } else {
          console.log(" API request failed");
        }
      })
      .catch((error) => {
        setIsPostButtonDisabled(false);
        console.error("Error in second API request:", error);
      });
  };

  return (
    <>
      <div
        className="frame"
        style={{ height: isAICaptionOpen ? "650px" : "500px" }}
      >
        <div className="overlap-group">
          <div className="div">
            <div className="text-wrapper">Schedule Post</div>
            {/* <CloseCircle className="close-circle" /> */}
          </div>
          <div className="rectangle-wrapper">
            {/* <img
            className="rectangle"
            alt="Rectangle"
            src="https://c.animaapp.com/xRFb8S6E/img/rectangle-4374@2x.png"
          /> */}
          </div>
          <div className="add-wrapper">
            <img src={plus_src} alt="Plus" className="r-b-add-reward-img" />
          </div>
          <div className="select-wrapper">
            <select
              onChange={handlePostTypeChange}
              className="select-options"
              value={mediaType}
            >
              <option value={""}>Please select media type</option>
              {isAuthorisedFacebook && (
                <option value={"facebook"}>facebook</option>
              )}
              {isAuthorisedInstagram && (
                <option value={"instagram"}>Instagram</option>
              )}
            </select>
          </div>
          {isAuthorisedFacebook || isAuthorisedInstagram ? (
            <div className="media-icon-wrapper">
              {isAuthorisedFacebook && (
                <div className="social-media-post-fb">
                  <FacebookIcon width="28px" height="28px" /> Connected
                </div>
              )}
              {isAuthorisedInstagram && (
                <div className="social-media-post-ig">
                  <InstaIcon width="20px" height="20px" /> Connected
                </div>
              )}
            </div>
          ) : (
            <div className="no-account-connected">
              {" "}
              <span className="no-account">No account Connected.</span>
              <Link to="/addaccount">
                <button className="add-account-btn">Connect to post</button>
              </Link>
            </div>
          )}
        </div>
        <div className="div-2">
          <div className="div-3">
            <div className="div-4">
              <div className="div-5">
                <textarea
                  rows={4}
                  value={caption}
                  onChange={handleInputChange}
                  placeholder="Add some description"
                  className="text-wrapper-2"
                />
                {error && (
                  <span
                    style={{
                      color: "#ca4848",
                      display: "inline-block",
                      marginTop: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {error}
                  </span>
                )}
                <div className="div-6">
                  <div className="div-7">
                    <img
                      className="vector"
                      alt="Vector"
                      src="https://c.animaapp.com/xRFb8S6E/img/vector-1.svg"
                    />
                    <div className="text-wrapper-3" onClick={toggleAICaption}>
                      AI Caption
                    </div>
                  </div>
                  {/* <div className="div-8">
                  <img
                    className="img"
                    alt="Vector"
                    src="https://c.animaapp.com/xRFb8S6E/img/vector.svg"
                  />
                  <div className="text-wrapper-3">AI #Hashtag</div>
                </div> */}
                </div>
              </div>
            </div>
            <div className={`div-9 ${isAICaptionOpen ? "open" : ""}`}>
              <div className="div-10">
                <div className="text-wrapper-4">AI Generate caption</div>
                {/* <div className="div-wrapper">
                <div className="text-wrapper-5">Generate</div>
              </div> */}
              </div>
              <div className="div-11">
                <div className="div-12">
                  <div className="text-wrapper-6">Context</div>
                  <div className="div-13">
                    <div className="text-wrapper-7">Select Context</div>
                    <img
                      className="chevron-down"
                      alt="Chevron down"
                      src="https://c.animaapp.com/xRFb8S6E/img/chevron-down-2.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="div-4">
                <div className="div-12">
                  <div className="text-wrapper-6">Specific Details</div>
                  <div className="div-14">
                    <input
                      type="text"
                      value={textForAiCaption}
                      onChange={(e) => setTextForAiCaption(e.target.value)}
                      className="text_for_ai"
                      placeholder=" Add Specific Details To Highlight Generate"
                    />
                    <img
                      className="chevron-down-2"
                      alt="Chevron down"
                      src="https://c.animaapp.com/xRFb8S6E/img/chevron-down-1@2x.png"
                    />
                  </div>
                </div>
                <div className="div-12">
                  <div className="text-wrapper-6">AI Generated Text</div>
                  <div className="div-14">
                    {<div className="text-wrapper-8">{aiGeneratedText}</div>}
                    <img
                      className="chevron-down-3"
                      alt="Chevron down"
                      src="https://c.animaapp.com/xRFb8S6E/img/chevron-down-1@2x.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`div-15 ${isAICaptionOpen ? "open" : ""}`}>
            <div className="div-wrapper-2">
              <div className="text-wrapper-9" onClick={createAIGeneratedText}>
                Generate
              </div>
            </div>
          </div>
          <div className="div- c-3">
            <div className="text-wrapper-10">Drag and Drop Video to post</div>
          </div>
          <div
            className="div-16"
            style={{
              border: "1px solid #433d3d",
              borderRadius: "10px",
              minHeight: "200px",
            }}
            onDrop={handleDrop}
            onDragOver={allowDrop}
          >
            {/* <div className="div- c-3">
              <div className="text-wrapper-10">Drag&Drop Media to post</div>
            </div> */}
            <div className="div-17">
              {droppedVideos.length > 0 &&
                droppedVideos.map((video, index) => (
                  // <div key={index} className="video">
                  <>
                    <video controls style={{ width: "auto", height: "100%" }}>
                      <source src={video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      onClick={() => {
                        setDroppedVideos([]);
                        setDroppedImages([]);
                        setContentDropped(false);
                      }}
                      style={{
                        //right: '5px',
                        // marginTop: '-10px', // Negative margin to position the delete icon inside the image container
                        marginRight: "-10px",
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        zIndex: 1,
                      }}
                    >
                      {" "}
                      &#10006;
                      <path
                        d="M18.0584 4.85817C16.7167 4.72484 15.375 4.62484 14.025 4.54984V4.5415L13.8417 3.45817C13.7167 2.6915 13.5334 1.5415 11.5834 1.5415H9.40005C7.45838 1.5415 7.27505 2.6415 7.14172 3.44984L6.96672 4.5165C6.19172 4.5665 5.41672 4.6165 4.64172 4.6915L2.94172 4.85817C2.59172 4.8915 2.34172 5.19984 2.37505 5.5415C2.40838 5.88317 2.70838 6.13317 3.05838 6.09984L4.75838 5.93317C9.12505 5.49984 13.5251 5.6665 17.9417 6.10817C17.9667 6.10817 17.9834 6.10817 18.0084 6.10817C18.3251 6.10817 18.6 5.8665 18.6334 5.5415C18.6584 5.19984 18.4084 4.8915 18.0584 4.85817Z"
                        fill="#9b4646"
                      />
                      <path
                        d="M16.525 7.2835C16.325 7.07516 16.05 6.9585 15.7666 6.9585H5.23329C4.94995 6.9585 4.66662 7.07516 4.47495 7.2835C4.28329 7.49183 4.17495 7.77516 4.19162 8.06683L4.70829 16.6168C4.79995 17.8835 4.91662 19.4668 7.82495 19.4668H13.175C16.0833 19.4668 16.2 17.8918 16.2916 16.6168L16.8083 8.07516C16.825 7.77516 16.7166 7.49183 16.525 7.2835ZM11.8833 15.2918H9.10829C8.76662 15.2918 8.48329 15.0085 8.48329 14.6668C8.48329 14.3252 8.76662 14.0418 9.10829 14.0418H11.8833C12.225 14.0418 12.5083 14.3252 12.5083 14.6668C12.5083 15.0085 12.225 15.2918 11.8833 15.2918ZM12.5833 11.9585H8.41662C8.07495 11.9585 7.79162 11.6752 7.79162 11.3335C7.79162 10.9918 8.07495 10.7085 8.41662 10.7085H12.5833C12.925 10.7085 13.2083 10.9918 13.2083 11.3335C13.2083 11.6752 12.925 11.9585 12.5833 11.9585Z"
                        fill="#9b4646"
                      />
                    </svg>
                  </>
                  // </div>
                ))}
              {droppedImages.length > 0 &&
                droppedImages.map((image, index) => (
                  // <div key={index} className="video">
                  <>
                    <img
                      style={{ width: "auto", height: "100%" }}
                      src={image}
                      alt="Dropped Image"
                    ></img>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      onClick={() => {
                        setDroppedVideos([]);
                        setDroppedImages([]);
                        setContentDropped(false);
                      }}
                      style={{
                        //right: '5px',
                        // marginTop: '-10px', // Negative margin to position the delete icon inside the image container
                        marginRight: "-10px",
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        zIndex: 1,
                      }}
                    >
                      {" "}
                      &#10006;
                      <path
                        d="M18.0584 4.85817C16.7167 4.72484 15.375 4.62484 14.025 4.54984V4.5415L13.8417 3.45817C13.7167 2.6915 13.5334 1.5415 11.5834 1.5415H9.40005C7.45838 1.5415 7.27505 2.6415 7.14172 3.44984L6.96672 4.5165C6.19172 4.5665 5.41672 4.6165 4.64172 4.6915L2.94172 4.85817C2.59172 4.8915 2.34172 5.19984 2.37505 5.5415C2.40838 5.88317 2.70838 6.13317 3.05838 6.09984L4.75838 5.93317C9.12505 5.49984 13.5251 5.6665 17.9417 6.10817C17.9667 6.10817 17.9834 6.10817 18.0084 6.10817C18.3251 6.10817 18.6 5.8665 18.6334 5.5415C18.6584 5.19984 18.4084 4.8915 18.0584 4.85817Z"
                        fill="#9b4646"
                      />
                      <path
                        d="M16.525 7.2835C16.325 7.07516 16.05 6.9585 15.7666 6.9585H5.23329C4.94995 6.9585 4.66662 7.07516 4.47495 7.2835C4.28329 7.49183 4.17495 7.77516 4.19162 8.06683L4.70829 16.6168C4.79995 17.8835 4.91662 19.4668 7.82495 19.4668H13.175C16.0833 19.4668 16.2 17.8918 16.2916 16.6168L16.8083 8.07516C16.825 7.77516 16.7166 7.49183 16.525 7.2835ZM11.8833 15.2918H9.10829C8.76662 15.2918 8.48329 15.0085 8.48329 14.6668C8.48329 14.3252 8.76662 14.0418 9.10829 14.0418H11.8833C12.225 14.0418 12.5083 14.3252 12.5083 14.6668C12.5083 15.0085 12.225 15.2918 11.8833 15.2918ZM12.5833 11.9585H8.41662C8.07495 11.9585 7.79162 11.6752 7.79162 11.3335C7.79162 10.9918 8.07495 10.7085 8.41662 10.7085H12.5833C12.925 10.7085 13.2083 10.9918 13.2083 11.3335C13.2083 11.6752 12.925 11.9585 12.5833 11.9585Z"
                        fill="#9b4646"
                      />
                    </svg>
                  </>
                  // </div>
                ))}
              {/* <div className="group">
                <div className="trash-wrapper">
                </div>
              </div> */}
            </div>
          </div>
          <div className="div-18">
            {/* <div className="div-wrapper-4">
            <div className="text-wrapper-5">Schedule</div>
          </div> */}
            <div
              className="div-wrapper-2"
              style={{
                opacity: isPostButtonDisabled ? "0.5" : "1",
              }}
            >
              <div
                className="text-wrapper-9"
                style={{
                  cursor: "pointer",
                  opacity: isPostButtonDisabled ? "0.4" : "1",
                  pointerEvents: isPostButtonDisabled ? "none" : "auto",
                }}
                onClick={
                  mediaType === "facebook"
                    ? createPostToFacebook
                    : createContainerForInstagram
                }
              >
                Post Now
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
