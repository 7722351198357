import React, { useState, useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import SideMenu from "../common/sideMenu/sideMenu";
import "./home.css";
import { containerHeightCalcFn } from "../common/global";
import HeaderContainer from "../../containers/header/headerContainer";

export default function Home(props) {
  const path = useLocation();
  const [sideMenuFlag, setSideMenuFlag] = useState(true);
  const [headerFlag, setHeaderFlag] = useState(false);

  useEffect(() => {
    if (
      path.pathname === "/login" ||
      path.pathname === "/loading" ||
      path.pathname === "/forgotPassword" ||
      path.pathname === "/register" ||
      path.pathname === "/verify" ||
      path.pathname === "/design" ||
      path.pathname === "/socialmediapopup" ||
      path.pathname === "/addaccount"
      //! this all is for full screen case
      // path.pathname === "/story/StoryStudio"
      // path.pathname === "/story/UploadStories"
    ) {
      setHeaderFlag(false);
    } else {
      setHeaderFlag(true);
    }
    if (
      path.pathname === "/login" ||
      path.pathname === "/loading" ||
      path.pathname === "/forgotPassword" ||
      path.pathname === "/register" ||
      path.pathname === "/verify" ||
      path.pathname === "/settings" ||
      path.pathname === "/design" ||
      path.pathname === "/socialmediapopup" ||
      path.pathname === "/addaccount"
      // path.pathname === "/story/StoryStudio"
      // path.pathname === "/story/UploadStories"
    ) {
      setSideMenuFlag(false);
    } else {
      setSideMenuFlag(true);
    }
  });

  return (
    <div id="">
      {headerFlag ? <HeaderContainer /> : null}
      {sideMenuFlag ? <SideMenu /> : null}
      <div
        id=""
        className={`float-left clearfix ${
          !headerFlag ? `` : `pt-4 pl-4 pb-4 pr-2`
        } ${sideMenuFlag ? `w-80` : `w-100`}`}
        style={{
          height:
            path.pathname === "/login" ||
            path.pathname === "/forgotPassword" ||
            path.pathname === "/register" ||
            path.pathname === "/verify"
              ? // path.pathname === "/story/StoryStudio"
                // path.pathname === "/story/UploadStories"

                `100vh`
              : containerHeightCalcFn(),
          overflowY: "scroll",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
