import React from 'react'

const ThirdPolygon = () => {
  return (
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 0L33.5885 9V27L18 36L2.41154 27V9L18 0Z" fill="#828293"/>
<path d="M18 3L30.9904 10.5V25.5L18 33L5.00962 25.5V10.5L18 3Z" fill="#222229"/>
<path d="M14.178 15.216C14.242 14.224 14.6207 13.4507 15.314 12.896C16.018 12.3413 16.914 12.064 18.002 12.064C18.7487 12.064 19.394 12.1973 19.938 12.464C20.482 12.7307 20.8927 13.0933 21.17 13.552C21.4473 14.0107 21.586 14.528 21.586 15.104C21.586 15.7653 21.41 16.3307 21.058 16.8C20.706 17.2693 20.2847 17.584 19.794 17.744V17.808C20.4233 18 20.914 18.352 21.266 18.864C21.618 19.3653 21.794 20.0107 21.794 20.8C21.794 21.4293 21.65 21.9893 21.362 22.48C21.074 22.9707 20.6473 23.36 20.082 23.648C19.5167 23.9253 18.8393 24.064 18.05 24.064C16.898 24.064 15.9487 23.7707 15.202 23.184C14.466 22.5867 14.0713 21.7333 14.018 20.624H15.778C15.8207 21.1893 16.0393 21.6533 16.434 22.016C16.8287 22.368 17.362 22.544 18.034 22.544C18.6847 22.544 19.186 22.368 19.538 22.016C19.89 21.6533 20.066 21.1893 20.066 20.624C20.066 19.8773 19.826 19.3493 19.346 19.04C18.8767 18.72 18.1513 18.56 17.17 18.56H16.754V17.056H17.186C18.05 17.0453 18.706 16.9013 19.154 16.624C19.6127 16.3467 19.842 15.9093 19.842 15.312C19.842 14.8 19.6767 14.3947 19.346 14.096C19.0153 13.7867 18.546 13.632 17.938 13.632C17.3407 13.632 16.8767 13.7867 16.546 14.096C16.2153 14.3947 16.018 14.768 15.954 15.216H14.178Z" fill="#828293"/>
</svg>
  )
}

export default ThirdPolygon