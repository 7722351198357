import React, { useState, useEffect, Fragment } from "react";
import PlaylistListComponent from "./PlaylistListComponent";
import { Button, Box, OutlinedInput, Checkbox } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import "./playlistManager.css";
import createNotification from "../common/reactNotification";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import PlaylistCardComponent from "./PlaylistCardComponent";
import { useHistory } from "react-router-dom";
import {
  GET_FEEDS_V1,
  ENGT_PROD_BASE_URI,
  CREATE_PLAY_LIST,
  GET_ALL_PLAYLIST,
} from "../../api/apiConstants";
import { getAuthAndData, postAuthAndData } from "../../api/ApiHelper";
import { useSelector, useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";
import { validURL } from '../regex.js';

let feedsGlobalData = [];
let playlistId = 0;


function PlaylistManagerComponent_New() {
  const [feeds, setFeeds] = useState([]);
  const [contentType, setContentType] = useState(2);
  const [editNameFlag, setEditNameFlag] = useState(true);
  const [selectedContent, setSelectedContent] = useState([]);
  const [playlistCollection, setPlaylistCollection] = useState([]);
  const [playlistFlag, setPlaylistFleg] = useState(true);
  const [updatePlaylistFlag, setUpdatePlaylistFlag] = useState(false);
  const [playlistData, setPlaylistData] = useState({
    playlistName: "",
    description: "",
    inputURL: ""
  });

  // const [inputURL, setInputURL] = useState("");

  const [modURL, setModURL] = useState("");

  const [isChecked, setIsChecked] = useState(true);

  const [hashArray, setHashArray] = useState({})

  const handleCheckbox = () => {
    setIsChecked(!isChecked)
  }

  // const validURL = `[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)`
  //const validURL = "^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$"
  // const validURL =  new RegExp('[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)')

  const History = useHistory();
  const Dispatch = useDispatch();






  //  const url = inputURL;

  // function getLastPart(url) {
  //   const parts = url.split('/');
  //   return parts.at(-1);
  // }

  // // console.log(getLastPart(url)); 

  // const newURL = getLastPart(url);

  // // console.log(newURL);

  // if (newURL.includes('?')) {
  //   const getNew = newURL.split('?')
  //   // const getNew=newURL.lastIndexOf("?");
  //   const requiredURL = getNew.slice(0, -1);
  //   setModURL(requiredURL);
  //   console.log('has?', modURL)

  // } else {
  //   const requiredURL = newURL;
  //   setModURL(requiredURL);
  //   console.log('donthave?', modURL)
  // }


  const fetchFeedsData = async (ContentType) => {
    handleLoader(true);
    const body = {
      Index: 1,
      ContentType: ContentType,
      IsTagged: false,
      URL: ""
    };
    try {
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_FEEDS_V1}`,
        body,
        History
      );
      // setFeeds(data?.data?.Feeds);
      feedsGlobalData = data?.data?.Feeds;
      getAllPlayList(contentType);
      customFeeds();
    } catch (err) { }
    handleLoader(false);
  };

  const customFeeds = () => {
    //setFeeds([]);
    const feedsArr = [];
    feedsGlobalData?.forEach((fees) => {
      const obj = {
        url: fees?.Thumbnail_URL,
        displayName: fees?.Thumbnail_Title,
        isSelectde: false,
        postId: fees?.EngagementPostId,
      };
      feedsArr.push(obj);
    });
    setFeeds(feedsArr);
  };

  const onEditFlow = (data) => {
    playlistId = data?.playListId;
    if (data?.name?.length > 0) {
      setEditNameFlag(false);
    }
    let selectedIdsArrr = [];
    data?.contentList?.map((content) => {
      if (content?.postId) {
        selectedIdsArrr.push(content?.postId);
      }
    });
    setSelectedContent(selectedIdsArrr);
    setPlaylistData({
      playlistName: data?.name,
      description: data?.description,
      inputURL: data?.url ? data?.url.toLowerCase() : data?.url,
    });

    console.log("onEditFlow=======%%%", data);
    console.log("selectedContent=======%%%", selectedIdsArrr);
    const feedsArr = [];
    feedsGlobalData?.forEach((fees) => {
      const obj = {
        url: fees?.Thumbnail_URL,
        displayName: fees?.Thumbnail_Title,
        isSelectde:
          selectedIdsArrr?.filter((id) => id == fees?.EngagementPostId)
            ?.length > 0
            ? true
            : false,
        postId: fees?.EngagementPostId,
      };
      //console.log("obj=======%%%", obj);
      feedsArr.push(obj);
    });
    if (data?.isTagged === true) {
      setIsChecked(!isChecked)
    }
    setFeeds(feedsArr);
    setPlaylistFleg(false);
    setUpdatePlaylistFlag(true);
  };

  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
    //    props.routeActionHandler.dispatchLoaderData(showBool);
  };
  const handleName = (event) => {
    setPlaylistData({ ...playlistData, playlistName: event?.target?.value });
  };

  const handleDescription = (event) => {
    setPlaylistData({ ...playlistData, description: event?.target?.value });
  };

  const handleInputURL = (event) => {
    setPlaylistData({ ...playlistData, inputURL: event?.target?.value });
  };

  const getAllPlayList = async (ContentType) => {
    handleLoader(true);
    const body = {
      Content_Type: ContentType
    };
    try {
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_ALL_PLAYLIST}`, body,
        History
      );
      // debugger;
      // data?.data.map(d => {
      //   setHashArray(...d, d.URL_Code)
      // })
      setHashArray(data?.data.map(d => d.URL_Code))
      console.log("getAllPlayList========", data?.data);
      // console.log("hash-array-------------->", hashArray)
      customPlaylist(data?.data);
    } catch (err) { }
    handleLoader(false);
  };

  const customPlaylist = (playlists) => {
    const array = [];
    playlists?.forEach((playlist) => {
      const obj = {
        description: playlist?.Description,
        name: playlist?.Name,
        url: playlist?.URL,
        isTagged: playlist?.IsTagged,
        checkBox: false,
        playListId: playlist?.PlayListId,
        contentList: createCustomContent(playlist?.Post_Ids),
        PlayListCode: playlist?.PlayListCode,
        url_code: playlist?.URL_Code
      };
      // console.log('array', playlist?.URL_Code)
      // console.log('obj------------------------->', obj)
      // setHashArray([playlist?.URL_Code])
      // console.log("hash url----------------->", hashArray)
      array.push(obj);
    });
    setPlaylistCollection(array);
  };

  const createCustomContent = (ids) => {
    const contentArry = [];
    ids?.forEach((id) => {
      let content = feedsGlobalData?.find((p) => p?.EngagementPostId === id);
      const obj = {
        url: content?.Thumbnail_URL,
        displayName: content?.Thumbnail_Title,
        isSelectde: true,
        postId: content?.EngagementPostId,
      };
      contentArry.push(obj);
    });
    return contentArry;
  };

  const checkManadatoryFields = () => {
    let hasMandatoryField = true;
    if (playlistData?.playlistName?.length <= 0) {
      hasMandatoryField = false;
      createNotification("warning", "Enter display name.");
    } else if (selectedContent?.length <= 0) {
      hasMandatoryField = false;
      createNotification("warning", "Add at least one video.");
    }
    return hasMandatoryField;
  };


  // const formatURL = () => {
  //   let mod_url = "";
  //   const parts = playlistData?.inputURL.split('/');
  //   const newURL = parts.at(-1);

  //   if (newURL.includes('?')) {
  //     const getNew = newURL.split('?')
  //     // const getNew=newURL.lastIndexOf("?");
  //     const requiredURL = getNew.slice(0, -1);
  //     mod_url = requiredURL[0]
  //     //setModURL(first);
  //     console.log('has?', modURL)
  //     console.log(mod_url)

  //   } else {
  //     mod_url = newURL;
  //     // setModURL(requiredURL);
  //     console.log('donthave?', mod_url)
  //     // console.log(first)

  //   }
  //   return mod_url;
  // }

  // String.prototype.hashCode = function () {
  //   var hash = 0,
  //     i, chr;
  //   if (this.length === 0) return hash;
  //   for (i = 0; i < this.length; i++) {
  //     chr = this.charCodeAt(i);
  //     hash = ((hash << 5) - hash) + chr;
  //     hash |= 0;
  //   }
  //   return hash;
  // }

  // const str = formatURL()
  // console.log(str, str.hashCode())

  const createPlaylist = async () => {


    if (!isChecked) {
      const formatURL = () => {
        let mod_url = "";
        const parts = playlistData?.inputURL.split('/');
        const newURL = parts.at(-1) === "" || !parts.at(-1)  ? parts.at(-2) : parts.at(-1);

        if (newURL.includes('?')) {
          const getNew = newURL.split('?')
          // const getNew=newURL.lastIndexOf("?");
          const requiredURL = getNew.slice(0, -1);
          mod_url = requiredURL[0]
          //setModURL(first);
          console.log('has?', modURL)
          console.log(mod_url)

        } else {
          mod_url = newURL;
          // setModURL(requiredURL);
          console.log('donthave?', mod_url)
          // console.log(first)

        }
        return mod_url;
      }

      String.prototype.hashCode = function () {
        var hash = 0,
          i, chr;
        if (this.length === 0) return hash;
        for (i = 0; i < this.length; i++) {
          chr = this.charCodeAt(i);
          hash = ((hash << 5) - hash) + chr;
          hash |= 0;
        }
        return hash;
      }

      const str = formatURL()
      console.log(str, str.hashCode())
      var mod_url = formatURL();
      var URL_Code = str.hashCode();

    }

    // const parts = inputURL.split('/');
    // const newURL = parts.at(-1);
    // if (newURL.includes('?')) {
    //   const getNew = newURL.split('?')
    //   // const getNew=newURL.lastIndexOf("?");
    //   const requiredURL = getNew.slice(0, -1);
    //   setModURL(requiredURL);
    //   console.log('has?', modURL)

    // } else {
    //   const requiredURL = newURL;
    //   setModURL(requiredURL);
    //   console.log('donthave?', modURL)
    // }

    if (!isChecked && playlistData?.inputURL === "") {
      createNotification("warning", "Enter URL")
      return;
    }
    if (!isChecked && !validURL.test(playlistData?.inputURL)) {
      createNotification("warning", "URL is not valid")
      return;
    }
    const checkData = checkManadatoryFields();
    if (checkData) {
      handleLoader(true);
      const body = {
        PlayListId: playlistId,
        Post_Ids: selectedContent,
        Name: playlistData?.playlistName,
        Description: playlistData?.description,
        Content_Type: contentType,
        IsTagged: !isChecked || false,
        URL: playlistData?.inputURL,
        MOD_URL: mod_url,
        URL_Code: URL_Code

      };
      try {
        const data = await postAuthAndData(
          `${ENGT_PROD_BASE_URI}${CREATE_PLAY_LIST}`,
          body,
          History
        );
        console.log(data, "playlist data................")
        setPlaylistData({
          playlistName: "",
          description: "",
          inputURL: ""
        });
        // setInputURL("");
        createNotification(
          "success",
          playlistId == 0
            ? "Playlist created successfully"
            : "Playlist updated sucessfully"
        );
        setSelectedContent([]);
        getAllPlayList(contentType);
      } catch (err) { }
      handleLoader(false);
      setPlaylistFleg(true);
    }

  };



  const contentTypeSet = (event) => {
    if (event.target.value === "Story") {
      setContentType(1)
      getAllPlayList(1)
    }
    if (event.target.value === "Post") {
      setContentType(2)
      getAllPlayList(2)
    }


  };

  const createPlaylistfun = () => {
    setEditNameFlag(true);
    setPlaylistFleg(false);
    setUpdatePlaylistFlag(false);
    customFeeds();
    playlistId = 0;
  };
  const handleClick = (Id) => {

    let arr = [...selectedContent]
    let index = arr.indexOf(Id)
    if (Id) {
      if (index >= 0) {
        arr.splice(index, 1)
      } else {
        arr.push(Id)
      }
    }
    console.log('post Id', Id, arr, index)
    setSelectedContent(arr)
  };

  useEffect(() => {

    fetchFeedsData([contentType]);
    //getAllPlayList();
  }, [contentType]);

  console.log("playlistCollection=========", playlistCollection);

  const refreshPage = () => {
    window.location.reload();
    console.log('refresh')
  }

  console.log("hash-array-------------->", JSON.stringify(hashArray))

  return (

    <div>
      <NotificationContainer />
      <div>
        {playlistFlag ? (
          <Fragment>
            <div className="PlaylistSubheader">
              <div className="alinPlaylistType">
                <div className="manage-journey-block">
                  <div className="manage-journey ">Playlists Type</div>
                </div>

                <RadioGroup
                  // style={{ backgroundColor: "white" }}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => contentTypeSet(e)}
                >
                  <FormControlLabel
                    value="Post"
                    control={<Radio checked={contentType == 2} />}
                    label="Shoppable Video"
                  />
                  <FormControlLabel
                    value="Story"
                    control={<Radio checked={contentType == 1} />}
                    label="Story"
                  />


                </RadioGroup>
              </div>

              <div
                // style={{ marginRight: "15px" }}
                className="btn-create-journey float-right text-center pt-2"
                onClick={createPlaylistfun}
              >
                <span className="btn-c-j-text">+ Create Playlist</span>
              </div>

            </div>
            <PlaylistListComponent
              playlistCollection={playlistCollection}
              onEditFlow={onEditFlow}
              contentType={contentType}
              hashArray={hashArray}
            />
          </Fragment>
        ) : (
          <>
            {/* <button type="submit"  onClick={refreshPage}>
              go back
            </button> */}
            <ArrowBackIcon
              onClick={refreshPage}
              style={{ marginRight: '5px', marginBottom: '3px' }} />

            <Fragment>

              <div className="manage-journey-block">
                <div className="manage-journey ">Playlists Type :  <span style={{ fontWeight: "400" }}>{contentType == 1 ? " Story" : " Shoppable Video"} </span></div>
              </div>
              <div className="PlaylistSubheader">
                <OutlinedInput
                  id="myInput"
                  value={playlistData?.playlistName}
                  color="primery"
                  placeholder="Playlist Name*"
                  inputProps={{ maxLength: 20 }}
                  style={{
                    // borderRadius: "30px 30px 30px 30px",
                    minWidth: "30%",
                    height: "35px",
                    color: "#6c757d",
                    backgroundColor: "white",
                    opacity: editNameFlag ? "1" : ".4",
                    //borderColor: "white",
                  }}
                  onChange={(event) => editNameFlag && handleName(event)} />
                <OutlinedInput
                  id="myInput"
                  value={playlistData?.description}
                  color="primery"
                  placeholder="Description"
                  style={{
                    // borderRadius: "30px 30px 30px 30px",
                    minWidth: "30%",
                    height: "35px",
                    color: "#6c757d",
                    backgroundColor: "white",
                    //borderColor: "white",
                  }}
                  inputProps={{ maxLength: 40 }}
                  onChange={(event) => handleDescription(event)} />

                <div
                  style={{ marginRight: "15px" }}
                  className="btn-create-journey float-right text-center pt-2"
                  onClick={createPlaylist}
                >
                  <span className="btn-c-j-text">
                    {updatePlaylistFlag ? "Update Playlist" : "+ Create Playlist"}
                  </span>
                </div>
              </div>


              <div style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '15px'
              }}>
                <input
                  type="checkbox"
                  // checked ={true}
                  checked={!isChecked}
                  onChange={handleCheckbox}
                  style={{ marginTop: '3px' }} />
                {/* <Checkbox
        checked={!isChecked}
        onChange={handleCheckbox}
        // style={{marginTop:'3px'}}
        /> */}
                <p style={{
                  fontSize: "16px",
                  marginLeft: '5px',
                  // fontWeight: 500,
                  color: "#3F4045",
                  // borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                  padding: "1px 0px 2px"
                }}>Tag to Product/Collection</p>
              </div>


              {!isChecked ?
                <>
                  <OutlinedInput
                    id="myInput"
                    value={playlistData?.inputURL}
                    color="primery"
                    placeholder="Enter URL*"
                    style={{
                      // borderRadius: "30px 30px 30px 30px",
                      minWidth: "70%",
                      height: "35px",
                      color: "#6c757d",
                      backgroundColor: "white",
                      // opacity: editNameFlag ? "1" : ".4",
                      //borderColor: "white",
                    }}
                    // inputProps={{ maxLength: 40 }}
                    onChange={(event) => handleInputURL(event)} />
                </>
                :
                <></>}
              <div className="story">
                {feeds.map((feed) => (
                  <PlaylistCardComponent
                    key={feeds.postId}
                    feed={feed}
                    checkBox={true}
                    playlistData={playlistData}
                    setPlaylistData={setPlaylistData}
                    handleClick={handleClick}
                    contentType={contentType} />
                ))}
              </div>
            </Fragment></>
        )}
      </div>
    </div>
  );
}

export default PlaylistManagerComponent_New;