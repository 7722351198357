import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogBox(props) {
        const generatedCodeObj = props.generatedCodeObj
    return (
        <div>
            {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Open alert dialog
            </Button> */}
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={generatedCodeObj.open}
                //open = {false}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => props.handleClose(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-title">{generatedCodeObj.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {generatedCodeObj.text1}
                    </DialogContentText>
                </DialogContent>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" style= {{color:"blue", fontSize:"0.8rem",
                      }}>
                        {generatedCodeObj.text2}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.handleClose(false)} color="primary" style={{borderColor:'#3390FF',border:'1px', opacity:"0"}}>
                        Okay
                    </Button>
                    <Button onClick={() => generatedCodeObj.handleClose(false)} style={{backgroundColor:'#3390FF',color:'#FFFFFF'}} autoFocus>
                          Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
