import React, { Fragment, useState } from 'react';
import './gamePlay.css';

export default function ManageGamePlay(props) {

    return (
        <div id="manage-gameplay-container">
            <span>Manage GamePlay Component</span>
        </div>
    )
}
