import React from 'react'
import "./abTest.style.css"
import { useState, useEffect } from 'react';
import {getAuthAndData} from '../../../api/ApiHelper';
import { ENGT_PROD_BASE_URI, ENGAGEMENT_AB_TESTING} from '../../../api/apiConstants';
import {useHistory} from "react-router-dom"
import AbTestingComponent from './AbTestingComponent';
import RowDataComponent from './RowDataComponent';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchLoaderData } from "../../../actions/route/routeActionHandler"


function AbTestComponent() {
    const dispatch = useDispatch()
    const history = useHistory()
    const [abTestData, setAbTestData] = useState([])

    const handleLoader = (showBool) => {
        dispatch(dispatchLoaderData(showBool))
      }

    const abTestingData = async()=>{
        handleLoader(true)
      try{
        const data = await getAuthAndData(`${ENGT_PROD_BASE_URI}${ENGAGEMENT_AB_TESTING}`,history)
        console.log('abTestingData=====', data)
        setAbTestData(data?.data)
      }
      catch(error){
        console.log(error.msg)
      }
      handleLoader(false)
    }

    useEffect(()=>{
        abTestingData()
    },[])
    return (
        <div>
            <div className='manage-journey-block'>
                    <div className='manage-journey'>AB Testing</div>
                    </div>
            <div className='headerStyle' >
                <div className='headerTextStyleName'>
                    Engagement Name
                </div>
                <div className='headerTextStyle'>
                Customer Engaged
                </div>
                <div className='headerTextStyle'>
                Avg Session (in sec) 
                </div>
                <div className='headerTextStyle'>
                    Winners
                </div>
                <div className='headerTextStyle'>
                Created On
                </div>
                <div className='headerTextStyleA'>
                    Action
                </div>
                
            </div>
            {abTestData?.map((item, index)=>(<RowDataComponent key={index} data={item}  />))}
            
        </div>
    )
}

export default AbTestComponent;
