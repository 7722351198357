import React, { useState } from "react";
import { DataGrid } from '@material-ui/data-grid';

const Invoice = ({activeplan, currentPlanDetails}) => {
  const columns = [
    {
      field: 'current_billing_start',
      headerName: 'Invoice Date',
      type: 'Date',
      width: 200,
      headerAlign: "left",
      align: "left"
    },
    {
      field: 'invoice_id',
      headerName: 'Invoice Id',
      width: 200,
      headerAlign: "left",
      align: "left"
    },
    {
      field: 'payment_status',
      headerName: 'Status',
      width: 200,
      headerAlign: "left",
      align: "left"
    },
    {
      field: 'billing_amount',
      headerName: 'Amount',
      type: 'number',
      width: 200,
      headerAlign: "left",
      align: "left"
    },
    {
      field: 'invoice_url',
      headerName: 'Download PDF',
      width: 200,
      headerAlign: "left",
      align: "left",
      renderCell: (row) => <a href={row.formattedValue} target="_blank">Download</a>
    },
    {
      field: 'payment_intent',
      headerName: 'Pay',
      width: 200,
      headerAlign: "left",
      align: "left"
    }  
  ];

  const rows = [
    {
      id: "inv1",
      user_id: 1,
      customer_id: "cus",
      tenant_id: 141,
      subscription_id: "sub",
      price_id: "price",
      invoice_id: "inv",
      subscription_status: "paid",
      billing_amount: 200,
      billing_currency: "inr",
      current_billing_start: "24-11-2023",
      current_billing_end: "24-12-2023",
      subscription_start_date: "24-10-2023",
      subscription_item: "si",
      payment_status: "paid",
      latest_transaction_date: "24-11-2023",
      invoice_url: "https://www.google.com",
      product_name: "Basic Plan",
      payment_intent: "pi"
    },
    {
      id: "inv2",
      user_id: 1,
      customer_id: "cus",
      tenant_id: 141,
      subscription_id: "sub",
      price_id: "price",
      invoice_id: "inv",
      subscription_status: "paid",
      billing_amount: 200,
      billing_currency: "inr",
      current_billing_start: "24-11-2023",
      current_billing_end: "24-12-2023",
      subscription_start_date: "24-10-2023",
      subscription_item: "si",
      payment_status: "paid",
      latest_transaction_date: "24-11-2023",
      invoice_url: "https://www.google.com",
      product_name: "Basic Plan",
      payment_intent: "pi"
    },
    {
      id: "inv3",
      user_id: 1,
      customer_id: "cus",
      tenant_id: 141,
      subscription_id: "sub",
      price_id: "price",
      invoice_id: "inv",
      subscription_status: "paid",
      billing_amount: 200,
      billing_currency: "inr",
      current_billing_start: "24-11-2023",
      current_billing_end: "24-12-2023",
      subscription_start_date: "24-10-2023",
      subscription_item: "si",
      payment_status: "paid",
      latest_transaction_date: "24-11-2023",
      invoice_url: "https://www.google.com",
      product_name: "Basic Plan",
      payment_intent: "pi"
    },
    {
      id: "inv4",
      user_id: 1,
      customer_id: "cus",
      tenant_id: 141,
      subscription_id: "sub",
      price_id: "price",
      invoice_id: "inv",
      subscription_status: "paid",
      billing_amount: 200,
      billing_currency: "inr",
      current_billing_start: "24-11-2023",
      current_billing_end: "24-12-2023",
      subscription_start_date: "24-10-2023",
      subscription_item: "si",
      payment_status: "paid",
      latest_transaction_date: "24-11-2023",
      invoice_url: "https://www.google.com",
      product_name: "Basic Plan",
      payment_intent: "pi"
    },
    {
      id: "inv5",
      user_id: 1,
      customer_id: "cus",
      tenant_id: 141,
      subscription_id: "sub",
      price_id: "price",
      invoice_id: "inv",
      subscription_status: "paid",
      billing_amount: 200,
      billing_currency: "inr",
      current_billing_start: "24-11-2023",
      current_billing_end: "24-12-2023",
      subscription_start_date: "24-10-2023",
      subscription_item: "si",
      payment_status: "paid",
      latest_transaction_date: "24-11-2023",
      invoice_url: "https://www.google.com",
      product_name: "Basic Plan",
      payment_intent: "pi"
    },
    {
      id: "inv6",
      user_id: 1,
      customer_id: "cus",
      tenant_id: 141,
      subscription_id: "sub",
      price_id: "price",
      invoice_id: "inv",
      subscription_status: "paid",
      billing_amount: 200,
      billing_currency: "inr",
      current_billing_start: "24-11-2023",
      current_billing_end: "24-12-2023",
      subscription_start_date: "24-10-2023",
      subscription_item: "si",
      payment_status: "paid",
      latest_transaction_date: "24-11-2023",
      invoice_url: "https://www.google.com",
      product_name: "Basic Plan",
      payment_intent: "pi"
    },
    {
      id: "inv7",
      user_id: 1,
      customer_id: "cus",
      tenant_id: 141,
      subscription_id: "sub",
      price_id: "price",
      invoice_id: "inv",
      subscription_status: "paid",
      billing_amount: 200,
      billing_currency: "inr",
      current_billing_start: "24-11-2023",
      current_billing_end: "24-12-2023",
      subscription_start_date: "24-10-2023",
      subscription_item: "si",
      payment_status: "paid",
      latest_transaction_date: "24-11-2023",
      invoice_url: "https://www.google.com",
      product_name: "Basic Plan",
      payment_intent: "pi"
    },
    {
      id: "inv8",
      user_id: 1,
      customer_id: "cus",
      tenant_id: 141,
      subscription_id: "sub",
      price_id: "price",
      invoice_id: "inv",
      subscription_status: "paid",
      billing_amount: 200,
      billing_currency: "inr",
      current_billing_start: "24-11-2023",
      current_billing_end: "24-12-2023",
      subscription_start_date: "24-10-2023",
      subscription_item: "si",
      payment_status: "paid",
      latest_transaction_date: "24-11-2023",
      invoice_url: "https://www.google.com",
      product_name: "Basic Plan",
      payment_intent: "pi"
    },
    {
      id: "inv9",
      user_id: 1,
      customer_id: "cus",
      tenant_id: 141,
      subscription_id: "sub",
      price_id: "price",
      invoice_id: "inv",
      subscription_status: "paid",
      billing_amount: 200,
      billing_currency: "inr",
      current_billing_start: "24-11-2023",
      current_billing_end: "24-12-2023",
      subscription_start_date: "24-10-2023",
      subscription_item: "si",
      payment_status: "paid",
      latest_transaction_date: "24-11-2023",
      invoice_url: "https://www.google.com",
      product_name: "Basic Plan",
      payment_intent: "pi"
    },
    {
      id: "inv10",
      user_id: 1,
      customer_id: "cus",
      tenant_id: 141,
      subscription_id: "sub",
      price_id: "price",
      invoice_id: "inv",
      subscription_status: "paid",
      billing_amount: 200,
      billing_currency: "inr",
      current_billing_start: "24-11-2023",
      current_billing_end: "24-12-2023",
      subscription_start_date: "24-10-2023",
      subscription_item: "si",
      payment_status: "paid",
      latest_transaction_date: "24-11-2023",
      invoice_url: "https://www.google.com",
      product_name: "Basic Plan",
      payment_intent: "pi"
    }
  ]

  return(
    <div className="tab-content-container">
      {
        activeplan &&
        <div>
          <div className="subscription-details">
            <div>
              <h4 className="current-subscription">
                Currenty Subscribed to
                <span>
                  {currentPlanDetails.plan_name}
                </span>
              </h4>
              <h4 className="current-renewal">
                Next Credit cycle renewal:
                <span>
                  {currentPlanDetails.current_billing_end}
                </span>
              </h4>
            </div>
          </div>
        </div>
      }
      <div className="billingdetails-container">
        <div className="current-billing-details">
          <h4 className="current-subscription">
            Invoice History
          </h4>
        </div>
        <div style={{marginTop: "30px", height: "430px"}}>
          <DataGrid
            style={{color: "white"}}
            rows={rows}
            columns={columns}
            pagination
            autoPageSize
          />
        </div>
      </div>
    </div>
  );
};

export default Invoice;
