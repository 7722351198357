import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "./engagements.css";
import EngagementsSmartContatiner from "../../containers/engagements/smart/smartContainer";
import EngagementsJourneyContatiner from "../../containers/engagements/journey/journeyContainer";
import LiveViewContatiner from "../../containers/liveView/liveViewContainer";
import { PAGE_PERMISSION } from "../../api/apiConstants";

export default function Engagements(props) {
  console.log(props);
  let history = useHistory();
  const userPermission = useSelector(
    (select) => select.userReducer.userData?.UserPermission
  );

  const hasPermisson = (id) =>
    userPermission?.find((e) => e.permissionId == id);

  return (
    <div id="engagements-container">
      {history.location.pathname === "/engagements/smart" ? (
        <EngagementsSmartContatiner />
      ) : null}

      {history.location.pathname === "/engagements/journey" ? (
        <EngagementsJourneyContatiner />
      ) : null}
    </div>
  );
}
