import React, { useState } from "react";
import Select from "react-select";
import ThumbnailVideoIcon from '../../../igUploads/icon/ThumbnailVideoIcon'
import SubmitButtonIcon from '../../../igUploads/icon/SubmitButtonIcon';
import CancelButtonIcon from '../../../igUploads/icon/CancelButtonIcon';

const SocailPlayList = () => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      color: "#fff",
      backgroundColor: "#27272f", // Custom background color
      borderColor: "#484851", // Custom border color
      boxShadow: "none", // Remove default box-shadow
      "&:hover": {
        borderColor: "#555", // Custom border color on hover
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#555" : "transparent", // Highlight selected option
      color: state.isSelected ? "#48485" : "#000", // Custom text color
      "&:hover": {
        backgroundColor: state.isSelected ? "#555" : "#f1f1f1", // Highlight selected option on hover
      },
      singleValue: (provided, state) => ({
        ...provided,
        color: "#fff", // Custom text color for the selected option
        fontWeight: "bold", // Make the selected option bold
      }),
    }),
    // Customize other components as needed (e.g., singleValue, menu, etc.)
  };
  const [playlistCollection, setPlaylistCollection] = useState([
    {
      value: -1,
      label: "All Playlist",
      playlistCode: "",
    },
    {
      value: 1,
      label: "All Playlist 1",
      playlistCode: "",
    },
  ]);
  const [selectedPlaylist, setSelectedPlaylist] = useState({
    value: -1,
    label: "All Playlist",
    playlistCode: "",
  });
  const onPlaylistChange = (target) => {
    console.log("target=====", target);
    setSelectedPlaylist(target);
  };
  return (
    <div
      className="d-flex flex-row bd-highlight "
      style={{ width: "980px", height: "500px" }}
    >
      <div className="p-2 poppup_container">
        <div className=" m-1">
          <div style={{ color: "#fff", fontSize: "14px" }}>Search Product</div>
          <div>
            <Select
              options={playlistCollection}
              // isDisabled={obj.rewardType?.value!==2}
              name="rewardName"
              onChange={(e) => onPlaylistChange(e)}
              value={selectedPlaylist}
              //defaultValue= {selectedPlaylist}
              style={{ lineHeight: "28px" }}
              maxMenuHeight={150}
              styles={customStyles}
              placeholder="Select your all assets"
            />
          </div>
          <div style={{ color: "#fff", fontSize: "14px", marginTop: "10px" }}>
            Search Product
          </div>
          <div className="search_product_button  my-2 "></div>
        </div>
      </div>
      <div className="d-flex flex-row bd-highlight">
        <div className="p-2 poppup_container p-2"></div>
        <div className="d-flex flex-row bd-highlight justify-content-center gap-2">
          <div
            className="generate_ai_button  my-2"
            style={{ backgroundColor: "#000" }}
          ></div>
        </div>

        <div className="p-2 poppup_container">
          <div className=" m-1">
            <div style={{ color: "#fff", fontSize: "14px" }}>Add Thumbnail</div>
            <div>
              <div
                className="generate_ai_button  my-2"
                
              ></div>
            </div>

            {/* <CircularProgressBar /> */}
          </div>
          <div className="thumbnail_video_icon_playlist">
              <ThumbnailVideoIcon />
              </div>
         <div></div>
         <div className="" style={{marginTop:"220px"}}>
         <div style={{ color: "#fff", fontSize: "14px",  }}>Thumbnail Title</div>
            {" "}
            <input
              className="thumbnail_title_input_playlist"
              type="text"
              id="thumbnailTitle"
              name="thumbnailTitle"
              required
              // value={thumbnailTitle}
              // onChange={handleChangeThumbnail}
            ></input>
          </div>
          <div style={{ color: "#fff", fontSize: "14px",  }}>Playlist</div>
          <div>
            <Select
              options={playlistCollection}
              // isDisabled={obj.rewardType?.value!==2}
              name="rewardName"
              onChange={(e) => onPlaylistChange(e)}
              value={selectedPlaylist}
              //defaultValue= {selectedPlaylist}
              style={{ lineHeight: "28px" }}
              maxMenuHeight={150}
              styles={customStyles}
              placeholder="Select your all assets"
            />
          </div>
          <div className="thumbnail_button_container_playlist">
         
        <div className="thumbnail_button_submit_playlist" 
      >
          <SubmitButtonIcon/>
        </div>
        <div className="thumbnail_button_cancel_playlist"
        
          >
          <CancelButtonIcon/>
        </div>

      </div>
        </div>
      </div>
    </div>
  );
};

export default SocailPlayList;
