import React, { useState, useEffect } from "react";
import AnalysisCard from "./AnalysisCard";
import Select from "react-select";
import "./story.Style.css";
import {
  GET_SOCIAL_DASHBOARD,
  ENGT_PROD_BASE_URI,
  GET_ALL_PLAYLIST
} from "../../api/apiConstants";
import { getAuthAndData, postAuthAndData } from "../../api/ApiHelper";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";

function VideoAnalyticsComponent() {
  const [socialDashboard, setSocialDashboard] = useState({});
  const [days, setdays] = useState(7);
  const [playlistCollection, setPlaylistCollection] = useState([{
    value: -1,
    label: "All Playlist",
    playlistCode: ""
  }]);
  const [selectedPlaylist, setSelectedPlaylist] = useState()
  const History = useHistory();
  const Dispatch = useDispatch();

 

  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
  };

  const getAllPlayList = async () => {
    handleLoader(true);
    const playlistArr = []    
    try {
      const body = {
        Content_Type: 2
      };
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_ALL_PLAYLIST}`,
        body,
        History
      );
      // debugger;
      console.log("getAllPlayList========", data?.data);
      data?.data?.forEach((player) => {
        let obj = {
          value: player?.PlayListId,
          label: player?.Name,
          playlistCode: player?.PlayListCode
        };
        playlistArr.push(obj);
      });
      console.log("playlistArr===",playlistArr)
      setPlaylistCollection([playlistCollection[0], ...playlistArr]);
      setSelectedPlaylist(playlistCollection[0]);
      //onPlaylistChange(playlistArr[0])
    } catch (err) {}
    handleLoader(false);
  };

  const fetchSocialDashboardData = async (playListId) => {
    handleLoader(true);
    var body = {
      Playlist_id: playListId ? playListId : -1,
      LastXDays: days
  }
    try {
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_SOCIAL_DASHBOARD}`, 
        body,
        History
      );
      setSocialDashboard(data.data);
      console.log('setSocialDashboard(data.data)',data.data);
    } catch (err) {}
    handleLoader(false);
  };

  const onPlaylistChange = (target) => {
    console.log("target=====", target)
    setSelectedPlaylist(target);
    fetchSocialDashboardData(target?.value)
  };

  useEffect(() => {
    fetchSocialDashboardData(selectedPlaylist?.value);
  }, [days])
  

  useEffect(() => {
    getAllPlayList()
  }, []);
  console.log("socialDashboard====", socialDashboard);
  return (
    <div>      
      <div className="subHeaderMv12">
      <div className="w-50 float-left clearfix mb-2">
            <div className="overview-heading float-left clearfix mt-2">
              Summary for the{" "}
              <a
                href="#"
                style={{ color: days == 7 ? "" : "#54b699" }}
                onClick={() => setdays(7)}
              >
                {" "}
                Last 7 Days
              </a>{" "}
              |{" "}
              <a
                href="#"
                style={{ color: days == 15 ? "" : "#54b699" }}
                onClick={() => setdays(15)}
              >
                Last 15 Days
              </a>{" "}
              |{" "}
              <a
                href="#"
                style={{ color: days == 30 ? "" : "#54b699" }}
                onClick={() => setdays(30)}
              >
                Last 30 Days
              </a>
            </div>
          </div>   
        <div className="w-30" style={{display:"flex"}}>
        <div className="w-33">
        <div className="overview-heading2 float-left clearfix mt-2">
         Filter By playlist
        </div>
        </div>
      <div className="w-67">
              <Select
               options={playlistCollection}
               // isDisabled={obj.rewardType?.value!==2}
               name="rewardName"
               onChange={(e) => onPlaylistChange(e)}
                value={selectedPlaylist}
               //defaultValue= {selectedPlaylist}
               style={{ lineHeight: "28px" }}
               maxMenuHeight={150}
              />
            </div>
            </div>
            </div>
           
      <div className="storyColection">
        <div className="story">
          <AnalysisCard
            data={`${
              socialDashboard?.ActiveViews ? socialDashboard?.ActiveViews : 0
            }%`}
            dataPercentage={""}
            iconType={1}
            headerA={"Engagement |"}
            headerB={"Active views"}
            headerC={"Active views"}
            headerD={
              "Active engagement from your viewers where they watched more than 3s."
            }
          />
          <AnalysisCard
            data={
              socialDashboard?.TotalWatchedDurationInLastWeek
                ? socialDashboard?.TotalWatchedDurationInLastWeek
                : 0
            }
            dataPercentage={`${
              socialDashboard?.PCTotalWatchedDurationInLastWeek
                ? socialDashboard?.PCTotalWatchedDurationInLastWeek
                : 0
            }%`}
            iconType={2}
            headerA={"Engagement |"}
            headerB={"Watch Times"}
            headerC={"Hours in a week"}
            headerD={"Total number of hours across all your videos."}
          />
          <AnalysisCard
            data={
              socialDashboard?.TotalVideoViews
                ? socialDashboard?.TotalVideoViews
                : 0
            }
            dataPercentage={`${
              socialDashboard?.PCTotalVideoViews
                ? socialDashboard?.PCTotalVideoViews
                : 0
            }%`}
            iconType={3}
            headerA={"Engagement |"}
            headerB={"Video Views"}
            headerC={"Video Views"}
            headerD={"Views across all your videos."}
          />
          <AnalysisCard
            data={
              socialDashboard?.TotalCustomerViews
                ? socialDashboard?.TotalCustomerViews
                : 0
            }
            dataPercentage={`${
              socialDashboard?.PCTotalCustomerViews
                ? socialDashboard?.PCTotalCustomerViews
                : 0
            }%`}
            iconType={4}
            headerA={"Engagement |"}
            headerB={"Number of viewers"}
            headerC={"Viewers"}
            headerD={"Total number of customers who watched."}
          />
          <AnalysisCard
            data={
              socialDashboard?.AvgDurationWatchedInSeconds
                ? socialDashboard?.AvgDurationWatchedInSeconds
                : 0
            }
            dataPercentage={`${
              socialDashboard?.PCAvgDurationWatchedInSeconds
                ? socialDashboard?.PCAvgDurationWatchedInSeconds
                : 0
            }%`}
            iconType={5}
            headerA={"Engagement |"}
            headerB={"Average watch time per viewer"}
            headerC={"Seconds on average"}
            headerD={"Average watch time per viewer."}
          />
          <AnalysisCard
            data={`${
              socialDashboard?.AvgCompletionRate
                ? socialDashboard?.AvgCompletionRate
                : 0
            }%`}
            dataPercentage={""}
            iconType={6}
            headerA={"Engagement |"}
            headerB={"Average completion rate"}
            headerC={"Average completion rate"}
            headerD={"Average completion rate for all your viewed videos."}
          />
          <AnalysisCard
            data={
              socialDashboard?.NumberOfClicks
                ? socialDashboard?.NumberOfClicks
                : 0
            }
            dataPercentage={""}
            iconType={7}
            headerA={"Conversion |"}
            headerB={"CTA Clicks"}
            headerC={"Clicks"}
            headerD={"Total number of clicks to action."}
          />
          <AnalysisCard
            data={`${
              socialDashboard?.EngagementRate
                ? socialDashboard?.EngagementRate
                : 0
            }%`}
            dataPercentage={""}
            iconType={8}
            headerA={"Conversion |"}
            headerB={"Engagement Rate"}
            headerC={"Click throughs"}
            headerD={
              "Percentage of total customers who click on links vs total who watched videos."
            }
          />
        </div>
      </div>
    </div>
  );
}
export default VideoAnalyticsComponent;
