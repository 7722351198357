import { STORY_ACTION_TYPE } from "../../constants/actionTypes";

const INITIAL_STATE = {
    storyId :null
};

const storyIdReducer = (state = INITIAL_STATE, action) =>{
    switch(action.type){
          case STORY_ACTION_TYPE:
              return {
                  ...state,
                  storyId : action.payload
              };
               
    
    default: {
        return state;
      }  
}
}
export default storyIdReducer;