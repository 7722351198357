import React, { useState } from 'react'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import img from "./imgData/inf2c.jpg"
import {makeStyles} from "@material-ui/core/styles"


const useStyles = makeStyles({
  name:{
      marginLeft:'5px',
      fontWeight: 'bold'
  },
  
})

function CardComponent(props) {
  console.log("CardComponent#$%@", props.data)
  const [checked, setChecked] = useState(props?.data?.IsSelected);
  
  const {name} = useStyles()
   



  const changeOnClick = (event)=>{
     setChecked(event.target.checked);
     props.handleClick(props?.data?.Id)
  

  }

 
  return (
    <div style={{marginRight:"25px", marginBottom:"25px" }} 
   // onClick = {handleClick}
    >
         <Card  style={{minWidth:230, maxWidth: 230, height:200,width:230, paddingTop:70, position:'relative' }}>
      <CardMedia 
        component="img"
        width='100%'
       //height="150"
        image={props?.data?.BrandLogo}
        alt="green iguana"
      />
        <FormControlLabel style={{position: 'absolute',bottom: 0}} id = {props?.data?.Id} onChange ={changeOnClick} control={<Checkbox checked={checked}/>} label={props?.data?.Name} />
    </Card>
        </div>
  )
}

export default CardComponent