import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import {
  ATTACH_COUPON,
  ENGT_PROD_BASE_URI,
  GET_ATTACHED_COUPON,
} from "../../api/apiConstants";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";
import { postAuthAndData, getAuthAndData } from "../../api/ApiHelper";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { NotificationContainer } from "react-notifications";
import createNotification from "../common/reactNotification";
import { withStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import "react-notifications/lib/notifications.css";
import {
  CardContent,
  Box,
  Button,
  OutlinedInput,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

function CouponCard({ selectedFeed }) {
  const engagementPostId = selectedFeed?.EngagementPostId;
  const [couponCode, setCouponCode] = useState("");
  const [radioType, setRadioType] = useState(1);
  const [discountAmount, setDiscountAmount] = useState("");
  const [postCouponId, setPostCoupnId] = useState(0);
  //let postCouponId = 0;
  const [couponFlag, setCouponFlag] = useState(false);
  const Dispatch = useDispatch();
  const History = useHistory();
  function onRadio(event) {
    setRadioType(event.target.value);
  }
  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
  };

  const onEventValueChange = (e) => {
    if (radioType == 1 && e.target.value < 101)
      setDiscountAmount(e.target.value);
    if (radioType == 2 && e.target.value <= 999)
      setDiscountAmount(e.target.value);
  };
  const BlueRadio = withStyles({
    root: {
      color: blue[400],
      "&$checked": {
        color: blue[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);
  const getAttachedCoupon = async () => {
    try {
      const data = await getAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_ATTACHED_COUPON}${engagementPostId}`,
        History
      );
      setCouponCode(data?.data?.coupon_code);
      setDiscountAmount(data?.data?.discount_value);
      setRadioType(data?.data?.discountType);
      setPostCoupnId(data?.data?.post_coupon_id);
      // postCouponId = data?.data?.post_coupon_id;

      if (data?.data?.coupon_code == null) {
        setCouponFlag(true);
      }
      console.log("data===%%%", data);
    } catch (err) {}
    handleLoader(false);
  };

  const attachCoupon = async () => {
    if (couponCode) {
      handleLoader(true);
      const body = {
        post_coupon_id: postCouponId,
        engagement_post_id: engagementPostId,
        discountType: radioType,
        coupon_code: couponCode,
        discount_value: discountAmount,
        is_active: true,
      };
      try {
        const data = await postAuthAndData(
          `${ENGT_PROD_BASE_URI}${ATTACH_COUPON}`,
          body,
          History
        );
        createNotification("success", "Coupon Added Succesfully");
      } catch (err) {}
      handleLoader(false);
    } else {
      createNotification("warning", "add coupon code");
    }
  };

  useEffect(() => {
    getAttachedCoupon();
  }, []);
  console.log("couponFlag===", couponFlag);
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        // height: "630px",
        // marginTop: "20px",
        padding: "3%",
      }}
    >
      <NotificationContainer />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // justifyContent: "center",
          margin: "0 auto",
          padding: "10px 20px",
          // border: "1px solid lightGrey ",
          background: "#F1F4FB",
          width: "max-content",
          boxShadow: "0px 6.71538px 8.95385px rgba(0, 0, 0, 0.08)",
          borderRadius: "11.1923px",
          // height: "100px",
        }}
      >
        <div>
          <div></div>
          <div>
            {/* <p style={{ margin: 0 }}>50% off on This</p> */}
            <p
              style={{
                margin: 0,
                textAlign: "center",
                border: "1px dashed black ",
                borderRadius: "8px",
              }}
            >
              {couponCode}
            </p>
          </div>
        </div>
        {/* <Typography>
          {couponCode ? couponCode : "No Coupon Available"}
        </Typography> */}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "start",
        }}
      >
        <Typography style={{ fontSize: "14px", lineHeight: "21px" }}>
          Coupon Title
        </Typography>

        <OutlinedInput
          id="myInput"
          // value
          inputProps={{ maxLength: 20 }}
          color="primary"
          placeholder="Ex blaash"
          style={{
            borderRadius: "8px",
            // minWidth: "60%",
            width: "100%",
            height: "35px",
            color: "#6c757d",
            // marginLeft: "10%",
            //borderColor: "white",
          }}
          onChange={(event) => couponFlag && setCouponCode(event.target.value)}
          // inputProps={{ style: { color: "white" } }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "start",
        }}
      >
        <Typography style={{ fontSize: "14px", lineHeight: "21px" }}>
          Coupon Code{" "}
        </Typography>

        <OutlinedInput
          id="myInput"
          value={couponCode}
          inputProps={{ maxLength: 20 }}
          color="primary"
          placeholder="Please enter text"
          style={{
            borderRadius: "8px",
            // minWidth: "60%",
            width: "100%",
            outline: "none",

            height: "35px",
            color: "#6c757d",
            // marginLeft: "10%",
            borderColor: "#F0F0F0",
          }}
          onChange={(event) => couponFlag && setCouponCode(event.target.value)}
          // inputProps={{ style: { color: "white" } }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "10px 0px",
        }}
      >
        <RadioGroup
          row
          aria-label="engagement-type"
          name="row-radio-buttons-group"
          // style={{ width: "60%" }}
        >
          <FormControlLabel
            control={<BlueRadio />}
            label="Percentage"
            onChange={onRadio}
            value="1"
            checked={radioType == 1}
          />
          <FormControlLabel
            control={<BlueRadio />}
            label="Fixed"
            onChange={onRadio}
            value="2"
            checked={radioType == 2}
          />
        </RadioGroup>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography style={{ fontSize: "14px", lineHeight: "21px" }}>
          Discount Amount
        </Typography>

        <OutlinedInput
          id="myInput"
          type="number"
          value={discountAmount}
          // inputProps={{ maxLength: 3 }}
          color="primery"
          placeholder="Please enter text"
          style={{
            borderRadius: "8px",
            width: "100%",
            height: "35px",
            color: "#6c757d",
            // marginLeft: "10%",
            //borderColor: "white",
          }}
          onChange={(event) => onEventValueChange(event)}
          // inputProps={{ style: { color: "white" } }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          marginTop: "60px",
        }}
      >
        <div
          className="btn-create-engagement float-right text-center pt-2 mr-3"
          style={{ background: "black", borderRadius: "10px" }}
          onClick={attachCoupon}
        >
          <span className="btn-c-e-text">Add Coupon</span>
        </div>
      </div>
    </div>
  );
}

export default CouponCard;
