// Store Action Types
export const ACTION_TYPE = "ACTION_TYPE";

export const SET_USER_DATA = "SET_USER_DATA";
export const SET_JWT_DATA = "SET_JWT_DATA";

export const SET_SHOW_LOADER = "SET_SHOW_LOADER";
export const SET_OPEN_ALERT_DIALOG = "SET_OPEN_ALERT_DIALOG";

export const SET_ENGAGEMENTS = "SET_ENGAGEMENTS";
export const E_S_SET_GOALS = "E_S_SET_GOALS";
export const E_S_SET_TARGET_AUDIENCE = "E_S_SET_TARGET_AUDIENCE";
export const E_S_SET_PRE_RULES = "E_S_SET_PRE_RULES";
export const E_S_DEFINE_JOURNEY = "E_S_DEFINE_JOURNEY";
export const E_S_SET_JOURNEY_BOX = "E_S_SET_JOURNEY_BOX";
export const E_S_SET_REWARDS_AND_BUDGET = "E_S_SET_REWARDS_AND_BUDGET";
export const SET_MASTER_REWARDS = "SET_MASTER_REWARDS";

// Application URL's
export const ACTION_TYPE_URL = "";

export const ES_SET_JOURNEY_DETAILS = "ES_SET_JOURNEY_DETAILS";
export const ES_SET_ALL_JOURNEYS_DATA = "ES_SET_ALL_JOURNEYS_DATA";
export const ES_SET_ALL_JOURNEYS_TASKS = "ES_SET_ALL_JOURNEYS_TASKS";

export const GET_USERS = "GET_USERS";
export const GET_ROLES = "GET_ROLES";

export const SET_ROLES_WITH_PERMISSION_COUNT =
  "SET_ROLES_WITH_PERMISSION_COUNT";
export const SET_PERMISSIONS = "SET_PERMISSIONS";

export const SET_SUMMARY_TOTALS = "SET_SUMMARY_TOTALS";
export const SET_INCREMENTAL_SALES_TOTALS = "SET_INCREMENTAL_SALES_TOTALS";
export const SET_BRAND_HEALTH_TOTALS = "SET_BRAND_HEALTH_TOTALS";
export const SET_LINE_CANVAS_SALES = "SET_LINE_CANVAS_SALES";
export const SET_LINE_CANVAS_DAY_WISE_ACTIVE_ENGAGED =
  "SET_LINE_CANVAS_DAY_WISE_ACTIVE_ENGAGED";
export const SET_LINE_CANVAS_MONTH_WISE_ACTIVE_ENGAGED =
  "SET_LINE_CANVAS_MONTH_WISE_ACTIVE_ENGAGED";
export const SET_BAR_CANVAS_BRAND_HEALTH_DATA =
  "SET_BAR_CANVAS_BRAND_HEALTH_DATA";

export const STORY_ACTION_TYPE = "STORY_ACTION_TYPE";
export const AB_TEST_ACTION_TYPE = "AB_TEST_ACTION_TYPE";
export const USER_DATA_ACTION_TYPE = "USER_DATA_ACTION_TYPE";
export const ACTIVE_CUSTOMER_COUNTS = "ACTIVE_CUSTOMER_COUNTS";
export const EVENT_LIST_COUNT_FOR_LAST_X_HOURES =
  "EVENT_LIST_COUNT_FOR_LAST_X_HOURES";
export const GROSS_VALUE_CUSTOMER_COUNT = "GROSS_VALUE_CUSTOMER_COUNT";
export const VIDEO_WATCHED_DURATION = "VIDEO_WATCHED_DURATION";

export const Dark_Mode = "Dark_Mode";
export const Light_Mode = "Light_Mode";

export const Selected_Product_Array = "Selected_Product_Array";
export const Selected_Youtube_Array = "Selected_Youtube_Array";
export const Delete_Product_Array = "Delete_Product_Array";
export const Delete_Youtube_Array = "Delete_Youtube_Array";

export const LOG_IN_STATE = "LOG_IN_STATE";
export const LOG_OUT_STATE = "LOG_OUT_STATE";
