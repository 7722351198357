import React, { useState, Suspense, useEffect, lazy } from "react";
import "./media.css";
import Select from "react-select";
import { getAuthAndData } from "../../../../../../api/ApiHelper";
import {
  ENGT_PROD_BASE_URI,
  GET_MEDIA_BY_TYPE,
} from "../../../../../../api/apiConstants";
import { TabButton } from "../../index.js";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../../../../../actions/route/routeActionHandler";
const TemplateCategory = lazy(() => import("../Template/TemplateCategory.js"));

const Media = () => {
  // const customStyles = {
  //   control: (provided) => ({
  //     ...provided,
  //     color: "#fff",
  //     backgroundColor: "#27272f", // Custom background color
  //     borderColor: "#484851", // Custom border color
  //     boxShadow: "none", // Remove default box-shadow
  //     "&:hover": {
  //       borderColor: "#555", // Custom border color on hover
  //     },
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isSelected ? "#555" : "transparent", // Highlight selected option
  //     color: state.isSelected ? "#48485" : "#000", // Custom text color
  //     "&:hover": {
  //       backgroundColor: state.isSelected ? "#555" : "#f1f1f1", // Highlight selected option on hover
  //     },
  //     singleValue: (provided, state) => ({
  //       ...provided,
  //       color: "#fff", // Custom text color for the selected option
  //       fontWeight: "bold", // Make the selected option bold
  //     }),
  //   }),
  //   // Customize other components as needed (e.g., singleValue, menu, etc.)
  // };

  const dispatch = useDispatch();
  const handleLoader = (showBool) => {
    dispatch(dispatchLoaderData(showBool));
  };

  const [imageMediaCollection, setImageMediaCollection] = useState([]);
  const [videoMediaCollection, setVideoMediaCollection] = useState([]);
  const [contenttype, setContentType] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [searchImageMedia, setSearchImageMedia] = useState([]);
  const [searchVideoMedia, setSearchVideoMedia] = useState([]);

  const GetAllMediaByType = async (type) => {
    handleLoader(true);

    await getAuthAndData(
      `${ENGT_PROD_BASE_URI}${GET_MEDIA_BY_TYPE}?content_type=${type}`
    )
      .then((response) => {
        if (response.code === 1) {
          if (type === 1) {
            setImageMediaCollection([...response.data]);
          } else if (type === 2) {
            setVideoMediaCollection([...response.data]);
          }
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
    handleLoader(false);
  };

  useEffect(() => {
    GetAllMediaByType(contenttype);
  }, []);

  useEffect(() => {
    (() => {
      if (contenttype === 1 && imageMediaCollection.length <= 0) {
        GetAllMediaByType(contenttype);
      }
      if (contenttype === 2 && videoMediaCollection.length <= 0) {
        GetAllMediaByType(contenttype);
      }
    })();
  }, [contenttype]);

  return (
    <div className="boxtemplate">
      <div
        style={{
          width: "300px",
          height: "610px",
          padding: "10px 10px 10px",
          background: "#27272F",
        }}
      >
        <div
          style={{
            // marginTop: "50px",
            width: "100%",
            background: "#27272F",
            boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
            borderRadius: "16px",
          }}
        >
          <div
            style={{
              width: "100%",
              padding: "10px 10px 10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TabButton className="tab-button" onClick={() => setContentType(1)}>
              Image
            </TabButton>
            <TabButton className="tab-button" onClick={() => setContentType(2)}>
              Video
            </TabButton>
          </div>
          <div className="searchbox" style={{ marginTop: "0px" }}>
            <SearchIcon />
            <input
              id="outlined-basic"
              type="text"
              className="search-input"
              placeholder="Search Media"
              onChange={(event) => {
                if (event.target.value.trim() === "") {
                  setSearchText(null);
                }
                setSearchText(event.target.value.trim());
              }}
            />
          </div>
          <div>
            <>
              {!!searchText && searchText.length >= 3 ? (
                <>
                  {contenttype === 1 && (
                    <>
                      {searchImageMedia.length > 0 ? (
                        <Suspense fallback={<div>Loading</div>}>
                          <TemplateCategory
                            playlist={searchImageMedia}
                            type={contenttype}
                          />
                        </Suspense>
                      ) : (
                        <div>No Media Available</div>
                      )}
                    </>
                  )}
                  {contenttype === 2 && (
                    <>
                      {searchVideoMedia.length > 0 ? (
                        <Suspense fallback={<div>Loading</div>}>
                          <TemplateCategory
                            playlist={searchVideoMedia}
                            type={contenttype}
                          />
                        </Suspense>
                      ) : (
                        <div>No Media Available</div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <>
                    {contenttype === 1 && (
                      <Suspense fallback={<div>Loading</div>}>
                        <TemplateCategory
                          playlistgroup={imageMediaCollection}
                          type={9}
                        />
                      </Suspense>
                    )}
                    {contenttype === 2 && (
                      <Suspense fallback={<div>Loading</div>}>
                        <TemplateCategory
                          playlistgroup={videoMediaCollection}
                          type={10}
                        />
                      </Suspense>
                    )}
                  </>
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Media;
