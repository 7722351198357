import React, { useState, useEffect, useRef, createRef } from "react";
import "./storyStudio.css";
import AppsIcon from "@material-ui/icons/Apps";
import FolderIcon from "@material-ui/icons/Folder";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import LabelIcon from "@material-ui/icons/Label";
import GrainIcon from "@material-ui/icons/Grain";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import PermMediaOutlinedIcon from "@material-ui/icons/PermMediaOutlined";
import image1 from "./images/image1.png";
import image2 from "./images/image2.png";
import image3 from "./images/image3.png";
import image4 from "./images/image4.png";
import image5 from "./images/image5.png";
import image6 from "./images/image6.png";
import image7 from "./images/image7.png";
import image8 from "./images/image8.png";
import image9 from "./images/image9.png";
import background from "./images/background.png";
import VideoLibrary_DescriptionNew from "./VideoLibrary_DescriptionNew";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Stories, { WithSeeMore } from "react-insta-stories";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import AddSticker from "./StoryEditor/AddSticker";
import TextEditorNew from "./StoryEditor/TextEditorNew";
import stickersData from "../../constants/stickersData";

import { useDropzone } from "react-dropzone";
import { FileUploader } from "react-drag-drop-files";
import {
  ENGT_PROD_BASE_URI,
  PREE_SIGNED_URL,
  SOMETHING_WENT_WRONG,
  SAVE_POST_DATA,
} from "../../api/apiConstants";
import { postAuthAndData } from "../../api/ApiHelper";
import { useHistory } from "react-router";
import createNotification from "../common/reactNotification";
import { NotificationContainer } from "react-notifications";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import {
  Textarea,
  InputBase,
  InputLabel,
  TextareaAutosize,
  IconButton,
  ButtonBase,
  Button,
} from "@material-ui/core";

function generateRandomString() {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

function StoryStudio() {
  // const[showTemp, setShowTemp] = useState(false);
  // const [showProductDetail, setShowProductDetail] = useState(false);

  const ImagesList = [
    {
      id: 1,
      image: image1,
    },
    {
      id: 2,
      image: image2,
    },
    {
      id: 3,
      image: image3,
    },
    {
      id: 4,
      image: image4,
    },
    {
      id: 5,
      image: image5,
    },
    {
      id: 6,
      image: image6,
    },
    {
      id: 7,
      image: image7,
    },
    {
      id: 8,
      image: image8,
    },
    {
      id: 9,
      image: image9,
    },
  ];

  const storyImagesInputRef = useRef(null);
  const stagesRef = useRef([]);
  const coverInputRef = useRef(null);
  const history = useHistory();

  let productIds = [];
  let isUploadToAWSSuccess = true;
  const userData = useSelector((select) => select.userReducer.userData);

  const [showTemp, setShowTemp] = useState(false);
  const [showText, setShowText] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  const [showSticker, setShowSticker] = useState(false);
  const [showAI, setShowAI] = useState(false);
  const [showProductDetail, setShowProductDetail] = useState(false);

  const [selectedTemp, setSelectedTemp] = useState("");

  const [designSaved, setDesignSaved] = useState(false);
  const [images, setImages] = useState([]);
  const [toBeSavedFiles, setToBeSavedFiles] = useState([]);

  const [productDetail, setProductDetail] = useState([]);

  const [presignedUploadUrlArr, setPresignedUploadUrlArr] = useState([]);
  const [preAssignthumbnailArr, setPreAssignthumbnailArr] = useState([]);
  const [imgInitialDimensions, setImgInitialDimensions] = useState([]);
  const [thumbnailUrl, setThumbnailUrl] = useState([]);
  const [thumbnailContent, setThumbnailContent] = useState([]);
  const [postContent, setPostContent] = useState([]);
  const [fileObj, setFileObj] = useState([]);

  const [imgArr, setImgArr] = useState([]);
  const[mainImg, setMainImg] = useState("");
  const[selectedImg, setSelectedImg] = useState(false)

  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [discription, setDiscription] = useState({
    tag: "",
    discription: "",
    targetCatogery: "",
    caption: "",
    fontFamily: "",
    ColorPicker: "",
    buttonType: 0,
    contentType: 0,
    productId: "",
    thumbnailTitle: "",
    liveDateTime: "",
  });
  const [textEditorInput, setTextEditorInput] = useState({
    id: 0,
    storyIndex: 0,
    text: "",
    fontSize: 18,
    fontStyle: "Roboto",
    fontWeight: 400,
    fontColor: "#000000",
    fontBackgroundColor: "#ffffff",
  });

  const fileTypes = ["JPG", "PNG", "GIF"];

  const [texts, setTexts] = useState([
    {
      id: 0,
      storyIndex: 0,
      x: 100,
      y: 100,
      width: null,
      height: null,
      text: "Hello World",
      textColor: "#000000",
      backgroundColor: "#ffffff",
      fontFamily: "Roboto",
      fontSize: 18,
    },
  ]);

  const addStickerToPanel = ({ src, width, x, y }) => {
    setImages((currentImages) => [
      ...currentImages,
      {
        storyIndex: currentStoryIndex,
        width,
        x,
        y,
        src,
        resetButtonRef: createRef(),
      },
    ]);
  };

  const handleAddText = () => {
    setTextEditorInput({
      id: texts.length + 1,
      text: "Add Text",
      fontSize: 18,
      fontStyle: "Roboto",
      fontWeight: 400,
      fontColor: "#000000",
      fontBackgroundColor: "#84FFF8",
    });
  };

  const initialStoryInput = {
    selectedCoverFile: null,
    thumbnailTitle: "",
    category: "",
    tag: "",
    ctaText: "",
    storySelectedFiles: [],
    coverFileUrl: "",
  };
  const [storyInputs, setStoryInputs] = useState(initialStoryInput);

  const handleStoryImagesChange = (e) => {
    const files = e.target.files;
    const storySelectedFiles = [];
    for (let i = 0; i < files.length; i++) {
      storySelectedFiles.push(files[i]);
    }
    setStoryInputs({
      ...storyInputs,
      storySelectedFiles: [
        ...storyInputs.storySelectedFiles,
        ...storySelectedFiles,
      ],
    });
  };

  const handleCoverFileChange = (e) => {
    const file = e.target.files[0];
    setStoryInputs({
      ...storyInputs,
      selectedCoverFile: file,
      coverFileUrl: URL.createObjectURL(file),
    });
    var reader = new FileReader();

    reader.onload = function () {
      // console.log(reader.result);
    };

    reader.readAsDataURL(file);
    // newFileUploadThumbnail([file]);
  };

  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };

  const drag = () => {
    document.getElementById("uploadFile").parentNode.className =
      "draging dragBox";
  };

  const drop = () => {
    document.getElementById("uploadFile").parentNode.className = "dragBox";
  };

  useEffect(() => {
    let textExists = false;
    let newTexts = texts.map((text) => {
      if (
        text.storyIndex == currentStoryIndex &&
        text.id == textEditorInput.id
      ) {
        textExists = true;
        return {
          ...text,
          storyIndex: currentStoryIndex,
          text: textEditorInput.text,
          textColor: textEditorInput.fontColor,
          backgroundColor: textEditorInput.fontBackgroundColor,
          fontFamily: textEditorInput.fontStyle,
          fontSize: textEditorInput.fontSize,
        };
      }
      return text;
    });
    if (!textExists) {
      newTexts.push({
        id: textEditorInput.id,
        x: 100,
        y: 100,
        width: null,
        height: null,
        storyIndex: currentStoryIndex,
        text: textEditorInput.text,
        textColor: textEditorInput.fontColor,
        backgroundColor: textEditorInput.fontBackgroundColor,
        fontFamily: textEditorInput.fontStyle,
        fontSize: textEditorInput.fontSize,
      });
    }
    setTexts(newTexts);
  }, [textEditorInput]);

  const handleResponseCode = (resp) => {
    if (!resp || resp.code === -1) {
      createNotification(
        "error",
        SOMETHING_WENT_WRONG + ", Please try again later."
      );
      //   handleLoader(false);
      return false;
    } else {
      return true;
    }
  };

  const uploadDataOnAws = () => {
    // //handleLoader(true);
    //local variable isUploadSuccess = true
    try {
      debugger;
      console.log("uploadDataOnAws=====$$$");
      // if (discription.contentType != 5) {
      console.log("uploadDataOnAws=====$$$", presignedUploadUrlArr);
      //   if (presignedUploadUrlArr?.length == 0) isUploadToAWSSuccess = false;
      // }
      presignedUploadUrlArr?.forEach((presignedUploadUrl) => {
        //if()
        let fobj = fileObj.find((e) => e.FileId == presignedUploadUrl.FileId);
        console.log("fileObj=====$$$", fobj);
        let conetntType = addContent(fobj);
        console.log("conetntType=====$$$", conetntType);
        const requestOptions = {
          method: "PUT",
          headers: {
            // "Content-Type": fileObj.find(
            //   (e) => e.FileId == presignedUploadUrl.FileId
            // ).file.type,

            "Content-Type": conetntType,
            "Access-Control-Allow-Origin": "*",
          },
          body: fileObj.find((e) => e.FileId == presignedUploadUrl.FileId).file,
        };
        // //handleLoader(false);
        console.log("requestOptions====$$", requestOptions);
        console.log("presignedUrl url", presignedUploadUrl.PreSignedUrl);
        fetch(new Request(presignedUploadUrl.PreSignedUrl, requestOptions))
          .then((response) => {
            //console("uploadDataOnAws Flow1", response);

            if (response.status == 200) {
              //responsehandler(true);
              //isUploadSuccess = false;
              //setFlag(true);
              uploadDataOnAws2();
            } else {
              //setFlag(false);
              // responsehandler(false);
              //If any file is failing to upload - We shall not create the Post - Please try again
              isUploadToAWSSuccess = false;
              createNotification(
                "error",
                SOMETHING_WENT_WRONG +
                  "Error uploading image to cloud, please try again."
              );
            }
          })
          .catch((err) => console.log({ err }));
      });
      if (presignedUploadUrlArr.length > 0) {
        createEngagementPost(isUploadToAWSSuccess);
      }
    } catch (error) {
      console.log("uploadDataOnAws error", error);
      isUploadToAWSSuccess = false;
      createNotification(
        "error",
        SOMETHING_WENT_WRONG +
          "Error uploading image to cloud, please try again."
      );
      //   //handleLoader(false);
    }
  };

  const addContent = (fobj) => {
    console.log("addContent ffob======", fobj);
    console.log("addContent ffobExtenssion======", fobj);
    let contentFile;
    if (fobj.contentType == 1) {
      contentFile = `image/${fobj?.extension}`;
    } else {
      contentFile = `video/${fobj?.extension}`;
    }
    return contentFile;
  };

  const uploadDataOnAws2 = () => {
    //handleLoader(true);
    try {
      if (preAssignthumbnailArr?.length == 0) isUploadToAWSSuccess = false;
      console.log("uploadDataOnAws2=====$$$", preAssignthumbnailArr);
      console.log("uploadDataOnAws2Thumbnail=====$$$", thumbnailUrl);
      preAssignthumbnailArr?.forEach((presignedUploadUrl) => {
        let thumbnailFileObj = thumbnailUrl.find(
          (e) => e.FileId == presignedUploadUrl.FileId
        );

        console.log("thumbnailFileObj=====$$$", thumbnailFileObj);
        let conetntType = addContent(thumbnailFileObj);
        console.log("thumbnail conetntType =====$$$", conetntType);
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": conetntType,
            "Access-Control-Allow-Origin": "*",
          },
          body: thumbnailUrl.find((e) => e.FileId == presignedUploadUrl.FileId)
            .file,
        };
        ////console("before fatch data in thumbnailContent");
        fetch(
          new Request(presignedUploadUrl.PreSignedUrl, requestOptions)
        ).then((response) => {
          ////console("uploadDataOnAws Flow2", response.status);
          if (response.status == 200) {
            //setFlag(true);
            //handleLoader(false);
            console.log("NEW CHNAGE==");
          } else {
            //handleLoader(false);
            isUploadToAWSSuccess = false;
            createNotification(
              "error",
              SOMETHING_WENT_WRONG +
                "Error uploading image to cloud, please try again."
            );
            //setFlag(false);
          }
        });
      });
      //Once Thumb nail is added - Save Post Data
    } catch {
      isUploadToAWSSuccess = false;
      //handleLoader(false);

      createNotification(
        "error",
        SOMETHING_WENT_WRONG +
          "Error uploading image to cloud, please try again."
      );
    }

    //After the 2nd Upload Initiate the Post
  };

  const createEngagementPost = (isAWSSuccess) => {
    console.log("Success Flow ===", isAWSSuccess);
    if (isAWSSuccess) {
      //Send Data to Server
      createPost();
    } else {
      createNotification(
        "error",
        SOMETHING_WENT_WRONG +
          "Error uploading image to cloud, please try again."
      );
    }
  };

  const checkManadatoryFields = () => {
    let hasMandatoryFieldmissing = true;
    if (!(thumbnailUrl?.length > 0)) {
      hasMandatoryFieldmissing = false;
      createNotification("warning", "Add banner image.");
    } else if (
      !(storyInputs.thumbnailTitle?.length > 0) &&
      discription.contentType != 5
    ) {
      hasMandatoryFieldmissing = false;
      createNotification("warning", "Enter banner title.");
      hasMandatoryFieldmissing = false;
    } else if (!(storyInputs.thumbnailTitle.length > 0)) {
      hasMandatoryFieldmissing = false;
      createNotification("warning", "Enter description.");
    }
    ////console("hasMandatoryFieldmissing", hasMandatoryFieldmissing);
    return hasMandatoryFieldmissing;
  };

  const createPost = () => {
    // debugger;\
    console.log(userData, "userdata");
    if (!userData) {
      Auth.signOut();
      history.push("/login");
    }
    //handleLoader(true);
    checkManadatoryFields();
    const body = {
      userId: userData?.UserID,
      description: storyInputs?.thumbnailTitle,
      tags: "",
      contentType: 1,
      postText: "",
      textfontFamily: "",
      captionFont: "",
      Video_duration: 0,
      captionColor: "",
      captionFont: "",
      ctaImageId: 0,
      buttonType: 0,
      buttonHeader: "",
      caption: "",
      buttonHeader: "",
      cTAUrl: "",
      postContents: postContent,
      ProductIdList: productIds,
      Thumbnail_URL: thumbnailContent[0]?.fileUrl,
      Thumbnail_Title: storyInputs?.thumbnailTitle,
      liveDateTime: 1674680400000,
      Button_CTA: storyInputs?.ctaText,
    };

    postAuthAndData(`${ENGT_PROD_BASE_URI}${SAVE_POST_DATA}`, body, history)
      .then((res) => {
        if (handleResponseCode(res)) {
          createNotification("success", "Post created successfully.");

          setFileObj([]);
          setThumbnailUrl([]);
          setThumbnailContent([]);
          // setThumbnailUrl([]);
          setProductDetail([]);
          productIds = [];
          setToBeSavedFiles([]);
          setDesignSaved(false);
          // setPostContent([]);
          setTexts([]);
          setPreAssignthumbnailArr([]);
          setPresignedUploadUrlArr([]);
          setImgInitialDimensions([]);
          setImages([]);
          // storyImagesInputRef.current.value = "";
          // coverInputRef.current.value = "";
          // stagesRef.current.map((stage, index) => {
          //   stagesRef.current[index] = null;
          // });
          setStoryInputs(initialStoryInput);
          //   setActiveStep(1);
          ////handleLoader(false);
          console.log("end");
        }
      })
      .catch((err) => {
        createNotification("error", "Something went wrong.");
      });
  };

  const handleClickTemplate = () => {
    setShowTemp(true);
    setShowText(false);
    setShowMedia(false);
    setShowSticker(false);
    setShowAI(false);
  };

  const handleClickText = () => {
    setShowTemp(false);
    setShowText(true);
    setShowMedia(false);
    setShowSticker(false);
    setShowAI(false);
  };

  const handleClickMedia = () => {
    setShowTemp(false);
    setShowText(false);
    setShowMedia(true);
    setShowSticker(false);
    setShowAI(false);
  };

  const handleClickSticker = () => {
    setShowTemp(false);
    setShowText(false);
    setShowMedia(false);
    setShowSticker(true);
    setShowAI(false);
  };

  const handleClickAI = () => {
    setShowTemp(false);
    setShowText(false);
    setShowMedia(false);
    setShowSticker(false);
    setShowAI(true);
  };

  const handleTempImageClick = (url) => {
    setSelectedTemp(url);
  };

  const templateOnClick = (imgUrl) => {
    //   e.preventDefault();

    const imgObj = {};
    imgObj.id = Math.random();
    imgObj.url = imgUrl;

    setImgArr((imgArr) => [...imgArr, imgObj]);
  };

  const imageArrOnClick = (arrUrl) => {

    setMainImg(arrUrl);
    setSelectedImg(true)
  }

  console.log(imgArr, "]]]]]]]]]]]]]imgArr");

  return (
    <>
      <NotificationContainer />
     
      <div className="row" style={{ height: "100vh", margin: "0" }}>
      <nav
        class="navbar navbar-light"
        style={{
          backgroundColor: "rgb(30, 30, 30)",
          borderBottom: "1px solid white",
        }}
      >
        <div class="container-fluid">
          <a class="navbar-brand">
            <KeyboardBackspaceIcon
              style={{ color: "white", fontSize: "35px" }}
            />
          </a>
          <form class="d-flex">
            {/* <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/> */}
            {/* <button
              class="btn"
              type="submit"
              style={{
                marginRight: "5px",
                border: "1px solid white",
                color: "white",
              }}
            >
              Add Product
            </button> */}

            <button
              class="btn"
              type="submit"
              style={{ backgroundColor: "#FFAD00", fontWeight: "600" }}
              onClick={() => uploadDataOnAws()}
            >
              Publish
            </button>
          </form>
        </div>
      </nav>
        <div
          className="col-sm-1"
          style={{
            // maxWidth: "fit-content",
            backgroundColor: "rgb(30, 30, 30)",
            display: "flex",
            flexDirection: "column",
            // width: "6%",
            borderRight: "1px solid white",
            padding: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "200px",
              backgroundColor: showTemp ? "white" : "",
              cursor: "pointer",
              marginBottom: "15px",
              padding: "10px",
            }}
            onClick={handleClickTemplate}
          >
            <AppsIcon
              style={{ color: showTemp ? "black" : "white", fontSize: "40px" }}
            />
            <p
              style={{
                color: showTemp ? "black" : "white",
                fontSize: "14px",
                marginBottom: "0px",
              }}
            >
              Templates
            </p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
              marginBottom: "15px",
              backgroundColor: showMedia ? "white" : "",
              padding: "10px",
            }}
            onClick={handleClickMedia}
          >
            <PermMediaOutlinedIcon
              style={{ color: showMedia ? "black" : "white", fontSize: "40px" }}
            />
            <p
              style={{
                color: showMedia ? "black" : "white",
                fontSize: "14px",
                marginBottom: "0px",
              }}
            >
              Media
            </p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
              marginBottom: "15px",
              backgroundColor: showText ? "white" : "",
              padding: "10px",
            }}
            onClick={handleClickText}
          >
            <TextFieldsIcon
              style={{ color: showText ? "black" : "white", fontSize: "40px" }}
            />
            <p
              style={{
                color: showText ? "black" : "white",
                fontSize: "14px",
                marginBottom: "0px",
              }}
            >
              Text
            </p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
              marginBottom: "15px",
              backgroundColor: showSticker ? "white" : "",
              padding: "10px",
            }}
            onClick={handleClickSticker}
          >
            <LabelOutlinedIcon
              style={{
                color: showSticker ? "black" : "white",
                fontSize: "40px",
              }}
            />
            <p
              style={{
                color: showSticker ? "black" : "white",
                fontSize: "14px",
                marginBottom: "0px",
              }}
            >
              Stickers
            </p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
              marginBottom: "15px",
              backgroundColor: showAI ? "white" : "",
              padding: "10px",
            }}
            onClick={handleClickAI}
          >
            <GrainIcon
              style={{ color: showAI ? "black" : "white", fontSize: "40px" }}
            />
            <p
              style={{
                color: showAI ? "black" : "white",
                fontSize: "14px",
                marginBottom: "0px",
              }}
            >
              AI Images
            </p>
          </div>
        </div>

        <div
          className="col-sm-3"
          style={{
            //   position: "fixed",
            //   height: "101vh",
            //   width: "350px",
            backgroundColor: "rgb(30, 30, 30)",
            overflowY: "scroll",
            //   marginLeft: "101px",
          }}
        >
          {!showTemp && !showMedia && !showAI && !showText && !showSticker && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={background}
                style={{ width: "80%", marginTop: "200px" }}
              />
            </div>
          )}

          {/* Template code start */}

          {showTemp && (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <h style={{ color: "white", fontWeight:"600" }}>Add Template</h>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  marginTop: "20px",
                }}
              >
                <input
                  style={{ width: "-webkit-fill-available" }}
                  type="search"
                  id="gsearch"
                  name="gsearch"
                />
                <div style={{ marginLeft: "5px", cursor: "pointer" }}>
                  <SearchIcon style={{ color: "white" }} />
                </div>
              </div>
              <div className="col-12 d-flex justify-content-evenly flex-wrap">
                {ImagesList.map((val) => {
                  return (
                    <div
                    //   onClick={() => storyImagesInputRef()}
                    //     ref={storyImagesInputRef}
                    //       onChange={handleStoryImagesChange}

                    // onClick={setCurrentStoryIndex}
                    onClick={() => templateOnClick(val.image)}

                    >
                      {/* <button
                        style={{
                          color: "black",
                          background: "white",
                          padding: "5px 15px",
                          border: "1px solid white",
                          borderRadius: "9px",
                          fontSize: "14px",
                        }}
                        //   onClick={() => storyImagesInputRef.current.click()}
                        onClick={() => templateOnClick(val.image)}
                      > */}
                        <img
                          //   className="col-3 img-fluid img-thumbnail"
                          style={{ height: "150px", marginTop: "10px" }}
                          src={val.image}
                          alt="val.alt_description"
                          ref={storyImagesInputRef}
                          onChange={handleStoryImagesChange}
                        />
                      {/* </button> */}
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/* Template code end */}

          {/* Text code start */}
          {showText && (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                  flexDirection: "column",
                }}
              >
                <h style={{ color: "white", marginBottom: "50px",  fontWeight:"600" }}>Add Text</h>

                {storyInputs.storySelectedFiles.length == [] ? (
                  <h style={{ color: "white" }}>Upload image in media</h>
                ) : (
                  <>
                    <TextEditorNew
                      textEditorInput={textEditorInput}
                      setTextEditorInput={setTextEditorInput}
                      handleAddText={handleAddText}
                    />
                    {designSaved && (
                      <div
                        style={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          bottom: 0,
                          left: 0,
                          background: "rgba(0,0,0,0.15)",
                        }}
                        onClick={() => {}}
                      ></div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}

          {/* Text code end */}

          {/* Media code start */}
          {showMedia && (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                  flexDirection: "column",
                }}
              >
                <h style={{ color: "white", marginBottom: "50px", fontWeight:"600" }}>
                  Add Media
                </h>

                <button
                  style={{
                    color: "black",
                    background: "white",
                    padding: "5px 15px",
                    border: "1px solid white",
                    borderRadius: "9px",
                    fontSize: "14px",
                  }}
                  onClick={() => storyImagesInputRef.current.click()}
                >
                  <input
                    type="file"
                    ref={storyImagesInputRef}
                    multiple
                    accept="image/*,video/*"
                    style={{ visibility: "hidden", width: "0px" }}
                    onChange={handleStoryImagesChange}
                  />
                  UPLOAD
                </button>
              </div>
            </div>
          )}

          {/* Media code end */}

          {/* Sticker code start */}
          {showSticker && (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                  flexDirection: "column",
                }}
              >
                <h style={{ color: "white", marginBottom: "50px", fontWeight:"600" }}>
                  Add Sticker
                </h>

                {storyInputs.storySelectedFiles.length == [] ? (
                  <h style={{ color: "white" }}>Upload image in media</h>
                ) : (
                  <>
                    <div
                      className="col-12 d-flex justify-content-evenly flex-wrap"
                      style={{
                        //   display: "flex",
                        //   flexDirection: "row",
                        //   justifyContent: "space-between",
                        //   gap: "10px",
                        //   border: "1px dashed #017efa",
                        //   borderRadius: "8px",
                        //   overflow: "hidden",
                        //   padding: "10px",
                        //   flexWrap: "wrap",
                        //   margin: "0px",
                        //   height: "45vh",
                        //   fontSize: "14px",
                        //   position: "relative",
                        marginTop: "5px",
                        gap: "50px 110px",
                        overflowY: "scroll",
                        marginBottom: "100px",
                      }}
                    >
                      {stickersData.map((sticker) => {
                        return (
                          <button
                            style={{
                              height: "80px",
                              width: "80px",
                              background: "none",
                              border: "none",
                              margin: 0,
                              padding: 0,
                            }}
                            onMouseDown={() => {
                              addStickerToPanel({
                                src: sticker.url,
                                width: sticker.width,
                                x: 100,
                                y: 100,
                              });
                            }}
                          >
                            <img
                              alt={sticker.alt}
                              src={sticker.url}
                              width={sticker.width}
                              // height={"80px"}
                              style={{ borderRadius: "10px" }}
                            />
                          </button>
                        );
                      })}
                      {designSaved && (
                        <div
                          style={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            bottom: 0,
                            left: 0,
                            background: "rgba(0,0,0,0.15)",
                          }}
                          onClick={() => {}}
                        ></div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          {/* Sticker code end */}

          {/* AI code start */}
          {showAI && (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                  flexDirection: "column",
                }}
              >
                <h style={{ color: "white", marginBottom: "20px", fontWeight:"600" }}>
                  AI Generated Images
                </h>
<div>
<h style={{ color: "white", fontSize:"14px" }}>I want to see my product</h>

                <select id="cars" name="cars" style={{ width: "90%", marginBottom:"10px", borderRadius: "10px", padding: "5px" }}>
  <option value="volvo">in</option>
  <option value="saab">at</option>
  <option value="fiat">located in</option>
  <option value="audi">close by</option>
</select>


<h style={{ color: "white", marginTop:"20px", fontSize:"14px" }}>Description</h>

                <textarea
                  type="text"
                  //    cols="40"
                  rows="3"
                  placeholder="Type anything..."
                  style={{ width: "90%", borderRadius: "10px", padding: "5px",  }}
                />

<button
                  style={{
                    color: "black",
                    background: "white",
                    padding: "5px 15px",
                    border: "1px solid white",
                    borderRadius: "10px",
                    fontSize: "14px",
                    marginTop: "10px",
                    width: "90%",
                    fontWeight:"600"
                  }}
                >
                  Generate Images
                </button>
                </div>

               
                    </div>
              </div>
        
          )}

          {/* AI code end */}
        </div>

        <div className="col-sm-6" style={{ background: "black", padding:"0" }}>

            <div style={{display:"flex", justifyContent:"center"}}>


            <img
                          //   className="col-3 img-fluid img-thumbnail"
                          style={{ height: "480px", width:"280px", backgroundColor:"white", marginTop:"20px" }}
                         src={mainImg}
                          alt="val.alt_description"
                        />

            </div>

{ imgArr.length >= 1 &&
            <div 
            style={{backgroundColor:"rgb(30, 30, 30)", position:"absolute", bottom:"0", width:"100%", overflowX:"scroll", display:"flex", flexDirection:"row",gap:"20px", paddingLeft:"20px", paddingRight:"20px"}}
            // onClick={}
            >

            {imgArr.map((img) => {
                  return (
                    <div
                    onClick={() => imageArrOnClick(img.url)}
                    >
                        <img
                          //   className="col-3 img-fluid img-thumbnail"
                          style={{ height: "150px", marginTop: "10px", border: selectedImg ? "4px solid darkgoldenrod" : "" }}
                          src={img.url}
                        //   alt="val.alt_description"
                        />
                    </div>
                  );
                })}
            </div>
}



        </div>

        <div className="col-sm-2" style={{ background: "rgb(30, 30, 30)",overflowY:"scroll" }}>

        <div
            //   style={{
            //     background: "rgb(30, 30, 30)",
            //     // boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
            //     // borderRadius: "16px",
            //     width: "22%",
            //     padding: "10px",
            //     position:"absolute",
            //     right: 0,
            //     height:"92%",
            //     overflowY:"scroll"

            //   }}
            >

<div style={{border:"1px dashed white", padding:"10px", borderRadius:"10px"}}>
                    <div style={{ marginBottom: "10px" }}>
                      <InputLabel style={{ fontSize: "14px", color:"white" }}>
                        Cover Image
                      </InputLabel>
                      <div
                        style={{
                          display: "flex",
                          gap: "40px",
                          alignItems: "center",
                          justifyContent:"space-between"
                        }}
                      >
                        <div style={{ width: "58px", height: "58px", background:"white", borderRadius:"50px" }}>
                          {storyInputs.selectedCoverFile !== null && (
                            <img
                              style={{
                                objectFit: "cover",
                                borderRadius: "50%",
                                width: "58px",
                                height: "58px",
                              }}
                              src={URL.createObjectURL(
                                storyInputs.selectedCoverFile
                              )}
                              alt={storyInputs.selectedCoverFile.name}
                            />
                          )}
                        </div>

                        <Button
                          style={{
                            color: "black",
                            background: "white",
                            padding: "5px 8px",
                            // border: "1px solid #57B2E0",
                            height: "30px",
                            borderRadius: "9px",
                             fontSize: "12px",
                            // marginRight: "50px",
                          }}
                          onClick={() => coverInputRef.current.click()}
                        >
                          <input
                            type="file"
                            ref={coverInputRef}
                            style={{ visibility: "hidden", width: "0px" }}
                            onChange={handleCoverFileChange}
                          />
                          Upload
                        </Button>
                      </div>
                    </div>


                    <div style={{ marginBottom: "10px" }}>
                      <InputLabel style={{ fontSize: "14px", color:"white" }}>
                        Thumbnail Title
                      </InputLabel>
                      {/* {error && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {error}
                        </div>
                      )} */}
                      <InputBase
                        placeholder="Ex. blaash"
                        style={{
                        //   border:
                        //     error !== ""
                        //       ? "1px solid red"
                        //       : "1px solid #F0F0F0",
                        border:"1px solid white",
                        color:"white",
                          padding: "0 10px",
                          width: "100%",
                          borderRadius: "10px",
                        }}
                        value={storyInputs.thumbnailTitle}
                        onChange={(e) =>
                          setStoryInputs({
                            ...storyInputs,
                            thumbnailTitle: e.target.value,
                          })
                        }
                      />
                    </div>
                    
                    <div style={{ marginBottom: "10px", }}>
                      <InputLabel style={{ fontSize: "14px", color:"white", fontWeight: 400 }}>
                        CTA Text
                      </InputLabel>

                      <InputBase
                        placeholder="Ex. blaash"
                        style={{
                          border: "1px solid #F0F0F0",
                          padding: "0 10px",
                          width: "100%",
                          borderRadius: "10px",
                          color:"white"
                        }}
                        value={storyInputs.ctaText}
                        onChange={(e) =>
                          setStoryInputs({
                            ...storyInputs,
                            ctaText: e.target.value,
                          })
                        }
                      />
                    </div>

                    </div>

                    <p
          style={{
            color: "white",
            margin: "10px 0",
            fontSize: "14px",
            textAlign: "center",
            fontWeight: 500,
          }}
        >
          Add Product
        </p>

              <VideoLibrary_DescriptionNew
                productDetail={productDetail}
                setProductDetail={setProductDetail}
                productIds={productIds}
              />
              </div>
        </div>
      </div>
    </>
  );
}

export default StoryStudio;
