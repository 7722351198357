import React, { useState, useRef, useEffect, Suspense, lazy } from "react";
import "./stickers.css";
import {
  getAuthAndData,
  postAuthAndData,
} from "../../../../../../api/ApiHelper";
import {
  ENGT_PROD_BASE_URI,
  ENGT_PROD_HOST_URI,
  GET_ALL_TEMPLATES,
  GET_TEMPLATES_BY_NAME,
} from "../../../../../../api/apiConstants";
import { TabButton } from "../..";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../../../../../actions/route/routeActionHandler";
const TemplateGroup = lazy(() => import("../Template/TemplateGroup.js"));

const Stickers = () => {
  // const customStyles = {
  //   control: (provided) => ({
  //     ...provided,
  //     color: "#fff",
  //     backgroundColor: "#27272f", // Custom background color
  //     borderColor: "#484851", // Custom border color
  //     boxShadow: "none", // Remove default box-shadow
  //     "&:hover": {
  //       borderColor: "#555", // Custom border color on hover
  //     },
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isSelected ? "#555" : "transparent", // Highlight selected option
  //     color: state.isSelected ? "#48485" : "#000", // Custom text color
  //     "&:hover": {
  //       backgroundColor: state.isSelected ? "#555" : "#f1f1f1", // Highlight selected option on hover
  //     },
  //     singleValue: (provided, state) => ({
  //       ...provided,
  //       color: "#fff", // Custom text color for the selected option
  //       fontWeight: "bold", // Make the selected option bold
  //     }),
  //   }),
  //   // Customize other components as needed (e.g., singleValue, menu, etc.)
  // };

  const dispatch = useDispatch();
  const handleLoader = (showBool) => {
    dispatch(dispatchLoaderData(showBool));
  };

  const [searchSticker, setSearchSticker] = useState("");
  const [searchStickerCollection, setSearchStickerCollection] = useState([]);
  const [stickerCollection, setStickCollection] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState({});

  const GetAllStickers = () => {
    // Content Type 4 for stickers
    handleLoader(true);
    getAuthAndData(
      `${ENGT_PROD_BASE_URI}${GET_ALL_TEMPLATES}?content_type=${4}`
    )
      .then((response) => {
        if (response.code === 1) {
          setStickCollection((prevList) => [...prevList, ...response.data]);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
    handleLoader(false);
  };

  const GetStickerbyName = async () => {
    const body = {
      template_search_type: 1,
      content_type: 4,
      search_text: searchSticker,
      display_count: 10,
      page_index: 0,
    };

    await postAuthAndData(`${ENGT_PROD_HOST_URI}${GET_TEMPLATES_BY_NAME}`, body)
      .then((response) => {
        if (response.code === 1) {
          setSearchStickerCollection([...response.data]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    (() => {
      GetAllStickers();
    })();
  }, []);

  useEffect(() => {
    if (!!searchSticker && searchSticker !== "" && searchSticker.length >= 3) {
      const getTemplate = setTimeout(() => {
        GetStickerbyName();
      }, [2000]);

      return () => clearTimeout(getTemplate);
    }
  }, [searchSticker]);

  return (
    <div className="boxtemplate">
      <div
        style={{
          width: "300px",
          height: "610px",
          padding: "10px 10px 10px",
          background: "#27272F",
        }}
      >
        <TabButton className="tab-button" onClick={() => {}}>
          Static
        </TabButton>
        <div className="searchbox">
          <SearchIcon />
          <input
            id="outlined-basic"
            type="text"
            className="search-input"
            placeholder="Search graphics, stickers, doodle"
            onChange={(event) => {
              setSearchSticker(event.target.value);
            }}
          />
        </div>
        {!!searchSticker &&
        searchSticker.length >= 3 &&
        searchStickerCollection.length > 0 ? (
          <Suspense fallback={<div>Loading</div>}>
            <TemplateGroup playlist={searchStickerCollection} type={4} />
          </Suspense>
        ) : (
          <Suspense fallback={<div>Loading</div>}>
            <TemplateGroup playlist={stickerCollection} type={4} />
          </Suspense>
        )}
      </div>
    </div>
  );
};

export default Stickers;
