import React, { useEffect } from "react";
import CloudHeader from "../cloudHeader";
import LeftSidebar from "../leftsidebar";
import UseDesignHook from "../UseDesignHook";
import "../Design/design.css";
import { SocialMediaPost } from "../SocialMediaPost";

const SocialMediaPopup = () => {
  const { updateThemeToDark, updateThemeToLight } = UseDesignHook();

  useEffect(() => {
    updateThemeToDark();
    document.body.style.background = "#22222A";

    return () => {
      updateThemeToLight();
      document.body.style.background = "white";
    };
  }, []);

  return (
    <>
      <CloudHeader />
      <div className=" bd-highlight">
        <LeftSidebar type={2} socialMediaPopup={true} addaccount={true} />
      </div>
    </>
  );
};

export default SocialMediaPopup;
