import React from 'react'
import "./abTest.style.css"
import { useState, useEffect } from 'react';
import {getAuthAndData} from '../../../api/ApiHelper';
import { ENGT_PROD_BASE_URI, ENGAGEMENT_AB_TESTING} from '../../../api/apiConstants';
import {useHistory} from "react-router-dom"


function AbTestingComponent(props) {
    console.log('AbTestingComponent========**',props?.data)
    const history = useHistory()
    const [abTestData, setAbTestData] = useState([])
    const [timeString] = useState(props?.data?.AverageTimeSpent)

    let splitedtime = timeString?.split(':')
    let  seconds = Math.floor((+splitedtime[0]) * 60 * 60 + (+splitedtime[1]) * 60 + (+splitedtime[2]));
    

    // const abTestingData = async()=>{
    //   try{
    //     const data = await getAuthAndData(`${ENGT_PROD_BASE_URI}${ENGAGEMENT_AB_TESTING}`,history)
    //     console.log('abTestingData=====', data)
    //     setAbTestData(data?.data)
    //   }
    //   catch(error){
    //     console.log(error.msg)
    //   }
    // }

    // useEffect(()=>{
    //     abTestingData()
    // },[])
    
  return (
    <div style={{marginTop:'2PX'}}>
         <div className='childRow' >
                <div className='rowTextStyleName'>
                    {props.data.ShortName}
                </div>
                <div className='columnElement'>
                <div className='childRowTextStyle'>
                {props.data.NoOfCustomers}
                </div>
                <div className={(props.data.PercentageChange_number_of_Customers >= 0) ? "percentageGreen":"percentageREd" } >
                {props?.data?.PercentageChange_number_of_Customers}%
                </div>
                </div>
                <div className='columnElement'>
                <div className='childRowTextStyle'>
                    {seconds}
                </div>
                <div className={(props.data.PercentageChange_AverageTimeSpent >= 0) ? "percentageGreen":"percentageREd" }>
                    {props?.data?.PercentageChange_AverageTimeSpent}%
                </div>
                </div>
                <div className='columnElement'>
                <div className='childRowTextStyle'>
                    {props?.data?.NoOfWinners}
                </div>
                <div className={(props.data.PercentageChange_number_of_Winners >= 0) ? "percentageGreen":"percentageREd" }>
                   {props?.data?.PercentageChange_number_of_Winners}%
                </div>
                </div>
                <div className='rowTextStyle'>
                { new Date(props?.data?.AB_Test_CreatedAt).toLocaleDateString()}
                </div>
                <div style= {{width:"14%"}}>
                <div style={{opacity:"0"}} className="btn-create-ab-row" >
                    <span className="btn-ab-text">Create AB Tests</span>
                </div>
                </div>
                

            </div>
    </div>
  )
}

export default AbTestingComponent