import React, { Fragment, useState, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import Table from "../common/reactTable/table";
import CustomTooltip from "../common/tooltip/tooltip";
import {RewardContextMenu} from "../common/reactTable/menu";
import createNotification from '../common/reactNotification';
import { NotificationContainer } from 'react-notifications';
import _ from 'lodash';
import './style.Segment.css';
import { containerHeightCalcFn } from "../common/global";
import SearchBar from "../common/searchBar/searchBar";
import three_dot_src from '../../assets/img/3dots_verticals.svg';
import add_gray_src from '../../assets/img/add_gray.svg';
import { getAuthAndData, postAuthAndData } from '../../api/ApiHelper';

import { Country, State, City }  from 'country-state-city';
import filter_src from "../../assets/img/segment_filter.svg";
import Select from 'react-select';
import location_src from "../../assets/img/segment_location.svg";
import { BsChevronLeft } from "react-icons/bs";

import {  
    DELETE_SEGMENT_BY_ID,
    ENGT_PROD_BASE_URI,   
    FILTER_MASTER,
    SOMETHING_WENT_WRONG,
    SET_ALL_SEGMENT_DATA,
    SAVE_CUSTOMER_SEGMENTS
} from '../../api/apiConstants';


const DaysTypeOptions = [
    { value: 'Days', label: 'Days' },
    // { value: 'Week', label: 'Week' },
    // { value: 'Month', label: 'Month'}
   
]


const countryOptions = [
    { value: 'IN', label: 'India' },
]

const firstOptions = [
    [{ value: 'IF', label: 'IF' }],
    [{ value: 'AND', label: 'AND' }],
    // { value: 'OR', label: 'OR' },
    // { value: 'IN', label: 'IN' }
];
const secondOptions = [
    [{ value: 'Event', label: 'Event' }],
    [{ value: 'Value', label: 'Value' }],
    [{ value: 'In Last', label: 'In Last' }]
];
const thirdOptions = [
    [{ value: 'Equals', label: 'Equals' }],
    [{ value: 'Greater than or Equals To', label: 'Greater than or Equals To' }]
];


export default function Segment(props) {
    console.log("BBBBBBB =============666666666")
    var history = useHistory();
    const statesArr = State.getStatesOfCountry("IN");
    const statesOptions = statesArr?.map(({name, isoCode}) => ( {'value':isoCode, 'label':name}))
    //const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [createFlag, setCreateFlag] = useState(false);
    const [updateFlag, setUpdateFlag] = useState(false);
    const [segmentsData, setSegmentsData] = useState();

    const [stateCode, setStatecode] = useState(statesOptions[0])
    const [cityCode,setCityCode] = useState({})
    const [countryCode, setCountryCode] = useState('IN')
    const [citysByStateCode, setCitysByStateCode] = useState([])
    const [sFilters, setSFilters] = useState();
    const [segmentData, setSegmentData] = useState({ID: 0,Name: '' });
    const [filters, setFilters] = useState({})
    const [seographies, setGeographies] = useState([])
    



   


    const  selectedState = (event)=>{
     setStatecode({value:event.value, label:event.label})
    }
    //console.log('!!!!!!!!!stateCode@#$234@$', stateCode)
   

    useEffect(()=>{
        getAllcity()
    }, [stateCode])
    
    const getAllcity =() =>{
    const citysArr = City.getCitiesOfState(countryCode, stateCode?.value)
    const citysOptions = citysArr?.map(({name}) => ( {'value':name, 'label':name}))
    setCitysByStateCode(citysOptions)
    //setCityCode(citysOptions[0])

    //console.log('citysOptions121', citysOptions)

    }

    const selectedCity = (event)=>{
        console.log("event+++++++===", event)
        setCityCode(event)
        

        const data = event?.map((e)=>({
            "CountryName": "India",
            "CountryCode": "IN",
            "StateName": stateCode.label,
            "StateCode": stateCode.value,
            "CityName": e.label,
            "CityCode": ""
        }))
        setGeographies(data)
        console.log('selectedCity+++++++', data)
       
        
    }

    const segmentColumns = [
        {
            name: "Segment Name",
            selector: "SegmentName",
            width:'20%'
        },
        {
            name: "Filters",
            width:'25%',
            cell: rowObj =>
                <div style={{ paddingRight: '5px' }}>
                    <div>{rowObj.Filters?.length && `${rowObj.Filters[0].FilterEventName} -> ${rowObj.Filters[0].FilterEventValue}
                    for last ${rowObj?.Filters[0].LastNoOfDays} days`}</div>
                    <CustomTooltip tooltipText={rowObj.Filters?.length && rowObj.Filters.map(j => '-> ' + j.FilterEventName +'-> ' + j.FilterEventValue + "for last " + j.LastNoOfDays + ' days ')} >
                        <button type="button" className='table-row-btn'>
                            <div className='table-row-btn-text'>{rowObj.Filters?.length}</div>
                        </button>
                    </CustomTooltip>
                </div>
        },
        {
            name: "Geography",
            width:'25%',
            cell: rowObj =>
                <div style={{ paddingRight: '5px' }}>
                    <div>{rowObj.Geographies?.length && (`${rowObj.Geographies[0].CountryName} -> ${rowObj.Geographies[0].StateName}
                    -> ${rowObj.Geographies[0].CityName} `)}</div>
                    <CustomTooltip tooltipText={rowObj.Geographies?.length && rowObj.Geographies.map(j => j.CountryName + '-> '+j.StateName+ '-> '+j.CityName+ ' ')} >
                        <button type="button" className='table-row-btn'>
                            <div className='table-row-btn-text'>{rowObj.Geographies?.length}</div>
                        </button>
                    </CustomTooltip>
                </div>
           
        },
        {
            name: "Created by",
            selector: "CreatedBy",
            width:'10%'
        },
        {
            name: "Created On",
            selector: "CreatedOn",
            width:'10%'
        },
        {
            name: "Action",
            width:'10%',
            cell: rowObj => <RewardContextMenu onAction={e => onActionClick(e, rowObj)} />
        }

    ]

    const createClick = () => {
        setCreateFlag(true)
        setFilters({eventValue:null, timeduration:null,
            label : '',value : null,
            MeasuringUnit :''})
        setSegmentData({ID: 0,Name: ' ' })
        setStatecode(statesOptions[0])
        setCityCode([])

    }

    const handleLoader = (showBool) => {
        props.routeActionHandler.dispatchLoaderData(showBool);
    }
    const handleAlertDialog = (obj) => {
        props.routeActionHandler.dispatchAlertDialogData(obj);
    }
    // const onDragStart = (event, data) => {
    //     event.dataTransfer.setData("draggedTask", JSON.stringify(data));
    // }

    // const onDragOver = (event) => {
    //     event.preventDefault();
    // }

    // const onDrop = (event) => {
    //     if (event.dataTransfer) {
    //         let taskObj = JSON.parse(event.dataTransfer.getData("draggedTask"));
    //         let tempDroppedItems = [...droppedItems || []];
    //         tempDroppedItems.push(taskObj);
    //         setDroppedItems(tempDroppedItems);
    //         forceUpdate();
    //     }
    // }
    // const onTaskValueChange = (e, taskObj) => {
    //     if(e.target.value.length>2){
    //         return;
    //     }
    //     taskObj.value = e.target.value;
    //     let selectedTasks = [...droppedItems];
    //     let taskIndex = _.findIndex(selectedTasks, t => t.JourneyTaskID == taskObj.JourneyTaskID);
    //     selectedTasks.splice(taskIndex, 1, taskObj);
    //     setDroppedItems(selectedTasks);
    // }

    const onSegmentSave = () => {
         if(!segmentData?.Name){
            createNotification('warning','Enter Segment Name');
            return;
         }
        
        else if(!filters?.eventValue){
            createNotification('warning','Please enter value');
            return;
         
        }
        else if(!filters?.timeduration){
            createNotification('warning','Please enter duration');
            return;
         
        }else {
            handleLoader(true);
            let body = {
                "SegmentCustomerId": segmentData.ID,
                "SegmentName": segmentData.Name,
                "TenantId": 3,
                "Geographies": seographies,
                "SegmentEventRuleDetails": [
                    {
                        "FilterEventName": filters.label,
                        "SegmentEventId": filters.value,
                        "FilterOperator": 1,
                        "FilterEventValue": filters.eventValue,
                        "LastNoOfDays": filters.timeduration,
                        "IsActive": true
                    }
                ]
            }
           
            postAuthAndData(`${SAVE_CUSTOMER_SEGMENTS}`, body, history)
                .then(res => {
                    console.log("postAuthAndData========",res)
                    if (handleResponseCode(res)) {
                        getAllSegments();
                        onCancel();
                        handleLoader(false);
                        createNotification('success', ` Segment Created Succesfully`)
                        setCreateFlag(false) 
                        //setUpdateFlag(false)

                    } else {
                        handleLoader(false);
                        createNotification('error', `Segment Creation is failed`)
                    }
                });
        }
    }

    const onSegmentUpdate = () => {
        handleLoader(true);
        let body = {
            "SegmentCustomerId": segmentData.ID,
            "SegmentName": segmentData.Name,
            "TenantId": 1,
            "Geographies": seographies,
            "SegmentEventRuleDetails": [
                {
                    "FilterEventName": filters.label,
                    "SegmentEventId": filters.value,
                    "FilterOperator": 1,
                    "FilterEventValue": filters.eventValue,
                    "LastNoOfDays": filters.timeduration,
                    "IsActive": true
                }
            ]
        }
        
        postAuthAndData(`${SAVE_CUSTOMER_SEGMENTS}`, body, history)
            .then(res => {
                if (handleResponseCode(res)) {
                    getAllSegments();
                    onCancel();
                    handleLoader(false);
                    createNotification('success', `Segment Updated Succesfully`)
                   // setCreateFlag(false) 
                    setUpdateFlag(false)
                } else {
                   handleLoader(false);
                    createNotification('error', `Segment updation is failed`)
                }
            });
    }

    const onCancel = () => {
        
        setSegmentData({ ID: 0, Name: '' });
        setFilters({eventValue:null, timeduration:null,
            label : '',value : null,
            MeasuringUnit :''})
            setCreateFlag(false);
        setUpdateFlag(false);
        //setDroppedItems();
    }
    const getAllSegments = async () => {
        handleLoader(true);
       await getAuthAndData(`${ENGT_PROD_BASE_URI}${SET_ALL_SEGMENT_DATA}`, history)
            .then(res => {
                if (handleResponseCode(res)) {
                    setGroupedSegment(res.data);
                    // setJourneysCount({ usedInEngagements: res.data.UsedJourneysCount, Total: res.data.TotalJourneysCount });
                }
                handleLoader(false);
            });
    }
    const onSegmentSearch = (searchText) => {
        if (searchText.length>2) {
            let arr= [...segmentsData]
            let filterArr=arr.filter(j=>j.SegmentName.toUpperCase().includes(searchText.toUpperCase()))
            if(filterArr.length>0){
                setSegmentsData(filterArr);

            }else{
                createNotification('info',`No Segment Found for '${searchText}' search`)
            }
            // getAuthAndData(`${JOURNEYS_BY_SEARCH}${searchText}`, history)
            //     .then(res => {
            //         if (handleResponseCode(res)) {
            //             setGroupedJourneys(res.data);
            //         }
            //     });
        } else {
            if(searchText.length < 2){
                setSegmentsData([...segmentsData])
            }
        }
        
    }
    const setGroupedSegment = (segmentList) => {
        var groupedsegments = [];
        segmentList.forEach(segment => {
                groupedsegments.push({
                    SegmentID: segment.SegmentCustomerId,
                    SegmentName: segment.SegmentName,
                    CreatedBy: segment.CreatedBy,
                    UpdatedOn: segment.IsActive,
                    Filters: segment?.SegmentEventRuleDetails,
                    Geographies: segment?.Geographies,
                
                    // Geographies:[{
                    //     CountryName : j.Geographies.CountryName,
                    //     StateName : j.Geographies.StateName,
                    //    CityName: j.Geographies.CityName
                    // }]

                });
            }
        )

       // props.engagementsJourneyActionHandler.dispatchJourneysData(groupedsegments);
       setSegmentsData(groupedsegments);
    }

    const getAllFilterTasks = () => {
        getAuthAndData(`${ENGT_PROD_BASE_URI}${FILTER_MASTER}`, history)
            .then(res => {
                if (handleResponseCode(res)) {
                    const filterData = res?.data?.map(({EventName, MeasuringUnit, SegmentCustomerEventMasterId})=>(
                        {'value':SegmentCustomerEventMasterId, 'label':EventName,
                        'MeasuringUnit':MeasuringUnit }))
                        setSFilters(filterData)
                       
                    // props.engagementsJourneyActionHandler.dispatchJourneyTasks(res.data);
                }
            });
    }

    const onActionClick = (e, rowObj) => {
        let action = e.target.textContent;
        console.log("rowObj=====", rowObj)
        if (action === 'Edit') {
            setUpdateFlag(true);
            setSegmentData({ ID: rowObj.SegmentID, Name: rowObj.SegmentName });
            setFilters({ eventValue: rowObj?.Filters[0]?.FilterEventValue,
                timeduration: rowObj?.Filters[0]?.LastNoOfDays, label: rowObj?.Filters[0].FilterEventName,
                value: rowObj?.Filters[0]?.SegmentEventId});
            setStatecode({value:rowObj.Geographies[0].StateCode, label:rowObj.Geographies[0].StateName})
            const cityArr = rowObj.Geographies.map((e)=>({value: e.CityName, label: e.CityName}))
            setCityCode(cityArr)    

        }
         else if (action === 'Delete') {
            handleAlertDialog({
                open: true, title: 'Delete Segment', text: 'Are you sure! Do you want to Delete Segment?', handleClose: (bool) => {
                    handleAlertDialog({ open: false, title: '', text: '', handleClose: () => { } });
                    if (bool) {
                        getAuthAndData(`${ENGT_PROD_BASE_URI}${DELETE_SEGMENT_BY_ID}${rowObj.SegmentID}`, history)
                        .then(res => {
                            if(handleResponseCode(res)){
                                getAllSegments();
                                setUpdateFlag(false);
                                createNotification('success', `${rowObj.SegmentID} Journey Deleted Succesfully`);
                            }
                        })
                    }
                }
            });
           
        } else {

        }
    }

    const handleResponseCode=(resp)=>{
        console.log("resp======",resp)
        if(!resp || resp.code===-1){
            createNotification('error',SOMETHING_WENT_WRONG+ ' in Segments');
            return false;
        }    
        if(resp.code === 5){
            createNotification("warning","Segment Name Already Exist")
            return false;
        }else{
            return true;
        }
    }

    const onEventValueChange = e =>{
        if(e.target.value <=999)
        setFilters({...filters, eventValue: e.target.value})

    }

    const onTimeDurationChange = e =>{
        if(e.target.value <=999)
        setFilters({...filters, timeduration: e.target.value})

    }

    useEffect(() => {
        getAllSegments();
        getAllFilterTasks();
    }, []);

    const goBack = () =>{
        setCreateFlag(false)
        setUpdateFlag(false)
    }


    console.log("segmentsData====$$$$", segmentsData)
    return (
        <div id="engagements-journey-container">
            <NotificationContainer/>
            {(createFlag || updateFlag) &&<div id="c-s-breadcrum" style = {{marginBottom:"10px"}} >
         <div className="c-s-breadcrum-back" onClick={() => goBack()}><BsChevronLeft></BsChevronLeft>Back</div>
         <div className="c-s-breadcrum-title">
        <span className="pl-1 c-pointer"  onClick={() => goBack()}>Segments / </span>
        <span className="text-bold">Create Segment</span>
         </div>
          </div>}
            {(!createFlag && !updateFlag) ?
                <Fragment>
                    
            <div className="w-100 float-left clearfix">
                <div className="disp-inline-block">
                    <span className="m-s-heading">Manage Segments</span>
                    <br />
                    <span className="m-s-sub-heading">
                   All Segments are part of running campaign
                    </span>
                </div>
                
                    <div className="btn-create-engagement float-right text-center pt-2" onClick={createClick} style={{marginBottom: '20px'}}>
                        <span className="btn-c-e-text">+ Create Segments</span>
                    </div>
                
            </div>
            
                <Fragment>
                    <Table columns={segmentColumns} 
                      data ={segmentsData} 
                        pagination={true}
                        subHeaderComponent={
                            <SearchBar placeHolder="Search Segments" onSearch={text=>onSegmentSearch(text)} fromRewards={true} searchFilter="All Rewards" />
                            
                        } 
                        subHeader={true} />
                </Fragment>
                </Fragment>
                :
                <Fragment>
                    <div className="w-100 float-left clearfix s-create" style={{height: containerHeightCalcFn(200), overflowY: "auto"}}>
                        <div className="w-100 float-left clearfix s-c-h">Create Segments</div>
                        <div className="w-100 float-left clearfix s-c-name">
                            <div className="s-c-n-h">Create Segments</div>
                            <div className="s-c-n-input">
                                <input type="text" placeholder="Segment name" 
                                maxLength={100}
                                onChange={e => e.target.value?.length < 101 && setSegmentData({ ...segmentData, Name: e.target.value })}
                                defaultValue={segmentData.Name}
                                  />
                            </div>
                        </div>
                        {/* <div className="w-100 float-left clearfix s-c-t-h">Choose customer type</div>
                        <div className="w-100 float-left clearfix s-c-t-sec">
                            {customerTypes && customerTypes.length > 0 ? (
                                <Fragment>
                                    {customerTypes.map((lObj, i) => (
                                        <div className="s-c-t-box float-left clearfix" key={`s-c-t-box-${i}`} onClick={() => customerTypeBoxClick(lObj)}>
                                            <div className="w-15 float-left clearfix" style={{marginTop: '13px'}}>
                                                <input id={`a-format-chk${lObj.id}`} type="checkbox" className="mt-0" checked={lObj.isActive ? true : false} onChange={ctChange}></input>
                                            </div>
                                            <div className="w-85 float-left clearfix mt-2">
                                                <span className="s-c-t-box-txt pl-2">{lObj.name}</span>
                                                <br />
                                                <span className="s-c-t-box-count pl-2">{lObj.count}</span>
                                            </div>
                                        </div>
                                    ))}
                                </Fragment>
                            ) : null}
                        </div> */}
                        <div className="w-100 float-left clearfix s-c-f-sec">
                            <div className="s-c-f-h">
                                <img src={filter_src} alt="Filter Heading" className="s-c-f-h-img mr-2" />
                                <span className="s-c-f-h-txt" style={{textTransform: "uppercase"}}>Filters</span>
                            </div>
                            {/* <div>
                                {sFilters && sFilters.length > 0 ? (
                                    <Fragment>
                                        {sFilters.map((obj, i) => (
                                            <SegmentFilter currentData={obj} key={`filter${i}`} deleteFilter={deleteFilterFn}></SegmentFilter>
                                        ))}
                                    </Fragment>
                                ) : <div className="s-c-f-h-txt pl-1 mb-2">No filters found!</div>}
                            </div> */}
                            <div className="s-c-f-add" >
                            <div>
                     <div className="w-100 float-left clearfix c-e-target-p-rule-opt">
                    {/* <img src={close_src} alt="Filter Close" className="float-left mr-3 mb-4" style={{marginTop: "12px"}} onClick={() => props.deleteFilter(props.currentData)} /> */}
                   <Select options={firstOptions[0]} value={firstOptions[0]}   
                    className="w-10 p-r-10 float-left clearfix mb-3"
                       />
            <Select options={secondOptions[0]}  value = {secondOptions[0]} className="w-10 p-r-10 float-left clearfix" />
            <Select options={thirdOptions[0]} value = {thirdOptions[0]} className="w-24 p-r-10 float-left clearfix" />
           <Select 
           
           value = {filters} options={sFilters} onChange = {event => setFilters({...filters, label : event.label,value : event.value,
           MeasuringUnit :event.MeasuringUnit})} className="w-25 p-r-10 float-left clearfix" />
           
         </div>
         <div className="w-100 float-left clearfix c-e-target-p-rule-opt">
         {/* <img src={close_src} alt="Filter Close" className="float-left mr-3 mb-4" style={{marginTop: "12px"}} onClick={() => props.deleteFilter(props.currentData)} /> */}
         <Select style={{marginTop: "12px"}} options={firstOptions[1]} value={firstOptions[1]} className="w-10 p-r-10 float-left clearfix mb-3" />
         <Select options={secondOptions[1]} value = {secondOptions[1]} className="w-10 p-r-10 float-left clearfix" />
         <Select options={thirdOptions[1]} defaultValue={[thirdOptions[1][0]]} className="w-24 p-r-10 float-left clearfix" />
         {/* <Select options={eventsOptions} className="w-15 p-r-10 float-left clearfix" /> */}
         {filters?.value? <span style={{marginTop:"5px"}}>{filters?.MeasuringUnit}</span>: null}
        
         <div className="w-25 m-r-10 float-left clearfix t-a-r-5 p-rule-duration">
              <input 
                  type="number"
                  onChange={onEventValueChange}
                  value={filters.eventValue}
                  className='p-rule-input'
              />
          </div>
      </div>
      <div className="w-100 float-left clearfix c-e-target-p-rule-opt">
         {/* <img src={close_src} alt="Filter Close" className="float-left mr-3 mb-4" style={{marginTop: "12px"}} onClick={() => props.deleteFilter(props.currentData)} /> */}
         <Select style={{marginTop: "12px"}} options={firstOptions[1]} value={firstOptions[1]} className="w-10 p-r-10 float-left clearfix" />
         <Select options={secondOptions[2]} value = {secondOptions[2]} className="w-10 p-r-10 float-left clearfix" />
         <div className="w-24 m-r-10 float-left clearfix t-a-r-5 p-rule-duration">
              <input 
                  type="number"
                  //pattern="\d*"
                  id="t-a-r-5"
                  value={filters.timeduration}
                  onChange={onTimeDurationChange}
                  className='p-rule-input'
              />
          </div>
         <Select options={DaysTypeOptions} value={[DaysTypeOptions[0]]} className="w-25 p-r-10 float-left clearfix" />
     </div>
     </div>
                                {/* <img src={add_src} alt="Filter Add" className="s-c-f-h-img mr-2" />
                                <span className="s-c-f-h-txt">Add Filter</span> */}
                            </div>
                        </div>
                        <div className="w-100 float-left clearfix s-c-ag-sec">
                            {/* <div className="s-c-ag-h">
                                <img src={user_src} alt="Age Gender Heading" className="s-c-ag-h-img mr-2" />
                                <span className="s-c-ag-h-txt" style={{textTransform: "uppercase"}}>Age & Gender</span>
                            </div> */}
                            {/* <div className="w-50 float-left clearfix">
                                <div className="s-c-ag-content">
                                    <div className="s-c-ag-box float-left clearfix" onClick={() => genderClick('Male')}>
                                        <div className="w-15 float-left clearfix" style={{marginTop: '13px'}}>
                                            <input id={`a-format-chk-male`} type="checkbox" className="mt-0" checked={genderValue === 'Male' ? true : false} onChange={ctChange}></input>
                                        </div>
                                        <div className="w-85 float-left clearfix mt-2">
                                            <span className="s-c-ag-box-txt pl-2">Male</span>
                                        </div>
                                    </div>
                                    <div className="s-c-ag-box float-left clearfix" onClick={() => genderClick('Female')}>
                                        <div className="w-15 float-left clearfix" style={{marginTop: '13px'}}>
                                            <input id={`a-format-chk-female`} type="checkbox" className="mt-0" checked={genderValue === 'Female' ? true : false} onChange={ctChange}></input>
                                        </div>
                                        <div className="w-85 float-left clearfix mt-2">
                                            <span className="s-c-ag-box-txt pl-2">Female</span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="w-50 float-left clearfix">

                            </div> */}
                        </div>
                        <div className="w-100 float-left clearfix s-c-l-sec">
                            <div className="s-c-l-h">
                                <img src={location_src} alt="Location Heading" className="s-c-l-h-img mr-2" />
                                <span className="s-c-l-h-txt" style={{textTransform: "uppercase"}}>Location</span>
                            </div>

                            <div className="s-c-f-add" >
                            <div>
        <div className="w-100 float-left clearfix c-e-target-p-rule-opt pb-3">
        <Select options={countryOptions} defaultValue= {countryOptions[0]}   className="w-35 p-r-10 float-left clearfix" />
        <Select options={statesOptions} value = {stateCode} onChange = {selectedState} className="w-35 p-r-10 float-left clearfix" />
        </div>
        <div className="w-100 float-left clearfix c-e-target-p-rule-opt pb-3">
    <Select closeMenuOnSelect={false} value={cityCode}
     isMulti options={citysByStateCode} onChange ={selectedCity}
    className="w-70 p-r-10 float-left clearfix"/>
    </div>
    </div>
                            </div>

                            {/* <div className="s-c-l-s">
                                <div className="s-c-l-s-h">Search Location</div>
                                <div className="w-100 float-left clearfix" style={{marginBottom: "28px"}}>
                                    <div className="w-50 float-left clearfix s-c-l-s-sec p-2">
                                        <BsSearch className="h-icons float-left clearfix m-0 h-s-sec-img mr-2"></BsSearch>
                                        <input type="text" className="h-s-sec-input" placeholder="" />
                                    </div>
                                    <div className="w-50 float-left clearfix">
                                        <input type="checkbox" className="float-left" checked={ctLocationValue} onChange={ctLocationChange} />
                                        <div className="s-c-l-s-chk-lbl float-left clearfix ml-2" style={{marginTop: "7px"}}>Include my Current Location</div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="s-c-l-m">
                                <Map className="w-100 float-left clearfix"></Map>
                            </div> */}
                        </div>
                    </div>
                    <div className="w-100 float-left clearfix s-create-a">
                        <div className="w-50 float-left clearfix"></div>
                        <div className="w-50 float-left clearfix" style={{marginRight: "45px"}}>
                            {createFlag && 
                                <div className="s-create-a-s content-c float-right clearfix" style = {{backgroundColor:"#6b9cf3"}}  onClick={onSegmentSave}>Save</div>
                            }
                            {updateFlag &&
                                <div className="c-j-a-s content-c float-right clearfix" style = {{backgroundColor:"#6b9cf3"}} onClick={onSegmentUpdate}>Update</div>
                            }
                            <div className="s-create-a-c content-c float-right clearfix" onClick={() => setCreateFlag(false) || setUpdateFlag(false)}>Cancel</div>
                        </div>
                    </div>
                </Fragment>
            }
        </div>
    )
}
