import React, { useEffect, useMemo, useState } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";
import NotFound from "../components/common/notFound";
import { ThemeProvider } from "../contexts/themeContext";
import Home from "../components/home/home";
import DashboardContatiner from "../containers/dashboard/dashboardContainer";
import SettingsContatiner from "../containers/settings/settingsContainer";
import ManageContatiner from "../containers/manage/manageContainer";
import EngagementsContatiner from "../containers/engagements/engagementsContainer";
import LiveViewContatiner from "../containers/liveView/liveViewContainer";
import AnalyticsContatiner from "../containers/analytics/analyticsContainer";
import SegmentsContatiner from "../containers/segments/segmentsContainer";
import AdminContatiner from "../containers/admin/adminContainer";
import LoginContatiner from "../containers/login/loginContainer";
import RegisterContatiner from "../containers/register/registerContainer";
import VerifyContatiner from "../containers/verify/verifyContainer";
import POCContainer from "../containers/poc/pocContainer";
import AlertDialog from "../components/common/alertBox/dialogBox";
import Loader from "../components/common/Spinner/spinner";
import dummy from "../components/loading/dummy";
import ForgotPassword from "../components/login/forgotPassword";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
import ProtectedRoute from "../components/protectedRoute";
import LoadingContatiner from "../components/loading/loadingContainer";
import StoryComponent from "../components/Story/StoryComponent";
import StoryDetailComponent from "../components/Story/StoryDetailComponent";
import RewardPartnerComponent from "../components/RewardPartner/RewardPartnerComponent";
import AbTestComponent from "../components/engagements/abTest/AbTestComponent";
import StodioComponent from "../components/Story/StodioComponent";
import PredictionDetail from "../components/engagements/prediction/PredictionDetail";
import { PAGE_PERMISSION } from "../api/apiConstants";
import VideoPerformanceComponent from "../components/Story/VideoPerformanceComponent";
import VideoAnalyticsComponent from "../components/Story/VideoAnalyticsComponent";
import LiveMonitoring from "../components/Story/LiveMonitoring";
// import PlaylistManagerComponent from "../components/playlistManager/PlaylistManagerComponent";
import EmbededPlayer from "../components/playlistManager/EmbededPlayer";
import TransformVideoComponent from "../components/transformVideo/TransformVideoComponent";
import ManageVideoComponent from "../components/manageVideo/ManageVideoComponent";
import UploadShoppableVideos from "../components/Story/UploadShoppableVideos";
import UploadStories from "../components/Story/UploadStories";
import ScheduleLive from "../components/Story/ScheduleLive";
import VideoLibrary from "../components/Story/VideoLibrary";
import StoryLibrary from "../components/Story/StoryLibrary";
import StoryDashboard from "../components/Story/StoryDashboard";
import UploadStudioComponents from "../components/Story/UploadStudioComponents";
// import PlaylistManagerComponent1 from "../components/playlistManager/PlaylistManagerComponent1";
import Revenue from "../components/Story/Revenue";
import PlaylistManagerComponent_New from "../components/playlistManager/PlaylistManagerComponent_New";
import StoryStudio from "../components/Story/StoryStudio";
import OneClickPost from "../components/igUploads/OneClickPost";
import DesignCloud from "../components/designCloud/DesignCloud";
import Design from "../components/designCloud/components/Design";
import { AddAccount } from "../components/AddAccount";
import SocialMediaPopup from "../components/designCloud/components/SocialMediaPopup";
import Billing from "../components/Billing/Billing";
import { AppProvider } from "../contexts/appContext";

Amplify.configure(awsconfig);

export default function AppRoute(props) {
  const userPermission = useSelector(
    (select) => select.userReducer.userData?.UserPermission
  );

  const hasPermisson2 = (id) =>
    userPermission?.find((e) => e.permissionId == id);
  return (
    <AppProvider>
      <ThemeProvider>
        <div id="app-route-container">
          <BrowserRouter>
            <Home>
              <Loader show={props.showLoader} />
              <AlertDialog
                open={props.alertDialog.open}
                title={props.alertDialog.title}
                text={props.alertDialog.text}
                handleClose={(bool) => props.alertDialog.handleClose(bool)}
              />
              <Switch>
                <ProtectedRoute
                  exact
                  path="/login"
                  component={LoginContatiner}
                />
                <Route
                  exact
                  path="/forgotPassword"
                  component={ForgotPassword}
                />
                <Route
                  exact
                  path="/design"
                  render={(props) => <Design {...props} />}
                />
                <Route
                  exact
                  path="/socialmediapopup"
                  component={SocialMediaPopup}
                />
                <Route exact path="/addaccount" component={AddAccount} />
                <ProtectedRoute
                  exact
                  // path="/story/StoryStudio"
                  path="/story/UploadStories"
                  component={UploadStories}
                />
                <ProtectedRoute
                  exact
                  path="/loading"
                  component={LoadingContatiner}
                />
                <ProtectedRoute
                  exact
                  path="/hireinfluncer"
                  component={StoryComponent}
                />
                <ProtectedRoute
                  exact
                  path="/oneclickpost"
                  component={OneClickPost}
                />
                <ProtectedRoute
                  exact
                  path="/designcloud"
                  component={DesignCloud}
                />
                <ProtectedRoute
                  exact
                  path="/embededplayer"
                  component={EmbededPlayer}
                />
                <ProtectedRoute
                  exact
                  path="/managevideocomponent"
                  component={ManageVideoComponent}
                />
                <ProtectedRoute
                  exact
                  path="/manage/RewardPartner"
                  component={RewardPartnerComponent}
                />
                <ProtectedRoute
                  exact
                  path="/shoppablevideo/transformvideo"
                  component={TransformVideoComponent}
                />
                <ProtectedRoute
                  exact
                  path="/hireinfluncer/details"
                  component={StoryDetailComponent}
                />
                {/* <ProtectedRoute
                exact
                path="/designstudio"
                component={StodioComponent}
              /> */}
                <ProtectedRoute
                  exact
                  path="/designstudio/uploadmanager"
                  component={UploadStudioComponents}
                />
                <ProtectedRoute
                  exact
                  path="/shoppablevideo/uploadshoppablevideos"
                  component={UploadShoppableVideos}
                  // component={StodioComponent}
                />
                <ProtectedRoute
                  exact
                  path="/story/uploadstories"
                  component={UploadStories}
                />

                <ProtectedRoute
                  exact
                  path="/live/schedulelive"
                  component={ScheduleLive}
                />
                <ProtectedRoute
                  exact
                  path="/designstudio/StodioComponent_old"
                  component={StodioComponent}
                />
                <ProtectedRoute
                  exact
                  path="/playlistmanager"
                  component={PlaylistManagerComponent_New}
                />
                <ProtectedRoute
                  exact
                  path="/shoppablevideo/videoslibrary"
                  component={VideoLibrary}
                />
                <ProtectedRoute
                  exact
                  path="/story/storylibrary"
                  component={StoryLibrary}
                />
                <ProtectedRoute
                  exact
                  path="/hireinfluncer/videoperformance"
                  component={VideoPerformanceComponent}
                />
                <ProtectedRoute exact path="/" component={Revenue} />
                <ProtectedRoute
                  exact
                  path="/shoppablevideo/dashboard"
                  component={VideoAnalyticsComponent}
                />
                <ProtectedRoute exact path="/dummy" component={dummy} />
                <ProtectedRoute
                  exact
                  path="/liveView"
                  component={LiveViewContatiner}
                />
                <ProtectedRoute
                  exact
                  path="/engagements/:tabname"
                  component={EngagementsContatiner}
                />
                <ProtectedRoute
                  exact
                  path={"/abTest"}
                  component={AbTestComponent}
                />
                <ProtectedRoute
                  exact
                  path="/liveview"
                  component={LiveViewContatiner}
                />
                <ProtectedRoute
                  exact
                  path="/story/storyDashboard"
                  component={StoryDashboard}
                />
                <ProtectedRoute
                  exact
                  path="/live/livemonitoring"
                  component={LiveMonitoring}
                />
                {/* <ProtectedRoute
                exact
                path="/playlistmanager_old"
                component={PlaylistManagerComponent_old} 
              />*/}
                <ProtectedRoute
                  exact
                  path="/analytics/:tabname"
                  component={DashboardContatiner}
                />
                <ProtectedRoute
                  exact
                  path="/manage/:tabname"
                  component={ManageContatiner}
                />
                <ProtectedRoute
                  exact
                  path="/segments"
                  component={SegmentsContatiner}
                />
                <ProtectedRoute
                  exact
                  path="/admin"
                  component={AdminContatiner}
                />
                <ProtectedRoute
                  exact
                  path="/settings"
                  component={SettingsContatiner}
                />
                <ProtectedRoute exact path="/billing" component={Billing} />
                <ProtectedRoute
                  exact
                  path="/register"
                  component={RegisterContatiner}
                />
                <ProtectedRoute
                  exact
                  path="/verify"
                  component={VerifyContatiner}
                />
                <ProtectedRoute exact path="/poc" component={POCContainer} />
                <ProtectedRoute
                  exact
                  path="/predictionDetails"
                  component={PredictionDetail}
                />
                <Route path="*" component={NotFound} />
              </Switch>
            </Home>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </AppProvider>
  );
}
