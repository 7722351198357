import React, { useState, useEffect } from "react";
import "./text.css";
import { getAuthAndData } from "../../../../../../api/ApiHelper";
import {
  ENGT_PROD_BASE_URI,
  GET_FONTS,
} from "../../../../../../api/apiConstants";
import TemplateCategory from "../Template/TemplateCategory";
import SearchIcon from "@material-ui/icons/Search";
import { TabButton } from "../..";
import { useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../../../../../actions/route/routeActionHandler";

const Text = () => {
  // const customStyles = {
  //   control: (provided) => ({
  //     ...provided,
  //     color: "#fff",
  //     backgroundColor: "#27272f", // Custom background color
  //     borderColor: "#484851", // Custom border color
  //     boxShadow: "none", // Remove default box-shadow
  //     "&:hover": {
  //       borderColor: "#555", // Custom border color on hover
  //     },
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isSelected ? "#555" : "transparent", // Highlight selected option
  //     color: state.isSelected ? "#48485" : "#000", // Custom text color
  //     "&:hover": {
  //       backgroundColor: state.isSelected ? "#555" : "#f1f1f1", // Highlight selected option on hover
  //     },
  //     singleValue: (provided, state) => ({
  //       ...provided,
  //       color: "#fff", // Custom text color for the selected option
  //       fontWeight: "bold", // Make the selected option bold
  //     }),
  //   }),
  //   // Customize other components as needed (e.g., singleValue, menu, etc.)
  // };

  const dispatch = useDispatch();
  const handleLoader = (showBool) => {
    dispatch(dispatchLoaderData(showBool));
  };

  const [selectFont, setSelectedFont] = useState(null);
  const [searchFont, setSearchFont] = useState(null);
  const [allFonts, setAllFonts] = useState([]);
  const [filteredFonts, setFilteredFonts] = useState([]);

  const GetAllFonts = async () => {
    handleLoader(true);
    await getAuthAndData(`${ENGT_PROD_BASE_URI}${GET_FONTS}`)
      .then((response) => {
        if (response.code === 1) {
          setAllFonts([...response.data]);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
    handleLoader(false);
  };

  const GetFontsbyName = () => {
    setFilteredFonts(
      allFonts.filter((font) =>
        font.font_id.toLowerCase().includes(searchFont.toLowerCase())
      )
    );
  };

  useEffect(() => {
    (async () => {
      await GetAllFonts();
    })();
  }, []);

  useEffect(() => {
    if (!!searchFont && searchFont !== "" && searchFont.length >= 3) {
      const getTemplate = setTimeout(() => {
        GetFontsbyName();
      }, [2000]);

      return () => clearTimeout(getTemplate);
    }
  }, [searchFont]);

  return (
    <div className="boxtemplate">
      <div>
        <div
          style={{
            width: "300px",
            height: "610px",
            padding: "10px 10px 10px",
            background: "#27272F",
          }}
        >
          <TabButton className="tab-button">Text</TabButton>
          <div className="searchbox">
            <SearchIcon />
            <input
              id="outlined-basic"
              className="search-input"
              type="text"
              placeholder="Search all fonts"
              onChange={(event) => {
                if (event.target.value === "") {
                  setSearchFont(null);
                }
                setSearchFont(event.target.value);
              }}
            />
          </div>
          <div className="text-store">
            {!!searchFont && searchFont.length >= 3 ? (
              <>
                {filteredFonts.length > 0 ? (
                  <TemplateCategory playlistgroup={filteredFonts} type={5} />
                ) : (
                  <div>No Font Available</div>
                )}
              </>
            ) : (
              <TemplateCategory playlistgroup={allFonts} type={5} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Text;
