import React, { useEffect, useState } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "./targetAudience.css";
import { getAuthAndData } from "../../../../api/ApiHelper";
import BasicTreeMap from "../../../common/map/treemap";
import createNotification from "../../../common/reactNotification";
import {
  CUSTOMERS_BY_FILTERS,
  SOMETHING_WENT_WRONG,
} from "../../../../api/apiConstants";

export default function TargetAudience(props) {
  console.log("***%%%%%%", props.props?.targetAudience);
  var history = useHistory();
  const [customerSegments, setCustomerSegments] = useState();
  const [selectedSegment, setSelectedSegment] = useState(
    props.props?.targetAudience
  );
  const [customSegment, setCustomSegment] = useState();
  const [selectedSegmentName, setCustomSegmentName] = useState(
    "Please Select a Segment."
  );
  //const [selectedRadio , setSeledtedRadio] = useState(false)

  const onSegmentSelection = (obj) => {
    console.log("+++++++++=++", obj.data);
    setSelectedSegment(obj.data);
    setCustomSegmentName(obj.data?.segment_name);

    props.setDefineSegment(obj.data);
    console.log(
      "selectedSegement id",
      props.props?.targetAudience?.segment_customers_id
    );
  };
  //console.log("selectedSegment^&^&^&", props.props?.targetAudience)

  const segmentType = () => {
    let segmentId = props.props?.targetAudience?.segment_customers_id;
    console.log("segmentType segmentId", segmentId);

    if (segmentId === -1 || !segmentId) {
      setCustomSegment(false);
    } else {
      setCustomSegment(true);
    }
  };

  const onRadio = (event) => {
    // setCustomSegment(!customSegment)
    console.log("event.target.value==", event.target.value);
    if (event.target.value === "1") {
      //setSeledtedRadio(1)
      //setSelectedSegment({})
      props.setDefineSegment(null);
      setCustomSegment(true);
      setCustomSegmentName("Please Select a Segment.");

      console.log(
        "selectedSegement id",
        props.props?.targetAudience?.segment_customers_id
      );
      // }else{
      //    props.setDefineSegment(selectedSegment);
      //     setCustomSegment(true)
    }
    if (event.target.value === "0") {
      setCustomSegment(false);
      setCustomSegmentName("All Customers.");
      allSegmentSelected();
      // props.setDefineSegment(null);
      console.log(
        "selectedSegement id",
        props.props?.targetAudience?.segment_customers_id
      );
    }
  };

  const allSegmentSelected = () => {
    const allSegment = {
      created_by: null,
      created_on: "2022-03-21T08:43:07.9616828Z",
      percentage: 0.05,
      segment_customers_id: -1,
      segment_name: "All Customer",
      tenant_id: 3,
      updated_by: null,
      updated_on: "2022-03-21T08:43:07.9616828Z",
    };
    setSelectedSegment(allSegment);
    props.setDefineSegment(allSegment);
  };

  const fetchCustomerSegments = () => {
    try {
      props.handleLoader(true);
      getAuthAndData(CUSTOMERS_BY_FILTERS, history).then((res) => {
        if (handleResponseCode(res)) {
          console.log("res============", res);
          const data = {
            name: "Target Audience",
            color: "hsl(233, 70%, 50%)",
            children: [],
          };
          res.data.map((c) => {
            c.percentage =
              c.segment_customers_id == 6
                ? 0.45
                : c.segment_customers_id == 1
                ? 0.25
                : c.segment_customers_id == 2
                ? 0.1
                : c.segment_customers_id == 4
                ? 0.07
                : c.segment_customers_id == 5
                ? 0.08
                : 0.05;
            data.children.push(c);
          });
          setCustomerSegments(data);
          segmentSelectionNEW(data);
        }
        props.handleLoader(false);
      });
    } catch (error) {
      props.handleLoader(false);
      console.error(error);
    }
  };
  const handleResponseCode = (resp) => {
    if (!resp || resp.code === -1) {
      createNotification("error", SOMETHING_WENT_WRONG + "in Target Audience");
      return false;
    } else {
      return true;
    }
  };
  const segmentSelectionNEW = (data) => {
    console.log("datacustomerSegments========11", data);
    let segmentName = "";
    console.log("All SEGMENTS FROM HOOK", data?.children);
    data?.children.map((e) => {
      console.log("Came INNN");
      // console.log("ABeeeeeeeee=====", e)
      console.log(
        "ID from PROP",
        props.props?.targetAudience?.segment_customers_id
      );
      console.log(" NAME from Collection", e?.segment_customers_id);

      console.log("Came Out");

      if (
        e?.segment_customers_id ===
        props.props?.targetAudience?.segment_customers_id
      ) {
        segmentName = e.segment_name;
        console.log("SEG NAME=====", segmentName);
        setSelectedSegment(e);
        return;
      }
      // if(e?.segment_customers_id === props.props?.targetAudience?.segment_customers_id)
      // console.log("ABeeeeeeeee=====", e)
      //setSelectedSegment(e)
      // (e.segment_customers_id === props.props?.targetAudience?.segment_customers_id)?setSelectedSegment(e):setSelectedSegment()
    });
  };
  useEffect(() => {
    fetchCustomerSegments();
    segmentType();
    //segmentSelection();
  }, []);
  useEffect(() => {
    return () => {
      props.props.engagementsSmartActionHandler.dispatchTargetAudienceData(
        selectedSegment
      );
    };
    allSegmentSelected();
  }, [selectedSegment]);
  // console.log("datacustomerSegments========", customerSegments)
  return (
    <div
      id="target-audience-container"
      className="c-e-target-sec w-100 float-left clearfix"
    >
      <div className="w-100 float-left clearfix c-e-target-h">
        Select Target Audience for the Engagement
      </div>
      <div
        className="w-50"
        style={{
          pointerEvents: props.updateEngagement ? "none" : "",
          opacity: props.updateEngagement ? "0.4" : "",
        }}
      >
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="engagement-type"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              control={<Radio />}
              label="All Customer"
              onChange={onRadio}
              value="0"
              checked={!customSegment}
            />

            <FormControlLabel
              control={<Radio />}
              label="Custom Segment"
              onChange={onRadio}
              value="1"
              checked={customSegment}
            />
          </RadioGroup>
        </FormControl>
      </div>
      {customSegment && (
        <div
          className="c-e-target-content w-100 float-left clearfix"
          style={{
            pointerEvents: props.updateEngagement ? "none" : "",
            opacity: props.updateEngagement ? "0.4" : "",
          }}
        >
          {customerSegments && (
            <BasicTreeMap
              data={customerSegments}
              onSegmentSelection={(obj) => onSegmentSelection(obj)}
            />
          )}
          <div
            className="w-100 float-left clearfix pl-2 pt-1"
            style={{ fontSize: "12px" }}
          >
            Selected Customer Segment : <b>{selectedSegmentName}</b>
          </div>

          {/* <div className="w-85 float-left clearfix c-e-target-left-box-con">
                    <div className="w-100 float-left clearfix">
                        <div className="w-60 float-left clearfix c-e-t-box c-e-target-g-box" onClick={() => taBoxSelect('All')}>
                            <div className="w-100 float-left clearfix c-r-t-box-n">All Customers</div>
                            <div className="w-100 float-left clearfix">
                                <div className="c-r-t-box-c float-left">5345</div>
                                <div className="c-r-t-box-per float-left">28%</div>
                                {selectedTABox && selectedTABox === 'All' ? (
                                    <div className="c-r-t-box-select float-right c-center mr-2">Selected</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="w-40 float-left clearfix c-e-t-box c-e-target-b-box" onClick={() => taBoxSelect('New')}>
                            <div className="w-100 float-left clearfix c-r-t-box-n">New Customers</div>
                            <div className="w-100 float-left clearfix">
                                <div className="c-r-t-box-c float-left">5345</div>
                                <div className="c-r-t-box-per float-left">28%</div>
                                {selectedTABox && selectedTABox === 'New' ? (
                                    <div className="c-r-t-box-select float-right c-center mr-2">Selected</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="w-100 float-left clearfix">
                        <div className="w-45 float-left clearfix c-e-t-box c-e-target-pur-box" onClick={() => taBoxSelect('Inactive')}>
                            <div className="w-100 float-left clearfix c-r-t-box-n" style={{ fontSize: "18px" }}>Inactive Customers</div>
                            <div className="w-100 float-left clearfix">
                                <div className="c-r-t-box-c float-left" style={{ fontSize: "28px" }}>4231</div>
                                <div className="c-r-t-box-per float-left">15%</div>
                                {selectedTABox && selectedTABox === 'Inactive' ? (
                                    <div className="c-r-t-box-select float-right c-center mr-2">Selected</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="w-30 float-left clearfix c-e-t-box c-e-target-l-pur-box" onClick={() => taBoxSelect('HVC1')}>
                            <div className="w-100 float-left clearfix c-r-t-box-n" style={{ fontSize: "16px" }}>High Volume Customers</div>
                            <div className="w-100 float-left clearfix">
                                <div className="c-r-t-box-c float-left" style={{ fontSize: "26px" }}>3122</div>
                                <div className="c-r-t-box-per float-left">15%</div>
                                {selectedTABox && selectedTABox === 'HVC1' ? (
                                    <div className="c-r-t-box-select float-right c-center mr-2">Selected</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="w-25 float-left clearfix c-e-t-box c-e-target-dp-box" onClick={() => taBoxSelect('HVC2')}>
                            <div className="w-100 float-left clearfix c-r-t-box-n" style={{ fontSize: "16px" }}>High Volume Customers</div>
                            <div className="w-100 float-left clearfix">
                                <div className="c-r-t-box-c float-left" style={{ fontSize: "26px" }}>3122</div>
                                <div className="c-r-t-box-per float-left">15%</div>
                                {selectedTABox && selectedTABox === 'HVC2' ? (
                                    <div className="c-r-t-box-select float-right c-center mr-2">Selected</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-15 float-left clearfix c-e-target-p-box" style={{ paddingTop: "80px" }} onClick={() => taBoxSelect('HVC3')}>
                    <div className="w-100 float-left clearfix" style={{ marginBottom: "10px" }}>
                        <div className="c-r-t-box-c float-left" style={{ fontSize: "26px" }}>3122</div>
                        <div className="c-r-t-box-per float-left">15%</div>
                    </div>
                    <div className="w-100 float-left clearfix c-r-t-box-n" style={{ fontSize: "16px", marginBottom: "30px" }}>High Volume Customers</div>
                    {selectedTABox && selectedTABox === 'HVC3' ? (
                        <div className="c-r-t-box-select float-left c-center mr-2">Selected</div>
                    ) : null}
                </div> */}
        </div>
      )}
    </div>
  );
}
