import React from 'react'
import "./abTest.style.css"
import AbTestingComponent from './AbTestingComponent'
import {useState} from "react"
import {useDispatch} from "react-redux"
import {updateAbobject} from "../../../actions/abtest/abtestActionHendler"
import { useHistory } from 'react-router-dom'


function RowDataComponent(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  console.log("props.data.ChildABEngagements====",props.data.Engagement.EngagementID)
  const [childData, setChildData] = useState(props?.data?.ChildABEngagements)
  const [timeString] = useState(props.data.Engagement.AverageTimeSpent)

  let splitedtime = timeString?.split(':')
  let  seconds = Math.floor((+splitedtime[0]) * 60 * 60 + (+splitedtime[1]) * 60 + (+splitedtime[2]));


  //console.log("props.data=====", props)

  const createClick = (Engagement)=>{
    console.log('=====eeeeeeeee', Engagement?.EngagementID)
    const data = {
    EngagementID : Engagement?.EngagementID,
    abTestFlage : true
    }
    dispatch(updateAbobject(data))
    history.push("/engagements/smart")
    

  }
 // console.log('childData======', childData)
  return (
    <div style={{marginBottom:"40px"}}>
        <div className='rowStyle' >
                <div className='rowTextStyleName'>
                   {props?.data?.Engagement?.ShortName}
                </div>
                <div className='rowTextStyle'>
                    {props?.data?.Engagement?.NoOfCustomers}
                </div>
                <div className='rowTextStyle'>
                   {seconds}
                </div>
                <div className='rowTextStyle'>
                {props.data.Engagement.NoOfWinners}
                </div>
                <div className='rowTextStyle'>
               { new Date(props?.data?.Engagement?.AB_Test_CreatedAt).toLocaleDateString()}
                </div>
                <div style={{width:"14%"}} >
                <div className="btn-create-ab-row " onClick={()=>createClick(props?.data?.Engagement)}>
                            <span className="btn-ab-text">Create AB Tests</span>
                        </div>
                </div>        
                
            </div>
            {childData?.map((item,index)=>(<AbTestingComponent key={index} data={item} />))}
    </div>
  )
}

export default RowDataComponent