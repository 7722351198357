import React, { useState } from "react";
import { useElements, useStripe, PaymentElement, AddressElement} from '@stripe/react-stripe-js';
import { PrimayButton } from "../Billing";
import { Redirect } from 'react-router-dom';
import "./Payment.css";

const CheckoutForm = ({paymentSecret}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentIntentData, setPaymentIntentData] = useState();

  const handleSubmit = async(e) => {
    e.preventDefault();
    
    const {error: submitError} = await elements.submit();
    if (submitError) {
      console.log(submitError);
      return;
    }

    let { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      clientSecret: paymentSecret,
      confirmParams: {
        return_url: window.location.href,
      }
    });

    if(error) {
      console.log(error);
      return;
    }
    setPaymentIntentData(paymentIntent);
  }

  if(paymentIntentData && paymentIntentData.status === 'succeeded') {
    return <Redirect to={{pathname: '/billing'}} />
  }

  return(
    <form onSubmit={handleSubmit}>
      <AddressElement
        options={{
          mode: "billing",
        }}
      />
      <PaymentElement />
      <PrimayButton type="submit" style={{marginTop: "20px"}} disabled={!stripe}>Submit</PrimayButton>
    </form>
  );
};

export default CheckoutForm;
