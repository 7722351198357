import React from "react";
import { useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";
//import img from "https://blsh-social.s3.amazonaws.com/i/manya/m1.png";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { borderRadius } from "polished";
import { useDispatch } from "react-redux";
import { updateStoryId } from "../../actions/story/storyActionHendler";

const useStyles = makeStyles({
  name: {
    marginLeft: "5px",
    fontWeight: "bold",
    marginTop: "8px",
  },
  tagp: {
    marginLeft: "5px",
    color: "#999ea7eb",
  },
  tagp1: {
    marginLeft: "5px",
    color: "black",
  },
  card: {
    position: "relative",
  },
});

function PerformanceCard({
  postEngagementDetails,
  thumbnailTitle,
  thumbnailURL,
}) {
  const numberOfLikes = postEngagementDetails?.NumberofLikesFormat;
  const numberOfClicks = postEngagementDetails?.NumberofClicksFormat;
  const avgDurationInSeconds =
    postEngagementDetails?.AvgDurationInSecondsFormat;
  const numberofViews = postEngagementDetails?.NumberofViewsFormat;
  const history = useHistory();
  const Dispatch = useDispatch();
  const { name, tagp, card, tagp1 } = useStyles();

  return (
    <div style={{ marginRight: "25px", marginBottom: "25px" }}>
      <Card
        className={card}
        style={{ minWidth: "24vw", maxWidth: "24vw", height: 500 }}
      >
        <CardMedia
          component="img"
          height="400"
          image={thumbnailURL}
          alt="green iguana"
        />

        <Typography
          className={name}
          gutterBottom
          variant="body2"
          component="div"
        >
          {thumbnailTitle.slice(0, 35)}..
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "5px 8px 5px 3px",
          }}
        >
          {" "}
          <div style={{ display: "flex" }}>
            <Typography
              className={tagp}
              gutterBottom
              variant="body2"
              component="div"
            >
              Number of Views
            </Typography>
            <Typography
              className={tagp1}
              gutterBottom
              variant="body2"
              component="div"
            >
              {numberofViews}
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <Typography
              className={tagp}
              gutterBottom
              variant="body2"
              component="div"
            >
              Number of Likes
            </Typography>
            <Typography
              className={tagp1}
              gutterBottom
              variant="body2"
              component="div"
            >
              {numberOfLikes}
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "5px 8px 5px 3px",
          }}
        >
          <div style={{ display: "flex" }}>
            <Typography
              className={tagp}
              gutterBottom
              variant="body2"
              component="div"
            >
              CTA Count :
            </Typography>
            <Typography
              className={tagp1}
              gutterBottom
              variant="body2"
              component="div"
            >
              {numberOfClicks}
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <Typography
              className={tagp}
              gutterBottom
              variant="body2"
              component="div"
            >
              Avg watch duration:
            </Typography>
            <Typography
              className={tagp1}
              gutterBottom
              variant="body2"
              component="div"
            >
              {avgDurationInSeconds}(sec)
            </Typography>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default PerformanceCard;
