import { ACTION_TYPE } from '../../constants/actionTypes';

const initialState = {
  segmenstList :[]
};
const SegmentsReducer = (state = initialState, action) => {
  switch (action.type) {

    case ACTION_TYPE:
      return {
          ...state,
          segmenstList : action.payload
      };
    
    default: {
      return state;
    }
  }
};
export default SegmentsReducer;
