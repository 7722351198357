import React, { useState, useEffect } from "react";
import {
  Textarea,
  InputBase,
  InputLabel,
  TextareaAutosize,
  IconButton,
  ButtonBase,
} from "@material-ui/core";
import {
  EVNT_PROD_HOST_URI,
  ENGT_PROD_BASE_URI,
  ENGT_PROD_HOST_URI,
  SOMETHING_WENT_WRONG,
  GET_PRODUCT_DETAILS,
  GET_POST_PRODUCT,
  GET_POST_CONTENT,
} from "../../api/apiConstants";
import { postAuthAndData, getAuthAndData } from "../../api/ApiHelper";
import { useHistory } from "react-router";
import createNotification from "../common/reactNotification";
import { NotificationContainer } from "react-notifications";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";
import { useDispatch } from "react-redux";
import SearchIcon from "@material-ui/icons//Search";
import CloseIcon from "@material-ui/icons/Close";

function StoryLibrary_Description(props) {
  const [activeProductFilter, setActiveProductFilter] = useState("name");
  const history = useHistory();
  const [inputMsg, setInputMsg] = useState("");
  const Dispatch = useDispatch();
  const [preview, setpreview] = useState(false);
  const [disableButton, setdisableButton] = useState(false);
  const [feeds, setFeeds] = useState([]);

  useEffect(() => {
    if (props.selectedCard?.postId)  {
      fatchPostProduct();    
    }
  }, [props.selectedCard]);

  const fatchPostProduct = async () => {
    handleLoader(true);
    try {
      getAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_POST_PRODUCT}${props.selectedCard?.postId}`,
        null,
        history
      ).then((res) => {
        if (handleResponseCode(res)) {
          console.log("response==>", res?.data.length)
          if (res?.data.length > 0) {
            res?.data.map((item) => props.productIds.push(item.productId));
            props?.setProductDetail(res?.data);
          }
        }
      });
    } catch (err) {}
    handleLoader(false);
  };

  const deleteProduct = (product) => {

    const idsArray = props.productIds.filter((e) => e !== product?.productId);
    props?.updateProductid(idsArray)


    const filterdArr = props?.productDetail?.filter(
      (e) => e?.productId !== product?.productId
    );

    props?.setProductDetail(()=>filterdArr);


  };

  const handleResponce = (event) => {
    setInputMsg(event.target.value);
  };

  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
  };
  const handleResponseCode = (resp) => {
    if (!resp || resp.code === -1) {
      createNotification("error", SOMETHING_WENT_WRONG + "in Target Audience");
      return false;
    } else {
      return true;
    }
  };

  const fatchProductDetail = async (id) => {
    handleLoader(true);
    setdisableButton(true)
    const body = {
      Sku: activeProductFilter == "sku" ? inputMsg : "",
      PortalProductId: activeProductFilter === "id" ? inputMsg : "",
      ProductName: "",
      ProductId: "",
    };
    try {
      postAuthAndData(
        `${EVNT_PROD_HOST_URI}${GET_PRODUCT_DETAILS}`,
        body,
        history
      ).then((res) => {
        if (handleResponseCode(res)) {
          setdisableButton(false)
          const isAvilable = props?.productIds?.find(
            (e) => e == res?.data[0]?.productId
          );
          console.log(isAvilable);
          if (isAvilable) {
            createNotification(
              "warning",
              "This prodect is already in the list"
            );
          } else {
            if (res?.data[0]) {
              props.productIds.push(res?.data[0]?.productId);
              props?.setProductDetail([res.data[0], ...props?.productDetail]);
              setInputMsg("");
              setpreview(true);
            }
          }
        }
      });
    } catch (err) {}
    handleLoader(false);
    setdisableButton(false);
  };

  const onSearch = () =>  {
    if (inputMsg) {
    console.log("onsearch");
    fatchProductDetail();
  } else {
    createNotification("warning", "Please enter ID or SKU or name or url");
  }
}

  return (
    <div
    // style={{
    //   border:"1px solid green",
    // }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          padding: "10px ",
          fontSize: "13px",
          width: "100%",
        }}
      >
        <div
          style={{
            background: activeProductFilter === "name" ? "#1F2020" : "#F2F2F2",
            borderRadius: "7px",
            padding: "5px 8px",
            cursor: "pointer",
            color: activeProductFilter === "name" ? "#FFFFFF" : "#212222",
          }}
          onClick={() => setActiveProductFilter("name")}
        >
          By Name
        </div>
        <div
          style={{
            background: activeProductFilter === "sku" ? "#1F2020" : "#F2F2F2",
            borderRadius: "7px",
            cursor: "pointer",
            padding: "5px 8px",
            color: activeProductFilter === "sku" ? "#FFFFFF" : "#212222",
          }}
          onClick={() => setActiveProductFilter("sku")}
        >
          By SKU
        </div>
        <div
          style={{
            background: activeProductFilter === "id" ? "#1F2020" : "#F2F2F2",
            borderRadius: "7px",
            padding: "5px 8px",
            cursor: "pointer",
            color: activeProductFilter === "id" ? "#FFFFFF" : "#212222",
          }}
          onClick={() => setActiveProductFilter("id")}
        >
          By ID
        </div>
        <div
          style={{
            background: activeProductFilter === "url" ? "#1F2020" : "#F2F2F2",
            borderRadius: "7px",
            cursor: "pointer",
            padding: "5px 8px",
            color: activeProductFilter === "url" ? "#FFFFFF" : "#212222",
          }}
          onClick={() => setActiveProductFilter("url")}
        >
          By URL
        </div>
      </div>
      <div
        style={{
          height: "90%",
        }}
      >
        <p
          style={{
            // padding: "10px",
            color: "rgba(0, 0, 0, 0.4)",
            fontSize: "12px",
          }}
        >
          Search Product
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0 5px",
              borderRadius: "10px",
              width: "80%",
              border: "1px solid #F0F0F0",
              boxShadow: "0px 0px 14.85px rgba(0, 0, 0, 0.05)",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1, border: "1px solid" }}
              style={{ fontSize: "12px" }}
              placeholder={"Search by " + activeProductFilter}
              value={inputMsg}
              onChange={(event) => handleResponce(event)}
              inputProps={{ "aria-label": "sSearch by By SKU" }}
            />
            {/* <IconButton
              type="button"
              aria-label="search"
              style={{
                margin: 0,
                padding: "5px",
                background: "rgba(1, 126, 250, 0.1)",
                borderRadius: "8px",
                backdropFilter: "blur(69.8723px)",
              }}
            >
              <SearchIcon style={{ fontSize: "18px", color: "#017EFA" }} />
            </IconButton> */}
          </div>
          <ButtonBase
            style={{
              color: "#017EFA",
              background: "#F2F9FF",
              padding: "5px 15px",
              border: "1px solid #57B2E0",
              borderRadius: "9px",
              fontSize: "12px",
            }}
            onClick={onSearch}
            disabled = {disableButton}
          >
            Add
          </ButtonBase>
        </div>
        <p
          style={{
            color: "#344767",
            margin: "10px 0",
            fontSize: "14px",
            textAlign: "center",
            fontWeight: 500,
          }}
        >
          Product List
        </p>
        {props.productDetail.map((product) => (
          <div
            style={{
              background: "rgba(242, 249, 255, 0.5)",
              borderRadius: "10px",
              padding: "10px",
              marginBottom: "10px",
              display: "flex",
              gap: "10px",
            }}
          >
            <CloseIcon
              style={{
                color: "Red",
                fontSize: "25px",
                zIndex: "999",
                marginRight: "20px",
                cursor: "pointer",
              }}
              onClick={() => deleteProduct(product)}
            />
            <img
              src={product?.baseImageURL}
              alt=""
              height={"64px"}
              width={"64px"}
              style={{ borderRadius: "10px" }}
            />
            <div>
              <p
                style={{
                  fontSize: "14px",
                  margin: 0,
                }}
              >
                {product?.productdisplayName}
              </p>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#000000",
                }}
              >
                {product?.formattedProductPrice}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StoryLibrary_Description;
