import React from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { CardContent, Box, Button } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CloseIcon from "@material-ui/icons/Close";

function ProductList({ associatedProduct}) {
  console.log(associatedProduct,"associated products")
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        height: "630px",
        marginTop: "20px",
        padding: "3%",
        display: "flex",
        flexDirection: "column",
        // height: containerHeightCalcFn(260),
        overflowY: "scroll",
        whiteSpace: "nowrap",
        "&::-webkit-scrollbar": {
          height: "10px",
        },
      }}
    >
      {associatedProduct?.map((product) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",

            // opacity: !showProductDetail && videoPlaying ? "1" : "0",
            // padding: "5%",
          }}
        >
          <Card
            // onClick={() => setShowProductDetail(true)}
            style={{
              maxWidth: "70%",
              minWidth: "70%",
              display: "flex",
              height: "110px",
              marginLeft: "12px",
              cursor: "pointer",
              marginBottom: "10px",
              zIndex: 1,
              background: "rgba(242, 249, 255, 0.5)",
              borderRadius: "13px",
            }}
          >
            <img
              src={product?.baseImageURL}
              alt={product?.productdisplayName}
              width="100px"
              height="110px"
            />
            <div
              //className={boxStyle}
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "10px",
                marginTop: "-5px",
              }}
            >
              <CardContent style={{ marginLeft: "-10px" }}>
                <Typography
                  style={{
                    fontSize: "14px",
                    whiteSpace: "initial",
                  }}
                  variant="subtitle1"
                  color="textSecondary"
                  component="div"
                >
                  {product?.productdisplayName?.length > 33
                    ? product?.productdisplayName?.slice(0, 33) + ".."
                    : product?.productdisplayName}
                </Typography>
                <div style={{ display: "flex" }}>
                  <Typography style={{ fontSize: "12px" }} component="div">
                    {product?.formattedProductPrice}
                  </Typography>
                  {/* <Button
                  //className={MuiButtonContainedSizeSmall}
                  style={{
                    backgroundColor: "#957D82",
                    fontSize: "10px",
                    marginLeft: "4px",
                    padding: "0px 4px",
                    color: "white",
                  }}
                  variant="contained"
                  size="small"
                >
                  Buy Now
                </Button> */}
                </div>
              </CardContent>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
}

export default ProductList;
