import { ACTION_TYPE } from '../../constants/actionTypes';

const initialState = {
    
};
const AnalyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    
    default: {
      return state;
    }
  }
};
export default AnalyticsReducer;
