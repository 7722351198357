import { AB_TEST_ACTION_TYPE } from "../../constants/actionTypes";

const INITIAL_STATE = {
    EngagementID :null,
    abTestFlage : false
};

const abtestReducer = (state = INITIAL_STATE, action) =>{
    switch(action.type){
          case AB_TEST_ACTION_TYPE:
              return { 
                  ...state,
                  EngagementID : action.payload.EngagementID,
                  abTestFlage : action.payload.abTestFlage,
              };
               
    
    default: {
        return state;
      }  
}
}
export default abtestReducer;