import { Dark_Mode, Light_Mode } from "../../constants/actionTypes";

const INITIAL_STATE = {
    Rtheme: "light", // Default theme is 'light'
  };
  
  const themeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

      case Dark_Mode:
        return {
          ...state,
          Rtheme: action?.payload,
        };

        case Light_Mode:
        return {
          ...state,
          Rtheme: action?.payload,
        };
        
      default:
        return state;
    }
  };
  
  export default themeReducer;