import React, { useState, useEffect, Suspense, lazy, useReducer } from "react";
import "./designApps.css";
import { getAuthAndData } from "../../../../../../api/ApiHelper";
import {
  ENGT_PROD_BASE_URI,
  GET_PIXELBAY_IMAGE,
  GET_PIXELBAY_VIDEO,
} from "../../../../../../api/apiConstants";
import SearchIcon from "@material-ui/icons/Search";
import { Typography } from "@material-ui/core";
import Px from "../../../../Image/Icon/px.png";
import Unplashs from "../../../../Image/Icon/unplash.png";
import SvImage from "../../../../Image/Icon/svimage.png";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../../../../../actions/route/routeActionHandler";
const TemplateCategory = lazy(() => import("../Template/TemplateCategory.js"));

const DesignApps = ({ type }) => {
  // const customStyles = {
  //   control: (provided) => ({
  //     ...provided,
  //     color: "#fff",
  //     backgroundColor: "#27272f", // Custom background color
  //     borderColor: "#484851", // Custom border color
  //     boxShadow: "none", // Remove default box-shadow
  //     "&:hover": {
  //       borderColor: "#555", // Custom border color on hover
  //     },
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isSelected ? "#555" : "transparent", // Highlight selected option
  //     color: state.isSelected ? "#48485" : "#000", // Custom text color
  //     "&:hover": {
  //       backgroundColor: state.isSelected ? "#555" : "#f1f1f1", // Highlight selected option on hover
  //     },
  //     singleValue: (provided, state) => ({
  //       ...provided,
  //       color: "#fff", // Custom text color for the selected option
  //       fontWeight: "bold", // Make the selected option bold
  //     }),
  //   }),
  //   // Customize other components as needed (e.g., singleValue, menu, etc.)
  // };

  const dispatch = useDispatch();
  const handleLoader = (showBool) => {
    dispatch(dispatchLoaderData(showBool));
  };

  const pxApps = [
    {
      id: 1,
      imageUrl: Px,
      title: "Pixabay",
    },
    {
      id: 2,
      imageUrl: Unplashs,
      title: "Unplashs",
    },
    {
      id: 3,
      imageUrl: SvImage,
      title: "SvImage",
    },
  ];

  const reducer = (state, action) => {
    switch (action.type) {
      case "Pixabay":
        return {
          ...state,
          [appSelected]: action.data,
        };
      default:
        return state;
    }
  };

  const [appSelected, setAppSelected] = useState(null);
  const [appTitle, setAppTitle] = useState(null);
  const [appsMediaCollection, dispatchAppsMediaCollection] = useReducer(
    reducer,
    null
  );
  const [selectedPlaylist, setSelectedPlaylist] = useState({});
  const [searchApp, setSearchApp] = useState(null);
  const [searchAppCollection, setSearchAppCollection] = useState([]);

  const GetAllAppsByType = async () => {
    handleLoader(true);
    if (appSelected === 1) {
      await getAuthAndData(
        `${ENGT_PROD_BASE_URI}${
          type === 1 ? GET_PIXELBAY_IMAGE : GET_PIXELBAY_VIDEO
        }`
      )
        .then((response) => {
          if (response.code === 1) {
            if (type === 1) {
              dispatchAppsMediaCollection({
                type: "Pixabay",
                data: [...response.data?.photos],
              });
            } else {
              dispatchAppsMediaCollection({
                type: "Pixabay",
                data: [...response.data?.videos],
              });
            }
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
    handleLoader(false);
  };

  const GetAppsbyName = async () => {
    handleLoader(true);

    await getAuthAndData(
      `${ENGT_PROD_BASE_URI}${
        type === 1 ? GET_PIXELBAY_IMAGE : GET_PIXELBAY_VIDEO
      }?search=${searchApp}`
    )
      .then((response) => {
        if (response.code === 1) {
          if (type === 1) {
            setSearchAppCollection([...response.data?.photos]);
          } else {
            setSearchAppCollection([...response.data?.videos]);
          }
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
    handleLoader(false);
  };

  useEffect(() => {
    (() => {
      if (type === 1) {
        GetAllAppsByType(1);
      } else {
        GetAllAppsByType(2);
      }
    })();
  }, [appSelected]);

  useEffect(() => {
    if (
      !!searchApp &&
      searchApp !== "" &&
      searchApp.length >= 3 &&
      !!appSelected
    ) {
      const getTemplate = setTimeout(() => {
        GetAppsbyName();
      }, [2000]);

      return () => clearTimeout(getTemplate);
    }
  }, [searchApp]);

  return (
    <div className="boxtemplate">
      <div
        style={{
          width: "300px",
          height: "610px",
          padding: "10px 10px 10px",
          background: "#27272F",
        }}
      >
        {!!appSelected ? (
          <>
            <div className="searchbox">
              <SearchIcon />
              <input
                id="outlined-basic"
                type="text"
                className="search-input"
                placeholder="Search Apps"
                onChange={(event) => {
                  setSearchApp(event.target.value);
                }}
              />
            </div>
            <div
              className="d-flex flex-row bd-highlight justify-content-between align-items-center"
              style={{ marginTop: "10px" }}
            >
              <Typography>{appTitle}</Typography>
              <div
                className="view-more"
                onClick={() => {
                  setAppSelected(null);
                  setAppTitle(null);
                }}
              >
                <ArrowBackIcon style={{ width: " 20px", height: "20px" }} />
              </div>
            </div>
            {!!searchApp && searchApp.length >= 3 && (
              <>
                {searchAppCollection?.length > 0 && (
                  <Suspense fallback={<div>Loading</div>}>
                    <TemplateCategory
                      playlistgroup={searchAppCollection}
                      type={type === 1 ? 7 : 8}
                    />
                  </Suspense>
                )}
              </>
            )}
            {!!appsMediaCollection && !!appsMediaCollection?.[appSelected] && (
              <>
                <Suspense fallback={<div>Loading</div>}>
                  <TemplateCategory
                    playlistgroup={appsMediaCollection?.[appSelected]}
                    type={type === 1 ? 7 : 8}
                  />
                </Suspense>
              </>
            )}
          </>
        ) : (
          <>
            <div className="searchbox">
              <SearchIcon />
              <input
                id="outlined-basic"
                type="text"
                className="search-input"
                placeholder="Search Apps"
                onChange={(event) => {
                  setSearchApp(event.target.value);
                }}
              />
            </div>
            <Typography style={{ marginTop: "10px" }}>Apps</Typography>
            <div className="card-container_px">
              {pxApps.map((app) => {
                return (
                  <img
                    key={app.id}
                    id={app.id}
                    className="app-card"
                    alt={app.title}
                    src={app.imageUrl}
                    onClick={() => {
                      setAppSelected(app.id);
                      setAppTitle(app.title);
                    }}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DesignApps;
