import React, { useState, useRef, useEffect } from "react";
import "./product.css";
import Dropzone, { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  EVNT_PROD_HOST_URI,
  GET_PRODUCT_IMAGES,
} from "../../../../../../api/apiConstants";
import { getAuthAndData } from "../../../../../../api/ApiHelper";
import TemplateCategory from "../Template/TemplateCategory";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../../../../../actions/route/routeActionHandler";

const Product = () => {
  const dispatch = useDispatch();
  const handleLoader = (showBool) => {
    dispatch(dispatchLoaderData(showBool));
  };

  const [selectedImages, setSelectedImages] = useState([]);
  const fileInputRef = useRef(null);
  console.log("selectedImages", selectedImages);

  const handleImageChange = (event) => {
    const files = event.target.files;
    const newImages = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onloadend = () => {
        newImages.push(reader.result);

        // Check if all images have been loaded
        if (newImages.length === files.length) {
          setSelectedImages((prevImages) => [...prevImages, ...newImages]);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDeleteImage = (index) => {
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  // const customStyles = {
  //   control: (provided) => ({
  //     ...provided,
  //     color: "#fff",
  //     backgroundColor: "#27272f", // Custom background color
  //     borderColor: "#484851", // Custom border color
  //     boxShadow: "none", // Remove default box-shadow
  //     "&:hover": {
  //       borderColor: "#555", // Custom border color on hover
  //     },
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isSelected ? "#555" : "transparent", // Highlight selected option
  //     color: state.isSelected ? "#48485" : "#000", // Custom text color
  //     "&:hover": {
  //       backgroundColor: state.isSelected ? "#555" : "#f1f1f1", // Highlight selected option on hover
  //     },
  //     singleValue: (provided, state) => ({
  //       ...provided,
  //       color: "#fff", // Custom text color for the selected option
  //       fontWeight: "bold", // Make the selected option bold
  //     }),
  //   }),
  //   // Customize other components as needed (e.g., singleValue, menu, etc.)
  // };
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [productCollection, setProductCollection] = useState([]);
  const [searchProduct, setSearchProduct] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const GetAllProduct = async () => {
    handleLoader(true);

    await getAuthAndData(`${EVNT_PROD_HOST_URI}${GET_PRODUCT_IMAGES}`)
      .then((response) => {
        if (response.code === 1) {
          setProductCollection([...response.data]);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
    handleLoader(false);
  };

  const GetProductsbyName = () => {
    setFilteredProducts(
      productCollection.filter((product) =>
        product.product_name.toLowerCase().includes(searchProduct.toLowerCase())
      )
    );
  };

  const handleCustomizeButtonClick = () => {
    // Trigger the hidden file input when the customize button is clicked
    fileInputRef.current.click();
  };

  useEffect(() => {
    GetAllProduct();
  }, []);

  useEffect(() => {
    if (!!searchProduct && searchProduct !== "" && searchProduct.length >= 3) {
      const getTemplate = setTimeout(() => {
        GetProductsbyName();
      }, [2000]);

      return () => clearTimeout(getTemplate);
    }
  }, [searchProduct]);

  return (
    <div className="boxtemplate">
      <div
        style={{
          width: "300px",
          height: "610px",
          padding: "10px 10px 10px",
          background: "#27272F",
        }}
      >
        <div className="searchbox">
          <SearchIcon />
          <input
            id="outlined-basic"
            className="search-input"
            type="text"
            placeholder="Search Product"
            onChange={(event) => {
              if (event.target.value === "") {
                setSearchProduct(null);
              }
              setSearchProduct(event.target.value);
            }}
          />
        </div>
        {/* <Dropzone
          styles={{
            borderRadius: "24px",
            border: "1px dashed #494B4D",
            background: "rgba(27, 27, 34, 0.40)",
          }}
          // onDrop={(acceptedFiles) => newFileUploadThumbnail(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="upload-product">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageChange}
                style={{ display: "none" }}
                multiple
              />

              <CloudUploadIcon
                className="uplodeimg"
                style={{ fontSize: "3rem", color: "#494B4D" }}
                onClick={handleCustomizeButtonClick}
              />

              <p style={{ color: "#FFF" }}>
                Drag and drop files or{" "}
                <span
                  style={{ color: "#017EFA" }}
                  onClick={handleCustomizeButtonClick}
                >
                  Browse
                </span>
              </p>

              <>
                <p
                  style={{
                    margin: 0,
                    fontSize: "14px",
                    color: "rgba(255, 255, 255, 0.50)",
                  }}
                >
                  Just drag and drop your media file. e.g., image, video, audios
                </p>
              </>
            </div>
          )}
        </Dropzone> */}
        {/* <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            {selectedImages.length > 0 &&
              selectedImages.map((image, index) => (
                <div key={index} className="">
                  <div style={{ position: "relative" }}>
                    <img
                      src={image}
                      alt={`Image ${index + 1}`}
                      style={{ width: "120px", height: "100px" }}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      onClick={() => handleDeleteImage(index)}
                      style={{
                        position: "absolute",
                        top: 0,
                        //right: '5px',
                        // marginTop: '-10px', // Negative margin to position the delete icon inside the image container
                        marginRight: "-10px",
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        zIndex: 1,
                      }}
                    >
                      {" "}
                      &#10006;
                      <path
                        d="M18.0584 4.85817C16.7167 4.72484 15.375 4.62484 14.025 4.54984V4.5415L13.8417 3.45817C13.7167 2.6915 13.5334 1.5415 11.5834 1.5415H9.40005C7.45838 1.5415 7.27505 2.6415 7.14172 3.44984L6.96672 4.5165C6.19172 4.5665 5.41672 4.6165 4.64172 4.6915L2.94172 4.85817C2.59172 4.8915 2.34172 5.19984 2.37505 5.5415C2.40838 5.88317 2.70838 6.13317 3.05838 6.09984L4.75838 5.93317C9.12505 5.49984 13.5251 5.6665 17.9417 6.10817C17.9667 6.10817 17.9834 6.10817 18.0084 6.10817C18.3251 6.10817 18.6 5.8665 18.6334 5.5415C18.6584 5.19984 18.4084 4.8915 18.0584 4.85817Z"
                        fill="#C0C0D6"
                      />
                      <path
                        d="M16.525 7.2835C16.325 7.07516 16.05 6.9585 15.7666 6.9585H5.23329C4.94995 6.9585 4.66662 7.07516 4.47495 7.2835C4.28329 7.49183 4.17495 7.77516 4.19162 8.06683L4.70829 16.6168C4.79995 17.8835 4.91662 19.4668 7.82495 19.4668H13.175C16.0833 19.4668 16.2 17.8918 16.2916 16.6168L16.8083 8.07516C16.825 7.77516 16.7166 7.49183 16.525 7.2835ZM11.8833 15.2918H9.10829C8.76662 15.2918 8.48329 15.0085 8.48329 14.6668C8.48329 14.3252 8.76662 14.0418 9.10829 14.0418H11.8833C12.225 14.0418 12.5083 14.3252 12.5083 14.6668C12.5083 15.0085 12.225 15.2918 11.8833 15.2918ZM12.5833 11.9585H8.41662C8.07495 11.9585 7.79162 11.6752 7.79162 11.3335C7.79162 10.9918 8.07495 10.7085 8.41662 10.7085H12.5833C12.925 10.7085 13.2083 10.9918 13.2083 11.3335C13.2083 11.6752 12.925 11.9585 12.5833 11.9585Z"
                        fill="#C0C0D6"
                      />
                    </svg>
                  </div>
                </div>
              ))}
          </div> */}
        <div className="text-store">
          {!!searchProduct && searchProduct.length >= 3 ? (
            <>
              {filteredProducts.length > 0 ? (
                <TemplateCategory playlistgroup={filteredProducts} type={6} />
              ) : (
                <div>No Product Available</div>
              )}
            </>
          ) : (
            <TemplateCategory playlistgroup={productCollection} type={6} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Product;
