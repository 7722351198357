import React, { useEffect } from "react";
import CloudHeader from "../cloudHeader";
import LeftSidebar from "../leftsidebar";
import UseDesignHook from "../UseDesignHook/index";
import "./design.css";
import styled from "styled-components";
import { Button } from "@material-ui/core";

const Design = (props) => {
  const { updateThemeToDark, updateThemeToLight } = UseDesignHook();
  const type = props.location.state.type;

  useEffect(() => {
    updateThemeToDark();
    document.body.style.background = "#22222A";

    return () => {
      updateThemeToLight();
      document.body.style.background = "white";
    };
  }, []);
  return (
    <>
      <CloudHeader />
      <div className="bd-highlight designContainer">
        <LeftSidebar type={type} />
      </div>
    </>
  );
};

export default Design;

export const TabButton = styled(Button)`
  color: #fff;
  background: #373743;
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 12px;
  width: 90px;
  height: 37px;
  margin-top: 0px;
  margin: 10px;
  border-bottom: 1px solid var(--style, #017efa);
`;

export const ExtendTabButton = styled(Button)`
  color: #fff;
  background: #373743;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
  width: 110px;
  height: 37px;
  margin-top: 0px;
  margin: 10px;
  border-bottom: 1px solid var(--style, #017efa);
`;
