import React, { useState, useRef, useEffect } from "react";
import "./assets.css";
import SearchBar from "../../../Searchbar/index";
import Select from "react-select";
import styled from "styled-components";
import Dropzone, { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Button from "@material-ui/core/Button";
import { BorderBottom } from "@material-ui/icons";
import axios from "axios";
import { useAppContext } from "../../../../../../contexts/appContext";
import {
  getAuthAndData,
  postAuthAndData,
} from "../../../../../../api/ApiHelper";
import {
  ENGT_PROD_BASE_URI,
  ENGT_PROD_HOST_URI,
  GET_MEDIA_ASSETS,
  PREE_SIGNED_URL,
  SAVE_MEDIA_DETAILS,
  SOMETHING_WENT_WRONG,
} from "../../../../../../api/apiConstants";
import createNotification from "../../../../../common/reactNotification";
import { dispatchLoaderData } from "../../../../../../actions/route/routeActionHandler";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { NotificationContainer } from "react-notifications";
import { contextType } from "react-recaptcha";

let isUploadToAWSSuccess = true;
let presignedUploadUrlArr = [];
let userData;

const Assets = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectVideos, setSelectedVideos] = useState([]);
  const [thumbnailUrl, setThumbnailUrl] = useState([]);
  const [PostContent, setPostContent] = useState([]);
  const [fileObj, setFileObj] = useState([]);
  const [thumbnailContent, setThumbnailContent] = useState([]);
  const [uploadSucess, setUploadSuccess] = useState(false);
  const [contenttype, setContenttype] = useState(1);
  userData = useSelector((select) => select.userReducer.userData);

  const dispatch = useDispatch();
  const history = useHistory();
  const fileInputRef = useRef(null);
  console.log("selectedImages", selectedImages);

  const { handleVideoDragStart, handleImageDragStart } = useAppContext();

  const handleImageChange = (event) => {
    const files = event.target.files;
    const newImages = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onloadend = () => {
        newImages.push(reader.result);

        // Check if all images have been loaded
        if (newImages.length === files.length) {
          setSelectedImages((prevImages) => [...prevImages, ...newImages]);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDeleteImage = (index) => {
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  const handleDeleteVideo = (index) => {
    setSelectedVideos((prevVideos) => {
      const updatedVideos = [...prevVideos];
      updatedVideos.splice(index, 1);
      return updatedVideos;
    });
  };

  const handleCustomizeButtonClick = () => {
    // Trigger the hidden file input when the customize button is clicked
    fileInputRef.current.click();
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      color: "#fff",
      backgroundColor: "#27272f", // Custom background color
      borderColor: "#484851", // Custom border color
      boxShadow: "none", // Remove default box-shadow
      "&:hover": {
        borderColor: "#555", // Custom border color on hover
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#555" : "transparent", // Highlight selected option
      color: state.isSelected ? "#48485" : "#000", // Custom text color
      "&:hover": {
        backgroundColor: state.isSelected ? "#555" : "#f1f1f1", // Highlight selected option on hover
      },
      singleValue: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "white" : "white",
        //color: "#fff", // Custom text color for the selected option
        fontWeight: "bold", // Make the selected option bold
      }),
    }),
    // Customize other components as needed (e.g., singleValue, menu, etc.)
  };
  const [active, setActive] = useState("Asset");
  function tabClick(val) {
    setActive(val);
    console.log("valu", val);
  }
  const [playlistCollection, setPlaylistCollection] = useState([
    {
      value: -1,
      label: "All Playlist",
      playlistCode: "",
    },
    {
      value: 1,
      label: "All Playlist 1",
      playlistCode: "",
    },
  ]);
  const [selectedPlaylist, setSelectedPlaylist] = useState({
    value: -1,
    label: "All Playlist",
    playlistCode: "",
  });
  const onPlaylistChange = (target) => {
    console.log("target=====", target);
    setSelectedPlaylist(target);
  };

  const handleDragStart = (e, index) => {
    // Store the index of the dragged image in dataTransfer
    e.dataTransfer.setData("text/plain", index.toString());
  };

  const handleResponseCode = (resp) => {
    if (!resp || resp.code === -1) {
      createNotification(
        "error",
        SOMETHING_WENT_WRONG + ", Please try again later."
      );
      handleLoader(false);

      return false;
    } else {
      //setSelectedImages(resp.data);
      return true;
    }
  };

  const handleResponseCode2 = (res, contentType) => {
    if (res.code === 1) {
      if (contentType === 1) {
        setUploadSuccess(false);
        setSelectedImages(res.data);
      } else {
        setUploadSuccess(false);
        setSelectedVideos(res.data);
      }
    }
  };

  const onDrop = (acceptedFiles) => {
    isUploadToAWSSuccess = true;
    setPostContent([]);
    setThumbnailContent([]);
    setFileObj([]);
    setThumbnailUrl([]);
    const filterObjArr = [];
    acceptedFiles?.forEach((file, index) => {
      console.log("ind file", file);
      ////console("file_extention===", file.name.split(".").pop());
      let extensionType = file.name.split(".").pop().toLowerCase();
      if (
        extensionType == "jpeg" ||
        extensionType == "jpg" ||
        extensionType == "mp4" ||
        extensionType == "png" ||
        extensionType == "webp" ||
        extensionType == "mp3"
      ) {
        if (
          (extensionType == "mp4" && file.size < 5000000) || //Video Size max is 5 MB
          ((extensionType == "jpeg" ||
            extensionType == "jpg" ||
            extensionType == "png") &&
            file.size < 1000000) ||
          (extensionType == "mp3" && file.size < 1000000)
        ) {
          const obj = {
            extension: file.name.split(".").pop(),
            contentType:
              extensionType == "mp4" ? "2" : extensionType == "mp3" ? "3" : "1",
            file: file,
            FileId: ` ${Date.now()}_${file.name}`,
          };
          filterObjArr.push(obj);
          ////console.log("File Object====",obj);
        } else {
          createNotification(
            "warning",
            "Image/Audio/Video size should be less than 1 MB/1 MB/5 MB"
          );
        }
      } else {
        createNotification(
          "warning",
          "Only jpeg, jpg, png, mp4, mp3  webp file type is allowed"
        );
      }
    });
    setFileObj(filterObjArr);
    fatchPreUrlData(filterObjArr);
  };

  const handleLoader = (showBool) => {
    dispatch(dispatchLoaderData(showBool));
  };

  const fatchPreUrlData = (filterObjArr) => {
    console.log("2 fatchPreUrlData");
    console.log("filterObjArr", filterObjArr);
    try {
      // if (!userData?.UserID) {
      //   console.log("not found");
      //   Auth.signOut();
      //   history.push("/login");
      // }
      presignedUploadUrlArr = [];

      setPostContent([]);
      handleLoader(true);
      const body = {
        userId: userData?.UserID,
        files: filterObjArr,
      };

      //debugger;
      postAuthAndData(
        `${ENGT_PROD_BASE_URI}${PREE_SIGNED_URL}`,
        body,
        history
      ).then((res) => {
        console.log("ressss", res);
        if (handleResponseCode(res)) {
          handleLoader(false);
          res?.data.forEach((e) => presignedUploadUrlArr.push(e));
          uploadDataOnAws(filterObjArr);
          // if (discription.contentType == 2) {
          //   getDuration(filterObjArr[0].file);
          //   setPostContent(
          //     res?.data.map((e) => ({
          //       fileName: e.FileKey,
          //       fileType: e.FileExtenssion.toLowerCase() == "mp4" ? 2 : 1,
          //       fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
          //     }))
          //   );
          // } else {
          //   setPostContent(
          //     res?.data.map((e) => ({
          //       fileName: e.FileKey,
          //       fileType: e.FileExtenssion.toLowerCase() == "mp4" ? 2 : 1,
          //       fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
          //     }))
          //   );
          // }
        }
      });
    } catch {}
  };

  const addContent = (fobj) => {
    console.log("4 addContent");
    ////console.log("addContent ffobExtenssion======", fobj)
    let contentFile;
    if (fobj.contentType == 1) {
      contentFile = `image/${fobj?.extension}`;
    } else {
      contentFile = `video/${fobj?.extension}`;
    }
    return contentFile;
  };

  const uploadDataOnAws = (filterObjArr) => {
    handleLoader(true);
    try {
      // if (discription.contentType != 5) {
      //   if (presignedUploadUrlArr?.length == 0) isUploadToAWSSuccess = false;
      // }
      presignedUploadUrlArr?.forEach((presignedUploadUrl) => {
        //if()
        let fobj = filterObjArr.find(
          (e) => e.FileId == presignedUploadUrl.FileId
        );
        let conetntType = addContent(fobj);
        const requestOptions = {
          method: "PUT",
          headers: {
            // "Content-Type": fileObj.find(
            //   (e) => e.FileId == presignedUploadUrl.FileId
            // ).file.type,

            "Content-Type": conetntType,
            "Access-Control-Allow-Origin": "*",
          },
          body: filterObjArr.find((e) => e.FileId == presignedUploadUrl.FileId)
            .file,
        };
        createNotification("info", "Please wait");
        fetch(
          new Request(presignedUploadUrl.PreSignedUrl, requestOptions)
        ).then((response) => {
          if (response.status == 200) {
            let dataToSend;

            if (
              conetntType === "image/png" ||
              conetntType === "image/jpg" ||
              conetntType === "image/jpeg" ||
              conetntType === "image/webp"
            ) {
              dataToSend = {
                Content_type: "1",
                url: presignedUploadUrl.PreSignedUrl,
              };
            } else if (conetntType === "video/mp4") {
              dataToSend = {
                Content_type: "2",
                url: presignedUploadUrl.PreSignedUrl,
              };
            } else if (conetntType === "audio/mp3") {
              dataToSend = {
                Content_type: "3",
                url: presignedUploadUrl.PreSignedUrl,
              };
            }

            setTimeout(() => {
              postAuthAndData(
                `${ENGT_PROD_HOST_URI}${SAVE_MEDIA_DETAILS}`,
                dataToSend
              )
                .then((res) => {
                  if (handleResponseCode(res)) {
                    console.log(" API request was successful");
                    createNotification("success", "SUCCESSFULLY DONE.");
                    setUploadSuccess(true);
                  }
                })
                .catch((error) => {
                  console.error("Error in API request:", error);
                });
            }, 2000);

            //responsehandler(true);
            //isUploadSuccess = false;
            //setFlag(true);
            // uploadDataOnAws2();
          } else {
            //setFlag(false);
            // responsehandler(false);
            //If any file is failing to upload - We shall not create the Post - Please try again
            isUploadToAWSSuccess = false;
            createNotification(
              "error",
              SOMETHING_WENT_WRONG +
                "Error uploading image to cloud, please try again."
            );
          }
        });
        handleLoader(false);
      });

      // if(isUploadSuccess)
      // {
      //   //postdata
      // }
      //  responsehandler(flag);
      //Calling Thumbnail Upload
    } catch {
      isUploadToAWSSuccess = false;
      createNotification(
        "error",
        SOMETHING_WENT_WRONG +
          "Error uploading image to cloud, please try again."
      );
    }
    handleLoader(false);
  };

  const getAllAssests = async (contentType) => {
    handleLoader(true);

    await getAuthAndData(
      `${ENGT_PROD_HOST_URI}${GET_MEDIA_ASSETS}?content_type=${contentType}`
    )
      .then((res) => {
        handleResponseCode2(res, contentType);
      })
      .catch((err) => {
        console.error("get assets error", err);
      });

    handleLoader(false);
  };

  useEffect(() => {
    getAllAssests(1);
    getAllAssests(2);
  }, [uploadSucess]);

  return (
    <div className="box_1">
      <NotificationContainer />
      <div style={{}}>
        <div
          style={{
            width: "300px",
            height: "610px",
            padding: "10px 10px 10px",
            background: "#27272F",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button
              style={{
                color: "#FFF",
                background: "#373743",
                padding: "5px 15px",
                borderRadius: "10px",
                fontSize: "12px",
                width: "90px",
                height: "37px",
                marginBottom: "10px",
                borderBottom: "1px solid var(--style, #017EFA)",
              }}
              onClick={() => setContenttype(1)}
            >
              Images
            </Button>
            <Button
              style={{
                color: "#FFF",
                background: "#373743",
                padding: "5px 15px",
                borderRadius: "10px",
                fontSize: "12px",
                width: "90px",
                height: "37px",
                marginBottom: "10px",
                borderBottom: "1px solid var(--style, #017EFA)",
              }}
              onClick={() => setContenttype(2)}
            >
              Videos
            </Button>
          </div>
          <div className="" style={{ display: "flex" }}>
            <div className="w-100">
              <Select
                options={playlistCollection}
                // isDisabled={obj.rewardType?.value!==2}
                name="rewardName"
                onChange={(e) => onPlaylistChange(e)}
                value={selectedPlaylist}
                //defaultValue= {selectedPlaylist}
                style={{ lineHeight: "28px" }}
                maxMenuHeight={150}
                styles={customStyles}
                placeholder="Select your all assets"
              />
            </div>
          </div>
          <div className="box_22">
            {/* <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="uplodeDiv">
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    multiple
                  />

                  <CloudUploadIcon
                    className="uplodeimg"
                    style={{ fontSize: "3rem", color: "#494B4D" }}
                    onClick={handleCustomizeButtonClick}
                  />

                  <p style={{ color: "#FFF" }}>
                    Drag and drop files or{" "}
                    <span
                      style={{ color: "#017EFA" }}
                      onClick={handleCustomizeButtonClick}
                    >
                      Browse
                    </span>
                  </p>

                  <>
                    <p
                      style={{
                        margin: 0,
                        fontSize: "14px",
                        color: "rgba(255, 255, 255, 0.50)",
                      }}
                    >
                      Just drag and drop your media file. e.g., image, video,
                      audios
                    </p>
                  </>
                </div>
              )}
            </Dropzone> */}
            <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="uplodeDiv">
                  <input {...getInputProps()} />
                  <CloudUploadIcon
                    className="uplodeimg"
                    style={{ fontSize: "3rem", color: "#494B4D" }}
                  />

                  <p style={{ color: "#FFF" }}>
                    Drag and drop files or{" "}
                    <span style={{ color: "#017EFA" }}>Browse</span>
                  </p>

                  <>
                    <p
                      style={{
                        margin: 0,
                        fontSize: "14px",
                        color: "rgba(255, 255, 255, 0.50)",
                      }}
                    >
                      Just drag and drop your media file. e.g., image, video,
                      audios
                    </p>
                  </>
                </div>
              )}
            </Dropzone>
          </div>
          <div className="text_upload">Upload and manage your assets.</div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              marginTop: "20px",
              height: "280px",
              overflowY: "auto",
            }}
          >
            {contenttype === 1 && (
              <>
                {selectedImages.length > 0 &&
                  selectedImages.map(({ url }, index) => {
                    const regex = /(https:\/\/.*\.(jpe?g|png|webp))/; // Regular expression to match the URL ending with .mp4
                    const match = url.match(regex);

                    if (match.length > 0) {
                      return (
                        <div key={index} className="assets-wrapper">
                          <div
                            style={{
                              position: "relative",
                              height: "120px",
                              aspectRatio: "1",
                            }}
                          >
                            <img
                              src={match[0]}
                              alt="Assest"
                              draggable={true}
                              onDragStart={(e) =>
                                handleImageDragStart(e, match[0])
                              }
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                            ></img>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                              onClick={() => handleDeleteImage(index)}
                              style={{
                                position: "absolute",
                                top: 0,
                                //right: '5px',
                                // marginTop: '-10px', // Negative margin to position the delete icon inside the image container
                                marginRight: "-10px",
                                background: "transparent",
                                border: "none",
                                cursor: "pointer",
                                zIndex: 1,
                              }}
                            >
                              &#10006;
                              <path
                                d="M18.0584 4.85817C16.7167 4.72484 15.375 4.62484 14.025 4.54984V4.5415L13.8417 3.45817C13.7167 2.6915 13.5334 1.5415 11.5834 1.5415H9.40005C7.45838 1.5415 7.27505 2.6415 7.14172 3.44984L6.96672 4.5165C6.19172 4.5665 5.41672 4.6165 4.64172 4.6915L2.94172 4.85817C2.59172 4.8915 2.34172 5.19984 2.37505 5.5415C2.40838 5.88317 2.70838 6.13317 3.05838 6.09984L4.75838 5.93317C9.12505 5.49984 13.5251 5.6665 17.9417 6.10817C17.9667 6.10817 17.9834 6.10817 18.0084 6.10817C18.3251 6.10817 18.6 5.8665 18.6334 5.5415C18.6584 5.19984 18.4084 4.8915 18.0584 4.85817Z"
                                fill="#C0C0D6"
                              />
                              <path
                                d="M16.525 7.2835C16.325 7.07516 16.05 6.9585 15.7666 6.9585H5.23329C4.94995 6.9585 4.66662 7.07516 4.47495 7.2835C4.28329 7.49183 4.17495 7.77516 4.19162 8.06683L4.70829 16.6168C4.79995 17.8835 4.91662 19.4668 7.82495 19.4668H13.175C16.0833 19.4668 16.2 17.8918 16.2916 16.6168L16.8083 8.07516C16.825 7.77516 16.7166 7.49183 16.525 7.2835ZM11.8833 15.2918H9.10829C8.76662 15.2918 8.48329 15.0085 8.48329 14.6668C8.48329 14.3252 8.76662 14.0418 9.10829 14.0418H11.8833C12.225 14.0418 12.5083 14.3252 12.5083 14.6668C12.5083 15.0085 12.225 15.2918 11.8833 15.2918ZM12.5833 11.9585H8.41662C8.07495 11.9585 7.79162 11.6752 7.79162 11.3335C7.79162 10.9918 8.07495 10.7085 8.41662 10.7085H12.5833C12.925 10.7085 13.2083 10.9918 13.2083 11.3335C13.2083 11.6752 12.925 11.9585 12.5833 11.9585Z"
                                fill="#C0C0D6"
                              />
                            </svg>
                          </div>
                        </div>
                      );
                    }
                  })}
              </>
            )}
            {contenttype === 2 && (
              <>
                {selectVideos.length > 0 &&
                  selectVideos.map(({ url }, index) => {
                    const regex = /(https:\/\/.*\.mp4)/; // Regular expression to match the URL ending with .mp4
                    const match = url.match(regex);

                    if (url.includes(".mp4")) {
                      return (
                        <div key={index} className="assets-wrapper">
                          <div
                            style={{
                              position: "relative",
                              height: "120px",
                              aspectRatio: "1",
                            }}
                          >
                            <video
                              draggable={true}
                              onDragStart={(e) =>
                                handleVideoDragStart(e, match[0])
                              }
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              preload="metadata"
                            >
                              <source src={match[0]} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                              onClick={() => handleDeleteVideo(index)}
                              style={{
                                position: "absolute",
                                top: 0,
                                //right: '5px',
                                // marginTop: '-10px', // Negative margin to position the delete icon inside the image container
                                marginRight: "-10px",
                                background: "transparent",
                                border: "none",
                                cursor: "pointer",
                                zIndex: 1,
                              }}
                            >
                              &#10006;
                              <path
                                d="M18.0584 4.85817C16.7167 4.72484 15.375 4.62484 14.025 4.54984V4.5415L13.8417 3.45817C13.7167 2.6915 13.5334 1.5415 11.5834 1.5415H9.40005C7.45838 1.5415 7.27505 2.6415 7.14172 3.44984L6.96672 4.5165C6.19172 4.5665 5.41672 4.6165 4.64172 4.6915L2.94172 4.85817C2.59172 4.8915 2.34172 5.19984 2.37505 5.5415C2.40838 5.88317 2.70838 6.13317 3.05838 6.09984L4.75838 5.93317C9.12505 5.49984 13.5251 5.6665 17.9417 6.10817C17.9667 6.10817 17.9834 6.10817 18.0084 6.10817C18.3251 6.10817 18.6 5.8665 18.6334 5.5415C18.6584 5.19984 18.4084 4.8915 18.0584 4.85817Z"
                                fill="#C0C0D6"
                              />
                              <path
                                d="M16.525 7.2835C16.325 7.07516 16.05 6.9585 15.7666 6.9585H5.23329C4.94995 6.9585 4.66662 7.07516 4.47495 7.2835C4.28329 7.49183 4.17495 7.77516 4.19162 8.06683L4.70829 16.6168C4.79995 17.8835 4.91662 19.4668 7.82495 19.4668H13.175C16.0833 19.4668 16.2 17.8918 16.2916 16.6168L16.8083 8.07516C16.825 7.77516 16.7166 7.49183 16.525 7.2835ZM11.8833 15.2918H9.10829C8.76662 15.2918 8.48329 15.0085 8.48329 14.6668C8.48329 14.3252 8.76662 14.0418 9.10829 14.0418H11.8833C12.225 14.0418 12.5083 14.3252 12.5083 14.6668C12.5083 15.0085 12.225 15.2918 11.8833 15.2918ZM12.5833 11.9585H8.41662C8.07495 11.9585 7.79162 11.6752 7.79162 11.3335C7.79162 10.9918 8.07495 10.7085 8.41662 10.7085H12.5833C12.925 10.7085 13.2083 10.9918 13.2083 11.3335C13.2083 11.6752 12.925 11.9585 12.5833 11.9585Z"
                                fill="#C0C0D6"
                              />
                            </svg>
                          </div>
                        </div>
                      );
                    }
                  })}
              </>
            )}
            {/* {selectedImages.length > 0 &&
              selectedImages.map((image, index) => (
                <div key={index} className="">
                  <div style={{ position: "relative" }}>
                    <img
                      src={image}
                      alt={`Image ${index + 1}`}
                      style={{ width: "120px", height: "100px" }}
                      draggable={true}
                      onDragStart={(e) => handleDragStart(e, index)}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      onClick={() => handleDeleteImage(index)}
                      style={{
                        position: "absolute",
                        top: 0,
                        //right: '5px',
                        // marginTop: '-10px', // Negative margin to position the delete icon inside the image container
                        marginRight: "-10px",
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        zIndex: 1,
                      }}
                    >
                      {" "}
                      &#10006;
                      <path
                        d="M18.0584 4.85817C16.7167 4.72484 15.375 4.62484 14.025 4.54984V4.5415L13.8417 3.45817C13.7167 2.6915 13.5334 1.5415 11.5834 1.5415H9.40005C7.45838 1.5415 7.27505 2.6415 7.14172 3.44984L6.96672 4.5165C6.19172 4.5665 5.41672 4.6165 4.64172 4.6915L2.94172 4.85817C2.59172 4.8915 2.34172 5.19984 2.37505 5.5415C2.40838 5.88317 2.70838 6.13317 3.05838 6.09984L4.75838 5.93317C9.12505 5.49984 13.5251 5.6665 17.9417 6.10817C17.9667 6.10817 17.9834 6.10817 18.0084 6.10817C18.3251 6.10817 18.6 5.8665 18.6334 5.5415C18.6584 5.19984 18.4084 4.8915 18.0584 4.85817Z"
                        fill="#C0C0D6"
                      />
                      <path
                        d="M16.525 7.2835C16.325 7.07516 16.05 6.9585 15.7666 6.9585H5.23329C4.94995 6.9585 4.66662 7.07516 4.47495 7.2835C4.28329 7.49183 4.17495 7.77516 4.19162 8.06683L4.70829 16.6168C4.79995 17.8835 4.91662 19.4668 7.82495 19.4668H13.175C16.0833 19.4668 16.2 17.8918 16.2916 16.6168L16.8083 8.07516C16.825 7.77516 16.7166 7.49183 16.525 7.2835ZM11.8833 15.2918H9.10829C8.76662 15.2918 8.48329 15.0085 8.48329 14.6668C8.48329 14.3252 8.76662 14.0418 9.10829 14.0418H11.8833C12.225 14.0418 12.5083 14.3252 12.5083 14.6668C12.5083 15.0085 12.225 15.2918 11.8833 15.2918ZM12.5833 11.9585H8.41662C8.07495 11.9585 7.79162 11.6752 7.79162 11.3335C7.79162 10.9918 8.07495 10.7085 8.41662 10.7085H12.5833C12.925 10.7085 13.2083 10.9918 13.2083 11.3335C13.2083 11.6752 12.925 11.9585 12.5833 11.9585Z"
                        fill="#C0C0D6"
                      />
                    </svg>
                  </div>
                </div>
              ))} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assets;
