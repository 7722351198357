import React from 'react'

const TiktokIcon = () => {
  return (
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_155_41107)">
                <path d="M0 10C0 4.47715 4.47715 0 10 0H38C43.5228 0 48 4.47715 48 10V38C48 43.5228 43.5228 48 38 48H10C4.47715 48 0 43.5228 0 38V10Z" fill="white"/>
                <path d="M11.7598 29.898L11.8558 30.174C11.8438 30.144 11.8078 30.048 11.7598 29.898ZM15.6538 23.604C15.8278 22.11 16.4158 21.276 17.5198 20.418C19.1038 19.26 21.0778 19.914 21.0778 19.914V16.02C21.5578 16.008 22.0378 16.038 22.5118 16.11V21.12C22.5118 21.12 20.5378 20.466 18.9538 21.624C17.8498 22.482 17.2618 23.316 17.0878 24.81C17.0818 25.62 17.2258 26.682 17.8978 27.594C17.7298 27.504 17.5618 27.402 17.3878 27.288C15.9178 26.256 15.6478 24.702 15.6538 23.604ZM30.6778 8.82004C29.5918 7.57804 29.1778 6.31804 29.0278 5.43604H30.3958C30.3958 5.43604 30.1258 7.75204 32.1118 10.026L32.1418 10.056C31.6018 9.70804 31.1098 9.28804 30.6778 8.82004ZM37.2718 12.348V17.256C37.2718 17.256 35.5258 17.184 34.2298 16.842C32.4238 16.362 31.2658 15.624 31.2658 15.624C31.2658 15.624 30.4618 15.096 30.4018 15.06V25.2C30.4018 25.764 30.2518 27.174 29.8018 28.35C29.2138 29.886 28.3018 30.9 28.1338 31.104C28.1338 31.104 27.0238 32.472 25.0738 33.39C23.3158 34.218 21.7678 34.2 21.3058 34.218C21.3058 34.218 18.6358 34.326 16.2298 32.7C15.7078 32.34 15.2218 31.932 14.7778 31.482L14.7898 31.494C17.1958 33.126 19.8658 33.012 19.8658 33.012C20.3278 32.994 21.8758 33.012 23.6338 32.184C25.5838 31.266 26.6938 29.898 26.6938 29.898C26.8618 29.694 27.7738 28.68 28.3618 27.144C28.8118 25.968 28.9618 24.558 28.9618 23.994V13.86C29.0278 13.896 29.8258 14.424 29.8258 14.424C29.8258 14.424 30.9838 15.162 32.7898 15.642C34.0858 15.984 35.8318 16.056 35.8318 16.056V12.21C36.4318 12.348 36.9418 12.384 37.2718 12.348Z" fill="#EE1D52"/>
                <path d="M35.832 12.204V16.05C35.832 16.05 34.086 15.978 32.79 15.636C30.984 15.156 29.826 14.418 29.826 14.418C29.826 14.418 29.022 13.89 28.962 13.854V23.994C28.962 24.558 28.812 25.968 28.362 27.144C27.774 28.68 26.862 29.694 26.694 29.898C26.694 29.898 25.584 31.266 23.634 32.184C21.876 33.012 20.328 32.994 19.866 33.012C19.866 33.012 17.196 33.12 14.79 31.494L14.778 31.482C14.526 31.224 14.286 30.954 14.064 30.672C13.296 29.694 12.828 28.542 12.708 28.212V28.206C12.516 27.636 12.12 26.256 12.174 24.93C12.27 22.584 13.062 21.138 13.272 20.778C13.824 19.794 14.55 18.912 15.402 18.174C16.158 17.538 17.016 17.028 17.934 16.674C18.93 16.254 19.998 16.032 21.078 16.014V19.908C21.078 19.908 19.104 19.254 17.52 20.412C16.416 21.27 15.828 22.104 15.654 23.598C15.648 24.696 15.918 26.25 17.394 27.288C17.568 27.402 17.736 27.504 17.904 27.594C18.162 27.942 18.474 28.248 18.834 28.5C20.28 29.454 21.486 29.52 23.034 28.902C24.066 28.488 24.84 27.558 25.2 26.52C25.428 25.872 25.422 25.224 25.422 24.552V5.43604H29.022C29.172 6.31804 29.58 7.57804 30.672 8.82004C31.11 9.28804 31.602 9.70804 32.136 10.056C32.292 10.23 33.102 11.07 34.146 11.592C34.68 11.862 35.25 12.066 35.832 12.204Z" fill="black"/>
                <path d="M11.2744 27.0239L11.3644 27.2819C11.3524 27.2519 11.3164 27.1619 11.2744 27.0239Z" fill="#69C9D0"/>
                <path d="M17.9344 16.6799C17.0104 17.0339 16.1584 17.5439 15.4024 18.1799C14.5444 18.9179 13.8244 19.8059 13.2724 20.7899C13.0624 21.1499 12.2704 22.5899 12.1744 24.9419C12.1204 26.2739 12.5224 27.6479 12.7084 28.2179V28.2239C12.8284 28.5479 13.2964 29.7059 14.0644 30.6839C14.2864 30.9659 14.5264 31.2359 14.7784 31.4939C13.9624 30.9299 13.2424 30.2519 12.6244 29.4779C11.8624 28.5119 11.3944 27.3659 11.2744 27.0299V27.0179C11.0824 26.4479 10.6804 25.0679 10.7404 23.7359C10.8364 21.3899 11.6284 19.9439 11.8384 19.5839C12.3904 18.5999 13.1104 17.7119 13.9684 16.9739C14.7244 16.3379 15.5824 15.8279 16.5004 15.4739C17.0764 15.2339 17.6764 15.0599 18.2884 14.9459C19.2124 14.7839 20.1544 14.7719 21.0844 14.9039V16.0199C19.9984 16.0379 18.9304 16.2599 17.9344 16.6799Z" fill="#69C9D0"/>
                <path d="M29.0282 5.43598H25.4282V24.552C25.4282 25.224 25.4282 25.872 25.2062 26.52C24.8402 27.552 24.0662 28.488 23.0402 28.902C21.4922 29.526 20.2802 29.454 18.8402 28.5C18.4802 28.254 18.1682 27.948 17.9102 27.6C19.1402 28.254 20.2382 28.242 21.6002 27.696C22.6322 27.282 23.4062 26.346 23.7662 25.314C23.9942 24.666 23.9882 24.018 23.9882 23.346V4.22998H28.9622C28.9562 4.22998 28.9022 4.70398 29.0282 5.43598ZM35.8322 11.142V12.204C35.2502 12.066 34.6802 11.862 34.1462 11.592C33.1082 11.076 32.2982 10.23 32.1362 10.056C32.3222 10.176 32.5082 10.284 32.7062 10.386C33.9782 11.016 35.2202 11.208 35.8322 11.142Z" fill="#69C9D0"/>
                <path d="M29.1481 41.688C29.1481 41.826 29.1661 41.958 29.2081 42.09C29.2081 42.102 29.2141 42.108 29.2141 42.12C29.4121 42.756 30.0001 43.188 30.6661 43.188V44.724C29.9161 44.724 29.3821 44.748 28.5661 44.268C27.6421 43.716 27.1201 42.714 27.1201 41.67C27.1201 40.596 27.7021 39.516 28.6981 38.994C29.4181 38.616 29.9641 38.616 30.6721 38.616V40.152C29.8321 40.152 29.1541 40.83 29.1541 41.67L29.1481 41.688Z" fill="#69C9D0"/>
                <path d="M32.1959 41.688C32.1959 41.826 32.1779 41.958 32.1359 42.09C32.1359 42.102 32.1299 42.108 32.1299 42.12C31.9319 42.756 31.3439 43.188 30.6779 43.188V44.724C31.4219 44.724 31.9619 44.748 32.7719 44.268C33.7019 43.716 34.2239 42.714 34.2239 41.67C34.2239 40.596 33.6419 39.516 32.6459 38.994C31.9259 38.616 31.3799 38.616 30.6719 38.616V40.152C31.5119 40.152 32.1899 40.83 32.1899 41.67V41.688H32.1959Z" fill="#EE1D52"/>
                <path d="M7.16992 37.2241H12.7919L12.2699 38.7721H10.7999V44.6941H8.99392V38.7721H7.16992V37.2241ZM22.0739 37.2241V38.7721H23.8979V44.6941H25.7099V38.7721H27.1799L27.7019 37.2241H22.0739ZM14.0159 38.9821C14.5019 38.9821 14.8919 38.5921 14.8919 38.1061C14.8919 37.6201 14.5019 37.2301 14.0159 37.2301C13.5299 37.2301 13.1399 37.6201 13.1399 38.1061C13.1399 38.5921 13.5299 38.9821 14.0159 38.9821ZM13.1399 44.6881H14.9159V39.6001H13.1399V44.6881ZM21.2999 38.9401H19.2239L17.4359 40.7281V37.2241H15.6719L15.6659 44.6821H17.4479V42.7381L17.9999 42.2341L19.7279 44.6821H21.6299L19.1279 41.1061L21.2999 38.9401ZM38.3279 41.1121L40.4999 38.9401H38.4239L36.6359 40.7281V37.2241H34.8719L34.8599 44.6881H36.6419V42.7441L37.1999 42.2401L38.9279 44.6881H40.8299L38.3279 41.1121ZM33.7619 41.6641C33.7619 43.3501 32.3759 44.7181 30.6659 44.7181C28.9559 44.7181 27.5699 43.3501 27.5699 41.6641C27.5699 39.9781 28.9559 38.6101 30.6659 38.6101C32.3759 38.6101 33.7619 39.9781 33.7619 41.6641ZM32.1839 41.6641C32.1839 40.8241 31.5059 40.1461 30.6659 40.1461C29.8259 40.1461 29.1479 40.8241 29.1479 41.6641C29.1479 42.5041 29.8259 43.1821 30.6659 43.1821C31.5059 43.1821 32.1839 42.5041 32.1839 41.6641Z" fill="black"/>
                </g>
                <defs>
                <clipPath id="clip0_155_41107">
                <rect width="48" height="48" fill="white"/>
                </clipPath>
                </defs>
                </svg>


  )
}

export default TiktokIcon