import React, { useState, useEffect, Fragment } from "react";
import "./story.Style.css";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import {
  CardContent,
  Box,
  Select as MaterialUiSelect,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
//import Select from "@material-ui/core/Select";
import Select from "react-select";
import ColorPicker from "material-ui-color-picker";
import Yes from "../../assets/img/storyimg/YES.png";
import No from "../../assets/img/storyimg/NO.png";
import OnSale from "../../assets/img/storyimg/onSale.png";
import Up from "../../assets/img/storyimg/Up.png";
import Down from "../../assets/img/storyimg/Down.png";
import orderNow from "../../assets/img/storyimg/orderNow.png";
import { useHistory } from "react-router";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { IconButton, Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DatePicker from "react-datepicker";
import { CustomeDatePickerENGT } from "../common/global";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";
import createNotification from "../common/reactNotification";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import {
  EVNT_PROD_HOST_URI,
  ENGT_PROD_BASE_URI,
  MASTER_CATEGORIES,
  SOMETHING_WENT_WRONG,
  GET_PRODUCT_DETAILS,
} from "../../api/apiConstants";
import {
  getAuthAndData,
  postAuthAndData,
  postAuthAndDataTemp,
} from "../../api/ApiHelper";
import { useDispatch } from "react-redux";

const useStyles = makeStyles({
  caption1: {
    fontFamily: "Beautiful People",
    color: "red",
  },
  caption2: {
    fontFamily: "Beautiful People",
    color: "green",
  },
  cardStyle: {
    maxWidth: 145,
    minWidth: 145,
    display: "flex",
    height: "75px",
    // marginRight: "12px",
    cursor: "pointer",
    // zIndex: "2",
    opacity: "0",
  },
  boxStyle: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "10px",
    marginTop: "-5px",
    marginBottom: "20px",
  },
});

function StudioComponenets_Description(props) {
  const { caption1, caption2, cardStyle, boxStyle } = useStyles();
  const [fonttype, setFontType] = useState("");
  const [borderColor1, setBorderColor1] = useState(false);
  const [borderColor2, setBorderColor2] = useState(false);
  const [borderColor3, setBorderColor3] = useState(false);
  const [borderColor4, setBorderColor4] = useState(false);
  const [bookingDate, setBookingDate] = useState(new Date());
  const [hour, setHour] = useState([]);
  const [minets, setMinets] = useState([]);
  const [buttonType, setButtonType] = useState(0);
  const [target, setTarget] = useState("");
  const [catogery, setcatogery] = useState([]);
  const history = useHistory();
  const [inputMsg, setInputMsg] = useState("");
  const Dispatch = useDispatch();

  const [preview, setpreview] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [selectedHour, setSelectedHour] = useState({
    value: 0,
    label: 0,
  });
  const [selectedMin, setSelectedMin] = useState({
    value: 0,
    label: 0,
  });
  // const [discription, setDiscription] = useState(
  //   {tag : "",
  //   discription : "",
  //   targetCatogery:"",
  //   caption:"" ,
  //   fontFamily:"",
  //   ColorPicker:"",
  //   buttonType: 0
  // })

  const handleResponseCode = (resp) => {
    if (!resp || resp.code === -1) {
      createNotification("error", SOMETHING_WENT_WRONG + "in Target Audience");
      return false;
    } else {
      return true;
    }
  };

  const timeInHour = () => {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      let obj = {
        value: i,
        label: i,
      };
      hours.push(obj);
    }
    setHour(hours);
  };

  const timeInMin = () => {
    const min = [];
    for (let i = 0; i < 60; i++) {
      let obj = {
        value: i,
        label: i,
      };
      min.push(obj);
    }
    setMinets(min);
  };

  const getMasterCategories = () => {
    getAuthAndData(`${ENGT_PROD_BASE_URI}${MASTER_CATEGORIES}`, history).then(
      (res) => {
        if (handleResponseCode(res)) {
          // //console.log("res====", res)
          //   var cats=res.data?.map(c=>{
          //       return {
          //           name:c.category_name,
          //           value:c.category_master_id
          //       }
          //   });
          setcatogery(res.data);
        }
      }
    );
  };

  useEffect(() => {
    getMasterCategories();
    timeInHour();
    timeInMin();
    customTimeString(bookingDate, selectedHour?.value, selectedMin?.value);
  }, []);

  const handleChange = (event) => {
    //console.log("fontFamily change", event.target.value);
    setFontType(event.target.value);
    props.setDiscription({
      ...props.discription,
      fontFamily: event.target.value,
    });
  };

  const handleTargetValue = (event) => {
    setTarget(event.target.value);
    props.setDiscription({
      ...props.discription,
      targetCatogery: event.target.value,
    });
  };

  const handleClick1 = () => {
    setBorderColor1(!borderColor1);
    props.setDiscription({ ...props.discription, buttonType: 1 });
    setBorderColor2(false);
    setBorderColor3(false);
    setBorderColor4(false);
  };
  const handleClick2 = () => {
    setBorderColor2(!borderColor2);
    props.setDiscription({ ...props.discription, buttonType: 2 });
    setBorderColor1(false);
    setBorderColor3(false);
    setBorderColor4(false);
  };
  const handleClick3 = () => {
    setBorderColor3(!borderColor3);
    props.setDiscription({ ...props.discription, buttonType: 3 });
    setBorderColor1(false);
    setBorderColor2(false);
    setBorderColor4(false);
  };
  const handleClick4 = () => {
    setBorderColor4(!borderColor4);
    props.setDiscription({ ...props.discription, buttonType: 4 });
    setBorderColor1(false);
    setBorderColor2(false);
    setBorderColor3(false);
  };

  const changeColor = (color) => {
    color && props.setDiscription({ ...props.discription, ColorPicker: color });
  };

  const changeTage = (event) => {
    //console.log("changeTage", event.target.value);
    props.setDiscription({ ...props.discription, tag: event.target.value });
  };

  const deleteProduct = (product) => {
    //console.log("product=======", product)
    const idsArray = props.productIds.filter((e) => e !== product?.productId);
    const filterdArr = props?.productDetail?.filter(
      (e) => e?.productId !== product?.productId
    );
    props?.setProductDetail(filterdArr);
    props.setDiscription({
      ...props.discription,
      productId:
        props.liveSchedule || props.discription.contentType == 2
          ? idsArray
          : idsArray,
    });
  };

  const changeDiscription = (event) => {
    //console.log("changeTage", event.target.value);
    props.setDiscription({
      ...props.discription,
      discription: event.target.value,
    });
  };
  const handleResponce = (event) => {
    // if (event.target.value == 0) {
    //   setInputText(true);
    // } else {
    //   setInputText(false);
    // }
    setInputMsg(event.target.value);
    //console.log("event===$$$=", event.target.value);
    //setInputText(event.target.value);
  };

  const changeCaption = (event) => {
    //console.log("changeTage", event.target.value);
    props.setDiscription({ ...props.discription, caption: event.target.value });
  };

  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
    //    props.routeActionHandler.dispatchLoaderData(showBool);
  };

  const fatchProductDetail = async (id) => {
    handleLoader(true);

    const body = {
      Sku: filterType == "SKU" ? inputMsg : "",
      PortalProductId: filterType === "ID" ? inputMsg : "",
      ProductName: "",
      ProductId: "",
    };
    try {
      postAuthAndData(
        `${EVNT_PROD_HOST_URI}${GET_PRODUCT_DETAILS}`,
        body,
        history
      ).then((res) => {
        if (handleResponseCode(res)) {
          const isAvilable = props?.productIds?.find(
            (e) => e == res?.data[0]?.productId
          );
          if (isAvilable) {
            createNotification(
              "warning",
              "This prodect is already in the list"
            );
          } else {
            if (res?.data[0]) {
              props.productIds.push(res?.data[0]?.productId);
              props?.setProductDetail([res.data[0], ...props?.productDetail]);
              props.setDiscription({
                ...props.discription,
                productId:
                  props.liveSchedule || props.discription.contentType == 2
                    ? props.productIds
                    : [res?.data[0]?.productId],
              });
              setpreview(true);
            }
          }
        }
      });
      // const data = await postAuthAndData(
      //   `${EVNT_PROD_HOST_URI}${GET_PRODUCT_DETAILS}`,
      //   body,
      //   history
      // );
      // props.setDiscription({
      //   ...props.discription,
      //   productId: data?.data[0]?.productId,
      // });
      // //console.log("data@@@@", data.data);
    } catch (err) {}
    handleLoader(false);
  };

  const onSearch = () => {
    fatchProductDetail();
  };

  const formDateTimeString = (data, type) => {
    //console.log("eventData===", data);
    if (type == 1) setBookingDate(data);
    if (type == 2) setSelectedHour(data);
    if (type == 3) setSelectedMin(data);
    const selectedDate = type == 1 ? data : bookingDate;
    const selectedHours = type == 2 ? data?.value : selectedHour?.value;
    const selectedMins = type == 3 ? data?.value : selectedMin?.value;
    customTimeString(selectedDate, selectedHours, selectedMins);
  };

  const customTimeString = (date, h, min) => {
    let slectedDate = new Date(date);
    let selectedDateTime = new Date(
      slectedDate.getFullYear(),
      slectedDate.getMonth(),
      slectedDate.getDate(),
      h,
      min
    ).getTime();
    //console.log("Converteddate =====&&&", selectedDateTime);
    props.setDiscription({
      ...props.discription,
      liveDateTime: selectedDateTime,
    });
  };

  const filterByType = (event) => {
    setFilterType(event.target.value);
    // if (event.target.value === "Story")
  };

  //console.log("productDetail=====&&&", props?.productDetail)
  return (
    <div>
      <NotificationContainer />
      {props.liveSchedule && (
        <FormControl>
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            style={{ fontSize: "1.1rem", marginTop: "30px" }}
          >
            Live Schedule
          </FormLabel>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <DatePicker
              minDate={new Date()}
              selected={bookingDate}
              customInput={<CustomeDatePickerENGT />}
              onChange={(date) => formDateTimeString(date, 1)}
            />
            <>
              <Select
                style={{ marginLeft: "30px" }}
                options={hour}
                value={selectedHour}
                onChange={(date) => formDateTimeString(date, 2)}
                className="w-70"
              />
              <spam>HH</spam>
              <Select
                options={minets}
                value={selectedMin}
                onChange={(date) => formDateTimeString(date, 3)}
                className="w-70 "
              />
              <spam>MM</spam>
            </>
          </div>
        </FormControl>
      )}

      <div>
        {props.liveSchedule && (
          <>
            <label
              style={{
                color: "rgba(0, 0, 0, 0.54)",
                marginTop: "30px",
                fontSize: "1.1rem",
              }}
            >
              Overview
            </label>
            <div className="disTag">
              <TextField
                id="standard-basic"
                label="Live Summary"
                variant="standard"
                multiline={true}
                onChange={changeDiscription}
                inputProps={{ maxLength: 300 }}
                required={true}
                maxRows={4}
                fullWidth={true}
                style={{ marginBottom: "20px" }}
              />
            </div>
          </>
        )}
        {/*
        {!props.liveSchedule && (
          <>
            <TextField
              id="standard-basic"
              label="Tags"
              variant="standard"
              multiline={true}
              onChange={changeTage}
              inputProps={{ maxLength: 300 }}
              maxRows={4}
              fullWidth={true}
              style={{ marginBottom: "20px" }}
            />
            {!props.storyFlage && <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Target Category
                </InputLabel>
                <MaterialUiSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={target}
                  label="target"
                  onChange={handleTargetValue}
                >
                  {catogery?.map((e) => (
                    <MenuItem value={e.category_url}>
                      {e.category_name}
                    </MenuItem>
                  ))}
                </MaterialUiSelect>
              </FormControl>
            </Box> }
          </>
        )}
      </div> */}
        {props.storyFlage ? (
          <Fragment>
            {/* <div>
            <label style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "1.1rem" }}>
              Styling
            </label>
            <div className="disTag">
              <TextField
                id="standard-basic"
                label="Caption"
                variant="standard"
                multiline={true}
                onChange={changeCaption}
                maxRows={4}
                fullWidth={true}
                style={{ marginBottom: "20px" }}
                inputProps={{
                  style: {
                    fontFamily:
                      props.discription.fontFamily == "BeautifulPeople"
                        ? "Beautiful People Personal Use"
                        : props.discription.fontFamily == "BeautyMountains"
                        ? "Beauty Mountains Personal Use"
                        : props.discription.fontFamily == "BunchBlossoms"
                        ? "Bunch Blossoms Personal Use"
                        : props.discription.fontFamily == "Countryside"
                        ? "Country Side Personal Use"
                        : props.discription.fontFamily == "GrestalScript"
                        ? "Grestal Script Personal Use"
                        : "Lemon Jelly Personal Use",
                    color: props.discription.ColorPicker,
                    fontSize: 30,
                  },
                  maxLength: 50,
                }}
              />

              <div className="fontColor">
                <ColorPicker
                  name="color"
                  defaultValue="ColorPicker"
                  onChange={(color) => changeColor(color)}
                  style={{ marginRight: "25px" }}
                />
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Font family
                    </InputLabel>
                    <MaterialUiSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={fonttype}
                      label="fonttype"
                      onChange={handleChange}
                    >
                      <MenuItem value="BeautifulPeople">
                        Beautiful People
                      </MenuItem>
                      <MenuItem value="BeautyMountains">
                        Beauty Mountains
                      </MenuItem>
                      <MenuItem value="BunchBlossoms">Bunch Blossoms</MenuItem>
                      <MenuItem value="Countryside">Country side</MenuItem>
                      <MenuItem value="GrestalScript">Grestal Script</MenuItem>
                      {/* <MenuItem  value = "LemonJelly">Lemon Jelly</MenuItem> 
                    </MaterialUiSelect>
                  </FormControl>
                </Box>
              </div>
            </div>
          </div> */}

            {/* <div>
            <label style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "1.1rem" }}>
              Stickers
            </label>
            <div className="storyImgcont">
              <div
                className="storyimg"
                onClick={(e) => handleClick1(e)}
                style={{
                  border: borderColor1
                    ? "2px solid #007bff"
                    : "1px solid rgba(139, 136, 136, 0.575)",
                }}
              >
                <img
                  alt="1"
                  src={Yes}
                  style={{
                    height: "90px",
                    width: "90px",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                />
                <img
                  alt="1"
                  src={No}
                  style={{
                    height: "90px",
                    width: "90px",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                />
              </div>
              <div
                className="storyimg"
                onClick={(e) => handleClick2(e)}
                style={{
                  border: borderColor2
                    ? "2px solid #007bff"
                    : "1px solid rgba(139, 136, 136, 0.575)",
                }}
              >
                <img
                  alt="2"
                  src={Up}
                  style={{
                    height: "90px",
                    width: "90px",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                />
                <img
                  alt="2"
                  src={Down}
                  style={{
                    height: "90px",
                    width: "90px",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                />
              </div>
              <div
                className="storyimg"
                onClick={(e) => handleClick3(e)}
                style={{
                  border: borderColor3
                    ? "2px solid #007bff"
                    : "1px solid rgba(139, 136, 136, 0.575)",
                }}
              >
                <img
                  alt="3"
                  src={OnSale}
                  style={{
                    height: "90px",
                    width: "90px",
                    marginRight: "10px",
                    marginLeft: "60px",
                  }}
                />
              </div>
              <div
                className="storyimg"
                onClick={(e) => handleClick4(e)}
                style={{
                  border: borderColor4
                    ? "2px solid #007bff"
                    : "1px solid rgba(139, 136, 136, 0.575)",
                }}
              >
                <img
                  alt="4"
                  src={orderNow}
                  style={{
                    height: "90px",
                    width: "90px",
                    marginRight: "10px",
                    marginLeft: "60px",
                  }}
                />
              </div>
            </div>
          </div> */}
            <Fragment>
              <FormControl>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  style={{ fontSize: "1.1rem" }}
                >
                  Attach Shoppable Product *
                </FormLabel>
                <RadioGroup
                  style={{ backgroundColor: "white" }}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => filterByType(e)}
                >
                  <FormControlLabel
                    value="SKU"
                    control={<Radio />}
                    label="By SKU"
                  />
                  <FormControlLabel
                    value="ID"
                    control={<Radio />}
                    label="By ID"
                  />
                  <FormControlLabel
                    value="URL"
                    control={<Radio />}
                    label="By URL"
                  />
                </RadioGroup>
              </FormControl>
              <div className="searchProduct">
                <OutlinedInput
                  colorSecondary
                  value={inputMsg}
                  placeholder="Enter product details"
                  style={{
                    borderRadius: "30px 30px 30px 30px",
                    minWidth: "85%",
                    height: "2.1876em",
                    //borderColor: "white",
                  }}
                  onChange={(event) => handleResponce(event)}
                  // inputProps={{ style: { color: "white" } }}
                />
                <IconButton onClick={onSearch}>
                  <Button
                    style={{
                      backgroundColor: "var(--buttonBg)",
                      color: "white",
                      margin: "20px 0px",
                      padding: "5px 20px",
                    }}
                  >
                    Add
                  </Button>
                </IconButton>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "35px",
                }}
              >
                <label
                  style={{
                    color: "rgba(0, 0, 0, 0.54)",
                    fontSize: "1.1rem",
                    marginLeft: "10px",
                    marginRight: "10Px",
                  }}
                >
                  Image
                </label>
                <label
                  style={{
                    color: "rgba(0, 0, 0, 0.54)",
                    fontSize: "1.1rem",
                    marginLeft: "10px",
                  }}
                >
                  Product Name
                </label>
              </div>
              <div className="preViewDiv">
                {!preview ? (
                  <label
                    style={{
                      color: "rgba(0, 0, 0, 0.54)",
                      fontSize: "0.9rem",
                      marginLeft: "20px",
                    }}
                  >
                    See the preview here
                  </label>
                ) : (
                  <div className="cardStyle">
                    <img
                      alt="4"
                      src={props?.productDetail[0]?.baseImageURL}
                      style={{
                        height: "50px",
                        width: "50px",
                      }}
                    />
                    <label
                      style={{
                        color: "rgba(0, 0, 0, 0.54)",
                        fontSize: "1.1rem",
                        marginLeft: "10px",
                      }}
                    >
                      {props?.productDetail[0]?.productdisplayName}
                    </label>
                  </div>
                )}
              </div>
            </Fragment>
          </Fragment>
        ) : (
          <>
            <Fragment>
              <FormControl>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  style={{ fontSize: "1.1rem" }}
                >
                  Attach Shoppable Product *
                </FormLabel>
                <RadioGroup
                  style={{ backgroundColor: "white" }}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => filterByType(e)}
                >
                  <FormControlLabel
                    value="SKU"
                    control={<Radio />}
                    label="By SKU"
                  />
                  <FormControlLabel
                    value="ID"
                    control={<Radio />}
                    label="By ID"
                  />
                  <FormControlLabel
                    value="URL"
                    control={<Radio />}
                    label="By URL"
                  />
                </RadioGroup>
              </FormControl>
              <div className="searchProduct">
                <OutlinedInput
                  colorSecondary
                  value={inputMsg}
                  placeholder="Enter product details"
                  style={{
                    borderRadius: "30px 30px 30px 30px",
                    minWidth: "85%",
                    height: "2.1876em",
                    //borderColor: "white",
                  }}
                  onChange={(event) => handleResponce(event)}
                  // inputProps={{ style: { color: "white" } }}
                />
                <IconButton onClick={onSearch}>
                  <Button
                    style={{
                      backgroundColor: "var(--buttonBg)",
                      color: "white",
                      margin: "20px 0px",
                      padding: "5px 20px",
                    }}
                  >
                    Add
                  </Button>
                  {/* <SearchIcon
                style={{ color: "rgb(247 16 11)", fontSize: "30px" }}
              /> */}
                </IconButton>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "76px",
                }}
              >
                <label
                  style={{
                    color: "rgba(0, 0, 0, 0.54)",
                    fontSize: "1.1rem",
                    marginLeft: "10px",
                    marginRight: "10Px",
                  }}
                >
                  Image
                </label>
                <label
                  style={{
                    color: "rgba(0, 0, 0, 0.54)",
                    fontSize: "1.1rem",
                    marginLeft: "10px",
                  }}
                >
                  Product Name
                </label>
              </div>
              <div className="preViewDiv">
                {!preview ? (
                  <label
                    style={{
                      color: "rgba(0, 0, 0, 0.54)",
                      fontSize: "0.9rem",
                      marginLeft: "20px",
                    }}
                  >
                    See the preview here
                  </label>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {props?.productDetail?.map((product) => (
                      <div
                        className="cardStyle"
                        style={{ paddingBottom: "5px" }}
                      >
                        <CloseIcon
                          style={{
                            color: "Red",
                            fontSize: "25px",
                            zIndex: "999",
                            marginRight: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => deleteProduct(product)}
                        />
                        <img
                          alt="4"
                          src={product?.baseImageURL}
                          style={{
                            height: "50px",
                            width: "50px",
                            marginRight: "10Px",
                          }}
                        />
                        <label
                          style={{
                            color: "black",
                            fontSize: "1.1rem",
                            marginLeft: "10px",
                          }}
                        >
                          {product?.productdisplayName}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Fragment>
          </>
        )}
      </div>
    </div>
  );
}

export default StudioComponenets_Description;
