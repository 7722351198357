import React, { useState, useEffect } from "react";
import CardComponent from "./CardComponent";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { BRAND_PARTNER, SAVE_BRAND_PARTNER } from "../../api/apiConstants";
import { getAuthAndData, postAuthAndData } from "../../api/ApiHelper";
import "./RewardPartner.style.css";
import { useSelector, useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";

const useStyles = makeStyles({
  saveButton: {
    padding: "5px 20px 5px 20px",
    margin: "20px",
    marginTop: "80px",
    position: "absolute",
    right: 0,
    top: 0,
    backgroundColor: "#6f42c1",
    color: "white",
  },

  header: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "15px",
    marginTop: "55px",
  },
});

function RewardPartnerComponent() {
  const { saveButton, header } = useStyles();
  const History = useHistory();
  const Dispatch = useDispatch();
  const [allpartner, setAllPartner] = useState();
  const [selectedPartner, setSelectedPartner] = useState([]);

  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
    //    props.routeActionHandler.dispatchLoaderData(showBool);
  };

  const allRewardPartner = async () => {
    handleLoader(true);
    try {
      const data = await getAuthAndData(BRAND_PARTNER, History);
      setAllPartner(data?.data);
      console.log("BRAND_PARTNER@#@#@", data);
    } catch (error) {
      console.log(error?.message);
    }
    handleLoader(false);
  };

  useEffect(() => {
    allRewardPartner();
    //selectedRewardPartner()
  }, []);

  const selectedRewardPartner = async () => {
    handleLoader(true);
    const body = {
      BrandsId: selectedPartner,
    };

    try {
      const data = await postAuthAndData(SAVE_BRAND_PARTNER, body, History);

      console.log("SAVE_BRAND_PARTNER@#@#@", data);
    } catch (error) {
      console.log(error?.message);
    }
    handleLoader(false);
  };

  const handleClick = (Id) => {
    //console.log('event.target.id', Id)
    if (selectedPartner?.find((p) => p === Id)) {
      setSelectedPartner(selectedPartner?.filter((p) => p !== Id));
    } else {
      setSelectedPartner([...selectedPartner, Id]);
    }
  };

  const SaveRewardPartner = () => {
    selectedRewardPartner();
  };

  console.log("selectedPartner=====", selectedPartner);
  return (
    <div>
      <div
        className="btn-create-journey float-right text-center pt-2"
        onClick={SaveRewardPartner}
      >
        <span className="btn-c-j-text">Save Brand Partner</span>
        {/* <Button className="btn-c-j-text"  onClick={SaveRewardPartner}  color="primary" variant="contained" size="small">
       Save
    </Button> */}
      </div>
      <div className="manage-journey-block">
        <div className="manage-journey">Manage Brands</div>
      </div>
      <div style={{ marginTop: "30px" }}>
        <div className="storyColection">
          <div className="story">
            {allpartner?.map((e) => (
              <CardComponent handleClick={handleClick} data={e} key={e.Id} />
            ))}
          </div>
        </div>
      </div>
    </div>
    // <div>RewardPartnerComponent</div>
  );
}

export default RewardPartnerComponent;
