import React, { useState } from "react";
import { Button, Box } from "@material-ui/core";
import "./playlistManager.css";
import PlaylistCardComponent from "./PlaylistCardComponent";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import AlertDialogBox from "../common/alertBox/AlertDialogBox";
import Attribute from "./Attribute";
import { ThreeDRotation } from "@material-ui/icons";
import createNotification from '../common/reactNotification';
import { NotificationContainer } from 'react-notifications';


const useStyles = makeStyles({
  scroll: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: window.innerWidth > 400 ? "center" : "start",
    overflowX: "scroll",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    "&::-webkit-scrollbar": {
      height: "5px",
      // border:"1px solid green",
    },
  },
});

function PlaylistListComponent({ playlistCollection, onEditFlow, contentType, hashArray }) {
  const { scroll } = useStyles();
  const dispatch = useDispatch();
  const userData = useSelector((select) =>
   select.userReducer.userData);
  //console.log("userData==1=", userData);

  //console.log('parent array', hashArray)


  const [generatedCodeObj, setgeneratedCodeObj] = useState({
    open: false,
    title: "",
    text1: "",
    text2: "",
    handleClose: () => { }
  })

  // const [single, setSingle] = useState(false)



  const copyGeneratedCode = (Playlist) => {
    // console.log("======Playlist", Playlist)
    // //console.log("length---------------------->",Playlist?.contentList?.length())
    // if(Playlist?.contentList)
    var size = Object.keys(Playlist?.contentList).length;
    //console.log(size, "length-----------------");
    if (size === 1) {
      var single = true
    } else {
      var single = false
    }
    if (Playlist?.isTagged === true) {
      // hashArray.filter((item) => item === 0)
     var filterHash = hashArray.filter(function(value, index, arr){ 
        return value > 1 || value <-1;
    });
      console.log(filterHash,"filter array")
      const videoDivCode = `<blsh-vplayer ${ec?ec:''} ${emp?emp:''} ${dpc?dpc:''} playlist="${Playlist?.PlayListCode}" cliendid="${userData?.TenantKey}" IsTagged="${Playlist?.isTagged}" IsSingle="${single}" hashCode="${filterHash}" id ="${userData?.TenantKey}_${Math.floor(Math.random() * 10000)}"></blsh-vplayer>`
      const storyDivCode = `<blsh-story playlist="${Playlist?.PlayListCode}" cliendid="${userData?.TenantKey}" IsTagged="${Playlist?.isTagged}" IsSingle="${single}" hashCode="${filterHash}" id ="${userData?.TenantKey}_${Math.floor(Math.random() * 10000)}"></blsh-story>`

      setgeneratedCodeObj({
        open: true,
        title: "Code copied",
        text1: "Code copied to clipboard, please paste it inside website to show the playlist videos/stories.",
        text2: contentType == 2 ? videoDivCode : storyDivCode,
        handleClose: (bool) => {
          setgeneratedCodeObj({
            ...generatedCodeObj, open: false, handleClose: () => { }
            // ,
            // title: "code copied ",
            // text1: "Code copied to clipboard, please paste it inside website to show the playlist videos/stories.",
            // text2: "The Engagement will go Live, Are you sufgdfgre?",
            // handleClose: () => {}
          })
        }

      })
      navigator.clipboard.writeText(contentType == 2 ? videoDivCode : storyDivCode)

    } else {
      const videoDivCode = `<blsh-vplayer ${ec?ec:''} ${emp?emp:''} ${dpc?dpc:''}  playlist="${Playlist?.PlayListCode}" cliendid="${userData?.TenantKey}" IsTagged="${Playlist?.isTagged}" IsSingle="${single}"  id ="${userData?.TenantKey}_${Math.floor(Math.random() * 10000)}"></blsh-vplayer>`
      const storyDivCode = `<blsh-story playlist="${Playlist?.PlayListCode}" cliendid="${userData?.TenantKey}" IsTagged="${Playlist?.isTagged}" IsSingle="${single}" id ="${userData?.TenantKey}_${Math.floor(Math.random() * 10000)}"></blsh-story>`

      setgeneratedCodeObj({
        open: true,
        title: "Code copied",
        text1: "Code copied to clipboard, please paste it inside website to show the playlist videos/stories.",
        text2: contentType == 2 ? videoDivCode : storyDivCode,
        handleClose: (bool) => {
          setgeneratedCodeObj({
            ...generatedCodeObj, open: false, handleClose: () => { }
            // ,
            // title: "code copied ",
            // text1: "Code copied to clipboard, please paste it inside website to show the playlist videos/stories.",
            // text2: "The Engagement will go Live, Are you sufgdfgre?",
            // handleClose: () => {}
          })
        }

      })
      navigator.clipboard.writeText(contentType == 2 ? videoDivCode : storyDivCode)

    }
    createNotification('success',' Code Generated Successfully');



    // setgeneratedCodeObj({
    //   open: true,
    //   title: "Code copied",
    //   text1: "Code copied to clipboard, please paste it inside website to show the playlist videos/stories.",
    //   text2: contentType == 2 ? videoDivCode : storyDivCode,
    //   handleClose: (bool) => {
    //     setgeneratedCodeObj({
    //       ...generatedCodeObj, open: false, handleClose: () => { }
    //       // ,
    //       // title: "code copied ",
    //       // text1: "Code copied to clipboard, please paste it inside website to show the playlist videos/stories.",
    //       // text2: "The Engagement will go Live, Are you sufgdfgre?",
    //       // handleClose: () => {}
    //     })
    //   }

    // })
    // navigator.clipboard.writeText(contentType == 2 ? videoDivCode : storyDivCode)


  }

  var ec = null;
  var emp = null;
  var dpc = null;
  var submitted =0;

  const handleChildData = (enableCircle, enableMiniPlayer, disableProductCard) => {
    
    ec = enableCircle ? 'isCircle="true"' : null;
    emp = enableMiniPlayer ? 'enablePIP="false"' : null;
    dpc = disableProductCard ? 'isRemProduct="true"' : null;

    // console.log("enableCircle", enableCircle);
    // console.log("enableMiniPlayer", enableMiniPlayer);
    // console.log("disableProductCard", disableProductCard);
    // console.log('-----------------------------');
    // console.log("ec", ec);
    // console.log("emp", emp);
    // console.log("dpc", dpc);
    // console.log('-----------------------------');



  };

  


  return (
    <div>
<NotificationContainer/>

      <AlertDialogBox generatedCodeObj={generatedCodeObj} />
      {/* //! work on this map */}
      {playlistCollection?.map((Playlist) => (
        <div style={{
          background: 'rgba(255, 255, 255, 0.8)', 
          borderRadius: '10px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', 
          padding: '10px',
          margin: '10px',
        }}
        >
          <Box className="playlistTytle">
            <div className="overview-heading float-left clearfix mt-2">
              {Playlist?.name}
            </div>
            {/* //! Generate Code */}
            <div
              className="btn-create-journey3 float-right text-center"
              onClick={() => { copyGeneratedCode(Playlist)}}
              //! with playlist provide your attributes here inside copyGeneratedCode
            >
              <span className="btn-c-j-text2">Generate Code</span>
            </div>
            {/* //! Manage */}

            <div
              className="btn-create-journey2 float-right text-center"
              style={{ marginRight: "10px" }}
              onClick={() => {
                onEditFlow(Playlist);
              }}
            >
              {/* <span className="btn-c-j-text">Manage</span> */}
              <span className="btn-c-j-text">Edit</span>

            </div>


            

      
          </Box>


          <div className={scroll}>
            {Playlist?.contentList?.map((content) => (
              <PlaylistCardComponent feed={content} checkBox={false} contentType={contentType} />
            ))}


          </div>

<div style={{margin : "10px"}} >
       <Attribute sendDataToParent={handleChildData} />  
</div>
        </div>

      ))}

    </div>
  );
}

export default PlaylistListComponent;
