import React from 'react'
import ReactPlayer from 'react-player'
import './story.Style.css'

function PlayerComponent({Video}) {
  return (
    <ReactPlayer className = "video"  controls = {true} playing = {true} volume = {0}
      url ={Video} />
  )
}

export default PlayerComponent