import React from 'react'

const SecondPolygon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 0L33.5885 9V27L18 36L2.41154 27V9L18 0Z" fill="white"/>
<path d="M18 3L30.9904 10.5V25.5L18 33L5.00962 25.5V10.5L18 3Z" fill="#222229"/>
<path d="M15.3508 21.52C16.3748 20.6347 17.1801 19.9093 17.7668 19.344C18.3641 18.768 18.8601 18.1707 19.2548 17.552C19.6495 16.9333 19.8468 16.3147 19.8468 15.696C19.8468 15.056 19.6921 14.5547 19.3828 14.192C19.0841 13.8293 18.6095 13.648 17.9588 13.648C17.3295 13.648 16.8388 13.8507 16.4868 14.256C16.1455 14.6507 15.9641 15.184 15.9428 15.856H14.1828C14.2148 14.64 14.5775 13.712 15.2708 13.072C15.9748 12.4213 16.8655 12.096 17.9428 12.096C19.1055 12.096 20.0121 12.416 20.6628 13.056C21.3241 13.696 21.6548 14.5493 21.6548 15.616C21.6548 16.384 21.4575 17.1253 21.0628 17.84C20.6788 18.544 20.2148 19.1787 19.6708 19.744C19.1375 20.2987 18.4548 20.944 17.6228 21.68L16.9028 22.32H21.9748V23.84H14.1988V22.512L15.3508 21.52Z" fill="white"/>
</svg>

  )
}

export default SecondPolygon