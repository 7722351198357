import React, { useEffect, useState } from "react";
import Slider from "react-slider-modal";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import "./Payment.css";
import CheckoutForm from "./CheckoutForm";

const Payment = ({quantity, subscriptionId, selectedplan, slidemenu, setSlidemenu, handleLoader, paymentSecret}) => {
  const [stripePromise, setStripePromise] = useState(null);

  // payment intent from API
  const appearance = {
    theme: "night",
    labels: 'floating'
  };
  const options = {
    clientSecret: paymentSecret,
    appearance
  };

  const stripeLoader = async() => {
    handleLoader(true);
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
    handleLoader(false);
    setStripePromise(stripe);
  }

  useEffect(() => {
    stripeLoader();
  },[]);

  return(
    <Elements stripe={stripePromise} options={options}>
      <Slider
          id="demoID3"
          className=""
          direction="left"
          sliderStyle={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "#22222A",
          }}
          animation="slide"
          closeModal={(e) => {
            setSlidemenu(e);
          }}
          toggle={slidemenu}
          closeIcon={() => {
            setSlidemenu(false);
          }}
        >
          <div className="slider-maincontainer">
            <div className="payment-container">
              <div>
                <h3>Checkout</h3>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px"}}>
                  <div className="total-container">
                    <div className="total-details">
                      <p>{quantity} X Team</p>
                      <p>{selectedplan.amount} {selectedplan.currency}</p>
                    </div>
                    <div className="total-details">
                      <p>Total Amount</p>
                      <p>{quantity * selectedplan.amount} {selectedplan.currency}</p>
                    </div>
                  </div>
                  <div className="total-container">
                    <div className="total-details">
                      <h6>Plan Details</h6>
                      <p>{selectedplan.plan_name}</p>
                    </div>
                    <div className="total-details">
                      <p>Start Date</p>
                      <p>{new Date().toLocaleDateString()}</p>
                    </div>
                  </div>
                </div>
                <CheckoutForm />
              </div>
            </div>
          </div>
      </Slider>
    </Elements>
  );
};

export default Payment;
