import React, { useState, useEffect } from "react";
import CardComponent from "./CardComponent";
//import FilterComponent from './FilterComponent'
import "./story.Style.css";
import { INFLUENCERS_BY_FILTER } from "../../api/apiConstants";
import { getAuthAndData, postAuthAndData } from "../../api/ApiHelper";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";

function StoryComponent() {
  const [influencerData, setInfluencerData] = useState();
  console.log("influencerDtat####", influencerData);
  const [active, setActive] = useState("All");
  const History = useHistory();
  const Dispatch = useDispatch();

  useEffect(() => {
    console.log("data123----");

    fetchInfluencerByStatus(0);
  }, []);

  function tabClick(val) {
    setActive(val);
    let id =
      val === "All"
        ? 0
        : val === "Featured"
        ? 1
        : val === "Trending"
        ? 2
        : val === "Fashion"
        ? 3
        : val === "Lifestyle"
        ? 4
        : 0;
    fetchInfluencerByStatus(id);
  }
  //const value = useSelector((state)=> state?.Route)

  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
    //    props.routeActionHandler.dispatchLoaderData(showBool);
  };

  const fetchInfluencerByStatus = async (id) => {
    handleLoader(true);
    const body = {
      Interests: id === 0 ? [] : [id],
      PriceRange: {
        From: 100,
        To: 100000,
      },
    };
    try {
      // console.log('data not fetch now')
      const data = await postAuthAndData(INFLUENCERS_BY_FILTER, body, History);
      console.log("data@@@@", data.data);
      setInfluencerData(data.data);
    } catch (err) {}
    handleLoader(false);
  };

  return (
    <div>
      <div>
        <div
          onClick={() => tabClick("All")}
          className={`e-s-tab ${active === "All" ? `e-s-tab-active` : ``}`}
        >
          All
        </div>
        <div
          onClick={() => tabClick("Featured")}
          className={`e-s-tab ${active === "Featured" ? `e-s-tab-active` : ``}`}
        >
          Featured
        </div>
        <div
          onClick={() => tabClick("Trending")}
          className={`e-s-tab ${active === "Trending" ? `e-s-tab-active` : ``}`}
        >
          Trending
        </div>
        <div
          onClick={() => tabClick("Fashion")}
          className={`e-s-tab ${active === "Fashion" ? `e-s-tab-active` : ``}`}
        >
          Fashion
        </div>
        <div
          onClick={() => tabClick("Lifestyle")}
          className={`e-s-tab ${
            active === "Lifestyle" ? `e-s-tab-active` : ``
          }`}
        >
          Lifestyle
        </div>
      </div>
      {/* <FilterComponent  setInfluencerData = {setInfluencerData}/> */}
      <div style={{ height: "30px" }}></div>
      <div className="storyColection">
        <div className="story">
          {influencerData?.map((profile) => (
            <CardComponent
              key={profile.influencer_master_id}
              id={profile.influencer_master_id}
              pImg={profile.profile_pic_url}
              Name={profile.name}
              price={profile.price}
              tag={profile.hash_tags}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default StoryComponent;
