import {
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  ButtonBase,
  List,
  Box,
  ListItem,
  SwipeableDrawer,
  TextField,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useEffect, useState } from "react";
import sampleImg from "../../assets/img/sample.jpg";
import {
  GET_FEEDS_V1,
  ENGT_PROD_BASE_URI,
  SAVE_POST_DATA,
  SOMETHING_WENT_WRONG,
} from "../../api/apiConstants";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";
import { getAuthAndData, postAuthAndData } from "../../api/ApiHelper";
import ReactPlayer from "react-player";
import VideoLibrary_Description from "./VideoLibrary_Description";
import createNotification from "../common/reactNotification";
import { NotificationContainer } from "react-notifications";

let feedsGlobalData = [];
let productIds = []

function VideoLibrary() {
  const [shoppablevideofeeds, setshoppablevideoFeeds] = useState([]);
  const [selectedCard, setselectedCard] = useState({});
  const [videoStatus, setVideoStatus] = useState(
    selectedCard?.isSelectde ? "Active" : "Inactive"
  );
  const [productDetail, setProductDetail] = useState([]);
  const [thumbnail_title, setThumbnail_title] = useState([]);
  const [discription, setDiscription] = useState({
    productId: "",
    thumbnailTitle: "",
  });

  const History = useHistory();
  const Dispatch = useDispatch();

  const handleResponseCode = (resp) => {
    if (!resp || resp.code === -1) {
      createNotification("error", SOMETHING_WENT_WRONG + "in Target Audience");
      return false;
    } else {
      return true;
    }
  };

  const emptyproductIDs = () => {
    productIds = [];
  };

  const fetchFeedsData = async (ContentType) => {
    handleLoader(true);
    const body = {
      Index: 1,
      ContentType: ContentType,
    };
    try {
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_FEEDS_V1}`,
        body,
        History
      );
      feedsGlobalData = data?.data?.Feeds;
      // console.log("ContentType",...ContentType);
      customFeeds();
    } catch (err) {}
    handleLoader(false);
  };

  const updateProductid = (product_id) => {
    productIds = [...product_id];
    // console.log(' updateProductid is running');
  }

  const customFeeds = () => {
    const feedsArr = [];
    feedsGlobalData?.forEach((fees) => {
      const obj = {
        url: fees?.Thumbnail_URL,
        displayName: fees?.Thumbnail_Title,
        isSelectde: fees?.IsCancelled,
        postId: fees?.EngagementPostId,
        Video_duration: fees?.Video_duration,
      };
      feedsArr.push(obj);
    });
    setshoppablevideoFeeds(feedsArr);
  };
  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
  };
  console.log("shoppablevideofeeds===>", shoppablevideofeeds);
  console.log("selectedCard===>", selectedCard);
  console.log("productIds===>", productIds);
  console.log("productDetails===>", productDetail);
  useEffect(() => {
    fetchFeedsData([2]);
  }, []);

  const CheckMandatoryFeilds = () =>{
    var allOkay = true;
    if (thumbnail_title.length <=0) {
      console.log("thumbnail title lenght",thumbnail_title.length)
      createNotification(
        "warning",
        "Please add thumbnail titile."
      );
      allOkay =false;
    }else if(productDetail.length <= 0){
      console.log("Please add at least one product to proceed.");
      createNotification(
        "warning",
        "Please select a product search criteria."
      );
      allOkay =false;
    }
    return allOkay;
  
  }
  

  const createPost = () => {
    try {
      if(!CheckMandatoryFeilds()){
        return ;
      }else{
      const body = {
        Thumbnail_Title: thumbnail_title,
        contentType: 2,
        ProductIdList: productIds,
        IsActive: videoStatus === "Active" ? true : false,
        PostId: selectedCard?.postId,
      };
      handleLoader(true);
      postAuthAndData(
        `${ENGT_PROD_BASE_URI}${SAVE_POST_DATA}`,
        body
      ).then((res) => {
        if (handleResponseCode(res)) {
          createNotification("success", "Shoppable Video Updated successfully.");
          setDiscription({
            contentType : 0,
            productId: "",
            thumbnailTitle: "",
          });
          productIds = [];
          setProductDetail([]);
          fetchFeedsData([2]);
          handleLoader(false);
        }
      });
    }
    } catch {
      handleLoader(false);
    }
  };

  console.log(videoStatus);

  const [drawes, setDrawers] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event?.type === "keydown" &&
      (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }

    setDrawers({ ...drawes, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      style={{ width: "350px" }}
      role="presentation"
    >
      <List>
        <ListItem>
          <div style={{ marginBottom: "5px" }}>
            <InputLabel style={{ fontSize: "14px" }}>
              Thumbnail Title
            </InputLabel>
            <InputBase
              // placeholder={selectedCard?.displayName}
              value={thumbnail_title}
              style={{
                border: "1px solid #F0F0F0",
                padding: "0 10px",
                width: "100%",
                borderRadius: "10px",
                marginBottom: "10px",
              }}
              onChange={(e) => setThumbnail_title(e.target.value)}
            />
          </div>
        </ListItem>
        <ListItem>
          <div style={{ marginBottom: "5px" }}>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                style={{ fontSize: "14px" }}
              >
                Video Status
              </FormLabel>
              <RadioGroup
                style={{ backgroundColor: "white" }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={videoStatus}
                onChange={(e) => setVideoStatus(e.target.value)}
              >
                <FormControlLabel
                  value="Active"
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  value="Inactive"
                  control={<Radio />}
                  label="In-active"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </ListItem>
        <ListItem>
          <VideoLibrary_Description
            productDetail={productDetail}
            setProductDetail={setProductDetail}
            productIds={productIds}
            selectedCard={selectedCard}
            updateProductid={updateProductid}
          />
        </ListItem>
        <ListItem>
          <ButtonBase
            style={{
              color: "#017EFA",
              background: "#F2F9FF",
              padding: "5px 15px",
              border: "1px solid #57B2E0",
              borderRadius: "9px",
              fontSize: "12px",
            }}
            onClick={createPost}
          >
            Update
          </ButtonBase>
        </ListItem>
      </List>
    </Box>
  );

  return (
    
    <div style={{ display: "flex" }}>
      <NotificationContainer />
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          {/* <h3 style={{ fontSize: "16px", color: "#4F4F4F" }}>Video Library</h3> */}
        </div>
        <div
          style={{
            display: "flex",
            gap: "20px",
            height: "70vh",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              rowGap: "25px",
            }}
          >
            {shoppablevideofeeds.map((item) => (
              <div onClick={toggleDrawer("right", true)}>
                <div
                  className="library-video"
                  style={{
                    boxShadow: "0px 2.56436px 15.3861px rgba(0, 0, 0, 0.08)",
                    borderRadius: "16px",
                    padding: "10px",
                    overflow: "hidden",
                    width: "170px",
                  }}
                  onClick={() => {
                    setselectedCard(item);
                    emptyproductIDs();
                    setVideoStatus(!item?.isSelectde ? "Active" : "Inactive");
                    setThumbnail_title(item?.displayName);
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <ReactPlayer
                      width={"215px"}
                      height={"170px"}
                      className="video"
                      controls={false}
                      // playing={true}
                      volume={0}
                      url={item.url}
                      style={{ objectFit: "cover", borderRadius: "15px" }}
                      loop={true}
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        padding: "2px 10px",
                        borderRadius: "7px",
                        color: "#ffffff",
                        background: "rgba(1, 126, 250, 0.4)",
                        backdropFilter: "blur(2.56436px)",
                      }}
                    >
                      <p style={{ margin: "0", fontSize: "12px" }}>
                        {item.Video_duration}
                      </p>
                    </div>
                  </div>
                  <p style={{ margin: " 10px 0", fontSize: "14px" }}>
                    {item.displayName.length > 20 ? item.displayName.substring(0, 20) + "...": item.displayName}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {selectedCard ? (
          <>
            <div>
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={drawes[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    {list(anchor)}
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default VideoLibrary;
