import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dark_Mode, Light_Mode } from "../../DesignHandler/DesignHandler";

const UseDesignHook = () => {
  let theme = useSelector((select) => select.themeReducer.Rtheme);
  const dispatch = useDispatch();

  const updateThemeToDark = () => {
    dispatch({ type: Dark_Mode, payload: "dark" });
  };
  const updateThemeToLight = () => {
    dispatch({ type: Light_Mode, payload: "light" });
  };
  return {
    theme,
    updateThemeToDark,
    updateThemeToLight,
  };
};

export default UseDesignHook;
