import React, { useState } from "react";
import { HexColorPicker } from "react-colorful";
import { Button, InputBase, InputLabel, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const initialActiveInput = {
  fontSize: false,
  fontStyle: false,
  textBackgroundColor: false,
  textColor: false,
};
const useStyles = makeStyles({
  item: {
    "&:hover": {
      backgroundColor: "black",
    },
  },
});
const TextEditor = ({ textEditorInput, setTextEditorInput, handleAddText }) => {
  const [activeInput, setActiveInput] = useState(initialActiveInput);
  const [moreColor, setMoreColor] = useState({
    backgroundColor: false,
    textColor: false,
  });
  const [activeColor, setActiveColor] = useState({
    backgroundColor: "#000",
    textColor: "#fff",
  });
  const [activeFont, setActiveFont] = useState("Roboto");
  const fonts = [
    "Alex Brush",
    "CooperHewitt",
    "Edmunds",
    "Kaushan Script",
    "Pacifico",
    "Seaside ResortNF",
    "Sofia",
  ];
  const defaultColors = ["#D9E3F0", "#F47373", "#697689", "#37D67A", "#2CCCE4"];
  const handleSelect = (property, value) => {
    setTextEditorInput({ ...textEditorInput, [property]: value });
    setActiveInput({ ...initialActiveInput });
  };
  const handleCustomColor = (property) => {
    setMoreColor({ ...moreColor, [property]: true });
    setActiveInput({ ...initialActiveInput });
  };
  const { item } = useStyles();

  // return (
  //   <div style={{ width: "100%" }}>
  //     <div
  //       style={{
  //         // display: "flex",
  //         gap: "30px",
  //         alignItems: "center",
  //         width: "100%",
  //       }}
  //     >
  //       <p>Hint: you can drag text on the preview image</p>
  //       <div style={{ display: "flex", justifyContent: "end" }}>
  //         <Button
  //           style={{
  //             color: "#000",
  //             // background: "#F0F0F0",
  //             padding: "5px 15px",
  //             border: "1px solid #F0F0F0",
  //             borderRadius: "9px",
  //             fontSize: "12px",
  //           }}
  //         >
  //           Rotate
  //         </Button>
  //       </div>
  //       <div style={{ marginBottom: "20px" }}>
  //         <InputLabel style={{ fontSize: "14px" }}>Text</InputLabel>
  //         <InputBase
  //           placeholder="Ex. blaash"
  //           style={{
  //             border: "1px solid #F0F0F0",
  //             padding: "0 10px",
  //             width: "100%",
  //             borderRadius: "10px",
  //           }}
  //           value={textEditorInput.text}
  //           onChange={(e) =>
  //             setTextEditorInput({ ...textEditorInput, text: e.target.value })
  //           }
  //         />
  //       </div>
  //       <div style={{ display: "flex", marginBottom: "20px", gap: "10px" }}>
  //         <div style={{ width: "50%", position: "relative" }}>
  //           <InputLabel style={{ fontSize: "14px" }}>Font Style</InputLabel>
  //           <div style={{ position: "relative" }}>
  //             <InputBase
  //               placeholder="Ex. blaash"
  //               style={{
  //                 border: "1px solid #F0F0F0",
  //                 padding: "0 10px",
  //                 width: "100%",
  //                 borderRadius: "10px",
  //               }}
  //               value={textEditorInput.fontStyle}
  //               onClick={() =>
  //                 setActiveInput({ ...initialActiveInput, fontStyle: true })
  //               }
  //             />
  //             <button
  //               style={{
  //                 position: "absolute",
  //                 right: "30px",
  //                 margin: "auto",
  //                 top: "50%",
  //                 transform: "translateY(-50%)",
  //                 height: "28px",
  //                 width: "28px",
  //                 border: "none",
  //                 borderRadius: "6px",
  //                 fontSize: "15px",
  //                 fontFamily: textEditorInput.fontStyle,
  //                 backgroundColor: "#F2F2F2",
  //               }}
  //               // onClick={() => setActiveColor(color)}
  //             >
  //               Aa
  //             </button>
  //             <div
  //               style={{
  //                 position: "absolute",
  //                 right: "5px",
  //                 top: "50%",
  //                 transform: "translateY(-50%)",
  //               }}
  //             >
  //               <ArrowDropDownIcon
  //                 fontSize="medium"
  //                 style={{ color: "#017EFA" }}
  //               />
  //             </div>
  //           </div>
  //           {activeInput.fontStyle && (
  //             <div
  //               style={{
  //                 position: "absolute",
  //                 top: "100%",
  //                 left: "0px",
  //                 right: "0px",
  //                 padding: "5px 10px 10px",
  //                 // border: "1px solid #F0F0F0",
  //                 backgroundColor: "#fff",
  //                 zIndex: "999",
  //                 borderRadius: "10px",
  //                 boxShadow:
  //                   "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
  //               }}
  //             >
  //               {fonts.map((font) => (
  //                 <p
  //                   className={item}
  //                   style={{
  //                     fontSize: "14px",
  //                     fontFamily: font,
  //                     margin: 0,
  //                     padding: "5px 0",
  //                     borderRadius: "6px",
  //                   }}
  //                   onClick={() => handleSelect("fontStyle", font)}
  //                 >
  //                   {font}
  //                 </p>
  //               ))}
  //             </div>
  //           )}
  //         </div>
  //         <div style={{ width: "50%" }}>
  //           <InputLabel style={{ fontSize: "14px" }}>Font Size</InputLabel>
  //           <div style={{ position: "relative" }}>
  //             <InputBase
  //               placeholder="18px"
  //               style={{
  //                 border: "1px solid #F0F0F0",
  //                 padding: "0 10px",
  //                 width: "100%",
  //                 borderRadius: "10px",
  //               }}
  //               type="number"
  //               value={textEditorInput.fontSize}
  //               onChange={(e) =>
  //                 setTextEditorInput({
  //                   ...textEditorInput,
  //                   fontSize: e.target.value,
  //                 })
  //               }
  //             />
  //           </div>
  //         </div>
  //       </div>
  //       <div style={{ display: "flex", marginBottom: "20px", gap: "10px" }}>
  //         <div style={{ width: "50%", position: "relative" }}>
  //           <InputLabel style={{ fontSize: "14px" }}>Font Color</InputLabel>
  //           <div style={{ position: "relative" }}>
  //             <InputBase
  //               placeholder="Ex. blaash"
  //               style={{
  //                 border: "1px solid #F0F0F0",
  //                 padding: "0 10px",
  //                 width: "100%",
  //                 borderRadius: "10px",
  //               }}
  //               value={textEditorInput.textColor}
  //               onClick={() =>
  //                 setActiveInput({ ...initialActiveInput, textColor: true })
  //               }
  //             />
  //             <button
  //               style={{
  //                 position: "absolute",
  //                 right: "30px",
  //                 margin: "auto",
  //                 top: "50%",
  //                 transform: "translateY(-50%)",
  //                 height: "28px",
  //                 width: "28px",
  //                 border: "none",
  //                 borderRadius: "6px",
  //                 fontSize: "15px",
  //                 backgroundColor: textEditorInput.textColor,
  //               }}
  //               onClick={() => setMoreColor({ ...moreColor, textColor: true })}
  //             ></button>
  //             <div
  //               style={{
  //                 position: "absolute",
  //                 right: "5px",
  //                 top: "50%",
  //                 transform: "translateY(-50%)",
  //               }}
  //             >
  //               <ArrowDropDownIcon
  //                 fontSize="medium"
  //                 style={{ color: "#017EFA" }}
  //               />
  //             </div>
  //           </div>
  //           {moreColor.textColor && (
  //             <div
  //               style={{
  //                 position: "absolute",
  //                 top: "100%",
  //                 right: "0px",
  //                 zIndex: 100,
  //               }}
  //             >
  //               <HexColorPicker
  //                 color={textEditorInput.textColor}
  //                 onChange={(color) =>
  //                   setTextEditorInput({
  //                     ...textEditorInput,
  //                     textColor: color,
  //                   })
  //                 }
  //               />
  //             </div>
  //           )}
  //           {activeInput.textColor && (
  //             <div
  //               style={{
  //                 position: "absolute",
  //                 top: "100%",
  //                 left: "0px",
  //                 right: "0px",
  //                 padding: "5px 10px 10px",
  //                 // border: "1px solid #F0F0F0",
  //                 backgroundColor: "#fff",
  //                 zIndex: "999",
  //                 borderRadius: "10px",
  //                 boxShadow:
  //                   "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
  //               }}
  //             >
  //               {defaultColors.map((color) => (
  //                 <div
  //                   className={item}
  //                   style={{
  //                     fontSize: "14px",
  //                     margin: 0,
  //                     padding: "5px 0",
  //                     borderRadius: "6px",
  //                     display: "flex",
  //                     justifyContent: "space-between",
  //                     alignItems: "center",
  //                   }}
  //                   onClick={() => handleSelect("textColor", color)}
  //                 >
  //                   <div> {color}</div>
  //                   <div
  //                     style={{
  //                       height: "25px",
  //                       width: "25px",
  //                       borderRadius: "6px",
  //                       backgroundColor: color,
  //                       marginRight: "15px",
  //                     }}
  //                   ></div>
  //                 </div>
  //               ))}
  //               <Button
  //                 style={{
  //                   color: "#017EFA",
  //                   background: "#F2F9FF",
  //                   padding: "5px 15px",
  //                   border: "1px solid #57B2E0",
  //                   borderRadius: "9px",
  //                   fontSize: "12px",
  //                   marginRight: "50px",
  //                 }}
  //                 onClick={() => handleCustomColor("textColor")}
  //               >
  //                 Choose Custom
  //               </Button>
  //             </div>
  //           )}
  //         </div>
  //         <div style={{ width: "50%" }}>
  //           <InputLabel style={{ fontSize: "14px" }}>
  //             Font Background Color
  //           </InputLabel>
  //           <div style={{ position: "relative" }}>
  //             <InputBase
  //               placeholder="#F2F2F2"
  //               style={{
  //                 border: "1px solid #F0F0F0",
  //                 padding: "0 10px",
  //                 width: "100%",
  //                 borderRadius: "10px",
  //               }}
  //               value={textEditorInput.fontBackgroundColor}
  //               onChange={(e) =>
  //                 setTextEditorInput({
  //                   ...textEditorInput,
  //                   fontBackgroundColor: e.target.value,
  //                 })
  //               }
  //               onClick={() =>
  //                 setActiveInput({
  //                   ...initialActiveInput,
  //                   textBackgroundColor: true,
  //                 })
  //               }
  //             />
  //             <button
  //               style={{
  //                 position: "absolute",
  //                 right: "30px",
  //                 margin: "auto",
  //                 top: "50%",
  //                 transform: "translateY(-50%)",
  //                 height: "28px",
  //                 width: "28px",
  //                 border: "none",
  //                 borderRadius: "6px",
  //                 fontSize: "20px",
  //                 backgroundColor: "#F2F2F2",
  //               }}
  //               onClick={() =>
  //                 setMoreColor({ ...moreColor, backgroundColor: true })
  //               }
  //             ></button>
  //             {moreColor.backgroundColor && (
  //               <div
  //                 style={{
  //                   position: "absolute",
  //                   bottom: "2px",
  //                   right: "0px",
  //                   zIndex: 100,
  //                 }}
  //               >
  //                 <HexColorPicker
  //                   color={textEditorInput.fontBackgroundColor}
  //                   onChange={(color) =>
  //                     setTextEditorInput({
  //                       ...textEditorInput,
  //                       fontBackgroundColor: color,
  //                     })
  //                   }
  //                 />
  //               </div>
  //             )}
  //             {activeInput.textBackgroundColor && (
  //               <div
  //                 style={{
  //                   position: "absolute",
  //                   top: "100%",
  //                   left: "0px",
  //                   right: "0px",
  //                   padding: "5px 10px 10px",
  //                   // border: "1px solid #F0F0F0",
  //                   backgroundColor: "#fff",
  //                   zIndex: "999",
  //                   borderRadius: "10px",
  //                   boxShadow:
  //                     "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
  //                 }}
  //               >
  //                 {defaultColors.map((color) => (
  //                   <div
  //                     className={item}
  //                     style={{
  //                       fontSize: "14px",
  //                       margin: 0,
  //                       padding: "5px 0",
  //                       borderRadius: "6px",
  //                       display: "flex",
  //                       justifyContent: "space-between",
  //                       alignItems: "center",
  //                     }}
  //                     onClick={() => handleSelect("textColor", color)}
  //                   >
  //                     <div> {color}</div>
  //                     <div
  //                       style={{
  //                         height: "25px",
  //                         width: "25px",
  //                         borderRadius: "6px",
  //                         backgroundColor: color,
  //                         marginRight: "15px",
  //                       }}
  //                     ></div>
  //                   </div>
  //                 ))}
  //                 <Button
  //                   style={{
  //                     color: "#017EFA",
  //                     background: "#F2F9FF",
  //                     padding: "5px 15px",
  //                     border: "1px solid #57B2E0",
  //                     borderRadius: "9px",
  //                     fontSize: "12px",
  //                     marginRight: "50px",
  //                   }}
  //                   onClick={() => handleCustomColor("textBackgroundColor")}
  //                 >
  //                   Choose Custom
  //                 </Button>
  //               </div>
  //             )}
  //             <div
  //               style={{
  //                 position: "absolute",
  //                 right: "5px",
  //                 top: "50%",
  //                 transform: "translateY(-50%)",
  //               }}
  //             >
  //               <ArrowDropDownIcon
  //                 fontSize="medium"
  //                 style={{ color: "#017EFA" }}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       {(moreColor.backgroundColor || moreColor.textColor) && (
  //         <div
  //           onClick={() =>
  //             setMoreColor({ backgroundColor: false, textColor: false })
  //           }
  //           style={{
  //             position: "absolute",
  //             top: 0,
  //             bottom: 0,
  //             right: 0,
  //             left: 0,
  //             // zIndex: 88,
  //           }}
  //         ></div>
  //       )}
  //       {(activeInput.fontSize ||
  //         activeInput.fontStyle ||
  //         activeInput.textBackgroundColor ||
  //         activeInput.textColor) && (
  //         <div
  //           style={{
  //             position: "absolute",
  //             top: 0,
  //             left: 0,
  //             bottom: 0,
  //             right: 0,
  //           }}
  //           onClick={() => setActiveInput(initialActiveInput)}
  //         ></div>
  //       )}
  //     </div>
  //     <div>
  //       <Button
  //         style={{
  //           color: "#fff",
  //           background: "#000",
  //           padding: "5px 15px",
  //           borderRadius: "9px",
  //           fontSize: "12px",
  //           float: "right",
  //           margin: "10px 0 20px",
  //         }}
  //         onClick={() => handleAddText()}
  //       >
  //         ADD TEXT
  //       </Button>
  //     </div>
  //   </div>
  // );
  return (
    <div
      style={{
        display: "flex",
        // width: "100%",
        gap: "30px",
        alignItems: "center",
        marginBottom: "30px",
        // position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {fonts.map((font) => (
          <button
            style={{
              height: "40px",
              width: "40px",
              fontFamily: font,
              borderRadius: "10px",
              border: "3px solid rgba(1, 126, 250, 0.1)",
              fontSize: "18px",
              backgroundColor:
                activeFont == font ? "rgba(1, 126, 250, 0.1)" : "white",
            }}
            onClick={() =>
              setTextEditorInput({ ...textEditorInput, fontStyle: font })
            }
          >
            Aa
          </button>
        ))}
      </div>
      <div>
        <div style={{ marginBottom: "20px", position: "relative" }}>
          <InputLabel style={{ fontSize: "14px" }}>Text</InputLabel>
          <InputBase
            placeholder="Ex. blaash"
            style={{
              border: "1px solid #F0F0F0",
              padding: "0 10px",
              width: "100%",
              borderRadius: "10px",
            }}
            value={textEditorInput.text}
            onChange={(e) =>
              setTextEditorInput({ ...textEditorInput, text: e.target.value })
            }
          />
          <button
            style={{
              position: "absolute",
              right: "0",
              margin: "auto",
              // top: "50%",
              // transform: "translateY(-50%)",
              height: "30px",
              width: "30px",
              border: "none",
              borderRadius: "6px",
              backgroundColor: textEditorInput.fontColor,
            }}
            onClick={() => setMoreColor({ ...moreColor, textColor: true })}
          ></button>
          {moreColor.textColor && (
            <div
              style={{
                position: "absolute",
                bottom: "2px",
                right: "0px",
                zIndex: 100,
              }}
            >
              <HexColorPicker
                color={textEditorInput.fontColor}
                onChange={(color) =>
                  setTextEditorInput({
                    ...textEditorInput,
                    fontColor: color,
                  })
                }
              />
            </div>
          )}
        </div>
        <div style={{ width: "100%" }}>
          <InputLabel style={{ fontSize: "14px" }}>Font Size</InputLabel>
          <div style={{ position: "relative" }}>
            <InputBase
              placeholder="18px"
              style={{
                border: "1px solid #F0F0F0",
                padding: "0 10px",
                width: "100%",
                borderRadius: "10px",
              }}
              type="number"
              value={textEditorInput.fontSize}
              onChange={(e) =>
                setTextEditorInput({
                  ...textEditorInput,
                  fontSize: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          gap: "10px",
          position: "relative",
        }}
      >
        {defaultColors.map((color) => (
          <button
            style={{
              height: "45px",
              width: "45px",
              borderRadius: "10px",
              border: activeColor == color ? "3px solid #F2F2F2" : "none",
              fontSize: "20px",
              backgroundColor: color,
            }}
            onClick={() =>
              setTextEditorInput({
                ...textEditorInput,
                fontBackgroundColor: color,
              })
            }
          ></button>
        ))}
        {moreColor.backgroundColor && (
          <div
            style={{
              position: "absolute",
              bottom: "2px",
              right: "0px",
              zIndex: 100,
            }}
          >
            <HexColorPicker
              color={textEditorInput.fontBackgroundColor}
              onChange={(color) =>
                setTextEditorInput({
                  ...textEditorInput,
                  fontBackgroundColor: color,
                })
              }
            />
          </div>
        )}
        <button
          style={{
            height: "45px",
            width: "45x",
            borderRadius: "10px",
            border: "3px solid #F2F2F2",
            fontSize: "20px",
            backgroundColor: "white",
            position: "relative",
            zIndex: 100,
          }}
          onClick={() => setMoreColor({ ...moreColor, backgroundColor: true })}
        >
          <AddIcon color="#F2F2F2" />
        </button>
      </div>

      {(moreColor.backgroundColor || moreColor.textColor) && (
        <div
          onClick={() =>
            setMoreColor({ backgroundColor: false, textColor: false })
          }
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            // zIndex: 88,
          }}
        ></div>
      )}
      <Button
        style={{
          position: "absolute",
          top: "0px",
          left: "15px",
          color: "#fff",
          background: "#000",
          padding: "5px 15px",
          borderRadius: "9px",
          fontSize: "12px",
          float: "right",
          margin: "10px 0 20px",
        }}
        onClick={() => handleAddText()}
      >
        ADD ANOTHER TEXT
      </Button>
    </div>
  );
};

export default TextEditor;
