import React, { useState } from "react";
import CardMedia from "@material-ui/core/CardMedia";
import "./OneClickPost.css";
import UseIgHook from "./igHook/UseIgHook";
import { useEffect } from "react";
import ReactPlayer from "react-player";
import { useDispatch } from "react-redux";
import { Selected_Product_Array } from "../../constants/actionTypes";

const IgCardComponent = ({ igData, photovideoClick }) => {
  const { igSelectedData, setIgSelectedData } = UseIgHook();

  // const [igSelectedData, setIgSelectedData] = useState([]);

  const handleCheckboxChange = (data) => {
    const selectedIndex = igSelectedData.findIndex(
      (item) => item.id === data.id
    );

    if (selectedIndex === -1) {
      setIgSelectedData([...igSelectedData, data]);
    } else {
      const updatedSelectedData = [...igSelectedData];
      updatedSelectedData.splice(selectedIndex, 1);
      setIgSelectedData(updatedSelectedData);
    }
  };

  useEffect(() => {
    console.log("useEffectigSelectedData", igSelectedData);
    updateSelectedArray(igSelectedData);
  }, [igSelectedData]);

  const dispatch = useDispatch();

  const updateSelectedArray = (igSelectedData) => {
    console.log("dispatch done from IgCardComponent");
    dispatch({ type: Selected_Product_Array, payload: igSelectedData });
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      {igData?.map((data, index) => {
        if (data.media_type === photovideoClick) {
          // ! temporary change
          // if (data.media_type === "VIDEO") {

          const checkboxSelected = igSelectedData.some(
            (item) => item.id === data.id
          );

          return (
            <div className="video_container" key={data.id}>
              <div className="video_container01">
                <div className="play_icon">
                  {/* //! <PlaySvg/> */}
                  <svg
                    width="26"
                    height="28"
                    viewBox="0 0 26 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {/* ...play icon SVG code... */}
                  </svg>
                </div>

                {data.media_type === "IMAGE" && (
                  <img
                    src={data.media_url}
                    alt="no photo"
                    style={{
                      position: "absolute",
                      objectFit: "cover",
                      width: "236px",
                      height: "148px",
                      borderRadius: "15px",
                      border: "1px solid #FFFFFF",
                      boxShadow: "0px 2.56px 15.39px 0px rgba(0, 0, 0, 0.05)",
                    }}
                  />
                )}
                {data.media_type === "VIDEO" && (
                  <ReactPlayer
                    url={data.media_url}
                    width="236px"
                    height="148px"
                    style={{
                      borderRadius: "15px",
                      // border: '1px solid #FFFFFF',
                      boxShadow: "0px 2.56px 15.39px 0px rgba(0, 0, 0, 0.05)",
                    }}
                  />
                )}

                {/* <div className="size_container">
                  <div className="size_container_text">300mb</div>
                </div> */}

                {/* <div className="timestamp_container">
                  <div className="timestamp_container_text">00:00:00</div>
                </div> */}
              </div>

              <div className="checkbox_container">
                <input
                  type="checkbox"
                  checked={checkboxSelected}
                  onChange={() => handleCheckboxChange(data)}
                />

                <div className="ig_title">
                  {data.caption ? data.caption : "NO CAPTION"}
                </div>
              </div>
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default IgCardComponent;