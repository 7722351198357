import React, { useState, useEffect } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import cloudsImage from "../../Image/Icon/close-circle.jpg";
import createNotification from "../../../common/reactNotification";
import { NotificationContainer } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  ENGT_PROD_BASE_URI,
  ENGT_PROD_HOST_URI,
  IDTY_PROD_HOST_URI,
  PREE_SIGNED_URL,
  SAVE_MEDIA_DETAILS,
  SOMETHING_WENT_WRONG,
  USER_DATA_GROUP_PERMISSIONS,
} from "../../../../api/apiConstants";
import { getAuthAndData, postAuthAndData } from "../../../../api/ApiHelper";
import { dispatchLoaderData } from "../../../../actions/route/routeActionHandler";
import { useCallback } from "react";
import { Auth } from "aws-amplify";
import { updateUserDataObj } from "../../../../actions/login/updateUserDataObj";
import axios from "axios";

let preAssignthumbnailArr = [];
let presignedUploadUrlArr = [];
let isUploadToAWSSuccess = true;
let userData;

const Modal = () => {
  userData = useSelector((select) => select.userReducer.userData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState([]);
  const [PostContent, setPostContent] = useState([]);
  const [fileObj, setFileObj] = useState([]);
  const [thumbnailContent, setThumbnailContent] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleResponseCode = (resp) => {
    if (!resp || resp.code === -1) {
      createNotification(
        "error",
        SOMETHING_WENT_WRONG + ", Please try again later."
      );
      handleLoader(false);

      return false;
    } else {
      return true;
    }
  };

  const onDrop = (acceptedFiles) => {
    console.log("dropped files", acceptedFiles);
    isUploadToAWSSuccess = true;
    setPostContent([]);
    setThumbnailContent([]);
    setFileObj([]);
    setThumbnailUrl([]);
    const filterObjArr = [];
    acceptedFiles?.forEach((file, index) => {
      console.log("ind file", file);
      ////console("file_extention===", file.name.split(".").pop());
      let extensionType = file.name.split(".").pop().toLowerCase();
      if (
        extensionType == "jpeg" ||
        extensionType == "jpg" ||
        extensionType == "mp4" ||
        extensionType == "png" ||
        extensionType == "webp" ||
        extensionType == "mp3"
      ) {
        if (
          (extensionType == "mp4" && file.size < 5000000) || //Video Size max is 5 MB
          ((extensionType == "jpeg" ||
            extensionType == "jpg" ||
            extensionType == "png") &&
            file.size < 1000000) ||
          (extensionType == "mp3" && file.size < 1000000)
        ) {
          const obj = {
            extension: file.name.split(".").pop(),
            contentType:
              extensionType == "mp4" ? "2" : extensionType == "mp3" ? "3" : "1",
            file: file,
            FileId: ` ${Date.now()}_${file.name}`,
          };
          filterObjArr.push(obj);
          ////console.log("File Object====",obj);
        } else {
          createNotification(
            "warning",
            "Image/Audio/Video size should be less than 1 MB/1 MB/5 MB"
          );
        }
      } else {
        createNotification(
          "warning",
          "Only jpeg, jpg, png, mp4, mp3  webp file type is allowed"
        );
      }
    });
    setFileObj(filterObjArr);
    fatchPreUrlData(filterObjArr);
    setIsModalOpen(false);
  };

  const handleLoader = (showBool) => {
    dispatch(dispatchLoaderData(showBool));
  };

  // const fetchPreUrlDataThumblail = (filterObjArr) => {
  //   try {
  //     // if (!userData?.UserID) {
  //     //   Auth.signOut();
  //     //   history.push("/login");
  //     // }

  //     preAssignthumbnailArr = [];
  //     handleLoader(true);
  //     const body = {
  //       userId: userData?.UserID,
  //       files: filterObjArr,
  //     };

  //     postAuthAndData(
  //       `${ENGT_PROD_BASE_URI}${PREE_SIGNED_URL}`,
  //       body,
  //       history
  //     ).then((res) => {
  //       if (handleResponseCode(res)) {
  //         handleLoader(false);
  //         res?.data.forEach((e) => preAssignthumbnailArr.push(e));

  //         setThumbnailContent(
  //           res?.data.map((e) => ({
  //             fileName: e.FileKey,
  //             fileType: e.FileExtenssion.toLowerCase() == "mp4" ? 2 : 1,
  //             fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
  //           }))
  //         );
  //       }
  //     });
  //   } catch {
  //     handleLoader(false);
  //   }
  // };

  const fatchPreUrlData = (filterObjArr) => {
    console.log("filterObjArr", filterObjArr);
    try {
      // if (!userData?.UserID) {
      //   console.log("not found");
      //   Auth.signOut();
      //   history.push("/login");
      // }
      presignedUploadUrlArr = [];

      setPostContent([]);
      handleLoader(true);
      const body = {
        userId: userData?.UserID,
        files: filterObjArr,
      };

      //debugger;
      postAuthAndData(
        `${ENGT_PROD_BASE_URI}${PREE_SIGNED_URL}`,
        body,
        history
      ).then((res) => {
        console.log("ressss", res);
        if (handleResponseCode(res)) {
          handleLoader(false);
          res?.data.forEach((e) => presignedUploadUrlArr.push(e));
          uploadDataOnAws(filterObjArr);
          // if (discription.contentType == 2) {
          //   getDuration(filterObjArr[0].file);
          //   setPostContent(
          //     res?.data.map((e) => ({
          //       fileName: e.FileKey,
          //       fileType: e.FileExtenssion.toLowerCase() == "mp4" ? 2 : 1,
          //       fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
          //     }))
          //   );
          // } else {
          //   setPostContent(
          //     res?.data.map((e) => ({
          //       fileName: e.FileKey,
          //       fileType: e.FileExtenssion.toLowerCase() == "mp4" ? 2 : 1,
          //       fileUrl: `https://blsh-social.s3.amazonaws.com/${e.FileKey}`,
          //     }))
          //   );
          // }
        }
      });
    } catch {}
  };

  const updateAndReturnUserDAta = () => {
    //console.log("userData%%%%========",userData)
    if (!userData) {
      //console.log("userData%%%%========111",userData)
      Auth.currentSession()
        .then((session) => {
          var payLoad = session.idToken.payload;
          //console.log("payLoadpayLoad234=======",payLoad)
          getAuthAndData(
            `${IDTY_PROD_HOST_URI}${USER_DATA_GROUP_PERMISSIONS}${payLoad.email}`
          ).then((res) => {
            //console.log("Response Came",res);
            if (handleResponseCode(res)) {
              //console.log("handleResponseCode Inside Came",res);
              dispatch(updateUserDataObj(res?.data));
              //console.log("userData%%%%========222",res?.data)
              userData = res?.data;
            } else {
              //console.log("handleResponseCode Outside Came",res);
              //update redux store
            }
          });
        })
        .catch((error) => {
          //update redux store
        });
    } else {
      //update redux store
    }
  };

  const addContent = (fobj) => {
    console.log("addContent ffob======", fobj);
    ////console.log("addContent ffobExtenssion======", fobj)
    let contentFile;
    if (fobj.contentType == 1) {
      contentFile = `image/${fobj?.extension}`;
    } else {
      contentFile = `video/${fobj?.extension}`;
    }
    return contentFile;
  };

  const uploadDataOnAws = (filterObjArr) => {
    handleLoader(true);
    //local variable isUploadSuccess = true
    try {
      console.log("uploadDataOnAws=====$$$");
      // if (discription.contentType != 5) {
      //   if (presignedUploadUrlArr?.length == 0) isUploadToAWSSuccess = false;
      // }
      console.log("presignedUploadUrlArr", presignedUploadUrlArr);
      console.log("fileObj5", filterObjArr);

      presignedUploadUrlArr?.forEach((presignedUploadUrl) => {
        //if()
        let fobj = filterObjArr.find(
          (e) => e.FileId == presignedUploadUrl.FileId
        );
        console.log("fileObj=====$$$", fobj);
        let conetntType = addContent(fobj);
        console.log("conetntType=====$$$", conetntType);
        const requestOptions = {
          method: "PUT",
          headers: {
            // "Content-Type": fileObj.find(
            //   (e) => e.FileId == presignedUploadUrl.FileId
            // ).file.type,

            "Content-Type": conetntType,
            "Access-Control-Allow-Origin": "*",
          },
          body: filterObjArr.find((e) => e.FileId == presignedUploadUrl.FileId)
            .file,
        };
        console.log("requestOptions====$$", requestOptions);
        createNotification("info", "Please wait");
        fetch(
          new Request(presignedUploadUrl.PreSignedUrl, requestOptions)
        ).then((response) => {
          console.log("uploadDataOnAws Flow1", response);
          if (response.status == 200) {
            let dataToSend;

            if (conetntType === "image/png") {
              console.log("conetntType image", conetntType);
              dataToSend = {
                Content_type: "1",
                url: presignedUploadUrl.PreSignedUrl,
              };
            } else if (conetntType === "video/mp4") {
              console.log("conetntType video", conetntType);
              dataToSend = {
                Content_type: "2",
                url: presignedUploadUrl.PreSignedUrl,
              };
            } else if (conetntType === "audio/mp3") {
              console.log("conetntType audio", conetntType);
              dataToSend = {
                Content_type: "3",
                url: presignedUploadUrl.PreSignedUrl,
              };
            }

            postAuthAndData(
              `${ENGT_PROD_HOST_URI}${SAVE_MEDIA_DETAILS}`,
              dataToSend
            )
              .then((res) => {
                if (handleResponseCode(res)) {
                  console.log(" API request was successful");
                  createNotification("success", "SUCCESSFULLY DONE.");
                }
              })
              .catch((error) => {
                console.error("Error in API request:", error);
              });

            //responsehandler(true);
            //isUploadSuccess = false;
            //setFlag(true);
            // uploadDataOnAws2();
          } else {
            //setFlag(false);
            // responsehandler(false);
            //If any file is failing to upload - We shall not create the Post - Please try again
            isUploadToAWSSuccess = false;
            createNotification(
              "error",
              SOMETHING_WENT_WRONG +
                "Error uploading image to cloud, please try again."
            );
          }
        });
        handleLoader(false);
      });

      // if(isUploadSuccess)
      // {
      //   //postdata
      // }
      //  responsehandler(flag);
      //Calling Thumbnail Upload
    } catch {
      isUploadToAWSSuccess = false;
      createNotification(
        "error",
        SOMETHING_WENT_WRONG +
          "Error uploading image to cloud, please try again."
      );
    }
    handleLoader(false);
  };

  // const uploadDataOnAws2 = () => {
  //   // handleLoader(true);
  //   try {
  //     if (preAssignthumbnailArr?.length == 0) isUploadToAWSSuccess = false;

  //     preAssignthumbnailArr?.forEach((presignedUploadUrl) => {
  //       let thumbnailFileObj = thumbnailUrl.find(
  //         (e) => e.FileId == presignedUploadUrl.FileId
  //       );

  //       ////console.log("thumbnailFileObj=====$$$", thumbnailFileObj)
  //       let conetntType = addContent(thumbnailFileObj);
  //       ////console.log("thumbnail conetntType =====$$$", conetntType)
  //       const requestOptions = {
  //         method: "PUT",
  //         headers: {
  //           "Content-Type": conetntType,
  //           "Access-Control-Allow-Origin": "*",
  //         },
  //         body: thumbnailUrl.find((e) => e.FileId == presignedUploadUrl.FileId)
  //           .file,
  //       };
  //       //////console("before fatch data in thumbnailContent");
  //       fetch(
  //         new Request(presignedUploadUrl.PreSignedUrl, requestOptions)
  //       ).then((response) => {
  //         //////console("uploadDataOnAws Flow2", response.status);
  //         if (response.status == 200) {
  //           //setFlag(true);
  //           ////console.log("NEW CHNAGE==");
  //           console.log("isUploadToAWSSuccess", isUploadToAWSSuccess);
  //         } else {
  //           handleLoader(false);
  //           isUploadToAWSSuccess = false;
  //           createNotification(
  //             "error",
  //             SOMETHING_WENT_WRONG +
  //               "Error uploading image to cloud, please try again."
  //           );
  //           //setFlag(false);
  //         }
  //       });
  //     });
  //     //Once Thumb nail is added - Save Post Data
  //   } catch {
  //     handleLoader(false);
  //     isUploadToAWSSuccess = false;
  //     createNotification(
  //       "error",
  //       SOMETHING_WENT_WRONG +
  //         "Error uploading image to cloud, please try again."
  //     );
  //   }
  //   // handleLoader(false);

  //   //After the 2nd Upload Initiate the Post
  // };
  //   console.log("dropped files", acceptedFiles);
  //   setThumbnailUrl([]);
  //   const filterObjArr = [];
  //   if (acceptedFiles?.length == 1) {
  //     acceptedFiles?.forEach((file) => {
  //       console.log("each dropped file", file);
  //       let extensionType = file.name.split(".").pop().toLowerCase();
  //       if (
  //         extensionType == "jpeg" ||
  //         extensionType == "jpg" ||
  //         extensionType == "mp4" ||
  //         extensionType == "png" ||
  //         extensionType == "webp"
  //       ) {
  //         if (file.size < 3000000) {
  //           const obj = {
  //             extension: file.name.split(".").pop(),
  //             contentType: extensionType == "mp4" ? "2" : "1",
  //             file: file,
  //             FileId: ` ${Date.now()}_${file.name}`,
  //           };
  //           filterObjArr.push(obj);
  //         } else {
  //           createNotification(
  //             "warning",
  //             "Image size should be less than 3 MB."
  //           );
  //         }
  //       } else {
  //         createNotification(
  //           "warning",
  //           "Only jpeg, jpg, png, webp file type is allowed."
  //         );
  //       }
  //     });
  //   } else {
  //     createNotification("warning", "Only one image allowed");
  //   }

  //   setThumbnailUrl(filterObjArr);
  //   fetchPreUrlDataThumblail(filterObjArr);
  // };

  useEffect(() => {
    handleLoader(false);
    updateAndReturnUserDAta();
  }, []);
  return (
    <div className="model-container ">
      <NotificationContainer />
      {isModalOpen && (
        <div className="modal-overlay">
          <div></div>
          <div
            className="modal-content"
            style={{ background: "#27272f", border: "0px" }}
          >
            <div className="center-box_new">
              <div>
                <div className="d-flex flex-row bd-highlight justify-content-between">
                  <div>
                    <h3
                      style={{
                        fontSize: "16px",
                        color: "rgba(255, 255, 255, 0.50)",
                      }}
                    >
                      Upload Thumbnail Image
                    </h3>
                  </div>
                  <div
                    style={{ color: "rgba(255, 255, 255, 0.50)" }}
                    onClick={closeModal}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        d="M13.9997 25.6668C20.443 25.6668 25.6663 20.4435 25.6663 14.0002C25.6663 7.55684 20.443 2.3335 13.9997 2.3335C7.55635 2.3335 2.33301 7.55684 2.33301 14.0002C2.33301 20.4435 7.55635 25.6668 13.9997 25.6668Z"
                        fill="#FF5F58"
                      />
                      <path
                        d="M15.2366 13.9999L17.9199 11.3166C18.2583 10.9783 18.2583 10.4183 17.9199 10.0799C17.5816 9.74159 17.0216 9.74159 16.6833 10.0799L13.9999 12.7633L11.3166 10.0799C10.9783 9.74159 10.4183 9.74159 10.0799 10.0799C9.74159 10.4183 9.74159 10.9783 10.0799 11.3166L12.7633 13.9999L10.0799 16.6833C9.74159 17.0216 9.74159 17.5816 10.0799 17.9199C10.2549 18.0949 10.4766 18.1766 10.6983 18.1766C10.9199 18.1766 11.1416 18.0949 11.3166 17.9199L13.9999 15.2366L16.6833 17.9199C16.8583 18.0949 17.0799 18.1766 17.3016 18.1766C17.5233 18.1766 17.7449 18.0949 17.9199 17.9199C18.2583 17.5816 18.2583 17.0216 17.9199 16.6833L15.2366 13.9999Z"
                        fill="#292D32"
                      />
                    </svg>
                  </div>
                </div>
                <div className="box_2">
                  <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className="uplodeDiv">
                        <input {...getInputProps()} />
                        <CloudUploadIcon
                          className="uplodeimg"
                          style={{ fontSize: "3rem", color: "#494B4D" }}
                        />

                        <p style={{ color: "#FFF" }}>
                          Drag and drop files or{" "}
                          <span style={{ color: "#017EFA" }}>Browse</span>
                        </p>

                        <>
                          <p
                            style={{
                              margin: 0,
                              fontSize: "14px",
                              color: "rgba(255, 255, 255, 0.50)",
                            }}
                          >
                            Just drag and drop your media file. e.g., image,
                            video, audios
                          </p>
                        </>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
            </div>
            {/* Add your custom modal content here */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
