//! activeStep 1
//! activeStep 2
//! activeStep 3
//! Thumbnail Title
//! Button CTA

import React, { useCallback, useState, useEffect, Fragment } from "react";
import ContentUplode from "./ContentUplode";
import DiscriptionStudio from "./DiscriptionStudio";
import StodioCategory from "./StodioCategory";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import DescriptionIcon from "@material-ui/icons/Description";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Typography from "@material-ui/core/Typography";
import { useDropzone } from "react-dropzone";
import "./story.Style.css";
import "react-notifications/lib/notifications.css";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SearchIcon from "@material-ui/icons//Search";

import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import {
  ENGT_PROD_BASE_URI,
  PREE_SIGNED_URL,
  SOMETHING_WENT_WRONG,
  SAVE_POST_DATA,
  GET_FEEDS_V1,
  CREATE_PLAY_LIST,
  GET_ALL_PLAYLIST,
} from "../../api/apiConstants";
import { postAuthAndData } from "../../api/ApiHelper";
import { useHistory } from "react-router";
import createNotification from "../common/reactNotification";
import { NotificationContainer } from "react-notifications";
import { dispatchLoaderData } from "../../actions/route/routeActionHandler";
import { useDispatch } from "react-redux";
import store from "../../store/store";
import Dropzone from "react-dropzone";
import {
  Textarea,
  InputBase,
  InputLabel,
  TextareaAutosize,
  IconButton,
  ButtonBase,
} from "@material-ui/core";
import ReactPlayer from "react-player";
import Usestoryhook from "./Usestoryhook";
import VideoLibrary_Description from "./VideoLibrary_Description";
const steps = [1, 2, 3];

function UploadShoppableVideos() {
  const {
    activeStep,
    pageType,
    discription,
    fileObj,
    thumbnailUrl,
    thumbnailContent,
    liveSchedule,
    productIds,
    storyFlage,
    inputMsg,
    productDetail,
    PostContent,
    activeDetailsFilter,
    activeProductFilter,
    initialDiscription,
    onDrop,
    setProductDetail,
    setInputMsg,
    setActiveDetailsFilter,
    setActiveProductFilter,
    fatchProductDetail,
    setActiveStep,
    setPageType,
    setDiscription,
    isStepOptional,
    isStepSkipped,
    handleNext,
    handleBack,
    handleSkip,
    handleReset,
    //contentType,
    removeAll,
    removeAll2,
    newFileUploadThumbnail,
    changeTitle,
    changeButtonCta,
    updateProductIds,
  } = Usestoryhook(steps);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, inputRef } =
    useDropzone({ onDrop });

  const handleNextStep = (contentType) => {
    // console.log("ajay productIds.length ---- on handle next--->",productIds.length);
    // console.log("ajay discription ---- on handle next--->",discription);

    // if(activeStep === 3){
    //   if(productIds.length!==0){
    //     setDiscription({ ...discription, contentType: contentType });
    //     handleNext(contentType);
    //     // setDiscription(initialDiscription)

    //   }else{
    //     createNotification("warning","Please select atleast one product.")
    //   }
    // }
    // else{
    setDiscription({ ...discription, contentType: contentType });
    handleNext(contentType);
    // }

    // checkFields(activeStep)
  };
  let feedsGlobalData = [];
  console.log("feedsGlobalData", feedsGlobalData);
let playlistId = 0;
  const Dispatch = useDispatch();
  const [feeds, setFeeds] = useState([]);
  const [contentType, setContentType] = useState(2);
  const [hashArray, setHashArray] = useState({})
  const [currentUrl, setCurrentUrl] = useState("");
  const [currentThumbNailUrl, setCurrentThumbNailUrl] = useState("");
  const [playlistCollection, setPlaylistCollection] = useState([]);
  console.log("feeddddd", feeds);
  const handleLoader = (showBool) => {
    Dispatch(dispatchLoaderData(showBool));
    //    props.routeActionHandler.dispatchLoaderData(showBool);
  };

  useEffect(() => {

    fetchFeedsData([contentType]);
    //getAllPlayList();
  }, [contentType]);

  const customFeeds = () => {
    //setFeeds([]);
    const feedsArr = [];
    feedsGlobalData?.forEach((fees) => {
      const obj = {
        url: fees?.Thumbnail_URL,
        displayName: fees?.Thumbnail_Title,
        isSelectde: false,
        postId: fees?.EngagementPostId,
        disabledProduct: fees?.DisabledProduct,
        ctaUrl: fees?.CtaUrl
      };
      feedsArr.push(obj);
    });
    setFeeds(feedsArr);
  };

  const getAllPlayList = async (ContentType) => {
    handleLoader(true);
    const body = {
      Content_Type: ContentType
    };
    try {
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_ALL_PLAYLIST}`, body,
        History
      );
      // debugger;
      // data?.data.map(d => {
      //   setHashArray(...d, d.URL_Code)
      // })
      setHashArray(data?.data.map(d => d.URL_Code))
      console.log("getAllPlayList========", data?.data);
      // console.log("hash-array-------------->", hashArray)
      customPlaylist(data?.data);
    } catch (err) { }
    handleLoader(false);
  };

  const createCustomContent = (ids) => {
    const contentArry = [];
    ids?.forEach((id) => {
      let content = feedsGlobalData?.find((p) => p?.EngagementPostId === id);
      const obj = {
        url: content?.Thumbnail_URL,
        displayName: content?.Thumbnail_Title,
        isSelectde: true,
        postId: content?.EngagementPostId,
      };
      contentArry.push(obj);
    });
    return contentArry;
  };

  const customPlaylist = (playlists) => {
    const array = [];
    playlists?.forEach((playlist) => {
      const obj = {
        description: playlist?.Description,
        name: playlist?.Name,
        url: playlist?.URL,
        isTagged: playlist?.IsTagged,
        checkBox: false,
        playListId: playlist?.PlayListId,
        contentList: createCustomContent(playlist?.Post_Ids),
        PlayListCode: playlist?.PlayListCode,
        url_code: playlist?.URL_Code
      };
      // console.log('array', playlist?.URL_Code)
      // console.log('obj------------------------->', obj)
      // setHashArray([playlist?.URL_Code])
      // console.log("hash url----------------->", hashArray)
      array.push(obj);
    });
    setPlaylistCollection(array);
  };
 
  const fetchFeedsData = async (ContentType) => {
    handleLoader(true);
    const body = {
      Index: 1,
      ContentType: ContentType,
      IsTagged: false,
      URL: ""
    };
    try {
      const data = await postAuthAndData(
        `${ENGT_PROD_BASE_URI}${GET_FEEDS_V1}`,
        body,
        History
      );
      setFeeds(data?.data?.Feeds);
      feedsGlobalData = data?.data?.Feeds;
      getAllPlayList(contentType);
      customFeeds();
    } catch (err) { }
    handleLoader(false);
  };
  // console.log("Main----",fileObj)
  // console.log("thumbnail----",thumbnailUrl)
  useEffect(() => {
    if (fileObj.length > 0) {
      const file = fileObj[0].file;
      const reader = new FileReader();
      reader.onload = (event) => {
        const dataURL = event.target.result;
        setCurrentUrl(dataURL);
      };
      reader.readAsDataURL(file);
    }
  }, [fileObj]);
  useEffect(() => {
    if (thumbnailUrl.length > 0) {
      const file = thumbnailUrl[0].file;
      const reader = new FileReader();
      reader.onload = (event) => {
        const dataURL = event.target.result;
        setCurrentThumbNailUrl(dataURL);
      };
      reader.readAsDataURL(file);
    }
  }, [thumbnailUrl]);

  const [prodId, setProdId] = useState([]);
  const passProductIds = (productId) => {
    console.log("passProductIdsproductId", productId);
    setProdId(productId);
    updateProductIds(productId);
  };

  const GetFileSize = (totalBytes) => {
    if (totalBytes < 1000000) {
      var FileSize = Math.floor(totalBytes / 1000) + " KB";
      console.log("FileSize", FileSize);
      return FileSize;
    } else {
      var FileSize = Math.floor(totalBytes / 1000000) + " MB";
      console.log("FileSize", FileSize);
      return FileSize;
    }
  };

  return (
    <div>
      <NotificationContainer />

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "15px 0",
          width: "70%",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          {steps.map((step, idx) => (
            <>
              {idx !== 0 && (
                <hr
                  style={{
                    display: "block",
                    // height: "4px",
                    width: "40px",
                    border: 0,
                    // borderWidth: "8px",
                    borderRadius: "0 20px 20px 0",
                    borderTop:
                      idx + 1 <= activeStep
                        ? "6px solid #017EFA"
                        : "6px solid #EFF0F7",
                    // margin: "1em 0",
                    padding: 0,
                  }}
                />
              )}
              <div
                style={{
                  width: "35px",
                  height: "35px",
                  color: idx + 1 <= activeStep ? "white" : "black",
                  margin: " 0 5px",
                  background: idx + 1 <= activeStep ? "#017EFA" : "#EFF0F7",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "10px",
                }}
              >
                {step}
              </div>
              {idx < steps.length - 1 && (
                <hr
                  style={{
                    display: "block",
                    // height: "19px",
                    width: "40px",
                    border: 0,
                    borderTop:
                      idx + 1 <= activeStep
                        ? "6px solid #017EFA"
                        : "6px solid #EFF0F7",
                    borderRadius: "20px 0px 0px 20px",

                    // margin: "1em 0",
                    padding: 0,
                  }}
                />
              )}
            </>
          ))}
        </div>
      </div>
      {/* //! activeStep 1 */}
      {activeStep === 1 && (
        <div style={{ display: "flex", gap: "30px", height: "73vh" }}>
          <div
            style={{
              width: "70%",
              background: "#FFFFFF",
              boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
              borderRadius: "16px",
            }}
          >
            <div
              style={{
                padding: "20px",
              }}
            >
              <h3 style={{ fontSize: "16px", marginBottom: "13px" }}>
                Upload Videos
              </h3>
              <div {...getRootProps()} className="uplodeDiv">
                <input {...getInputProps()} />
                <CloudUploadIcon
                  className="uplodeimg"
                  style={{ fontSize: "3rem" }}
                />
                {fileObj?.length > 0 && (
                  <p>{fileObj?.length} file is selected for upload</p>
                )}

                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <>
                    <p style={{ margin: 0, fontSize: "14px" }}>
                      Select files or Drag 'n' drop some files here
                    </p>
                    <p
                      style={{
                        color: "rgba(0, 0, 0, 0.4)",
                        margin: "0 0 15px",
                        fontSize: "14px",
                      }}
                    >
                      Video file,file size no more than 10mb
                    </p>
                    {/* <button></button> */}
                    <Button
                      variant="contained"
                      // color="#F2F9FF"
                      style={{
                        background: "#F2F9FF",
                        border: "1px solid #57B2E0",
                        color: "#017EFA",
                        fontSize: "12px",
                        borderRadius: "9px",
                      }}
                    >
                      SELECT FILE
                    </Button>
                  </>
                )}
              </div>
              <p
                style={{
                  textAlign: "center",
                  margin: "10px 0px",
                  fontSize: "14px",
                }}
              >
                What are you creating today.....
              </p>
              {/* here */}
              {fileObj?.length > 0 && (
                <div>
                  <h5 style={{ fontSize: "16px" }}>File added</h5>
                  <div
                    style={{
                      display: "flex",
                      margin: "3px 0px",
                      // width: "100%",
                      // justifyContent: "space-between",
                    }}
                  >
                    <DescriptionIcon
                      style={{ color: "#017EFA", fontSize: "35px" }}
                    />
                    <div
                      style={{
                        width: "100%",
                        borderBottom: "3px solid #017EFA",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "12px",
                          margin: "0",
                          color: "rgba(0, 0, 0, 0.4)",
                        }}
                      >
                        {fileObj[0]?.file?.name}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <DeleteOutlineIcon
                          style={{
                            color: "rgba(255, 78, 78, 1)",
                            fontSize: "18px",
                          }}
                        />
                        <p
                          style={{
                            fontSize: "12px",
                            margin: "0",
                            color: "rgba(0, 0, 0, 0.4)",
                          }}
                        >
                          {GetFileSize(fileObj[0]?.file?.size)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <Button
                    style={{
                      color: "white",
                      background: "#017EFA",
                      padding: "5px 15px",
                      borderRadius: "9px",
                      fontSize: "12px",
                      float: "right",
                    }}
                    onClick={() => handleNextStep(2)}
                  >
                    NEXT STEP
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              background: "#FFFFFF",
              boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
              borderRadius: "16px",
              width: "30%",
              padding: "10px",
              display: "flex",
              alignItems: "end",
            }}
          >
            {PostContent.length > 0 ? (
              <ReactPlayer url={currentUrl} controls={true} height={"100%"} />
            ) : (
              <>
                <h4
                  style={{
                    // textOrientation: "sideways",
                    writingMode: "vertical-rl	",
                    margin: "0 0 20px",
                    transform: "rotate(-180deg)",
                    color: "#4F4F4F",
                    // height: "90%",
                    fontSize: "40px",
                  }}
                >
                  Preview
                </h4>
                <div
                  style={{
                    height: "90%",
                    border: "1px dashed #017efa",
                    margin: "auto",
                    borderRadius: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // width: "80%",
                  }}
                >
                  <p
                    style={{
                      padding: "10px",
                      color: "rgba(0, 0, 0, 0.4)",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    Uploaded <span style={{ color: "black" }}>Videos</span> will
                    be shown here
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {/* //! activeStep 2 */}
      {activeStep === 2 && (
        <div style={{ display: "flex", gap: "30px", height: "73vh" }}>
          <div
            style={{
              width: "70%",
              background: "#FFFFFF",
              boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
              borderRadius: "16px",
            }}
          >
            <div
              style={{
                padding: "20px",
              }}
            >
              <h3 style={{ fontSize: "16px", marginBottom: "30px" }}>
                Upload Thumbnail Video
              </h3>
              <div {...getRootProps()} className="uplodeDiv">
                <input
                  {...getInputProps()}
                  // onChange={(e) => newFileUploadThumbnail([e.target.files[0]])}
                />
                <CloudUploadIcon
                  className="uplodeimg"
                  style={{ fontSize: "3rem" }}
                />
                {thumbnailUrl?.length > 0 && (
                  <p>{thumbnailUrl?.length} file is selected for upload</p>
                )}

                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <>
                    <p style={{ margin: 0, fontSize: "14px" }}>
                      Select files or Drag 'n' drop some files here
                    </p>
                    <p
                      style={{
                        color: "rgba(0, 0, 0, 0.4)",
                        margin: "0 0 15px",
                        fontSize: "14px",
                      }}
                    >
                      Video file,file size no more than 10mb
                    </p>
                    {/* <button></button> */}
                    <Button
                      variant="contained"
                      // color="#F2F9FF"
                      style={{
                        background: "#F2F9FF",
                        border: "1px solid #57B2E0",
                        color: "#017EFA",
                        fontSize: "12px",
                        borderRadius: "9px",
                      }}
                    >
                      SELECT FILE
                    </Button>
                  </>
                )}
              </div>
              <p
                style={{
                  textAlign: "center",
                  margin: "10px 0",
                  fontSize: "14px",
                }}
              >
                What are you creating today.....
              </p>
              {thumbnailUrl?.length > 0 && (
                <div>
                  <h5 style={{ fontSize: "16px" }}>File added</h5>
                  <div
                    style={{
                      display: "flex",
                      margin: "3px 0px",
                      // width: "100%",
                      // justifyContent: "space-between",
                    }}
                  >
                    <DescriptionIcon
                      style={{ color: "#017EFA", fontSize: "35px" }}
                    />
                    <div
                      style={{
                        width: "100%",
                        borderBottom: "3px solid #017EFA",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "12px",
                          margin: "0",
                          color: "rgba(0, 0, 0, 0.4)",
                        }}
                      >
                        {thumbnailUrl[0]?.file?.name}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <DeleteOutlineIcon
                          style={{
                            color: "rgba(255, 78, 78, 1)",
                            fontSize: "18px",
                          }}
                        />
                        <p
                          style={{
                            fontSize: "12px",
                            margin: "0",
                            color: "rgba(0, 0, 0, 0.4)",
                          }}
                        >
                          {GetFileSize(thumbnailUrl[0]?.file?.size)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* <h5 style={{ fontSize: "16px" }}>File added</h5> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 0",
                }}
              >
                <Button
                  style={{
                    color: "#017EFA",
                    background: "#F2F9FF",
                    padding: "5px 15px",
                    border: "1px solid #57B2E0",
                    borderRadius: "9px",
                    fontSize: "12px",
                  }}
                  onClick={() => setActiveStep(1)}
                >
                  PREVIOUS
                </Button>
                <Button
                  style={{
                    color: "white",
                    background: "#017EFA",
                    padding: "5px 15px",
                    borderRadius: "9px",
                    fontSize: "12px",
                  }}
                  onClick={() => handleNextStep(2)}
                >
                  NEXT STEP
                </Button>
              </div>
            </div>
          </div>
          <div
            style={{
              background: "#FFFFFF",
              boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
              borderRadius: "16px",
              width: "30%",
              padding: "10px",
              display: "flex",
              alignItems: "end",
            }}
          >
            {thumbnailUrl.length > 0 ? (
              <ReactPlayer
                url={currentThumbNailUrl}
                controls={true}
                height={"100%"}
              />
            ) : (
              <>
                <h4
                  style={{
                    // textOrientation: "sideways",
                    writingMode: "vertical-rl	",
                    margin: "0 0 20px",
                    transform: "rotate(-180deg)",
                    color: "#4F4F4F",
                    // height: "90%",
                    fontSize: "40px",
                  }}
                >
                  Preview
                </h4>
                <div
                  style={{
                    height: "90%",
                    border: "1px dashed #017efa",
                    margin: "auto",
                    borderRadius: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "70%",
                  }}
                >
                  <p
                    style={{
                      padding: "10px",
                      color: "rgba(0, 0, 0, 0.4)",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    Uploaded <span style={{ color: "black" }}>Photo</span> will
                    be shown here
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {/* //! activeStep 3 */}
      {activeStep === 3 && (
        <div style={{ display: "flex", gap: "30px", height: "73vh" }}>
          <div
            style={{
              width: "70%",
              background: "#FFFFFF",
              boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
              borderRadius: "16px",
            }}
          >
            <div
              style={{
                padding: "20px",
              }}
            >
              <h3 style={{ fontSize: "16px", marginBottom: "30px" }}>
                Fill Details
              </h3>

              <div>
                {/* //! Thumbnail Title  */}
                <div style={{ marginBottom: "5px" }}>
                  <InputLabel style={{ fontSize: "14px" }}>
                    Thumbnail Title
                  </InputLabel>
                  <InputBase
                    placeholder="Ex. blaash"
                    style={{
                      border: "1px solid #F0F0F0",
                      padding: "0 10px",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                    value={discription.thumbnailTitle}
                    onChange={(e) =>
                      setDiscription({
                        ...discription,
                        thumbnailTitle: e.target.value,
                      })
                    }
                  />
                </div>
                {/* //! Button CTA  */}
                <div style={{ marginBottom: "5px" }}>
                  <InputLabel style={{ fontSize: "14px" }}>
                    Button CTA
                  </InputLabel>
                  <input
                    type="text"
                    placeholder="Button CTA"
                    style={{
                      border: "1px solid #F0F0F0",
                      padding: "0 10px",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                    value={discription.buttonCta}
                    onChange={(e) =>
                      setDiscription({
                        ...discription,
                        buttonCta: e.target.value.slice(0, 20),
                      })
                    }
                    maxLength={20}
                  />

                  {/* <InputBase
                    placeholder="Button CTA"
                    style={{
                      border: "1px solid #F0F0F0",
                      padding: "0 10px",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                    value={discription.buttonCta}
                    onChange={(e) =>
                      setDiscription({
                        ...discription,
                        buttonCta: e.target.value,
                      })
                    }
                  /> */}
                </div>
                <div style={{ marginBottom: "5px" }}>
                  <InputLabel style={{ fontSize: "14px" }}>Summary</InputLabel>

                  <TextareaAutosize
                    minRows={2.5}
                    placeholder="Summary"
                    style={{
                      border: "1px solid #F0F0F0",
                      width: "100%",
                      padding: "5px 10px",
                      borderRadius: "10px",
                    }}
                    value={discription.discription}
                    onChange={(e) =>
                      setDiscription({
                        ...discription,
                        discription: e.target.value,
                      })
                    }
                  />
                </div>
                
                {/* <div style={{ marginBottom: "5px" }}>
                  <InputLabel style={{ fontSize: "14px" }}>
                    Category (maximum 20)
                  </InputLabel>

                  <TextareaAutosize
                    minRows={2.5}
                    placeholder="Separate by enter"
                    style={{
                      border: "1px solid #F0F0F0",
                      width: "100%",
                      padding: "5px 10px",
                      borderRadius: "10px",
                    }}
                  />
                </div> */}
                {/* <div style={{ marginBottom: "5px" }}>
                  <InputLabel style={{ fontSize: "14px" }}>
                    Tags (maximum 20)
                  </InputLabel>

                  <TextareaAutosize
                    minRows={2.5}
                    placeholder="Separate by enter"
                    style={{
                      border: "1px solid #F0F0F0",
                      padding: "5px 10px",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </div> */}
              </div>

              {/* <h5 style={{ fontSize: "16px" }}>File added</h5> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 0",
                }}
              >
                <Button
                  style={{
                    color: "#017EFA",
                    background: "#F2F9FF",
                    padding: "5px 15px",
                    border: "1px solid #57B2E0",
                    borderRadius: "9px",
                    fontSize: "12px",
                  }}
                  onClick={() => setActiveStep(2)}
                >
                  PREVIOUS
                </Button>
                <Button
                  style={{
                    color: "#017EFA",
                    background: "#FFF2F2",
                    border: "1px solid rgba(253, 0, 0, 0.5)",
                    padding: "5px 15px",
                    color: "#FA0101",
                    borderRadius: "9px",
                    fontSize: "12px",
                  }}
                  onClick={() => handleReset()}
                >
                  RESET ALL
                </Button>
                <Button
                  style={{
                    color: "white",
                    background: "#017EFA",
                    padding: "5px 15px",
                    borderRadius: "9px",
                    fontSize: "12px",
                  }}
                  onClick={() => handleNextStep(2)}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
          <div
            style={{
              background: "#FFFFFF",
              boxShadow: "0px 10px 32px rgba(0, 0, 0, 0.07)",
              borderRadius: "16px",
              width: "30%",
              padding: "10px",
            }}
          >
            <VideoLibrary_Description
              productDetail={productDetail}
              setProductDetail={setProductDetail}
              productIds={productIds}
              passProductIds={passProductIds}
            />
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                padding: "10px ",
                fontSize: "13px",
              }}
            >
              <div
                style={{
                  background:
                    activeProductFilter === "name" ? "#1F2020" : "#F2F2F2",
                  borderRadius: "7px",
                  padding: "5px 8px",
                  cursor: "pointer",
                  color: activeProductFilter === "name" ? "#FFFFFF" : "#212222",
                }}
                onClick={() => setActiveProductFilter("name")}
              >
                By Name
              </div>
              <div
                style={{
                  background:
                    activeProductFilter === "sku" ? "#1F2020" : "#F2F2F2",
                  borderRadius: "7px",
                  cursor: "pointer",
                  padding: "5px 8px",
                  color: activeProductFilter === "sku" ? "#FFFFFF" : "#212222",
                }}
                onClick={() => setActiveProductFilter("sku")}
              >
                By SKU
              </div>
              <div
                style={{
                  background:
                    activeProductFilter === "id" ? "#1F2020" : "#F2F2F2",
                  borderRadius: "7px",
                  padding: "5px 8px",
                  cursor: "pointer",
                  color: activeProductFilter === "id" ? "#FFFFFF" : "#212222",
                }}
                onClick={() => setActiveProductFilter("id")}
              >
                By ID
              </div>
              <div
                style={{
                  background:
                    activeProductFilter === "url" ? "#1F2020" : "#F2F2F2",
                  borderRadius: "7px",
                  cursor: "pointer",
                  padding: "5px 8px",
                  color: activeProductFilter === "url" ? "#FFFFFF" : "#212222",
                }}
                onClick={() => setActiveProductFilter("url")}
              >
                By URL
              </div>
            </div>
            <div
              style={{
                height: "90%",
              }}
            >
              <p
                style={{
                  // padding: "10px",
                  color: "rgba(0, 0, 0, 0.4)",
                  fontSize: "12px",
                }}
              >
                Search Product
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 5px",
                    borderRadius: "10px",
                    width: "80%",
                    border: "1px solid #F0F0F0",
                    boxShadow: "0px 0px 14.85px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1, border: "1px solid" }}
                    style={{ fontSize: "12px" }}
                    placeholder="Search by By SKU"
                    inputProps={{ "aria-label": "sSearch by By SKU" }}
                    value={inputMsg}
                    onChange={(e) => setInputMsg(e.target.value)}
                  />
                  <IconButton
                    type="button"
                    aria-label="search"
                    style={{
                      margin: 0,
                      padding: "5px",
                      background: "rgba(1, 126, 250, 0.1)",
                      borderRadius: "8px",
                      backdropFilter: "blur(69.8723px)",
                    }}
                    onClick={fatchProductDetail}
                  >
                    <SearchIcon
                      style={{ fontSize: "18px", color: "#017EFA" }}
                    />
                  </IconButton>
                </div>
                <ButtonBase
                  style={{
                    color: "#017EFA",
                    background: "#F2F9FF",
                    padding: "5px 15px",
                    border: "1px solid #57B2E0",
                    borderRadius: "9px",
                    fontSize: "12px",
                  }}
                >
                  Add
                </ButtonBase>
              </div>
              <p
                style={{
                  color: "#344767",
                  margin: "10px 0",
                  fontSize: "14px",
                  textAlign: "center",
                  fontWeight: 500,
                }}
              >
                Product List
              </p>
              {productDetail.map(() => (
                <div
                  style={{
                    background: "rgba(242, 249, 255, 0.5)",
                    borderRadius: "10px",
                    padding: "10px",
                    marginBottom: "10px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <img
                    src="#"
                    alt=""
                    height={64}
                    width={64}
                    style={{ borderRadius: "10px" }}
                  />
                  <div>
                    <p
                      style={{
                        fontSize: "14px",
                        margin: 0,
                      }}
                    >
                      Rosemary Purify Invigorating Conditioner
                    </p>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "#000000",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "15px",

                          color: "rgba(28, 28, 30, 0.8)",
                          textDecoration: "line-through",
                        }}
                      >
                        $200
                      </span>{" "}
                      - $100
                    </p>
                  </div>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default UploadShoppableVideos;
