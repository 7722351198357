// import "./styles.css";
import { Button } from "@material-ui/core";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { Image as KonvaImage, Layer, Stage } from "react-konva";
import { IndividualSticker } from "./IndividualSticker";
import { StickyNote } from "./StickyNote";

const AddSticker = React.forwardRef((props, ref) => {
  const {
    images,
    background,
    setImages,
    texts,
    setTexts,
    key,
    currentStoryIndex,
    width,
    height,
    scaleX,
    scaleY,
  } = props;
  const [imageSrc, setImageSrc] = useState("");
  const [selectedId, selectShape] = useState(null);
  const resetAllButtons = useCallback(() => {
    images.forEach((image) => {
      if (image.resetButtonRef.current) {
        image.resetButtonRef.current();
      }
    });
  }, [images]);

  useEffect(() => {
    let img = new window.Image();
    img.onload = () => {
      setImageSrc(img);
    };
    img.crossOrigin = "Anonymous";

    var reader = new FileReader();

    reader.onloadend = () => {
      img.src = reader.result;
      setImageSrc(img);
    };

    reader.readAsDataURL(background);
    img.addEventListener("load", setImageSrc(img));

    return () => {
      img.removeEventListener("load", setImageSrc(img));
    };
  }, [background]);

  const handleCanvasClick = useCallback(
    (event) => {
      if (event.target.attrs.id === "backgroundImage" + key) {
        resetAllButtons();
      }
    },
    [resetAllButtons]
  );
  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const stage = e.target;
    if (stage.parent === null) {
      selectShape(null);
    }
  };

  return (
    <div style={{ position: "relative" }} key={key}>
      <Stage
        // width={width}
        // height={height}
        width={270}
        height={480}
        scaleX={scaleX}
        scaleY={scaleY}
        ref={ref}
        onClick={handleCanvasClick}
        onTap={handleCanvasClick}
        onMouseDown={checkDeselect}
        onTouchStart={checkDeselect}
      >
        <Layer>
          <KonvaImage
            id={"backgroundImage"}
            image={imageSrc}
            // width="270px"
            // height="480px"
            width={width}
            height={height}
          />

          {images.map(
            (image, index) =>
              image.storyIndex == currentStoryIndex && (
                <IndividualSticker
                  image={image}
                  onDelete={() => {
                    const imgs = images.slice();
                    imgs.splice(index, 1);
                    setImages(imgs);
                  }}
                  onDragEnd={(evt) => {
                    image.x = evt.target.x();
                    image.y = evt.target.y();
                    console.log({ x: evt.target.x(), y: evt.target.y() });
                    setImages([...images, image]);
                  }}
                />
              )
          )}
          {texts.map(
            (text, index) =>
              text.storyIndex == currentStoryIndex && (
                <StickyNote
                  textProps={text}
                  isSelected={text.id == selectedId}
                  onSelect={() => {
                    selectShape(text.id);
                  }}
                  onDelete={() => {
                    const txts = texts.slice();
                    txts.splice(index);
                    setTexts(txts);
                  }}
                  onChange={(newAttrs) => {
                    const txt = texts.slice();
                    txt[index] = newAttrs;
                    setTexts(txt);
                  }}
                />
              )
          )}
        </Layer>
      </Stage>
      <Button
        style={{
          position: "absolute",
          right: "0",
          left: 0,
          bottom: "10px",
          backgroundColor: "rgba(31, 32, 32, 0.85)",
          fontSize: "15px",
          boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.15)",
          borderRadius: "10px",
          fontWeight: 500,
          // marginLeft: "4px",

          padding: window.innerWidth > 500 ? "7px 14px" : "7px 10px",
          width: "65%",
          margin: "auto",
          color: "white",
          textTransform: "capitalize",
          textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          // height: "2rem",
        }}
      >
        BUY NOW
      </Button>
    </div>
  );
});

export default AddSticker;
