import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import InstaIcon from '../icon/InstaIcon';
import { makeStyles } from '@material-ui/core';

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({}))

function IgAlertBox({alertObj, handleOpenSecondPopup}) {
        // const {alertObj,handleOpenSecondPopup} = props
        const classes = useStyles();

        
    return (
        <div>
            <Dialog
            
                fullWidth="100px"
                maxWidth={'xs'} 
                elementType={"Fade"}
                open={alertObj.open}
                // TransitionComponent={Transition}
                keepMounted
                onClose={() => alertObj.handleClose(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                // style={{
                //     backgroundColor:"black",
                // }}
                PaperProps={{
                    style: {
                      background: "#161414",
                      color: "white",
                    },
                  }}
                
            >

                <DialogTitle id="alert-dialog-title">
                    {/* {generatedCodeObj.title} */}
                    No Social Account Connected
                    </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description"
                    style = {{
                        background: "#161414",
                        color: "white",
                      }}
                    >
                        {/* {generatedCodeObj.text1} */}
                        You do not have social account connected.

                    </DialogContentText>
                </DialogContent>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" 
                    style= {{color:"cyan", fontSize:"1rem",
                      }}>
                        {/* {generatedCodeObj.text2} */}
                        Connect Atleast one account please.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                    onClick={() => handleOpenSecondPopup()} color="primary" style={{borderColor:'#3390FF',border:'1px', 
                }}
                    >
                        Connect Account
                    </Button>
                    <Button 
                    onClick={() => alertObj.handleClose(false)} style={{backgroundColor:'#3390FF',color:'#FFFFFF'}} autoFocus
                    >
                          Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default IgAlertBox;
