import groupSticker from "../assets/img/stickers/_Group_.png";
import buyNowSticker from "../assets/img/stickers/Buy Now.png";
import freshSticker from "../assets/img/stickers/Fresh.png";
import loveditSticker from "../assets/img/stickers/LovedIt.png";
import saleSticker from "../assets/img/stickers/Sale.png";
import superSticker from "../assets/img/stickers/Super.png";
import wowSticker from "../assets/img/stickers/WOW.png";

export default [
  {
    url: groupSticker,
    width: 100,
    alt: "Group Sticker",
  },
  {
    url: buyNowSticker,
    width: 100,
    alt: "Buy Now",
  },
  {
    url: freshSticker,
    width: 100,
    alt: "Fresh Sticker",
  },
  {
    url: loveditSticker,
    width: 100,
    alt: "Loved It Sticker",
  },
  {
    url: saleSticker,
    width: 100,
    alt: "Sale Sticker",
  },
  {
    url: superSticker,
    width: 100,
    alt: "Super Sticker",
  },
  {
    url: wowSticker,
    width: 100,
    alt: "Wow Sticker",
  },
];
