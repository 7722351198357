import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import search from "../designCloud/Image/Icon/search.svg";
import eye from "../designCloud/Image/Icon/eye.svg";
import crown from "../designCloud/Image/Icon/crown.svg";
import threeDotsHorz from "../designCloud/Image/Icon/three-dots-Horz.svg";
import facebook from "../designCloud/Image/Icon/facebook.svg";
import plus_src from "../../assets/img/add_gray.svg";
import InstaIcon from "../igUploads/icon/InstaIcon";
import FacebookIcon from "../igUploads/icon/FacebookIcon";
import NetworkCard from "../designCloud/components/AccountCard";
import LeftSidebar from "../designCloud/components/leftsidebar";
import CloudHeader from "../designCloud/components/cloudHeader";
import UseDesignHook from "../designCloud/components/UseDesignHook";
import "./index.css";
import { SocialMediaPost } from "../designCloud/components/SocialMediaPost";
import SideMenu from "../common/sideMenu/sideMenu";
import { useAppContext } from "../../contexts/appContext";

export const AddAccount = () => {
  const { updateThemeToDark, updateThemeToLight } = UseDesignHook();

  const { isAuthorisedFacebook, isAuthorisedInstagram, isAuthorizedYT } =
    useAppContext();
  console.log(
    "isAuthorisedFacebook",
    isAuthorisedFacebook,
    isAuthorisedInstagram,
    isAuthorizedYT
  );

  useEffect(() => {
    updateThemeToDark();
    document.body.style.background = "#22222A";

    return () => {
      updateThemeToLight();
      document.body.style.background = "white";
    };
  }, []);

  return (
    <>
      <CloudHeader />
      <div className="bd-highlight">
        {/* <SocialMediaPost /> */}
        {/* <LeftSidebar addaccount={true} /> */}
        <SideMenu />
        <div className="box-first_new post-files-container  add-account">
          <NetworkCard />
        </div>
      </div>
      {/* <NetworkCard /> */}
      {/* <SocialMediaPost /> */}
    </>
  );
};
