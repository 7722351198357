import React, { useCallback, useEffect } from "react";
import IgCardComonent from "./IgCardComonent";
import { useState } from "react";
import "./OneClickPost.css";
import ReactPlayer from "react-player";
import Stories from "react-insta-stories";

import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import StepOneIcon from "./icon/StepOneIcon";
import StepTwoIcon from "./icon/StepTwoIcon";
import StepThreeIcon from "./icon/StepThreeIcon";
import ThumbnailVideoIcon from "./icon/ThumbnailVideoIcon";
import SubmitButtonIcon from "./icon/SubmitButtonIcon";
import CancelButtonIcon from "./icon/CancelButtonIcon";
import AddNewButton from "./icon/AddNewButton";
import InstaIcon from "./icon/InstaIcon";
import TiktokIcon from "./icon/TiktokIcon";
import YouTubeIcon from "./icon/YouTubeIcon";

import MultipleBoxDropDown from "./materualUiComponent/MultipleBoxDropdown";
import ComboBoxDropdown from "./materualUiComponent/ComboBoxDropdown";
import Select from "react-select";
import FirstPolygon from "./icon/FirstPolygon";
import SecondPolygon from "./icon/SecondPolygon";
import ThirdPolygon from "./icon/ThirdPolygon";
import ThirdPolygonBright from "./icon/ThirdPolygonBright";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dark_Mode, Light_Mode } from "./igHandler/igHandler.js";
import { useHistory } from "react-router";
import createNotification from "../common/reactNotification";
import {
  ACTIVATE_CODE,
  ADD_ACCOUNT,
  CLIENT_URL_FOR_YT,
  ENGT_PROD_BASE_URI,
  ENGT_PROD_HOST_URI,
  ENGT_PROD_HOST_URI_IG,
  EVNT_PROD_HOST_URI,
  GENERATE_YOUTUBE_ACCESS_TOKEN,
  GET_ALL_PLAYLIST,
  GET_ALL_PRODUCT,
  GET_INSTA_MEDIA,
  GET_MEDIA,
  GET_POST_PRODUCT,
  GET_SHORTS_FROM_YOUTUBE,
} from "../../api/apiConstants";
import { axiosInstance } from "../../api/axios-config";
import { getAuthAndData, postAuthAndData } from "../../api/ApiHelper";
import UseIgHook from "./igHook/UseIgHook";
import { useDropzone } from "react-dropzone";
import SecondPolygonDim from "./icon/SecondPolygonDim";
import { NotificationContainer } from "react-notifications";
import IgAlertBox from "./materualUiComponent/IgAlertBox";
import IgAlertBox1 from "./materualUiComponent/IgAlerBox1";
import axios from "axios";
import { useLocation } from "react-use";
import YtCardComponent from "./YtCardComonent";
import { useAppContext } from "../../contexts/appContext";

const OneClickPost = () => {
  const {
    // selectedProductIdArray,
    // setSelectedProductIdArray,
    // selectedProductArray,
    // setSelectedProductArray,

    postState,
    setPostState,
    thumbnailTitle,
    setThumbnailTitle,
    photovideoClick,
    setPhotovideoClick,
    // updatePhotovideoClick,
    handlephotoclick,
    handlevideoclick,
    history,
    handleIgSubmit,
    handleYtSubmit,
    handleChangeThumbnail,
    handleAddPlaylist,
    theme,
    updateThemeToDark,
    updateThemeToLight,
    playlistCollection,
    setPlaylistCollection,
    selectedPlaylist,
    setSelectedPlaylist,
    PlaylistArr,
    setPlaylistArr,
    getAllPlayList,
    productArr,
    setProductArr,
    getAllProduct,
    handleInstaFileUpload,
    onDrop,
    discription,
    setDiscription,
    handleLoader,
    // igData,
    // setIgData,
    // updateIgData,
    handleIgNext,
    handleYtNext,
    productArray,
    setProductArray,
    handleResponseCode,
    selectedPlaylistt,
    PostContent,
    fileObj,
    thumbnailContent,
    thumbnailUrl,
    GetThumbnailUrl,
    getInstagramVideoData,
    igSelectedData,
    setIgSelectedData,
    igArrays,
    isChecked,
    setIsChecked,
  } = UseIgHook();

  console.log("00thumbnailContent", thumbnailContent);

  const [currentUrl, setCurrentUrl] = useState("");
  useEffect(() => {
    if (fileObj.length > 0) {
      const file = fileObj[0].file;
      const reader = new FileReader();
      reader.onload = (event) => {
        const dataURL = event.target.result;
        setCurrentUrl(dataURL);
      };
      reader.readAsDataURL(file);
    }
  }, [fileObj]);

  const [igArrayy, setIgArrayy] = useState([]);
  // const [isInstaSelected, setIsInstaSelected] = useState(false);
  // const [isYoutubeSelected, setIsYoutubeSelected] = useState(false);

  const {
    isInstaSelected,
    setIsInstaSelected,
    isYoutubeSelected,
    setIsYoutubeSelected,
  } = useAppContext();

  if (igArrayy.length > 0) {
    setIgArrayy(igArrays);
    console.log("igArrayy updated");
  }

  let userData = useSelector((select) => select.userReducer.userData);

  const [igData, setIgData] = useState([]);
  const [ytData, setYtData] = useState([]);

  const fetchInstaVideos = async () => {
    const body = {
      // "accessToken": "IGQVJYd19uanhlb0JCaU9aR3dzUE5nUHoxbERuR1pIeWljUk1QeDNCalp2bHREb2Nua3ZAFXzNEcVVMbHhTRjNYWUZAzaC14TVotemxtOWJJRW9rVHVORC0xczhkSWpDcTJ6ZAGQ3OUFB"
    };
    handleLoader(true);
    console.log("start");
    await getAuthAndData(`${ENGT_PROD_BASE_URI}${GET_INSTA_MEDIA}`, history)
      .then((response) => {
        console.log("postState2response", response);

        if (response.code == 3) {
          console.log("response.data.code", response.data.error);
          console.log("response.data.code", response.data.error_description);
          handleLoader(false);
          console.log("stop");
          handleOpenAlert1();
        } else {
          if (handleResponseCode(response)) {
            console.log("responseigdata", response.data);
            if (response !== null || undefined) {
              // getAllPlayList();
              getAllProduct();
              setIgData(response.data.items);
              console.log("response", response.data.items);
              handleLoader(false);
              console.log("end");
            } else {
              setPostState(1);
              handleLoader(false);
              console.log("end");
              return;
            }
          }
        }
      })
      .catch((error) => {
        console.error("error from authorizeCode", error);
        createNotification("error", "Error occured during fething media");
        handleLoader(false);
      });
  };

  const fetchYoutubeVideos = async () => {
    const body = {
      // "accessToken": "IGQVJYd19uanhlb0JCaU9aR3dzUE5nUHoxbERuR1pIeWljUk1QeDNCalp2bHREb2Nua3ZAFXzNEcVVMbHhTRjNYWUZAzaC14TVotemxtOWJJRW9rVHVORC0xczhkSWpDcTJ6ZAGQ3OUFB"
    };
    handleLoader(true);
    console.log("start");

    await getAuthAndData(`${ENGT_PROD_HOST_URI}${GET_SHORTS_FROM_YOUTUBE}`)
      .then((response) => {
        console.log("postState2 youtube response", response);

        if (response.code == 500) {
          console.log("response.data.code", response);
          handleLoader(false);
          console.log("stop");
          handleOpenAlert1();
        } else {
          if (handleResponseCode(response)) {
            console.log("responseYtdata", response.data);
            if (response !== null || undefined) {
              // getAllPlayList();
              getAllProduct();
              setYtData(response.data);
              console.log("response", response.data);
              handleLoader(false);
              console.log("end");
            } else {
              setPostState(1);
              handleLoader(false);
              console.log("end");
              return;
            }
          }
        }
      })
      .catch((error) => {
        console.error("error from authorizeCode", error);
        createNotification("error", "Error occured during fething media");
        handleLoader(false);
      });
  };

  useEffect(async () => {
    //! getMediaData
    const body = {
      // "accessToken": "IGQVJYd19uanhlb0JCaU9aR3dzUE5nUHoxbERuR1pIeWljUk1QeDNCalp2bHREb2Nua3ZAFXzNEcVVMbHhTRjNYWUZAzaC14TVotemxtOWJJRW9rVHVORC0xczhkSWpDcTJ6ZAGQ3OUFB"
    };

    if (isInstaSelected && postState == 2) {
      fetchInstaVideos();
    }

    if (isYoutubeSelected && postState == 2) {
      fetchYoutubeVideos();
    }
  }, [postState]);

  useEffect(() => {
    console.log("igDataa", igData);
  }, [igData]);

  useEffect(() => {
    console.log("ytDataa", ytData);
  }, [ytData]);

  useEffect(() => {
    setCurrentUrl(thumbnailUrl);
  },[thumbnailUrl]);

  // const fireApi =() =>{
  //   const data = axios.get("https://mgd1lneid1.execute-api.ap-south-1.amazonaws.com/test1/app-controller/get")
  // }
  //! theme use effect
  useEffect(() => {
    // fireApi();
    // getAllPlayList();
    updateThemeToDark();
    document.body.style.background = "#22222A";

    return () => {
      updateThemeToLight();
      document.body.style.background = "white";
    };
  }, []);

  console.log("rendered oneclickpost");

  const handleProductArrayChange = (newProductArray) => {
    setProductArray(newProductArray);
  };

  useEffect(() => {
    console.log("productArray0", productArray);
    const productIdArray = productArray.map((product) => product.product_id);
    setDiscription({
      ...discription,
      // productId: productArray[0]?.product_id //! add all the productid
      productId: productIdArray,
    });
  }, [productArray]);

  let arr = useSelector((select) => select.selectedProductArrayReducer.arr);
  // console.log("arrrrr", arr);
  let arr2 = useSelector(
    (select) => select.selectedYoutubeArrayReducer.videoObj
  );
  console.log("arr2", arr2);

  const handleDeleteButton = (productId) => {
    console.log("Delete button clicked for productId:", productId);
    setProductArray((prevProductArray) =>
      prevProductArray.filter((product) => product.product_id !== productId)
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false, // Allow only one file to be uploaded
  });

  const location = useLocation();

  const [alertObj, setAlertObj] = useState({
    open: false,
    title: "",
    text1: "",
    text2: "",
    handleClose: () => {},
  });

  const [alertInstObj, setAlertInstObj] = useState({
    open: false,
    title: "",
    text1: "",
    text2: "",
    handleClose: () => {},
  });

  const handleOpenAlert1 = () => {
    console.log("handleConnecthandleConnect");
    setAlertObj({
      open: true,
      title: "Code copied",
      text1: "Code copied",
      text2: "Code copied",
      handleClose: (bool) => {
        setAlertObj({
          ...alertObj,
          open: false,
          handleClose: () => {},
        });
      },
    });
  };

  const handleOpenSecondPopup = () => {
    console.log("handleOpenSecondPopup");
    history.push("/addaccount");
    // setAlertObj({
    //   open: false,
    //   title: "Code copied",
    //   text1: "Code copied",
    //   text2: "Code copied",
    //   handleClose: (bool) => {
    //     setAlertObj({
    //       ...alertObj,
    //       open: false,
    //       handleClose: () => {},
    //     });
    //   },
    // });
    // setAlertInstObj({
    //   open: true,
    //   title: "Code copied",
    //   text1: "Code copied",
    //   text2: "Code copied",
    //   handleClose: (bool) => {
    //     setAlertInstObj({
    //       ...alertInstObj,
    //       open: false,
    //       handleClose: () => {},
    //     });
    //   },
    // });
  };

  const handleInstaAuthorize = async () => {
    handleLoader(true);
    let activationCodeUrl = "";
    console.log("handleInstaAuthorize");
    setAlertInstObj({
      open: false,
    });
    const body = {};
    const url = "http://192.168.1.7:3001/social-service-engine/addAccount";

    await postAuthAndData(
      `${ENGT_PROD_HOST_URI_IG}${ADD_ACCOUNT}`,
      body,
      history
    )
      .then((response) => {
        // if (handleResponseCode(response)) {
        console.log("addaccountresponse", response);
        // createNotification("success","Code generated successfully")
        console.log("handleInstaAuthorize response.data", response.url);
        activationCodeUrl = response.url;
        window.location.href = activationCodeUrl;
        handleLoader(false);
        //  window.open(activationCodeUrl); //! for different window
        // }
      })
      .catch((error) => {
        console.error("error from handleInstaAuthorize", error);
        createNotification("error", "Error occured during authorization");
        handleLoader(false);
      });
  };

  const handleYoutubeAuthorize = async () => {
    handleLoader(true);
    let activationCodeUrl = "";
    console.log("handleInstaAuthorize");
    setAlertInstObj({
      open: false,
    });
    const body = {};
    // const url =
    //   "https://accounts.google.com/o/oauth2/v2/auth?client_id=833115940983-5h4v6tuqh9ut12igpq9376nh5sppibqh.apps.googleusercontent.com&redirect_uri=https://localhost:7107/&scope=https://www.googleapis.com/auth/youtube.readonly&access_type=offline&include_granted_scopes=true&response_type=code&prompt=select_account consent";

    // const url =
    //   "https://accounts.google.com/o/oauth2/v2/auth?client_id=511035235678-4cnlhassu9tsh0a6ev1j3oo3a3k46vmj.apps.googleusercontent.com&redirect_uri=https://localhost:3000/oneclickpost&scope=https://www.googleapis.com/auth/youtube.readonly&access_type=offline&include_granted_scopes=true&response_type=code&prompt=select_account consent";

    window.location.href = CLIENT_URL_FOR_YT;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code === null) return;

    const body = {
      code: code,
    };

    postAuthAndData(
      `${ENGT_PROD_HOST_URI}${GENERATE_YOUTUBE_ACCESS_TOKEN}`,
      body,
      history
    )
      .then((response) => {
        // if (handleResponseCode(response)) {
        console.log("addaccountresponse", response);
        createNotification("success", "Account connected successfully");
        console.log("handleInstaAuthorize response.data", response.url);
        // activationCodeUrl = response.url;
        // window.location.href = activationCodeUrl;
        handleLoader(false);
        //  window.open(activationCodeUrl); //! for different window
        // }
      })
      .catch((error) => {
        console.error("error from handleInstaAuthorize", error);
        createNotification("error", "Error occured during authorization");
        handleLoader(false);
      });
  }, []);
  // https://localhost:3000/oneclickpost?code=AQA0dFvGJsqyIayKB37y-PWL2r-Eq3EMQxcM22wto_E9f-_o1wypzJ3R8EloImqQddGtmPWjR0aD-i_QTawGuOwW0YIhOQ8SVnQCLSPpSdP5FkGGcxN_86sGn3MTKcvxOaNCZgscJdFjBlGXnaeCKEeWJI7ykR1ebCxESgi89UXS80ilFv7cZb8yKTgVxjuy-1rKSCndwS-Xb5Ma5-oGNkgQEw89PLF1zyxHn2kPZEgrGg#_

  // const authorizeCode = async (finalcode) => {
  //   console.log("authorizeCode");
  //   const body = {
  //     code: finalcode,
  //   };
  //   await postAuthAndData(
  //     `${ENGT_PROD_HOST_URI_IG}${ACTIVATE_CODE}`,
  //     body,
  //     history
  //   )
  //     .then((response) => {
  //       if (response.statusCode == 500) {
  //         createNotification("warning", "Please try again");
  //         return;
  //       }
  //       if (handleResponseCode(response)) {
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("error from authorizeCode", error);
  //       createNotification("error", "Error occured during authorization");
  //     });
  // };

  // const [fileName, setFileName] = useState('');

  // const handleFileChange = (event) => {
  //     const file = event.target.files[0];
  //     const fileName = file.name;
  //     setFileName(fileName);
  //     console.log('fileName',fileName);
  //   };

  // useEffect(() => {
  //   const queryString = window.location.search;
  //   console.log("useEffectqueryString", queryString);
  //   if (queryString) {
  //     console.log("queryString", queryString);
  //     const urlParams = new URLSearchParams(queryString);
  //     const code = urlParams.get("code", code);
  //     console.log("codeee", code);
  //     // let finalcode = code.slice(0, -2);
  //     let finalcode = code;
  //     console.log("finalcode", finalcode);
  //     authorizeCode(finalcode);
  //   }
  // }, []);

  return (
    <>
      <IgAlertBox
        alertObj={alertObj}
        handleOpenSecondPopup={handleOpenSecondPopup}
      />
      <IgAlertBox1
        alertInstObj={alertInstObj}
        handleInstaAuthorize={handleInstaAuthorize}
        handleYoutubeAuthorize={handleYoutubeAuthorize}
      />

      <NotificationContainer />
      {postState === 1 && (
        <>
          {/* //! Top Navbar */}
          <div className="page_no_navbar">
            <div className="page_no_navbar_polygon">
              <FirstPolygon />
            </div>

            <div className="page_no_navbar_line_01">
              <div className="page_no_navbar_line_01_bright"></div>
              <div className="page_no_navbar_line_01_dim"></div>
            </div>

            <div className="page_no_navbar_polygon_2nd">
              {/* <div  className='page_no_navbar_polygon_2nd_01'>
            <div  className='page_no_navbar_polygon_2nd_text'>
              2
            </div>
            </div> */}
              <SecondPolygonDim />
            </div>
            <div className="page_no_navbar_line_02">
              <div className="page_no_navbar_line_02_bright"></div>
              <div className="page_no_navbar_line_02_dim"></div>
            </div>
            <div className="page_no_navbar_polygon_3rd">
              {/* <div className='page_no_navbar_polygon_3rd_01'>
            <div className='page_no_navbar_polygon_3rd_text'>
              3
            </div>
            </div> */}
              <ThirdPolygon />
            </div>
          </div>

          <div className="select_platform_text_container">
            <div className="select_platform_text_00">
              Select Platform for Import Videos
            </div>
            <div className="select_platform_text_01">
              Select all social media platform from where you want to import
              your video.
            </div>
          </div>

          <div className="platforms_container">
            <div
              className="instagram_container"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setPostState(2);
                setIsInstaSelected(true);
                setIsYoutubeSelected(false);
              }}
            >
              <div className="insta_icon">
                <InstaIcon />
              </div>
              <div className="insta_text_container">
                <div className="insta_text_01">Instagram</div>
                <div className="insta_text_02">Add story, reel, Video post</div>
              </div>
            </div>
            <div
              className="tiktok_container"
              // onClick={handleOpenAlert1}
            >
              <div className="tiktok_icon">
                <TiktokIcon />
              </div>
              <div className="tiktok_text_container">
                <div className="tiktok_text_01">Tiktok</div>
                <div className="tiktok_text_02">
                  Add a tiktok videos from a URL. Coming Soon...
                </div>
              </div>
            </div>
            <div
              className="youtube_container"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setPostState(2);
                setIsYoutubeSelected(true);
                setIsInstaSelected(false);
              }}
            >
              <div className="youtube_icon">
                <YouTubeIcon />
              </div>
              <div className="youtube_text_container">
                <div className="youtube_text_01">YouTube</div>
                <div className="youtube_text_02">
                  Add Youtube Videos, Youtube Shorts
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {postState === 2 && (
        <>
          {/* <ReactPlayer
            url={
              "https://d33zkbf3uttm0b.cloudfront.net/generated_assets/divanor/tWwDXPdkhmg.mp4"
            }
            controls={true}
            height={"100%"}
            width={"100%"}
          /> */}
          {/* //! Top Navbar */}
          <div className="page_no_navbar">
            <div className="page_no_navbar_polygon">
              <FirstPolygon />
            </div>

            <div className="page_no_navbar_line_01">
              <div className="page_no_navbar_line_01_bright"></div>
              {/* <div className='page_no_navbar_line_01_dim'>

          </div> */}
            </div>

            <div className="page_no_navbar_polygon_2nd">
              {/* <div  className='page_no_navbar_polygon_2nd_01'>
            <div  className='page_no_navbar_polygon_2nd_text'>
              2
            </div>
            </div> */}
              <SecondPolygon />
            </div>
            <div className="page_no_navbar_line_02">
              <div className="page_no_navbar_line_02_bright"></div>
              <div className="page_no_navbar_line_02_dim"></div>
            </div>
            <div className="page_no_navbar_polygon_3rd">
              {/* <div className='page_no_navbar_polygon_3rd_01'>
            <div className='page_no_navbar_polygon_3rd_text'>
              3
            </div>
            </div> */}
              <ThirdPolygon />
            </div>
          </div>
          {/* //! mid Navbar */}
          <div className="mid_navbar">
            <div
              className="mid_navbar_photo_video"
              style={{ cursor: "pointer" }}
            >
              {/* {isInstaSelected && (
                <div
                  className="mid_navbar_photo"
                  onClick={handlephotoclick}
                  style={
                    photovideoClick === "IMAGE"
                      ? {
                          background: "#222229",
                          border: "1px solid #017EFA",
                        }
                      : null
                  }
                >
                  <div className="mid_navbar_photo_text">Photo</div>
                </div>
              )} */}
              <div
                className="mid_navbar_video"
                onClick={handlevideoclick}
                style={
                  photovideoClick === "VIDEO"
                    ? {
                        background: "#222229",
                        border: "1px solid #017EFA",
                      }
                    : null
                }
              >
                <div className="mid_navbar_video_text">Videos</div>
              </div>
            </div>

            {/* <div className='mid_navbar_duration_size'>
          <div className='mid_navbar_duration'>
            <div className='mid_navbar_duration_text'>
            <select className='select_dropdown'>
                <option disabled selected>Size</option>
                <option value="01">011111</option>
                <option value="02">022222</option>
                <option value="03">033333</option>
            </select>
              
            </div>
          </div>
          <div className='mid_navbar_size'>
            <div className='mid_navbar_size_text'>
              Size
            </div>
          </div>
          <select className='select_dropdown'>
                <option disabled selected>Duration</option>
                <option value="01">011111</option>
                <option value="02">022222</option>
                <option value="03">033333</option>
            </select>
        </div> */}
          </div>

          <Button
            style={{
              color: "white",
              background: "#017EFA",
              padding: "5px 15px",
              borderRadius: "9px",
              fontSize: "12px",
              float: "right",
              position: "absolute",
              top: "146px",
              right: "98px",
              width: "108px",
            }}
            onClick={() => (isInstaSelected ? handleIgNext() : handleYtNext())}
          >
            Next
          </Button>
          <Button
            style={{
              color: "white",
              background: "#017EFA",
              padding: "5px 15px",
              borderRadius: "9px",
              fontSize: "12px",
              float: "right",
              position: "absolute",
              top: "146px",
              left: "400px",
              width: "108px",
            }}
            onClick={() => setPostState(1)}
          >
            Back
          </Button>

          {/* //! Playlist Video */}
          <div className="playlist_container">
            {!!isInstaSelected && igData?.length !== 0 && (
              <>
                <IgCardComonent
                  igData={igData}
                  photovideoClick={photovideoClick}
                />
              </>
            )}
            {!!isYoutubeSelected && ytData?.length !== 0 && (
              <>
                <YtCardComponent
                  ytData={ytData}
                  photovideoClick={photovideoClick}
                />
              </>
            )}
          </div>
        </>
      )}
      {postState === 3 && (
        <>
          {/* //! Top Navbar */}
          <div className="page_no_navbar">
            <div className="page_no_navbar_polygon">
              <FirstPolygon />
            </div>

            <div className="page_no_navbar_line_01">
              <div className="page_no_navbar_line_01_bright"></div>
              {/* <div className='page_no_navbar_line_01_dim'>

          </div> */}
            </div>

            <div className="page_no_navbar_polygon_2nd">
              {/* <div  className='page_no_navbar_polygon_2nd_01'>
            <div  className='page_no_navbar_polygon_2nd_text'>
              2
            </div>
            </div> */}
              <SecondPolygon />
            </div>
            <div className="page_no_navbar_line_02">
              <div className="page_no_navbar_line_02_bright"></div>
              {/* <div className='page_no_navbar_line_02_dim'>

          </div> */}
            </div>
            <div className="page_no_navbar_polygon_3rd">
              {/* <div className='page_no_navbar_polygon_3rd_01'>
            <div className='page_no_navbar_polygon_3rd_text'>
              3
            </div>
            </div> */}
              <ThirdPolygonBright />
            </div>
          </div>

          <Button
            style={{
              color: "white",
              background: "#017EFA",
              padding: "5px 15px",
              borderRadius: "9px",
              fontSize: "12px",
              float: "right",
              position: "absolute",
              top: "140px",
              left: "400px",
              width: "108px",
            }}
            onClick={() => setPostState(2)}
          >
            Back
          </Button>

          <div className="design_container">
            {/* //! 01 */}
            <div className="search_product_container">
              <div className="search_product_container00">
                <div className="search_container">
                  <div className="search_product_text">Search Product</div>
                  {/* <div></div> */}
                  <MultipleBoxDropDown
                    productArr={productArr}
                    productArray={productArray}
                    setProductArray={setProductArray}
                    onProductArrayChange={handleProductArrayChange}
                  />
                </div>
              </div>
              <div className="add_product_container">
                <div className="add_product_list_container">
                  <div className="add_product_list_text">Add Product List</div>
                  <div className="add_product_list_list">
                    {/* //! frame 12936 */}

                    {productArray.length == 0
                      ? null
                      : productArray.map((product) => (
                          <div
                            className="product_container"
                            key={product.product_id}
                          >
                            <div className="product_container00">
                              <div className="rectangle_img">
                                {/* //! rectangle3 after that group5 */}
                                <img
                                  src={product?.base_image_url}
                                  // src="https://images.unsplash.com/photo-1526170375885-4d8ecf77b99f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cHJvZHVjdHxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80"
                                  alt="no image"
                                  style={{
                                    width: "64px",
                                    height: "64px",
                                    borderRadius: "12.48px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                              <div className="product_text_Container">
                                <div className="product_text_top">
                                  <div className="product_text_top_text">
                                    {product?.display_name}
                                  </div>
                                  <div className="product_price_container">
                                    {/* <div className="product_price_old">{product?.original_price}</div> */}
                                    <div className="product_price_new">
                                      {" "}
                                      {product?.original_price}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="product_delete"
                              onClick={() =>
                                handleDeleteButton(product.product_id)
                              }
                            >
                              <svg
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0 10C0 4.47715 4.47715 0 10 0H35V25C35 30.5228 30.5228 35 25 35H0V10Z"
                                  fill="#35373B"
                                />
                                <path
                                  d="M25.0584 11.8584C23.7167 11.7251 22.375 11.6251 21.025 11.5501V11.5417L20.8417 10.4584C20.7167 9.69175 20.5334 8.54175 18.5834 8.54175H16.4C14.4584 8.54175 14.275 9.64175 14.1417 10.4501L13.9667 11.5167C13.1917 11.5667 12.4167 11.6167 11.6417 11.6917L9.94172 11.8584C9.59172 11.8917 9.34172 12.2001 9.37505 12.5417C9.40838 12.8834 9.70838 13.1334 10.0584 13.1001L11.7584 12.9334C16.125 12.5001 20.5251 12.6667 24.9417 13.1084C24.9667 13.1084 24.9834 13.1084 25.0084 13.1084C25.3251 13.1084 25.6001 12.8667 25.6334 12.5417C25.6584 12.2001 25.4084 11.8917 25.0584 11.8584Z"
                                  fill="#C0C0D6"
                                />
                                <path
                                  d="M23.525 14.2833C23.325 14.0749 23.05 13.9583 22.7666 13.9583H12.2333C11.95 13.9583 11.6666 14.0749 11.475 14.2833C11.2833 14.4916 11.175 14.7749 11.1916 15.0666L11.7083 23.6166C11.8 24.8833 11.9166 26.4666 14.825 26.4666H20.175C23.0833 26.4666 23.2 24.8916 23.2916 23.6166L23.8083 15.0749C23.825 14.7749 23.7166 14.4916 23.525 14.2833ZM18.8833 22.2916H16.1083C15.7666 22.2916 15.4833 22.0083 15.4833 21.6666C15.4833 21.3249 15.7666 21.0416 16.1083 21.0416H18.8833C19.225 21.0416 19.5083 21.3249 19.5083 21.6666C19.5083 22.0083 19.225 22.2916 18.8833 22.2916ZM19.5833 18.9583H15.4166C15.075 18.9583 14.7916 18.6749 14.7916 18.3333C14.7916 17.9916 15.075 17.7083 15.4166 17.7083H19.5833C19.925 17.7083 20.2083 17.9916 20.2083 18.3333C20.2083 18.6749 19.925 18.9583 19.5833 18.9583Z"
                                  fill="#C0C0D6"
                                />
                              </svg>
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>

            {/* //! 02 */}
            <div className="media_container">
              <div className="player_container">
                {/* <div className="player_left_icon">
                        <svg width="50" height="50" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g filter="url(#filter0_b_155_22385)">
                          <circle cx="11" cy="11" r="11" transform="matrix(-1 0 0 1 22.8521 0)" fill="white" fill-opacity="0.1"/>
                          <circle cx="11" cy="11" r="10.5" transform="matrix(-1 0 0 1 22.8521 0)" stroke="white" stroke-opacity="0.1"/>
                          </g>
                          <path d="M9.88732 16.6766C10.0117 16.6766 10.1361 16.6308 10.2343 16.5326L14.5034 12.2635C15.1974 11.5695 15.1974 10.4302 14.5034 9.73617L10.2343 5.46712C10.0445 5.27724 9.73018 5.27724 9.54029 5.46712C9.35041 5.657 9.35041 5.97128 9.54029 6.16117L13.8093 10.4302C14.1236 10.7445 14.1236 11.2552 13.8093 11.5695L9.54029 15.8385C9.35041 16.0284 9.35041 16.3427 9.54029 16.5326C9.63851 16.6243 9.76291 16.6766 9.88732 16.6766Z" fill="white"/>
                          <defs>
                          <filter id="filter0_b_155_22385" x="-172.832" y="-173.684" width="369.368" height="369.368" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                          <feGaussianBlur in="BackgroundImageFix" stdDeviation="86.8421"/>
                          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_155_22385"/>
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_155_22385" result="shape"/>
                          </filter>
                          </defs>
                        </svg>
        </div> */}
                {isYoutubeSelected && photovideoClick == "VIDEO" && (
                  <div className="player_react_player">
                    <ReactPlayer
                      url={arr2.data}
                      controls={true}
                      width="100%"
                      height="100%"
                    />
                  </div>
                )}
                {isInstaSelected &&
                  photovideoClick == "VIDEO" &&
                  arr?.map((igData) => (
                    <div className="player_react_player">
                      <ReactPlayer
                        url={igData.media_url}
                        controls={true}
                        width="100%"
                        height="100%"
                      />
                    </div>
                  ))}
                {photovideoClick == "IMAGE" && (
                  <div className="player_react_player">
                    {arr.length > 0 && (
                      <Stories
                        stories={arr?.map((igData) => {
                          return {
                            url: igData.media_url,
                            type: "image",
                          };
                        })}
                        width={"100%"}
                        loop={true}
                        height={"100%"}
                      ></Stories>
                    )}
                  </div>
                )}
                {/* <div className="player_right_icon">
                        <svg width="50" height="50" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g filter="url(#filter0_b_155_22385)">
                          <circle cx="11" cy="11" r="11" transform="matrix(-1 0 0 1 22.8521 0)" fill="white" fill-opacity="0.1"/>
                          <circle cx="11" cy="11" r="10.5" transform="matrix(-1 0 0 1 22.8521 0)" stroke="white" stroke-opacity="0.1"/>
                          </g>
                          <path d="M9.88732 16.6766C10.0117 16.6766 10.1361 16.6308 10.2343 16.5326L14.5034 12.2635C15.1974 11.5695 15.1974 10.4302 14.5034 9.73617L10.2343 5.46712C10.0445 5.27724 9.73018 5.27724 9.54029 5.46712C9.35041 5.657 9.35041 5.97128 9.54029 6.16117L13.8093 10.4302C14.1236 10.7445 14.1236 11.2552 13.8093 11.5695L9.54029 15.8385C9.35041 16.0284 9.35041 16.3427 9.54029 16.5326C9.63851 16.6243 9.76291 16.6766 9.88732 16.6766Z" fill="white"/>
                          <defs>
                          <filter id="filter0_b_155_22385" x="-172.832" y="-173.684" width="369.368" height="369.368" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                          <feGaussianBlur in="BackgroundImageFix" stdDeviation="86.8421"/>
                          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_155_22385"/>
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_155_22385" result="shape"/>
                          </filter>
                          </defs>
                        </svg>
        </div> */}
              </div>
              {/* <div className="media_scroll_container">
        <div className="media_text_container">
          <div className="media_text_container_text">Media</div>
        </div>
        <div className="media_productImage_container">
          {arr?.map((igData)=><div className="media_productImage">
          <ReactPlayer 
            url={igData.mediaUrl}
            // controls={true}
            width="100px"
            height="97  px"
        />           
          </div>)}
        </div>
      </div> */}
            </div>

            {/* //! 03 */}
            <div className="thumbnail_container">
              <div className="thumbnail_container00">
                <div className="generatebox_container">
                    <Button
                      style={{
                        color: "white",
                        background: "#017EFA",
                        padding: "5px 15px",
                        borderRadius: "5px",
                        fontSize: "12px",
                        width: "150px",
                      }}
                      onClick={() => GetThumbnailUrl()}
                    >
                      Generate
                    </Button>
                  <div className="ig_title">Auto generated thumbnail</div>
                </div>
                <div className="thumbnail_title_text_playlist_container">
                  {!!thumbnailUrl && (
                    <>
                      {thumbnailContent.length > 0 ? (
                        <>
                          <div className="thumbnail_video_container uplodeDiv">
                            {thumbnailContent[0]?.fileType === 2 ? (
                              <ReactPlayer
                                url={currentUrl}
                                controls={true}
                                height={"100%"}
                                width={"100%"}
                              />
                            ) : (
                              <img
                                style={{
                                  height: "100%",
                                }}
                                src={currentUrl}
                              />
                            )}
                          </div>
                        </>
                      ) : (
                        <div
                          {...getRootProps()}
                          className="thumbnail_video_container uplodeDiv"
                        >
                          <input
                            {...getInputProps()}
                            // onChange={handleFileChange}
                          />
                          <div className="thumbnail_video_icon">
                            <ThumbnailVideoIcon />
                          </div>
                          {/* {<p
          style={{
            color: "white",
          }}
        >
          Uploaded file: {fileName}
        </p>} */}
                        </div>
                      )}
                    </>
                  )}

                  <div className="thumbnail_title_container">
                    <div className="thumbnail_title_text">Thumbnail Title</div>
                    <input
                      className="thumbnail_title_input"
                      type="text"
                      id="thumbnailTitle"
                      name="thumbnailTitle"
                      required
                      value={thumbnailTitle}
                      onChange={handleChangeThumbnail}
                    ></input>
                  </div>
                  <div className="">
                    <div className="thumbnail_playlist_text">PLAYLIST</div>
                    {/* <div className="add_New_Button_container" onClick={handleAddPlaylist}><AddNewButton/></div> */}
                    <ComboBoxDropdown
                      PlaylistArr={PlaylistArr}
                      selectedPlaylistt={selectedPlaylistt}
                    />
                  </div>
                </div>
                <div className="thumbnail_button_container">
              <div
                className="thumbnail_button_submit"
                onClick={() =>
                  isInstaSelected
                    ? handleIgSubmit(userData)
                    : handleYtSubmit(userData)
                }
              >
                <SubmitButtonIcon />
              </div>
              <div
                className="thumbnail_button_cancel"
                onClick={() => (window.location.href = "/oneclickpost")}
              >
                <CancelButtonIcon />
              </div>
            </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OneClickPost;