import React, { useState } from "react";
import CardMedia from "@material-ui/core/CardMedia";
import "./OneClickPost.css";
import UseIgHook from "./igHook/UseIgHook";
import { useEffect } from "react";
import ReactPlayer from "react-player";
import { useDispatch } from "react-redux";
import {
  Selected_Product_Array,
  Selected_Youtube_Array,
} from "../../constants/actionTypes";

const YtCardComponent = ({ ytData, photovideoClick }) => {
  const {
    igSelectedData,
    setIgSelectedData,
    ytSelectedData,
    setYtSelectedData,
  } = UseIgHook();

  // const [igSelectedData, setIgSelectedData] = useState([]);

  const handleCheckboxChange = (data) => {
    console.log("checkbox data", data);
    console.log("handleCheckboxChange", data);
    // const selectedIndex = igSelectedData.findIndex(
    //   (item) => item.id === data.videoId
    // );

    console.log(ytSelectedData);
    const ytselectedIndex = ytSelectedData.findIndex(
      // (item) => console.log(item, "item")
      (item) => item.videoId === data.videoId
    );

    console.log("ytselectedIndex", ytselectedIndex);

    // if (selectedIndex === -1) {
    //   setIgSelectedData([...igSelectedData, data]);
    // } else {
    //   const updatedSelectedData = [...igSelectedData];
    //   updatedSelectedData.splice(selectedIndex, 1);
    //   setIgSelectedData(updatedSelectedData);
    // }
    if (ytselectedIndex === -1) {
      setYtSelectedData([...ytSelectedData, data]);
    } else {
      const updatedYtSelectedData = [...ytSelectedData];
      updatedYtSelectedData.splice(ytselectedIndex, 1);
      setYtSelectedData(updatedYtSelectedData);
    }
  };

  // useEffect(() => {
  //   console.log("useEffectigSelectedData", igSelectedData);
  //   updateSelectedArray(igSelectedData);
  // }, [igSelectedData]);

  // useEffect(() => {
  //   console.log("useEffectytSelectedData", ytSelectedData);
  //   updateSelectedArray(ytSelectedData);
  // }, [ytSelectedData]);

  // const dispatch = useDispatch();

  // const updateSelectedArray = (igSelectedData) => {
  //   console.log("dispatch done from IgCardComponent");
  //   dispatch({ type: Selected_Product_Array, payload: igSelectedData });
  // };

  // const updateSelectedArray = (ytSelectedData) => {
  //   console.log("dispatch done from YtCardComponent");
  //   dispatch({ type: Selected_Youtube_Array, payload: ytSelectedData });
  // };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      {ytData?.map((data, index) => {
        console.log("inside yt comp", data);
        // ! temporary change
        // if (data.mediaType === "VIDEO") {

        const checkboxSelected = ytSelectedData.some(
          (item) => item.videoId === data.videoId
        );

        return (
          <div className="video_container" key={data.id}>
            <div className="video_container01">
              <div className="play_icon">
                {/* //! <PlaySvg/> */}
                <svg
                  width="26"
                  height="28"
                  viewBox="0 0 26 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {/* ...play icon SVG code... */}
                </svg>
              </div>

              {/* {data.mediaType === "IMAGE" && <img
                  src={data.mediaUrl}
                  alt="no photo"
                  style={{
                    position:'absolute',
                    objectFit: 'cover',
                    width: '236px',
                    height: '148px',
                    borderRadius: '15px',
                    border: '1px solid #FFFFFF',
                    boxShadow: '0px 2.56px 15.39px 0px rgba(0, 0, 0, 0.05)',
                  }}
                />} */}
              <img
                src={data?.thumbnail_url}
                alt="no photo"
                style={{
                  position: "absolute",
                  objectFit: "cover",
                  width: "236px",
                  height: "148px",
                  borderRadius: "15px",
                  border: "1px solid #FFFFFF",
                  boxShadow: "0px 2.56px 15.39px 0px rgba(0, 0, 0, 0.05)",
                }}
              />

              {/* <div className="size_container">
                  <div className="size_container_text">300mb</div>
                </div> */}

              {/* <div className="timestamp_container">
                  <div className="timestamp_container_text">00:00:00</div>
                </div> */}
            </div>

            <div className="checkbox_container">
              <input
                type="checkbox"
                checked={checkboxSelected}
                onChange={() => handleCheckboxChange(data)}
              />

              <div className="ig_title">
                {data.title ? data.title : "NO CAPTION"}
              </div>
            </div>
          </div>
        );
        return null;
      })}
    </>
  );
};

export default YtCardComponent;
