import React, { useState, useEffect } from 'react';

const Attribute = ({ sendDataToParent }) => {
  const [enableCircle, setEnableCircle] = useState(false);
  const [enableMiniPlayer, setEnableMiniPlayer] = useState(false);
  const [disableProductCard, setDisableProductCard] = useState(false);

  useEffect(() => {
    sendDataToParent(enableCircle, enableMiniPlayer, disableProductCard);
  }, [enableCircle, enableMiniPlayer, disableProductCard, sendDataToParent]);

  const handleCircleCheckboxChange = () => {
    setEnableCircle(!enableCircle);
  };

  const handleMiniPlayerCheckboxChange = () => {
    setEnableMiniPlayer(!enableMiniPlayer);
  };

  const handleProductCardCheckboxChange = () => {
    setDisableProductCard(!disableProductCard);
  };

  const initialState = () => {
    setEnableCircle(false);
    setEnableMiniPlayer(false);
    setDisableProductCard(false);
  };

  const handleClearButtonClick = () => {
    initialState();
  };

  return (
    <>
      {/* <NotificationContainer /> */}
      <div className="container02" style={{ alignItems: 'none' }}>
        <p style={{ marginRight: '10px' }}>
          <b>Select Layout Option</b>
        </p>

        {/* Video format */}
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              checked={enableCircle}
              onChange={handleCircleCheckboxChange}
            />
            &nbsp;Enable Circle
          </label>
        </div>

        {/* Floating Bubble */}
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              checked={enableMiniPlayer}
              onChange={handleMiniPlayerCheckboxChange}
            />
            &nbsp;Disable Mini Player
          </label>
        </div>

        {/* Product Card */}
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              checked={disableProductCard}
              onChange={handleProductCardCheckboxChange}
            />
            &nbsp;Disable Product Card
          </label>

        </div>

        {/* Clear Button */}
        {/* <button onClick={handleClearButtonClick}>Clear</button> */}
      </div>
    </>
  );
};

export default Attribute;
