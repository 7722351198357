import { Delete_Product_Array, Selected_Product_Array } from "../../constants/actionTypes";

const INITIAL_STATE = {
    arr: [], // Default value
   
  };
  
  const selectedProductArrayReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

      case Selected_Product_Array:
        return {
          // ...state,
          arr: action?.payload,
        };

        case Delete_Product_Array:
          return {
            // ...state,
            arr: [],
          };

        
      default:
        return state;
    }
  };
  
  export default selectedProductArrayReducer;