import React, { useState } from "react";
import { HexColorPicker } from "react-colorful";
import { Button, InputBase, InputLabel, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const initialActiveInput = {
  fontSize: false,
  fontStyle: false,
  textBackgroundColor: false,
  textColor: false,
};
const useStyles = makeStyles({
  item: {
    "&:hover": {
      backgroundColor: "black",
    },
  },
});
const TextEditorNew = ({ textEditorInput, setTextEditorInput, handleAddText }) => {
  const [activeInput, setActiveInput] = useState(initialActiveInput);
  const [moreColor, setMoreColor] = useState({
    backgroundColor: false,
    textColor: false,
  });
  const [activeColor, setActiveColor] = useState({
    backgroundColor: "#000",
    textColor: "#fff",
  });
  const [activeFont, setActiveFont] = useState("Roboto");
  const fonts = [
    "Alex Brush",
    "CooperHewitt",
    "Edmunds",
    "Kaushan Script",
    "Pacifico",
    "Seaside ResortNF",
    "Sofia",
  ];
  const defaultColors = ["#D9E3F0", "#F47373", "#697689", "#37D67A", "#2CCCE4"];
  const handleSelect = (property, value) => {
    setTextEditorInput({ ...textEditorInput, [property]: value });
    setActiveInput({ ...initialActiveInput });
  };
  const handleCustomColor = (property) => {
    setMoreColor({ ...moreColor, [property]: true });
    setActiveInput({ ...initialActiveInput });
  };
  const { item } = useStyles();

 
  return (
    <div
      style={{
        display: "flex",
        // width: "100%",
        gap: "30px",
        alignItems: "center",
        marginBottom: "30px",
        flexDirection:"column"
        // position: "relative",
      }}
    >
         <div>
        <div style={{ marginBottom: "20px", position: "relative" }}>
          <InputLabel style={{ fontSize: "14px", color:"white" }}>Text</InputLabel>
          <InputBase
            placeholder="Ex. blaash"
            style={{
              border: "1px solid #F0F0F0",
              padding: "0 10px",
              width: "100%",
              borderRadius: "10px",
              color:"white"
            }}
            value={textEditorInput.text}
            onChange={(e) =>
              setTextEditorInput({ ...textEditorInput, text: e.target.value })
            }
          />
          <button
            style={{
              position: "absolute",
              right: "0",
              margin: "auto",
              // top: "50%",
              // transform: "translateY(-50%)",
              height: "30px",
              width: "30px",
              border: "none",
              borderRadius: "6px",
              backgroundColor: textEditorInput.fontColor,
            }}
            onClick={() => setMoreColor({ ...moreColor, textColor: true })}
          ></button>
          {moreColor.textColor && (
            <div
              style={{
                position: "absolute",
                bottom: "2px",
                right: "0px",
                zIndex: 100,
              }}
            >
              <HexColorPicker
                color={textEditorInput.fontColor}
                onChange={(color) =>
                  setTextEditorInput({
                    ...textEditorInput,
                    fontColor: color,
                  })
                }
              />
            </div>
          )}
        </div>
        <div style={{ width: "100%" }}>
          <InputLabel style={{ fontSize: "14px", color:"white" }}>Font Size</InputLabel>
          <div style={{ position: "relative" }}>
            <InputBase
              placeholder="18px"
              style={{
                border: "1px solid #F0F0F0",
                padding: "0 10px",
                width: "100%",
                borderRadius: "10px",
                color:"white"
              }}
              type="number"
              value={textEditorInput.fontSize}
              onChange={(e) =>
                setTextEditorInput({
                  ...textEditorInput,
                  fontSize: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        {fonts.map((font) => (
          <button
            style={{
              height: "40px",
              width: "40px",
              fontFamily: font,
              borderRadius: "10px",
              border: "3px solid rgba(1, 126, 250, 0.1)",
              fontSize: "18px",
              backgroundColor:
                activeFont == font ? "rgba(1, 126, 250, 0.1)" : "white",
            }}
            onClick={() =>
              setTextEditorInput({ ...textEditorInput, fontStyle: font })
            }
          >
            Aa
          </button>
        ))}
      </div>
     

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // justifyContent: "center",
          gap: "10px",
          position: "relative",
        }}
      >
        {defaultColors.map((color) => (
          <button
            style={{
              height: "45px",
              width: "45px",
              borderRadius: "10px",
              border: activeColor == color ? "3px solid #F2F2F2" : "none",
              fontSize: "20px",
              backgroundColor: color,
            }}
            onClick={() =>
              setTextEditorInput({
                ...textEditorInput,
                fontBackgroundColor: color,
              })
            }
          ></button>
        ))}
        {moreColor.backgroundColor && (
          <div
            style={{
              position: "absolute",
              bottom: "2px",
              right: "0px",
              zIndex: 100,
            }}
          >
            <HexColorPicker
              color={textEditorInput.fontBackgroundColor}
              onChange={(color) =>
                setTextEditorInput({
                  ...textEditorInput,
                  fontBackgroundColor: color,
                })
              }
            />
          </div>
        )}
        <button
          style={{
            height: "45px",
            width: "45x",
            borderRadius: "10px",
            border: "3px solid #F2F2F2",
            fontSize: "20px",
            backgroundColor: "white",
            position: "relative",
            zIndex: 100,
          }}
          onClick={() => setMoreColor({ ...moreColor, backgroundColor: true })}
        >
          <AddIcon color="#F2F2F2" />
        </button>
      </div>

      {(moreColor.backgroundColor || moreColor.textColor) && (
        <div
          onClick={() =>
            setMoreColor({ backgroundColor: false, textColor: false })
          }
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            // zIndex: 88,
          }}
        ></div>
      )}
      <Button
        style={{
        //   position: "absolute",
        //   top: "0px",
        //   left: "15px",
          color: "#fff",
          background: "#000",
        //   padding: "5px 15px",
          borderRadius: "9px",
          fontSize: "12px",
          float: "right",
        //   margin: "10px 0 20px",
        }}
        onClick={() => handleAddText()}
      >
        ADD ANOTHER TEXT
      </Button>
    </div>
  );
};

export default TextEditorNew;
